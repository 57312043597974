import React, { useMemo } from "react";
import { DeletedIndicator } from "components/common/entities/components/indicators/DeletedIndicator";
import { ResourceDetailsTooltip } from "components/common/ResourceDetailsTooltip";
import { getDynamicSizeIcon } from "components/ui/dynamicSizeIcon";
import { ResourcesIcon } from "components/ui/Icons/ResourcesIcon";
import { useIntegrations } from "hooks/useIntegrations";
import { Chip, TChipVariant, type TChipSize } from "../Chip";
import type { IntegrationResourceModel } from "models/IntegrationResourceModel";

interface IResourceChipProps {
	integrationResource: IntegrationResourceModel;
	onDelete?: () => void;
	readonly?: boolean;
	selected?: boolean;
	size?: TChipSize;
	variant?: TChipVariant;
}

export const ResourceChip: FC<IResourceChipProps> = ({
	className,
	innerRef,
	integrationResource,
	onDelete,
	readonly = false,
	selected = false,
	size = "medium",
	variant = "regular"
}) => {
	const integrations = useIntegrations();
	const resourceIntegration = integrations?.get(integrationResource.integrationId);
	const resourceImage = resourceIntegration?.imageUrl;

	const ResourceChipIcon = useMemo(
		() => (resourceImage ? getDynamicSizeIcon(<img src={resourceImage} />) : ResourcesIcon),
		[resourceImage]
	);

	const inactive = integrationResource.isDeleted;
	const PrefixIcon = useMemo(() => (inactive ? DeletedIndicator : ResourceChipIcon), [inactive, ResourceChipIcon]);

	return (
		<ResourceDetailsTooltip
			name={integrationResource.name}
			type={integrationResource.type}
			id={integrationResource.id}
			euid={integrationResource.euid}
			description={integrationResource.description}>
			<Chip
				noTooltip
				readonly={readonly}
				variant={variant}
				inactive={inactive}
				size={size}
				PrefixIcon={PrefixIcon}
				onDelete={onDelete}
				selected={selected}
				className={className}
				innerRef={innerRef}>
				{integrationResource.name}
			</Chip>
		</ResourceDetailsTooltip>
	);
};
