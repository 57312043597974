import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	tokenSelectContainer: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between"
	},
	tokenSelectInput: {
		flex: 1
	}
});
