import { List, Record } from "immutable";
import { ApprovalFlowEntityModel, IApprovalFlowEntitySchema } from "./ApprovalFlowEntityModel";
import { ApprovalFlowNotifiedEntityModel, IApprovalFlowNotifiedEntitySchema } from "./ApprovalFlowNotifiedEntityModel";

const defaults = {
	approvers: null as List<ApprovalFlowEntityModel> | null,
	id: "",
	notified: List<ApprovalFlowNotifiedEntityModel>(),
	operator: "" as "or" | "and",
	sortOrder: 0
};

export interface IApprovalFlowRequestSchema {
	approvers: IApprovalFlowEntitySchema[];
	notified: IApprovalFlowNotifiedEntitySchema[];
	operator: "and" | "or";
	sortOrder: number;
}

export class ApprovalFlowRequestModel extends Record<typeof defaults>(defaults) {
	static fromServerData(data: unknown) {
		const { approvers, id, notified, operator, sortOrder } = data as typeof defaults;

		return new ApprovalFlowRequestModel({
			approvers: approvers ? List(approvers.map(approver => ApprovalFlowEntityModel.fromServerData(approver))) : null,
			id,
			notified: List(notified.map(notified => ApprovalFlowNotifiedEntityModel.fromServerData(notified))),
			operator,
			sortOrder
		});
	}

	toServerData(): IApprovalFlowRequestSchema {
		const { approvers, notified, operator, sortOrder } = this;
		return {
			approvers: approvers ? approvers.map(approver => approver.toServerData()).toArray() : [],
			notified: notified.map(notified => notified.toServerData()).toArray(),
			operator,
			sortOrder
		};
	}
}
