import React from "react";
import { makeIcon } from "components/ui/Icon";

export const PendingApprovalIcon = makeIcon(
	<svg viewBox="0 0 24 24" fill="none">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M7.175 10.825C7.95833 11.6083 8.9 12 10 12C11.1 12 12.0417 11.6083 12.825 10.825C13.6083 10.0417 14 9.1 14 8C14 6.9 13.6083 5.95833 12.825 5.175C12.0417 4.39167 11.1 4 10 4C8.9 4 7.95833 4.39167 7.175 5.175C6.39167 5.95833 6 6.9 6 8C6 9.1 6.39167 10.0417 7.175 10.825ZM2.2875 19.7125C2.47917 19.9042 2.71667 20 3 20H11.25C11.05 19.7 10.8667 19.3792 10.7 19.0375C10.5333 18.6958 10.4 18.35 10.3 18H4V17.2C4 17.0167 4.04583 16.85 4.1375 16.7C4.22917 16.55 4.35 16.4333 4.5 16.35C5.1 16.05 5.87083 15.75 6.8125 15.45C7.75417 15.15 8.81667 15 10 15H10.1C10.15 14.65 10.2208 14.3125 10.3125 13.9875C10.4042 13.6625 10.5167 13.35 10.65 13.05C10.55 13.0167 10.45 13 10.35 13H10C8.71667 13 7.54167 13.15 6.475 13.45C5.40833 13.75 4.45 14.1167 3.6 14.55C3.1 14.8 2.70833 15.1667 2.425 15.65C2.14167 16.1333 2 16.65 2 17.2V19C2 19.2833 2.09583 19.5208 2.2875 19.7125ZM11.4125 9.4125C11.0208 9.80417 10.55 10 10 10C9.45 10 8.97917 9.80417 8.5875 9.4125C8.19583 9.02083 8 8.55 8 8C8 7.45 8.19583 6.97917 8.5875 6.5875C8.97917 6.19583 9.45 6 10 6C10.55 6 11.0208 6.19583 11.4125 6.5875C11.8042 6.97917 12 7.45 12 8C12 8.55 11.8042 9.02083 11.4125 9.4125ZM13.4625 19.5375C14.4375 20.5125 15.6167 21 17 21C18.3833 21 19.5625 20.5125 20.5375 19.5375C21.5125 18.5625 22 17.3833 22 16C22 14.6167 21.5125 13.4375 20.5375 12.4625C19.5625 11.4875 18.3833 11 17 11C15.6167 11 14.4375 11.4875 13.4625 12.4625C12.4875 13.4375 12 14.6167 12 16C12 17.3833 12.4875 18.5625 13.4625 19.5375ZM19.35 17.65L18.65 18.35L16.5 16.2V13H17.5V15.8L19.35 17.65Z"
			fill="currentColor"
		/>
	</svg>
);
