export const FADE_CENTER_IN_ANIMATION_NAME = "fade-move-from-center-in";
export const FADE_CENTER_OUT_ANIMATION_NAME = "fade-move-from-center-out";

export const styles = {
	[`@keyframes ${FADE_CENTER_IN_ANIMATION_NAME}`]: {
		"0%": {
			opacity: "0",
			transform: "scale(1.16)"
		},
		"100%": {
			opacity: "1",
			transform: "scale(1)"
		}
	},

	[`@keyframes ${FADE_CENTER_OUT_ANIMATION_NAME}`]: {
		"0%": {
			opacity: "1",
			transform: "scale(1)"
		},
		"100%": {
			opacity: "0",
			transform: "scale(0.92)"
		}
	},
	transitionable: {
		"&.animate": {
			'&[data-animation="fade-move-from-center"]': {
				"&.in": {
					animation: `var(--animation-duration) var(--transition-easing) forwards $${FADE_CENTER_IN_ANIMATION_NAME}`,
					zIndex: "10"
				},

				"&.out": {
					animation: `var(--animation-duration) var(--transition-easing) forwards $${FADE_CENTER_OUT_ANIMATION_NAME}`,
					zIndex: "5"
				},

				"&.reverse": {
					"&.in": {
						animation: `var(--animation-duration) var(--transition-easing-reversed) forwards reverse $${FADE_CENTER_OUT_ANIMATION_NAME}`
					},

					"&.out": {
						animation: `var(--animation-duration) var(--transition-easing-reversed) forwards reverse $${FADE_CENTER_IN_ANIMATION_NAME}`
					}
				}
			}
		}
	}
};
