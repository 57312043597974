import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	cell: {
		alignItems: "center",
		display: "flex",
		minHeight: "3rem"
	},
	lighterText: {
		color: "var(--color-grey-dark)"
	},
	link: {
		color: "var(--typography-link-font-color)"
	},
	row: {
		gridTemplateColumns: "2fr 1f 1fr"
	},
	searchBar: {
		marginBottom: "2rem",
		width: "33%"
	},
	separator: {
		margin: "0 0.5rem"
	},
	spinner: {
		margin: "auto"
	},
	titlePart: {
		alignItems: "center",
		display: "flex",
		flexDirection: "row",
		gap: "var(--spacing-x1, 4px)"
	},
	titleContainer: {
		display: "flex",
		flexDirection: "row",
		flexGrow: 1,
		justifyContent: "space-between"
	},
	inlineInputContainer: {
		display: "flex",
		flexDirection: "column",
		width: "100%"
	},
	inlineInputLabelContainer: {
		alignItems: "center",
		cursor: "pointer",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between"
	},
	ownerListContainer: {
		width: "23rem"
	},
	userInlineOptions: {
		padding: "8px 12px"
	},
	arrowDownInline: {
		visibility: (props: { showArrow?: boolean }) => (props?.showArrow ? "visible" : "hidden")
	},
	list: {
		maxHeight: "400px"
	}
});
