import { createUseStyles } from "react-jss";
import { BP_MEDIA_QUERIES } from "utils/ui/breakpoints";

export const useStyles = createUseStyles({
	pageContentContainer: {},
	pageContentBodyContainer: {
		display: "flex",
		flexDirection: "column",
		gap: "var(--spacing-x19, 76px)",
		alignItems: "flex-start",
		alignSelf: "stretch"
	},
	applyToContainer: {
		alignItems: "flex-start",
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
		gap: "var(--spacing-x5, 20px)",
		[BP_MEDIA_QUERIES.TWENTY]: {
			flexWrap: "nowrap",
			gap: "var(--spacing-x18, 72px)"
		}
	},
	applyToOptionContainer: {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start",
		gap: "var(--spacing-x5, 20px)"
	},
	applyToOptionTitleContainer: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		gap: "var(--spacing-x2, 8px)"
	},
	explanationTooltip: {
		minWidth: "496px"
	},
	clickable: {
		"&:hover": {
			cursor: "pointer"
		}
	}
});
