import classNames from "classnames";
import React, { useMemo } from "react";
import { RequestDateAndNumber } from "components/common/RequestDateAndNumber";
import {
	AdminDeniedStatus,
	CancelledStatus,
	DeniedStatus,
	ExpiredStatus,
	FailedStatus,
	GrantedStatus,
	PendingApprovalStatus,
	PendingManualFixStatus,
	PermissionInProgressStatus
} from "components/common/RequestStatuses";
import { TicketModel } from "models/TicketModel";
import { useStyles } from "./styles";

type TProps = {
	actions?: React.ReactNode;
	request: TicketModel;
	showStatus?: boolean;
	toApprove?: boolean;
};

export const RequestSummary: FC<TProps> = ({ actions, className, innerRef, request, showStatus = false }) => {
	const classes = useStyles();

	const status = useMemo(() => {
		if (!showStatus) return null;
		switch (request.status) {
			case "approved":
			case "granted":
				return <GrantedStatus size="huge" />;
			case "cancelled":
				return <CancelledStatus size="huge" />;
			case "failed":
				return <FailedStatus size="huge" />;
			case "rejected":
				return request.ticketApprovalResponses?.some(response => response.type === "adminDecline") ? (
					<AdminDeniedStatus size="huge" />
				) : (
					<DeniedStatus size="huge" />
				);
			case "revoked":
				// TODO: add manually revoke check, for now we don't have this status
				return <ExpiredStatus size="huge" />;
			case "waitingForApproval":
				return <PendingApprovalStatus size="huge" request={request} />;
			case "waitingForIT":
				return <PendingManualFixStatus size="huge" />;
			case "permissionInProgress":
				return <PermissionInProgressStatus size="huge" />;
			default:
				return null;
		}
	}, [showStatus, request]);

	return (
		<div className={classNames(classes.container, className)} ref={innerRef}>
			<RequestDateAndNumber date={request.createdAt} number={request.number} />
			<div className={classes.statusContainer}>{status}</div>
			<div className={classes.actionsContainer}>{actions}</div>
		</div>
	);
};
