import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	textCell: {
		display: "flex",
		justifyContent: "flex-start",
		alignItems: "center",
		width: "100%",
		height: "100%",
		gap: "var(--spacing-x2)"
	},
	textCellResizeableContent: {
		display: "flex",
		gap: "var(--spacing-x2, 8px)"
	},
	centeredCellContent: {
		display: "flex",
		overflow: "hidden",
		gap: "var(--spacing-x2)",
		justifyContent: "center",
		alignItems: "center",
		width: "100%",
		height: "100%"
	},
	spaceBetweenCellContent: {
		display: "flex",
		overflow: "hidden",
		gap: "var(--spacing-x2)",
		justifyContent: "space-between",
		alignItems: "center",
		width: "100%",
		height: "100%"
	},
	icon: {
		flexShrink: 0,
		height: "20px",
		width: "20px",
		"&$icon > *": {
			height: "100%",
			width: "100%"
		}
	},
	linkContainer: {
		// here we want the ellipsis dots to have this color
		color: "var(--color-blue-500)"
	},
	link: {
		// here we want the link to have this color in all of the html states
		// it's intentionally separated from the linkContainer class because of the tooltip
		color: "var(--color-blue-500)",
		"&:link, :visited, :hover, :active": {
			textDecoration: "none"
		}
	},
	userNameWithEmail: {
		display: "flex",
		flexDirection: "column",
		overflow: "hidden",
		minWidth: 0,
		justifyContent: "center",
		alignItems: "flex-start"
	},
	userImage: {
		height: "32px",
		width: "32px",
		borderRadius: "50%",
		fontSize: "32px",
		"&$compact": {
			height: "24px",
			width: "24px",
			fontSize: "24px"
		}
	},
	userNodeContainer: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-start",
		maxWidth: "100%",
		gap: "var(--spacing-x2)"
	},
	userText: {
		maxWidth: "100%"
	},
	checkbox: {
		margin: 0
	},
	compact: {},
	disabled: {}
});
