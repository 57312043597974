import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CommonAccordion } from "components/common/CommonAccordion";
import { UserEntity } from "components/common/entities/UserEntity";
import { LegacyInheritOwnerOption } from "components/common/InheritOwnerOption";
import { Link } from "components/common/Link";
import { LogoAvatar } from "components/common/LogoAvatar";
import { Button } from "components/ui/Button";
import { HasAccessFromIcon } from "components/ui/Icons/HasAccessFromIcon";
import { TooltipOnOverflow } from "components/ui/legacy/TooltipOnOverflow";
import { Typography } from "components/ui/legacy/Typography";
import { PageSelect } from "components/ui/PageSelect";
import { Table } from "components/ui/Table";
import { useApplications } from "hooks/useApplications";
import { useIntegrations } from "hooks/useIntegrations";
import { useLoadingState } from "hooks/useLoadingState";
import { useLocalPagination } from "hooks/useLocalPagination";
import { useUser } from "hooks/useUser";
import { IntegrationResourceModel, TIntegrationResourceModel } from "models/IntegrationResourceModel";
import { IntegrationResourceRoleModel, TIntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";
import { useStyles } from "./styles";
import type { List } from "immutable";

type THasAccessFromRole = TIntegrationResourceRoleModel & {
	roleId: string;
	roleName: string;
};

type THasAccessFromResource = TIntegrationResourceModel & {
	roleId: string;
	roleName: string;
};

type THasAccessFromItem = THasAccessFromResource | THasAccessFromRole;

interface IReadProps {
	actions?: React.ReactNode;
	integrationResourceRoles: List<IntegrationResourceRoleModel>;
	integrationResource: IntegrationResourceModel;
	manual?: never;
	onDelete?: never;
	resourceName: string;
}

interface IManualProps {
	actions?: React.ReactNode;
	integrationResourceRoles: List<IntegrationResourceRoleModel>;
	integrationResource: IntegrationResourceModel;
	manual: boolean;
	onDelete: (roleId: string, resourceId: string) => Promise<void>;
	resourceName: string;
}

type THasAccessFromProps = IReadProps | IManualProps;

const isRole = (item: THasAccessFromItem): item is THasAccessFromRole => {
	return "integrationResource" in item;
};

const useGetItemResourceData = (item: THasAccessFromItem) => {
	return isRole(item) ? item.integrationResource! : item;
};

const HasAccessFromRow: FC<{
	item: THasAccessFromItem;
	ownIntegrationResource: IntegrationResourceModel;
	onDelete?: (roleId: string, resourceId: string) => Promise<void>;
}> = ({ item: giverItem, ownIntegrationResource, onDelete }) => {
	const resource = useGetItemResourceData(giverItem);
	const integrations = useIntegrations();
	const integration = integrations?.get(resource.integrationId);
	const resourceOwnerId = resource.ownerId;
	const user = useUser(resourceOwnerId || integration?.ownerId);
	const applications = useApplications();
	const classes = useStyles();
	const { t } = useTranslation();
	const { withLoader, isLoading } = useLoadingState();
	const deleteRow = useCallback(async () => {
		if (!onDelete) return;
		await withLoader(onDelete(giverItem.roleId, giverItem.id));
	}, [onDelete, giverItem, withLoader]);
	if (!integration) {
		return null;
	}
	const integrationName = integration.name;
	const integrationImage =
		integration.imageUrl || applications?.find(({ id }) => integration.applicationId === id)?.imageUrl;

	return (
		<Table.Row key={giverItem.id + giverItem.roleId}>
			<Table.Cell className={classes.resource}>
				{integrationImage && (
					<LogoAvatar size="small">
						<img src={integrationImage} />
					</LogoAvatar>
				)}
				<Link className={classes.link} noDecoration to={`/integrations/${integration.id}`}>
					{integrationName}
				</Link>
				/
				<Link className={classes.link} noDecoration to={`/integrations/${integration.id}/resources/${resource.id}`}>
					{resource.name}
				</Link>
				/
				{!isRole(giverItem) ? (
					<Typography> {t("pages.integrationResource.anyRole")} </Typography>
				) : (
					<Link
						className={classes.link}
						noDecoration
						to={`/integrations/${resource.integrationId}/resources/${resource.id}/roles/${giverItem.id}`}>
						{giverItem.name}
					</Link>
				)}
			</Table.Cell>
			<Table.Cell>
				<TooltipOnOverflow
					content={
						<Link
							className={classes.link}
							noDecoration
							to={`/integrations/${ownIntegrationResource.integrationId}/resources/${ownIntegrationResource.id}/roles/${giverItem.roleId}`}>
							{giverItem.roleName}
						</Link>
					}
				/>
			</Table.Cell>
			<Table.Cell>
				{user ? (
					resourceOwnerId ? (
						<UserEntity withIcon user={user} />
					) : (
						<LegacyInheritOwnerOption user={user} emphasis="user" />
					)
				) : (
					<Typography>{t("pages.integrationResource.unknown")}</Typography>
				)}
			</Table.Cell>
			{onDelete && (
				<Table.Cell>
					<div className={classes.cell}>
						<Button variant="text" size="medium" onClick={deleteRow} loading={isLoading}>
							{t("buttons.delete")}
						</Button>
					</div>
				</Table.Cell>
			)}
		</Table.Row>
	);
};

export const HasAccessFromTable: FC<THasAccessFromProps> = ({
	actions,
	integrationResourceRoles,
	integrationResource,
	manual = false,
	onDelete,
	resourceName
}) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const items: List<THasAccessFromItem> = useMemo(() => {
		return integrationResourceRoles
			.filter(role => role.hasAccessFromResources || role.hasAccessFromRoles)
			.flatMap(role => {
				const resources =
					role.hasAccessFromResources
						?.map(
							resource =>
								({
									...(resource.toJS() as TIntegrationResourceModel),
									roleName: role.name,
									roleId: role.id
								}) as THasAccessFromItem
						)
						.toArray() || [];
				const roles =
					role.hasAccessFromRoles
						?.map(
							role =>
								({
									...(role.toJS() as TIntegrationResourceRoleModel),
									roleName: role.name,
									roleId: role.id
								}) as THasAccessFromItem
						)
						.toArray() || [];

				return resources.concat(roles);
			});
	}, [integrationResourceRoles]);

	const headers: string[] = useMemo(() => {
		const baseHeaders = [
			t("pages.integrationResource.hasAccessFromTableHeaders.source"),
			t("pages.integrationResource.hasAccessFromTableHeaders.role", { resourceName }),
			t("pages.integrationResource.hasAccessFromTableHeaders.owner")
		];
		if (manual) baseHeaders.push("");
		return baseHeaders;
	}, [manual, t, resourceName]);

	const { page, currentPageNumber, setCurrentPage, totalPages } = useLocalPagination(items);

	if (items.size === 0 && !manual) {
		return null;
	}

	return (
		<CommonAccordion
			rounded
			noMargin
			title={
				<div className={classes.titleContainer}>
					<Typography variant="h3" prefixIcon={<HasAccessFromIcon />}>
						{t("pages.integrationResource.hasAccessFrom")}
					</Typography>

					{actions ? <div>{actions}</div> : null}
				</div>
			}>
			<Table gridColumns={`3fr 1fr 2fr ${manual ? "15rem" : ""}`}>
				<Table.Row>
					{headers.map(title => (
						<Table.Header key={title}>
							<Typography>{title}</Typography>
						</Table.Header>
					))}
				</Table.Row>
				{page.map(item => (
					<HasAccessFromRow
						key={item.id + item.roleId}
						ownIntegrationResource={integrationResource}
						item={item}
						onDelete={manual ? onDelete : undefined}
					/>
				))}
			</Table>
			{totalPages > 1 && (
				<PageSelect
					changePage={setCurrentPage}
					currentPageNumber={currentPageNumber}
					pagesCount={totalPages}
					pagesShownAmount={3}
				/>
			)}
		</CommonAccordion>
	);
};
