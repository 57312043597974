import classNames from "classnames";
import { List } from "immutable";
import React, { useCallback, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { RulesList } from "components/common/Rules/RulesList";
import { SubmitModal } from "components/common/Stepper/components/SubmitModal";
import { PageTitleContent } from "components/templates/PageTitleContentTemplate";
import { BasicCard } from "components/ui/BasicCard";
import { IconButton } from "components/ui/IconButton";
import { CloseIcon } from "components/ui/Icons/CloseIcon";
import { InfoIcon } from "components/ui/Icons/InfoIcon";
import { Tooltip } from "components/ui/Tooltip";
import { Typography } from "components/ui/Typography";
import { useApplyRuleStep } from "./ApplyRuleStep.hooks";
import { useStyles } from "./styles";
import type { TRuleType } from "models/RuleModel";

type TBuildRuleStepProps = {
	onExit: () => void;
};

type TApplyToProps = {
	applyTo: (withExisting: boolean) => unknown;
	applyToExisting: boolean | undefined;
	ruleType: TRuleType;
};

const ApplyToOptions: FC<TApplyToProps> = React.memo(function ApplyToOptions({ ruleType, applyTo, applyToExisting }) {
	const { t } = useTranslation("translation", { keyPrefix: "pages.createRule.applyRuleStep" });
	const translatedRuleType = t(`ruleType.${ruleType}`);
	const classes = useStyles();

	const applyToWithExisting = useCallback(() => void applyTo(true), [applyTo]);
	const applyToWithoutExisting = useCallback(() => void applyTo(false), [applyTo]);

	return (
		<div className={classes.applyToContainer}>
			<div className={classes.applyToOptionContainer}>
				<div className={classes.applyToOptionTitleContainer}>
					<Typography noWrap variant="body_sb">
						{t("applyToNewAndExisting.title", { ruleType: translatedRuleType })}
					</Typography>
					<Tooltip
						className={classes.explanationTooltip}
						content={t("applyToNewAndExisting.explanation", { ruleType: translatedRuleType })}>
						<IconButton size="medium">
							<InfoIcon />
						</IconButton>
					</Tooltip>
				</div>
				<BasicCard className={classes.clickable} onClick={applyToWithExisting} selected={applyToExisting} size="large">
					<Typography variant="body_sb">
						{t("applyToNewAndExisting.buttonText", { ruleType: translatedRuleType })}
					</Typography>
				</BasicCard>
			</div>
			<div className={classes.applyToOptionContainer}>
				<div className={classes.applyToOptionTitleContainer}>
					<Typography noWrap variant="body_sb">
						{t("applyToNew.title", { ruleType: translatedRuleType })}
					</Typography>
					<Tooltip
						className={classes.explanationTooltip}
						content={t("applyToNew.explanation", { ruleType: translatedRuleType })}>
						<IconButton size="medium">
							<InfoIcon />
						</IconButton>
					</Tooltip>
				</div>
				<BasicCard
					className={classes.clickable}
					onClick={applyToWithoutExisting}
					selected={applyToExisting === false}
					size="large">
					<Typography variant="body_sb">{t("applyToNew.buttonText", { ruleType: translatedRuleType })}</Typography>
				</BasicCard>
			</div>
		</div>
	);
});

export const ApplyRuleStep: FC<TBuildRuleStepProps> = ({ onExit, className }) => {
	const {
		applyToExisting,
		setApplyToExisting,
		rule,
		onSubmit,
		submitError,
		submitSuccess,
		submissionType,
		onCloseSubmitModal,
		isSubmitModalOpen
	} = useApplyRuleStep();
	const classes = useStyles();
	const ref = useRef<HTMLDivElement>(null);
	const { t } = useTranslation("translation", { keyPrefix: "pages.createRule.applyRuleStep" });

	const rules = useMemo(() => List([rule]), [rule]);
	return (
		<PageTitleContent innerRef={ref} className={classNames(className, classes.pageContentContainer)} noTransition>
			<PageTitleContent.Header>
				<PageTitleContent.Header.Top>
					<PageTitleContent.Header.Actions>
						<IconButton size="large">
							<CloseIcon onClick={onExit} />
						</IconButton>
					</PageTitleContent.Header.Actions>
				</PageTitleContent.Header.Top>
				<PageTitleContent.Header.Bottom>
					<PageTitleContent.Header.Title title={t("title")} />
				</PageTitleContent.Header.Bottom>
			</PageTitleContent.Header>
			<PageTitleContent.Body className={classes.pageContentBodyContainer}>
				<SubmitModal
					close={onCloseSubmitModal}
					isOpen={isSubmitModalOpen}
					retry={onSubmit}
					error={submitError}
					success={submitSuccess}
					successMessage={t(`upsertRuleModal.success.${submissionType}`)}
					errorTitle={t(`upsertRuleModal.error.title.${submissionType}`)}
					errorContent={t("upsertRuleModal.error.content")}
					loadingMessage={t(`upsertRuleModal.loading.${submissionType}`)}
					goBackMessage={t("upsertRuleModal.error.goBack")}
				/>
				<ApplyToOptions ruleType={rule.type} applyToExisting={applyToExisting} applyTo={setApplyToExisting} />
				<RulesList rules={rules} />
			</PageTitleContent.Body>
		</PageTitleContent>
	);
};
