import classNames from "classnames";
import { Map } from "immutable";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IntegrationSelectInput } from "components/common/IntegrationSelectInput";
import { IntegrationActorSelectInput } from "components/common/UserIntegrationActorsSelect";
import { Button } from "components/ui/Button";
import { Modal } from "components/ui/Modal";
import { Typography } from "components/ui/Typography";
import { useIntegrations } from "hooks/useIntegrations";
import { IntegrationActorModel } from "models/IntegrationActorModel";
import { IntegrationModel } from "models/IntegrationModel";
import { useStyles } from "./styles";

interface IProps {
	isOpen: boolean;
	loading?: boolean;
	selectedIntegration?: IntegrationModel;
	onClose: () => void;
	actorsByIntegrationId?: Map<string, IntegrationActorModel[]>;
	onSubmit: (integrationActor: IntegrationActorModel) => Promise<void>;
}

export const AddIntegrationActorModal: FC<IProps> = ({
	isOpen,
	onClose,
	onSubmit,
	loading,
	actorsByIntegrationId,
	selectedIntegration
}) => {
	const classes = useStyles();
	const integrations = useIntegrations();
	const { t: accountsT } = useTranslation("translation", { keyPrefix: "pages.user.accountsTab" });
	const { t } = useTranslation();
	const [integration, setIntegration] = useState<IntegrationModel | null>(null);
	const [actor, setActor] = useState<IntegrationActorModel | null>(null);

	const actorIdsToExclude = useMemo(() => {
		if (selectedIntegration) {
			return actorsByIntegrationId?.get(selectedIntegration.id)?.map(actor => actor.id);
		}
		return [];
	}, [selectedIntegration, actorsByIntegrationId]);

	const content = useMemo(() => {
		const options = selectedIntegration
			? Map([[selectedIntegration.id, selectedIntegration]])
			: integrations?.filter(integration => !actorsByIntegrationId?.has(integration.id));

		return (
			<div className={classes.contentContainer}>
				<Typography variant="title_sb">
					{selectedIntegration ? accountsT("addAccounts") : accountsT("addIntegrationAndAccount")}
				</Typography>
				<div className={classNames(classes.contentContainer, classes.inputsContainer)}>
					<IntegrationSelectInput
						label={accountsT("selectIntegration")}
						placeholder={accountsT("selectIntegration")}
						disabled={selectedIntegration !== undefined}
						options={options ?? Map()}
						value={integration}
						onChange={setIntegration}
					/>
					<IntegrationActorSelectInput
						label={accountsT("selectAccount")}
						placeholder={accountsT("selectAccount")}
						disabled={!integration?.id}
						actorIdsToExclude={actorIdsToExclude}
						integrationId={integration?.id ?? ""}
						value={actor}
						onChange={setActor}
					/>
				</div>
			</div>
		);
	}, [
		selectedIntegration,
		integrations,
		classes.contentContainer,
		classes.inputsContainer,
		accountsT,
		integration,
		actorIdsToExclude,
		actor,
		actorsByIntegrationId
	]);

	const actions = useMemo(() => {
		return (
			<>
				<Button size="medium" variant="secondary" onClick={onClose}>
					{t("buttons.cancel")}
				</Button>
				<Button
					size="medium"
					onClick={() => (actor ? onSubmit(actor) : null)}
					disabled={!actor?.id || loading}
					loading={loading}>
					{selectedIntegration ? accountsT("addAccounts") : accountsT("addIntegration")}
				</Button>
			</>
		);
	}, [onClose, t, actor, loading, selectedIntegration, accountsT, onSubmit]);

	useEffect(() => {
		if (!isOpen) {
			setIntegration(null);
			setActor(null);
		}
	}, [isOpen]);

	useEffect(() => {
		if (selectedIntegration) {
			setIntegration(selectedIntegration);
		}
	}, [selectedIntegration]);

	return <Modal className={classes.modal} isOpen={isOpen} onClose={onClose} content={content} actions={actions} />;
};
