import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	loadingDots: {
		alignItems: "center",
		color: "#8c7099",
		display: "flex",
		flexDirection: "column",
		fontSize: "4rem",
		height: "1em",
		justifyContent: "center",
		width: "1em",
		"&.small": {
			height: "0.75em",
			width: "0.75em"
		},
		"&.medium": {
			height: "1em",
			width: "1em"
		},
		"&.big": {
			height: "1.25em",
			width: "1.25em"
		},
		"&.inline": {
			display: "inline-flex",
			fontSize: "1em",
			verticalAlign: "text-top"
		},
		"&.center": {
			margin: "auto"
		}
	},
	dots: {
		animationDuration: "1s",
		"& *": {
			animation: "$dotsAnimation var(--animation-duration) infinite"
		},
		"& :nth-child(1)": {
			transformOrigin: "16px 6px"
		},
		"& :nth-child(2)": {
			transformOrigin: "26px 16px",
			animationDelay: "0.1s"
		},
		"& :nth-child(3)": {
			transformOrigin: "16px 26px",
			animationDelay: "0.2s"
		},
		"& :nth-child(4)": {
			transformOrigin: "6px 16px",
			animationDelay: "0.3s"
		}
	},
	"@keyframes dotsAnimation": {
		"0%,100%": {
			opacity: "1",
			transform: "scale(1)"
		},

		"50%": {
			opacity: "0.4",
			transform: "scale(0.7)"
		}
	}
});
