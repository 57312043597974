import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	formLinkActions: {
		alignItems: "flex-start",
		display: "flex",
		flexDirection: "row",
		padding: "0.5rem 0",
		gap: "0.3rem"
	}
});
