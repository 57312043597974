import anime from "animejs";
import { List, Map as ImmutableMap } from "immutable";
import * as panzoom from "panzoom";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { getGraphIntegrations, getGraphResources, getGraphResourceTypes, getGraphRoles } from "api/identityGraph";
import { useIdentityGraphContext } from "components/pages/IdentityGraphPage/identityGraphContext";
import { useFetchedState } from "hooks/useFetchedState";
import { RoleVertexModel } from "models/IdentityGraph/RoleVertexModel";
import { VertexModel } from "models/IdentityGraph/VertexModel";

export const useGraphIntegrations = () => {
	const { data: integrations, loadData: loadIntegrations, isLoading } = useFetchedState(getGraphIntegrations);

	useEffect(() => {
		void loadIntegrations();
	}, [loadIntegrations]);

	return { integrations, isLoading };
};

export const useGraphResources = (integrationId: string | null, resourceType?: string | null) => {
	const fetchCallback = useCallback(
		async () => (integrationId ? getGraphResources(integrationId, resourceType ?? undefined) : null),
		[integrationId, resourceType]
	);
	const { data: resources, loadData: loadResources, isLoading, setData: setResources } = useFetchedState(fetchCallback);

	useEffect(() => {
		if (integrationId) {
			void loadResources();
		} else {
			setResources(null);
		}
	}, [integrationId, loadResources, setResources]);

	return { resources, isLoading };
};

export const useGraphResourceTypes = (integrationId: string | null) => {
	const fetchCallback = useCallback(
		async () => (integrationId ? getGraphResourceTypes(integrationId) : null),
		[integrationId]
	);
	const {
		data: resourceTypes,
		loadData: loadResourceTypes,
		isLoading,
		setData: setResourceTypes
	} = useFetchedState(fetchCallback);

	useEffect(() => {
		if (integrationId) {
			void loadResourceTypes();
		} else {
			setResourceTypes(null);
		}
	}, [integrationId, loadResourceTypes, setResourceTypes]);

	return { resourceTypes, isLoading };
};

export const useGraphRoles = (resourceId: string | null, withUnmanaged = false) => {
	const fetchCallback = useCallback(
		async () => (resourceId ? getGraphRoles(resourceId, withUnmanaged) : null),
		[resourceId, withUnmanaged]
	);
	const { data: roles, loadData: loadRoles, isLoading, setData: setRoles } = useFetchedState(fetchCallback);

	useEffect(() => {
		if (resourceId) {
			void loadRoles();
		} else {
			setRoles(null);
		}
	}, [resourceId, loadRoles, setRoles]);

	return { roles, isLoading };
};

export const useGroupedData = () => {
	const {
		state: { graphData }
	} = useIdentityGraphContext();

	const groupedByStep = useMemo(() => {
		let grouped = ImmutableMap<number, List<VertexModel>>();
		if (!graphData) return grouped;
		graphData.vertices.forEach(vertex => {
			const step = vertex.step;
			const list = grouped.get(step) || List<VertexModel>();
			grouped = grouped.set(step, list.push(vertex));
		});
		return grouped;
	}, [graphData]);

	const groupedByStepAndIntegrationId: ImmutableMap<number, ImmutableMap<string, List<VertexModel>>> | null =
		useMemo(() => {
			if (!groupedByStep || !groupedByStep.size) return null;
			const grouped = groupedByStep.mapEntries(([step, vertices]) => {
				const stepType = vertices.first()!.type;
				if (stepType === "integration") {
					return [step, ImmutableMap<string, List<VertexModel>>({ integration: vertices })];
				}
				if (stepType !== "role") {
					return [step, ImmutableMap<string, List<VertexModel>>({ [stepType]: vertices })];
				}
				let integrationIdGroupMap = ImmutableMap<string, List<VertexModel>>();
				vertices.forEach(vertex => {
					const integrationId = (vertex as RoleVertexModel).entity.integrationId;
					const list = integrationIdGroupMap.get(integrationId) || List<VertexModel>();
					integrationIdGroupMap = integrationIdGroupMap.set(integrationId, list.push(vertex));
				});
				return [step, integrationIdGroupMap];
			});

			return grouped;
		}, [groupedByStep]);

	return groupedByStepAndIntegrationId;
};

const MAX_SCALE = 3;
const MIN_SCALE = 0.2;

export const useGraphPanAndZoom = (allowActions: boolean) => {
	const {
		state: { elementRef }
	} = useIdentityGraphContext();
	const graphRef = useRef<HTMLDivElement>(null);
	const viewRef = useRef<HTMLDivElement>(null);
	const [cursorStyle, setCursorStyle] = useState<"default" | "grab" | "grabbing">("default");
	const disablePenRef = useRef<boolean>(true);
	const panRef = useRef<panzoom.PanZoom | null>(null);

	const returnToStart = useCallback(() => {
		if (!panRef.current) return;

		// animate reset without smoothZoom and smoothMoveTo because they don't work good together
		const transform = panRef.current.getTransform();

		anime({
			targets: transform,
			easing: "easeOutQuad",
			duration: 200,
			x: 50,
			y: 0,
			scale: 1,
			update: () => {
				panRef.current?.zoomAbs(0, 0, transform.scale);
				panRef.current?.moveTo(transform.x, transform.y);
			}
		});
	}, []);

	useEffect(() => {
		if (viewRef.current) {
			panRef.current = panzoom.default(viewRef.current, {
				beforeWheel: e => {
					if (!disablePenRef.current) return true;
					// deltaMode 0 indicate that the track pad was used
					// we only want to allow zooming with mouse wheel when ctrl is pressed
					return (!allowActions || e.button !== 1) && (!e.ctrlKey || e.deltaMode !== 0);
				},
				beforeMouseDown: e => {
					if (!disablePenRef.current) return true;
					return !allowActions || e.button !== 1;
				},
				minZoom: MIN_SCALE,
				maxZoom: MAX_SCALE
			});

			panRef.current.moveTo(50, 0);
		}
	}, [allowActions]);

	const zoomIn = useCallback(() => {
		if (!allowActions) return;
		panRef.current?.smoothZoom(0, 0, 1.1);
	}, [allowActions]);

	const zoomOut = useCallback(() => {
		if (!allowActions) return;
		panRef.current?.smoothZoom(0, 0, 0.9);
	}, [allowActions]);

	useEffect(() => {
		const element = elementRef.current;
		if (!element) return;

		const onEelementKeyUp = (event: KeyboardEvent) => {
			if (event.code === "Space" && !disablePenRef.current) {
				event.preventDefault();
				disablePenRef.current = true;
				setCursorStyle("default");
			}
		};

		const onElementKeyDown = (event: KeyboardEvent) => {
			if (event.code === "Digit0" && event.ctrlKey) {
				returnToStart();
			} else if (event.code === "Space" && disablePenRef.current) {
				event.preventDefault();
				disablePenRef.current = false;
				setCursorStyle("grab");
			}
		};

		element.addEventListener("keydown", onElementKeyDown);
		element.addEventListener("keyup", onEelementKeyUp);
		return () => {
			element.removeEventListener("keydown", onElementKeyDown);
			element.removeEventListener("keyup", onEelementKeyUp);
		};
	}, [elementRef, returnToStart]);

	useEffect(() => {
		const currentElement = elementRef.current;
		if (!currentElement) return;

		const onMouseDown = (event: MouseEvent) => {
			if (event.button === 0 && !disablePenRef.current) {
				setCursorStyle("grabbing");
			}
		};
		const onMouseUp = (event: MouseEvent) => {
			if (event.button === 0 && !disablePenRef.current) {
				setCursorStyle("grab");
			}
		};
		currentElement.addEventListener("mousedown", onMouseDown);
		currentElement.addEventListener("mouseup", onMouseUp);
		return () => {
			currentElement.removeEventListener("mousedown", onMouseDown);
			currentElement.removeEventListener("mouseup", onMouseUp);
		};
	}, [elementRef]);

	useEffect(() => {
		const view = graphRef.current;
		if (!view) return;

		const onMouseWheel = (event: WheelEvent) => {
			if (!event.ctrlKey) {
				panRef.current?.moveBy(-event.deltaX, -event.deltaY, false);
			}
			event.preventDefault();
		};

		const onMouseMove = (event: MouseEvent) => {
			if (!disablePenRef.current && cursorStyle === "grabbing") {
				event.preventDefault();
				panRef.current?.moveBy(event.movementX, event.movementY, false);
			}
		};
		view.addEventListener("wheel", onMouseWheel);
		view.addEventListener("mousemove", onMouseMove);
		return () => {
			view.removeEventListener("wheel", onMouseWheel);
			view.removeEventListener("mousemove", onMouseMove);
		};
	}, [zoomIn, zoomOut, cursorStyle]);

	useEffect(() => {
		// Reset the zoom when the graph data empties
		if (!allowActions) {
			returnToStart();
		}
	}, [allowActions, returnToStart]);

	return {
		cursorStyle,
		graphRef,
		panRef,
		returnToStart,
		zoomIn,
		zoomOut,
		viewRef
	};
};
