import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	option: {
		alignItems: "center",
		cursor: "pointer",
		display: "flex",
		justifyContent: "flex-start",
		gap: "var(--spacing-x2, 8px)",
		width: "100%",
		paddingLeft: "var(--spacing-x4, 16px)",
		paddingRight: "var(--spacing-x4, 16px)",
		paddingTop: "var(--spacing-x3, 12px)",
		paddingBottom: "var(--spacing-x3, 12px)",
		"&:hover:not($readonly)": {
			background: "var(--color-purple-100, #F7F4F8)"
		},
		"&$selected:not($disabled)": {
			background: "var(--color-purple-200, #EFE8F2)",
			"&:hover": {
				background: "var(--color-purple-300, #DFD2E4)"
			}
		},
		"&$disabled": {
			background: "var(--color-white, #FFF)",
			color: "var(--color-grey-450, #CDCED0)",
			cursor: "default",
			pointerEvents: "none",
			"&$selected": {
				background: "var(--color-purple-200, #EFE8F2)",
				color: "var(--color-grey-450, #CDCED0)"
			}
		}
	},
	selected: {},
	disabled: {},
	readonly: {}
});
