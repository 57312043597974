import classNames from "classnames";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { RoleBar, TRoleBarColumn } from "components/common/RoleBar";
import { RoleBarBundle } from "components/common/RoleBar/components/RoleBarBundle";
import {
	ROLE_BAR_INTEGRATION_WIDTH,
	RoleBarIntegration
} from "components/common/RoleBar/components/RoleBarIntegration";
import { ROLE_BAR_RESOURCE_WIDTH, RoleBarResource } from "components/common/RoleBar/components/RoleBarResource";
import { ROLE_BAR_ROLE_WIDTH, RoleBarRole } from "components/common/RoleBar/components/RoleBarRole";
import { StaticChip } from "components/ui/chips/StaticChip";
import { IconButton } from "components/ui/IconButton";
import { BundleIcon } from "components/ui/Icons/BundleIcon";
import { CloseIcon } from "components/ui/Icons/CloseIcon";
import { TitleTooltip } from "components/ui/TitleTooltip";
import { Typography } from "components/ui/Typography";
import { useBreakpoints } from "hooks/useBreakpoints";
import { useBundles } from "hooks/useBundles";
import { useIntegrations } from "hooks/useIntegrations";
import { notEmpty } from "utils/comparison";
import { Bp } from "utils/ui/breakpoints";
import { useStyles } from "./styles";
import type { TBundleRequestTarget, TRequestTarget, TRoleRequestTarget } from "components/pages/NewRequestPage/types";
import type { IntegrationModel } from "models/IntegrationModel";
import type { IntegrationResourceModel } from "models/IntegrationResourceModel";
import type { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";

const EMPTY_BUNDLE_COLUMN: TRoleBarColumn = {
	content: <RoleBarBundle />
};

const getRoleBarColumns = (
	role: IntegrationResourceRoleModel,
	resource: IntegrationResourceModel,
	integration: IntegrationModel
) => {
	return [
		{
			content: (
				<RoleBarIntegration name={integration.name} imageUrl={integration.imageUrl} isDeleted={integration.isDeleted} />
			),
			width: ROLE_BAR_INTEGRATION_WIDTH
		},
		{
			content: (
				<RoleBarResource
					name={resource.name}
					euid={resource.euid}
					description={resource.description || undefined}
					tags={resource.calculatedTags}
					type={resource.type}
					isDeleted={resource.isDeleted}
				/>
			),
			width: ROLE_BAR_RESOURCE_WIDTH
		},
		{
			content: <RoleBarRole role={role} />,
			width: ROLE_BAR_ROLE_WIDTH
		}
	] as TRoleBarColumn[];
};

type TTargetProps = {
	target: TRequestTarget;
	hasBundles?: boolean;
	removeTarget: (id: string) => void;
	chooseActor?: (id: string) => void;
};

export const RequestTarget: FC<TTargetProps> = ({
	target,
	hasBundles = false,
	removeTarget,
	chooseActor,
	innerRef,
	className
}) => {
	const integrations = useIntegrations();
	const bundles = useBundles();
	const classes = useStyles();
	const { t } = useTranslation();
	const { isBiggerThan: showExternalTooltip } = useBreakpoints(Bp.TWENTY);

	const getRoleRoleBar = useCallback(
		(roleTarget: TRoleRequestTarget): TRoleBarColumn[] => {
			if (!bundles || !integrations) return [];
			const role = roleTarget.fullTarget;
			const resource = role.integrationResource;
			const integration = integrations.get(resource.integrationId);
			if (!integration) return [];
			const roleColumns = getRoleBarColumns(role, resource, integration);
			return hasBundles ? [EMPTY_BUNDLE_COLUMN, ...roleColumns] : roleColumns;
		},
		[bundles, hasBundles, integrations]
	);

	const getBundleRoleBar = useCallback(
		(target: TBundleRequestTarget): TRoleBarColumn[] => {
			if (!bundles || !integrations) return [];
			const bundle = target.fullTarget;
			const roles = bundle.bundleItems
				.map(bundleItem => bundleItem.integrationResourceRole)
				.filter(role => role.managed);
			const resources = roles.map(role => role.integrationResource);
			const uniqueResourcesIds = resources
				.map(resource => resource?.id)
				.filter(notEmpty)
				.toSet();
			const integrationIds = resources
				.map(resource => resource?.integrationId)
				.filter(notEmpty)
				.toSet();

			const tooltipRolesColumns = roles
				.map(role => {
					const resource = role.integrationResource;
					const integration = integrations.get(resource?.integrationId || "");
					if (!integration || !resource) return null;
					return { columns: getRoleBarColumns(role, resource, integration), id: role.id };
				})
				.filter(notEmpty)
				.toArray();

			const tooltipContent = {
				header: (
					<div className={classes.bundleTooltipHeader}>
						<BundleIcon size={24} />
						<Typography variant="body_sb">{bundle.name}</Typography>
						<StaticChip size="small" variant="regular">
							{t("number", { value: roles.size })}
						</StaticChip>
					</div>
				),
				body: tooltipRolesColumns.map(roleBar => <RoleBar key={roleBar.id} columns={roleBar.columns} noInteraction />)
			};

			const bundleColumns = [
				{
					content: <RoleBarBundle name={bundle.name} description={bundle.description} tags={bundle.tags} />
				},
				{
					content: showExternalTooltip ? (
						<TitleTooltip header={tooltipContent.header} body={tooltipContent.body}>
							<RoleBarIntegration withoutTooltip amount={integrationIds.size} />
						</TitleTooltip>
					) : (
						<RoleBarIntegration amount={integrationIds.size} />
					),
					width: ROLE_BAR_INTEGRATION_WIDTH
				},
				{
					content: showExternalTooltip ? (
						<TitleTooltip header={tooltipContent.header} body={tooltipContent.body}>
							<RoleBarResource withoutTooltip amount={uniqueResourcesIds.size} />
						</TitleTooltip>
					) : (
						<RoleBarResource amount={uniqueResourcesIds.size} />
					),
					width: ROLE_BAR_RESOURCE_WIDTH
				},
				{
					content: showExternalTooltip ? (
						<TitleTooltip header={tooltipContent.header} body={tooltipContent.body}>
							<RoleBarRole withoutTooltip amount={roles.size} />
						</TitleTooltip>
					) : (
						<RoleBarRole amount={roles.size} />
					),
					width: ROLE_BAR_ROLE_WIDTH
				}
			] as TRoleBarColumn[];

			return bundleColumns;
		},
		[bundles, classes.bundleTooltipHeader, integrations, t, showExternalTooltip]
	);

	const columns = useMemo(() => {
		if (target.type === "role") {
			return getRoleRoleBar(target);
		} else {
			return getBundleRoleBar(target);
		}
	}, [getBundleRoleBar, getRoleRoleBar, target]);

	const onChooseActorClick = useCallback(() => {
		chooseActor?.(target.id);
	}, [chooseActor, target.id]);

	const onRemoveTargetClick = useCallback(() => {
		removeTarget(target.id);
	}, [removeTarget, target.id]);

	const actions = (
		<IconButton size="medium" onClick={onRemoveTargetClick}>
			<CloseIcon />
		</IconButton>
	);

	return (
		<div className={classNames(classes.targetRow, className)} ref={innerRef}>
			<RoleBar
				selected
				className={classNames({ [classes.chooseActorWrap]: !!chooseActor })}
				noInteraction={!!chooseActor}
				onClick={chooseActor ? onChooseActorClick : undefined}
				columns={columns}
				actions={actions}
			/>
		</div>
	);
};
