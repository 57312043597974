import classNames from "classnames";
import React from "react";
import { TicketRenewalButton } from "components/common/TicketRenewalButton";
import { TicketModel } from "models/TicketModel";
import { useStyles } from "./styles";

type TProps = {
	request: TicketModel;
};

const RequestPastActions: FC<TProps> = ({ className, innerRef, request }) => {
	const classes = useStyles();

	return (
		<div className={classNames(classes.container, className)} ref={innerRef}>
			<TicketRenewalButton ticket={request} />
		</div>
	);
};

const memoizedComponent = React.memo(RequestPastActions);
export { memoizedComponent as RequestPastActions };
