import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import pickBy from "lodash/pickBy";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { createRolesRule, createResourcesRule, updateResourcesRule, updateRolesRule } from "api/rules";
import { useStepperContext } from "components/common/Stepper/stepperContext";
import { useRulesContext } from "components/pages/RulesPage/RulesContext";
import { Button } from "components/ui/Button";
import { GrantedIcon } from "components/ui/Icons/GrantedIcon";
import { useIsOpenState } from "hooks/useIsOpenState";
import { useLoadingState } from "hooks/useLoadingState";
import { Conditions, RuleModel } from "models/RuleModel";
import { Require } from "types/utilTypes";
import { useRuleStepperContext } from "../../ruleStepperContext";

const useUpsertRuleSubmit = () => {
	const { isLoading: submitIsLoading, withLoader: withSubmitLoader } = useLoadingState();
	const [error, setError] = useState<boolean | undefined>(undefined);
	const [success, setSuccess] = useState(false);

	const {
		state: { rule, applyToExisting }
	} = useRuleStepperContext();

	const {
		state: { rulesMap }
	} = useRulesContext();

	const rulePriority = useMemo(
		() => rulesMap.get(rule.id)?.priority ?? rule.priority,
		[rulesMap, rule.id, rule.priority]
	);

	const ruleForCreation = useMemo(
		() =>
			rule
				.set("conditions", new Conditions(rule.conditions.toSeq().filter(condition => !isEmpty(condition?.value))))
				.set(
					"updates",
					pickBy(rule.updates, value => !isNil(value))
				)
				.set("priority", rulePriority) as Require<RuleModel, "type">,
		[rule, rulePriority]
	);

	const onSubmit = useCallback(async () => {
		if (applyToExisting === undefined) return;
		setSuccess(false);
		setError(undefined);
		try {
			let action: (rule: RuleModel, applyToExisting: boolean) => Promise<RuleModel>;
			if (ruleForCreation.type === "resources") {
				action = ruleForCreation.id ? updateResourcesRule : createResourcesRule;
			} else {
				action = ruleForCreation.id ? updateRolesRule : createRolesRule;
			}
			await withSubmitLoader(action(ruleForCreation, applyToExisting));
			setSuccess(true);
		} catch (_error) {
			setError(true);
		}
	}, [applyToExisting, ruleForCreation, withSubmitLoader, setSuccess, setError]);

	return { error, onSubmit, submitIsLoading, success, ruleForCreation };
};

export const useApplyRuleStep = () => {
	const { close, open: openSubmitModal, isOpen: isSubmitModalOpen } = useIsOpenState();

	const { t } = useTranslation("translation", { keyPrefix: "pages.createRule.applyRuleStep" });
	const {
		error: submitError,
		onSubmit,
		submitIsLoading,
		success: submitSuccess,
		ruleForCreation
	} = useUpsertRuleSubmit();
	const {
		actions: { setRulesReadonly, setSelectedRule }
	} = useRulesContext();

	const {
		state: { rule, applyToExisting },
		actions: { setApplyToExisting }
	} = useRuleStepperContext();

	const {
		actions: { setCanContinue, setFooterAction }
	} = useStepperContext();

	useEffect(() => {
		setCanContinue(applyToExisting !== undefined);
	}, [setCanContinue, applyToExisting]);

	useEffect(() => {
		setRulesReadonly(true);
		setSelectedRule(null);
	}, [setRulesReadonly, rule, setSelectedRule]);

	const onSubmitClick = useCallback(() => {
		openSubmitModal();
		void onSubmit();
	}, [onSubmit, openSubmitModal]);

	const translatedRuleType = t(`ruleType.${rule.type}`);

	const submitButton = useMemo(() => {
		const buttonText =
			applyToExisting === true
				? t("applyToNewAndExisting.completeButtonLabel", { ruleType: translatedRuleType })
				: applyToExisting === false
					? t("applyToNew.completeButtonLabel", { ruleType: translatedRuleType })
					: t("apply");
		return (
			<Button
				variant="primary"
				size="large"
				suffix={<GrantedIcon size={24} />}
				onClick={onSubmitClick}
				disabled={applyToExisting === undefined}>
				{buttonText}
			</Button>
		);
	}, [applyToExisting, onSubmitClick, t, translatedRuleType]);

	useEffect(() => {
		setFooterAction("complete", submitButton);
	}, [setFooterAction, submitButton]);

	const onCloseSubmitModal = useCallback(() => {
		close();
	}, [close]);

	const submissionType: "create" | "update" = rule.id ? "update" : "create";

	return {
		submissionType,
		applyToExisting,
		rule: ruleForCreation,
		submitIsLoading,
		submitButton,
		submitError,
		submitSuccess,
		isSubmitModalOpen,
		setApplyToExisting,
		onSubmit,
		onCloseSubmitModal,
		openSubmitModal
	};
};
