import classNames from "classnames";
import React, { MutableRefObject } from "react";
import { createPortal } from "react-dom";
import { useBreakpoint } from "hooks/useBreakpoint";
import { useOnEscKeyDownEvent } from "hooks/useOnEscKeyDownEvent";
import { useStopPropagation } from "hooks/useStopPropagation";
import { Bp } from "utils/ui/breakpoints";
import { useStyles } from "./styles";

type TDrawerProps = {
	open?: boolean;
	size: number;
	parent?: MutableRefObject<HTMLElement | null> | HTMLElement | null;
	absoluteOffset?: {
		bottom?: number;
		top?: number;
	};
	onClose?: () => void;
};

const InnerDrawer: FC<TDrawerProps> = ({ children, className, innerRef, open, size, absoluteOffset, onClose }) => {
	const { isBiggerThan: isHorizontal, isLoading } = useBreakpoint(Bp.FORTY);

	const stopPropagation = useStopPropagation();

	const classes = useStyles({ size, absoluteOffset });

	useOnEscKeyDownEvent(onClose, true);

	return (
		<div
			className={classNames(
				isHorizontal || isLoading ? classes.relativeContainer : classes.absoluteContainer,
				{ [classes.open]: open },
				className
			)}
			ref={innerRef}
			onMouseDown={stopPropagation}
			onClick={stopPropagation}
			key={`${isHorizontal}`}>
			<div className={classes.paddedContent}>{children}</div>
		</div>
	);
};

/**
 * `Drawer` is a functional component that provides a sliding panel (drawer) functionality.
 *
 * Props:
 * - `parent`: Specifies the side of the parent element which the drawer will be anchored for. Default to document body.
 * - `open`: If true, the drawer is open. Defaults to false.
 *
 * @returns The Drawer component.
 */
export const Drawer: FC<TDrawerProps> = ({ open = false, parent, ...innerDrawerProps }) => {
	const element = parent && "current" in parent ? parent.current : parent;
	const anchor = element || document.body;
	return createPortal(<InnerDrawer parent={anchor} open={open} {...innerDrawerProps} />, anchor);
};
