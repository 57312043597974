import constate from "constate";
import { useCallback } from "react";
import { multiGetDirectoryGroups } from "api/directoryGroups";
import { useThrottledBulkFetch } from "hooks/useThrottledBulkFetch";
import type { DirectoryGroupModel } from "models/DirectoryGroupModel";

const useDirectoryGroups = () => {
	const { itemsById, setItemsById, loadIds } = useThrottledBulkFetch(multiGetDirectoryGroups, {
		throttleTime: 50,
		includeDeleted: true
	});

	const addGroups = useCallback(
		(groups: DirectoryGroupModel[]) => {
			const missingGroups = groups.filter(group => !itemsById.has(group.id));
			if (!missingGroups.length) return;
			setItemsById(curr => {
				missingGroups.forEach(group => {
					curr = curr.set(group.id, group);
				});
				return curr;
			});
		},
		[itemsById, setItemsById]
	);

	return { state: { directoryGroupsById: itemsById }, actions: { loadGroups: loadIds, addGroups } };
};

export const [DirectoryGroupsProvider, useDirectoryGroupsContext] = constate(useDirectoryGroups);
