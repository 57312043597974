import React from "react";
import { makeIcon, TIconProps } from "components/ui/Icon";

const ExpiredIcon = makeIcon(
	<svg viewBox="0 0 24 24" fill="none">
		<path
			d="M12 11C13.1 11 14.0417 10.6083 14.825 9.825C15.6083 9.04167 16 8.1 16 7V4H8V7C8 8.1 8.39167 9.04167 9.175 9.825C9.95833 10.6083 10.9 11 12 11ZM19 22H5C4.71667 22 4.47917 21.9042 4.2875 21.7125C4.09583 21.5208 4 21.2833 4 21C4 20.7167 4.09583 20.4792 4.2875 20.2875C4.47917 20.0958 4.71667 20 5 20H6V17C6 15.9833 6.2375 15.0292 6.7125 14.1375C7.1875 13.2458 7.85 12.5333 8.7 12C7.85 11.4667 7.1875 10.7542 6.7125 9.8625C6.2375 8.97083 6 8.01667 6 7V4H5C4.71667 4 4.47917 3.90417 4.2875 3.7125C4.09583 3.52083 4 3.28333 4 3C4 2.71667 4.09583 2.47917 4.2875 2.2875C4.47917 2.09583 4.71667 2 5 2H19C19.2833 2 19.5208 2.09583 19.7125 2.2875C19.9042 2.47917 20 2.71667 20 3C20 3.28333 19.9042 3.52083 19.7125 3.7125C19.5208 3.90417 19.2833 4 19 4H18V7C18 8.01667 17.7625 8.97083 17.2875 9.8625C16.8125 10.7542 16.15 11.4667 15.3 12C16.15 12.5333 16.8125 13.2458 17.2875 14.1375C17.7625 15.0292 18 15.9833 18 17V20H19C19.2833 20 19.5208 20.0958 19.7125 20.2875C19.9042 20.4792 20 20.7167 20 21C20 21.2833 19.9042 21.5208 19.7125 21.7125C19.5208 21.9042 19.2833 22 19 22Z"
			fill="currentColor"
		/>
	</svg>
) as IconComponent & { Orange: IconComponent };

const orangeIcon = (props: TIconProps) => <ExpiredIcon {...props} color="var(--color-orange-500)" />;
orangeIcon.isIconComponent = true as const;
ExpiredIcon.Orange = orangeIcon;

export { ExpiredIcon };
