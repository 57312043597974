import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	title: {
		padding: "0.5em 0 1em 0",
		display: "block",
		"&$border": {
			borderBottom: "1px var(--text-color) solid",
			marginBottom: "1em",
			paddingBottom: "0.25em"
		},
		"&$extraMargin": {
			marginBottom: "2em"
		}
	},
	border: {},
	extraMargin: {}
});
