import React from "react";
import { makeIcon } from "components/ui/Icon";

export const RedoIcon = makeIcon(
	<svg viewBox="0 0 24 24" fill="none">
		<path
			d="M16.2 9.99999H9.9C8.85 9.99999 7.9375 10.3333 7.1625 11C6.3875 11.6667 6 12.5 6 13.5C6 14.5 6.3875 15.3333 7.1625 16C7.9375 16.6667 8.85 17 9.9 17H16C16.2833 17 16.5208 17.0958 16.7125 17.2875C16.9042 17.4792 17 17.7167 17 18C17 18.2833 16.9042 18.5208 16.7125 18.7125C16.5208 18.9042 16.2833 19 16 19H9.9C8.28333 19 6.89583 18.475 5.7375 17.425C4.57917 16.375 4 15.0667 4 13.5C4 11.9333 4.57917 10.625 5.7375 9.57499C6.89583 8.52499 8.28333 7.99999 9.9 7.99999H16.2L14.3 6.09999C14.1167 5.91665 14.025 5.68332 14.025 5.39999C14.025 5.11665 14.1167 4.88332 14.3 4.69999C14.4833 4.51665 14.7167 4.42499 15 4.42499C15.2833 4.42499 15.5167 4.51665 15.7 4.69999L19.3 8.29999C19.4 8.39999 19.4708 8.50832 19.5125 8.62499C19.5542 8.74165 19.575 8.86665 19.575 8.99999C19.575 9.13332 19.5542 9.25832 19.5125 9.37499C19.4708 9.49165 19.4 9.59999 19.3 9.69999L15.7 13.3C15.5167 13.4833 15.2833 13.575 15 13.575C14.7167 13.575 14.4833 13.4833 14.3 13.3C14.1167 13.1167 14.025 12.8833 14.025 12.6C14.025 12.3167 14.1167 12.0833 14.3 11.9L16.2 9.99999Z"
			fill="currentColor"
		/>
	</svg>
);
