import { createUseStyles } from "react-jss";
import { BP_MEDIA_QUERIES } from "utils/ui/breakpoints";

export const useStyles = createUseStyles({
	actionsContainer: {
		alignItems: "flex-end",
		display: "flex",
		gap: "var(--spacing-x3, 12px)",
		justifyContent: "flex-end",
		flexWrap: "wrap",
		[BP_MEDIA_QUERIES.TWENTY]: {
			alignSelf: "initial",
			alignItems: "flex-end",
			flexDirection: "row",
			justifyContent: "space-between",
			flexWrap: "nowrap"
		}
	},
	topSection: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		gap: "var(--spacing-x6, 24px)"
	}
});
