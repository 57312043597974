import { MaintainersIcon } from "components/ui/Icons/MaintainersIcon";
import { OwnerIcon } from "components/ui/Icons/OwnerIcon";
import { RequestsIcon } from "components/ui/Icons/RequestsIcon";
import { WorkflowsIcon } from "components/ui/Icons/WorkflowsIcon";
import type { TConditionName } from "filters/condition.interface";
import type { TRoleOrResourceUpdate } from "models/RuleModel";

export function getChipTypeFromCondition(filterName: TConditionName) {
	if (filterName === "resourceName") {
		return "resource";
	}
	if (filterName === "resourceTags") {
		return "tag";
	}
	if (filterName === "roleName") {
		return "role";
	}
	return undefined;
}

export function getIconByAttribute(attribute: keyof TRoleOrResourceUpdate) {
	switch (attribute) {
		case "approvalAlgorithmId":
			return WorkflowsIcon;
		case "allowsRequests":
			return RequestsIcon;
		case "ownerUserId":
			return OwnerIcon;
		case "maintainers":
			return MaintainersIcon;
		default:
			return undefined;
	}
}
