import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	tooltipHeader: {
		alignItems: "flex-start",
		display: "flex",
		width: "100%",
		flexDirection: "column",
		justifyContent: "center"
	},
	tooltipBody: {
		display: "flex",
		width: "100%"
	}
});
