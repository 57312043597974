import React from "react";
import { useTranslation } from "react-i18next";
import { BasicRoleBar } from "components/common/BasicRoleBar";
import { CommonAccordion } from "components/common/CommonAccordion";
import { VirtualPermissionIcon } from "components/ui/Icons/VirtualPermissionIcon";
import { Typography } from "components/ui/legacy/Typography";
import { LoadingDots } from "components/ui/LoadingDots";
import { Unknown } from "components/ui/Unknown";
import { useIntegrationResourceRole } from "hooks/useIntegrationResourceRole";
import { useIntegrations } from "hooks/useIntegrations";
import { useStyles } from "./styles";

interface IProps {
	virtualizedRoleId: string;
}

export const VirtualizedRoleBlock: FC<IProps> = ({ virtualizedRoleId }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { role, isLoading } = useIntegrationResourceRole(virtualizedRoleId);
	const integrations = useIntegrations();
	const integration = integrations?.get(role?.integrationResource?.integrationId || "");

	const showRoleBar = role?.integrationResource && integration;

	return (
		<CommonAccordion
			rounded
			title={
				<Typography variant="h3" prefixIcon={<VirtualPermissionIcon />}>
					{t("pages.integrationResourceRole.virtualizedRole.title")}
				</Typography>
			}
			detailsClassname={classes.detailsContainer}>
			{!integrations && isLoading ? (
				<LoadingDots className={classes.loading} />
			) : (
				<div className={classes.resource}>
					<Unknown unknown={!showRoleBar}>
						{showRoleBar && (
							<BasicRoleBar selected role={role} integration={integration} resource={role.integrationResource} />
						)}
					</Unknown>
				</div>
			)}
		</CommonAccordion>
	);
};
