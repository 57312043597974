import orderBy from "lodash/orderBy";
import { requirePropertyOf } from "./security";

type TSortable<T, K extends keyof T> = T[K] extends string ? T : never;
type TSortOrder = "asc" | "desc";

export const basicSort = <T, K extends keyof T>(
	options: TSortable<T, K>[],
	keys: K[],
	sortOrder: TSortOrder[] | null = null
) => {
	const lowerValuesByKey = keys.map(key => (option: TSortable<T, K>) => {
		const value = requirePropertyOf(option, key);
		return typeof value === "string" ? value.toLowerCase() : value;
	});
	return orderBy(options, lowerValuesByKey, sortOrder || keys.map(() => "asc"));
};

export const sortByName = <T extends { name: string }>(options: TSortable<T, "name">[]) => {
	return basicSort(options, ["name"]);
};

export const sortByFullName = <T extends { fullName: string }>(options: TSortable<T, "fullName">[]) => {
	return basicSort(options, ["fullName"]);
};

export const sortByDisplayName = <T extends { displayName: string }>(options: TSortable<T, "displayName">[]) => {
	return basicSort(options, ["displayName"]);
};
