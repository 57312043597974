import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	placeholder: {
		color: "var(--color-grey-600)",
		fontSize: "var(--typography-font-size-text)",
		fontStyle: "normal",
		fontWeight: "var(--typography-font-weight-regular)"
	},
	disabledPlaceholder: {
		extend: "placeholder",
		color: "var(--color-grey-400)"
	},
	container: {
		alignItems: "flex-start",
		display: "flex",
		flexDirection: "column",
		flexGrow: 1,
		gap: "var(--spacing-x1, 4px)",
		justifyContent: "flex-start",
		minWidth: "140px"
	},
	top: {
		alignItems: "center",
		display: "flex",
		flexDirection: "column",
		gap: "var(--spacing-x1, 4px)",
		justifyContent: "center",
		"&$disabled": {
			color: "var(--color-grey-400)"
		}
	},
	inputContainer: {
		alignItems: "center",
		cursor: "text",
		display: "grid",
		gridTemplateColumns: "auto 1fr auto",
		gap: "var(--spacing-x2, 8px)",
		width: "100%",
		"&$noPrefix:not($noSuffix)": {
			gridTemplateColumns: "1fr auto"
		},
		"&$noSuffix:not($noPrefix)": {
			gridTemplateColumns: "auto 1fr"
		},
		"&$noPrefix$noSuffix": {
			gridTemplateColumns: "1fr"
		},
		"& > $inputHTMLComponent": {},

		"&$large": {
			height: "44px"
		},
		"&$medium": {
			height: "32px"
		},
		"&$text": {
			backgroundColor: "var(--color-white)",
			borderColor: "var(--color-grey-500)",
			borderStyle: "solid",
			borderWidth: "1px",
			"&$large, &$medium": {
				padding: "var(--spacing-x1, 4px) var(--spacing-x2, 8px)"
			},
			"&$large": {
				borderRadius: "6px"
			},
			"&&$medium": {
				borderRadius: "5px"
			},
			"&:hover:not($disabled), &$focused:not($disabled)": {
				backgroundColor: "var(--color-purple-50)"
			},
			"&$disabled": {
				borderColor: "var(--color-grey-400)",
				color: "var(--color-grey-400)"
			},
			"&$error": {
				borderColor: "var(--color-red-700)",
				borderWidth: "2px"
			}
		},
		"&$search": {
			backgroundColor: "var(--color-white)",
			borderColor: "var(--color-grey-500)",
			borderStyle: "solid",
			borderWidth: "1px",
			"&$large, &$medium": {
				padding: "var(--spacing-x1, 4px) var(--spacing-x3, 12px)"
			},
			"&$large": {
				borderRadius: "36px"
			},
			"&&$medium": {
				borderRadius: "33px"
			},
			"&:hover:not($disabled), &$focused:not($disabled)": {
				backgroundColor: "var(--color-purple-50)"
			},
			"&$disabled": {
				borderColor: "var(--color-grey-400)",
				color: "var(--color-grey-400)"
			},
			"&$error": {
				borderColor: "var(--color-red-700)",
				borderWidth: "2px"
			}
		},
		"&$table": {
			backgroundColor: "transparent",
			"&$large": {
				padding: "var(--spacing-x2, 8px) 0"
			},
			"&&$medium": {
				padding: "var(--spacing-x1, 4px) 0"
			},
			"&$disabled": {
				color: "var(--color-grey-400)"
			}
		},
		transitionDuration: "0.2s",
		transitionProperty: "background-color, border-color, border-width",
		transitionTimingFunction: "ease-in-out",
		willChange: "background-color, border-color, border-width"
	},
	inputHTMLComponent: {
		backgroundColor: "transparent",
		border: "none",
		fontFamily: "inherit",
		fontSize: "var(--typography-font-size-text)",
		fontStyle: "normal",
		fontWeight: "var(--typography-font-weight-semibold)",
		"&$disabled": {
			"&:placeholder": {
				extend: "disabledPlaceholder"
			},
			"&:-ms-input-placeholder": {
				extend: "disabledPlaceholder"
			},
			"&::-webkit-input-placeholder": {
				extend: "disabledPlaceholder"
			},
			"&::-moz-placeholder": {
				extend: "disabledPlaceholder"
			}
		},
		"&:placeholder": {
			extend: "placeholder"
		},
		"&:-ms-input-placeholder": {
			extend: "placeholder"
		},
		"&::-webkit-input-placeholder": {
			extend: "placeholder"
		},
		"&::-moz-placeholder": {
			extend: "placeholder"
		},
		height: "100%",
		outline: "none",
		textOverflow: "ellipsis",
		width: "100%",
		"&$hideInput": {
			width: "0"
		}
	},
	prefix: {
		alignItems: "center",
		display: "flex",
		flexDirection: "row",
		gap: "var(--spacing-x1, 4px)",
		overflow: "auto"
	},
	suffix: {
		alignItems: "center",
		display: "flex",
		flexDirection: "row",
		gap: "var(--spacing-x1, 4px)"
	},
	large: {},
	medium: {},
	text: {},
	search: {},
	table: {},
	focused: {},
	error: {},
	disabled: {},
	hideInput: {},
	noPrefix: {},
	noSuffix: {}
});
