import isEqual from "lodash/isEqual";
import { type DependencyList, type EffectCallback, useEffect, useRef } from "react";

function useDeepCompareMemoize(value: unknown) {
	const ref = useRef<unknown>();

	if (!isEqual(value, ref.current)) {
		ref.current = value;
	}

	return ref.current;
}

export function useDeepCompareEffect(effect: EffectCallback, dependencies: DependencyList) {
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(effect, dependencies.map(useDeepCompareMemoize));
}
