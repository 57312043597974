import { createUseStyles } from "react-jss";
import { BP_MEDIA_QUERIES } from "utils/ui/breakpoints";

export const useStyles = createUseStyles({
	container: {
		display: "none",
		[BP_MEDIA_QUERIES.TWENTY]: {
			display: "flex",
			width: "fit-content",
			alignItems: "center",
			flexDirection: "row",
			justifyContent: "flex-start",
			gap: "var(--spacing-x8, 32px)",
			borderRadius: "8px",
			padding: "var(--spacing-x3, 12px) var(--spacing-x5, 20px)",
			maxWidth: "524px",
			background: "var(--color-purple-100, #F7F4F8)"
		}
	},
	column: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-start",
		alignItems: "flex-start",
		gap: "var(--spacing-x05, 2px)"
	},
	emailColumn: {
		minWidth: "44px"
	},
	roleColumn: {
		minWidth: "64px"
	},
	managerColumn: {
		minWidth: "100px"
	},
	managersContainer: {
		display: "flex",
		height: "100%",
		gap: "var(--spacing-x8, 32px)"
	}
});
