import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { AreYouSureModal } from "components/common/AreYouSureModal";
import { type TUserSelectOption, UserSelect } from "components/common/UserSelect";
import { useNewRequestFormContext } from "components/pages/NewRequestPage/newRequestFormContext";
import { IconButton } from "components/ui/IconButton";
import { InfoIcon } from "components/ui/Icons/InfoIcon";
import { UserNodeOption } from "components/ui/selectOptions/UserNodeOption";
import { Tooltip } from "components/ui/Tooltip";
import { Typography } from "components/ui/Typography";
import { useIsOpenState } from "hooks/useIsOpenState";
import { useUser } from "hooks/useUser";
import { useStyles } from "./styles";
import type { UserModel } from "models/UserModel";

const T_PREFIX = "pages.newRequest.selectRolesStep.requestOnBehalf";

export const RequestOnBehalf: FC = ({ className, innerRef }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const {
		state: { currentUser, receiverUser, requestTargets },
		actions: { changeReceiverUser }
	} = useNewRequestFormContext();
	const { open, isOpen, close } = useIsOpenState();
	const receiverUserModel = useUser(receiverUser?.id, false);
	const [chosenValue, setChosenValue] = useState<UserModel | null>(null);

	const selectValue = useMemo(() => {
		if (!receiverUser || !currentUser || receiverUser.id === currentUser.id) return null;
		return receiverUserModel;
	}, [currentUser, receiverUser, receiverUserModel]);

	const label = useMemo(() => {
		const tooltipText = t(`${T_PREFIX}.tooltip`);
		const labelText = t(`${T_PREFIX}.inputLabel`);

		return (
			<div className={classes.label}>
				<Typography variant="text_sm_reg">{labelText}</Typography>
				<Tooltip className={classes.labelTooltip} content={tooltipText}>
					<IconButton size="medium">
						<InfoIcon />
					</IconButton>
				</Tooltip>
			</div>
		);
	}, [classes.label, classes.labelTooltip, t]);

	const changeReceiverUserToChosen = useCallback(() => {
		changeReceiverUser(chosenValue ?? currentUser);
		close();
	}, [changeReceiverUser, chosenValue, close, currentUser]);

	const handleValueChange = useCallback(
		(user: TUserSelectOption | null) => {
			if (typeof user === "string") return;
			setChosenValue(user);
			if (requestTargets.size > 0) {
				open();
			} else {
				changeReceiverUser(user ?? currentUser);
			}
		},
		[changeReceiverUser, currentUser, open, requestTargets.size]
	);

	useEffect(() => {
		if (!receiverUser && currentUser) {
			changeReceiverUser(currentUser);
		}
	}, [changeReceiverUser, currentUser, receiverUser]);

	return (
		<>
			<AreYouSureModal
				isOpen={isOpen}
				onClose={close}
				onAction={changeReceiverUserToChosen}
				actionLabel={t(`${T_PREFIX}.areYouSureModal.actionLabel`)}
				closeLabel={t("buttons.cancel")}
				title={t(`${T_PREFIX}.areYouSureModal.title`)}
				content={t(`${T_PREFIX}.areYouSureModal.content`)}
			/>
			<UserSelect
				className={className}
				disabledIds={currentUser?.id ? [currentUser.id] : undefined}
				innerRef={innerRef}
				label={label}
				onChange={handleValueChange}
				renderOption={UserNodeOption}
				placeholder={t(`${T_PREFIX}.placeholder`)}
				value={selectValue}
			/>
		</>
	);
};
