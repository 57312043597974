import { AbortError } from "utils/errors/abortError";

export async function ignoreAbortError<T>(promise: Promise<T>, handleResult: (result: T) => void) {
	try {
		const result = await promise;
		handleResult(result);
	} catch (error) {
		if (!(error instanceof Error) || error.message !== "Request aborted" || !(error instanceof AbortError)) {
			throw error;
		}
	}
}
