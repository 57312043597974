import React from "react";
import { makeIcon } from "components/ui/Icon";

export const AsteriskIcon = makeIcon(
	<svg viewBox="0 0 24 24" fill="none">
		<rect width="100%" height="100%" rx="50%" fill="currentColor" />
		<path
			d="m16.813 8.053 1.155 2.085L13.508 12 18 13.83l-1.22 2.148L12.9 13.155 13.412 18H11.005l0.48 -4.845 -3.881 2.856 -1.252 -2.183L10.815 11.97 6.353 10.17l1.188 -2.15L11.518 10.875 11.005 6h2.439l-0.546 4.877 3.915 -2.823Z"
			fill="white"
		/>
	</svg>
);
