import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	navigationBackButton: {
		left: "0",
		position: "absolute"
	},
	navigationContainer: {
		alignItems: "center",
		display: "flex",
		gap: "1.25rem",
		height: "100%"
	},
	navigationHeader: {
		alignItems: "center",
		display: "flex",
		justifyContent: "center",
		position: "relative",
		width: "100%"
	},
	doneButton: {
		right: "0",
		position: "absolute"
	}
});
