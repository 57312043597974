import React from "react";

export const JiraLogo: FC = ({ className }) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" className={className}>
		<path
			d="M21.1123 11.4403L12.5062 2.83421L11.672 2L5.19364 8.47835L2.23165 11.4403C1.92278 11.7492 1.92278 12.2508 2.23165 12.5597L8.15034 18.4784L11.672 22L18.1503 15.5216L18.2507 15.4213L21.1123 12.5597C21.4212 12.2508 21.4212 11.7492 21.1123 11.4403ZM11.672 14.9567L8.71528 12L11.672 9.04329L14.6287 12L11.672 14.9567Z"
			fill="#2684FF"
		/>
		<path
			d="M11.672 9.04328C9.73698 7.10822 9.72642 3.972 11.6509 2.02374L5.18048 8.49153L8.70213 12.0132L11.672 9.04328Z"
			fill="url(#paint0_linear_1_196)"
		/>
		<path
			d="M14.6366 11.9921L11.6719 14.9567C13.6176 16.8997 13.6176 20.0517 11.6746 21.9973L11.6719 22L18.1582 15.5137L14.6366 11.9921Z"
			fill="url(#paint1_linear_1_196)"
		/>
		<defs>
			<linearGradient
				id="paint0_linear_1_196"
				x1="11.142"
				y1="6.05093"
				x2="7.01322"
				y2="10.1798"
				gradientUnits="userSpaceOnUse">
				<stop offset="0.18" stopColor="#0052CC" />
				<stop offset="1" stopColor="#2684FF" />
			</linearGradient>
			<linearGradient
				id="paint1_linear_1_196"
				x1="12.2362"
				y1="17.9134"
				x2="16.3571"
				y2="13.7925"
				gradientUnits="userSpaceOnUse">
				<stop offset="0.18" stopColor="#0052CC" />
				<stop offset="1" stopColor="#2684FF" />
			</linearGradient>
		</defs>
	</svg>
);
