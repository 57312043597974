import { NotificationIntegrationModel } from "models/NotificationIntegrationModel";
import { compiledClientConfig } from "../config";

export type TNotificationIntegration =
	| {
			integration?: NotificationIntegrationModel;
			onDisconnect?: (id: string) => void;
	  }
	| {
			integration?: never;
			onDisconnect?: never;
	  };

const ADMIN_CONSENT_URL = "https://login.microsoftonline.com/organizations/v2.0/adminconsent";
const ADMIN_CONSENT_SCOPES = "https://graph.microsoft.com/.default";
const { teamsClientId, teamsRedirectUrl, slackClientId } = compiledClientConfig;

export const getSlackLink = (companyId: string) =>
	`https://slack.com/oauth/v2/authorize?client_id=${slackClientId}&scope=app_mentions:read,channels:manage,chat:write,commands,im:write,team:read,users:read,users:read.email,workflow.steps:execute,channels:history,groups:history,mpim:history,im:history&user_scope=&state=${companyId}`;

export const getTeamsLink = (companyId: string) =>
	`${ADMIN_CONSENT_URL}?client_id=${teamsClientId}&redirect_uri=${teamsRedirectUrl}&scope=${ADMIN_CONSENT_SCOPES}&state=${companyId}`;
