import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	actions: {
		display: "flex",
		flexDirection: "column",
		gap: "12px",
		alignItems: "flex-end"
	},
	error: {
		display: "flex",
		gap: "0.25rem",
		alignItems: "center",
		color: "var(--color-red-dark)"
	},
	description: {
		whiteSpace: "pre-line"
	}
});
