import { Record as ImmutableRecord } from "immutable";
import type { TBulkActionUpdateData } from "context/adminLiveUpdatesContext";

const defaults = {
	totalItems: 0,
	model: "integration" as TBulkActionUpdateData["model"],
	updates: {} as TBulkActionUpdateData["updates"],
	progress: 0
};

export class BulkActionModel extends ImmutableRecord<typeof defaults>(defaults) {
	static fromServerData(data: TBulkActionUpdateData): BulkActionModel {
		return new BulkActionModel({
			totalItems: data.totalItems,
			model: data.model,
			updates: data.updates,
			progress: data.progressPercentage
		});
	}
}
