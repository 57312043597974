import classNames from "classnames";
import React, { useCallback, useMemo } from "react";
import { AccountChip } from "components/ui/chips/AccountChip";
import { BundleChip } from "components/ui/chips/BundleChip";
import { Chip, TChipVariant, TChipSize } from "components/ui/chips/Chip";
import { GroupChip } from "components/ui/chips/GroupChip";
import { HiddenChip, THiddenChipType } from "components/ui/chips/HiddenChip";
import { RoleChip } from "components/ui/chips/RoleChip";
import { StaticChip } from "components/ui/chips/StaticChip";
import { TagChip } from "components/ui/chips/TagChip";
import { ResourcesIcon } from "components/ui/Icons/ResourcesIcon";
import { Typography } from "components/ui/legacy/Typography";
import { Tooltip } from "components/ui/Tooltip";
import { BundleModel } from "models/BundleModel";
import { DirectoryGroupModel } from "models/DirectoryGroupModel";
import { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";
import { OnCallIntegrationScheduleModel } from "models/OnCallIntegrationScheduleModel";
import { getOptionKey } from "utils/ui/select";
import { useStyles } from "./styles";
import type { IRenderChipParams } from "../..";

export type TChipType =
	| "regular"
	| "static"
	| "bundle"
	| "tag"
	| "user"
	| "account"
	| "integration"
	| "resource"
	| "group"
	| "role"
	| "workflow"
	| "owner"
	| "maintainer"
	| "allowRequests"
	| "hidden";

interface IChipsOptions<T> {
	getOptionLabel: (option: T) => string;
	multiLine?: boolean;
	noCollapse?: boolean;
	onClick?: () => void;
	onRemove?: (option: T) => void;
	ChipElement?: FC<IRenderChipParams<T>>;
	LimitChip?: FC<IRenderChipParams<string>>;
	limitChipSize?: TChipSize;
	values: T[] | null;
	limit?: number;
	readonly?: boolean;
	collapsedChipsValuesClassName?: string;
	readonlyValues?: T[];
	size?: TChipSize;
	variant?: TChipVariant;
	collapsedValuesClassName?: string;
	tooltipClassName?: string;
	disableTooltip?: boolean;
	ChipPrefixIcon?: IconComponent;
	type?: TChipType;
	limitChipType?: THiddenChipType;
	selected?: boolean;
}

export const Chips = <T,>(options: TProps<IChipsOptions<T>>) => {
	const {
		getOptionLabel,
		noCollapse = false,
		onClick,
		onRemove,
		ChipElement,
		values,
		limit = 2,
		readonly = false,
		readonlyValues,
		size = "small",
		limitChipSize,
		variant = "regular",
		type,
		ChipPrefixIcon: PrefixIcon,
		limitChipType,
		collapsedValuesClassName,
		tooltipClassName,
		disableTooltip = false,
		selected = false
	} = options;
	const classes = useStyles();

	const renderChip = useCallback(
		(option: T, handleRemoveChip: ((option: T) => void) | undefined, noBorder?: boolean, stretch?: boolean) => {
			const removeChip = handleRemoveChip ? () => handleRemoveChip(option) : undefined;
			const key = getOptionKey(option, getOptionLabel) || "";
			const readonlyChip = readonly || readonlyValues?.includes(option);
			const label = getOptionLabel(option);

			const getChip = () => {
				switch (type) {
					case "static":
						return (
							<StaticChip key={key} variant={variant} size={size} selected={selected}>
								{label}
							</StaticChip>
						);
					case "tag":
						return (
							<TagChip key={key} variant={variant} size={size} selected={selected} onDelete={removeChip}>
								{label}
							</TagChip>
						);
					case "account":
						return (
							<AccountChip key={key} variant={variant} size={size} selected={selected} onDelete={removeChip}>
								{label}
							</AccountChip>
						);
					case "resource":
						return (
							<StaticChip key={key} variant={variant} size={size} selected={selected} PrefixIcon={ResourcesIcon}>
								{label}
							</StaticChip>
						);
					case "role":
						return <RoleChip key={key} integrationResourceRole={option as IntegrationResourceRoleModel} />;
					case "bundle":
						return <BundleChip key={key} bundle={option as BundleModel} />;

					case "group":
						return <GroupChip key={key} value={option as DirectoryGroupModel | OnCallIntegrationScheduleModel} />;
					default:
						return (
							<Chip
								key={key}
								variant={variant}
								PrefixIcon={PrefixIcon}
								size={size}
								onClick={onClick}
								onDelete={removeChip}
								selected={selected}>
								{label}
							</Chip>
						);
				}
			};

			const className = classNames(classes.chip, readonlyChip ? classes.readonlyChip : "");
			return ChipElement ? ( // We are handling this function as component because it may have hooks, and if we treat it as function it will fire hooks exception
				<ChipElement
					className={className}
					componentKey={key}
					key={key}
					stretch={stretch}
					noBorder={noBorder}
					option={option}
					onRemove={removeChip}
					onClick={onClick}
				/>
			) : (
				getChip()
			);
		},
		[
			getOptionLabel,
			readonly,
			readonlyValues,
			ChipElement,
			onClick,
			classes.chip,
			classes.readonlyChip,
			size,
			type,
			variant,
			selected,
			PrefixIcon
		]
	);

	const renderPlusChip = useCallback(() => {
		return (
			<HiddenChip
				selected={selected}
				size={limitChipSize || size}
				type={limitChipType}
				variant={variant}>{`+${values ? values.length - limit : 0}`}</HiddenChip>
		);
	}, [selected, limitChipSize, size, limitChipType, variant, values, limit]);

	const chips = useMemo(() => {
		if (!values) {
			return null;
		}
		return values.slice(0, noCollapse ? undefined : limit).map(value => renderChip(value, onRemove));
	}, [limit, noCollapse, onRemove, renderChip, values]);

	if (!chips) return null;
	return (
		<>
			{chips}
			{!(values && values.length > limit) ? null : !noCollapse && !disableTooltip ? (
				<Tooltip
					className={tooltipClassName}
					trigger="hover"
					content={
						<CollapsedValues
							values={values.slice(limit)}
							renderChip={renderChip}
							onRemove={onRemove}
							stretchChips
							readonly
							className={collapsedValuesClassName}
						/>
					}>
					{renderPlusChip()}
				</Tooltip>
			) : (
				renderPlusChip()
			)}
		</>
	);
};

interface ICollapsedValuesProps<T> {
	onRemove?: (option: T) => void;
	renderChip: (
		option: T,
		onRemove?: (option: T) => void,
		noBorder?: boolean,
		stretch?: boolean
	) => string | React.JSX.Element;
	values: T[];
	stretchChips?: boolean;
	readonly?: boolean;
	className?: string;
}

const CollapsedValues = <T,>(props: ICollapsedValuesProps<T>) => {
	const { onRemove, renderChip, values, stretchChips, className } = props;
	const classes = useStyles();
	const chips = useMemo(
		() => values.map(value => renderChip(value, onRemove, true, stretchChips)),
		[onRemove, renderChip, stretchChips, values]
	);
	return (
		<Typography component="div" className={classNames(classes.collapsedValues, className)}>
			{chips}
		</Typography>
	);
};
