import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	sortButtonInvisible: {
		visibility: "hidden"
	},
	virtualTableHeaderContent: {
		alignItems: "center",
		display: "flex",
		gap: "1rem",
		minHeight: "2.35rem",
		height: "100%",
		width: "fit-content",
		overflow: "hidden",
		wordBreak: "keep-all",
		whiteSpace: "nowrap"
	},
	tableHeaderContent: {
		alignItems: "center",
		display: "flex",
		gap: "1rem",
		width: "100%",
		height: "100%"
	},
	tableHeader: {
		"&:hover": {
			backgroundColor: "#F7F4F8"
		}
	}
});
