import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	approverList: {
		display: "flex",
		flexDirection: "column",
		gap: "8px",
		paddingLeft: "8px"
	},
	notice: {
		maxWidth: "23rem"
	},
	modalContent: {
		display: "flex",
		flexDirection: "column",
		gap: "20px"
	},
	modal: {
		paddingLeft: "20px"
	}
});
