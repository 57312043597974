import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	actionsContainer: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		gap: "var(--spacing-x1)",
		marginTop: "var(--spacing-x3)",
		flexWrap: "wrap"
	},
	actionsText: {
		wordBreak: "auto-phrase",
		textAlign: "center"
	},
	actionsCell: {
		gap: "var(--spacing-x1)",
		flexDirection: "column"
	},
	cell: {
		alignItems: "center",
		display: "flex",
		justifyContent: "flex-start",
		padding: "var(--spacing-x5)",
		"&$actionsCell": {
			justifyContent: "center"
		},
		"&$nameCell": {
			alignItems: "flex-start"
		}
	},
	header: {
		extend: "cell",
		height: "40px"
	},
	disabledWebhook: {
		color: "var(--color-red-700)"
	},
	nameCell: {},
	cellTextArea: {
		background: "var(--color-almost-white)",
		height: "160px"
	},
	tableSection: {
		overflow: "hidden"
	},
	table: {
		minWidth: "1100px"
	},
	tableContainer: {
		overflow: "auto",
		height: "100%",
		width: "100%"
	}
});
