import React from "react";

export const OneLoginLogo: FC = ({ className }) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" className={className}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM9.60585 8.84597H10.8997H12.9271C13.0249 8.8461 13.1186 8.88499 13.1877 8.9541C13.2568 9.02322 13.2957 9.11693 13.2958 9.21467V15.421C13.2957 15.5188 13.2568 15.6125 13.1877 15.6816C13.1186 15.7507 13.0249 15.7896 12.9271 15.7897H11.2684C11.1707 15.7896 11.077 15.7507 11.0079 15.6816C10.9388 15.6125 10.8999 15.5188 10.8997 15.421V11.2421H9.60585C9.5081 11.2419 9.41439 11.203 9.34528 11.1339C9.27616 11.0648 9.23727 10.9711 9.23714 10.8734V9.21467C9.23727 9.11693 9.27616 9.02322 9.34528 8.9541C9.41439 8.88499 9.5081 8.8461 9.60585 8.84597Z"
			fill="#1F1F1F"
		/>
	</svg>
);
