import React from "react";

export const FreshteamLogo: FC = ({ className }) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" className={className}>
		<path
			d="M2 12.0266C2 6.48907 6.48907 2 12.0266 2H19.5466C20.931 2 22.0532 3.12227 22.0532 4.50666V12.0266C22.0532 17.5642 17.5642 22.0532 12.0266 22.0532C6.48907 22.0532 2 17.5642 2 12.0266Z"
			fill="#7052CC"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12.0266 11.024C13.1341 11.024 14.0319 10.1261 14.0319 9.01863C14.0319 7.91112 13.1341 7.01331 12.0266 7.01331C10.9191 7.01331 10.0213 7.91112 10.0213 9.01863C10.0213 10.1261 10.9191 11.024 12.0266 11.024ZM12.0266 17.0399C15.0847 17.0399 16.3882 14.5082 16.3882 14.5082C16.3882 14.5082 15.1349 11.9765 12.0266 11.9765C8.91837 11.9765 7.66504 14.5082 7.66504 14.5082C7.66504 14.5082 8.9685 17.0399 12.0266 17.0399Z"
			fill="white"
		/>
	</svg>
);
