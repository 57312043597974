import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	logoContainer: {
		display: "flex",
		alignItems: "center",
		flexDirection: "row",
		justifyContent: "flex-start",
		gap: "var(--spacing-x3, 12px)",
		height: "24px"
	},
	beyondTrustLogoContainer: {
		display: "flex",
		justifyContent: "center",
		alignItems: "flex-end",
		gap: "var(--spacing-x1, 4px)"
	}
});
