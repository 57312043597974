import { Map } from "immutable";
import React, { useCallback, useMemo } from "react";
import { Select } from "components/ui/Select";
import { IntegrationOption } from "components/ui/selectOptions/IntegrationOption";
import { IntegrationModel } from "models/IntegrationModel";
import { sortByName } from "utils/sortUtils";
import { IntegrationEntity } from "../entities";

interface IProps {
	disabled?: boolean;
	filter?: (integration: IntegrationModel) => boolean;
	label: string;
	limit?: number;
	loading?: boolean;
	onChange: (value: IntegrationModel | null) => void;
	options: Map<string, IntegrationModel> | null;
	placeholder?: string;
	required?: boolean;
	value: IntegrationModel | null;
}

export const IntegrationSelectInput: FC<IProps> = ({
	className,
	disabled = false,
	filter,
	label,
	limit,
	loading = false,
	onChange,
	options,
	placeholder,
	required = false,
	value
}) => {
	const selectOptions = useMemo(
		() =>
			options
				?.toList()
				.toArray()
				.filter(option => option.resourcesCount > 0) || [],
		[options]
	);

	const getLabel = useCallback((option: IntegrationModel) => option.name, []);
	const isEqual = useCallback(
		(option: IntegrationModel | null, value: IntegrationModel | null) => option?.id === value?.id,
		[]
	);

	const filteredIntegrations = useMemo(
		() => (filter ? selectOptions.filter(integration => filter(integration)) : selectOptions),
		[filter, selectOptions]
	);

	const renderLabel = (option: IntegrationModel) => (
		<IntegrationEntity inactive={disabled} noWrap relative withIcon size="medium" integration={option} />
	);

	return (
		<Select
			className={className}
			disabled={disabled}
			getOptionLabel={getLabel}
			isOptionEqualToValue={isEqual}
			label={label}
			limit={limit}
			loading={loading}
			onChange={onChange}
			options={filteredIntegrations}
			placeholder={placeholder}
			renderLabel={renderLabel}
			required={required}
			sort={sortByName}
			value={value}
			renderOption={IntegrationOption}
		/>
	);
};
