import classNames from "classnames";
import { Map } from "immutable";
import React, { useCallback, useMemo } from "react";
import { CloseIcon } from "components/ui/Icons/CloseIcon";
import { TooltipOnOverflow } from "components/ui/TooltipOnOverflow";
import { TTypographyVariant, Typography } from "components/ui/Typography";
import { useStyles } from "./styles";

export type TChipSize = "small" | "medium" | "large";
export type TChipVariant = "regular" | "light" | "green" | "red" | "white";

const REGULAR_TEXT_VARIANT_MAP = Map<TChipSize, TTypographyVariant>({
	small: "text_tny_reg",
	medium: "text_sm_reg",
	large: "text_reg"
});

const SEMIBOLD_TEXT_VARIANT_MAP = Map<TChipSize, TTypographyVariant>({
	small: "text_tny_sb",
	medium: "text_sm_sb",
	large: "text_title_sb"
});

const SMALL_ICON_SIZE = 12;
const ICON_SIZE = 16;
export interface IChipProps {
	disabled?: boolean;
	inactive?: boolean;
	noShrink?: boolean;
	noTooltip?: boolean;
	onClick?: (e: React.MouseEvent) => void;
	onDelete?: () => void;
	onSuffixIconClick?: (e: React.MouseEvent) => void;
	PrefixIcon?: IconComponent;
	readonly?: boolean;
	selected?: boolean;
	size?: TChipSize;
	SuffixIcon?: IconComponent;
	variant?: TChipVariant;
}

export const Chip: FC<IChipProps> = ({
	children,
	className,
	disabled = false,
	innerRef,
	inactive = false,
	noShrink = false,
	noTooltip = false,
	onClick: onChipClick,
	onDelete,
	onSuffixIconClick,
	PrefixIcon,
	readonly = false,
	selected = false,
	size = "medium",
	SuffixIcon,
	variant = "regular"
}) => {
	const classes = useStyles();
	const { sizeClass, iconSize } = useMemo(() => {
		switch (size) {
			case "small":
				return { sizeClass: classes.small, iconSize: SMALL_ICON_SIZE };
			case "large":
				return { sizeClass: classes.large, iconSize: ICON_SIZE };
			case "medium":
			default:
				return { sizeClass: classes.medium, iconSize: ICON_SIZE };
		}
	}, [classes.large, classes.medium, classes.small, size]);

	const { colorClass, iconColorClass } = useMemo(() => {
		switch (variant) {
			case "light":
				return { colorClass: classes.light, iconColorClass: classes.lightIcon };
			case "green":
				return { colorClass: classes.green, iconColorClass: classes.whiteIcon };
			case "red":
				return { colorClass: classes.red, iconColorClass: classes.whiteIcon };
			case "white":
				return { colorClass: classes.white, iconColorClass: classes.regularIcon };
			case "regular":
			default:
				return { colorClass: classes.regularColor, iconColorClass: classes.regularIcon };
		}
	}, [classes, variant]);

	const getSuffix = useCallback(() => {
		if (onDelete) {
			return (
				<CloseIcon
					className={classNames(iconColorClass, classes.clickable)}
					clickable={false}
					disabled={disabled}
					onClick={onDelete}
					size={iconSize}
				/>
			);
		} else if (SuffixIcon) {
			return (
				<SuffixIcon
					className={iconColorClass}
					clickable={false}
					disabled={disabled}
					onClick={onSuffixIconClick}
					size={iconSize}
				/>
			);
		}
		return null;
	}, [onDelete, iconColorClass, disabled, iconSize, SuffixIcon, onSuffixIconClick, classes.clickable]);

	const suffix = getSuffix();
	return (
		<div
			ref={innerRef}
			data-interrupt-parent-tooltip // this is used to interrupt chip tooltip on PrefixIcon tooltip
			className={classNames(
				classes.chip,
				colorClass,
				sizeClass,
				{
					[classes.clickable]: !!onChipClick && !disabled,
					[classes.disabled]: disabled,
					[classes.inactive]: inactive,
					[classes.noShrink]: noShrink,
					[classes.readOnly]: readonly,
					[classes.selected]: selected,
					[classes.chipWithOneIcon]: (PrefixIcon || suffix) && !(PrefixIcon && suffix),
					[classes.chipWithTwoIcons]: PrefixIcon && suffix
				},
				className
			)}
			onClick={!disabled ? onChipClick : undefined}>
			{PrefixIcon && <PrefixIcon size={iconSize} />}
			{!noTooltip ? (
				<TooltipOnOverflow
					className={classes.chipText}
					textVariant={selected ? SEMIBOLD_TEXT_VARIANT_MAP.get(size) : REGULAR_TEXT_VARIANT_MAP.get(size)}
					content={children}
				/>
			) : (
				<Typography
					noWrap={true}
					className={classes.chipText}
					variant={selected ? SEMIBOLD_TEXT_VARIANT_MAP.get(size) : REGULAR_TEXT_VARIANT_MAP.get(size)}>
					{children}
				</Typography>
			)}
			{suffix}
		</div>
	);
};
