import constate from "constate";
import { List } from "immutable";
import { useCallback, useEffect, useMemo, useState } from "react";

/*
 * Keeps track of all the listeners that are listening for the escape key down event
 * Only the last listener is triggered when the escape key is pressed
 * Always listen flag can be used for specific listeners that should always be listening (Sidebars, Drawer, etc)
 */
const useEscKeyDownEventListener = () => {
	const [listeners, setListeners] = useState(List<{ id: string; callback: () => void; alwaysListen: boolean }>());
	const listenersToTrigger = useMemo(
		() => listeners.filter((listener, i) => listener.alwaysListen || i === listeners.size - 1),
		[listeners]
	);
	const listenersToTriggerCount = listenersToTrigger.size;

	const onKeyDown = useCallback(
		(event: KeyboardEvent) => {
			if (!listenersToTriggerCount || event.code !== "Escape") return;

			event.preventDefault();
			event.stopPropagation();
			listenersToTrigger.forEach(({ callback }) => callback());
		},
		[listenersToTrigger, listenersToTriggerCount]
	);

	useEffect(() => {
		if (!listenersToTriggerCount) return;

		document.addEventListener("keydown", onKeyDown);
		return () => {
			document.removeEventListener("keydown", onKeyDown);
		};
	}, [listenersToTriggerCount, onKeyDown]);

	const addEventListener = useCallback(
		(id: string, callback: () => void, alwaysListen = false) =>
			setListeners(prev => prev.push({ id, callback, alwaysListen })),
		[]
	);
	const removeEventListener = useCallback(
		(id: string) =>
			setListeners(prev => {
				const index = prev.findIndex(listener => listener.id === id);
				if (index === -1) return prev;
				return prev.delete(index);
			}),
		[]
	);

	return useMemo(
		() => ({
			actions: { addEventListener, removeEventListener }
		}),
		[addEventListener, removeEventListener]
	);
};

export const [EscKeyDownEventListenerProvider, useEscKeyDownEventListenerContext] =
	constate(useEscKeyDownEventListener);
