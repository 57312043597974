import classNames from "classnames";
import React, { useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { notEmpty } from "utils/comparison";
import { dayjs } from "utils/dates/dayjs";
import { useStyles } from "./styles";
import { Button } from "../Button";
import { Description, Errors } from "../fieldHelpers";
import { RefreshIcon } from "../Icons/RefreshIcon";
import { Typography } from "../legacy/Typography";
import { Select } from "../Select";
import { TextOption } from "../selectOptions/TextOption";

interface IProps {
	clearable?: boolean;
	defaultMax?: boolean;
	disabled?: boolean;
	errors?: string[];
	hint?: string;
	label?: string;
	maxValue: Date;
	minValue: Date;
	onChange: (value: Date | null) => void;
	value: Date | null;
}

type TMonth = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;

const daysInMonths = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
const isLeapYear = (year: number) => year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0);

const getDaysInMonth = (year: number, month: TMonth) =>
	month === 1 && isLeapYear(year || 0) ? 29 : daysInMonths.at(month)!;
const getArrayInSize = (size: number) => new Array<number>(size).fill(0);
const getMaxDate = (year: number, month?: TMonth, day?: number) =>
	new Date(
		year,
		month === undefined ? 11 : month,
		day === undefined ? getDaysInMonth(year, month === undefined ? 11 : month) : day,
		23,
		59,
		59,
		999
	);

const getOptionLabel = ({ label }: { label: string }) => label;

export const DateInput: FC<IProps> = ({
	className,
	clearable,
	defaultMax,
	disabled,
	errors,
	hint,
	label,
	maxValue,
	minValue,
	onChange,
	value
}) => {
	const { t } = useTranslation();
	const classes = useStyles();

	const year = useMemo(() => (value ? { value: value.getFullYear(), label: `${value.getFullYear()}` } : null), [value]);
	const month = useMemo(
		() =>
			value ? { value: value.getMonth() as TMonth, label: t(`dateTime.months.${value.getMonth() as TMonth}`) } : null,
		[t, value]
	);
	const day = useMemo(() => (value ? { value: value.getDate(), label: `${value.getDate()}` } : null), [value]);

	const yearOptions = useMemo(() => {
		const minYear = minValue.getFullYear();
		const maxYear = maxValue.getFullYear();
		return getArrayInSize(maxYear - minYear + 1).map((_, index) => ({
			value: maxYear - index,
			label: `${maxYear - index}`
		}));
	}, [maxValue, minValue]);

	const monthOptions = useMemo(
		() =>
			year
				? getArrayInSize(12)
						.map((_, index) => {
							const monthValue = index as TMonth;
							const minDateInMonth = new Date(year.value, monthValue, 1);
							const daysAmount = getDaysInMonth(year.value, monthValue);
							const maxDateInMonth = new Date(year.value, monthValue, daysAmount, 23, 59, 59, 999);
							if (dayjs(maxDateInMonth).isBefore(minValue) || dayjs(minDateInMonth).isAfter(maxValue)) return null;
							return { value: monthValue, label: t(`dateTime.months.${monthValue}`) };
						})
						.filter(notEmpty)
				: [],
		[maxValue, minValue, t, year]
	);

	const dayOptions = useMemo(() => {
		if (!year || !month) return [];

		const daysAmount = getDaysInMonth(year.value, month.value);
		return getArrayInSize(daysAmount)
			.map((_, index) => {
				const minDateInDay = new Date(year.value, month.value, index + 1);
				const maxDateInDay = getMaxDate(year.value, month.value, index + 1);
				if (dayjs(maxDateInDay).isBefore(minValue) || dayjs(minDateInDay).isAfter(maxValue)) return null;
				return { value: index + 1, label: `${index + 1}` };
			})
			.filter(notEmpty);
	}, [maxValue, minValue, month, year]);

	const onYearChange = useCallback(
		(value: { value: number; label: string } | null) => {
			if (!value) onChange(null);
			else {
				const minMonth = value.value === minValue.getFullYear() ? minValue.getMonth() : 0;
				const maxMonth = value.value === maxValue.getFullYear() ? maxValue.getMonth() : 11;
				let isInvalidMonth = false;
				if (month) isInvalidMonth = month.value < minMonth || month.value > maxMonth;
				const defaultMonth = defaultMax ? maxMonth : minMonth;
				const newMonthValue = (isInvalidMonth ? defaultMonth : month?.value || defaultMonth) as TMonth;

				const minDay = value.value === minValue.getFullYear() && newMonthValue === minMonth ? minValue.getDate() : 1;
				const maxDay =
					value.value === maxValue.getFullYear() && newMonthValue === maxMonth
						? maxValue.getDate()
						: getDaysInMonth(value.value, newMonthValue);
				let isInvalidDay = false;
				if (day)
					isInvalidDay =
						(month?.value === 2 && day?.value === 29 && !isLeapYear(value?.value || 1)) ||
						day.value > minDay ||
						day.value > maxDay;
				const defaultDay = defaultMax ? maxDay : minDay;
				const newDayValue = isInvalidDay ? defaultDay : day?.value || defaultDay;

				const newValue = defaultMax
					? getMaxDate(value.value, newMonthValue, newDayValue)
					: new Date(value.value, newMonthValue, newDayValue);
				onChange(newValue);
			}
		},
		[day, defaultMax, maxValue, minValue, month, onChange]
	);
	const onMonthChange = useCallback(
		(value: { value: TMonth; label: string } | null) => {
			if (!year || !value) return;
			const minMonth = year.value === minValue.getFullYear() ? minValue.getMonth() : 0;
			const maxMonth = year.value === maxValue.getFullYear() ? maxValue.getMonth() : 11;

			const minDay = year.value === minValue.getFullYear() && value.value === minMonth ? minValue.getDate() : 1;
			const maxDay =
				year.value === maxValue.getFullYear() && value.value === maxMonth
					? maxValue.getDate()
					: getDaysInMonth(year.value, value.value);
			let isInvalidDay = false;
			isInvalidDay = day ? day.value < minDay || day.value > maxDay : false;
			const defaultDay = defaultMax ? maxDay : minDay;
			const newDayValue = isInvalidDay ? defaultDay : day?.value || defaultDay;

			if (year) {
				const newValue = defaultMax
					? getMaxDate(year.value, value.value, newDayValue)
					: new Date(year.value, value.value, newDayValue);
				onChange(newValue);
			}
		},
		[day, defaultMax, maxValue, minValue, onChange, year]
	);
	const onDayChange = useCallback(
		(selectedDay: { value: number; label: string } | null) => {
			if (!year || !month || !selectedDay) return;
			const newDay = selectedDay.value;
			const newValue = defaultMax
				? getMaxDate(year.value, month.value, newDay)
				: new Date(year.value, month.value, newDay);
			onChange(newValue);
		},
		[defaultMax, month, onChange, year]
	);

	const reset = useCallback(() => {
		if (clearable) onYearChange(null);
		else {
			const defaultValue = defaultMax ? maxValue : minValue;
			onChange(defaultValue);
		}
	}, [clearable, defaultMax, maxValue, minValue, onChange, onYearChange]);

	return (
		<div className={classNames(className, classes.container)}>
			<div className={classes.textContainer}>
				<Typography variant="small">{label}</Typography>
				<Description description={hint} disabled={disabled} />
				<Errors errorMessages={errors || null} />
			</div>
			<div className={classNames(className, classes.inputsContainer)}>
				<Select
					className={classes.yearInput}
					disabled={disabled}
					getOptionLabel={getOptionLabel}
					hideClear
					limit={yearOptions.length}
					onChange={onYearChange}
					renderOption={TextOption}
					options={yearOptions}
					sort={null}
					value={year}
				/>
				<Select
					className={classes.monthInput}
					disabled={!year}
					getOptionLabel={getOptionLabel}
					hideClear
					limit={monthOptions.length}
					onChange={onMonthChange}
					renderOption={TextOption}
					options={monthOptions}
					sort={null}
					value={month}
				/>
				<Select
					className={classes.dayInput}
					disabled={!month}
					getOptionLabel={getOptionLabel}
					hideClear
					limit={dayOptions.length}
					onChange={onDayChange}
					renderOption={TextOption}
					options={dayOptions}
					sort={null}
					value={day}
				/>
				<Button variant="secondary" size="small" onClick={reset} prefix={<RefreshIcon />}>
					{clearable ? t("buttons.clear") : t("buttons.reset")}
				</Button>
			</div>
		</div>
	);
};
