import React from "react";
import { useTranslation } from "react-i18next";
import { getIntegrationLogsFields } from "api/sessionAuditLogs";
import { FilterExpressionEmptyState } from "components/common/FilterExpressionEmptyState";
import { TextOption } from "components/ui/selectOptions/TextOption";
import { getLabel } from "utils/ui/select";
import { IntegrationLogsMultiSelectFilterExpression } from "../IntegrationLogsMultiSelectFilterExpression";

type TTargetsFilterExpressionProps = {
	onRemove: () => void;
};

export const TargetsFilterExpression: FC<TTargetsFilterExpressionProps> = ({ onRemove }) => {
	const { t } = useTranslation();

	const emptyState = <FilterExpressionEmptyState text={t("pages.auditLog.integrationLogs.filter.targetEmptyState")} />;

	return (
		<IntegrationLogsMultiSelectFilterExpression
			fetchOptions={getIntegrationLogsFields}
			filterEmptyState={emptyState}
			filterField="target_display_name"
			filterName="target"
			getOptionLabel={getLabel}
			inputPlaceholder={t("pages.auditLog.integrationLogs.filter.targetPlaceholder")}
			onFilterRemove={onRemove}
			optionRenderer={TextOption}
			title={t("pages.auditLog.integrationLogs.filter.target")}
		/>
	);
};
