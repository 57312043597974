import React from "react";

export const SlackLogo: FC = ({ className }) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" className={className}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M9.31527 7.36951H3.99507C2.8932 7.36951 2 8.26271 2 9.36458C2 10.4665 2.8932 11.3597 3.99507 11.3597H9.31527C10.4171 11.3597 11.3103 10.4665 11.3103 9.36458C11.3103 8.26271 10.4171 7.36951 9.31527 7.36951Z"
			fill="#36C5F0"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M16.6305 9.36459V4.04439C16.6305 2.94252 15.7373 2.04932 14.6355 2.04932C13.5336 2.04932 12.6404 2.94252 12.6404 4.04439V9.36459C12.6404 10.4665 13.5336 11.3597 14.6355 11.3597C15.7373 11.3597 16.6305 10.4665 16.6305 9.36459Z"
			fill="#2EB67D"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M14.6355 16.6798H19.9557C21.0575 16.6798 21.9507 15.7866 21.9507 14.6848C21.9507 13.5829 21.0575 12.6897 19.9557 12.6897H14.6355C13.5336 12.6897 12.6404 13.5829 12.6404 14.6848C12.6404 15.7866 13.5336 16.6798 14.6355 16.6798Z"
			fill="#ECB22E"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M7.32019 14.6848V20.005C7.32019 21.1068 8.21339 22 9.31526 22C10.4171 22 11.3103 21.1068 11.3103 20.005V14.6848C11.3103 13.5828 10.4171 12.6897 9.31526 12.6897C8.21339 12.6897 7.32019 13.5829 7.32019 14.6848Z"
			fill="#E01E5A"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M9.31526 2.04932C8.21339 2.04932 7.32019 2.94252 7.32019 4.04439C7.32019 5.14626 8.21339 6.03946 9.31526 6.03946H11.3103V4.04439C11.3103 2.94252 10.4171 2.04932 9.31526 2.04932Z"
			fill="#36C5F0"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M21.9507 9.36458C21.9507 8.26271 21.0575 7.36951 19.9556 7.36951C18.8538 7.36951 17.9606 8.26271 17.9606 9.36458V11.3597H19.9556C21.0575 11.3597 21.9507 10.4665 21.9507 9.36458Z"
			fill="#2EB67D"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M14.6355 22C15.7373 22 16.6305 21.1068 16.6305 20.005C16.6305 18.9031 15.7373 18.0099 14.6355 18.0099H12.6404V20.005C12.6404 21.1068 13.5336 22 14.6355 22Z"
			fill="#ECB22E"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M2 14.6848C2 15.7866 2.8932 16.6798 3.99507 16.6798C5.09695 16.6798 5.99015 15.7866 5.99015 14.6848V12.6897H3.99507C2.8932 12.6897 2 13.5829 2 14.6848Z"
			fill="#E01E5A"
		/>
	</svg>
);
