import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	actions: {
		justifyContent: "center"
	},
	container: {
		alignItems: "center",
		backgroundColor: "var(--color-white)",
		borderRadius: "var(--border-radius-semi-rounded)",
		display: "flex",
		flexDirection: "column",
		gap: "1rem",
		padding: "1rem"
	},
	containerHeader: {
		alignItems: "baseline",
		display: "flex",
		gap: "1rem",
		width: "100%"
	},
	status: {
		display: "flex",
		alignItems: "center",
		gap: "0.5rem"
	},
	table: {
		width: "100%"
	},
	deleteButton: {
		width: "2rem",
		height: "2rem"
	},
	description: {
		border: "none",
		overflow: "auto",
		padding: "0 0.3rem 0 0",
		"& textarea": {
			cursor: "pointer"
		}
	}
});
