import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	container: {
		background: "var(--color-almost-white)",
		padding: "1rem 1.5rem 1.5rem",
		border: "1px solid var(--color-grey-light)",
		borderRadius: "var(--border-radius-sharp)",
		width: "fit-content",
		height: "fit-content",
		display: "flex",
		gap: "1rem",
		flexDirection: "column",
		overflow: "auto",
		"&$vertical": {
			maxHeight: "100%",
			overflowX: "hidden",
			minWidth: "20rem"
		},
		"&$horizontal": {
			maxWidth: "70rem"
		},
		"&$noBorder": {
			border: "none"
		},
		"&$noBackground": {
			background: "none"
		}
	},
	title: {
		paddingBottom: "0.5rem",
		fontSize: "1.1em"
	},
	approvalFlowContainer: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		"&$vertical": {
			flexDirection: "column",
			gap: "1.15rem",
			width: "100%",
			height: "100%"
		},
		"&$horizontal": {
			flexDirection: "row",
			gap: "1.5rem",
			width: "fit-content",
			height: "fit-content"
		}
	},
	approvedContainer: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center"
	},
	approved: {
		display: "flex",
		justifyContent: "center",
		background: "var(--color-grey-background)",
		padding: "0.4rem 0.5rem",
		borderRadius: "var(--border-radius-semi-rounded)",
		gap: "0.35rem"
	},
	approvedText: {
		wordBreak: "normal"
	},
	horizontal: {},
	vertical: {},
	noBorder: {},
	noBackground: {}
});
