import classNames from "classnames";
import React, { useMemo } from "react";
import { Avatar } from "components/common/Avatar";
import { ImageGrid } from "components/common/ImageGrid/ImageGrid";
import { Block } from "components/ui/Block";
import { Typography } from "components/ui/legacy/Typography";
import { EntitleLogo } from "components/ui/systemLogos/EntitleLogo";
import { useAuditLogImages } from "hooks/useAuditLogImages";
import { useUser } from "hooks/useUser";
import { dateToShortText } from "utils/dates/date";
import { useStyles } from "./styles";
import { AuditLogContent } from "../AuditLogContent";
import type { TAuditLogModel } from "models/auditLogs";
import type { TTimezone } from "utils/auditLogs/systemAuditLogTimezone";

const isImagesArray = (images: string[] | FC[] | null): images is string[] => {
	return Array.isArray(images) && (images.length === 0 || typeof images.at(0) === "string");
};

export const AuditLog: FC<AuditLineProps> = ({ auditLog, timezone = "local", className }) => {
	const formattedDate = useMemo(() => dateToShortText(auditLog?.createdAt, timezone), [auditLog?.createdAt, timezone]);
	const logUser = useUser(auditLog?.userId);
	const classes = useStyles();
	const images = useAuditLogImages(auditLog);
	const imagesArray = useMemo(() => (isImagesArray(images) ? images : undefined), [images]);
	const Logo = useMemo(() => (images?.length && !isImagesArray(images) ? images.at(0) : undefined), [images]);

	return (
		<Block className={classNames(classes.auditLog, className)}>
			<div className={classes.images}>
				{imagesArray && <ImageGrid images={imagesArray} imageGridClass={classes.integrationImage} />}
				<span className={classes.userImage}>
					{logUser ? (
						<Avatar size="large" imageUrl={logUser.imageUrl!} fullName={logUser.fullName} />
					) : Logo && !imagesArray ? (
						<Logo className={classes.appIcon} />
					) : (
						<EntitleLogo className={classes.appIcon} />
					)}
				</span>
			</div>
			<div className={classes.textContainer}>
				<Typography className={classes.date}>{formattedDate}</Typography>
				<Typography className={classes.message}>
					<AuditLogContent auditLog={auditLog} logUser={logUser} />
				</Typography>
			</div>
		</Block>
	);
};

type AuditLineProps = {
	auditLog: TAuditLogModel;
	timezone?: TTimezone;
};
