import classNames from "classnames";
import React, { ReactNode } from "react";
import { IconButton, TIconButtonProps } from "components/ui/IconButton";
import { useStyles } from "./styles";
import type { IRowOptions } from "../../types";

const IconButtonsCellContent: FC<
	{
		icons: (Omit<TProps<TIconButtonProps>, "children"> & { icon: ReactNode })[];
	} & IRowOptions
> = ({ icons, className }) => {
	const classes = useStyles();

	return (
		<div className={classNames(className, classes.centeredCellContent)}>
			{icons.map(({ icon, className: buttonClassName, ...buttonProps }, index) => (
				<IconButton key={index} className={classNames(classes.icon, buttonClassName)} {...buttonProps}>
					{icon}
				</IconButton>
			))}
		</div>
	);
};

const IconButtonsCellContentMemo = React.memo(IconButtonsCellContent) as typeof IconButtonsCellContent;

export { IconButtonsCellContentMemo as IconButtonsCellContent };
