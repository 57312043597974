import React from "react";

export const MicrosoftLogo: FC = ({ className }) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" className={className}>
		<rect x="12.4762" y="2" width="9.52382" height="9.52382" fill="#4BB400" />
		<rect x="12.4762" y="12.4762" width="9.52382" height="9.52382" fill="#FEB102" />
		<rect x="2" y="2" width="9.52382" height="9.52382" fill="#FF481E" />
		<rect x="2" y="12.4762" width="9.52382" height="9.52382" fill="#0297D4" />
	</svg>
);
