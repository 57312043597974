import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	actions: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-end"
	},
	addResourceButtonContainer: {
		display: "flex",
		paddingLeft: (props?: { hasItems?: boolean }) => (props?.hasItems ? "1.1rem" : "0"),
		paddingBottom: "1.1rem"
	},
	addResourceButton: {
		backgroundColor: "var(--color-white)",
		borderRadius: "var(--border-radius-rounder)"
	},
	modal: {
		minHeight: "35%",
		width: "800px"
	},
	modalContent: {
		display: "flex",
		flexDirection: "column",
		gap: "20px",
		minWidth: "400px"
	},
	resourcesAndRoles: {
		display: "flex",
		flexDirection: "column",
		gap: "0.5rem",
		backgroundColor: (props?: { hasItems?: boolean }) =>
			props?.hasItems ? "var(--surface-content-contrast-background-color)" : "inherit",
		borderRadius: "var(--border-radius-semi-rounded)"
	}
});
