import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { TicketingIntegrationSelect } from "components/common/TicketingIntegrationSelect";
import { useNewTicketFormContext } from "components/pages/NewTicketPage/newTicketContext";
import { Checkbox } from "components/ui/Checkbox";
import { Form } from "components/ui/Form";
import { InfoIcon } from "components/ui/Icons/InfoIcon";
import { Typography } from "components/ui/legacy/Typography";
import { Tooltip } from "components/ui/Tooltip";
import { useStyles } from "./styles";

export const ConnectTo3rdPartyTicketField: FC = () => {
	const {
		state: {
			formState: { ticketingIntegrationTicketId, connectToThirdParty }
		},
		actions: {
			formActions: { setConnectToThirdParty, setTicketingIntegrationTicketId }
		}
	} = useNewTicketFormContext();

	const { t } = useTranslation(undefined, { keyPrefix: "pages.newTicket.newTicketForm.connectThirdParty" });
	const classes = useStyles();

	const toggleConnectToThirdParty = useCallback(() => {
		setConnectToThirdParty(value => {
			if (value) setTicketingIntegrationTicketId(null);
			return !value;
		});
	}, [setTicketingIntegrationTicketId, setConnectToThirdParty]);

	useEffect(() => {
		if (ticketingIntegrationTicketId && !connectToThirdParty) setConnectToThirdParty(true);
	}, [connectToThirdParty, setConnectToThirdParty, ticketingIntegrationTicketId]);

	const closeInput = useCallback(() => {
		if (connectToThirdParty) toggleConnectToThirdParty();
	}, [connectToThirdParty, toggleConnectToThirdParty]);

	return (
		<>
			<Form.Field>
				<Checkbox
					className={classes.checkbox}
					label={
						<div className={classes.checkboxLabel}>
							<Typography>{t("checkbox")}</Typography>
							<Tooltip content={t("tooltip")}>
								<InfoIcon />
							</Tooltip>
						</div>
					}
					onClick={toggleConnectToThirdParty}
					selected={connectToThirdParty}
				/>
				{connectToThirdParty && (
					<TicketingIntegrationSelect
						placeholder={t("placeholder")}
						onChange={setTicketingIntegrationTicketId}
						value={ticketingIntegrationTicketId}
						onTicketNotFound={closeInput}
					/>
				)}
			</Form.Field>
		</>
	);
};
