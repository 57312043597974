import classNames from "classnames";
import React, { createContext, useMemo } from "react";
import { useControlled } from "hooks/useControlled";
import { useStyles } from "./styles";
import { Collapse } from "../Collapse";

interface IAccordionProps {
	defaultExpanded?: boolean;
	disabled?: boolean;
	expanded?: boolean;
	onChange: (expanded: boolean) => void;
	rounded?: boolean;
	noMargin?: boolean;
}

type TAccordionContext = {
	expanded: boolean;
	disabled: boolean;
	onToggle: (event: React.MouseEvent) => void;
};

export const AccordionContext = createContext<TAccordionContext>({
	expanded: false,
	disabled: false
} as TAccordionContext);

export const Accordion: FC<IAccordionProps> = ({
	children: childrenProp,
	className,
	defaultExpanded = false,
	disabled = false,
	expanded: expandedProp,
	rounded = true,
	noMargin = false,
	onChange: userOnChange
}) => {
	const [expanded, setExpanded] = useControlled<boolean>({
		controlled: expandedProp,
		default: defaultExpanded
	});
	const classes = useStyles();

	const onChange = React.useCallback(() => {
		setExpanded(!expanded);

		if (userOnChange) {
			userOnChange(!expanded);
		}
	}, [expanded, userOnChange, setExpanded]);

	const [summary, ...children] = React.Children.toArray(childrenProp);

	const context = useMemo(() => ({ expanded, disabled, onToggle: onChange }), [expanded, disabled, onChange]);

	return (
		<div className={classNames(classes.container, { [classes.disabled]: disabled })}>
			<div
				className={classNames(
					classes.accordion,
					{ [classes.rounded]: rounded, [classes.noMargin]: noMargin },
					className
				)}>
				<AccordionContext.Provider value={context as TAccordionContext}>{summary}</AccordionContext.Provider>
				<Collapse open={expanded || false}>{children}</Collapse>
			</div>
		</div>
	);
};
