import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { BasicRoleBar } from "components/common/BasicRoleBar";
import { Chip } from "components/ui/chips/Chip";
import { StaticChip } from "components/ui/chips/StaticChip";
import { PermissionsIcon } from "components/ui/Icons/PermissionsIcon";
import { TitleTooltip } from "components/ui/TitleTooltip";
import { Typography } from "components/ui/Typography";
import { useStyles } from "./styles";
import type { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";

type TProps = {
	permissions: IntegrationResourceRoleModel[];
};

const TOOLTIP_OFFSET = [-45, 6] as [number, number];
export const PermissionsCell: FC<TProps> = ({ permissions }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const permissionsTooltipHeader = useMemo(() => {
		return (
			<div className={classes.tooltipTitle}>
				<Typography variant="body_med"> {t("pages.auditLog.integrationLogs.columns.permissions")}</Typography>
				<StaticChip size="small" variant="regular">
					{t("number", { value: permissions.length })}
				</StaticChip>
			</div>
		);
	}, [classes.tooltipTitle, permissions.length, t]);

	const permissionsTooltipBody = useMemo(
		() => permissions.map((permission, index) => <BasicRoleBar noInteractions key={index} role={permission} />),
		[permissions]
	);

	return permissions.length > 0 ? (
		<TitleTooltip
			className={classes.tooltipBodyContainer}
			header={permissionsTooltipHeader}
			body={permissionsTooltipBody}
			placement="top-end"
			offset={TOOLTIP_OFFSET}>
			<div className={classes.permissionChip}>
				<Chip PrefixIcon={PermissionsIcon} size="small">
					{permissions.length}
				</Chip>
			</div>
		</TitleTooltip>
	) : null;
};
