import React, { useMemo } from "react";
import { GroupIcon } from "components/ui/Icons/GroupIcon";
import { useDirectoryGroupInfo } from "hooks/useGroupInfo";
import { EntityNodeContent } from "../components/EntityNodeContent";
import { DeletedIndicator } from "../components/indicators/DeletedIndicator";
import { NameWithEmail } from "../components/NameWithEmail";
import { NameWithEmailNodeContent } from "../components/NameWithEmailNodeContent";
import { EntityLabel } from "../EntityLabel";
import type { TBaseEntityNodeProps } from "../types";

type TProps = TBaseEntityNodeProps & {
	directoryGroup: { name: string; email?: string | null; isDeleted?: boolean };
	withIcon?: boolean;
	size: "small" | "medium";
};
export const DirectoryGroupEntity: FC<TProps> = ({
	directoryGroup,
	withIcon,
	size,
	className,
	inactive: propInactive,
	...entityNodeProps
}) => {
	const indicators = useMemo(() => {
		const indicators: React.ReactNode[] = [];
		if (directoryGroup.isDeleted) {
			indicators.push(<DeletedIndicator />);
		}

		return indicators;
	}, [directoryGroup.isDeleted]);

	const { name, Icon } = useDirectoryGroupInfo(directoryGroup.name);

	const inactive = propInactive || directoryGroup.isDeleted;

	const content = useMemo(
		() =>
			directoryGroup.email ? (
				<NameWithEmailNodeContent
					text={<NameWithEmail name={name} email={directoryGroup.email} />}
					withIcon={withIcon}
					size="medium"
					icon={Icon ? <Icon /> : <GroupIcon />}
					inactive={inactive}
					{...entityNodeProps}
				/>
			) : (
				<EntityNodeContent
					tooltipOnOverflow
					text={name}
					withIcon={withIcon}
					size={size}
					icon={Icon && <Icon />}
					inactive={inactive}
					{...entityNodeProps}
				/>
			),
		[name, directoryGroup.email, inactive, withIcon, size, entityNodeProps, Icon]
	);

	return <EntityLabel content={content} size={size} indicators={indicators} className={className} />;
};
