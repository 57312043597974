import { Record } from "immutable";
import uniq from "lodash/uniq";
import { validateFilterEqualityOperator } from "utils/validation/filterValidation";
import type { IFilter } from "types/filters";

const filterName = "integrationResourceRoleWorkflow";

const defaults = {
	name: filterName as typeof filterName,
	value: [] as (string | null)[],
	operator: "is" as "is" | "isNot"
};

type TDefaults = typeof defaults;

export class IntegrationResourceRoleWorkflowFilter
	extends Record<TDefaults>(defaults)
	implements IFilter<TDefaults["value"], TDefaults["operator"]>
{
	static filterName: typeof filterName = filterName;

	static fromURLSearchParams(urlSearchParams: URLSearchParams): IntegrationResourceRoleWorkflowFilter | null {
		const integrationResourceRoleWorkflow = uniq(
			urlSearchParams.getAll("integrationResourceRoleWorkflow").map(value => value || null)
		);
		const integrationResourceRoleWorkflowOperator =
			urlSearchParams.get("integrationResourceRoleWorkflowOperator") || undefined;
		if (
			integrationResourceRoleWorkflow.length === 0 ||
			!validateFilterEqualityOperator(integrationResourceRoleWorkflowOperator)
		) {
			return null;
		}
		return new IntegrationResourceRoleWorkflowFilter({
			value: integrationResourceRoleWorkflow,
			operator: integrationResourceRoleWorkflowOperator
		});
	}

	toURLSearchParams(): URLSearchParams {
		const params = new URLSearchParams();
		if (!this.value.length) {
			return params;
		}
		for (const value of uniq(this.value)) {
			params.append("integrationResourceRoleWorkflow", value || "null");
		}
		params.append("integrationResourceRoleWorkflowOperator", this.operator);
		return params;
	}
}
