import React from "react";
import { makeIcon } from "components/ui/Icon";

export const ChevronDownIcon = makeIcon(
	<svg viewBox="0 0 24 24" fill="none">
		<path
			d="M12 14.95C11.8667 14.95 11.7417 14.9292 11.625 14.8875C11.5084 14.8458 11.4 14.775 11.3 14.675L6.67503 10.05C6.4917 9.86667 6.4042 9.63751 6.41253 9.36251C6.42086 9.08751 6.5167 8.85834 6.70003 8.67501C6.88336 8.49167 7.1167 8.40001 7.40003 8.40001C7.68336 8.40001 7.9167 8.49167 8.10003 8.67501L12 12.575L15.925 8.65001C16.1084 8.46667 16.3375 8.37917 16.6125 8.38751C16.8875 8.39584 17.1167 8.49167 17.3 8.67501C17.4834 8.85834 17.575 9.09167 17.575 9.37501C17.575 9.65834 17.4834 9.89167 17.3 10.075L12.7 14.675C12.6 14.775 12.4917 14.8458 12.375 14.8875C12.2584 14.9292 12.1334 14.95 12 14.95Z"
			fill="currentColor"
		/>
	</svg>
);
