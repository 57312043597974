import classNames from "classnames";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { TIntegrationResourceRole, editIntegrationResourceRole } from "api/integrationResourceRoles";
import { Link } from "components/common/Link";
import { UnmanagedRole } from "components/common/UnmanagedRole";
import { WorkflowInlineSelect } from "components/common/WorkflowInlineSelect";
import { CloseIcon } from "components/ui/Icons/CloseIcon";
import { GrantedIcon } from "components/ui/Icons/GrantedIcon";
import { Table } from "components/ui/Table";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { ApprovalAlgorithmModel } from "models/ApprovalAlgorithmModel";
import { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";
import { useStyles } from "./styles";
import type { IntegrationResourceModel } from "models/IntegrationResourceModel";

interface IProps {
	role: IntegrationResourceRoleModel;
	integrationResource: IntegrationResourceModel;
	onRoleChanged: (role: Partial<TIntegrationResourceRole> & { id: string }) => void;
}

export const RoleRow: FC<IProps> = ({ role, integrationResource, onRoleChanged }) => {
	const classes = useStyles();

	const { t } = useTranslation();
	const [approvalAlgorithmId, setApprovalAlgorithmId] = useState<string | null>(role.approvalAlgorithmId);
	const openGlobalErrorModal = useOpenGlobalErrorModal();

	const changeApprovalAlgorithm = useCallback(
		async (value: ApprovalAlgorithmModel | undefined) => {
			setApprovalAlgorithmId(value?.id || null);
			try {
				await editIntegrationResourceRole({ id: role.id, approvalAlgorithmId: value?.id ?? null });
			} catch (_error) {
				setApprovalAlgorithmId(role.approvalAlgorithmId);
				openGlobalErrorModal(
					new Error(t("errors.integration.integrationResource.workflowChange", { roleName: role.name }))
				);
			}
			onRoleChanged({ id: role.id, approvalAlgorithmId: value?.id ?? null });
		},
		[onRoleChanged, role.id, openGlobalErrorModal, t, role.approvalAlgorithmId, role.name]
	);

	return (
		<Table.Row className={classes.roleRow}>
			<Table.Cell className={classes.roleCell}>
				{role.managed ? (
					<Link className={classes.link} noDecoration to={`roles/${role.id}`}>
						{role.name}
					</Link>
				) : (
					<div className={classes.roleCellUnmanaged}>
						<div>{role.name}</div>
						<UnmanagedRole />
					</div>
				)}
			</Table.Cell>
			<Table.Cell className={classes.roleCell}>
				{role.managed ? (
					<WorkflowInlineSelect
						approvalAlgorithmId={approvalAlgorithmId}
						inheritTranslationPath="pages.integration.resource.role.inherit"
						setApprovalAlgorithm={changeApprovalAlgorithm}
					/>
				) : null}
			</Table.Cell>

			<Table.Cell className={classes.roleCell}>
				{role.managed
					? role?.allowedDurations
						? t("pages.integration.resource.role.overridden")
						: t("pages.integration.resource.role.inherit")
					: null}
			</Table.Cell>
			<Table.Cell className={classNames(classes.roleCell, classes.iconCell)}>
				{role.managed ? (
					integrationResource.allowsRequests && role?.allowsRequests ? (
						<GrantedIcon color="var(--color-green-dark)" />
					) : (
						<CloseIcon color="var(--color-red-dark)" />
					)
				) : null}
			</Table.Cell>
		</Table.Row>
	);
};
