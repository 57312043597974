import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	directoryName: {
		fontWeight: "var(--typography-font-weight-bold)"
	},
	userName: {
		fontWeight: "var(--typography-font-weight-bold)"
	},
	userText: {
		width: "fit-content",
		fontWeight: "bold",
		margin: "0 0.2rem"
	},
	companyIcon: {
		fontSize: "var(--icon-small-size)",
		height: "var(--icon-small-size)",
		width: "var(--icon-small-size)"
	},
	directoryConfigurationContainer: {
		display: "inline-flex",
		verticalAlign: "middle",
		gap: "0.25em",
		alignItems: "baseline"
	},
	ticketingIntegrationTicketChip: {
		display: "inline-block",
		margin: "0 0.1rem"
	}
});
