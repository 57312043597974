import { createUseStyles } from "react-jss";

export const MAX_CHIP_WIDTH = 240;
export const useStyles = createUseStyles({
	chip: {
		height: "fit-content",
		alignItems: "center",
		border: "2px solid",
		display: "inline-flex",
		justifyContent: "flex-start",
		maxWidth: `${MAX_CHIP_WIDTH}px`,
		width: "fit-content",
		"&$small": {
			height: "21px",
			border: "1px solid",
			borderRadius: "16px",
			gap: "var(--spacing-x1, 4px)",
			padding: "var(--spacing-x05, 2px) var(--spacing-x2, 8px);",
			"&$chipWithOneIcon": {
				minWidth: "40px"
			},
			"&$chipWithTwoIcons": {
				minWidth: "56px"
			}
		},
		"&$medium": {
			height: "30px",
			borderRadius: "20px",
			gap: "var(--spacing-x1, 4px)",
			minWidth: "44px",
			padding: "var(--spacing-x1, 4px) var(--spacing-x3, 12px);",
			"&$noShrink": {
				minWidth: "unset"
			},
			"&$chipWithOneIcon": {
				minWidth: "76px"
			},
			"&$chipWithTwoIcons": {
				minWidth: "100px"
			}
		},
		"&$large": {
			height: "41px",
			borderRadius: "20px",
			gap: "var(--spacing-x2, 8px)",
			minWidth: "60px",
			padding: "var(--spacing-x2, 8px) var(--spacing-x3, 12px);",
			"&$noShrink": {
				minWidth: "unset"
			},
			"&$chipWithOneIcon": {
				minWidth: "92px"
			},
			"&$chipWithTwoIcons": {
				minWidth: "112px"
			}
		},
		"&$regularColor": {
			background: "var(--color-purple-200, #EFE8F2)",
			borderColor: "var(--color-purple-200, #EFE8F2)",
			color: "var(--color-purple-600, #5E1D79)",
			"&$inactive": {
				color: "var(--color-purple-500, #7C5B8A)"
			},
			"&:hover:not($disabled,$inactive):not($readOnly)": {
				borderColor: "var(--color-purple-300, #DFD2E4)",
				background: "var(--color-purple-300, #DFD2E4)"
			},
			"&$selected:not($disabled,$inactive)": {
				borderColor: "var(--color-purple-600, #5E1D79)",
				"&:hover:not($disabled,$inactive):not($readOnly)": {
					borderColor: "var(--color-purple-600, #DFD2E4)"
				}
			}
		},
		"&$light": {
			background: "var(--color-purple-100, #EFE8F2)",
			borderColor: "var(--color-purple-100, #E8F0E8)",
			color: "var(--color-purple-600, #5E1D79)",
			"&:hover:not($disabled,$inactive):not($readOnly)": {
				borderColor: "var(--color-purple-300, #DFD2E4)",
				background: "var(--color-purple-300, #DFD2E4)"
			},
			"&$selected:not($disabled,$inactive)": {
				borderColor: "var(--color-purple-500, #5E1D79)",
				"&:hover:not($disabled,$inactive):not($readOnly)": {
					borderColor: "var(--color-purple-500, #DFD2E4)"
				}
			}
		},
		"&$green": {
			background: "var(--color-green-400, #E8F0E8)",
			borderColor: "var(--color-green-400, #E8F0E8)",
			color: "var(--color-white, #FFF)",
			"&:hover:not($disabled):not($readOnly)": {
				borderColor: "var(--color-green-500, #1E840E)",
				background: "var(--color-green-500, #1E840E)"
			},
			"&$selected:not($disabled)": {
				borderColor: "var(--color-green-700, #5E1D79)",
				"&:hover:not($disabled):not($readOnly)": {
					borderColor: "var(--color-green-700, #DFD2E4)"
				}
			}
		},
		"&$red": {
			background: "var(--color-red-700, #E56238)",
			borderColor: "var(--color-red-700, #E56238)",
			color: "var(--color-white, #FFF)",
			"&:hover:not($disabled):not($readOnly)": {
				borderColor: "var(--color-red-800, #D75025)",
				background: "var(--color-red-800, #D75025)"
			},
			"&$selected:not($disabled)": {
				borderColor: "var(--color-red-900, #C54218)",
				"&:hover:not($disabled):not($readOnly)": {
					borderColor: "var(--color-red-800, #DFD2E4)"
				}
			}
		},
		"&$white": {
			background: "var(--color-white, #FFF)",
			borderColor: "var(--color-white, #FFF)",
			color: "var(--color-purple-600, #5E1D79)",
			"&:hover:not($disabled):not($readOnly)": {
				borderColor: "var(--color-grey-300, #F3F1F3)",
				background: "var(--color-grey-300, #F3F1F3)"
			},
			"&$selected:not($disabled)": {
				borderColor: "var(--color-grey-700, #5F6066)",
				"&:hover:not($disabled):not($readOnly)": {
					borderColor: "var(--color-grey-700, #5F6066)"
				}
			}
		},
		"&$disabled": {
			background: "var(--color-gray-300, #F3F1F3)",
			borderColor: "var(--color-gray-300, #F3F1F3)",
			color: "var(--color-gray-450, #B2B4B8)",
			"&$selected": {
				background: "var(--color-gray-400, #E0DCE2)",
				borderColor: "var(--color-grey-500, #B2B4B8)"
			},
			"&$red": {
				background: "var(--color-red-300, #FEF1EE)",
				borderColor: "var(--color-red-300, #FEF1EE)",
				"&$selected": {
					borderColor: "var(--color-red-400, #FCDED8)"
				}
			},
			"&$green": {
				background: "var(--color-green-200, #E4F8DB)",
				borderColor: "var(--color-green-200, #E4F8DB)",
				"&$selected": {
					borderColor: "var(--color-green-700, #5E1D79)"
				}
			}
		}
	},
	noOverflow: {
		"&$noOverflow&$noOverflow": {
			overflow: "unset"
		}
	},
	regularIcon: {
		color: "var(--color-purple-600, #5E1D79)"
	},
	lightIcon: {
		color: "var(--color-purple-600, #5E1D79)"
	},
	whiteIcon: {
		color: "var(--color-white, #FFF)"
	},
	clickable: { cursor: "pointer" },
	chipText: {
		flex: "1",
		textAlign: "center"
	},
	disabled: {},
	green: {},
	large: {},
	light: {},
	medium: {},
	inactive: {},
	noShrink: {},
	readOnly: {},
	red: {},
	regularColor: {},
	selected: {},
	small: {},
	white: {},
	chipWithOneIcon: {},
	chipWithTwoIcons: {}
});
