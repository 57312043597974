import { apiReq, TBody } from "./apiReq";

export async function exportToCSV(url: string, defaultFileName = "data.csv", body?: TBody): Promise<void> {
	let response: Response;
	if (body) response = (await apiReq("POST", url, body, { expectJson: false })).response;
	else response = (await apiReq("GET", url, null, { expectJson: false })).response;
	const contentDisposition = response.headers.get("content-disposition");
	const fileName = extractFileName(contentDisposition, defaultFileName);
	const data = await response.blob();
	downloadFile(data, fileName);
}

const FILE_NAME_KEY = "filename=";
const extractFileName = (header: string | null, defaultFileName: string): string => {
	if (!header || !header.includes(FILE_NAME_KEY)) return defaultFileName;
	const fileNameStartIndex = header.indexOf(FILE_NAME_KEY) + FILE_NAME_KEY.length;
	const fileNameEndIndex = header.indexOf(";", fileNameStartIndex);
	const fileName = header.substring(fileNameStartIndex, fileNameEndIndex === -1 ? undefined : fileNameEndIndex);
	return fileName;
};

const downloadFile = (data: Blob, fileName: string) => {
	const url = window.URL.createObjectURL(data);
	const hiddenLink = document.createElement("a");
	document.body.appendChild(hiddenLink);
	hiddenLink.setAttribute("style", "display: none");
	hiddenLink.href = url;
	hiddenLink.download = fileName;
	hiddenLink.click();
	window.URL.revokeObjectURL(url);
	hiddenLink.remove();
};
