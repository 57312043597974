import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	noPadding: {
		padding: 0
	},
	actionsContainer: {
		display: "flex",
		width: "100%",
		justifyContent: "center",
		alignItems: "center"
	}
});
