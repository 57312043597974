import classNames from "classnames";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useOptionImage } from "components/pages/NewTicketPage/components/NewTicketForm/hooks/useImages";
import { BundleIcon } from "components/ui/Icons/BundleIcon";
import { ResourcesIcon } from "components/ui/Icons/ResourcesIcon";
import { Typography } from "components/ui/legacy/Typography";
import { useIntegrations } from "hooks/useIntegrations";
import { LabelTags } from "./components/LabelTags";
import { useStyles } from "./styles";
import type {
	TBundleOption,
	TNewTicketOption,
	TRoleOption
} from "components/pages/NewTicketPage/components/NewTicketForm/types";

interface IProps {
	query: string | null;
}
type TBundleOptionProps = IProps & TBundleOption;
type TRoleOptionProps = IProps & TRoleOption;

const MAX_DESCRIPTION_LENGTH = 100;
const SHOWN_TAGS = 5;
const SHOWN_TAG_MAX_LENGTH = 10;

export const SearchOption: FC<TBundleOptionProps | TRoleOptionProps> = ({ className, type, value: item, query }) => {
	const { t } = useTranslation(undefined, { keyPrefix: "pages.newTicket.newTicketForm.searchInput.option" });
	const classes = useStyles();
	const integrations = useIntegrations();

	const secondaryHeader = useMemo(() => {
		let content: string | undefined;
		if (type === "bundle") {
			if (!item.category) return null;
			content = t("category", { category: item.category });
		} else {
			const resource = item.integrationResource;
			const integration = integrations?.get(resource.integrationId);
			if (!integration) return null;
			content = `${integration.name} - ${resource.name}`;
		}
		return <Typography variant="small">{content}</Typography>;
	}, [integrations, item, t, type]);

	const imageItem = useMemo(() => ({ type, value: item }) as TNewTicketOption, [type, item]);
	const image = useOptionImage(imageItem, classes.image);

	const description = useMemo(() => {
		let showedDescription: string | undefined | null;

		if (type === "bundle") {
			showedDescription = item.description;
		} else {
			showedDescription = item.integrationResource.calculatedDescription;
		}
		if (!showedDescription) return null;
		return (
			<Typography variant="small" noWrap>
				{showedDescription.substring(0, MAX_DESCRIPTION_LENGTH)}
			</Typography>
		);
	}, [type, item]);

	const allTags = useMemo(() => {
		if (type === "bundle") {
			return item.tags || [];
		} else {
			return item.integrationResource.calculatedTags;
		}
	}, [item, type]);

	return (
		<div className={classNames(classes.option, className)}>
			{image}
			<div className={classes.content}>
				<Typography>{item.name}</Typography>
				{secondaryHeader}
				{description}
				<LabelTags
					tags={allTags}
					tagMaxLength={SHOWN_TAG_MAX_LENGTH}
					tagsShown={SHOWN_TAGS}
					variant="small"
					query={query || undefined}
				/>
			</div>
			{type === "bundle" ? <BundleIcon /> : <ResourcesIcon />}
		</div>
	);
};
