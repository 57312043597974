import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	actions: {
		display: "flex",
		gap: "0.2rem"
	},
	addBundleContainer: {
		paddingTop: "0.5rem"
	},
	input: {},
	bundleSelectInput: {
		display: "flex"
	},
	bundlesListContainer: {
		minHeight: "6rem",
		display: "flex",
		flexDirection: "column"
	},
	bundleRow: {
		display: "flex",
		justifyContent: "space-between",
		gap: "8px",
		padding: "4px",
		alignItems: "flex-end"
	}
});
