import { List } from "immutable";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useApprovalFlowsWebhooksMap } from "hooks/useApprovalFlowsWebhooksMap";
import { useMultiDirectoryGroups } from "hooks/useMultiDirectoryGroups";
import { useMultiUsers } from "hooks/useMultiUsers";
import { useOnCallIntegrationSchedules } from "hooks/useOnCallIntegrationSchedules";
import { ApprovalFlowEntityModel, isNoIdentifierApprovalFlowEntityType } from "models/ApprovalFlowEntityModel";
import { ApprovalFlowNotifiedEntityModel } from "models/ApprovalFlowNotifiedEntityModel";
import type { ApprovalFlowsWebhookModel } from "models/ApprovalFlowsWebhookModel";
import type { DirectoryGroupModel } from "models/DirectoryGroupModel";
import type { OnCallIntegrationScheduleModel } from "models/OnCallIntegrationScheduleModel";
import type { UserModel } from "models/UserModel";
import type { IEntity, IUserEntity, TEntityType } from "types/entity";

const customType = "custom" as TEntityType;

const getUserEntityObject = (user: UserModel): IUserEntity => ({
	name: user.fullName,
	tooltip: user.email,
	isDeleted: user.isDeleted,
	imageUrl: user.imageUrl,
	type: "user"
});

const getDirectoryGroupEntityObject = (directoryGroup: DirectoryGroupModel): IEntity => ({
	name: directoryGroup.name,
	tooltip: directoryGroup.email || undefined,
	type: "directoryGroup",
	isDeleted: directoryGroup.isDeleted
});

const getScheduleEntityObject = (schedule?: OnCallIntegrationScheduleModel): IEntity => ({
	name: schedule?.name ?? "",
	isDeleted: schedule?.isDeleted || !schedule,
	onCallScheduleType: schedule?.type,
	type: "onCallIntegrationSchedule"
});

const getWebhookEntityObject = (webhook?: ApprovalFlowsWebhookModel): IEntity => ({
	name: webhook?.name ?? "",
	isDeleted: webhook?.disabled || !webhook,
	type: "webhook"
});

export const useApprovalFlowStepEntities = (
	approvalFlowEntities: List<ApprovalFlowNotifiedEntityModel> | List<ApprovalFlowEntityModel> | null
) => {
	const { t } = useTranslation();
	const onCallIntegrationSchedules = useOnCallIntegrationSchedules();
	const approvalFlowsWebhooksMap = useApprovalFlowsWebhooksMap();
	const directoryGroupEntityIds = useMemo(
		() =>
			approvalFlowEntities
				?.toArray()
				.filter(entity => entity.type === "DirectoryGroup")
				.map(entity => entity.identifier!) || [],
		[approvalFlowEntities]
	);
	const usersIds = useMemo(
		() =>
			approvalFlowEntities
				?.toArray()
				.filter(entity => entity.type === "User")
				.map(entity => entity.identifier!) || [],
		[approvalFlowEntities]
	);
	const directoryGroups = useMultiDirectoryGroups(directoryGroupEntityIds);
	const users = useMultiUsers(usersIds);

	const entities: IEntity[] = useMemo(() => {
		if (!approvalFlowEntities?.size) return [];

		return approvalFlowEntities
			.map(approvalFlowEntity => {
				if (isNoIdentifierApprovalFlowEntityType(approvalFlowEntity.type)) {
					return { name: t(`common.approvalFlow.entityTypes.${approvalFlowEntity.type}`), type: customType } as IEntity;
				}
				if (approvalFlowEntity.type === "User") {
					const user = users?.get(approvalFlowEntity.identifier || "");
					if (user) {
						return getUserEntityObject(user);
					}
				}
				if (approvalFlowEntity.type === "DirectoryGroup") {
					const directoryGroup = directoryGroups?.find(group => group.id === approvalFlowEntity.identifier);
					if (directoryGroup) {
						const obj = getDirectoryGroupEntityObject(directoryGroup);
						obj.name = obj.name || t("shared.unknown");
						return obj;
					}
				}
				if (approvalFlowEntity.type === "OnCallIntegrationSchedule") {
					const onCallIntegrationSchedule = onCallIntegrationSchedules?.get(approvalFlowEntity.identifier ?? "");
					const obj = getScheduleEntityObject(onCallIntegrationSchedule);
					obj.name = obj.name === "" ? t("common.approvalFlow.deletedOnCallEntitySchedule") : obj.name;
					return obj;
				}
				if (approvalFlowEntity.type === "Webhook") {
					const webhook = approvalFlowsWebhooksMap?.get(approvalFlowEntity.identifier ?? "");
					const obj = getWebhookEntityObject(webhook);
					obj.name = obj.name === "" ? t("common.approvalFlow.deletedWebhook") : obj.name;
					return obj;
				}
				return { name: t("shared.unknown"), type: customType };
			})
			.toArray();
	}, [approvalFlowEntities, approvalFlowsWebhooksMap, directoryGroups, onCallIntegrationSchedules, t, users]);

	return entities;
};
