import { useCallback, useState } from "react";

export const useControlled = <T>({
	controlled,
	default: defaultValue
}: {
	controlled: T | undefined;
	default: T;
}): [T, (value: T) => void] => {
	const [stateValue, setStateValue] = useState(controlled !== undefined ? controlled : defaultValue);
	const isControlled = controlled !== undefined;

	const setValue = useCallback(
		(newValue: T) => {
			if (!isControlled) {
				setStateValue(newValue);
			}
		},
		[isControlled]
	);

	return [isControlled ? controlled : stateValue, setValue];
};
