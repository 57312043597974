import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	modalContent: {
		display: "flex",
		flexDirection: "column",
		gap: "20px"
	},
	resourcePermissionForm: {
		display: "flex",
		gap: "1.2rem",
		padding: "1.1rem"
	},
	input: {
		width: "20rem"
	},
	prioritize: {
		order: -1
	}
});
