import classNames from "classnames";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { BinaryToggle } from "components/ui/BinaryToggle";
import { useStyles } from "./styles";

interface IProps {
	operator: "and" | "or";
	disabled?: boolean;
	onChange?: (operator: "and" | "or") => void;
}

interface IToggleOption {
	value: "and" | "or";
	label: string;
}

const useAndOrOptions = () => {
	const { t } = useTranslation();
	const options: [IToggleOption, IToggleOption] = useMemo(
		() => [
			{
				value: "and",
				label: t("flow.all")
			},
			{
				value: "or",
				label: t("flow.any")
			}
		],
		[t]
	);

	return options;
};

export const AnyOrAllToggle: FC<IProps> = ({ className, onChange, operator, disabled }) => {
	const classes = useStyles();
	const options = useAndOrOptions();

	return (
		<BinaryToggle
			options={options}
			className={classNames(classes.root, className)}
			value={operator}
			disabled={disabled}
			onChange={onChange}
			fontSize="small"
		/>
	);
};
