import React from "react";
import { UserCard } from "components/common/UserCard";
import { TooltipOnOverflow } from "components/ui/TooltipOnOverflow";
import { useUser } from "hooks/useUser";
import { UserModel } from "models/UserModel";
import { INHERIT, type TInherit, type TOptionComponentProps } from "utils/ui/select";
import { useStyles } from "./styles";
import { SelectOption } from "../SelectOption";
import type { TEntityOption } from "utils/entityOptionType";

// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents -- TInherit is string, but not id string
function UserNodeOption<T extends UserModel | TEntityOption | TInherit | string>({
	disabled = false,
	getTextContent,
	isSelected = false,
	onSelect,
	option,
	optionKey
}: TOptionComponentProps<T>) {
	const classes = useStyles();

	const isId = typeof option === "string" && option !== INHERIT;
	const isEntityOption = typeof option === "object" && "identifier" in option;
	const id = isId ? option : isEntityOption ? option.identifier : null;

	const user = useUser(id || undefined);
	const displayUser = id && user ? user : (option as UserModel);

	const inheritDisplayText = option === INHERIT && getTextContent ? getTextContent(option) : null;

	return (
		<SelectOption onSelect={onSelect} disabled={disabled} value={option} readonly key={optionKey}>
			{inheritDisplayText ? (
				<TooltipOnOverflow content={inheritDisplayText} textVariant="body_sb" />
			) : (
				<UserCard user={displayUser} selected={isSelected} className={classes.user} />
			)}
		</SelectOption>
	);
}

UserNodeOption.isSelectOption = true;

export { UserNodeOption };
