import { createUseStyles } from "react-jss";
import { BP_MEDIA_QUERIES } from "utils/ui/breakpoints";

export const BREAKPOINT_TO_PER_ROW = new Map([
	[10, 2],
	[20, 2],
	[30, 2],
	[40, 3],
	[50, 4],
	[60, 4],
	[70, 5],
	[80, 6]
]);

export const useStyles = createUseStyles({
	gridContainer: {
		display: "grid",
		gap: "var(--spacing-x4)",
		gridTemplateColumns: `repeat(${BREAKPOINT_TO_PER_ROW.get(10)}, 1fr)`,
		width: "100%",
		[BP_MEDIA_QUERIES.FORTY]: {
			gridTemplateColumns: `repeat(${BREAKPOINT_TO_PER_ROW.get(40)}, 1fr)`
		},
		[BP_MEDIA_QUERIES.FIFTY]: {
			gridTemplateColumns: `repeat(${BREAKPOINT_TO_PER_ROW.get(50)}, 1fr)`
		},
		[BP_MEDIA_QUERIES.SEVENTY]: {
			gridTemplateColumns: `repeat(${BREAKPOINT_TO_PER_ROW.get(70)}, 1fr)`
		},
		[BP_MEDIA_QUERIES.EIGHTY]: {
			gridTemplateColumns: `repeat(${BREAKPOINT_TO_PER_ROW.get(80)}, 1fr)`
		},
		"& > *": {
			alignSelf: "start"
		}
	},
	gridItem: {
		minWidth: 0,
		order: (props?: { order?: number }) => props?.order || 0,
		"&$expandedGridItem": {
			gridColumn: "1 / -1",
			order: (props?: { order?: number }) => (props?.order || 0) + 1
		}
	},
	ghostGridItem: { visibility: "hidden" },
	expandedGridItem: {},
	notDisplayRow: { display: "none" },
	emptyState: {
		alignItems: "center",
		display: "flex",
		gridColumn: "1 / -1",
		height: "240px",
		justifyContent: "center"
	}
});
