import React, { useCallback } from "react";
import { BundleEntity } from "components/common/entities";
import { BundleModel } from "models/BundleModel";
import { SelectOption, TEntityOverrideProps } from "../SelectOption";
import type { TOptionComponent } from "utils/ui/select";

const BundleOption: TOptionComponent<BundleModel> = ({
	disabled = false,
	isSelected = false,
	onSelect,
	option,
	optionKey
}) => {
	const renderOption = useCallback(
		(entityProps: TEntityOverrideProps) => <BundleEntity noWrap relative bundle={option} {...entityProps} />,
		[option]
	);

	return (
		<SelectOption
			disabled={disabled}
			isSelected={isSelected}
			key={optionKey}
			onSelect={onSelect}
			value={option}
			renderOption={renderOption}
		/>
	);
};

BundleOption.isSelectOption = true;

export { BundleOption };
