import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	container: {
		alignItems: "flex-start",
		alignSelf: "stretch",
		background: "var(--color-purple-50, #FBF9FB)",
		borderRadius: "12px",
		display: "flex",
		flexDirection: "column",
		gap: "var(--spacing-x5, 20px)",
		padding: "var(--spacing-x4, 16px) var(--spacing-x3, 12px) var(--spacing-x4, 16px) var(--spacing-x4, 16px)"
	}
});
