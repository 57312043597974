import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	card: {
		display: "block",
		border: "1px solid var(--surface-block-background-color)",
		borderRadius: "var(--border-radius-rounded)",
		backgroundColor: "var(--surface-block-background-color)",
		boxShadow: "var(--surface-block-box-shadow)",
		boxSizing: "border-box",
		"&:hover": {
			border: "1px solid var(--color-blue-transparent)"
		}
	},
	content: {
		display: "grid",
		gridTemplateColumns: "1fr auto 1fr auto 1fr",
		textDecoration: "none",
		color: "inherit",
		gap: "3rem",
		padding: "1.5em"
	},
	resourceCount: {
		color: "var(--color-grey-dark)"
	},
	cell: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		width: "100%"
	},
	summaryCell: {
		gap: "0.85rem"
	},
	defaultApprovalAlgorithm: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		gap: "0.85rem"
	},
	integrationImageContainer: {
		height: "3rem",
		width: "3rem"
	},
	spinner: {
		margin: "auto"
	}
});
