import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	textCellResizeableWrapper: {
		justifyContent: "flex-start",
		alignItems: "center",
		width: "100%",
		height: "100%",
		display: "flex",
		position: "relative"
	},
	textCellResizeable: {
		padding: "var(--spacing-x1) var(--spacing-x5)"
	},
	divider: {
		position: "absolute",
		zIndex: 1,
		cursor: "col-resize",
		userSelect: "none",
		top: 0,
		right: -2,
		"&$disableResize": {
			cursor: "default"
		},
		"&$dividerHover:not($disableResize)": {
			right: -3
		}
	},
	disableResize: {},
	dividerHover: {}
});
