import classNames from "classnames";
import React, { useMemo } from "react";
import { DirectoryGroupEntity } from "components/common/entities/DirectoryGroupEntity";
import { UserEntity } from "components/common/entities/UserEntity";
import { WebhookEntity } from "components/common/entities/WebhookEntity";
import { Entity } from "components/common/Entity";
import { OnCallIntegrationScheduleLabel } from "components/common/OnCallIntegrationScheduleLabel";
import { List } from "components/ui/List";
import { useStyles } from "./styles";
import type { IEntity, TEntity } from "types/entity";

const EntityItem: FC<{ entity: string | TEntity }> = ({ entity }) => {
	// Directory group is the default component for every entity except for strings and on call schedules
	if (typeof entity === "string") {
		return <DirectoryGroupEntity withIcon size="medium" directoryGroup={{ name: entity }} />;
	}
	if (entity.type === "directoryGroup") {
		const group = { ...entity, email: entity.tooltip };
		return <DirectoryGroupEntity withIcon size="medium" directoryGroup={group} />;
	}
	if (entity.type === "onCallIntegrationSchedule") {
		return (
			<OnCallIntegrationScheduleLabel
				value={entity.name}
				onCallType={entity.onCallScheduleType}
				isDeleted={entity.isDeleted}
			/>
		);
	}
	if (entity.type === "webhook") {
		return <WebhookEntity withIcon size="medium" webhook={entity} />;
	}
	if (entity.type === "user") {
		return (
			<UserEntity
				withoutEmail
				withIcon
				size="medium"
				user={{ fullName: entity.name, isDeleted: entity.isDeleted, imageUrl: entity.imageUrl }}
			/>
		);
	}

	return <Entity content={entity.name} isDeleted={entity.isDeleted} prefixIcon={entity.prefixIcon} />;
};

const MemoizedEntityItem = React.memo(EntityItem);

const ArrayEntity: FC<{ entities: string[] | IEntity[] }> = ({ entities }) => {
	const classes = useStyles();

	const items = useMemo(
		() =>
			entities?.map(entity => (
				<MemoizedEntityItem
					key={typeof entity === "string" ? entity : `${entity.name}-${entity.tooltip}`}
					entity={entity}
				/>
			)),
		[entities]
	);
	if (!entities || entities.length === 0) return null;
	if (items.length > 1) return <List items={items} className={classes.entityList} />;
	return <>{items[0]}</>;
};

export const ApprovalAlgorithmEntity: FC<{
	value: string[] | string | IEntity | IEntity[];
}> = ({ value, className }) => {
	const classes = useStyles();
	return (
		<div className={classNames(classes.entityContainer, className)}>
			{Array.isArray(value) ? <ArrayEntity entities={value} /> : <MemoizedEntityItem entity={value} />}
		</div>
	);
};
