import { Record, Map } from "immutable";

type TPolicyAuditLogAction = "PolicyCreated" | "PolicyDeleted" | "PolicyUpdatedData" | "PolicyUpdatedOrder";

const defaults = {
	action: "PolicyCreated" as TPolicyAuditLogAction,
	policyId: "",
	userId: "",
	id: "",
	createdAt: new Date(0),
	data: null as Map<string, unknown> | null
};

export class PolicyAuditLogModel extends Record<typeof defaults>(defaults) {
	static readonly type = "policy";

	static fromServerData(data: unknown) {
		const {
			id,
			createdAt,
			data: _data,
			action,
			userId,
			policyId
		} = data as Omit<typeof defaults, "data"> & { data: { [key: string]: string } };
		return new PolicyAuditLogModel({
			id,
			createdAt: new Date(createdAt),
			data: _data && Map<string, unknown>(_data),
			action,
			userId,
			policyId
		});
	}
}
