import React from "react";
import { makeIcon } from "components/ui/Icon";

export const ReportIcon = makeIcon(
	<svg viewBox="0 0 24 24" fill="none">
		<path
			d="M8 17C8.28333 17 8.52083 16.9042 8.7125 16.7125C8.90417 16.5208 9 16.2833 9 16V11C9 10.7167 8.90417 10.4792 8.7125 10.2875C8.52083 10.0958 8.28333 10 8 10C7.71667 10 7.47917 10.0958 7.2875 10.2875C7.09583 10.4792 7 10.7167 7 11V16C7 16.2833 7.09583 16.5208 7.2875 16.7125C7.47917 16.9042 7.71667 17 8 17ZM12 17C12.2833 17 12.5208 16.9042 12.7125 16.7125C12.9042 16.5208 13 16.2833 13 16V8C13 7.71667 12.9042 7.47917 12.7125 7.2875C12.5208 7.09583 12.2833 7 12 7C11.7167 7 11.4792 7.09583 11.2875 7.2875C11.0958 7.47917 11 7.71667 11 8V16C11 16.2833 11.0958 16.5208 11.2875 16.7125C11.4792 16.9042 11.7167 17 12 17ZM16 17C16.2833 17 16.5208 16.9042 16.7125 16.7125C16.9042 16.5208 17 16.2833 17 16V14C17 13.7167 16.9042 13.4792 16.7125 13.2875C16.5208 13.0958 16.2833 13 16 13C15.7167 13 15.4792 13.0958 15.2875 13.2875C15.0958 13.4792 15 13.7167 15 14V16C15 16.2833 15.0958 16.5208 15.2875 16.7125C15.4792 16.9042 15.7167 17 16 17ZM5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H19C19.55 3 20.0208 3.19583 20.4125 3.5875C20.8042 3.97917 21 4.45 21 5V19C21 19.55 20.8042 20.0208 20.4125 20.4125C20.0208 20.8042 19.55 21 19 21H5ZM5 19H19V5H5V19Z"
			fill="currentColor"
		/>
	</svg>
);
