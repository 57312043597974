import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	description: {
		height: "100%"
	},
	descriptionCell: {
		overflow: "auto",
		maxHeight: "20rem"
	},
	actions: {
		alignItems: "center",
		display: "flex",
		gap: "0.5rem",
		justifyContent: "center"
	},
	action: {
		color: "var(--button-secondary-font-color)",
		cursor: "pointer",
		borderRadius: "var(--border-radius-sharp)",
		border: "1px solid var(--color-grey-light)",
		padding: "0.2rem",
		width: "1.75rem",
		height: "1.75rem"
	},
	centeredIconContainer: {
		alignItems: "center",
		display: "flex",
		flexGrow: 1,
		justifyContent: "center"
	},
	content: {
		display: "flex",
		flexDirection: "column",
		gap: "1.5rem",
		"&$content$content": {
			background: "none",
			border: "none",
			padding: "1.5rem 2.25rem"
		}
	},
	header: {
		display: "flex",
		justifyContent: "start",
		gap: "0.25rem"
	},
	headerTitle: {
		alignItems: "center",
		display: "flex",
		justifyContent: "space-between",
		padding: "0.75em 1.5em",
		background: "var(--color-almost-white)"
	},
	templates: {
		padding: "0 2.25em"
	},
	table: {
		background: "var(--color-white)"
	}
});
