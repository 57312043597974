import { getI18nTextIfExists } from "utils/i18n";
import type { TTimezone } from "utils/auditLogs/systemAuditLogTimezone";

export const formatDateToText = (date: Date, timezone: TTimezone) => {
	const todayDate = new Date();
	const yesterdayDate = new Date(todayDate);
	const timezoneType = timezone === "global" ? "UTC" : Intl.DateTimeFormat().resolvedOptions().timeZone;
	yesterdayDate.setDate(yesterdayDate.getDate() - 1);

	if (date.toDateString() === todayDate.toDateString()) {
		return getI18nTextIfExists("dateTime.today");
	} else if (date.toDateString() === yesterdayDate.toDateString()) {
		return getI18nTextIfExists("dateTime.yesterday");
	} else {
		return date.toLocaleDateString([], {
			month: "long",
			day: "2-digit",
			timeZone: timezoneType
		});
	}
};

export const formatTimeToText = (date: Date, timezone: TTimezone) => {
	const timezoneType = timezone === "global" ? "UTC" : Intl.DateTimeFormat().resolvedOptions().timeZone;
	return new Date(date).toLocaleTimeString([], { hour12: false, timeZone: timezoneType });
};
