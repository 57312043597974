import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	header: {
		alignItems: "baseline",
		display: "flex",
		width: "100%"
	},
	headerButtons: {
		marginLeft: "auto",
		display: "flex",
		gap: "0.5rem"
	},
	headerMetadata: {
		color: "var(--color-grey-dark)",
		paddingLeft: "0.85rem",
		display: "flex",
		flex: "1 1 auto",
		gap: "0.2rem"
	},
	wrapContent: {
		whiteSpace: "pre-wrap"
	}
});
