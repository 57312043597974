import React from "react";
import { makeIcon } from "components/ui/Icon";

export const RoleIcon = makeIcon(
	<svg viewBox="0 0 24 24" fill="none">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M13 3H11V7H13V3ZM11 1C9.89543 1 9 1.89543 9 3V7C9 8.10457 9.89543 9 11 9H13C14.1046 9 15 8.10457 15 7V3C15 1.89543 14.1046 1 13 1H11ZM9 5H6C4.89543 5 4 5.89543 4 7V20C4 21.1046 4.89543 22 6 22H18C19.1046 22 20 21.1046 20 20V7C20 5.89543 19.1046 5 18 5H15V7H18V20H17C17 19 16 17 12 17C8 17 7 19 7 20H6V7H9V5ZM12 16C12.8286 16 13.5357 15.7071 14.1214 15.1214C14.7071 14.5357 15 13.8286 15 13C15 12.1714 14.7071 11.4643 14.1214 10.8786C13.5357 10.2929 12.8286 10 12 10C11.1714 10 10.4643 10.2929 9.87857 10.8786C9.29286 11.4643 9 12.1714 9 13C9 13.8286 9.29286 14.5357 9.87857 15.1214C10.4643 15.7071 11.1714 16 12 16Z"
			fill="currentColor"
		/>
	</svg>
);
