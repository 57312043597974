import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	iconButton: {
		display: "flex",
		padding: "0 var(--spacing-x6, 24px)",
		justifyContent: "center",
		alignItems: "center",
		height: "20px",
		gap: "var(--spacing-x1, 4px)",
		backgroundColor: "var(--color-gray-300, #F3F1F3)",
		"&:hover": {
			backgroundColor: "var(--color-gray-400, #E0DEE0)",
			cursor: "pointer"
		},
		"&$chevronUp": {
			borderTopLeftRadius: "8px",
			borderTopRightRadius: "8px"
		},
		"&$chevronDown": {
			borderBottomLeftRadius: "8px",
			borderBottomRightRadius: "8px"
		}
	},
	timeInput: {
		display: "flex",
		flexDirection: "column",
		width: "100%",
		borderRadius: "8px",
		justifyContent: "center",
		backgroundColor: "var(--color-white, #FFFFFF)",
		alignItems: "center",
		gap: "var(--spacing-x1, 4px)"
	},
	timeInputElement: {
		alignItems: "center",
		backgroundColor: "var(--color-white, #FFFFFF)",
		border: "none",
		borderRadius: "8px",
		color: "var(--color-black, #272931)",
		display: "flex",
		flexDirection: "column",
		fontFamily: "inherit",
		fontSize: "var(--typography-font-size-body)",
		fontStyle: "normal",
		fontWeight: "var(--typography-font-weight-semibold)",
		gap: "var(--spacing-x1, 4px)",
		height: "25px",
		justifyContent: "center",
		outline: "none",
		textAlign: "center",
		userSelect: "none",
		width: "50px"
	},
	timer: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
		width: "100%",
		gap: "var(--spacing-x3, 12px)"
	},
	inputs: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		alignItems: "center",
		height: "76px"
	},
	timeButtonInput: {
		display: "flex",
		padding: "var(--spacing-x1, 4px) var(--spacing-x4, 16px)",
		alignItems: "flex-start",
		gap: "var(--spacing-x1, 4px)",
		width: "60px",
		userSelect: "none",
		justifyContent: "center",
		borderRadius: "8px",
		backgroundColor: "var(--color-white, #FFFFFF)",
		color: "var(--color-black, #272931)",
		textAlign: "center",
		"&:hover": {
			cursor: "pointer",
			backgroundColor: "var(--color-purple-200, #E0DEE0)"
		},
		"&$selectedTimeType": {
			backgroundColor: "var(--color-purple-600, #5E1D79)",
			color: "var(--color-white, #FFFFFF)"
		}
	},
	seperatorInput: {
		userSelect: "none"
	},
	selectedTimeType: {},
	chevronUp: {},
	chevronDown: {}
});
