import uniq from "lodash/uniq";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { IntegrationEntity, ResourceEntity, RoleEntity } from "components/common/entities";
import { RequestNumberChip } from "components/common/RequestNumberChip";
import { AccountIcon } from "components/ui/Icons/AccountIcon";
import { ExpiredIcon } from "components/ui/Icons/ExpiredIcon";
import { IntegrationIcon } from "components/ui/Icons/IntegrationIcon";
import { RequestsIcon } from "components/ui/Icons/RequestsIcon";
import { ResourcesIcon } from "components/ui/Icons/ResourcesIcon";
import { RoleIcon } from "components/ui/Icons/RoleIcon";
import { TColumn, VirtualTable } from "components/ui/VirtualTable";
import { ChipContainerCellContent, HeaderCellContent, TextCellContent } from "components/ui/VirtualTable/components";
import { useIntegrations } from "hooks/useIntegrations";
import { UserEntitlementModel } from "models/UserEntitlementModel";
import { notEmpty } from "utils/comparison";
import { ExpirationCell } from "./components/ExpirationCell";
import { RevokeCell } from "../RevokeCell";
import type { TVirtualTableWidth } from "components/ui/VirtualTable/types";
import type { IntegrationResourceModel } from "models/IntegrationResourceModel";
import type { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";

type TUserPermissionsTableProps = {
	permissions: UserEntitlementModel[];
	isLoading: boolean;
	revokePermission?: (permission: UserEntitlementModel) => Promise<unknown>;
};

const COLUMNS_WIDTHS = {
	integration: "minmax(168px, 1fr)",
	resourceType: "minmax(168px, 1fr)",
	resource: "minmax(168px, 1fr)",
	role: "minmax(168px, 1fr)",
	account: "minmax(144px, 1fr)",
	requestNumber: "minmax(216px, 1fr)",
	expiration: "minmax(160px, 1fr)",
	revoke: "108px"
};
const USERS_PAGE_TRANSLATION_PREFIX = "pages.user.entitlements";

export const UserPermissionsTable: FC<TUserPermissionsTableProps> = ({
	className,
	innerRef,
	permissions,
	isLoading,
	revokePermission
}) => {
	const { t } = useTranslation();
	const [searchParams, setSearchParams] = useSearchParams();
	const integrations = useIntegrations();

	const renderRequestNumber = useCallback(
		(entitlement: UserEntitlementModel, disabled = false) => {
			const tickets = uniq(
				(entitlement.ticketPermissions?.toArray() || [])
					.map(ticketPermission => ticketPermission.ticket)
					.filter(notEmpty)
			);

			const onChipClick = (ticketId: string) => {
				searchParams.set("ticketId", ticketId);
				setSearchParams(searchParams);
			};

			return tickets.map(ticket => (
				<RequestNumberChip
					key={ticket.id}
					requestNumber={ticket.number}
					onClick={!disabled ? () => onChipClick(ticket.id) : undefined}
				/>
			));
		},
		[searchParams, setSearchParams]
	);

	const columns = useMemo(() => {
		const baseUserPermissionsTableColumns = [
			{
				renderCell: (row: UserEntitlementModel) => {
					const integration = integrations?.get(row.role?.integrationResource?.integrationId || "");

					if (!integration) {
						return <TextCellContent text="" />;
					}
					return <IntegrationEntity withIcon size="medium" variant="link" integration={integration} />;
				},
				header: (
					<HeaderCellContent
						text={t(`${USERS_PAGE_TRANSLATION_PREFIX}.permissionsTable.integration`)}
						icon={<IntegrationIcon />}
					/>
				),
				key: "integration",
				width: COLUMNS_WIDTHS.integration
			},
			{
				renderCell: TextCellContent,
				getProps: (row: UserEntitlementModel) => ({ text: row.role?.integrationResource?.type }),
				header: <HeaderCellContent text={t(`${USERS_PAGE_TRANSLATION_PREFIX}.permissionsTable.resourceType`)} />,
				key: "resourceType",
				width: COLUMNS_WIDTHS.resourceType
			},
			{
				renderCell: (row: UserEntitlementModel) => {
					if (!row.role?.integrationResource) {
						return <TextCellContent text="" />;
					}

					return <ResourceEntity resource={row.role.integrationResource} size="medium" variant="link" />;
				},
				header: (
					<HeaderCellContent
						text={t(`${USERS_PAGE_TRANSLATION_PREFIX}.permissionsTable.resource`)}
						icon={<ResourcesIcon />}
					/>
				),
				key: "resource",
				width: COLUMNS_WIDTHS.resource
			},
			{
				renderCell: row => {
					if (!row.role) {
						return <TextCellContent text="" />;
					}

					return (
						<RoleEntity
							size="medium"
							variant={row.role.integrationResource ? "link" : "standard"}
							role={row.role as IntegrationResourceRoleModel & { integrationResource: IntegrationResourceModel }}
						/>
					);
				},
				header: (
					<HeaderCellContent text={t(`${USERS_PAGE_TRANSLATION_PREFIX}.permissionsTable.role`)} icon={<RoleIcon />} />
				),
				key: "role",
				width: COLUMNS_WIDTHS.role
			},
			{
				renderCell: TextCellContent,
				getProps: (row: UserEntitlementModel) => ({ text: row.actor?.displayName }),
				header: (
					<HeaderCellContent
						text={t(`${USERS_PAGE_TRANSLATION_PREFIX}.permissionsTable.account`)}
						icon={<AccountIcon />}
					/>
				),
				key: "account",
				width: COLUMNS_WIDTHS.account
			},
			{
				renderCell: ChipContainerCellContent,
				getProps: (row, options) => ({
					chips: row.role?.managed ? renderRequestNumber(row, options.disabled || false) : [],
					size: "medium"
				}),
				header: <HeaderCellContent text={t("common.requestDateAndNumber.requestNumber")} icon={<RequestsIcon />} />,
				key: "requestNumber",
				width: COLUMNS_WIDTHS.requestNumber
			},
			{
				renderCell: (row: UserEntitlementModel) => <ExpirationCell entitlement={row} />,
				header: (
					<HeaderCellContent
						text={t(`${USERS_PAGE_TRANSLATION_PREFIX}.permissionsTable.expiration`)}
						icon={<ExpiredIcon />}
					/>
				),
				key: "expiration",
				width: COLUMNS_WIDTHS.expiration
			}
		] as TColumn<UserEntitlementModel>[];
		if (revokePermission)
			baseUserPermissionsTableColumns.push({
				renderCell: (row: UserEntitlementModel) => <RevokeCell entitlement={row} revokePermission={revokePermission} />,
				key: "revoke",
				width: COLUMNS_WIDTHS.revoke as TVirtualTableWidth
			});
		return baseUserPermissionsTableColumns;
	}, [integrations, renderRequestNumber, revokePermission, t]);

	return (
		<VirtualTable
			className={className}
			innerRef={innerRef}
			rows={permissions}
			totalRows={permissions.length}
			columns={columns}
			emptyTableMessage={t(`${USERS_PAGE_TRANSLATION_PREFIX}.permissionsTable.noPermissions`)}
			isLoading={isLoading}
		/>
	);
};
