export function validateFilterRelation(relation: unknown): relation is "and" | "or" | undefined {
	return relation === undefined || relation === "and" || relation === "or";
}
export function validateFilterEqualityOperator(operator: unknown): operator is "is" | "isNot" | undefined {
	return operator === undefined || operator === "is" || operator === "isNot";
}

export function validateFilterInclusionOperator(operator: unknown): operator is "contains" | "notContains" | undefined {
	return operator === undefined || operator === "contains" || operator === "notContains";
}

export function getMaintainerPossibleValue(maintainers: string[]): null | string[] {
	return maintainers.length === 1 && maintainers[0] === "null" ? null : maintainers;
}
