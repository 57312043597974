import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	approvalTaskBody: {
		display: "flex",
		flexDirection: "column",
		gap: "0.4rem",
		overflow: "hidden"
	},
	cardBody: {
		display: "flex",
		flexDirection: "column",
		width: "100%"
	},
	cardBodySection: {},
	cardFooter: {
		alignItems: "center",
		display: "flex",
		justifyContent: "start",
		width: "100%"
	},
	cardHeader: {
		color: "var(--color-grey-dark)",
		alignItems: "center",
		display: "flex",
		justifyContent: "space-between",
		width: "100%"
	},
	done: {},
	inProgress: {},
	cardRow: {
		"@media (min-width: 992px)": {
			display: "grid",
			gap: "0.3rem",
			gridTemplateColumns: "1fr 3fr",
			gridTemplateRows: "2rem auto"
		},
		width: "100%",
		alignItems: "center",
		boxShadow: "0px 1px 0px rgba(0, 0, 0, 0.08)"
	},
	sectionHeader: {
		"@media (max-width: 992px)": {
			alignItems: "center",
			color: "var(--color-grey-dark)",
			display: "flex",
			fontWeight: "bold"
		},
		alignItems: "center",
		color: "var(--color-grey-dark)",
		display: "flex"
	},
	sectionLabel: {
		alignItems: "center",
		display: "flex"
	},
	taskCard: {
		alignItems: "center",
		display: "flex",
		flexDirection: "column",
		gap: "1rem",
		justifyContent: "space-between",
		marginX: "0.25rem",
		padding: "1rem",
		color: "var(--color-grey-dark)",
		minWidth: "380px"
	},
	assignee: {
		width: "100%",
		display: "flex",
		alignItems: "center",
		gap: "1rem",
		paddingTop: "0.5rem",
		flexBasis: "content"
	},
	todo: {}
});
