import classNames from "classnames";
import React, { useMemo } from "react";
import { useStyles } from "./styles";
import { Typography, type TTypographyVariant } from "../Typography";

type TProps = {
	size?: "small" | "medium" | "large";
	title?: React.ReactNode;
	body?: React.ReactNode;
};

export const TitleBody: FC<TProps> = ({ body, className, innerRef, size = "medium", title }) => {
	const classes = useStyles({ size });

	const [titleVariant, bodyVariant]: [TTypographyVariant, TTypographyVariant] = useMemo(() => {
		switch (size) {
			case "small":
				return ["text_sm_sb", "text_sm_reg"];
			case "medium":
				return ["body_sb", "body_reg"];
			case "large":
				return ["title_sb", "body_reg"];
		}
	}, [size]);

	return (
		<div className={classNames(classes.container, className)} ref={innerRef}>
			{title ? <Typography variant={titleVariant}>{title}</Typography> : null}
			{body ? (
				<Typography variant={bodyVariant} className={classes.body}>
					{body}
				</Typography>
			) : null}
		</div>
	);
};
