import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	container: ({ maxLines }: { maxLines: number }) => ({
		lineClamp: maxLines,
		WebkitBoxOrient: "vertical",
		overflow: "hidden",
		display: "-webkit-box"
	})
});
