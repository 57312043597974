import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	autoAssignHelpBlock: {
		display: "inline-block"
	},
	titleContainer: {
		display: "flex",
		flexDirection: "row",
		flexGrow: 1,
		justifyContent: "space-between"
	}
});
