import React from "react";
import { makeIcon } from "components/ui/Icon";

export const ChevronUpIcon = makeIcon(
	<svg viewBox="0 0 24 24" fill="none">
		<path
			d="M6.70005 14.675C6.51672 14.4917 6.42505 14.2584 6.42505 13.975C6.42505 13.6917 6.51672 13.4584 6.70005 13.275L11.3 8.67502C11.4 8.57502 11.5084 8.50419 11.625 8.46252C11.7417 8.42086 11.8667 8.40002 12 8.40002C12.1334 8.40002 12.2584 8.42086 12.375 8.46252C12.4917 8.50419 12.6 8.57502 12.7 8.67502L17.325 13.3C17.5084 13.4834 17.6 13.7084 17.6 13.975C17.6 14.2417 17.5 14.475 17.3 14.675C17.1167 14.8584 16.8834 14.95 16.6 14.95C16.3167 14.95 16.0834 14.8584 15.9 14.675L12 10.775L8.07505 14.7C7.89172 14.8834 7.66672 14.975 7.40005 14.975C7.13338 14.975 6.90005 14.875 6.70005 14.675Z"
			fill="currentColor"
		/>
	</svg>
);
