import { createUseStyles } from "react-jss";

export const ROLE_BAR_BUNDLE_WIDTH_PX = "minmax(148px, 400px)";

export const useStyles = createUseStyles({
	container: {
		alignItems: "center",
		display: "grid",
		gap: "28px",
		gridTemplateColumns: ROLE_BAR_BUNDLE_WIDTH_PX,
		"&$withCheckbox": {
			gridTemplateColumns: `auto ${ROLE_BAR_BUNDLE_WIDTH_PX}`
		}
	},
	bundlePart: {
		alignItems: "center",
		display: "flex",
		gap: "4px",
		justifyContent: "space-between"
	},
	withCheckbox: {}
});
