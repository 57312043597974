import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	modalContent: {
		padding: "0 12px 12px 0",
		display: "flex",
		flexDirection: "column",
		gap: "20px"
	}
});
