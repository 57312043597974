import { useEffect, useMemo } from "react";
import { useUsersContext } from "context/usersContext";
import { devLog } from "utils/devtools/devLogging";
import { isUUID } from "utils/strings";

export const useUser = (id?: string | null, includeDeleted = true, full = false) => {
	const {
		state: { fullUsersState, users },
		actions: { loadUser, loadFullUser }
	} = useUsersContext();
	const fullUserState = useMemo(() => (id ? fullUsersState.get(id) || undefined : undefined), [fullUsersState, id]);
	const loading = fullUserState?.loading || false;
	const hadError = fullUserState?.hadError || false;

	const user = useMemo(() => {
		if (!id || !users) return undefined;
		let result = users.get(id) || undefined;
		if (full) {
			result = fullUserState?.data || undefined;
		}
		return includeDeleted ? result : !result?.isDeleted ? result : undefined;
	}, [id, users, full, includeDeleted, fullUserState]);

	useEffect(() => {
		if (!id || user || loading || hadError) return;
		if (!isUUID(id)) {
			devLog({
				extra: { stack: new Error().stack },
				level: "error",
				message: `Invalid UUID: ${id}`
			});
			return;
		}
		const isMissing = full ? !fullUserState : !users.has(id);
		if (!isMissing) return;

		if (full) {
			void loadFullUser(id);
		} else {
			loadUser(id);
		}
	}, [users, id, loadUser, user, loading, hadError, full, loadFullUser, fullUserState]);

	return user;
};
