import { INTEGRATION_FILTERS, INTEGRATION_FILTERS_BY_NAME } from "./integration";
import { INTEGRATION_RESOURCE_FILTERS, INTEGRATION_RESOURCE_FILTERS_BY_NAME } from "./integrationResource";
import {
	INTEGRATION_RESOURCE_ROLE_FILTERS,
	INTEGRATION_RESOURCE_ROLE_FILTERS_BY_NAME
} from "./integrationResourceRole";

const ALL_FILTERS = [...INTEGRATION_FILTERS, ...INTEGRATION_RESOURCE_FILTERS, ...INTEGRATION_RESOURCE_ROLE_FILTERS];

const ALL_FILTERS_BY_NAME = {
	...INTEGRATION_FILTERS_BY_NAME,
	...INTEGRATION_RESOURCE_FILTERS_BY_NAME,
	...INTEGRATION_RESOURCE_ROLE_FILTERS_BY_NAME
};

export type TFilterName = keyof typeof ALL_FILTERS_BY_NAME;

export {
	ALL_FILTERS,
	ALL_FILTERS_BY_NAME,
	INTEGRATION_FILTERS,
	INTEGRATION_RESOURCE_FILTERS,
	INTEGRATION_RESOURCE_ROLE_FILTERS
};
