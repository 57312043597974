import get from "lodash/get";
import { sourceIcons } from "./sourceIcons";
import type { TDirectory } from "types/directoryConfiguration";

export const directoryIconRegex = new RegExp(/\[([A-z]+)\] (.*)/);

export const getNameAndIcon = (value: string) => {
	const match = directoryIconRegex.exec(value);
	if (!match) {
		return {
			name: value,
			icon: null
		};
	}

	const iconString = match[1] as TDirectory;
	const name = match[2];
	const Icon = get(sourceIcons, iconString);

	return {
		name,
		Icon
	};
};
