import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { UserCircleIcon } from "components/ui/Icons/UserCircleIcon";
import { useStyles } from "./styles";
import { EntityNodeContent } from "../components/EntityNodeContent";
import { DeletedIndicator } from "../components/indicators/DeletedIndicator";
import { NameWithEmail } from "../components/NameWithEmail";
import { NameWithEmailNodeContent } from "../components/NameWithEmailNodeContent";
import { EntityLabel } from "../EntityLabel";
import type { TBaseEntityNodeProps } from "../types";

type TBaseUser = {
	fullName: string;
	imageUrl?: string | null;
	isDeleted?: boolean;
	email?: string;
};

type TUserWithEmail = TBaseUser & {
	email: string;
};

type TProps = Omit<TBaseEntityNodeProps, "size"> & {
	withIcon?: boolean;
} & (
		| {
				withoutEmail?: false;
				size?: "medium";
				user: TUserWithEmail;
		  }
		| { withoutEmail: true; user: TBaseUser; size: "small" | "medium" }
	);

export const UserEntity: FC<TProps> = ({
	user,
	withIcon,
	className,
	variant,
	size: propSize,
	inactive: propInactive,
	withoutEmail,
	...entityNodeProps
}) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const indicators = useMemo(() => {
		const indicators: React.ReactNode[] = [];
		if (user.isDeleted) {
			indicators.push(<DeletedIndicator />);
		}

		return indicators;
	}, [user.isDeleted]);

	const iconComponent = useMemo(() => {
		return user.imageUrl ? (
			<img src={user.imageUrl} className={classes.userImage} />
		) : (
			<UserCircleIcon className={classes.userImage} />
		);
	}, [user.imageUrl, classes.userImage]);

	const inactive = propInactive || user.isDeleted;
	const size = withoutEmail ? propSize : "medium";

	const userFullName = useMemo(() => user.fullName || t("common.unknown.user"), [user, t]);
	const content = useMemo(
		() =>
			withoutEmail ? (
				<EntityNodeContent
					tooltipOnOverflow
					text={userFullName}
					withIcon={withIcon}
					size={size}
					icon={iconComponent}
					variant={variant}
					inactive={inactive}
					{...entityNodeProps}
				/>
			) : (
				<NameWithEmailNodeContent
					text={<NameWithEmail name={userFullName} email={user.email} />}
					withIcon={withIcon}
					size={size}
					icon={iconComponent}
					inactive={inactive}
					{...entityNodeProps}
				/>
			),
		[userFullName, user.email, inactive, size, withoutEmail, withIcon, variant, entityNodeProps, iconComponent]
	);

	return <EntityLabel content={content} size={size} indicators={indicators} className={className} />;
};
