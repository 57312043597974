import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	container: {
		flexGrow: 1
	},
	groupContainer: {
		boxSizing: "border-box",
		display: "flex",
		flexDirection: "column",
		width: "100%"
	},
	groupLabel: {
		fontWeight: "var(--font-weight-bold)",
		pointerEvents: "none",
		paddingLeft: "var(--spacing-x5, 20px)",
		paddingRight: "var(--spacing-x4, 16px)",
		paddingTop: "var(--spacing-x3, 12px)",
		paddingBottom: "var(--spacing-x3, 12px)"
	},
	input: {
		"&:hover": {
			"& $suffixClear": {
				opacity: 1
			}
		}
	},
	inputChipsContainer: {
		alignItems: "center",
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
		gap: "0.25rem",
		width: "calc(100% - 2.9rem)",
		marginRight: "0.25rem"
	},
	inputContainer: {
		display: "grid",
		gridTemplateColumns: "auto minmax(40px, 1fr) auto"
	},
	inputHTMLComponent: {
		"&.hide": {
			height: "0",
			minWidth: "1rem",
			padding: "0",
			width: "0"
		},
		"&:disabled": {
			color: "var(--color-grey-dark)"
		},
		backgroundColor: "transparent",
		border: "none",
		fontFamily: "inherit",
		height: "100%",
		maxWidth: "100%",
		minWidth: "1rem",
		outline: "none"
	},
	maxHeight: {
		maxHeight: "35rem"
	},
	noOptions: {
		alignItems: "center",
		backgroundColor: "var(--color-white)",
		boxSizing: "border-box",
		cursor: "default",
		display: "flex",
		height: "3.4rem",
		minWidth: "100%",
		padding: "0.5rem 1rem",
		width: "fit-content"
	},
	prefixChip: {
		fontSize: "var(--typography-small-font-size)",
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap"
	},
	searchForMore: {
		alignItems: "center",
		display: "flex",
		justifyContent: "center",
		padding: "0.25rem 0"
	},
	selectItemsContainer: {
		backgroundColor: "var(--color-white)",
		borderRadius: "var(--border-radius-semi-rounded)",
		boxShadow: "var(--surface-popup-box-shadow)",
		boxSizing: "border-box",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		overflow: "auto",
		padding: "0.55rem 0",
		zIndex: "999"
	},
	suffix: {
		alignItems: "center",
		display: "flex",
		flexDirection: "row",
		gap: "0.25rem",
		height: "100%",
		justifyContent: "center"
	},
	suffixClear: {
		opacity: 0,
		transition: "opacity 0.1s",
		willChange: "opacity"
	}
});
