import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { search } from "api/accessRequestForm";
import { SearchIcon } from "components/ui/Icons/SearchIcon";
import { type IRenderChipParams, MultipleSelect } from "components/ui/MultipleSelect";
import { PermissionOption } from "components/ui/selectOptions/PermissionOption";
import { useSelectSearchProps } from "hooks/useSelectSearchProps";
import { SearchChip } from "../SearchChip";
import type { TNewTicketOption } from "components/pages/NewTicketPage/components/NewTicketForm/types";

const LIMIT = 100;

const equality = (option: TNewTicketOption, value: TNewTicketOption) => option.value.id === value.value.id;

const renderSearchInputChip = (options: IRenderChipParams<TNewTicketOption>) => {
	return <SearchChip {...options} />;
};

// label is not shown but because of how multi select works if we do not provide options with the field id or identifier
// the multi select will use the label as key, label is not used anyways so we can use the id as label and thus make it unique
const getOptionLabel = (option: TNewTicketOption) => option.value.id;

export const SearchInput: FC<{
	userId: string;
	selectedItems: TNewTicketOption[];
	setSelectedItems: (value: TNewTicketOption[]) => void;
	isItemConflicted: (item: TNewTicketOption) => boolean;
}> = ({ userId, selectedItems, setSelectedItems, isItemConflicted }) => {
	const { t } = useTranslation(undefined, { keyPrefix: "pages.newTicket.newTicketForm.searchInput" });

	const fetchSearchResults = useCallback(
		async (query: string) => {
			const response = await search({ search: query, userId, perPage: LIMIT });
			return response.result.toArray();
		},
		[userId]
	);

	const { selectProps, query } = useSelectSearchProps(fetchSearchResults, selectedItems, true);

	const onSelect = useCallback(
		(newValue: TNewTicketOption[] | null) => {
			setSelectedItems(newValue ?? []);
		},
		[setSelectedItems]
	);

	return (
		<MultipleSelect
			{...selectProps}
			filter={null}
			getOptionLabel={getOptionLabel}
			inputValue={query || ""}
			isOptionDisabled={isItemConflicted}
			isOptionEqualToValue={equality}
			label={t("label")}
			limit={LIMIT}
			noResultsText={t("noOptions")}
			onChange={onSelect}
			renderOption={PermissionOption}
			placeholder={t("placeholder")}
			renderChip={renderSearchInputChip}
			required
			sort={null}
			suffix={<SearchIcon />}
			value={selectedItems}
		/>
	);
};
