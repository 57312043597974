import React, { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { FilterExpressionEmptyState } from "components/common/FilterExpressionEmptyState";
import { IntegrationResourceTagsFilterExpression } from "components/pages/CreateRulePage/components/filters/IntegrationResourceTagsFilterExpression";
import { IntegrationResourceTypeFilterExpression } from "components/pages/CreateRulePage/components/filters/IntegrationResourceTypeFilterExpression";
import { Chip } from "components/ui/chips/Chip";
import { FilterExpression } from "components/ui/filters/FilterExpression";
import { ResourcesIcon } from "components/ui/Icons/ResourcesIcon";
import { RoleIcon } from "components/ui/Icons/RoleIcon";
import { OPERATORS as resourceNameOperators } from "filters/integrationResource/integrationResourceNameFilter";
import { OPERATORS as resourceTagsOperators } from "filters/integrationResource/integrationResourceTagsFilter";
import { OPERATORS as resourceTypeOperators } from "filters/integrationResource/integrationResourceTypeFilter";
import { OPERATORS as roleNameOperators } from "filters/integrationResourceRole/integrationResourceRoleNameFilter";
import { useOnMount } from "hooks/useOnMount";
import { Conditions, TCondition } from "models/RuleModel";
import type { TConditionName } from "filters/condition.interface";

const CONDITIONS_OPERATORS = new Map([
	["resourceType", resourceTypeOperators as NonNullable<Conditions["resourceType"]>["operator"][]],
	["resourceName", resourceNameOperators as NonNullable<Conditions["resourceName"]>["operator"][]],
	["resourceTags", resourceTagsOperators as NonNullable<Conditions["resourceTags"]>["operator"][]],
	["roleName", roleNameOperators as NonNullable<Conditions["roleName"]>["operator"][]]
]);

const getConditionIcon = (conditionName: TConditionName) => {
	switch (conditionName) {
		case "resourceName":
			return ResourcesIcon;
		case "roleName":
			return RoleIcon;
		default:
			return undefined;
	}
};

type TConditionExpressionProps = {
	conditionName: Exclude<TConditionName, "integration">;
	conditions: Conditions;
	removeCondition: (conditionName: TConditionName) => void;
	updateCondition: (conditionName: TConditionName, condition: TCondition) => void;
};

export const ConditionExpression: FC<TConditionExpressionProps> = ({
	conditionName,
	conditions,
	removeCondition,
	updateCondition
}) => {
	const { t } = useTranslation("translation", { keyPrefix: "filters" });
	const ref = useRef<HTMLDivElement | null>(null);
	const onRemoveFilter = useCallback(
		(conditionName: TConditionName) => {
			removeCondition(conditionName);
		},
		[removeCondition]
	);

	const onResetFilter = useCallback(
		(conditionName: TConditionName) => {
			updateCondition(conditionName, { ...conditions.get(conditionName)!, value: [] });
		},
		[updateCondition, conditions]
	);

	const renderSelected = useCallback(
		(conditionName: TConditionName, condition: TCondition | undefined, value: string) => {
			if (condition === undefined) return null;
			const onDelete = () =>
				updateCondition(conditionName, { ...condition, value: condition.value.filter(val => val !== value) });

			return (
				<Chip onDelete={onDelete} PrefixIcon={getConditionIcon(conditionName)} variant="regular" size="large" selected>
					{`"${value}"`}
				</Chip>
			);
		},
		[updateCondition]
	);

	useOnMount(() => {
		ref.current?.scrollIntoView({ behavior: "smooth", block: "start" });
	});

	if (conditions.get(conditionName) === undefined) return null;

	switch (conditionName) {
		case "resourceType":
			return (
				<IntegrationResourceTypeFilterExpression
					innerRef={ref}
					filter={conditions["resourceType"]!}
					filterName={conditionName}
					updateFilter={updateCondition}
					removeFilter={onRemoveFilter}
				/>
			);
		case "resourceTags":
			return (
				<IntegrationResourceTagsFilterExpression
					innerRef={ref}
					filter={conditions["resourceTags"]!}
					filterName={conditionName}
					updateFilter={updateCondition}
					removeFilter={onRemoveFilter}
				/>
			);
		default: {
			const condition = conditions.get(conditionName)!;
			const operators = CONDITIONS_OPERATORS.get(conditionName)!;
			const onOptionSelect = (value: string) =>
				updateCondition(conditionName, {
					...condition,
					value: condition.value.includes(value) ? condition.value : [...condition.value, value]
				});
			const removeFilter = () => onRemoveFilter(conditionName);
			const resetFilter = () => onResetFilter(conditionName);
			const renderValue = (value: string) => renderSelected(conditionName, condition, value);
			return (
				<FilterExpression
					applyOnBlur
					emptyState={<FilterExpressionEmptyState text={t(`emptyState.${conditionName}`)} Icon={RoleIcon} />}
					innerRef={ref}
					inputPlaceholder={t(`placeholders.${conditionName}`)}
					onOperatorSelect={operator =>
						updateCondition(conditionName, {
							...condition,
							operator: operator as NonNullable<TCondition>["operator"]
						})
					}
					onOptionSelect={onOptionSelect}
					onRemoveFilter={removeFilter}
					onReset={resetFilter}
					operators={operators}
					relation="or"
					renderSelected={renderValue}
					selected={condition.value}
					selectedOperator={condition.operator ?? operators[0]}
					title={t(`filterNames.${conditionName}`)}
					type="multiText"
				/>
			);
		}
	}
};
