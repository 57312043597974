import { useEffect, RefObject } from "react";

export function useOnClickOutside(
	ref: RefObject<HTMLElement> | HTMLElement | undefined,
	callback: (event: MouseEvent | TouchEvent) => void,
	wrapperRef?: RefObject<HTMLElement>
) {
	useEffect(() => {
		const listener = (event: MouseEvent | TouchEvent) => {
			// Do nothing if clicking ref's element or descendent elements
			if (!ref || !callback) return;
			if (isRef(ref)) {
				if (!ref.current || ref.current.contains(event.target as Node)) {
					return;
				}
			} else if (ref.contains(event.target as Node)) {
				return;
			}

			callback(event);
		};

		const currentWrapper = wrapperRef?.current;
		if (currentWrapper) {
			currentWrapper.addEventListener("mousedown", listener);
			currentWrapper.addEventListener("touchstart", listener);
		}
		document.addEventListener("mousedown", listener);
		document.addEventListener("touchstart", listener);

		return () => {
			if (currentWrapper) {
				currentWrapper.removeEventListener("mousedown", listener);
				currentWrapper.removeEventListener("touchstart", listener);
			}
			document.removeEventListener("mousedown", listener);
			document.removeEventListener("touchstart", listener);
		};
	}, [ref, callback, wrapperRef]);
}

const isRef = (value: unknown): value is RefObject<HTMLElement> => {
	return value instanceof Object && "current" in value;
};
