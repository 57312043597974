import constate from "constate";
import { useMemo } from "react";
import { TBreakpointState as TBpState, useBreakpoint } from "hooks/useBreakpoint";
import { Bp } from "utils/ui/breakpoints";

export type TBreakpointState = TBpState;
export type TBreakpointsState = {
	state: {
		TEN: TBreakpointState;
		TWENTY: TBreakpointState;
		THIRTY: TBreakpointState;
		FORTY: TBreakpointState;
		FIFTY: TBreakpointState;
		SIXTY: TBreakpointState;
		SEVENTY: TBreakpointState;
		EIGHTY: TBreakpointState;
		currentBp: Bp;
	};
};

const useBreakpoints = (): TBreakpointsState => {
	const TEN = useBreakpoint(Bp.TEN);
	const TWENTY = useBreakpoint(Bp.TWENTY);
	const THIRTY = useBreakpoint(Bp.THIRTY);
	const FORTY = useBreakpoint(Bp.FORTY);
	const FIFTY = useBreakpoint(Bp.FIFTY);
	const SIXTY = useBreakpoint(Bp.SIXTY);
	const SEVENTY = useBreakpoint(Bp.SEVENTY);
	const EIGHTY = useBreakpoint(Bp.EIGHTY);

	const currentBp = useMemo(() => {
		// The order matters
		const isExact = [EIGHTY, SEVENTY, SIXTY, FIFTY, FORTY, THIRTY, TWENTY, TEN].find(bp => bp.isExact)!;
		return isExact?.breakingPoint || Bp.TEN;
	}, [TEN, TWENTY, THIRTY, FORTY, FIFTY, SIXTY, SEVENTY, EIGHTY]);

	return { state: { TEN, TWENTY, THIRTY, FORTY, FIFTY, SIXTY, SEVENTY, EIGHTY, currentBp } };
};

export const [BreakpointsProvider, useBreakpointsContext] = constate(useBreakpoints);
