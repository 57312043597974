import { Record } from "immutable";
import uniq from "lodash/uniq";
import { validateFilterEqualityOperator, validateFilterRelation } from "utils/validation/filterValidation";
import type { IFilter } from "types/filters";

const filterName = "integrationResourceRoleIntegrationResourceTags";

const defaults = {
	name: filterName as typeof filterName,
	value: [] as string[],
	operator: "is" as "is" | "isNot",
	relation: "or" as "and" | "or"
};

type TDefaults = typeof defaults;

export class IntegrationResourceRoleIntegrationResourceTagsFilter
	extends Record<TDefaults>(defaults)
	implements IFilter<TDefaults["value"], TDefaults["operator"], TDefaults["name"]>
{
	static filterName: typeof filterName = filterName;

	static fromURLSearchParams(
		urlSearchParams: URLSearchParams
	): IntegrationResourceRoleIntegrationResourceTagsFilter | null {
		const integrationResourceRoleIntegrationResourceTags = uniq(
			urlSearchParams.getAll("integrationResourceRoleIntegrationResourceTags")
		);
		const integrationResourceRoleIntegrationResourceTagsOperator =
			urlSearchParams.get("integrationResourceRoleIntegrationResourceTagsOperator") || undefined;
		const integrationResourceRoleIntegrationResourceTagsRelation =
			urlSearchParams.get("integrationResourceRoleIntegrationResourceTagsRelation") || undefined;
		if (
			integrationResourceRoleIntegrationResourceTags.length === 0 ||
			!validateFilterEqualityOperator(integrationResourceRoleIntegrationResourceTagsOperator) ||
			!validateFilterRelation(integrationResourceRoleIntegrationResourceTagsRelation)
		) {
			return null;
		}
		return new IntegrationResourceRoleIntegrationResourceTagsFilter({
			value: integrationResourceRoleIntegrationResourceTags,
			operator: integrationResourceRoleIntegrationResourceTagsOperator,
			relation: integrationResourceRoleIntegrationResourceTagsRelation
		});
	}

	toURLSearchParams(): URLSearchParams {
		const params = new URLSearchParams();
		if (!this.value.length) {
			return params;
		}
		for (const value of uniq(this.value)) {
			params.append("integrationResourceRoleIntegrationResourceTags", value);
		}
		params.append("integrationResourceRoleIntegrationResourceTagsOperator", this.operator);
		params.append("integrationResourceRoleIntegrationResourceTagsRelation", this.relation);
		return params;
	}
}

export type TIntegrationResourceRoleIntegrationResourceTagsFilter = Omit<TDefaults, "name">;
