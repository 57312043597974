import constate from "constate";
import { useCallback } from "react";
import {
	getIdentityProviders,
	createIdentityProvider as apiCreateIdentityProvider,
	deleteIdentityProvider as apiDeleteIdentityProvider
} from "api/identityProviders";
import { useFetchedState } from "hooks/useFetchedState";

const useIdentityProviders = () => {
	const {
		data: identityProviders,
		loadData: loadIdentityProviders,
		retryAction,
		isLoading
	} = useFetchedState(getIdentityProviders);

	const createIdentityProvider = useCallback(
		async (application: string, metadataUrl: string) => {
			await apiCreateIdentityProvider(application, metadataUrl);
			await retryAction();
		},
		[retryAction]
	);

	const deleteIdentityProvider = useCallback(
		async (id: string) => {
			await apiDeleteIdentityProvider(id);
			await retryAction();
		},
		[retryAction]
	);

	return {
		state: { identityProviders, isLoading },
		actions: { loadIdentityProviders, createIdentityProvider, deleteIdentityProvider }
	};
};

export const [IdentityProvidersProvider, useIdentityProvidersContext] = constate(useIdentityProviders);
