import { ChangeEvent, useCallback, useRef, useState } from "react";

export const useImageUpload = (handleImageUpload: (image: File) => Promise<void>) => {
	const fileInputRef = useRef<HTMLInputElement>(null);
	// Used as indication for reloading the image
	const [imageTimestamp, setImageTimestamp] = useState(Date.now());

	const handleButtonClick = useCallback(() => {
		fileInputRef.current?.click();
	}, []);

	const resizeImage = useCallback(async (file: File) => {
		// No node runtime in browser, we must use the HTML canvas API
		const imageBlob = new Blob([file]);
		const image = await createImageBitmap(imageBlob);
		const canvas = document.createElement("canvas");
		const targetSize = Math.min(image.height, image.width, 256);
		canvas.width = targetSize;
		canvas.height = targetSize;
		const ctx = canvas.getContext("2d");
		ctx?.drawImage(image, 0, 0, targetSize, targetSize);

		return new Promise<File | undefined>((resolve, _reject) => {
			canvas.toBlob(blob => {
				if (!blob) {
					return;
				}

				const resizedFile = new File([blob], file.name, { type: file.type });
				resolve(resizedFile);
			});
		});
	}, []);

	const onFileSelection = useCallback(
		async (event: ChangeEvent<HTMLInputElement>) => {
			// Files is a `FileList`, which uses `item` instead of `at`.
			const file = event.target.files?.item(0);
			if (file && file.type.startsWith("image/")) {
				const resizedFile = await resizeImage(file);
				if (!resizedFile) {
					throw new Error("Failed uploading image");
				}

				await handleImageUpload(resizedFile);
				setImageTimestamp(Date.now());
			}
		},
		[handleImageUpload, resizeImage]
	);

	return {
		fileInputRef,
		imageTimestamp,
		handleButtonClick,
		onFileSelection
	};
};
