import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	pagination: {
		display: "inline-flex",
		justifyContent: "space-between",
		padding: "0.85rem 1.15rem",
		width: "100%"
	},
	paginationPages: {
		alignItems: "center",
		display: "inline-flex",
		gap: "0.25rem",
		justifyContent: "center"
	},
	break: {
		textAlign: "center",
		width: "2rem"
	}
});
