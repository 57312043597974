import { List } from "immutable";
import { useCallback, useEffect, useState } from "react";
import type { TOwnerOption } from "components/pages/CreateIntegrationPage/components/OwnerSelect";
import type { TTicketDuration } from "utils/durationsOptions";

export type TFormData = {
	owner: TOwnerOption;
	resourceType: string | undefined;
	allowsRequests: boolean;
	allowedDurations: List<TTicketDuration> | undefined;
	tags: string[] | undefined | null;
	description: string | undefined;
	name: string;
	overrideAllowedDurations?: boolean;
};

type TStateArgs = {
	propOwner: TOwnerOption;
	propAllowsRequests: boolean;
	propAllowedDurations: List<TTicketDuration> | undefined;
	propOnSave: (formData: TFormData) => Promise<void>;
	propResourceType?: string;
	propDescription?: string;
	propTags?: string[] | null;
	propName: string;
	propOverrideAllowedDurations?: boolean;
};

export const useSettingsPopupState = ({
	propAllowedDurations,
	propAllowsRequests,
	propOnSave,
	propOwner,
	propDescription,
	propResourceType,
	propTags,
	propName,
	propOverrideAllowedDurations = false
}: TStateArgs) => {
	const [allowedDurations, setAllowedDurations] = useState(propAllowedDurations || List<TTicketDuration>());
	const [allowsRequests, setAllowsRequests] = useState(propAllowsRequests);
	const [description, setDescription] = useState(propDescription);
	const [name, setName] = useState(propName);
	const [overrideAllowedDurations, setOverrideAllowedDurations] = useState(propOverrideAllowedDurations);
	const [owner, setOwner] = useState(propOwner);
	const [resourceType, setResourceType] = useState(propResourceType);
	const [tags, setTags] = useState(propTags);

	useEffect(() => {
		if (propAllowedDurations) {
			setAllowedDurations(propAllowedDurations);
		}
	}, [propAllowedDurations]);

	const toggleAllowsRequests = useCallback(() => setAllowsRequests(curr => !curr), []);

	const onClose = useCallback(() => {
		setName(propName);
		setResourceType(propResourceType);
		setDescription(propDescription);
		setTags(propTags);
		setAllowedDurations(propAllowedDurations || List<TTicketDuration>());
		setOverrideAllowedDurations(propOverrideAllowedDurations);
		setAllowsRequests(propAllowsRequests);
		setOwner(propOwner);
	}, [
		propAllowedDurations,
		propAllowsRequests,
		propDescription,
		propName,
		propOverrideAllowedDurations,
		propOwner,
		propResourceType,
		propTags
	]);

	const onSave = useCallback(async () => {
		await propOnSave({
			owner,
			allowedDurations: overrideAllowedDurations ? allowedDurations : undefined,
			resourceType,
			description,
			allowsRequests,
			tags,
			name
		});
	}, [
		propOnSave,
		owner,
		overrideAllowedDurations,
		allowedDurations,
		resourceType,
		description,
		allowsRequests,
		tags,
		name
	]);

	return {
		owner,
		allowsRequests,
		overrideAllowedDurations,
		allowedDurations,
		resourceType,
		description,
		tags,
		name,
		setOwner,
		toggleAllowsRequests,
		setOverrideAllowedDurations,
		setAllowedDurations,
		onSave,
		onClose,
		setResourceType,
		setDescription,
		setTags,
		setName
	};
};
