import { createUseStyles } from "react-jss";
import { BORDER_SIZE } from "../constants";

export const INTEGRATION_NODE_WIDTH = 200;

export const useStyles = createUseStyles({
	alignedContent: {
		alignItems: "center",
		display: "flex",
		flexDirection: "row",
		gap: "4px",
		minWidth: 0
	},
	container: {
		alignItems: "center",
		backgroundColor: "var(--color-white)",
		border: `${BORDER_SIZE}px solid var(--color-blue-500)`,
		borderRadius: "4px 4px 20px 20px",
		display: "flex",
		flexDirection: "column",
		height: "fit-content",
		justifyContent: "center",
		minHeight: "44px",
		minWidth: `${INTEGRATION_NODE_WIDTH}px`,
		width: `${INTEGRATION_NODE_WIDTH}px`,
		"&$selected": {
			backgroundColor: "var(--color-blue-500)",
			"& > $integrationHeaderContainer": {
				backgroundColor: "var(--color-blue-500)",
				color: "var(--color-white)"
			}
		}
	},
	contentContainer: {
		minWidth: 0,
		padding: "8px 12px",
		width: "100%"
	},
	contentWithActions: {
		alignItems: "center",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between"
	},
	integrationHeaderContainer: {
		alignItems: "center",
		borderBottom: `${BORDER_SIZE}px solid var(--color-blue-500)`,
		display: "flex",
		flexDirection: "row",
		gap: "4px",
		height: "44px",
		justifyContent: "center",
		padding: "8px 12px",
		width: `calc(${INTEGRATION_NODE_WIDTH}px - 6px)`
	},
	selected: {}
});
