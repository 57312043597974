import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { getIntegrations } from "api/accessRequestForm";
import { IntegrationChip } from "components/ui/chips/IntegrationChip";
import { IRenderChipParams, MultipleSelect } from "components/ui/MultipleSelect";
import { IntegrationOption } from "components/ui/selectOptions/IntegrationOption";
import { useSelectSearchProps } from "hooks/useSelectSearchProps";
import { IntegrationModel } from "models/IntegrationModel";

const LIMIT = 100;

interface IProps {
	setIntegrations: (value: IntegrationModel[] | null) => void;
	integrations: IntegrationModel[] | null;
	userId: string;
}

const getLabel = (option: IntegrationModel) => option.name;
const equality = (option: IntegrationModel, value: IntegrationModel) => option.id === value.id;

export const IntegrationsInput: FC<IProps> = ({ userId, setIntegrations, integrations }) => {
	const { t } = useTranslation(undefined, { keyPrefix: "pages.newTicket.newTicketForm.integrationsInput" });

	const renderChip = useCallback(({ option, componentKey, ...rest }: IRenderChipParams<IntegrationModel>) => {
		return <IntegrationChip selected onDelete={rest.onRemove} integration={option.id} variant="regular" />;
	}, []);

	const fetchIntegrations = useCallback(
		async (query: string) => {
			const response = await getIntegrations({ userId, search: query, perPage: LIMIT });
			return response.result.toArray();
		},
		[userId]
	);

	const { selectProps } = useSelectSearchProps(fetchIntegrations, integrations, true);

	return (
		<MultipleSelect
			{...selectProps}
			filter={null}
			getOptionLabel={getLabel}
			isOptionEqualToValue={equality}
			label={t("label")}
			limit={LIMIT}
			limitChipType="integration"
			onChange={setIntegrations}
			renderOption={IntegrationOption}
			placeholder={t("placeholder")}
			renderChip={renderChip}
			required
			sort={null}
			value={integrations}
		/>
	);
};
