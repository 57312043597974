import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { RequestDetails } from "components/common/RequestDetails";
import { PageTitleContent } from "components/templates/PageTitleContentTemplate";
import { MyPermissionsTableSection } from "./components/MyPermissionsTableSection";
import { useStyles } from "./styles";

export const MyPermissionsPage: FC = ({ className, innerRef }) => {
	const { t } = useTranslation();
	const classes = useStyles();

	const breadcrumbs = useMemo(() => {
		return [
			{
				title: t("navigation.requests"),
				url: "/requests"
			},
			{
				title: t("pages.myPermissions.title")
			}
		];
	}, [t]);

	return (
		<PageTitleContent innerRef={innerRef} className={className}>
			<RequestDetails />
			<PageTitleContent.Header>
				<PageTitleContent.Header.Top breadcrumbs={breadcrumbs} />
				<PageTitleContent.Header.Bottom>
					<PageTitleContent.Header.Title title={t("pages.myPermissions.title")} />
				</PageTitleContent.Header.Bottom>
			</PageTitleContent.Header>
			<PageTitleContent.Body className={classes.pageBody}>
				<MyPermissionsTableSection />
			</PageTitleContent.Body>
		</PageTitleContent>
	);
};
