import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { AreYouSureModal } from "components/common/AreYouSureModal";
import { Button } from "components/ui/Button";
import { IconButton } from "components/ui/IconButton";
import { DeleteIcon } from "components/ui/Icons/DeleteIcon";
import { ViewIcon } from "components/ui/Icons/ViewIcon";
import { Tooltip } from "components/ui/Tooltip";
import { usePersonalAccessTokensContext } from "context/personalAccessTokensContext";
import { useIsOpenState } from "hooks/useIsOpenState";
import { useStyles } from "./styles";
import { checkIfTokenIsNew } from "../../../utils";
import type { TPersonalAccessToken } from "../../../PersonalAccessTokensTableSection";

type TProps = {
	token: TPersonalAccessToken;
	handleDeleteNewRow: () => void;
	handleTokenCreation: () => Promise<void>;
	setIsTokenNew: (isNew: boolean) => void;
	disabled: boolean;
};

const PREFIX = "pages.profile.changeProfileForm.personalAccessTokens";

export const ActionsCell: FC<TProps> = ({
	token,
	handleDeleteNewRow,
	handleTokenCreation,
	setIsTokenNew,
	disabled
}) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { open: openDeleteModal, close: closeDeleteModal, isOpen: isDeleteModalOpen } = useIsOpenState();

	const {
		actions: { deleteToken }
	} = usePersonalAccessTokensContext();

	const handleDelete = useCallback(async () => {
		if (!checkIfTokenIsNew(token)) await deleteToken(token.id);
		setIsTokenNew(false);
	}, [deleteToken, setIsTokenNew, token]);

	return "isTokenNew" in token ? (
		<div className={classes.actionsCell}>
			<Tooltip content={t(`${PREFIX}.viewToken`)}>
				<IconButton size="medium" disabled={disabled} onClick={handleTokenCreation}>
					<ViewIcon />
				</IconButton>
			</Tooltip>
			<Tooltip content={t(`${PREFIX}.deleteToken`)}>
				<IconButton size="medium" onClick={handleDeleteNewRow}>
					<DeleteIcon />
				</IconButton>
			</Tooltip>
		</div>
	) : (
		<>
			<AreYouSureModal
				onClose={closeDeleteModal}
				isOpen={isDeleteModalOpen}
				onAction={handleDelete}
				actionLabel={t(`${PREFIX}.areYouSure.action`)}
				title={t(`${PREFIX}.areYouSure.title`)}
				content={t(`${PREFIX}.areYouSure.content`)}
				closeLabel={t(`${PREFIX}.areYouSure.cancel`)}
			/>
			<div className={classes.actionsCell}>
				<Button onClick={openDeleteModal} variant="secondary" size="medium">
					{t("buttons.revoke")}
				</Button>
			</div>
		</>
	);
};
