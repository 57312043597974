import React, { useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { AreYouSureModal } from "components/common/AreYouSureModal";
import { useGoBack } from "hooks/useGoBack";
import { useRuleStepperContext } from "../../ruleStepperContext";

type TExitUpsertRuleModalProps = {
	close: () => void;
	isOpen: boolean;
	editMode?: boolean;
};
export const ExitUpsertRuleModal: FC<TExitUpsertRuleModalProps> = ({
	isOpen,
	close,
	editMode = false,
	className,
	innerRef
}) => {
	const {
		state: { dirty }
	} = useRuleStepperContext();
	const { t } = useTranslation("translation", {
		keyPrefix: editMode ? "pages.createRule.exitEditRuleModal" : "pages.createRule.exitCreateRuleModal"
	});

	const goBack = useGoBack();
	const onGoBack = useCallback(() => {
		close();
		goBack();
	}, [close, goBack]);

	useEffect(() => {
		if (isOpen && !dirty) {
			onGoBack();
		}
	}, [isOpen, dirty, onGoBack]);

	if (!dirty) return null;
	return (
		<AreYouSureModal
			className={className}
			innerRef={innerRef}
			isOpen={isOpen}
			onClose={close}
			onCancel={onGoBack}
			onAction={close}
			title={t("title")}
			actionLabel={t("stayLabel")}
			closeLabel={t("cancelLabel")}
			content={t("content")}
		/>
	);
};
