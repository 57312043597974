import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ChipContainer } from "components/ui/ChipContainer";
import { AccountChip } from "components/ui/chips/AccountChip";
import { StaticChip } from "components/ui/chips/StaticChip";
import { IconButton } from "components/ui/IconButton";
import { AddIcon } from "components/ui/Icons/AddIcon";
import { useIntegrations } from "hooks/useIntegrations";
import { useStyles } from "./styles";
import { AreYouSureModal } from "../AreYouSureModal";
import { IntegrationEntity } from "../entities";
import type { IntegrationActorModel } from "models/IntegrationActorModel";
import type { IntegrationModel } from "models/IntegrationModel";

const CHIPS_AMOUNT = 1;

const IntegrationActorBlockHeader: FC<{
	integrationId: string;
	integrationActors: IntegrationActorModel[];
	addActor: (integration: IntegrationModel) => void;
}> = ({ integrationId, addActor, integrationActors }) => {
	const integrations = useIntegrations(true);
	const integration = useMemo(() => integrations?.get(integrationId), [integrations, integrationId]);
	const { t } = useTranslation();
	const classes = useStyles();

	const openAddActor = useCallback(() => {
		if (integration) addActor(integration);
	}, [addActor, integration]);

	if (!integration) return null;

	return (
		<div className={classes.blockHeader}>
			<div className={classes.integrationName}>
				<IntegrationEntity withIcon integration={integration} size="medium" />
				<StaticChip size="small" variant="regular">
					{t("number", { value: integrationActors.length })}
				</StaticChip>
			</div>
			<IconButton onClick={openAddActor}>
				<AddIcon />
			</IconButton>
		</div>
	);
};

export const IntegrationAccountsBlock: FC<{
	integrationId: string;
	integrationActors: IntegrationActorModel[];
	deleteActor: (actor: IntegrationActorModel) => Promise<void>;
	addActorForIntegration: (integration: IntegrationModel) => void;
}> = ({ integrationId, integrationActors, deleteActor, addActorForIntegration }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const [integrationActorToDelete, setIntegrationActorToDelete] = useState<IntegrationActorModel>();

	const onDeleteActor = useCallback((actor?: IntegrationActorModel) => {
		setIntegrationActorToDelete(curr => {
			if (curr) {
				return actor ? curr : undefined;
			}
			return actor;
		});
	}, []);

	const chips = useMemo(() => {
		const accountChips = integrationActors.map(actor => (
			<AccountChip key={actor.id} size="large" variant="regular" onDelete={() => onDeleteActor(actor)}>
				{actor.displayName}
			</AccountChip>
		));
		return (
			<ChipContainer
				maxChipsToDisplay={CHIPS_AMOUNT}
				className={classes.chipsContainer}
				chips={accountChips}
				size="large"
				limitChipType="account"
			/>
		);
	}, [classes.chipsContainer, integrationActors, onDeleteActor]);

	const clearActorToDelete = useCallback(() => {
		setIntegrationActorToDelete(undefined);
	}, []);

	const deleteSelectedActor = useCallback(async () => {
		if (integrationActorToDelete) {
			await deleteActor(integrationActorToDelete);
		}
		clearActorToDelete();
	}, [clearActorToDelete, deleteActor, integrationActorToDelete]);

	return (
		<div className={classes.blockContainer}>
			<AreYouSureModal
				isOpen={Boolean(integrationActorToDelete)}
				actionLabel={t("pages.user.accountsTab.removeAccountModal.action")}
				content={t("pages.user.accountsTab.removeAccountModal.description")}
				title={t("pages.user.accountsTab.removeAccountModal.title")}
				closeLabel={t("buttons.cancel")}
				onAction={deleteSelectedActor}
				onClose={clearActorToDelete}
			/>
			<IntegrationActorBlockHeader
				addActor={addActorForIntegration}
				integrationActors={integrationActors}
				integrationId={integrationId}
			/>
			{chips}
		</div>
	);
};
