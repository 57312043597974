import classNames from "classnames";
import React from "react";
import { useStyles } from "./styles";
import { LoadingSpinner } from "../LoadingSpinner";
import { Overlay } from "../Overlay";

export const LoadingOverlay: FC<LoadingOverlayProps> = ({ isOpen, className }) => {
	const classes = useStyles();
	return (
		<Overlay isOpen={isOpen} className={classNames(classes.loadingOverlay, className)}>
			<LoadingSpinner />
		</Overlay>
	);
};

type LoadingOverlayProps = {
	isOpen: boolean;
};
