import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	sourceIconContainer: {
		alignItems: "center",
		overflow: "hidden",
		textOverflow: "ellipsis",
		display: "block",
		maxWidth: "100%",
		justifyContent: "center",
		gap: "0.25em"
	},
	sourceIcon: {
		height: "var(--icon-medium-size)",
		width: "var(--icon-medium-size)",
		minWidth: "1em",
		padding: "0.125rem",
		fill: "var(--text-color)"
	},
	entity: {
		gap: "0.2rem"
	}
});
