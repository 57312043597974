import { useCallback, useEffect } from "react";
import { useAccessReviewReportsContext } from "context/accessReviewReportsContext";
import { useLoadingState } from "./useLoadingState";

export const useAccessReviewReport = (id: string | undefined) => {
	const {
		state: { accessReviewReports },
		actions: { getAccessReviewReport: contextGetAccessReviewReport }
	} = useAccessReviewReportsContext();

	const { isLoading: getIsLoading, withLoader: getWithLoader } = useLoadingState();

	const getAccessReviewReport = useCallback(async () => {
		if (!id) {
			return null;
		}
		return getWithLoader(contextGetAccessReviewReport(id));
	}, [getWithLoader, contextGetAccessReviewReport, id]);

	const accessReviewReport = accessReviewReports.get(id || "");

	useEffect(() => {
		if (id && !accessReviewReport) {
			void getAccessReviewReport();
		}
	}, [accessReviewReports, accessReviewReport, getAccessReviewReport, id]);

	return {
		accessReviewReport,
		getAccessReviewReport,
		getActionLoading: getIsLoading
	};
};
