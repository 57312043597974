import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	logo: {
		width: "24px",
		height: "24px",
		verticalAlign: "middle",
		flexShrink: 0
	},
	optionContainer: {
		display: "flex",
		alignItems: "center",
		gap: "var(--spacing-x2, 4px)",
		overflow: "hidden"
	},
	optionLabelContainer: {
		display: "flex",
		alignItems: "center",
		gap: "var(--spacing-x2, 4px)",
		overflow: "hidden",
		maxWidth: "100%"
	},
	labelLogo: {
		margin: 0
	}
});
