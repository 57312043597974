import React from "react";
import { makeIcon } from "components/ui/Icon";

export const AccountIcon = makeIcon(
	<svg viewBox="0 0 24 24" fill="none">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M10 12C8.9 12 7.95833 11.6083 7.175 10.825C6.39167 10.0417 6 9.1 6 8C6 6.9 6.39167 5.95833 7.175 5.175C7.95833 4.39167 8.9 4 10 4C11.1 4 12.0417 4.39167 12.825 5.175C13.6083 5.95833 14 6.9 14 8C14 9.1 13.6083 10.0417 12.825 10.825C12.0417 11.6083 11.1 12 10 12ZM8 17C8 18.1256 8.37194 19.1643 8.99963 20H4C3.45 20 2.97917 19.8042 2.5875 19.4125C2.19583 19.0208 2 18.55 2 18V17.2C2 16.6333 2.14583 16.1125 2.4375 15.6375C2.72917 15.1625 3.11667 14.8 3.6 14.55C4.63333 14.0333 5.68333 13.6458 6.75 13.3875C7.81651 13.1292 8.89968 13 9.99951 13C8.78534 13.9122 8 15.3644 8 17ZM10.875 19.125C11.4583 19.7083 12.1667 20 13 20C13.6333 20 14.2083 19.8125 14.725 19.4375C15.2417 19.0625 15.6167 18.5833 15.85 18H19V19C19 19.2833 19.0958 19.5208 19.2875 19.7125C19.4792 19.9042 19.7167 20 20 20C20.2833 20 20.5208 19.9042 20.7125 19.7125C20.9042 19.5208 21 19.2833 21 19V18C21.2833 18 21.5208 17.9042 21.7125 17.7125C21.9042 17.5208 22 17.2833 22 17C22 16.7167 21.9042 16.4792 21.7125 16.2875C21.5208 16.0958 21.2833 16 21 16H15.85C15.6167 15.4167 15.2417 14.9375 14.725 14.5625C14.2083 14.1875 13.6333 14 13 14C12.1667 14 11.4583 14.2917 10.875 14.875C10.2917 15.4583 10 16.1667 10 17C10 17.8333 10.2917 18.5417 10.875 19.125ZM13.7125 17.7125C13.5208 17.9042 13.2833 18 13 18C12.7167 18 12.4792 17.9042 12.2875 17.7125C12.0958 17.5208 12 17.2833 12 17C12 16.7167 12.0958 16.4792 12.2875 16.2875C12.4792 16.0958 12.7167 16 13 16C13.2833 16 13.5208 16.0958 13.7125 16.2875C13.9042 16.4792 14 16.7167 14 17C14 17.2833 13.9042 17.5208 13.7125 17.7125Z"
			fill="currentColor"
		/>
	</svg>
);
