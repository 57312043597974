import React from "react";
import { makeIcon } from "components/ui/Icon";

export const NoEditIcon = makeIcon(
	<svg viewBox="0 0 24 24" fill="none">
		<path
			d="M17.0226 7.02083L13.5434 10.4792C13.3906 10.6319 13.1996 10.7118 12.9705 10.7188C12.7413 10.7257 12.5434 10.6458 12.3767 10.4792C12.2239 10.3264 12.1476 10.1319 12.1476 9.89583C12.1476 9.65972 12.2239 9.46528 12.3767 9.3125L13.5017 8.1875L12.3142 7L11.1892 8.125C11.0364 8.27778 10.842 8.35417 10.6059 8.35417C10.3698 8.35417 10.1753 8.27778 10.0226 8.125C9.86977 7.97222 9.79339 7.77778 9.79339 7.54167C9.79339 7.30556 9.86977 7.11111 10.0226 6.95833L13.5017 3.47917C13.6684 3.3125 13.8524 3.19097 14.0538 3.11458C14.2552 3.03819 14.467 3 14.6892 3C14.9114 3 15.1267 3.04167 15.3351 3.125C15.5434 3.20833 15.7239 3.33333 15.8767 3.5L17.0226 4.66667C17.1892 4.81944 17.3107 5 17.3871 5.20833C17.4635 5.41667 17.5017 5.625 17.5017 5.83333C17.5017 6.05556 17.4635 6.26736 17.3871 6.46875C17.3107 6.67014 17.1892 6.85417 17.0226 7.02083ZM4.16839 16.3333H5.35589L9.41839 12.2708L8.83505 11.6667L8.23089 11.0833L4.16839 15.1458V16.3333ZM15.9184 18.75L10.6059 13.4583L6.52255 17.5208C6.36977 17.6736 6.19269 17.7917 5.9913 17.875C5.78991 17.9583 5.57811 18 5.35589 18H3.33505C3.09894 18 2.90102 17.9201 2.7413 17.7604C2.58158 17.6007 2.50172 17.4028 2.50172 17.1667V15.1667C2.50172 14.9444 2.54339 14.7326 2.62672 14.5312C2.71005 14.3299 2.82811 14.1528 2.98089 14L7.06422 9.91667L1.75172 4.58333C1.58505 4.41667 1.50519 4.22222 1.51214 4C1.51908 3.77778 1.60589 3.58333 1.77255 3.41667C1.93922 3.25 2.13714 3.16667 2.3663 3.16667C2.59547 3.16667 2.79339 3.25 2.96005 3.41667L17.1059 17.5625C17.2726 17.7292 17.3524 17.9271 17.3455 18.1562C17.3385 18.3854 17.2517 18.5833 17.0851 18.75C16.9184 18.9167 16.7239 19 16.5017 19C16.2795 19 16.0851 18.9167 15.9184 18.75ZM8.83505 11.6667L8.23089 11.0833L9.41839 12.2708L8.83505 11.6667Z"
			fill="currentColor"
		/>
	</svg>
);
