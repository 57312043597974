import constate from "constate";
import { useCallback } from "react";
import { changeUsersRole, getAdmins as apiGetAdmins } from "api/users";
import { useFetchedState } from "hooks/useFetchedState";
import { TAdminUser } from "models/UserModel";

const useAdmins = () => {
	const { data: admins, setData: setAdmins, loadData: loadAdmins } = useFetchedState(apiGetAdmins);

	const addAdmin = useCallback(
		async (userId: string) => {
			const adminUser = (await changeUsersRole(userId, "admin")) as TAdminUser;
			setAdmins(current => (current ? current.set(adminUser.id, adminUser) : null));
		},
		[setAdmins]
	);

	const removeAdmin = useCallback(
		async (userId: string) => {
			const adminUser = (await changeUsersRole(userId, "regular")) as TAdminUser;
			setAdmins(current => current!.remove(adminUser.id));
		},
		[setAdmins]
	);

	return {
		state: { admins },
		actions: { loadAdmins, addAdmin, removeAdmin }
	};
};

export const [AdminsProvider, useAdminsContext] = constate(useAdmins);
