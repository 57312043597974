import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	operatorBlock: {
		"&$operatorBlock$operatorBlock$operatorBlock": {
			justifyContent: "center",
			padding: "0.27rem 0.57rem"
		}
	}
});
