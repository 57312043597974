import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	cell: {
		alignItems: "center",
		display: "flex",
		justifyContent: "center",
		width: "100%"
	},
	link: {
		color: "var(--typography-link-font-color)"
	},
	resource: {
		gap: "0.5rem"
	},
	titleContainer: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		width: "100%"
	},
	ownerContainer: {
		display: "flex",
		flexDirection: "column",
		gap: "2px",
		alignItems: "flex-start"
	}
});
