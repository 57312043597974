import React from "react";
import { makeIcon } from "components/ui/Icon";

export const ArrowUpIcon = makeIcon(
	<svg viewBox="0 0 24 24" fill="none">
		<path
			d="M12 20C11.7167 20 11.4792 19.9042 11.2875 19.7125C11.0959 19.5209 11 19.2834 11 19V7.82505L6.12502 12.7C5.92502 12.9 5.68752 13 5.41252 13C5.13752 13 4.90002 12.9 4.70002 12.7C4.50002 12.5 4.40002 12.2667 4.40002 12C4.40002 11.7334 4.50002 11.5 4.70002 11.3L11.3 4.70005C11.4 4.60005 11.5084 4.52922 11.625 4.48755C11.7417 4.44588 11.8667 4.42505 12 4.42505C12.1334 4.42505 12.2625 4.44588 12.3875 4.48755C12.5125 4.52922 12.6167 4.60005 12.7 4.70005L19.3 11.3C19.5 11.5 19.6 11.7334 19.6 12C19.6 12.2667 19.5 12.5 19.3 12.7C19.1 12.9 18.8625 13 18.5875 13C18.3125 13 18.075 12.9 17.875 12.7L13 7.82505V19C13 19.2834 12.9042 19.5209 12.7125 19.7125C12.5209 19.9042 12.2834 20 12 20Z"
			fill="currentColor"
		/>
	</svg>
);
