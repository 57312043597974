import { List, Record } from "immutable";
import { PrerequisitePermissionClauseModel } from "./PrerequisitePermissionClauseModel";

const defaults = {
	id: "",
	prerequisitePermissionClauses: List<PrerequisitePermissionClauseModel>()
};

export class PrerequisitePermissionModel extends Record<typeof defaults>(defaults) {
	static fromServerData(data: unknown) {
		const { id, prerequisitePermissionClauses } = data as typeof defaults;

		return new PrerequisitePermissionModel({
			id,
			prerequisitePermissionClauses: List(
				prerequisitePermissionClauses.map(clause => PrerequisitePermissionClauseModel.fromServerData(clause))
			)
		});
	}
}
