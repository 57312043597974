import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	card: {
		"&$card$card$card": {
			padding: "var(--spacing-x3, 12px) var(--spacing-x10, 40px)"
		}
	},
	container: {
		alignItems: "baseline",
		display: "flex",
		gap: "var(--spacing-x05, 2px)"
	},
	clickable: {
		"&:hover": {
			cursor: "pointer"
		}
	}
});
