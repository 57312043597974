import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	container: {
		display: "flex",
		minWidth: "100px",
		maxWidth: "240px",
		maxHeight: "288px",
		justifyContent: "flex-start",
		alignItems: "flex-start",
		overflow: "auto",
		flexDirection: "column",
		gap: "var(--spacing-x3, 12px)",
		paddingBottom: "var(--spacing-x1, 8px)",
		paddingTop: "var(--spacing-x1, 8px)"
	},
	singleChip: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end"
	}
});
