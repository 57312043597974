import { Record } from "immutable";

const defaults = {
	content: "",
	createdAt: new Date(0),
	creatorId: "",
	id: "",
	isDeleted: false,
	updatedAt: new Date(0)
};

export class AccessReviewPermissionCommentModel extends Record<typeof defaults>(defaults) {
	static fromServerData(data: unknown): AccessReviewPermissionCommentModel {
		const { content, createdAt, creatorId, id, isDeleted, updatedAt } = data as typeof defaults;

		return new AccessReviewPermissionCommentModel({
			content,
			createdAt: new Date(createdAt),
			creatorId,
			id,
			isDeleted,
			updatedAt: new Date(updatedAt)
		});
	}
}
