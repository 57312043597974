import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { TForwardType, createForward, deleteForward, updateForward } from "api/forwards";
import { UserEntity } from "components/common/entities/UserEntity";
import { UserSelect } from "components/pages/SettingsPage/components/UserSelect";
import { Button } from "components/ui/Button";
import { Table } from "components/ui/Table";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { useUser } from "hooks/useUser";
import { ForwardModel } from "models/ForwardModel";
import { useStyles } from "./styles";

interface IProps {
	forward: ForwardModel;
	onSave: (forward: ForwardModel) => void;
	onCancel: () => void;
	type: TForwardType;
}

interface ICreateProps {
	create: true;
	existingForwardersIds?: string[];
	edit?: never;
	enterEdit?: never;
	onDelete?: never;
}

interface IExistingProps {
	edit: boolean;
	enterEdit: (id: string) => void;
	onDelete: (forwardId: string) => void;
	create?: never;
	existingForwardersIds?: never;
}

type TProps = IProps & (ICreateProps | IExistingProps);
export const Forward: FC<TProps> = ({
	forward,
	create = false,
	onCancel,
	enterEdit,
	edit = false,
	existingForwardersIds,
	onSave,
	onDelete,
	type
}) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const handleError = useOpenGlobalErrorModal();

	const [inputForwarderId, setInputForwarderId] = useState<string>(forward.forwarderUserId);
	const [inputTargetId, setInputTargetId] = useState<string>(forward.targetUserId);
	const excludedTargets = useMemo(() => [inputForwarderId], [inputForwarderId]);
	const forwarder = useUser(forward.forwarderUserId);
	const target = useUser(forward.targetUserId);

	const isValid = useMemo(() => Boolean(inputTargetId) && Boolean(inputForwarderId), [inputTargetId, inputForwarderId]);

	const handleSave = useCallback(async () => {
		try {
			let newForward: ForwardModel;
			if (create) {
				newForward = await createForward(type, inputForwarderId, inputTargetId);
			} else {
				newForward = await updateForward(type, forward.id, inputTargetId);
			}
			onSave(newForward);
		} catch (error) {
			handleError(error as Error);
		}
	}, [create, forward, handleError, inputForwarderId, inputTargetId, onSave, type]);

	const handleDelete = useCallback(async () => {
		try {
			await deleteForward(type, forward.id);
			if (onDelete) {
				onDelete(forward.id);
			}
		} catch (error) {
			handleError(error as Error);
		}
	}, [forward, handleError, onDelete, type]);

	const handleEdit = useCallback(() => {
		if (enterEdit) {
			enterEdit(forward.id);
		}
	}, [enterEdit, forward]);

	return (
		<Table.Row>
			<Table.Cell>
				{create && (
					<UserSelect
						setUserId={setInputForwarderId}
						userId={inputForwarderId}
						excludedUserIds={existingForwardersIds}
					/>
				)}
				{!create && forwarder && <UserEntity withIcon user={forwarder} />}
			</Table.Cell>

			<Table.Cell>
				{(create || edit) && (
					<UserSelect setUserId={setInputTargetId} userId={inputTargetId} excludedUserIds={excludedTargets} />
				)}
				{!(create || edit) && target && <UserEntity withIcon user={target} />}
			</Table.Cell>

			<Table.Cell className={classes.actionsContainer}>
				{create || edit ? (
					<>
						<Button variant="text" size="small" disabled={!isValid} onClick={handleSave}>
							{t("buttons.save")}
						</Button>
						<Button variant="text" size="small" onClick={onCancel}>
							{t("buttons.cancel")}
						</Button>
					</>
				) : (
					<>
						<Button variant="text" size="small" onClick={handleEdit}>
							{t("buttons.edit")}
						</Button>
						<Button variant="text" size="small" onClick={handleDelete}>
							{t("buttons.delete")}
						</Button>
					</>
				)}
			</Table.Cell>
		</Table.Row>
	);
};
