import type { List } from "immutable";
import type { TicketPermissionModel } from "models/TicketPermissionModel";

export const getTicketExpirationDate = (ticketPermissions: List<TicketPermissionModel> | null) => {
	return ticketPermissions && ticketPermissions.count() > 0
		? ticketPermissions.reduce(
				(maxDate, { expiresAt }) => {
					if (!maxDate || !expiresAt) return null;
					return expiresAt > maxDate ? expiresAt : maxDate;
				},
				new Date(0) as Date | null
			) || null
		: null;
};
