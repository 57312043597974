import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "components/ui/Button";
import { AddIcon } from "components/ui/Icons/AddIcon";
import { Typography } from "components/ui/legacy/Typography";
import { LoadingSpinner } from "components/ui/LoadingSpinner";
import { Table } from "components/ui/Table";
import { Title } from "components/ui/Title";
import { useAdminsContext } from "context/adminsContext";
import { useAdmins } from "hooks/useAdmins";
import { AdminRow } from "./Admin";
import { useStyles } from "./styles";

export const AdminsManagement: FC = () => {
	const classes = useStyles();
	const [newUser, setNewUser] = useState<boolean>(false);
	const { t } = useTranslation();

	const {
		actions: { addAdmin, removeAdmin }
	} = useAdminsContext();

	const admins = useAdmins();

	const openNewUser = useCallback(() => setNewUser(true), []);
	const closeNewUser = useCallback(() => setNewUser(false), []);
	const saveAdmin = useCallback(
		async (userId: string) => {
			if (newUser) {
				await addAdmin(userId);
				setNewUser(false);
			}
		},
		[addAdmin, newUser]
	);

	return (
		<>
			<Title variant="h3" noBorder className={classes.title} extraMargin>
				<span>{t("pages.settings.adminsManagement.title")}</span>
				<Button onClick={openNewUser} variant="secondary" size="medium" prefix={<AddIcon />}>
					{t("buttons.add")}
				</Button>
			</Title>
			<div>
				{!!admins || <LoadingSpinner />}
				<Table gridColumns="1fr 15rem">
					<Table.Row>
						<Table.Header>
							<Typography variant="small">{t("shared.Name")}</Typography>
						</Table.Header>
					</Table.Row>
					{admins ? (
						<>
							{newUser && <AdminRow create createHandler={saveAdmin} closeInput={closeNewUser} />}
							{admins.map(admin => (
								<AdminRow key={admin.id} admin={admin} removeHandler={removeAdmin} />
							))}
						</>
					) : (
						<LoadingSpinner />
					)}
				</Table>
			</div>
		</>
	);
};
