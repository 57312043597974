import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	fieldNameText: {
		display: "flex",
		alignItems: "center",
		textWrap: "nowrap",
		alignSelf: "stretch"
	},
	updatesCellContainer: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-start",
		gap: "var(--spacing-x3, 12px)"
	},
	updateContainer: {
		display: "flex",
		justifyContent: "flex-start",
		alignItems: "center",
		height: "30px",
		gap: "var(--spacing-x2, 8px)",
		width: "100%"
	},
	disabledRuleTooltip: {
		minWidth: "424px"
	},
	chipContainer: {
		overflow: "hidden"
	}
});
