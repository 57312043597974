import React from "react";
import { useNewTicketFormContext } from "components/pages/NewTicketPage/newTicketContext";
import { Form } from "components/ui/Form";
import { IntegrationActorModel } from "models/IntegrationActorModel";
import { IntegrationActorSelectInput } from "../IntegrationActorSelectInput";

interface IProps {
	integrationId: string;
}
const OPTIONS_LIMIT = 100;

export const IntegrationActorField: FC<IProps> = ({ integrationId }) => {
	const {
		state: {
			formState: { integrationActors, receiverIntegrationActorIds }
		},
		actions: {
			formActions: { setIntegrationActor }
		}
	} = useNewTicketFormContext();

	return (
		<Form.Field>
			<IntegrationActorSelectInput
				integrationId={integrationId}
				options={(integrationActors!.get(integrationId) as IntegrationActorModel[]) ?? []}
				onChange={setIntegrationActor}
				multiple
				selected={receiverIntegrationActorIds.get(integrationId) || null}
				limit={OPTIONS_LIMIT}
			/>
		</Form.Field>
	);
};
