import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	button: {
		alignItems: "center",
		border: "none",
		borderWidth: "2px",
		display: "inline-flex",
		justifyContent: "flex-start",
		transitionProperty: "background-color, border-color, color",
		transitionDuration: "0.3s",
		transitionTimingFunction: "ease-out",
		"&$disabled": {
			transition: "none"
		},
		"&:hover:not($disabled):not($loading)": {
			cursor: "pointer"
		}
	},
	small: {
		borderWidth: "1px",
		borderRadius: "4px",
		gap: "4px",
		height: "20px",
		padding: "2px 8px",
		"& > $prefixOrSuffix": {
			maxHeight: "12px",
			maxWidth: "12px",
			"& > div:has(> svg)": {
				maxHeight: "12px",
				maxWidth: "12px",
				fontSize: "12px"
			}
		}
	},
	medium: {
		borderRadius: "6px",
		gap: "4px",
		height: "32px",
		padding: "2px 8px",
		"& > $prefixOrSuffix": {
			maxHeight: "20px",
			maxWidth: "20px",
			"& > div:has(> svg)": {
				maxHeight: "20px",
				maxWidth: "20px",
				fontSize: "20px"
			}
		}
	},
	large: {
		borderRadius: "6px",
		gap: "8px",
		height: "44px",
		padding: "2px 16px",
		"& > $prefixOrSuffix": {
			maxHeight: "24px",
			maxWidth: "24px",
			"& > div:has(> svg)": {
				maxHeight: "24px",
				maxWidth: "24px",
				fontSize: "24px"
			}
		}
	},
	primary: {
		backgroundColor: "var(--button-primary-background-color-regular)",
		color: "var(--button-primary-color)",
		"&:hover": {
			backgroundColor: "var(--button-primary-background-color-hover)"
		},
		"&:active": {
			backgroundColor: "var(--button-primary-background-color-pressed)"
		},
		"&$disabled": {
			backgroundColor: "var(--button-primary-background-color-disabled)"
		}
	},
	secondary: {
		backgroundColor: "var(--button-secondary-background-color-regular)",
		borderStyle: "solid",
		borderColor: "var(--button-secondary-border-color)",
		color: "var(--button-secondary-color)",
		"&:hover": {
			backgroundColor: "var(--button-secondary-background-color-hover)"
		},
		"&:active": {
			backgroundColor: "var(--button-secondary-background-color-pressed)"
		},
		"&$disabled": {
			backgroundColor: "var(--button-secondary-background-color-disabled)",
			borderColor: "var(--button-secondary-border-color-disabled)",
			color: "var(--button-secondary-color-disabled)"
		}
	},
	text: {
		backgroundColor: "var(--button-text-background-color-regular)",
		color: "var(--button-text-color)",
		"&:hover": {
			backgroundColor: "var(--button-text-background-color-hover)"
		},
		"&:active": {
			backgroundColor: "var(--button-text-background-color-pressed)"
		},
		"&$disabled": {
			backgroundColor: "var(--button-text-background-color-disabled)",
			color: "var(--button-text-color-disabled)"
		}
	},
	prefixOrSuffix: {
		alignItems: "center",
		display: "flex",
		justifyContent: "center"
	},
	disabled: {},
	loading: {},
	innerText: {
		minWidth: 0
	}
});
