import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "components/ui/Button";
import { SyncIcon } from "components/ui/Icons/SyncIcon";
import { useLoadingState } from "hooks/useLoadingState";

interface IProps {
	propsOnClick: () => Promise<void>;
	nextSyncTime: Date | null;
	withIcon?: boolean;
}

export const SyncButton: FC<IProps> = ({ propsOnClick, nextSyncTime, withIcon = false }) => {
	const { t } = useTranslation();
	const nextSyncInMs = nextSyncTime ? nextSyncTime.valueOf() - new Date().valueOf() : 0;
	const nextSyncInMinutes = Math.ceil(nextSyncInMs / 60000);

	const [isDisabled, setIsDisabled] = useState(nextSyncInMs > 0);
	const { withLoader, isLoading } = useLoadingState();
	const [tooltipText, setTooltipText] = useState(
		isDisabled ? t("common.syncLimiter.waitFor", { duration: nextSyncInMinutes }) : t("common.syncLimiter.tooltip")
	);
	const [_, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

	useEffect(() => {
		if (nextSyncInMs > 0) {
			setIsDisabled(true);
			setTooltipText(t("common.syncLimiter.waitFor", { duration: nextSyncInMinutes }));
			setTimeoutId(timeoutId => {
				if (timeoutId) {
					clearTimeout(timeoutId);
				}
				return setTimeout(() => {
					setIsDisabled(false);
					setTooltipText("");
				}, nextSyncInMs);
			});
		}
	}, [nextSyncInMinutes, nextSyncInMs, t]);

	const onClick = useCallback(() => {
		void withLoader(propsOnClick());
	}, [propsOnClick, withLoader]);

	return (
		<Button
			variant="text"
			size="medium"
			prefix={withIcon ? <SyncIcon /> : undefined}
			tooltip={tooltipText}
			disabled={isDisabled || isLoading}
			loading={isLoading}
			onClick={onClick}>
			{t("common.syncLimiter.sync")}
		</Button>
	);
};
