import { useState, RefObject, useCallback, useEffect } from "react";
import { useResizeDetector } from "react-resize-detector";

export const useIsScrollHorizontal = (elementRef: RefObject<HTMLElement | null>) => {
	const [isOverflowedLeft, setIsOverflowedLeft] = useState(false);
	const [isOverflowedRight, setIsOverflowedRight] = useState(false);

	const calculateOverflow = useCallback(() => {
		const element = elementRef.current;
		const scrollPosition = element?.scrollLeft || 0;
		const containerWidth = element?.clientWidth || 0;
		const scrollWidth = element?.scrollWidth || 0;
		const newIsOverflowLeft = scrollPosition > 0;
		const newIsOverflowRight = scrollPosition + containerWidth < scrollWidth;

		if (isOverflowedLeft !== newIsOverflowLeft) {
			setIsOverflowedLeft(newIsOverflowLeft);
		}
		if (isOverflowedRight !== newIsOverflowRight) {
			setIsOverflowedRight(newIsOverflowRight);
		}
	}, [elementRef, isOverflowedLeft, isOverflowedRight]);

	useEffect(() => {
		const element = elementRef.current;
		if (!element) return;
		element.addEventListener("scroll", calculateOverflow);
		return () => {
			element.removeEventListener("scroll", calculateOverflow);
		};
	});

	useResizeDetector({
		targetRef: elementRef,
		handleWidth: true,
		handleHeight: false,
		onResize: calculateOverflow
	});

	return { isOverflowedLeft, isOverflowedRight, calculateOverflow };
};
