import classNames from "classnames";
import React from "react";
import { Typography } from "components/ui/legacy/Typography";
import { Unknown } from "components/ui/Unknown";
import { UserModel } from "models/UserModel";
import { useStyles } from "./styles";
import { UserEntity } from "../entities/UserEntity";

export const UserOption: FC<{ option: UserModel; noEmail?: boolean }> = ({ option, className, noEmail = false }) => {
	const classes = useStyles();

	return (
		<Typography variant="tiny" component="div" className={classNames(classes.container, className)}>
			<Unknown unknown={!option}>
				{option ? <UserEntity withIcon withoutEmail={noEmail} size="medium" user={option} /> : null}
			</Unknown>
		</Typography>
	);
};
