import { Map } from "immutable";
import { ApprovalFlowsWebhookModel } from "models/ApprovalFlowsWebhookModel";
import { OnCallIntegrationScheduleModel } from "models/OnCallIntegrationScheduleModel";
import { UserModel } from "models/UserModel";
import { withPagination, type IPaginationResponse } from "utils/pagination";
import { type IPaginatedSearchOptions, getPaginatedSearchQueryString } from "utils/searchUtils";
import { requirePropertyOf } from "utils/security";
import { DirectoryGroupModel } from "../models/DirectoryGroupModel";
import { apiReq } from "../utils/api/apiReq";

export type TCustomerEntityType = "user" | "directoryGroup" | "onCallIntegrationSchedule" | "approvalFlowWebhook";

type TCustomerEntityModelTypes =
	| typeof UserModel
	| typeof DirectoryGroupModel
	| typeof OnCallIntegrationScheduleModel
	| typeof ApprovalFlowsWebhookModel;

export type TCustomerEntityModels = InstanceType<TCustomerEntityModelTypes>;

export interface ICustomerEntity {
	id: string;
	type: TCustomerEntityType;
	entity: TCustomerEntityModels;
}

const ENTITY_TO_MODEL = Map<TCustomerEntityType, TCustomerEntityModelTypes>([
	["user", UserModel],
	["directoryGroup", DirectoryGroupModel],
	["onCallIntegrationSchedule", OnCallIntegrationScheduleModel],
	["approvalFlowWebhook", ApprovalFlowsWebhookModel]
]);

export async function getPaginatedCustomerEntities(
	paginationOptions?: IPaginatedSearchOptions,
	{ entities, withDeleted = false }: { entities?: TCustomerEntityType[]; withDeleted?: boolean } = {}
): Promise<IPaginationResponse<ICustomerEntity>> {
	return withPagination(
		(customerEntity: Record<string, unknown>, index) => {
			const type = requirePropertyOf(customerEntity, "type") as TCustomerEntityType;
			const Model = ENTITY_TO_MODEL.get(type)!;
			return {
				id: `customerEntity${index}`,
				type,
				entity: Model.fromServerData(customerEntity.entity)
			};
		},
		() => {
			const paginationQs = getPaginatedSearchQueryString(paginationOptions);
			const entitiesQs = entities ? entities.map(entity => `&entities=${entity}`).join("") : "";
			const url = `/v1/customerEntities?withDeleted=${withDeleted}${paginationQs ? `&${paginationQs}` : ""}${entitiesQs || ""}`;
			return apiReq("GET", url);
		}
	);
}
