import React, { useMemo, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserEntity } from "components/common/entities/UserEntity";
import { FloatingSelect } from "components/common/FloatingSelect";
import { LegacyInheritOwnerOption } from "components/common/InheritOwnerOption";
import { ChevronDownIcon } from "components/ui/Icons/ChevronDownIcon";
import { useInlineInputState } from "hooks/useInlineInputState";
import { useIntegrations } from "hooks/useIntegrations";
import { useUser } from "hooks/useUser";
import { useUsersSelect } from "hooks/useUsersSelect";
import { useStyles } from "./styles";
import type { UserModel } from "models/UserModel";

type Props = {
	ownerId: string | null;
	setOwner: (owner: UserModel | null) => void | Promise<void>;
	integrationId: string;
};
const UserOptionContent: FC<{
	// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents -- Show inherit option
	option: { label: string; value: string | `inherit-${string}` };
	onClick: (event: React.MouseEvent) => void;
}> = ({ option, onClick }) => {
	const classes = useStyles();

	const isInherit = option.value.startsWith("inherit-");
	const id = isInherit ? option.value.replace("inherit-", "") : option.value;
	const owner = useUser(id);
	if (!owner) return <div onClick={onClick} />;
	return (
		<div onClick={onClick} className={classes.userInlineOptions}>
			{isInherit ? (
				<LegacyInheritOwnerOption emphasis="text" user={owner} wrap />
			) : (
				<UserEntity withIcon noWrap={false} user={owner} />
			)}
		</div>
	);
};
const UserOption = (
	option: { label: string; value: string },
	props: { key: string; onClick: (event: React.MouseEvent) => void }
) => <UserOptionContent key={props.key} onClick={props.onClick} option={option} />;

export const OwnerInlineSelect: FC<Props> = ({ ownerId, setOwner, integrationId }) => {
	const { t } = useTranslation();
	const { onClose, onHover, onLeave, onOpen, optionsOpen, showArrow } = useInlineInputState();
	const classes = useStyles({ showArrow });
	const integrations = useIntegrations();
	const [query, setQuery] = useState("");
	const { items: users } = useUsersSelect(query, { includeDeleted: false });
	const integration = integrations?.get(integrationId);
	const integrationOwnerId = integration?.ownerId;

	const owner = useUser(ownerId || integrationOwnerId);
	const inheritUser = useUser(integrationOwnerId);
	const ownerOptions = useMemo(() => {
		const normalizedUsers = users?.toArray() || [];
		const defaultOptions = inheritUser ? [{ value: `inherit-${inheritUser.id}`, label: inheritUser.fullName }] : [];
		return defaultOptions.concat(
			normalizedUsers
				.map(user => ({
					value: user.id,
					label: user.fullName
				}))
				.sort((a, b) => a.label.localeCompare(b.label))
		);
	}, [inheritUser, users]);

	const onOwnerChange = useCallback(
		(value: string) => {
			if (!users) return;
			const isInherit = value.startsWith("inherit-");
			void setOwner(isInherit ? null : users.find(({ id }) => id === value) || null);
			onClose();
		},
		[setOwner, users, onClose]
	);

	if (!owner) return null;
	return (
		<div className={classes.inlineInputContainer}>
			<FloatingSelect
				filter
				placeholder={t("pages.integration.searchPerson")}
				position="bottom-start"
				renderOption={UserOption}
				options={ownerOptions}
				onSelect={onOwnerChange}
				onInputChange={setQuery}
				inputValue={query}
				onClose={onClose}
				sort={null}
				open={optionsOpen}
				className={classes.ownerListContainer}
				optionsClassName={classes.list}>
				<div
					onMouseOver={onHover}
					onMouseLeave={onLeave}
					onClick={onOpen}
					className={classes.inlineInputLabelContainer}>
					{ownerId === null ? (
						<LegacyInheritOwnerOption emphasis="user" user={owner} wrap />
					) : (
						<UserEntity withIcon noWrap user={owner} />
					)}
					<ChevronDownIcon className={classes.arrowDownInline} />
				</div>
			</FloatingSelect>
		</div>
	);
};
