import React from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "components/ui/legacy/Typography";
import { LoadingSpinner } from "components/ui/LoadingSpinner";
import { Table } from "components/ui/Table";
import { Title } from "components/ui/Title";
import { CompanyModel } from "models/CompanyModel";
import { DirectoryIntegrations } from "./components/DirectoryIntegrations";
import { HRIntegrations } from "./components/HRIntegrations";
import { NotificationIntegrations } from "./components/NotificationIntegrations";
import { OnCallIntegrations } from "./components/OnCallIntegrations";
import { TicketingIntegrations } from "./components/TicketingIntegrations";

export const Integrations: FC<{ company: CompanyModel }> = ({ company }) => {
	const { t } = useTranslation();

	return (
		<>
			<Title variant="h3" noBorder extraMargin>
				{t("pages.settings.integrations.title")}
			</Title>
			<div>
				{company ? (
					<Table gridColumns="1fr 1fr 15rem">
						<Table.Row>
							<Table.Header>
								<Typography variant="small">{t("pages.settings.integrations.columns.application")}</Typography>
							</Table.Header>
							<Table.Header>
								<Typography variant="small">{t("pages.settings.integrations.columns.status")}</Typography>
							</Table.Header>
						</Table.Row>
						<NotificationIntegrations />
						<HRIntegrations company={company} />
						<TicketingIntegrations company={company} />
						<OnCallIntegrations company={company} />
						<DirectoryIntegrations />
					</Table>
				) : (
					<LoadingSpinner />
				)}
			</div>
		</>
	);
};
