import type { i18nText } from "utils/i18n";

type TErrorActions = {
	onClose?: () => void;
	onRetry?: () => void;
	closeText?: i18nText | null; // null means no close button
	retryText?: i18nText | null; // null means no retry text
};

export abstract class EntitleError extends Error {
	readonly title: string | null;
	readonly actions?: TErrorActions;
	suppressError = false;

	constructor(message?: string, title?: string, actions?: TErrorActions, suppressError = false) {
		super(message);
		this.title = title || null;
		this.actions = actions;
		this.suppressError = suppressError;
	}

	getTexts(): TErrorTexts {
		return {
			title: this.title,
			message: this.message
		};
	}

	getActions(): TErrorActions {
		return this.actions || {};
	}
}

export type TErrorTexts = {
	title: string | null;
	message: string | null;
};
