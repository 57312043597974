import { createUseStyles } from "react-jss";
import { BP_MEDIA_QUERIES } from "utils/ui/breakpoints";

export const useStyles = createUseStyles({
	container: {
		display: "none",
		gap: "8px",
		minWidth: "160px",
		maxWidth: "220px",
		[BP_MEDIA_QUERIES.SIXTY]: {
			display: "flex",
			flexDirection: "column",
			alignItems: "flex-start",
			justifyContent: "flex-start",
			flexGrow: 1,
			flexShrink: 1
		}
	},
	image: {
		height: "32px",
		width: "32px",
		borderRadius: "50%"
	},
	tooltipContent: {
		display: "flex",
		flexDirection: "column",
		gap: "8px"
	},
	tooltipHeader: {
		display: "flex",
		flexDirection: "row",
		gap: "var(--spacing-x2, 8px)",
		alignItems: "center",
		justifyContent: "flex-start"
	}
});
