import constate from "constate";
import { List, Map, OrderedMap } from "immutable";
import isEqual from "lodash/isEqual";
import hash from "object-hash";
import React, { useCallback, useRef, useState } from "react";
import {
	getIntegrations,
	getIntegrationsResources,
	getIntegrationResourcesRoles,
	getAllowedDurations,
	search,
	getBundles,
	getBundle,
	getIntegrationResourceRole,
	getIntegrationResource,
	getIntegration,
	getTicket
} from "api/accessRequestForm";
import { Provider } from "context/Provider";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { BundleModel } from "models/BundleModel";
import { IntegrationModel } from "models/IntegrationModel";
import { IntegrationResourceModel } from "models/IntegrationResourceModel";
import { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";
import { toMapBy, toOrderedMapBy } from "utils/toMapBy";
import type { ITargetData } from "api/tickets";
import type { TicketModel } from "models/TicketModel";
import type { Require } from "types/utilTypes";
import type { TTicketDuration } from "utils/durationsOptions";
import type { TNewTicketOption } from "../NewTicketPage/components/NewTicketForm/types";

type TLoadingState = "Initial" | "Loading" | "Loaded" | "Error";

type TIntegrationResourceQueryData = {
	integrationResources: OrderedMap<string, IntegrationResourceModel>;
	totalAmount: number;
	lastSearch?: string;
};
type TIntegrationResourceMap = OrderedMap<string, TIntegrationResourceQueryData>;

type TIntegrationResourceRoleQueryData = {
	integrationResourceRoles: Map<string, IntegrationResourceRoleModel>;
	totalAmount: number;
	lastSearch?: string;
};
type TIntegrationResourceRoleMap = Map<string, TIntegrationResourceRoleQueryData>;

type TWithLastUserIdObject<T> = T & { lastUserId?: string };

type TBundlesMap = {
	bundles: Map<string, Require<BundleModel, "bundleItems">>;
	totalAmount: number;
};
type TIntegrationsMap = { integrations: Map<string, IntegrationModel>; totalAmount: number };

type TAllResourcesState = { map: TIntegrationResourceMap; lastUserId?: string };
type TAllRolesState = { map: TIntegrationResourceRoleMap; lastUserId?: string };

const useLastQuery = () => {
	const lastQuery = useRef<string>();

	const setLastQuery = useCallback((query: string) => {
		lastQuery.current = query;
	}, []);

	const getLastQuery = useCallback(() => lastQuery.current, []);

	return { setLastQuery, getLastQuery };
};

const useQueryOnRequest = <T extends Record<string, unknown>, R>(
	fetchMethod: (options: T) => Promise<R>
): {
	fetch: (options: T) => Promise<R | null>;
	loadingState: TLoadingState;
} => {
	const { setLastQuery, getLastQuery } = useLastQuery();
	const loadingStateRef = useRef(Map<string, TLoadingState>());
	const [loadingState, setLoadingState] = useState(Map<string, TLoadingState>());
	const openGlobalErrorModal = useOpenGlobalErrorModal();

	const fetch = useCallback(
		async (options: T) => {
			const hashKey = hash(options);

			const lastQuery = getLastQuery();

			if (loadingStateRef.current.get(hashKey) !== "Error") {
				if (
					(!lastQuery && !options) ||
					isEqual(lastQuery, hashKey) ||
					loadingStateRef.current.get(hashKey) === "Loading"
				)
					return null;
			}

			loadingStateRef.current = loadingStateRef.current.set(hashKey, "Loading");
			setLoadingState(loadingStateRef.current);
			setLastQuery(hashKey);
			try {
				const result = await fetchMethod(options);
				loadingStateRef.current = loadingStateRef.current.set(hashKey, "Loaded");
				setLoadingState(loadingStateRef.current);
				return result;
			} catch (error) {
				loadingStateRef.current = loadingStateRef.current.set(hashKey, "Error");
				setLoadingState(loadingStateRef.current);
				openGlobalErrorModal(error as Error);
				return null;
			}
		},
		[fetchMethod, getLastQuery, openGlobalErrorModal, setLastQuery]
	);

	return {
		fetch,
		loadingState: loadingState.get(getLastQuery() || "") || "Initial"
	};
};

const useNewRequestBundlesData = () => {
	const [allBundles, setAllBundles] = useState<TWithLastUserIdObject<TBundlesMap>>({ totalAmount: 0, bundles: Map() });
	const [bundles, setBundles] = useState<TBundlesMap>({ totalAmount: 0, bundles: Map() });
	const [loadingSpecificIdsState, setLoadingSpecificIdsState] = useState<TLoadingState>("Initial");
	const { fetch: bundlesFetch, loadingState: bundlesLoadingState } = useQueryOnRequest(getBundles);

	const wrappedFetchBundles = useCallback(
		async (options: { userId: string; page?: number }) => {
			const { userId, page = 1 } = options;
			const bundlesResult = await bundlesFetch({ userId, page });
			if (!bundlesResult) return;
			const mappedBundles = toMapBy(bundlesResult.result, bundle => bundle.id);
			setBundles({ bundles: mappedBundles, totalAmount: bundlesResult.pagination.totalResults });
			setAllBundles(current => {
				if (current.lastUserId && current.lastUserId !== userId) {
					return { bundles: mappedBundles, totalAmount: bundlesResult.pagination.totalResults, lastUserId: userId };
				}
				return {
					bundles: current.bundles.merge(mappedBundles),
					totalAmount: Math.max(current.totalAmount, bundlesResult.pagination.totalResults),
					lastUserId: userId
				};
			});
		},
		[bundlesFetch]
	);

	const fetchBundle = useCallback(async (id: string, userId: string) => {
		const bundle = await getBundle(id, userId);

		setAllBundles(current => {
			if (current.bundles.has(bundle.id)) return current;

			return {
				bundles: current.bundles.set(bundle.id, bundle as Require<BundleModel, "bundleItems">),
				totalAmount: current.totalAmount + 1,
				lastUserId: userId
			};
		});
	}, []);

	const fetchBundleIds = useCallback(
		async (options: { ids: string[]; userId: string }) => {
			const { ids, userId } = options;
			setLoadingSpecificIdsState("Loading");
			try {
				const bundles = await Promise.all(ids.map(id => getBundle(id, userId)));
				if (!bundles) return;
				setAllBundles(current => {
					for (const bundle of bundles) {
						if (current.bundles.has(bundle.id)) continue;
						current = {
							bundles: current.bundles.set(bundle.id, bundle as Require<BundleModel, "bundleItems">),
							totalAmount: current.totalAmount + 1
						};
					}
					return current;
				});
				setLoadingSpecificIdsState("Loaded");
			} catch (_error) {
				setLoadingSpecificIdsState("Error");
			}
		},
		[setLoadingSpecificIdsState]
	);

	return {
		allData: allBundles,
		data: bundles,
		loadingState: bundlesLoadingState,
		loadingSpecificIdsState,
		fetch: wrappedFetchBundles,
		fetchBundle,
		fetchBundleIds
	};
};

const useNewRequestIntegrationsData = () => {
	const [allIntegrations, setAllIntegrations] = useState<TWithLastUserIdObject<TIntegrationsMap>>({
		totalAmount: 0,
		integrations: Map()
	});
	const [integrations, setIntegrations] = useState<TIntegrationsMap>({ totalAmount: 0, integrations: Map() });
	const { fetch: integrationsFetch, loadingState: integrationsLoadingState } = useQueryOnRequest(getIntegrations);

	const wrappedFetchIntegrations = useCallback(
		async (options: { userId: string; page?: number }) => {
			const { userId, page = 1 } = options;
			const integrationsResult = await integrationsFetch({ userId, page });
			if (!integrationsResult) return;
			const mappedIntegrations = toMapBy(integrationsResult.result, integration => integration.id);
			setIntegrations({ integrations: mappedIntegrations, totalAmount: integrationsResult.pagination.totalResults });
			setAllIntegrations(current => {
				if (current.lastUserId && current.lastUserId !== userId) {
					return {
						integrations: mappedIntegrations,
						totalAmount: integrationsResult.pagination.totalResults,
						lastUserId: userId
					};
				}
				return {
					integrations: current.integrations.merge(mappedIntegrations),
					totalAmount: Math.max(current.totalAmount, integrationsResult.pagination.totalResults),
					lastUserId: userId
				};
			});
		},
		[integrationsFetch]
	);

	const fetchIntegration = useCallback(async (id: string, userId: string) => {
		const integration = await getIntegration(id, userId);

		setAllIntegrations(current => {
			if (current.integrations.has(integration.id)) return current;

			return {
				integrations: current.integrations.set(integration.id, integration),
				totalAmount: current.totalAmount + 1,
				lastUserId: userId
			};
		});
	}, []);

	const addIntegrations = useCallback((newIntegrations: IntegrationModel[]) => {
		setAllIntegrations(current => {
			for (const integration of newIntegrations) {
				if (current.integrations.has(integration.id)) continue;
				current = {
					integrations: current.integrations.set(integration.id, integration),
					totalAmount: current.totalAmount + 1
				};
			}
			return current;
		});
	}, []);

	return {
		allData: allIntegrations,
		data: integrations,
		loadingState: integrationsLoadingState,
		fetch: wrappedFetchIntegrations,
		fetchIntegration,
		addIntegrations
	};
};

type TFetchResourcesOptions = {
	userId: string;
	integrationId: string;
	search?: string;
	resourceTypes?: string[];
	page?: number;
};

const useNewRequestIntegrationResourcesData = () => {
	const [allIntegrationResources, setAllIntegrationResources] = useState<TAllResourcesState>({
		map: OrderedMap()
	});
	const [integrationResources, setIntegrationResources] = useState<TIntegrationResourceMap>(OrderedMap());
	const { fetch: resourcesFetch, loadingState: resourceLoadingState } = useQueryOnRequest(getIntegrationsResources);

	const wrappedFetchIntegrationResources = useCallback(
		async (options: TFetchResourcesOptions) => {
			const { integrationId, search, userId, resourceTypes, page } = options;
			const resourcesResult = await resourcesFetch({
				userId,
				integrationIds: [integrationId],
				search,
				resourceTypes,
				page
			});
			if (!resourcesResult) return;
			const mappedResources: OrderedMap<string, IntegrationResourceModel> = toOrderedMapBy(
				resourcesResult.result,
				resource => resource.id
			);
			setIntegrationResources(current => {
				const currentIntegration = current.get(integrationId);
				let updatedIntegrationResources: OrderedMap<string, IntegrationResourceModel> = mappedResources;
				if (page && page > 1 && currentIntegration) {
					updatedIntegrationResources = currentIntegration.integrationResources.toOrderedMap().concat(mappedResources);
				}
				const newResourcesMap: TIntegrationResourceQueryData = {
					integrationResources: updatedIntegrationResources,
					totalAmount: resourcesResult.pagination.totalResults,
					lastSearch: search
				};
				return current.set(integrationId, newResourcesMap);
			});

			const updateAllResourcesState = (current: TAllResourcesState): TAllResourcesState => {
				if (current.lastUserId && current.lastUserId !== userId) {
					return {
						map: OrderedMap([
							[
								integrationId,
								{ integrationResources: mappedResources, totalAmount: resourcesResult.pagination.totalResults }
							]
						]),
						lastUserId: userId
					};
				}
				const currentIntegration = current.map.get(integrationId);
				const updatedIntegrationResources: OrderedMap<string, IntegrationResourceModel> =
					currentIntegration?.integrationResources.toOrderedMap().merge(mappedResources) || mappedResources;
				return {
					map: current.map.set(integrationId, {
						integrationResources: updatedIntegrationResources,
						totalAmount: Math.max(currentIntegration?.totalAmount || 0, resourcesResult.pagination.totalResults)
					}),
					lastUserId: userId
				};
			};
			setAllIntegrationResources(updateAllResourcesState);
		},
		[resourcesFetch]
	);

	const fetchResource = useCallback(async (id: string, userId: string) => {
		const resource = await getIntegrationResource(id, userId);

		setAllIntegrationResources(current => {
			const currentIntegrationResourcesMap = current.map.get(resource.integrationId);
			if (currentIntegrationResourcesMap?.integrationResources.has(resource.id)) return current;

			const newMap: TIntegrationResourceMap = currentIntegrationResourcesMap
				? current.map.set(resource.integrationId, {
						integrationResources: currentIntegrationResourcesMap.integrationResources.set(id, resource),
						totalAmount: currentIntegrationResourcesMap.totalAmount + 1
					})
				: current.map.set(resource.integrationId, {
						integrationResources: OrderedMap([[id, resource]]),
						totalAmount: 1
					});

			return {
				map: newMap,

				lastUserId: current.lastUserId
			};
		});
	}, []);

	return {
		allData: allIntegrationResources.map,
		data: integrationResources,
		loadingState: resourceLoadingState,
		fetch: wrappedFetchIntegrationResources,
		fetchResource
	};
};

const useNewRequestIntegrationResourceRolesData = () => {
	const [allIntegrationResourceRoles, setAllIntegrationResourceRoles] = useState<TAllRolesState>({ map: Map() });
	const [integrationResourceRoles, setIntegrationResourceRoles] = useState<TIntegrationResourceRoleMap>(Map());
	const [loadingSpecificIdsState, setLoadingSpecificIdsState] = useState<TLoadingState>("Initial");
	const { fetch: rolesFetch, loadingState: rolesLoadingState } = useQueryOnRequest(getIntegrationResourcesRoles);

	const wrappedFetchIntegrationResourceRoles = useCallback(
		async (options: { userId: string; integrationResourceId: string; search?: string }) => {
			const { integrationResourceId, search, userId } = options;
			const rolesResult = await rolesFetch({
				userId,
				integrationResourceIds: [integrationResourceId],
				search
			});
			if (!rolesResult) return;
			const mappedRoles = toMapBy(rolesResult.result, role => role.id);
			setIntegrationResourceRoles(current => {
				return current.set(integrationResourceId, {
					integrationResourceRoles: mappedRoles,
					totalAmount: rolesResult.pagination.totalResults,
					lastSearch: search
				});
			});
			setAllIntegrationResourceRoles(current => {
				if (current.lastUserId && current.lastUserId !== userId) {
					return {
						map: Map([
							[
								integrationResourceId,
								{ integrationResourceRoles: mappedRoles, totalAmount: rolesResult.pagination.totalResults }
							]
						]),
						lastUserId: userId
					};
				}
				const currentResource = current.map.get(integrationResourceId);
				return {
					map: current.map.set(integrationResourceId, {
						integrationResourceRoles: currentResource?.integrationResourceRoles.merge(mappedRoles) || mappedRoles,
						totalAmount: Math.max(currentResource?.totalAmount || 0, rolesResult.pagination.totalResults)
					}),
					lastUserId: userId
				};
			});
		},
		[rolesFetch]
	);

	const fetchRoleIds = useCallback(
		async (options: { ids: string[]; userId: string }) => {
			const { ids, userId } = options;
			setLoadingSpecificIdsState("Loading");
			try {
				const roles = await Promise.all(ids.map(id => getIntegrationResourceRole(id, userId)));
				if (!roles) return;
				setIntegrationResourceRoles(current => {
					for (const role of roles) {
						const resourceId = role.integrationResourceId;
						const currentResource = current.get(resourceId);
						const roleMapping = { [role.id]: role };
						if (currentResource && currentResource.integrationResourceRoles.has(role.id)) continue;
						current = current.set(resourceId, {
							integrationResourceRoles: currentResource
								? currentResource.integrationResourceRoles.merge(roleMapping)
								: Map<string, IntegrationResourceRoleModel>(roleMapping),
							totalAmount: (currentResource?.totalAmount || 0) + 1
						});
					}
					return current;
				});
				setLoadingSpecificIdsState("Loaded");
			} catch (_error) {
				setLoadingSpecificIdsState("Error");
			}
		},
		[setLoadingSpecificIdsState]
	);

	const fetchRole = useCallback(async (id: string, userId: string) => {
		const role = await getIntegrationResourceRole(id, userId);

		setAllIntegrationResourceRoles(current => {
			if (current.map.has(role.id)) return current;

			const currentIntegrationResourceMap = current.map.get(role.integrationResourceId);
			return {
				map: currentIntegrationResourceMap
					? current.map.set(role.integrationResourceId, {
							integrationResourceRoles: currentIntegrationResourceMap.integrationResourceRoles.set(id, role),
							totalAmount: currentIntegrationResourceMap.totalAmount + 1
						})
					: current.map.set(role.integrationResourceId, {
							integrationResourceRoles: Map([[id, role]]),
							totalAmount: 1
						}),

				lastUserId: current.lastUserId
			};
		});
	}, []);

	return {
		allData: allIntegrationResourceRoles.map,
		data: integrationResourceRoles,
		loadingState: rolesLoadingState,
		loadingSpecificIdsState,
		fetch: wrappedFetchIntegrationResourceRoles,
		fetchRole,
		fetchRoleIds
	};
};

const useNewRequestAllowedDurationsData = () => {
	const [allowedDurations, setAllowedDurations] = useState(List<TTicketDuration>());
	const { fetch: durationsFetch, loadingState: durationsLoadingState } = useQueryOnRequest(getAllowedDurations);

	const wrappedFetchAllowedDurations = useCallback(
		async (options: { targets: ITargetData[]; userId: string }) => {
			const allowedDurationsResult = await durationsFetch(options);
			if (!allowedDurationsResult) return;
			setAllowedDurations(List(allowedDurationsResult));
		},
		[durationsFetch]
	);

	return {
		data: allowedDurations,
		loadingState: durationsLoadingState,
		fetch: wrappedFetchAllowedDurations
	};
};

const useNewRequestSearchResultsData = () => {
	const [searchResults, setSearchResults] = useState<List<TNewTicketOption>>(List());
	const { fetch: searchFetch, loadingState: searchLoadingState } = useQueryOnRequest(search);

	const wrappedFetchSearchResults = useCallback(
		async (options: { userId: string; search: string }) => {
			const searchResult = await searchFetch(options);
			if (!searchResult) return;
			setSearchResults(List(searchResult.result));
		},
		[searchFetch]
	);

	return {
		data: searchResults,
		loadingState: searchLoadingState,
		fetch: wrappedFetchSearchResults
	};
};

const useNewRequestOriginalTicketData = () => {
	const [ticket, setTicket] = useState<TicketModel>();
	const { fetch: ticketFetch, loadingState: ticketLoadingState } = useQueryOnRequest(getTicket);

	const wrappedFetchOriginalTicket = useCallback(
		async (options: { id: string }) => {
			const { id } = options;
			const ticketResult = await ticketFetch({ id });
			if (!ticketResult) return;
			setTicket(ticketResult);
		},
		[ticketFetch]
	);

	return {
		data: ticket,
		loadingState: ticketLoadingState,
		fetch: wrappedFetchOriginalTicket
	};
};

const [NewRequestBundlesProvider, useNewRequestBundles] = constate(useNewRequestBundlesData);
const [NewRequestIntegrationsProvider, useNewRequestIntegrations] = constate(useNewRequestIntegrationsData);
const [NewRequestIntegrationResourcesProvider, useNewRequestIntegrationResources] = constate(
	useNewRequestIntegrationResourcesData
);
const [NewRequestIntegrationResourceRolesProvider, useNewRequestIntegrationResourceRoles] = constate(
	useNewRequestIntegrationResourceRolesData
);
const [NewRequestAllowedDurationsProvider, useNewRequestAllowedDurations] = constate(useNewRequestAllowedDurationsData);
const [NewRequestSearchResultsProvider, useNewRequestSearchResults] = constate(useNewRequestSearchResultsData);
const [NewRequestOriginalTicketProvider, useNewRequestOriginalTicket] = constate(useNewRequestOriginalTicketData);

const NewRequestDataProvider: FC = ({ children }) => {
	return (
		<Provider
			providers={[
				NewRequestBundlesProvider,
				NewRequestIntegrationsProvider,
				NewRequestIntegrationResourcesProvider,
				NewRequestIntegrationResourceRolesProvider,
				NewRequestAllowedDurationsProvider,
				NewRequestSearchResultsProvider,
				NewRequestOriginalTicketProvider
			]}>
			<>{children}</>
		</Provider>
	);
};

export {
	NewRequestDataProvider,
	useNewRequestBundles,
	useNewRequestIntegrations,
	useNewRequestIntegrationResources,
	useNewRequestIntegrationResourceRoles,
	useNewRequestAllowedDurations,
	useNewRequestSearchResults,
	useNewRequestOriginalTicket
};
