import { Record as ImmutableRecord, List } from "immutable";
import type { TEntityOption, IEntityOption } from "utils/entityOptionType";

const defaults = {
	id: "",
	url: "",
	name: "",
	headers: {} as Record<string, string>,
	additionalParams: {} as Record<string, unknown>,
	approvalAlgorithmIds: List<string>(),
	enabled: true,
	createdAt: new Date(0)
};

export class ApprovalFlowsWebhookModel
	extends ImmutableRecord<typeof defaults>(defaults)
	implements IEntityOption<"Webhook">
{
	static fromServerData(data: unknown) {
		const { additionalParams, createdAt, headers, approvalAlgorithmIds, enabled, id, url, name } = data as Omit<
			typeof defaults,
			"approvalAlgorithmIds"
		> & { approvalAlgorithmIds: string[]; createdAt: string };

		return new ApprovalFlowsWebhookModel({
			additionalParams,
			name,
			approvalAlgorithmIds: List(approvalAlgorithmIds ?? []),
			createdAt: new Date(createdAt),
			enabled,
			headers,
			id,
			url
		});
	}

	get disabled() {
		return !this.enabled;
	}

	toEntityOption(): TEntityOption<"Webhook"> {
		return {
			displayName: this.name,
			email: this.url,
			identifier: this.id,
			type: "Webhook",
			isDeleted: this.disabled
		};
	}
}
