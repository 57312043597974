import classNames from "classnames";
import React, { ReactNode, useMemo } from "react";
import { useStyles } from "./styles";
import { TextCellContent } from "./TextCellContent";
import type { IRowOptions } from "../../types";

export const LinkCellContent: FC<{ text?: string; url: string; icon?: ReactNode } & IRowOptions> = ({
	text,
	icon,
	disabled,
	url,
	className
}) => {
	const classes = useStyles();
	const content = useMemo(
		() => (
			<a
				href={!disabled ? url : undefined}
				target="_blank"
				rel="noopener noreferrer"
				className={classNames(classes.link, { [classes.disabled]: disabled })}>
				{text}
			</a>
		),
		[disabled, url, classes.link, classes.disabled, text]
	);

	return (
		<TextCellContent
			text={content}
			disabled={disabled}
			icon={icon}
			textVariant="body_sb"
			className={classNames(classes.linkContainer, className, { [classes.disabled]: disabled })}
		/>
	);
};
