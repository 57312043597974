import { useCallback, useMemo, useState } from "react";
import useMountState from "./useMountState";

interface ILoadingState {
	isLoading: boolean;
	withLoader: <T>(promise: Promise<T> | T) => Promise<T>;
}

export const useLoadingState = (): ILoadingState => {
	const [activeLoaders, setActiveLoaders] = useState(0);
	const [isMounted] = useMountState();

	const withLoader = useCallback(
		async <T>(promise: Promise<T> | T): Promise<T> => {
			try {
				if (isMounted()) setActiveLoaders(current => current + 1);

				const result = await promise;
				return result;
			} finally {
				if (isMounted()) setActiveLoaders(current => current - 1);
			}
		},
		[isMounted]
	);

	const isLoading = useMemo(() => activeLoaders > 0, [activeLoaders]);

	return useMemo(
		() => ({
			isLoading,
			withLoader
		}),
		[withLoader, isLoading]
	);
};
