import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	container: {
		position: "absolute",
		zIndex: 2,
		display: "flex",
		width: "100%",
		height: "100%",
		alignItems: "center",
		justifyContent: "center",
		flexDirection: "column",
		backgroundColor: "var(--color-white)",
		minHeight: "29rem",
		borderRadius: "var(--border-radius-semi-rounded)"
	},
	icon: {
		marginBottom: "var(--spacing-x4, 16px)"
	},
	headerText: {
		marginBottom: "var(--spacing-x2, 8px)"
	},
	message: {
		whiteSpace: "pre-wrap",
		textAlign: "center"
	}
});
