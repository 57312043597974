import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	subTitle: {
		maxWidth: "508px"
	},
	contentContainer: {
		overflow: "hidden"
	}
});
