import { uniqueId } from "lodash";
import { createUseStyles } from "react-jss";

// Making sure it's unique across the app, and doesn't clash with react-jss
export const MODAL_FIXED_CLASS = uniqueId("modal_fixed_");

export const useStyles = createUseStyles({
	container: {
		backgroundColor: "var(--color-white)",
		borderRadius: "8px",
		boxShadow: "var(--shadow-bottom)",
		display: "grid",
		gridTemplateRows: "auto 1fr auto"
	},
	top: {
		alignItems: "center",
		display: "flex",
		justifyContent: "flex-end"
	},
	content: {
		overflow: "auto"
	},
	actions: {
		alignItems: "center",
		display: "flex",
		gap: "12px",
		paddingRight: "12px",
		justifyContent: "flex-end"
	},
	wrapper: {
		alignItems: "center",
		backgroundColor: "var(--modal-background-color)",
		display: "flex",
		height: "100%",
		justifyContent: "center",
		left: 0,
		position: "fixed",
		top: 0,
		width: "100%",
		zIndex: 100
	},
	// The different styles is to allow easy overriding of the modal
	"@global": {
		// :where is a selector with 0 specificity, so it can be overridden
		[`:where(.${MODAL_FIXED_CLASS})`]: {
			gap: "8px",
			maxHeight: "95vh",
			maxWidth: "var(--modal-max-height)",
			minHeight: 0,
			minWidth: 0,
			padding: "20px"
		}
	}
});
