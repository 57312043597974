import React from "react";
import { Divider } from "components/ui/Divider";
import { BeyondTrustLogo } from "components/ui/systemLogos/BeyondTrustLogo";
import { BeyondTrustTextLogo } from "components/ui/systemLogos/BeyondTrustTextLogo";
import { EntitleTextNewLogo } from "components/ui/systemLogos/EntitleTextNewLogo";
import { useStyles } from "./styles";

export const BeyondTrustLogoGroup: FC = ({ className, innerRef }) => {
	const classes = useStyles();

	return (
		<div className={classes.logoContainer}>
			<EntitleTextNewLogo />
			<Divider vertical />
			<div className={classes.beyondTrustLogoContainer}>
				<BeyondTrustLogo />
				<BeyondTrustTextLogo />
			</div>
		</div>
	);
};
