import { createUseStyles } from "react-jss";
import { BP_MEDIA_QUERIES } from "utils/ui/breakpoints";

export const useStyles = createUseStyles({
	addRoleContainer: {
		paddingTop: "0.5rem"
	},
	actions: {
		display: "flex",
		gap: "0.2rem"
	},
	input: {},
	roleSelectInput: {
		display: "flex",
		flexWrap: "wrap",
		gap: "8px",
		padding: "4px",
		justifyContent: "flex-end",
		alignItems: "flex-end",
		[BP_MEDIA_QUERIES.FORTY]: {
			flexWrap: "noWrap",
			"& > $icon": {
				order: "unset"
			}
		}
	},
	rolesListContainer: {
		minHeight: "6rem",
		display: "flex",
		flexDirection: "column",
		gap: "16px"
	},
	inputValue: {
		whiteSpace: "nowrap",
		textOverflow: "ellipsis",
		overflow: "hidden"
	},
	icon: {
		order: -1
	}
});
