import { createUseStyles } from "react-jss";
import { BP_MEDIA_QUERIES } from "utils/ui/breakpoints";

export const useStyles = createUseStyles({
	container: {
		display: "flex",
		width: "100%",
		gap: "var(--spacing-x2, 8px)",
		justifyContent: "flex-end",
		flexWrap: "wrap",
		[BP_MEDIA_QUERIES.TWENTY]: {
			alignItems: "center",
			flexWrap: "nowrap"
		}
	}
});
