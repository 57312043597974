import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	overlay: {
		display: "flex",
		flexDirection: "column",
		position: "absolute",
		top: "0",
		left: "0",
		width: "100%",
		height: "100%",
		backgroundColor: "var(--surface-dimmer-background-color)",
		overflow: "hidden",
		contain: "strict",
		zIndex: "150",
		"--transition-duration": "0.3s",
		transition: "opacity var(--transition-duration) var(--transition-easing)",
		willChange: "opacity",
		"&.hide": {
			pointerEvents: "none",
			opacity: "0",
			"--transition-duration": "0.23"
		}
	},
	content: {
		flex: "1",
		display: "flex",
		flexDirection: "column",
		padding: "1.5rem 2.25rem",
		height: "100%"
	}
});
