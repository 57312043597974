import React from "react";
import { makeIcon } from "components/ui/Icon";
import { JiraLogo } from "components/ui/systemLogos/JiraLogo";
import { JiraServiceManagementLogo } from "components/ui/systemLogos/JiraServiceManagementLogo";
import { ZendeskLogo } from "components/ui/systemLogos/ZendeskLogo";
import type { TTicketingIntegrationType } from "models/TicketingIntegrationTicketModel";

const TICKETING_INTEGRATION_LOGOS = new Map<TTicketingIntegrationType, IconComponent>([
	["jira", makeIcon(<JiraLogo />)],
	["jira-service-management", makeIcon(<JiraServiceManagementLogo />)],
	["zendesk", makeIcon(<ZendeskLogo />)]
]);

export const getTicketingIntegrationLogoByType = (type: TTicketingIntegrationType) =>
	TICKETING_INTEGRATION_LOGOS.get(type);
