import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	greyText: {
		color: "var(--color-grey-dark)"
	},
	header: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between"
	},
	headerTitle: {
		alignItems: "center",
		display: "flex",
		gap: "1rem",
		height: "100%"
	},
	spacer: {
		borderLeft: "1px solid var(--color-grey-light)",
		height: "min(80%, 1.5rem)"
	}
});
