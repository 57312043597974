import { useCallback, useEffect } from "react";

export type TKeyDownOptions = Map<string, (e: KeyboardEvent) => void>;

export function useOnKeyDownEvent(options: TKeyDownOptions) {
	const onKeyDown = useCallback(
		(event: KeyboardEvent) => {
			options.forEach((handler, key) => {
				if (event.code === key) {
					handler(event);
				}
			});
		},
		[options]
	);
	useEffect(() => {
		if (options.size) document.addEventListener("keydown", onKeyDown);

		return () => {
			if (options.size) document.removeEventListener("keydown", onKeyDown);
		};
	}, [onKeyDown, options.size]);
}
