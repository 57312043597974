import { useEffect } from "react";
import { useApprovalFlowsContext } from "context/approvalFlowContext";

export const useApprovalFlows = () => {
	const {
		state: { approvalFlows },
		actions: { loadApprovalFlows }
	} = useApprovalFlowsContext();

	useEffect(() => {
		void loadApprovalFlows();
	}, [loadApprovalFlows]);

	return approvalFlows;
};
