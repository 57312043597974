import classNames from "classnames";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "components/ui/Button";
import { Typography } from "components/ui/legacy/Typography";
import { LoadingDots } from "components/ui/LoadingDots";
import { TextAreaInput } from "components/ui/TextAreaInput";
import { useAccessReviewsContext } from "context/accessReviewsContext";
import { useAccessReviews } from "hooks/useAccessReviews";
import { useFullAccessReview } from "hooks/useFullAccessReview";
import { useLoadingState } from "hooks/useLoadingState";
import { getDescriptionValidators } from "utils/validation/validationUtils";
import { EmptyPermissionsMessage } from "./components/EmptyPermissionsMessage";
import { LoadingMessage } from "./components/LoadingMessage";
import { NavigationHeader } from "./components/NavigationHeader";
import { OverviewTabs } from "./components/OverviewTabs";
import { SummaryHeader } from "./components/SummaryHeader";
import { useStyles } from "./styles";
import { AccessReviewStatistics } from "../AccessReviewStatistics";

type TAccessReviewProps = {
	change: (reportId: string) => void;
	loadingNewAccessReview?: boolean;
} & (
	| {
			accessReviewId: string;
			back: () => void;
	  }
	| {
			accessReviewId?: never;
			back?: never;
	  }
);

const POLLING_INTERVAL = 10000;

export const AccessReview: FC<TAccessReviewProps> = ({
	accessReviewId,
	back,
	change,
	className,
	id,
	innerRef,
	loadingNewAccessReview
}) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { sortedAccessReviews, editAccessReview } = useAccessReviews();
	const {
		state: { latestAccessReviewId, isGetLoading },
		actions: { loadAccessReview }
	} = useAccessReviewsContext();
	const { accessReview: latestAccessReview } = useFullAccessReview(latestAccessReviewId, true);
	const { accessReview, isLoading } = useFullAccessReview(accessReviewId || latestAccessReviewId, true);
	const [isEditingDescription, setIsEditingDescription] = useState(false);
	const [editableDescription, setEditableDescription] = useState<string | undefined>("");
	const [currentAccessReviewIndex, setCurrentAccessReviewIndex] = useState(
		sortedAccessReviews?.findIndex(ar => ar.id === accessReviewId) ?? -1
	);
	const { withLoader, isLoading: isDescriptionLoading } = useLoadingState();

	useEffect(() => {
		setCurrentAccessReviewIndex(sortedAccessReviews?.findIndex(ar => ar.id === accessReviewId) ?? -1);
	}, [accessReviewId, sortedAccessReviews]);

	const changeToLatest = useCallback(() => {
		if (!change || !latestAccessReview) return;
		change(latestAccessReview?.id);
	}, [change, latestAccessReview]);

	const descriptionValidators = useMemo(
		() => getDescriptionValidators(t("common.resourceHeader.description"), true),
		[t]
	);

	useEffect(() => {
		setEditableDescription(accessReview?.description);
	}, [accessReview]);

	const onEdit = useCallback(() => setIsEditingDescription(true), []);

	const onSave = useCallback(async () => {
		setIsEditingDescription(false);
		await withLoader(editAccessReview(accessReview!.id, { description: editableDescription }));
	}, [withLoader, accessReview, editableDescription, editAccessReview]);

	const onCancel = useCallback(() => {
		setIsEditingDescription(false);
		setEditableDescription(accessReview?.description);
	}, [accessReview]);

	useEffect(() => {
		if (accessReview?.status === "pendingCreation") {
			const intervalId = setInterval(() => {
				if (accessReview?.status === "pendingCreation" && !isGetLoading) {
					void loadAccessReview((accessReviewId || latestAccessReviewId)!, true);
				}
			}, POLLING_INTERVAL);

			return () => {
				clearInterval(intervalId);
			};
		}
		return;
	}, [accessReviewId, latestAccessReviewId, accessReview?.status, isGetLoading, loadAccessReview]);

	const isValid = useMemo(() => {
		return !descriptionValidators.some(validate => validate(editableDescription || ""));
	}, [descriptionValidators, editableDescription]);

	const accessReviewPendingCreation = loadingNewAccessReview || accessReview?.status === "pendingCreation";
	const accessReviewHasNoPermissions = !accessReviewPendingCreation && accessReview?.permissionCount === 0;
	if ((!accessReview || isLoading) && !accessReviewPendingCreation) return <LoadingDots center />;

	return (
		<div className={classNames(classes.container, className)} id={id} ref={innerRef}>
			{accessReviewPendingCreation && <LoadingMessage />}
			{accessReviewHasNoPermissions && <EmptyPermissionsMessage />}
			{accessReview && !isLoading && (
				<div
					className={classNames(classes.contentContainer, {
						[classes.loadingLatestAccessReview]: accessReviewPendingCreation
					})}>
					{!accessReviewId ? (
						<SummaryHeader accessReview={accessReview} changeToLatest={changeToLatest} />
					) : (
						sortedAccessReviews && (
							<NavigationHeader
								back={back}
								change={change}
								createdAt={accessReview.createdAt}
								currentAccessReviewIndex={currentAccessReviewIndex}
								sortedAccessReviews={sortedAccessReviews}
							/>
						)
					)}
					{isEditingDescription ? (
						<div className={classes.descriptionContainer}>
							<TextAreaInput
								textAreaClassName={classes.editDescription}
								placeholder={t("common.accessReview.noDescription")}
								onValueChange={setEditableDescription}
								value={editableDescription}
								validators={descriptionValidators}
								actions={
									<div className={classes.actions}>
										<Button
											size="small"
											variant="text"
											className={classes.saveButton}
											onClick={onSave}
											loading={isDescriptionLoading}
											disabled={!isValid || isDescriptionLoading}>
											{t("buttons.save")}
										</Button>
										<Button
											size="small"
											variant="text"
											className={classes.cancelButton}
											onClick={onCancel}
											disabled={isDescriptionLoading}>
											{t("buttons.cancel")}
										</Button>
									</div>
								}
							/>
						</div>
					) : (
						<div className={classes.descriptionContainer}>
							<Typography multiLine fade={!editableDescription} className={classes.description}>
								{editableDescription || t("common.accessReview.noDescription")}
							</Typography>
							<Button size="small" variant="text" className={classes.editButton} onClick={onEdit}>
								{t("buttons.edit")}
							</Button>
						</div>
					)}
					<div>
						<AccessReviewStatistics accessReview={accessReview} />
					</div>
					<div>
						{/* Key will force refresh when access review is fully loaded */}
						<OverviewTabs accessReview={accessReview} key={accessReview.id + accessReview.status} />
					</div>
				</div>
			)}
		</div>
	);
};
