import { Record } from "immutable";
import uniq from "lodash/uniq";
import { validateFilterInclusionOperator, validateFilterRelation } from "utils/validation/filterValidation";
import type { IFilter } from "types/filters";

const filterName = "integrationName";

const defaults = {
	name: filterName as typeof filterName,
	value: [] as string[],
	operator: "contains" as "contains" | "notContains",
	relation: "or" as "and" | "or"
};

type TDefaults = typeof defaults;

export class IntegrationNameFilter
	extends Record<TDefaults>(defaults)
	implements IFilter<TDefaults["value"], TDefaults["operator"], TDefaults["name"]>
{
	static filterName: typeof filterName = filterName;

	static fromURLSearchParams(urlSearchParams: URLSearchParams): IntegrationNameFilter | null {
		const integrationName = uniq(urlSearchParams.getAll("integrationName"));
		const integrationNameOperator = urlSearchParams.get("integrationNameOperator") || undefined;
		const integrationNameRelation = urlSearchParams.get("integrationNameRelation") || undefined;
		if (
			integrationName.length === 0 ||
			!validateFilterInclusionOperator(integrationNameOperator) ||
			!validateFilterRelation(integrationNameRelation)
		) {
			return null;
		}
		return new IntegrationNameFilter({
			value: integrationName,
			operator: integrationNameOperator,
			relation: integrationNameRelation
		});
	}

	toURLSearchParams(): URLSearchParams {
		const params = new URLSearchParams();
		if (!this.value.length) {
			return params;
		}
		for (const value of uniq(this.value)) {
			params.append("integrationName", value);
		}
		params.append("integrationNameOperator", this.operator);
		params.append("integrationNameRelation", this.relation);
		return params;
	}
}
