import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	actions: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-end"
	},
	title: {
		display: "flex",
		flexDirection: "row",
		gap: "0.35rem"
	},
	resourceForm: {
		display: "flex",
		flexDirection: "column",
		gap: "2rem",
		height: "fit-content",
		width: "40rem",
		padding: "0"
	},
	hint: {
		color: "var(--color-grey-dark)",
		display: "block"
	},
	checkboxField: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between"
	},
	defaultRoleForm: {
		backgroundColor: "var(--surface-content-contrast-background-color)",
		borderRadius: "var(--border-radius-semi-rounded)",
		padding: "0.5rem",
		display: "flex",
		flexDirection: "column",
		gap: "0.5rem"
	},
	roleName: {
		display: "inline-flex",
		gap: "0.5rem",
		alignItems: "center"
	},
	virtualizedRoleSelectInputs: {
		display: "flex",
		flexDirection: "column",
		gap: "0.5rem"
	}
});
