import React from "react";
import { IconButton } from "components/ui/IconButton";
import { SideNavIcon } from "components/ui/Icons/SideNavIcon";
import { useStyles } from "./styles";

interface IProps {
	toggle?(): void;
}

export const ToggleSection: FC<IProps> = ({ toggle }) => {
	const classes = useStyles();

	return (
		<div className={classes.section}>
			<IconButton variant="primary" className={classes.button} onClick={toggle} size="huge">
				<SideNavIcon />
			</IconButton>
		</div>
	);
};
