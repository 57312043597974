import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	header: {
		display: "flex",
		justifyContent: "space-between"
	},
	headerActions: {
		display: "flex",
		gap: "0.5rem"
	},
	content: {
		display: "flex",
		flexDirection: "column",
		gap: "1.5rem"
	},
	noAccessReviews: {
		alignItems: "center",
		display: "flex",
		flexDirection: "column",
		height: "100%",
		justifyContent: "center",
		width: "100%"
	}
});
