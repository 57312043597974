import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	avatar: {
		"&.large": {
			fontSize: "1.14rem",
			height: "2.571rem",
			width: "2.571rem"
		},
		"&.medium": {
			fontSize: "var(---typography-standard-font-size)",
			height: "2rem",
			width: "2rem"
		},
		"&.small": {
			fontSize: "var(--typography-small-font-size)",
			height: "1.714rem",
			width: "1.714rem"
		},
		alignItems: "center",
		backgroundColor: "var(--color-blue-dark)",
		backgroundSize: "cover",
		borderRadius: "var(--border-radius-round)",
		boxSizing: "border-box",
		color: "var(--color-white)",
		display: "flex",
		flexDirection: "row",
		flexShrink: 0,
		justifyContent: "center",
		letterSpacing: "0.4px",
		userSelect: "none"
	}
});
