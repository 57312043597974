import { List } from "immutable";
import React from "react";
import { useTranslation } from "react-i18next";
import { IconButton } from "components/ui/IconButton";
import { CancelCircleIcon } from "components/ui/Icons/CancelCircleIcon";
import { DeniedIcon } from "components/ui/Icons/DeniedIcon";
import { ErrorIcon } from "components/ui/Icons/ErrorIcon";
import { ExpiredIcon } from "components/ui/Icons/ExpiredIcon";
import { GrantedIcon } from "components/ui/Icons/GrantedIcon";
import { ManualApprovalIcon } from "components/ui/Icons/ManualApprovalIcon";
import { NoAccessCircleIcon } from "components/ui/Icons/NoAccessCircleIcon";
import { PendingIcon } from "components/ui/Icons/PendingIcon";
import { Tooltip } from "components/ui/Tooltip";
import { Typography } from "components/ui/Typography";
import { useStyles } from "./styles";
import { ApprovalProcess } from "../RequestDetails/components/ApprovalProcess";
import type { TicketModel } from "models/TicketModel";
import type { TFullApprovalRequest, TFullTicket } from "../RequestDetails";

type TProps = {
	size?: "tiny" | "xs" | "small" | "medium" | "large" | "huge";
	request?: TFullTicket | TicketModel;
};

const PendingManualFix: FC<TProps> = ({ size = "medium" }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const tooltipContent = (
		<div className={classes.statusTooltip}>
			<ManualApprovalIcon />
			<Typography variant="text_sm_sb">{t("common.requestStatus.statuses.waitingForIT")}</Typography>
		</div>
	);
	return (
		<IconButton size={size}>
			<ManualApprovalIcon tooltip={tooltipContent} />
		</IconButton>
	);
};

export const PendingManualFixStatus = React.memo(PendingManualFix);

const Expired: FC<TProps> = ({ size = "medium" }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const tooltipContent = (
		<div className={classes.statusTooltip}>
			<ExpiredIcon />
			<Typography variant="text_sm_sb">{t("common.requestStatus.statuses.expired")}</Typography>
		</div>
	);
	return (
		<IconButton size={size}>
			<NoAccessCircleIcon tooltip={tooltipContent} />
		</IconButton>
	);
};

export const ExpiredStatus = React.memo(Expired);

const Denied: FC<TProps> = ({ size = "medium" }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const tooltipContent = (
		<div className={classes.statusTooltip}>
			<DeniedIcon />
			<Typography variant="text_sm_sb">{t("common.requestStatus.statuses.denied")}</Typography>
		</div>
	);
	return (
		<IconButton size={size}>
			<NoAccessCircleIcon tooltip={tooltipContent} />
		</IconButton>
	);
};

export const DeniedStatus = React.memo(Denied);

const AdminDenied: FC<TProps> = ({ size = "medium" }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const tooltipContent = (
		<div className={classes.statusTooltip}>
			<DeniedIcon />
			<Typography variant="text_sm_sb">{t("common.requestStatus.statuses.adminDenied")}</Typography>
		</div>
	);
	return (
		<IconButton size={size}>
			<NoAccessCircleIcon tooltip={tooltipContent} />
		</IconButton>
	);
};

export const AdminDeniedStatus = React.memo(AdminDenied);

const Revoked: FC<TProps> = ({ size = "medium" }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const tooltipContent = (
		<div className={classes.statusTooltip}>
			<CancelCircleIcon />
			<Typography variant="text_sm_sb">{t("common.requestStatus.statuses.revoked")}</Typography>
		</div>
	);
	return (
		<IconButton size={size}>
			<NoAccessCircleIcon tooltip={tooltipContent} />
		</IconButton>
	);
};

export const RevokedStatus = React.memo(Revoked);

const Failed: FC<TProps> = ({ size = "medium" }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const tooltipContent = (
		<div className={classes.statusTooltip}>
			<ErrorIcon />
			<Typography variant="text_sm_sb">{t("common.requestStatus.statuses.failed.label")} -</Typography>
			<Typography variant="text_sm_reg">{t("common.requestStatus.statuses.failed.contactAdmin")}</Typography>
		</div>
	);
	return (
		<IconButton size={size}>
			<NoAccessCircleIcon tooltip={tooltipContent} />
		</IconButton>
	);
};

export const FailedStatus = React.memo(Failed);

const Cancelled: FC<TProps> = ({ size = "medium" }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const tooltipContent = (
		<div className={classes.statusTooltip}>
			<CancelCircleIcon />
			<Typography variant="text_sm_sb">{t("common.requestStatus.statuses.cancelled")}</Typography>
		</div>
	);
	return (
		<IconButton size={size}>
			<NoAccessCircleIcon tooltip={tooltipContent} />
		</IconButton>
	);
};

export const CancelledStatus = React.memo(Cancelled);

const Granted: FC<TProps> = ({ size = "medium" }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const tooltipContent = (
		<div className={classes.statusTooltip}>
			<GrantedIcon />
			<Typography variant="text_sm_sb">{t("common.requestStatus.statuses.granted")}</Typography>
		</div>
	);
	return (
		<IconButton size={size}>
			<GrantedIcon tooltip={tooltipContent} />
		</IconButton>
	);
};

export const GrantedStatus = React.memo(Granted);

const PendingApproval: FC<TProps> = ({ size = "medium", request }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const tooltipContent =
		request && request.approvalRequests && request.ticketApprovalResponses ? (
			<div className={classes.pendingTooltipWrapper}>
				<div className={classes.pendingTitle}>
					<PendingIcon size={24} className={classes.pendingIcon} />
					<Typography variant="body_sb">{t("common.requestStatus.statuses.pendingApproval")}</Typography>
				</div>
				<ApprovalProcess
					approvalRequests={request.approvalRequests as List<TFullApprovalRequest>}
					approvalResponses={request.ticketApprovalResponses}
					hideTitle
				/>
			</div>
		) : null;
	return (
		<IconButton size={size}>
			<Tooltip content={tooltipContent} className={classes.pendingTooltip} placement="bottom-end" delayShow={400}>
				<PendingIcon />
			</Tooltip>
		</IconButton>
	);
};

export const PendingApprovalStatus = React.memo(PendingApproval);

const PermissionInProgress: FC<TProps> = ({ size = "medium" }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const tooltipContent = (
		<div className={classes.statusTooltip}>
			<PendingIcon />
			<Typography variant="text_sm_sb">{t("common.requestStatus.statuses.permissionInProgress")}</Typography>
		</div>
	);

	return (
		<IconButton size={size}>
			<GrantedIcon tooltip={tooltipContent} />
		</IconButton>
	);
};

export const PermissionInProgressStatus = React.memo(PermissionInProgress);
