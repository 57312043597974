import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	nameAndEmailContainer: {
		display: "flex",
		flexDirection: "column",
		alignItems: "start",
		overflow: "hidden",
		justifyContent: "center",
		width: "100%"
	}
});
