import { createUseStyles } from "react-jss";
import { BP_MEDIA_QUERIES } from "utils/ui/breakpoints";

export const useStyles = createUseStyles({
	container: {
		alignItems: "flex-start",
		display: "flex",
		justifyContent: "flex-end",
		gap: "var(--spacing-x2, 8px)",
		flexWrap: "wrap",
		[BP_MEDIA_QUERIES.TWENTY]: {
			flexWrap: "nowrap"
		}
	}
});
