import React from "react";

export const JumpCloudLogo: FC = ({ className }) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" className={className}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M16.0604 8.0302C15.7527 8.0302 15.4555 8.07503 15.1749 8.1585C14.5313 6.94066 13.2518 6.11073 11.7785 6.11073C9.91061 6.11073 8.35426 7.44485 8.01038 9.21221C7.98687 9.21169 7.96328 9.21142 7.93964 9.21142C6.74238 9.21142 5.7036 9.88999 5.18694 10.8835C4.84888 10.7571 4.48285 10.6879 4.10067 10.6879C2.38822 10.6879 1 12.0761 1 13.7886C1 15.501 2.38822 16.8893 4.10067 16.8893C4.1517 16.8893 4.20243 16.888 4.25285 16.8856L4.24832 16.8893L4.98663 16.8893C5.15919 15.7641 6.46627 15.3167 7.14809 15.2239C6.64059 14.8181 6.31552 14.1936 6.31552 13.4933C6.31552 12.2701 7.30711 11.2785 8.53029 11.2785C9.75347 11.2785 10.7451 12.2701 10.7451 13.4933C10.7451 14.1767 10.4355 14.7878 9.94894 15.1941C10.447 15.2974 10.8831 15.4473 11.2618 15.5972C11.6788 15.1679 12.277 14.8678 13.02 14.6967C12.528 14.2263 12.2215 13.5634 12.2215 12.8289C12.2215 11.4018 13.3784 10.245 14.8054 10.245C16.2325 10.245 17.3893 11.4018 17.3893 12.8289C17.3893 13.5592 17.0863 14.2187 16.5992 14.6887C18.5306 14.9989 19.1121 16.2835 19.1611 16.8893L19.7517 16.8893L19.7481 16.8856C19.7982 16.888 19.8486 16.8893 19.8993 16.8893C21.6117 16.8893 23 15.501 23 13.7886C23 12.0761 21.6117 10.6879 19.8993 10.6879C19.6379 10.6879 19.3841 10.7203 19.1416 10.7812C18.9679 9.23334 17.6547 8.0302 16.0604 8.0302Z"
			fill="#052B49"
		/>
	</svg>
);
