import { Set } from "immutable";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { type TSearchTicketsParams, getTickets, exportTicketsToCSV, retryTicket } from "api/tickets";
import { RequestBar } from "components/common/RequestBar";
import { REQUEST_BAR_HEIGHT } from "components/common/RequestBar/styles";
import { RequestDetails } from "components/common/RequestDetails";
import { VirtualRequestList } from "components/common/RequestList";
import { TicketFiltersModal } from "components/common/TicketFilters";
import { PageTitleContent } from "components/templates/PageTitleContentTemplate";
import { Button } from "components/ui/Button";
import { StaticChip } from "components/ui/chips/StaticChip";
import { DownloadIcon } from "components/ui/Icons/DownloadIcon";
import { FilterIcon } from "components/ui/Icons/FilterIcon";
import { Section } from "components/ui/Section";
import { Skeleton } from "components/ui/Skeleton";
import { Typography } from "components/ui/Typography";
import { TicketRenewalProvider } from "context/renewalTicketContext";
import { useTicketUpdatesContext } from "context/ticketUpdatesContext";
import { useAuthenticatedUser } from "hooks/useAuthenticatedUser";
import { usePagination } from "hooks/usePagination";
import { useSortState } from "hooks/useSortState";
import { notEmpty } from "utils/comparison";
import { IPaginatedSearchOptions } from "utils/searchUtils";
import { AnimatedRequestBar } from "../RequestsPage/components/AwaitingApprovalSection";
import type { TicketModel } from "models/TicketModel";

const REQUESTS_PAGE_SIZE = 30;

const REQUESTS_LOGS_SUBSCRIPTION_ID = "requestsLogs";

export const RequestsLogPage: FC = ({ className, innerRef }) => {
	const { t } = useTranslation();
	const { user } = useAuthenticatedUser();
	const [filtersOpen, setFiltersOpen] = useState(false);
	const { sortOrder, sortFields } = useSortState({ defaultSortField: "ticketNumber", defaultSortOrder: "DESC" });

	const [ticketFilters, setTicketFilters] = useState<TSearchTicketsParams>({});
	const searchTickets = useCallback(
		(paginationOptions: IPaginatedSearchOptions) => getTickets(paginationOptions, ticketFilters),
		[ticketFilters]
	);
	const exportTickets = useCallback(() => exportTicketsToCSV(ticketFilters), [ticketFilters]);
	const { getPage, totalResults, itemsForVirtualTable, setItem } = usePagination({
		fetchItems: searchTickets,
		perPage: REQUESTS_PAGE_SIZE,
		sortOrder,
		sortFields
	});
	const { subscribeTicketUpdates, unsubscribeTicketUpdates } = useTicketUpdatesContext();
	// Saving the request ids that are waiting for approval to avoid animation bugs when request status is declined/approved
	const [animationRequestsIds, setAnimationRequestsIds] = useState(Set<string>());

	useEffect(() => {
		if (!itemsForVirtualTable) return;
		const newIds = itemsForVirtualTable
			.filter(request => request?.status === "waitingForApproval" && !animationRequestsIds.has(request.id))
			.map(request => request?.id)
			.filter(notEmpty);
		if (newIds.length) {
			setAnimationRequestsIds(curr => curr.union(newIds));
		}
	}, [itemsForVirtualTable, animationRequestsIds]);

	const requests = useMemo(() => itemsForVirtualTable || [], [itemsForVirtualTable]);

	const totalAmount = useMemo(
		() => totalResults - ((itemsForVirtualTable?.length || 0) - requests.length),
		[totalResults, itemsForVirtualTable, requests]
	);

	const onAnimationEnd = useCallback((requestId: string) => {
		setAnimationRequestsIds(prev => prev.delete(requestId));
	}, []);

	const onTicketUpdated = useCallback(
		(ticket: TicketModel) => {
			setItem(ticket);
		},
		[setItem]
	);

	useEffect(() => {
		subscribeTicketUpdates(REQUESTS_LOGS_SUBSCRIPTION_ID, onTicketUpdated);
		return () => {
			unsubscribeTicketUpdates(REQUESTS_LOGS_SUBSCRIPTION_ID);
		};
	}, [onTicketUpdated, subscribeTicketUpdates, unsubscribeTicketUpdates]);

	const openFiltersModal = useCallback(() => setFiltersOpen(true), []);
	const closeFiltersModal = useCallback(() => setFiltersOpen(false), []);

	const renderRequest = useCallback(
		(request?: TicketModel) => {
			if (!request) return <Skeleton height={REQUEST_BAR_HEIGHT} />;
			const isAnimated = animationRequestsIds.has(request.id);
			return isAnimated ? (
				<AnimatedRequestBar key={request.id} request={request} onAnimationEnd={onAnimationEnd} />
			) : (
				<RequestBar key={request.id} request={request} openSidebarOnClick onRetry={retryTicket} />
			);
		},
		[animationRequestsIds, onAnimationEnd]
	);

	const title = (
		<>
			<Typography variant="body_sb">{t("shared.all")}</Typography>
			<StaticChip size="small" variant="regular">
				{t("number", { value: Number(totalResults || 0) })}
			</StaticChip>
		</>
	);
	const titleActions = (
		<Button size="medium" onClick={openFiltersModal} prefix={<FilterIcon />}>
			{t("buttons.filter")}
		</Button>
	);

	return (
		<TicketRenewalProvider>
			<PageTitleContent innerRef={innerRef} className={className}>
				<RequestDetails />
				<TicketFiltersModal
					isOpen={filtersOpen}
					onClose={closeFiltersModal}
					onFiltersChange={setTicketFilters}
					ticketFilters={ticketFilters}
				/>
				<PageTitleContent.Header>
					<PageTitleContent.Header.Bottom>
						<PageTitleContent.Header.Title title={t("pages.requestsLog.title")} />
						<PageTitleContent.Header.Actions>
							{user?.isAdmin && (
								<Button variant="secondary" size="medium" onClick={exportTickets} suffix={<DownloadIcon />}>
									{t("buttons.downloadAsCSV")}
								</Button>
							)}
						</PageTitleContent.Header.Actions>
					</PageTitleContent.Header.Bottom>
				</PageTitleContent.Header>
				<PageTitleContent.Body>
					<Section title={title} titleActions={titleActions} fullHeight>
						<VirtualRequestList
							fetchPage={getPage}
							innerRef={innerRef}
							perPage={REQUESTS_PAGE_SIZE}
							requestRenderer={renderRequest}
							requests={requests}
							totalRequests={totalAmount}
							fullHeight
						/>
					</Section>
				</PageTitleContent.Body>
			</PageTitleContent>
		</TicketRenewalProvider>
	);
};
