import classNames from "classnames";
import React, { useCallback } from "react";
import { Typography } from "components/ui/legacy/Typography";
import { useStyles } from "./styles";

interface IRadioInputProps<T> {
	disabled?: boolean;
	label?: string;
	onClick?: (value: T) => void;
	selected?: boolean;
	small?: boolean;
	value: T;
}

export function RadioInput<T>({
	label,
	value,
	disabled,
	selected,
	onClick,
	small,
	className,
	innerRef
}: TProps<IRadioInputProps<T>>) {
	const classes = useStyles();
	const clickHandler = useCallback(
		(event: React.MouseEvent) => {
			event.stopPropagation();
			if (!disabled && onClick) onClick(value);
		},
		[onClick, value, disabled]
	);

	const notSelected = !selected;
	return (
		<Typography component="div" className={classNames(classes.radioInput, className)} innerRef={innerRef}>
			<div className={classNames(classes.radioButton, { disabled, selected, notSelected })} onClick={clickHandler}>
				<div
					className={classNames(classes.filledRadioButton, {
						disabled,
						notSelected,
						selected
					})}
				/>
			</div>
			<Typography variant={small ? "small" : "standard"}>{label}</Typography>
		</Typography>
	);
}
