import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	fieldsContainer: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "flex-start",
		gap: "var(--spacing-x2, 8px)",
		flexWrap: "wrap",
		width: "100%"
	},
	generalInfo: {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start",
		justifyContent: "flex-start",
		gap: "var(--spacing-x3, 12px)",
		width: "100%"
	},
	field: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-start",
		alignItems: "flex-start",
		gap: "var(--spacing-x1, 4px)",
		minWidth: "60px"
	}
});
