import classNames from "classnames";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IconPrefix } from "components/ui/IconPrefix";
import { RoleIcon } from "components/ui/Icons/RoleIcon";
import { TitleTooltip } from "components/ui/TitleTooltip";
import { BasicRoleBar, type TRole } from "./BasicRoleBar";
import { useStyles } from "./styles";
import type { TTooltipProps } from "components/ui/Tooltip";

export const RoleBarTooltip: FC<{ role: TRole; children: TTooltipProps["children"] }> = ({
	className,
	innerRef,
	role,
	children
}) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const tooltipContent = useMemo(() => <BasicRoleBar role={role} />, [role]);

	const tooltipHeader = useMemo(
		() => (
			<div className={classes.tooltipTitle}>
				<IconPrefix semibold Icon={RoleIcon} content={t("shared.role")} />
			</div>
		),
		[classes.tooltipTitle, t]
	);

	if (!children) return null;

	return (
		<TitleTooltip
			className={classNames(classes.tooltip, className)}
			innerRef={innerRef}
			header={tooltipHeader}
			body={tooltipContent}
			placement="top">
			{children}
		</TitleTooltip>
	);
};
