import classNames from "classnames";
import React from "react";
import { Tooltip } from "components/ui/Tooltip";
import { useDirectoryGroup } from "hooks/useDirectoryGroup";
import { useStyles } from "./styles";
import { TextWithDirectoryIcon } from "../TextWithDirectoryIcon";

interface IProps {
	groupName: string;
	groupEmail?: string;
	withSeparator?: string;
	isDeleted?: boolean;
	// group id might not be provided if the name came from audit log
	groupId?: string;
}

export const DirectoryGroupTooltip: FC<IProps> = ({
	groupEmail,
	groupName,
	withSeparator,
	isDeleted,
	groupId,
	className
}) => {
	const classes = useStyles();
	const directoryGroup = useDirectoryGroup(groupId || "");
	const email = groupEmail || directoryGroup?.email || undefined;
	const directoryGroupComponent = (
		<TextWithDirectoryIcon
			key={groupName}
			value={groupName || ""}
			className={classNames(classes.directoryGroup, className)}
			separator={withSeparator ? "," : undefined}
			iconClassName={classes.directoryGroupIcon}
			isDeleted={isDeleted}
		/>
	);
	return <Tooltip content={email}>{directoryGroupComponent}</Tooltip>;
};
