import React from "react";
import { makeIcon } from "components/ui/Icon";

export const WorkflowsIcon = makeIcon(
	<svg viewBox="0 0 24 24" fill="none">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M19 3C17.8954 3 17 3.89543 17 5V5.97578C15.621 6.011 14.5466 6.33066 13.6561 6.845C12.6274 7.43915 11.9147 8.26024 11.3247 8.94007L11.2454 9.03135C10.611 9.76093 10.1009 10.3177 9.3904 10.679C8.84141 10.9583 8.10429 11.1511 7 11.0952V10.0001C7 8.89548 6.10457 8.00005 5 8.00005H3C1.89543 8.00005 1 8.89548 1 10.0001V14.0001C1 15.1046 1.89543 16.0001 3 16.0001H5C6.10457 16.0001 7 15.1046 7 14.0001V13.2809C7.88885 13.21 8.4951 13.3211 8.92904 13.497C9.53744 13.7437 9.94813 14.1728 10.3738 14.7687C10.5094 14.9585 10.6138 15.1158 10.726 15.2847L10.726 15.2847L10.726 15.2848L10.7262 15.285C10.8185 15.4241 10.9162 15.5711 11.0407 15.7506C11.2797 16.0951 11.5589 16.467 11.9047 16.8031C12.4491 17.3322 13.1209 17.7345 14 17.9073V19C14 20.1046 14.8954 21 16 21H18C19.1046 21 20 20.1046 20 19V15C20 13.8954 19.1046 13 18 13H16C14.8954 13 14 13.8954 14 15V15.8335C13.7135 15.7206 13.493 15.5578 13.2986 15.3688C13.0897 15.1658 12.8962 14.9166 12.684 14.6107C12.6139 14.5095 12.526 14.3781 12.4316 14.2369C12.2901 14.0255 12.1341 13.7922 12.0013 13.6062C11.6686 13.1405 11.2635 12.6438 10.7115 12.2278C11.5906 11.6813 12.2196 10.959 12.7546 10.3437L12.7628 10.3342C13.4035 9.59749 13.9222 9.00095 14.6564 8.57687C15.2113 8.25638 15.9387 8.01051 17 7.97648V9C17 10.1046 17.8954 11 19 11H21C22.1046 11 23 10.1046 23 9V5C23 3.89543 22.1046 3 21 3H19ZM19 5H21V9L19 9V5ZM3 10.0001H5V14.0001H3V10.0001ZM18 19V15H16V19H18Z"
			fill="currentColor"
		/>
	</svg>
);
