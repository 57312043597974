import React from "react";
import { makeIcon } from "components/ui/Icon";

export const SendIcon = makeIcon(
	<svg viewBox="0 0 24 24" fill="none">
		<path
			d="M19.8 12.925L4.4 19.425C4.06667 19.5583 3.75 19.5291 3.45 19.3375C3.15 19.1458 3 18.8666 3 18.5V5.49997C3 5.1333 3.15 4.85414 3.45 4.66247C3.75 4.4708 4.06667 4.44164 4.4 4.57497L19.8 11.075C20.2167 11.2583 20.425 11.5666 20.425 12C20.425 12.4333 20.2167 12.7416 19.8 12.925ZM5 17L16.85 12L5 6.99997V10.5L11 12L5 13.5V17Z"
			fill="currentColor"
		/>
	</svg>
);
