import { useCallback, useMemo, useState } from "react";

export default function useErrorModalState(): ErrorModalState {
	const [isOpen, setIsOpenState] = useState(false);
	const [error, setErrorState] = useState<Error | null>(null);
	const [retries, setRetries] = useState(0);

	const open = useCallback(() => {
		setIsOpenState(true);
	}, []);

	const close = useCallback(() => {
		setIsOpenState(false);
	}, []);

	const retry = useCallback(() => {
		setRetries(retries => retries + 1);
		setIsOpenState(false);
	}, []);

	const setIsOpen = useCallback((isOpen: boolean) => {
		setIsOpenState(isOpen);
	}, []);

	const setError = useCallback((error: Error | null) => {
		if (error) {
			setErrorState(error);
			setIsOpenState(true);
		} else setIsOpenState(false);
	}, []);

	const res = useMemo(
		() => ({
			errorModalClose: close,
			errorModalError: error,
			errorModalIsOpen: isOpen,
			errorModalOpen: open,
			errorModalRetries: retries,
			errorModalRetry: retry,
			errorModalSetError: setError,
			errorModalSetIsOpen: setIsOpen
		}),
		[setError, isOpen, error, close, retry, retries, open, setIsOpen]
	);

	return res;
}

type ErrorModalState = {
	errorModalClose(): void;
	errorModalError: Error | null;
	errorModalIsOpen: boolean;
	errorModalOpen(): void;
	errorModalRetries: number;
	errorModalRetry(): void;
	errorModalSetError(error: Error | null): void;
	errorModalSetIsOpen(isOpen: boolean): void;
};
