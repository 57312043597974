import { List } from "immutable";
import React, { ReactElement } from "react";

type TProviderProps = {
	providers: FC | FC[];
	children: ReactElement;
};

export function Provider({ providers, children }: TProviderProps) {
	if (!Array.isArray(providers)) {
		providers = [providers];
	}
	// We reverse so the order of the array is the order of wrappers
	const reversedProviders = List(providers).reverse();

	let ComponentChildren = children;
	ComponentChildren = reversedProviders.reduce((innerChildren, CurrentProvider) => {
		return <CurrentProvider>{innerChildren}</CurrentProvider>;
	}, ComponentChildren);

	return ComponentChildren;
}
