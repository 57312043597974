import React from "react";
import { makeIcon } from "components/ui/Icon";
import { OpsgenieLogo } from "components/ui/systemLogos/OpsgenieLogo";
import { PagerDutyLogo } from "components/ui/systemLogos/PagerDutyLogo";
import type { TOnCallType } from "models/OnCallIntegrationScheduleModel";

export const ON_CALL_INTEGRATIONS_LOGOS = new Map<TOnCallType, IconComponent>([
	["Opsgenie", makeIcon(<OpsgenieLogo />)],
	["PagerDuty", makeIcon(<PagerDutyLogo />)]
]);
