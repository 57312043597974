import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	modal: {
		gap: "var(--spacing-x5, 20px)",
		width: "400px"
	},
	contentContainer: {
		display: "flex",
		flexDirection: "column",
		gap: "var(--spacing-x10, 40px)",
		"&$inputsContainer": {
			gap: "var(--spacing-x4, 16px)"
		}
	},
	inputsContainer: {}
});
