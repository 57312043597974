import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	selectOption: {
		borderRadius: "unset"
	},
	selectOptionsContainer: {
		minWidth: 0,
		"& $selectOption": {
			display: "flex",
			alignItems: "center"
		}
	},
	innerTooltipContainer: {
		display: "inline-flex"
	},
	small: {},
	medium: {},
	large: {}
});
