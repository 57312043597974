import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	option: {
		"&:hover:not($active)": {
			backgroundColor: "var(--color-grey-700)"
		},
		justifyContent: "space-between",
		alignItems: "center",
		padding: "0 24px",
		transition: "background-color 0.2s",
		display: "flex",
		textDecoration: "none",
		height: 40,
		width: "var(--side-nav-width)"
	},
	active: {
		backgroundColor: "var(--color-purple-500)",
		cursor: "default"
	},
	text: {
		opacity: 1,
		transition: "0.6s opacity ease",
		"&$minimized": { opacity: 0 },
		display: "inline-flex",
		justifyContent: "center"
	},
	title: {
		justifyItems: "flex-start",
		alignItems: "center",
		display: "flex",
		gap: 12,
		color: "var(--nav-text-color)"
	},
	minimized: {},
	chip: {
		fontSize: 10
	},
	container: {
		maxWidth: "var(--side-nav-width)",
		"&$minimized": { maxWidth: "var(--side-nav-width-collapsed)" },
		overflow: "visible"
	},
	tooltip: {
		backgroundColor: "var(--color-black)",
		color: "var(--color-white)"
	}
});
