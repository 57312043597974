import { uniq } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { AreYouSureModal } from "components/common/AreYouSureModal";
import { EditorSuccessModal } from "components/common/EditorSuccessModal";
import { PageTitleContent } from "components/templates/PageTitleContentTemplate";
import { Button } from "components/ui/Button";
import { ErrorModal } from "components/ui/ErrorModal";
import { AddIcon } from "components/ui/Icons/AddIcon";
import { useBundlesContext } from "context/bundlesContext";
import { useBundles } from "hooks/useBundles";
import useErrorModalState from "hooks/useErrorModalState";
import { useIsOpenState } from "hooks/useIsOpenState";
import { BundleModel } from "models/BundleModel";
import { BundleTableSection } from "./components/BundleTableSection";
import { EditBundle } from "./components/EditBundle";
import { useStyles } from "./styles";

export const BundlesPage: FC = ({ className }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const bundles = useBundles(false, true);
	const {
		actions: { deleteBundle }
	} = useBundlesContext();
	const [bundleToEdit, setBundleToEdit] = useState<BundleModel | null>(null);
	const { isOpen: editorIsOpen, open: openEditor, close: closeEditor } = useIsOpenState();
	const { isOpen: successModalIsOpen, open: openSuccessModal, close: closeSuccessModal } = useIsOpenState();
	const { isOpen: areYouSureModalIsOpen, open: openAreYouSureModal, close: closeAreYouSureModal } = useIsOpenState();
	const {
		errorModalSetError,
		errorModalIsOpen,
		errorModalError,
		errorModalClose: closeErrorModal
	} = useErrorModalState();

	const categories = useMemo(
		() =>
			bundles
				? uniq(
						bundles
							.toList()
							.toArray()
							.map(bundle => bundle.category)
							.filter(option => !!option)
					)
				: [],
		[bundles]
	);

	const onAddBundleClick = useCallback(() => {
		setBundleToEdit(null);
		openEditor();
	}, [openEditor]);

	const onEditBundleClick = useCallback(
		(bundle: BundleModel) => {
			setBundleToEdit(bundle);
			openEditor();
		},
		[openEditor]
	);
	const [removeAction, setRemoveAction] = useState<() => Promise<void>>();

	const onDeleteBundleClick = useCallback(
		(bundle: BundleModel) => {
			const remove = () => deleteBundle(bundle.id);
			setRemoveAction(() => remove);
			openAreYouSureModal();
		},
		[openAreYouSureModal, deleteBundle]
	);

	const removeBundle = useCallback(async () => {
		if (!removeAction) return;
		try {
			await removeAction();
		} catch (error) {
			errorModalSetError(error as Error);
		} finally {
			closeAreYouSureModal();
		}
	}, [closeAreYouSureModal, removeAction, errorModalSetError]);

	const onSubmit = useCallback(() => {
		closeEditor();
		openSuccessModal();
	}, [openSuccessModal, closeEditor]);

	return (
		<PageTitleContent className={className} noTransition>
			<PageTitleContent.Header>
				<PageTitleContent.Header.Bottom>
					<PageTitleContent.Header.Title title={t("pages.bundles.title")} />
					<Button size="large" onClick={onAddBundleClick} className={classes.addBundleButton} prefix={<AddIcon />}>
						{t("pages.bundles.addBundle")}
					</Button>
				</PageTitleContent.Header.Bottom>
			</PageTitleContent.Header>
			<PageTitleContent.Body>
				{editorIsOpen && (
					<EditBundle
						isOpen={editorIsOpen}
						onClose={closeEditor}
						bundle={bundleToEdit || undefined}
						onError={errorModalSetError}
						onSubmit={onSubmit}
						categories={categories as string[]}
					/>
				)}
				<ErrorModal error={errorModalError} isOpen={errorModalIsOpen} onClose={closeErrorModal} />
				<EditorSuccessModal
					state={bundleToEdit ? "edit" : "create"}
					isOpen={successModalIsOpen}
					editTitle={t("pages.bundles.bundleEditor.editSuccessModalTitle")}
					createTitle={t("pages.bundles.bundleEditor.createSuccessModalTitle")}
					content={t("pages.bundles.bundleEditor.successModalInformation")}
					onClose={closeSuccessModal}
				/>
				<AreYouSureModal
					onClose={closeAreYouSureModal}
					isOpen={areYouSureModalIsOpen}
					onAction={removeBundle}
					actionLabel={t("pages.bundles.deleteBundle.deleteBundle")}
					title={t("pages.bundles.deleteBundle.title")}
					content={t("pages.bundles.deleteBundle.content")}
				/>
				<BundleTableSection onEdit={onEditBundleClick} onRemove={onDeleteBundleClick} />
			</PageTitleContent.Body>
		</PageTitleContent>
	);
};
