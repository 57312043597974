import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	modal: {
		padding: "20px",
		maxWidth: "1052px",
		width: "100%",
		"&$withoutUnmanaged": {
			maxWidth: "594px"
		}
	},
	content: {
		padding: "0 var(--spacing-x12, 40px) 0 var(--spacing-x5, 20px)",
		marginBottom: "var(--spacing-x12, 48px)"
	},
	divider: {
		margin: "var(--spacing-x8, 32px) 0"
	},
	unmanagedRolesWrapper: {
		marginTop: "var(--spacing-x8, 32px)"
	},
	unmanagedRolesList: {
		display: "flex",
		flexDirection: "column",
		gap: "var(--spacing-x2, 8px)",
		height: ({ unmanagedRoleListHeight }: { unmanagedRoleListHeight: number }) => `${unmanagedRoleListHeight}px`
	},
	loading: {
		marginTop: "var(--spacing-x4, 16px)"
	},
	withoutUnmanaged: {}
});
