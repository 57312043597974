import React, { useMemo } from "react";
import { Trans, TransProps, useTranslation } from "react-i18next";
import { LoadingDots } from "components/ui/LoadingDots";
import { useApprovalFlowsWebhook } from "hooks/useApprovalFlowsWebhook";
import { boldComponent } from "i18n";
import { ApprovalFlowsWebhookAuditLogModel } from "models/auditLogs";
import { useStyles } from "./styles";
import { AuditLogUser } from "../AuditLogUser";
import type { ParseKeys } from "i18next";
import type {
	IApprovalFlowsWebhookDeletedAuditLogDiffData,
	IApprovalFlowsWebhookUpdatedAuditLogDiffData
} from "models/auditLogs/ApprovalFlowsWebhookAuditLogModel";
import type { TAuditLogContentComponent } from "./AuditLogContent.types";

const TRANSLATION_PREFIX = "pages.auditLog.auditLogList.approvalFlowsWebhook.";

const isDeletedAuditLogData = (
	auditLog: ApprovalFlowsWebhookAuditLogModel
): auditLog is ApprovalFlowsWebhookAuditLogModel & { data: IApprovalFlowsWebhookDeletedAuditLogDiffData } =>
	auditLog.action === "WorkflowStepWebhookDeleted";

const isUpdatedAuditLogData = (
	auditLog: ApprovalFlowsWebhookAuditLogModel
): auditLog is ApprovalFlowsWebhookAuditLogModel & { data: IApprovalFlowsWebhookUpdatedAuditLogDiffData } =>
	auditLog.action === "WorkflowStepWebhookUpdated";

export const ApprovalFlowsWebhookAuditLogContent: TAuditLogContentComponent<ApprovalFlowsWebhookAuditLogModel> = ({
	auditLog,
	logUser
}) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const { webhook, loading } = useApprovalFlowsWebhook(auditLog.approvalFlowsWebhookId);

	const translations = useMemo(() => {
		const isDeleteAuditLog = isDeletedAuditLogData(auditLog);
		const base: (TransProps<ParseKeys> & { key: string })[] = [
			{
				key: "title",
				values: { name: isDeleteAuditLog ? auditLog.data.name : webhook?.name },
				components: { bold: boldComponent, user: <AuditLogUser user={logUser} className={classes.userText} /> },
				i18nKey: auditLog.action
					? (`${TRANSLATION_PREFIX}${auditLog.action}` as const)
					: ("shared.emptyString" as const)
			}
		];

		if (!isDeleteAuditLog && isUpdatedAuditLogData(auditLog)) {
			const keys: ("name" | "additionalParams" | "url" | "headers")[] = [];

			if (auditLog.data.fromName !== auditLog.data.toName) keys.push("name");
			if (auditLog.data.fromAdditionalParams !== auditLog.data.toAdditionalParams) keys.push("additionalParams");
			if (auditLog.data.fromUrl !== auditLog.data.toUrl) keys.push("url");
			if (auditLog.data.headersUpdated) keys.push("headers");

			const keysTranslated = keys.map(key => t(`${TRANSLATION_PREFIX}keys.${key}` as const)).join(", ");
			base.push({
				key: "updateData",
				values: { keys: keysTranslated, count: keys.length },
				components: { bold: boldComponent, user: <AuditLogUser user={logUser} className={classes.userText} /> },
				i18nKey: `${TRANSLATION_PREFIX}keysUpdated` as const
			});
		}

		return base;
	}, [auditLog, classes.userText, logUser, t, webhook?.name]);

	return loading ? (
		<LoadingDots />
	) : (
		translations.map(({ key, ...props }) => (
			<div key={key}>
				<Trans t={t} {...props} />
			</div>
		))
	);
};
