import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	externalLogsNotConnectedWrapper: {
		alignItems: "center",
		border: "2px solid var(--color-black)",
		borderRadius: "var(--spacing-x2, 8px)",
		display: "flex",
		gap: "var(--spacing-x4, 12px)",
		marginBottom: "var(--spacing-x10, 40px)",
		maxWidth: "538px",
		padding: "var(--spacing-x3, 12px)"
	},
	icon: {
		color: "var(--color-blue-500)"
	}
});
