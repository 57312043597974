import React, { useCallback } from "react";
import { WorkflowEntity } from "components/common/entities/WorkflowEntity";
import { TooltipOnOverflow } from "components/ui/TooltipOnOverflow";
import { ApprovalAlgorithmModel } from "models/ApprovalAlgorithmModel";
import { INHERIT, type TInherit, type TOptionComponentProps } from "utils/ui/select";
import { SelectOption, type TEntityOverrideProps } from "../SelectOption";

function WorkflowOption<T extends ApprovalAlgorithmModel | TInherit>({
	disabled = false,
	getTextContent,
	isSelected = false,
	onSelect,
	option,
	optionKey
}: TOptionComponentProps<T>) {
	const renderOption = useCallback(
		(entityProps: TEntityOverrideProps) => {
			if (option === INHERIT) {
				const content = getTextContent ? getTextContent(option) : option;
				return <TooltipOnOverflow content={content} textVariant="body_reg" />;
			}
			return <WorkflowEntity withIcon approvalAlgorithm={option} {...entityProps} />;
		},
		[option, getTextContent]
	);

	return (
		<SelectOption
			disabled={disabled}
			isSelected={isSelected}
			key={optionKey}
			onSelect={onSelect}
			value={option}
			renderOption={renderOption}
		/>
	);
}

WorkflowOption.isSelectOption = true;

export { WorkflowOption };
