import { createUseStyles } from "react-jss";

export const ACTIVITY_WIDTH_PX = 376;
export const MIN_DETAILS_WIDTH_PX = 388;

export const useStyles = createUseStyles({
	sidebarContent: {
		overflow: "hidden"
	},
	requestDetailsSidebar: {
		display: "flex",
		height: "100%"
	},
	ticketActivityContainer: {
		background: "var(--color-purple-50)",
		width: `${ACTIVITY_WIDTH_PX}px`,
		maxWidth: `${ACTIVITY_WIDTH_PX}px`,
		minWidth: `${ACTIVITY_WIDTH_PX}px`,
		height: "100%"
	},
	requestData: {
		flex: 1,
		padding: "var(--spacing-x7, 28px) var(--spacing-x4, 16px) var(--spacing-x10, 40px) var(--spacing-x6, 24px)",
		overflowY: "auto",
		width: "100%"
	},
	detailsContainer: {
		overflow: "hidden",
		height: "100%",
		width: "100%",
		display: "flex",
		flexDirection: "column",
		maxWidth: "100%",
		minWidth: `${MIN_DETAILS_WIDTH_PX}px`
	},
	divider: {
		margin: "var(--spacing-x7, 28px) 0"
	},
	requestTitleContainer: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		marginBottom: "var(--spacing-x9, 36px)"
	},
	bottomBar: {
		padding: "var(--spacing-x6, 24px)",
		display: "flex",
		justifyContent: "flex-end",
		gap: "var(--spacing-x2, 8px)",
		boxShadow: "0px -4px 5px 1px rgba(0, 0, 0, 0.20)"
	}
});
