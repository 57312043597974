import classNames from "classnames";
import { Map } from "immutable";
import orderBy from "lodash/orderBy";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { SortableTableHeader } from "components/common/SortableTableHeader";
import { Table } from "components/ui/Table";
import { TResourcePermission, useResourcePermissionsReviewContext } from "context/resourcePermissionReviewContext";
import { SortTableProvider, useSortTableContext } from "context/sortingTableContext";
import { ASC } from "hooks/usePagination";
import { getTicketExpirationDate } from "utils/tickets/ticketExpirationDate";
import { PermissionTableRow } from "./PermissionTableRow";
import { useStyles } from "./styles";
import type { ListIteratee } from "lodash";

const TRANSLATION_PREFIX = "common.resourcePermissionsReviewTable";

interface IProps {
	changeCheckbox?: (permissionId: string, checked: boolean) => void;
	removeCheckbox?: (permissionId: string) => void;
	getIsSelected?: (permissionId: string) => boolean;
	readonly?: boolean;
	immediateRevoke: boolean;
}

const ResourcePermissionsTableContent: FC<IProps> = ({
	changeCheckbox,
	removeCheckbox,
	className,
	getIsSelected,
	id,
	innerRef,
	readonly,
	immediateRevoke
}) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const {
		state: { sortOrder, sortFields }
	} = useSortTableContext();

	const {
		state: { resourcePermissions }
	} = useResourcePermissionsReviewContext();

	const permissions = useMemo(() => {
		if (!resourcePermissions) {
			return [];
		}
		return resourcePermissions.toList().toArray();
	}, [resourcePermissions]);

	const headers = useMemo(
		() =>
			[
				[t(`${TRANSLATION_PREFIX}.table.role`), "role"],
				[t(`${TRANSLATION_PREFIX}.table.account`), "account"],
				[t(`${TRANSLATION_PREFIX}.table.users`)],
				[t(`${TRANSLATION_PREFIX}.table.requests`)],
				[t(`${TRANSLATION_PREFIX}.table.expires`), "expires"],
				[t(`${TRANSLATION_PREFIX}.table.lastUse`), "lastUse"],
				[t(`${TRANSLATION_PREFIX}.table.comments`), "comments"]
			].concat(readonly ? [[t(`${TRANSLATION_PREFIX}.table.status`)]] : []),
		[readonly, t]
	);

	const permissionExpirations = useMemo(
		() =>
			permissions.reduce((acc, permission) => {
				const ticketPermissions = permission.integrationActorPermission?.ticketPermissions;
				const expiresAt = getTicketExpirationDate(ticketPermissions || null);

				return acc.set(
					permission.resourcePermissionId,
					expiresAt ? t("dateTime.date", { date: expiresAt }) : t("shared.ticketExpiration.never")
				);
			}, Map<string, string>()),
		[permissions, t]
	);

	const sortedPermissions = useMemo(() => {
		if (!sortOrder || !sortFields) {
			return permissions;
		}
		const sortingFuncs = Map<string, ListIteratee<TResourcePermission>>({
			role: "roleName",
			account: "actorName",
			expires: permission => permissionExpirations.get(permission.resourcePermissionId),
			lastUse: "integrationActorPermission.integrationActor.lastUsed",
			comments: "comments"
		});
		return (
			permissions &&
			(orderBy(
				permissions,
				[sortingFuncs.get(sortFields[0])],
				[sortOrder === ASC ? "asc" : "desc"]
			) as TResourcePermission[])
		);
	}, [sortFields, permissions, sortOrder, permissionExpirations]);

	return (
		<Table
			outline
			id={id}
			innerRef={innerRef}
			className={classNames(classes.table, className)}
			gridColumns="2fr 1fr 1.8fr 1fr 1fr 1fr 1.8fr 15rem">
			<Table.Row>
				{headers.map(([title, field]) => (
					<SortableTableHeader title={title} key={title} field={field} />
				))}
			</Table.Row>
			{sortedPermissions &&
				sortedPermissions.map(permission => (
					<PermissionTableRow
						permission={permission}
						permissionExpirations={permissionExpirations}
						key={permission.resourcePermissionId}
						changeCheckbox={changeCheckbox}
						removeCheckbox={removeCheckbox}
						getIsSelected={getIsSelected}
						readonly={readonly}
						immediateRevoke={immediateRevoke}
					/>
				))}
		</Table>
	);
};

export const ResourcePermissionsTable: FC<IProps> = props => (
	<SortTableProvider defaultSortField="role" defaultSortOrder={ASC}>
		<ResourcePermissionsTableContent {...props} />
	</SortTableProvider>
);
