import { IntegrationAllowRequestsFilter } from "./integrationAllowRequestsFilter";
import { IntegrationIdFilter } from "./integrationIdFilter";
import { IntegrationMaintainerFilter } from "./integrationMaintainerFilter";
import { IntegrationNameFilter } from "./integrationNameFilter";
import { IntegrationOwnerFilter } from "./integrationOwnerFilter";
import { IntegrationWorkflowFilter } from "./integrationWorkflowFilter";

export const INTEGRATION_FILTERS = [
	IntegrationAllowRequestsFilter,
	IntegrationIdFilter,
	IntegrationMaintainerFilter,
	IntegrationNameFilter,
	IntegrationOwnerFilter,
	IntegrationWorkflowFilter
];

export const INTEGRATION_FILTERS_BY_NAME = {
	[IntegrationAllowRequestsFilter.filterName]: IntegrationAllowRequestsFilter,
	[IntegrationIdFilter.filterName]: IntegrationIdFilter,
	[IntegrationMaintainerFilter.filterName]: IntegrationMaintainerFilter,
	[IntegrationNameFilter.filterName]: IntegrationNameFilter,
	[IntegrationOwnerFilter.filterName]: IntegrationOwnerFilter,
	[IntegrationWorkflowFilter.filterName]: IntegrationWorkflowFilter
};

export {
	IntegrationAllowRequestsFilter,
	IntegrationIdFilter,
	IntegrationMaintainerFilter,
	IntegrationNameFilter,
	IntegrationOwnerFilter,
	IntegrationWorkflowFilter
};
