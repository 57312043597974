import classNames from "classnames";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useResizeDetector } from "react-resize-detector";
import { useStyles } from "./styles";
import { AccountChip } from "../chips/AccountChip";
import { Chip, TChipSize, TChipVariant } from "../chips/Chip";
import { HiddenChip, THiddenChipType } from "../chips/HiddenChip";
import { StaticChip } from "../chips/StaticChip";
import { TagChip } from "../chips/TagChip";
import { ResourcesIcon } from "../Icons/ResourcesIcon";
import { RoleIcon } from "../Icons/RoleIcon";
import { Tooltip } from "../Tooltip";
import type { TChipType } from "../MultipleSelect/components/Chips";

export type TChipContainerProps = {
	chips: React.ReactNode[];
	children?: never;
	size?: TChipSize;
	type?: TChipType;
	readonly?: boolean;
	maxChipsToDisplay?: number;
	limitChipType?: THiddenChipType;
	variant?: TChipVariant;
};

export const ChipContainer: FC<TChipContainerProps> = ({
	className,
	chips,
	variant,
	size,
	type,
	readonly,
	maxChipsToDisplay,
	limitChipType
}) => {
	const classes = useStyles();
	const [displayedChipsCount, setDisplayedChipsCount] = useState(0);
	const [doneCalculating, setDoneCalculating] = useState(false);
	const [deferredDone, setDeferredDone] = useState(false);

	const chipsToDisplay = Math.min(maxChipsToDisplay ?? displayedChipsCount, displayedChipsCount);

	const reset = useCallback(() => {
		setDisplayedChipsCount(0);
		setDoneCalculating(false);
	}, []);

	const { ref } = useResizeDetector({ onResize: reset, refreshMode: "debounce", refreshRate: 100 });

	const wrappedChips = useMemo(() => {
		return chips.map(chip => {
			if (typeof chip === "string") {
				switch (type) {
					case "static":
						return (
							<StaticChip key={chip} variant={variant} size={size}>
								{chip}
							</StaticChip>
						);
					case "tag":
						return (
							<TagChip key={chip} size={size} readonly={readonly} variant={variant}>
								{chip}
							</TagChip>
						);
					case "account":
						return (
							<AccountChip key={chip} size={size} readonly={readonly} variant={variant}>
								{chip}
							</AccountChip>
						);
					case "resource":
						return (
							<StaticChip key={chip} variant={variant} size={size} PrefixIcon={ResourcesIcon}>
								{`"${chip}"`}
							</StaticChip>
						);
					case "role":
						return (
							<StaticChip key={chip} variant={variant} size={size} PrefixIcon={RoleIcon}>
								{`"${chip}"`}
							</StaticChip>
						);
					default:
						return (
							<Chip key={chip} readonly={readonly} variant={variant} size={size}>
								{chip}
							</Chip>
						);
				}
			}
			return chip;
		});
	}, [chips, variant, size, type, readonly]);

	const displayedChips = useMemo(() => wrappedChips.slice(0, chipsToDisplay), [chipsToDisplay, wrappedChips]);
	const undisplayedChips = useMemo(() => wrappedChips.slice(chipsToDisplay), [chipsToDisplay, wrappedChips]);

	const tooltipChip = useMemo(() => {
		if (undisplayedChips.length === 0) return null;
		return (
			<Tooltip content={<div className={classes.tooltipContentContainer}>{undisplayedChips.map(chip => chip)}</div>}>
				<HiddenChip type={limitChipType} size={size} variant={variant}>
					{`${undisplayedChips.length}+`}
				</HiddenChip>
			</Tooltip>
		);
	}, [classes.tooltipContentContainer, size, variant, undisplayedChips, limitChipType]);

	useEffect(() => {
		if (chipsToDisplay > chips.length) {
			setDoneCalculating(true);
			return;
		}
		if (doneCalculating) return;
		const currentRef = ref.current;
		if (!currentRef) return;
		if (currentRef.clientWidth >= currentRef.scrollWidth) {
			setDisplayedChipsCount(prev => prev + 1);
		} else {
			setDisplayedChipsCount(prev => prev - 1);
			setDoneCalculating(true);
		}
	}, [chips.length, chipsToDisplay, doneCalculating, ref]);

	useEffect(() => {
		if (maxChipsToDisplay === chipsToDisplay) {
			setDeferredDone(true);
			return;
		}
		if (!doneCalculating) {
			setDeferredDone(false);
			return;
		}

		const timeout = setTimeout(() => setDeferredDone(true), 500);
		return () => clearTimeout(timeout);
	}, [chipsToDisplay, doneCalculating, maxChipsToDisplay]);

	if (chips.length === 0) return null;

	return (
		<div className={classNames(classes.container, { [classes.invisible]: !deferredDone }, className)} ref={ref}>
			{displayedChips}
			{tooltipChip}
		</div>
	);
};
