import { useEffect, useRef, useState } from "react";

const useChangedOnce = (prop: boolean | undefined) => {
	const originalProp = useRef(prop);
	const [changedOnce, setChangedOnce] = useState(false);

	useEffect(() => {
		if (prop !== originalProp?.current) {
			setChangedOnce(true);
		}
	}, [prop]);

	return changedOnce;
};

export default useChangedOnce;
