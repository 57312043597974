import classNames from "classnames";
import { List } from "immutable";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useResizeDetector } from "react-resize-detector";
import { type ListOnItemsRenderedProps, type ListChildComponentProps, FixedSizeList } from "react-window";
import { useDebounceFn } from "hooks/useDebounce";
import { useIsOverflowed } from "hooks/useIsOverflowed";
import { TableHeader, TableRow, COMPACT_ROW_HEIGHT_PX, DEFAULT_ROW_HEIGHT_PX } from "./components";
import { CheckboxCellContent } from "./components/CellContents/CheckboxCellContent";
import { useStyles } from "./styles";
import { VirtualTableProvider } from "./virtualTableContext";
import { Typography } from "../Typography";
import type { TColumn } from "./types";

interface ICheckboxSelectable<T extends object> {
	excludedItems?: T[];
	getIsEqual?: (prevRow: T, nextRow: T) => boolean;
	isAllSelected?: boolean;
	isLoading?: boolean;
	isSelected?: never;
	selectAll?: () => void;
	selectable: true;
	selectableTrigger: "checkbox";
	selectedItems?: T[];
	selectedRow?: T;
	toggleRowSelection?: (row: T) => void;
}

interface IManualSelectable<T extends object> {
	excludedItems?: never;
	getIsEqual?: (prevRow: T, nextRow: T) => boolean;
	isAllSelected?: never;
	isLoading?: boolean;
	isSelected?: (row: T) => boolean;
	selectAll?: never;
	selectable: true;
	selectableTrigger: "manual";
	selectedItems?: never;
	selectedRow?: T;
	toggleRowSelection?: never;
}

interface INotSelectable {
	excludedItems?: never;
	getIsEqual?: never;
	isAllSelected?: never;
	isLoading?: boolean;
	isSelected?: never;
	selectAll?: never;
	selectable?: false;
	selectableTrigger?: never;
	selectedItems?: never;
	selectedRow?: never;
	toggleRowSelection?: never;
}

export type TTableSelectionProps<T extends object> = ICheckboxSelectable<T> | IManualSelectable<T> | INotSelectable;

interface ITableProps<T extends object> {
	compact?: boolean;
	columns: TColumn<T>[];
	emptyTableMessage?: string;
	fetchRow?: (rowIndex: number) => Promise<void> | void;
	onRowClicked?: (row: T) => void;
	onRowKeyDown?: (row: T) => void;
	shouldDisableRow?: (row: T) => boolean;
	isRowHoverable?: (row: T) => boolean;
	overscan?: number;
	rows: (T | undefined)[];
	totalRows: number;
	resizeable?: boolean;
	limitedHeight?: boolean;
}

type TTableProps<T extends object> = ITableProps<T> & TTableSelectionProps<T>;

export const VirtualTable = <T extends object>({
	className,
	columns,
	compact = false,
	emptyTableMessage,
	excludedItems,
	fetchRow,
	getIsEqual,
	isAllSelected,
	isLoading = false,
	isRowHoverable,
	isSelected,
	limitedHeight = false,
	onRowClicked,
	overscan,
	resizeable = false,
	rows,
	selectAll,
	selectable = false,
	selectableTrigger,
	selectedItems,
	selectedRow,
	shouldDisableRow,
	toggleRowSelection: propToggleRowSelection,
	totalRows
}: TProps<TTableProps<T>>) => {
	const { t } = useTranslation("translation", { keyPrefix: "table" });
	const classes = useStyles();
	const rowHeight = compact ? COMPACT_ROW_HEIGHT_PX : DEFAULT_ROW_HEIGHT_PX;

	const listRef = useRef<FixedSizeList>(null);
	const listScrollElementRef = useRef<HTMLDivElement>(null);
	const headerRef = useRef<HTMLDivElement>(null);
	const requestedRowsRef = useRef(new Set<number>());

	const { overflowedY: hasVerticalScroll } = useIsOverflowed(listScrollElementRef);
	const { ref: bodyRef, width: bodyWidth, height: bodyHeight } = useResizeDetector<HTMLDivElement>();

	const getIsSelected = useCallback(
		(row: T) => {
			if (selectableTrigger === "checkbox") {
				if (isAllSelected) {
					if (getIsEqual) {
						return !excludedItems?.find(excludedRow => getIsEqual(excludedRow, row));
					}
					return !excludedItems?.includes(row);
				}
				if (getIsEqual) {
					return !!selectedItems?.find(selectedRow => getIsEqual(selectedRow, row));
				}
				return !!selectedItems?.includes(row);
			} else if (selectableTrigger === "manual") {
				return isSelected?.(row) || false;
			}
			return false;
		},
		[excludedItems, getIsEqual, isAllSelected, isSelected, selectableTrigger, selectedItems]
	);

	useEffect(() => {
		if (selectedRow) {
			listRef.current?.scrollToItem(rows.indexOf(selectedRow), "smart");
		}
	}, [rows, selectedRow]);

	const toggleRowSelection = useCallback(
		(e: React.MouseEvent, row: T) => {
			if (propToggleRowSelection) {
				const isSelectingRow = !getIsSelected(row);
				propToggleRowSelection(row);
				if (isSelectingRow) {
					const currentlySelectedLength = selectedItems?.length || 0;
					const isSelectingLastUnselected = !isAllSelected && currentlySelectedLength + 1 === totalRows;
					if (isSelectingLastUnselected) {
						selectAll?.();
					}
				} else {
					const currentlyExcludedLength = excludedItems?.length || 0;
					const isExcludingLastUnexcluded = isAllSelected && currentlyExcludedLength + 1 === totalRows;
					if (isExcludingLastUnexcluded) {
						selectAll?.();
					}
				}
			}
		},
		[
			excludedItems?.length,
			getIsSelected,
			isAllSelected,
			propToggleRowSelection,
			selectAll,
			selectedItems?.length,
			totalRows
		]
	);

	const tableColumns = useMemo(() => {
		if (selectable && selectableTrigger === "checkbox") {
			const anySelectedOrExcluded = Boolean(selectedItems?.length || excludedItems?.length);
			return [
				{
					key: "selectable",
					width: "min-content",
					header: (
						<CheckboxCellContent
							allSelected={isAllSelected && !anySelectedOrExcluded}
							partialSelected={anySelectedOrExcluded}
							onClick={selectAll!}
						/>
					),
					renderCell: (row: T, { disabled }: { disabled: boolean }) => (
						<CheckboxCellContent
							disabled={disabled}
							value={row}
							selected={getIsSelected(row)}
							onClick={toggleRowSelection}
						/>
					)
				} as TColumn<T>,
				...columns
			];
		}
		return columns;
	}, [
		selectable,
		selectableTrigger,
		columns,
		selectedItems?.length,
		excludedItems?.length,
		isAllSelected,
		selectAll,
		getIsSelected,
		toggleRowSelection
	]);

	const columnsWidthOptions = useMemo(() => List(tableColumns.map(({ width }) => width)), [tableColumns]);

	useEffect(() => {
		requestedRowsRef.current.clear();
	}, [fetchRow]);

	const handleFetchRow = useCallback(
		(rowIndex: number) => {
			if (rows.at(rowIndex) || !fetchRow) return;
			if (requestedRowsRef.current.has(rowIndex)) return;

			requestedRowsRef.current.add(rowIndex);
			void fetchRow(rowIndex);
		},
		[fetchRow, rows]
	);

	const handleItemsRendered = useCallback(
		({ overscanStopIndex, overscanStartIndex }: ListOnItemsRenderedProps) => {
			for (let i = overscanStartIndex; i <= overscanStopIndex; i++) {
				handleFetchRow(i);
			}
		},
		[handleFetchRow]
	);
	const debouncedHandleItemsRendered = useDebounceFn(handleItemsRendered, 50);

	useEffect(() => {
		const listScrollElement = listScrollElementRef.current;
		if (listScrollElement) {
			const syncScroll = () => {
				if (headerRef.current) {
					headerRef.current.scrollLeft = listScrollElement.scrollLeft;
				}
			};
			listScrollElement.addEventListener("scroll", syncScroll);
			return () => listScrollElement.removeEventListener("scroll", syncScroll);
		}
		return;
	}, [bodyWidth, rows]);

	const rowRender = useCallback(
		({ index, style }: ListChildComponentProps<T>) => {
			const row = rows.at(index);
			const selected = row ? getIsSelected(row) : false;
			const hoverable = selectable && row ? (isRowHoverable?.(row) ?? true) : false;

			return (
				<TableRow
					columns={tableColumns}
					defaultRowHeight={rowHeight}
					index={index}
					onRowClicked={onRowClicked}
					row={rows.at(index)}
					selected={selected}
					shouldDisableRow={shouldDisableRow}
					shouldHighlightRowOnHover={hoverable}
					style={style}
				/>
			);
		},
		[rows, tableColumns, onRowClicked, shouldDisableRow, selectable, rowHeight, getIsSelected, isRowHoverable]
	);

	const itemCount = totalRows || overscan || 5;

	return (
		<VirtualTableProvider
			selectable={selectable}
			rowHeight={rowHeight}
			compact={compact}
			resizeable={resizeable}
			columnsWidthOptions={columnsWidthOptions}>
			<div className={classNames(classes.table, { [classes.limitedHeight]: limitedHeight }, className)}>
				<TableHeader columns={tableColumns} hasVerticalScroll={hasVerticalScroll} headerRef={headerRef} />
				<div className={classes.body} ref={bodyRef}>
					{totalRows === 0 && !isLoading ? (
						<Typography variant="body_reg" className={classes.emptyTableMessage}>
							{emptyTableMessage || t("noResults")}
						</Typography>
					) : (
						<FixedSizeList
							height={bodyHeight ?? 0}
							width={bodyWidth ?? 0}
							itemCount={itemCount}
							ref={listRef}
							itemSize={rowHeight}
							overscanCount={overscan ?? 5}
							onItemsRendered={debouncedHandleItemsRendered}
							outerRef={listScrollElementRef}>
							{rowRender}
						</FixedSizeList>
					)}
				</div>
			</div>
		</VirtualTableProvider>
	);
};

type TPaginatedVirtualTableProps<T extends object> = Omit<TProps<ITableProps<T>>, "fetchRow"> & {
	perPage: number;
	fetchPage: (page: number) => void | Promise<void>;
} & TTableSelectionProps<T>;

export const PaginatedVirtualTable = <T extends { id: string }>({
	perPage,
	fetchPage,
	...virtualTableProps
}: TPaginatedVirtualTableProps<T>) => {
	const requestedPagesRef = useRef<Set<number>>(new Set());

	useEffect(() => {
		requestedPagesRef.current.clear();
	}, [fetchPage, perPage]);

	const fetchRow = useCallback(
		(rowIndex: number) => {
			const page = Math.ceil((rowIndex + 1) / perPage);
			if (requestedPagesRef.current?.has(page)) return;
			requestedPagesRef.current?.add(page);
			void fetchPage(page);
		},
		[fetchPage, perPage]
	);

	return <VirtualTable fetchRow={fetchRow} overscan={Math.round(perPage / 2)} {...virtualTableProps} />;
};
