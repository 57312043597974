import constate from "constate";
import { useCallback, useEffect, useState } from "react";
import { io as webSocketIoClient } from "socket.io-client";
import { devLog } from "utils/devtools/devLogging";

const webSocketClient = webSocketIoClient("/", {
	transports: ["websocket"],
	autoConnect: false,
	path: "/ws"
});

const useLiveUpdates = () => {
	const [isConnected, setIsConnected] = useState(false);
	const joinRoom = useCallback((room: string, value?: unknown) => {
		if (webSocketClient.connected) {
			devLog({ message: `Joining room: ${room}`, level: "debug" });
			webSocketClient.emit(room, value);
		}
	}, []);

	useEffect(() => {
		if (!webSocketClient.connected) {
			webSocketClient.connect();
			webSocketClient.on("connect", () => {
				devLog({ message: "Connected to live updates server", level: "debug" });
				setIsConnected(true);
			});

			webSocketClient.on("disconnect", () => {
				devLog({ message: "Disconnected from live updates server", level: "debug" });
				setIsConnected(false);
			});
		}
		return () => {
			webSocketClient.off("connect");
			webSocketClient.off("disconnect");
			webSocketClient.disconnect();
			setIsConnected(false);
		};
	}, []);

	return { socket: webSocketClient, joinRoom, isConnected };
};

export const [LiveUpdatesProvider, useLiveUpdatesContext] = constate(useLiveUpdates);
