import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	content: {
		justifyContent: "flex-start",
		alignItems: "flex-start",
		alignSelf: "stretch",
		display: "flex",
		flexWrap: "wrap",
		gap: "var(--spacing-x3, 12px)"
	}
});
