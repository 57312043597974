import { createUseStyles } from "react-jss";

const MAX_WIDTH = 276;

export const useStyles = createUseStyles({
	directoryNameWithEmail: {
		display: "flex",
		flexDirection: "column",
		minWidth: 0,
		justifyContent: "center",
		alignItems: "flex-start",
		"& > div": {
			whiteSpace: "nowrap",
			overflow: "hidden",
			textOverflow: "ellipsis",
			maxWidth: "100%"
		}
	},
	icon: {
		fontSize: "32px",
		height: "100%",
		width: "32px"
	},
	cardContainer: {
		alignSelf: "auto",
		width: "220px"
	},
	groupTooltipTitle: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		gap: "var(--spacing-x2, 8px)"
	},
	directoryGroupTooltipTitle: {
		maxWidth: `calc(${MAX_WIDTH}px - var(--spacing-x6, 24px) * 2 - var(--spacing-x2, 8px))`
	},
	directoryGroupTooltipTitleTop: {
		display: "flex",
		flexDirection: "row",
		gap: "var(--spacing-x2, 8px)",
		alignItems: "center",
		justifyContent: "flex-start"
	},
	approversListContainer: {
		display: "flex",
		flexDirection: "column",
		gap: "var(--spacing-x2, 8px)",
		justifyContent: "flex-start",
		alignItems: "flex-start",
		maxHeight: "316px",
		overflowY: "auto"
	},
	disabled: {
		opacity: 0.4
	},
	webhookChip: {
		"&$approved": {
			background: "var(--color-green-400)",
			color: "var(--color-white)"
		},
		"&$declined": {
			background: "var(--color-red-700)",
			color: "var(--color-white)"
		}
	},
	approved: {},
	declined: {}
});
