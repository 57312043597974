import { createUseStyles } from "react-jss";
import * as GlobalStylesVariables from "globalStylesVariables";

export const useGlobalStyles = createUseStyles({
	"@global": {
		body: {
			"--direction": "ltr",
			"--rtl-inverted": 1,
			backgroundColor: "var(--surface-page-contrast-background-color)",
			color: "var(--typography-primary-font-color)",
			direction: "var(--direction)",
			fontFamily: "Poppins, sans-serif",
			fontSize: "1rem",
			height: "100%",
			margin: 0,
			textRendering: "auto",
			width: "100%"
		},

		"body.rtl": {
			"--direction": "rtl",
			"--rtl-inverted": -1
		},

		"*": {
			boxSizing: "border-box"
		},

		html: {
			backgroundColor: "var(--surface-page-contrast-background-color)",
			fontSize: "14px",
			height: "100%",
			margin: 0,
			overflowX: "hidden",
			touchAction: "manipulation",
			width: "100%",

			/* New (UX Refresh) Design System */
			/* Colors */
			/* Purple */
			"--color-purple-50": "rgba(251, 249, 251, 1)",
			"--color-purple-100": "rgba(247, 244, 248, 1)",
			"--color-purple-200": "rgba(239, 232, 242, 1)",
			"--color-purple-300": "rgba(223, 210, 228, 1)",
			"--color-purple-400": "rgba(166, 134, 180, 1)",
			"--color-purple-450": "rgba(191, 165, 201, 1)",
			"--color-purple-500": "rgba(124, 91, 138, 1)",
			"--color-purple-600": "rgba(94, 29, 121, 1)",
			"--color-purple-700": "rgba(71, 36, 86, 1)",
			/* Greyscale */
			"--color-black": "rgba(39, 41, 49, 1)",
			"--color-grey-100": "rgba(252, 252, 252, 1)",
			"--color-grey-200": "rgba(249, 248, 249, 1)",
			"--color-grey-300": "rgba(243, 241, 243, 1)",
			"--color-grey-400": "rgba(224, 220, 226, 1)",
			"--color-grey-450": "rgba(205, 206, 208, 1)",
			"--color-grey-500": "rgba(178, 180, 184, 1)",
			"--color-grey-600": "rgba(131, 133, 137, 1)",
			"--color-grey-700": "rgba(95, 96, 102, 1)",
			"--color-white": "rgba(255, 255, 255, 1)",
			/* Blue */
			"--color-blue-100": "rgba(248, 250, 255, 1)",
			"--color-blue-200": "rgba(239, 245, 255, 1)",
			"--color-blue-300": "rgba(224, 236, 255, 1)",
			"--color-blue-400": "rgba(145, 182, 243, 1)",
			"--color-blue-500": "rgba(66, 133, 244, 1)",
			/* Red */
			"--color-red-100": "rgba(255, 249, 238, 1)",
			"--color-red-200": "rgba(255, 248, 244, 1)",
			"--color-red-300": "rgba(254, 241, 238, 1)",
			"--color-red-400": "rgba(252, 222, 216, 1)",
			"--color-red-500": "rgba(255, 190, 163, 1)",
			"--color-red-600": "rgba(247, 135, 100, 1)",
			"--color-red-700": "rgba(229, 98, 56, 1)",
			"--color-red-800": "rgba(215, 80, 37, 1)",
			"--color-red-900": "rgba(197, 66, 24, 1)",
			/* Green */
			"--color-green-100": "rgba(243, 255, 238, 1)",
			"--color-green-200": "rgba(228, 248, 219, 1)",
			"--color-green-250": "rgba(167, 214, 159, 1)",
			"--color-green-300": "rgba(112, 181, 79, 1)",
			"--color-green-400": "rgba(35, 153, 16, 1)",
			"--color-green-500": "rgba(33, 142, 15, 1)",
			"--color-green-600": "rgba(27, 117, 13, 1)",
			"--color-green-700": "rgba(24, 98, 12, 1)",
			/* Magenta */
			"--color-magenta-200": "rgba(250, 197, 255, 1)",
			"--color-magenta-300": "rgba(238, 129, 248, 1)",
			"--color-magenta-500": "rgba(173, 48, 184, 1)",
			"--color-magenta-700": "rgba(153, 40, 163, 1)",
			/* Orange */
			"--color-orange-500": "rgba(236, 167, 75, 1)",

			/* Spacing */
			"--spacing-x0": "0px",
			"--spacing-x05": `${GlobalStylesVariables.SPACING_X1 * 0.5}px`,
			"--spacing-x1": `${GlobalStylesVariables.SPACING_X1}px`,
			"--spacing-x2": `${GlobalStylesVariables.SPACING_X1 * 2}px`,
			"--spacing-x3": `${GlobalStylesVariables.SPACING_X1 * 3}px`,
			"--spacing-x4": `${GlobalStylesVariables.SPACING_X1 * 4}px`,
			"--spacing-x5": `${GlobalStylesVariables.SPACING_X1 * 5}px`,
			"--spacing-x6": `${GlobalStylesVariables.SPACING_X1 * 6}px`,
			"--spacing-x7": `${GlobalStylesVariables.SPACING_X1 * 7}px`,
			"--spacing-x8": `${GlobalStylesVariables.SPACING_X1 * 8}px`,
			"--spacing-x9": `${GlobalStylesVariables.SPACING_X1 * 9}px`,
			"--spacing-x10": `${GlobalStylesVariables.SPACING_X1 * 10}px`,
			"--spacing-x11": `${GlobalStylesVariables.SPACING_X1 * 11}px`,
			"--spacing-x12": `${GlobalStylesVariables.SPACING_X1 * 12}px`,
			"--spacing-x13": `${GlobalStylesVariables.SPACING_X1 * 13}px`,
			"--spacing-x14": `${GlobalStylesVariables.SPACING_X1 * 14}px`,

			/* Typography */
			"--typography-font-weight-light": "300",
			"--typography-font-weight-regular": "400",
			"--typography-font-weight-medium": "500",
			"--typography-font-weight-semibold": "600",
			"--typography-font-weight-bold": "700",

			/* with assuming REM is 16px */
			"--typography-font-size-tiny": "0.625rem",
			"--typography-font-size-small": "0.75rem",
			"--typography-font-size-text": "0.875rem",
			"--typography-font-size-body": "1rem",
			"--typography-font-size-title": "1.25rem",
			"--typography-font-size-h2": "1.5rem",
			"--typography-font-size-h1": "1.875rem",
			"--typography-font-size-header": "2rem",
			/* with relative units */
			"--typography-font-size-relative-tiny": "0.625em",
			"--typography-font-size-relative-small": "0.75em",
			"--typography-font-size-relative-text": "0.875em",
			"--typography-font-size-relative-body": "1em",
			"--typography-font-size-relative-title": "1.25em",
			"--typography-font-size-relative-h2": "1.5em",
			"--typography-font-size-relative-h1": "1.875em",
			"--typography-font-size-relative-header": "2em",

			/* Table */
			"--table-cell-border-color": "var(--color-grey-600)",
			"--table-even-row-background-color": "var(--color-purple-50)",
			"--table-hover-row-background-color": "var(--color-purple-100)",
			"--table-selected-row-background-color": "var(--color-purple-200)",

			/* Button */
			"--button-primary-color": "var(--color-white)",
			"--button-primary-background-color-regular": "var(--color-purple-600)",
			"--button-primary-background-color-hover": "var(--color-purple-700)",
			"--button-primary-background-color-pressed": "var(--color-purple-600)",
			"--button-primary-background-color-disabled": "var(--color-purple-400)",
			"--button-secondary-color": "var(--color-purple-600)",
			"--button-secondary-color-disabled": "var(--color-purple-400)",
			"--button-secondary-background-color-regular": "transparent",
			"--button-secondary-background-color-hover": "var(--color-purple-200)",
			"--button-secondary-background-color-pressed": "transparent",
			"--button-secondary-background-color-disabled": "transparent",
			"--button-secondary-border-color": "var(--color-purple-600)",
			"--button-secondary-border-color-disabled": "var(--color-purple-400)",
			"--button-text-color": "var(--color-purple-600)",
			"--button-text-color-disabled": "var(--color-purple-400)",
			"--button-text-background-color-regular": "transparent",
			"--button-text-background-color-hover": "var(--color-purple-200)",
			"--button-text-background-color-pressed": "transparent",
			"--button-text-background-color-disabled": "transparent",
			/* Icon Button */
			"--icon-button-primary-color": "var(--color-black)",
			"--icon-button-primary-color-disabled": "var(--color-grey-500)",
			"--icon-button-primary-background-color-regular": "transparent",
			"--icon-button-primary-background-color-hover": "var(--color-purple-200)",
			"--icon-button-primary-background-color-pressed": "var(--color-purple-300)",
			"--icon-button-primary-background-color-disabled": "transparent",
			"--icon-button-secondary-color": "var(--color-black)",
			"--icon-button-secondary-color-disabled": "var(--color-grey-500)",
			"--icon-button-secondary-background-color-regular": "var(--color-white)",
			"--icon-button-secondary-background-color-hover": "var(--color-purple-200)",
			"--icon-button-secondary-background-color-pressed": "var(--color-purple-300)",
			"--icon-button-secondary-background-color-disabled": "var(--color-white)",
			"--icon-button-secondary-border-color": "var(--color-purple-400)",
			"--icon-button-reverse-color": "var(--color-white)",
			"--icon-button-reverse-color-disabled": "var(--color-grey-500)",
			"--icon-button-reverse-background-color-regular": "transparent",
			"--icon-button-reverse-background-color-hover": "rgba(255, 255, 255, 0.30)",
			"--icon-button-reverse-background-color-pressed": "rgba(255, 255, 255, 0.50)",
			"--icon-button-reverse-background-color-disabled": "transparent",
			/* Dropdown Button */
			"--dropdown-button-primary-color": "var(--color-white)",
			"--dropdown-button-primary-color-pressed": "var(--color-purple-600)",
			"--dropdown-button-primary-background-color-regular": "var(--color-purple-600)",
			"--dropdown-button-primary-background-color-hover": "var(--color-purple-700)",
			"--dropdown-button-primary-background-color-pressed": "transparent",
			"--dropdown-button-primary-background-color-disabled": "var(--color-purple-400)",
			"--dropdown-button-primary-border-color-pressed": "var(--color-purple-600)",
			"--dropdown-button-secondary-color": "var(--color-purple-600)",
			"--dropdown-button-secondary-color-disabled": "var(--color-purple-400)",
			"--dropdown-button-secondary-color-pressed": "var(--color-white)",
			"--dropdown-button-secondary-background-color-regular": "transparent",
			"--dropdown-button-secondary-background-color-hover": "var(--color-purple-200)",
			"--dropdown-button-secondary-background-color-pressed": "var(--color-purple-600)",
			"--dropdown-button-secondary-background-color-disabled": "transparent",
			"--dropdown-button-secondary-border-color": "var(--color-purple-600)",
			"--dropdown-button-secondary-border-color-disabled": "var(--color-purple-400)",
			// MVP does not support text buttons
			// "--dropdown-button-text-color": "var(--color-purple-600)",
			// "--dropdown-button-text-color-disabled": "var(--color-purple-400)",
			// "--dropdown-button-text-background-color-regular": "transparent",
			// "--dropdown-button-text-background-color-hover": "var(--color-purple-200)",
			// "--dropdown-button-text-background-color-pressed": "transparent",
			// "--dropdown-button-text-background-color-disabled": "transparent",

			/* Nav */
			"--nav-background-color": "var(--color-black)",
			"--nav-text-color": "var(--color-white)",
			/* Side Nav */
			"--side-nav-width": `${GlobalStylesVariables.SIDE_NAV_WIDTH_PX}px`,
			"--side-nav-width-collapsed": `${GlobalStylesVariables.SIDE_NAV_WIDTH_COLLAPSED_PX}px`,
			/* Modal (popup) */
			"--modal-background-color": "rgba(39, 41, 49, 0.5)",
			"--modal-max-height": "min(95vh, 90vw)",
			/* Shadows */
			"--shadow-bottom": "0px 8px 7px 7px rgba(0, 0, 0, 0.20)",
			/* Chip */
			"--chip-background-color": "var(--color-purple-200)",
			"--chip-background-color-disabled": "var(--color-grey-300)",
			"--chip-background-color-hover": "var(--color-purple-300)",
			"--chip-border-color-selected": "var(--color-purple-600)",
			"--chip-text-color": "var(--color-purple-600)",
			"--chip-text-color-disabled": "var(--color-grey-500)",
			/* ------------------------------------------ /*
	
	/* Old (Dana) Design System  */
			"--primary-color": "#c62828",
			"--primary-color-faint": "rgba(198, 40, 40, 0.14)",
			"--text-color": "#444444",
			"--accordion-background-color": "#fff",
			"--modal-red-box-shadow":
				"0 1rem 3rem 0px rgba(239, 83, 80, 0.16), 0 3rem 14rem 0 rgba(239, 83, 80, 0.32), 0 6rem 64rem -12rem rgba(239, 83, 80, 0.52)",
			"--button-default-background-color": "rgba(68, 68, 68, 0.12)",
			"--dimmed-error-background": "#e9000029",
			"--block-border": "1px solid #d6ddea",
			"--block-background-color": "#ffffff",
			"--block-blur-background-color": "rgba(255, 255, 255, 0.72)",
			"--block-box-shadow": "0 1rem 2rem 0rem rgba(198, 40, 40, 0.04), 0 1rem 4rem 0rem rgba(198, 40, 40, 0.02)",
			"--checkbox-border-color": "#d8deea",
			"--list-icon-color": "#899bc1",
			"--profile-block-background-color": "rgba(68, 68, 68, 1)",
			"--transition-easing": "cubic-bezier(0.4, 0, 0.2, 1)",
			"--transition-easing-reversed": "cubic-bezier(0.8, 0, 0.6, 1)",
			/* Figma Old Design System */
			/* Pallette */
			"--color-blue-light": "rgb(8, 89, 131)",

			/* Blue */
			"--color-blue-dark": "rgba(0, 76, 178, 1)",
			"--color-blue": "rgba(7, 101, 229, 1)",
			"--color-blue-transparent": "rgba(7, 101, 229, 0.6)",
			"--color-blue-10": "rgba(226, 245, 255, 1)",
			"--color-blue-5": "rgba(238, 249, 255, 1)",
			"--color-blue-underline": "rgba(0, 208, 255, 1)",
			/* White, Grey, Black */
			"--color-menu-background": "rgba(37, 24, 42, 1)",
			"--color-menu-selected": "rgba(5, 70, 103, 1)",
			"--color-grey-dark": "rgba(95, 96, 102, 1)",
			"--color-grey": "rgba(182, 188, 199, 1)",
			"--color-grey-disabled": "rgba(178, 180, 184, 1)",
			"--color-grey-light": "rgba(214, 221, 234, 1)",
			"--color-grey-background": "rgba(238, 243, 251, 1)",
			"--color-almost-white": "rgba(250, 251, 254, 1)",
			"--color-white-dimmer": "rgba(255, 255, 255, 0.4)",
			"--color-transparent": "rgba(0, 0, 0, 0)",
			"--color-shadow-transparent": "rgba(0, 0, 0, 0.1)",
			/* Red */
			"--color-red-dark": "rgba(221, 84, 16, 1)",
			"--color-red": "rgba(252, 109, 38, 1)",
			"--color-red-10": "rgba(255, 240, 233, 1)",
			"--color-red-5": "rgba(255, 248, 244, 1)",
			"--color-red-transparent": "rgba(255, 0, 0, 0.12)",
			/* Green */
			"--color-green-dark": "rgba(0, 172, 72, 1)",
			"--color-green": "rgba(34, 227, 114, 1)",
			"--color-green-10": "rgba(233, 252, 241, 1)",
			"--color-green-5": "rgba(244, 254, 248, 1)",
			/* Yellow */
			"--color-yellow": "rgba(254, 195, 82, 1)",
			"--color-yellow-10": "rgba(255, 249, 238, 1)",
			/* Purple */
			"--color-purple-dark": "rgba(67, 44, 75, 1)",
			"--color-purple": "rgba(140, 112, 153, 1)",
			"--color-purple-transparent": "rgba(140, 112, 153, 0.6)",
			"--color-purple-text": "rgba(88, 63, 103, 1)",
			"--color-purple-10": "rgba(230, 230, 255, 1)",
			"--color-purple-5": "rgba(240, 240, 255, 1)",
			"--color-purple-underline": "rgba(178, 160, 187, 1)",
			"--color-purple-pastel": "rgba(189, 137, 226, 1)",
			"--color-pink-background": "rgba(252, 222, 216, 1)",

			/* Orange */
			"--color-orange": "rgba(236, 167, 75, 1)",

			/* -> General */
			"--border-radius-round": "50%",
			"--border-radius-semi-rounded": "8px",
			"--border-radius-sharp": "4px",
			"--border-radius-rounded": "12px",
			"--border-radius-rounder": "24px",
			"--border-color": "rgb(218, 229, 246)",
			/* -> Surfaces */
			/* ---> Colors */
			"--surface-content-background-color": "var(--color-white)",
			"--surface-page-contrast-background-color": "var(--color-grey-background)",
			"--surface-header-background-color": "var(--color-white)",
			"--surface-contrast-background-color": "var(--color-menu-background)",
			"--surface-content-contrast-background-color": "var(--color-grey-background)",
			"--surface-block-background-color": "var(--color-white)",
			"--surface-dimmer-background-color": "var(--color-white-dimmer)",
			/* ---> Borders */
			"--surface-block-border": "1px solid var(--color-grey-light)",
			/* ---> Box Shadow */
			"--surface-block-box-shadow": "0 2px 2px rgba(0, 0, 0, 0.06)",
			"--surface-popup-box-shadow": "0px 0px 36px rgba(15, 62, 125, 0.2)",
			/* -> Buttons */
			/* ---> Colors */
			"--button-disabled-background-color": "var(--color-grey-background)",
			"--button-disabled-font-color": "var(--color-grey-disabled)",
			"--button-primary-standard-background-color": "var(--color-purple)",
			"--button-primary-hover-background-color": "rgba(126, 101, 138, 1)",
			"--button-primary-pressed-background-color": "rgba(37, 24, 42, 1)",
			"--button-primary-font-color": "var(--color-white)",
			"--button-secondary-standard-background-color": "var(--color-transparent)",
			"--button-secondary-hover-background-color": "var(--color-purple-10)",
			"--button-secondary-pressed-background-color": "var(--color-purple-10)",
			"--button-secondary-font-color": "var(--color-purple-text)",
			"--button-negative-standard-background-color": "var(--color-transparent)",
			"--button-negative-hover-background-color": "var(--color-red-transparent)",
			"--button-negative-pressed-background-color": "var(--color-red-transparent)",
			"--button-negative-font-color": "var(--primary-color)",
			/* ---> Borders */
			"--button-disabled-border": "1px solid var(--button-disabled-background-color)",
			"--button-primary-border-color": "var(--button-primary-standard-background-color)",
			"--button-primary-hover-border-color": "var(--button-primary-hover-background-color)",
			"--button-primary-pressed-border-color": "var(--button-primary-pressed-background-color)",
			"--button-secondary-border": "1px solid var(--color-grey-light)",
			"--button-border-radius": "var(--border-radius-rounder)",
			/* ---> Sizes */
			"--button-large-min-height": "2.5rem",
			"--button-large-font-size": "1rem",
			"--button-medium-min-height": "2.1rem",
			"--button-medium-font-size": "0.92rem",
			"--button-small-min-height": "1.57rem",
			"--button-small-font-size": "0.78rem",
			/* ---> Margins & Paddings */
			"--button-large-padding": "0.5rem 1rem",
			"--button-medium-padding": "0.375rem 0.75rem",
			"--button-small-padding": "0.25rem 0.5rem",
			/* -> Navigation Tabs */
			/* ---> Colors */
			"--navigation-tab-inactive-hover-background-color": "rgba(88, 63, 103, 1)",
			"--navigation-tab-font-color": "var(--color-white)",
			/* ---> Sizes */
			/* -> Navigation Bar */
			/* ---> Colors */
			"--navigation-bar-background-color": "var(--color-menu-background)",
			"--navigation-bar-font-color": "var(--color-white)",
			/* ---> Borders */
			/* ---> Sizes */
			/* ---> Margins & Paddings */
			"--navigation-bar-padding": "0.72rem",
			/* -> ToggleButton */
			/* ---> Colors */
			"--toggle-button-inactive-background-color": "var(--color-grey-background)",
			"--toggle-button-active-background-color": "var(--color-white)",
			"--toggle-button-inactive-hover-background-color": "var(--color-grey-light)",
			"--toggle-button-active-hover-background-color": "var(--color-purple-10)",
			/* ---> Box Shadow */
			"--toggle-button-inactive-first-box-shadow": "inset -0.25rem 0 0.25rem rgba(0, 0, 0, 0.06)",
			"--toggle-button-inactive-last-box-shadow": "inset 0.25rem 0 0.25rem rgba(0, 0, 0, 0.06)",
			/* -> Card List */
			/* ---> Colors */
			/* ---> Borders */
			"--card-list-border": "1px solid var(--color-grey-light)",
			/* ---> Sizes */
			/* ---> Margins & Paddings */
			/* -> Table */
			/* ---> Colors */
			"--table-card-background-color": "var(--color-white)",
			"--table-card-hover-background-color": "var(--color-almost-white)",
			/* ---> Margins & Paddings */
			"--table-card-padding": "1rem 0.85rem",
			/* ---> Sizes */
			"--table-card-min-height": "2.575rem",
			/* ---> Box Shadow */
			"--table-card-box-shadow": "1px 1px 0px #e5e5e5",
			/* -> Tooltip */
			/* ---> Colors */
			"--tooltip-background-color": "var(--surface-content-background-color)",
			/* ---> Borders */
			"--tooltip-border": "1px solid var(--color-grey-background)",
			/* ---> Sizes */
			"--tooltip-min-height": "1.3rem",
			"--tooltip-min-width": "1.3rem",
			"--tooltip-max-width": "25rem",
			/* ---> Margins & Paddings */
			"--tooltip-padding": "0.5rem 0.85rem",
			/* ---> Box Shadow */
			"--tooltip-box-shadow": "0px 0px 1.42rem rgba(0, 0, 0, 0.12)",
			/* -> Typography */
			/* ---> Colors */
			"--typography-primary-font-color": "var(--color-black)",
			"--typography-secondary-font-color": "var(--color-white)",
			"--typography-link-font-color": "var(--color-blue)",
			/* ---> Borders */
			/* ---> Sizes */
			"--typography-standard-font-size": "1rem",
			"--typography-small-font-size": "0.857rem",
			"--typography-tiny-font-size": "0.71rem",
			"--typography-h1-font-size": "2.14rem",
			"--typography-h2-font-size": "1.715rem",
			"--typography-h3-font-size": "1.14rem",
			"--typography-section-title-font-size": "1.14rem",

			"--typography-relative-standard-font-size": "1em",
			"--typography-relative-small-font-size": "0.857em",
			"--typography-relative-tiny-font-size": "0.71em",
			"--typography-relative-h1-font-size": "2.14em",
			"--typography-relative-h2-font-size": "1.715em",
			"--typography-relative-h3-font-size": "1.14em",
			"--typography-relative-section-title-font-size": "1.14em",

			/* -> Icons */
			/* ---> Colors */
			"--icon-fill-color": "var(--color-black)",
			/* ---> Borders */
			"--icon-logo-border": "var(--color-grey-background)",
			"--icon-logo-border-radius": "var(--border-radius-semi-rounded)",
			/* ---> Sizes */
			"--icon-small-size": "0.857em",
			"--icon-medium-size": "1.14em",
			"--icon-large-size": "1.25em",
			"--icon-xl-size": "1.5em"
		},

		"@media (prefers-color-scheme: dark)": {},

		"#app": {
			height: "100%",
			width: "100%"
		},

		/* These webkit selectors work in most browsers, but not in Firefox */

		"::-webkit-scrollbar": {
			width: `${GlobalStylesVariables.SCROLLBAR_SIZE_PX}px`,
			height: `${GlobalStylesVariables.SCROLLBAR_SIZE_PX}px`
		},

		"::-webkit-scrollbar-thumb": {
			background: "transparent",
			borderRadius: "30px"
		},

		"::-webkit-scrollbar-thumb:hover": {
			background: "#9e9b9e"
		},

		"::-webkit-scrollbar-track": {
			background: "transparent",
			borderRadius: "30px",
			boxShadow: "inset 0px 0px 0px 0px #f0f0f0"
		},

		"div:hover": {
			"&::-webkit-scrollbar-thumb": {
				background: "var(--color-purple-400)"
			}
		},

		"input, textarea, select, button, meter, progress": {
			fontFamily: "inherit",
			fontSize: "1rem"
		}
	}
});
