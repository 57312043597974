import constate from "constate";
import { useCallback, useState, useMemo } from "react";
import { BP } from "utils/ui/breakpoints";
import { useSystemOverlays } from "./overlaysContext";

const MAX_FIXED_WIDTH_PX = BP.EIGHTY;

const calculateMaxWidth = () => {
	const maxWidth = document.body.clientWidth * 0.95;
	return Math.min(maxWidth, MAX_FIXED_WIDTH_PX);
};

const useObserver = () => {
	const [maxWidth, setMaxWidth] = useState(calculateMaxWidth());
	const systemOverlays = useSystemOverlays();

	const calculateObserver = useCallback(() => {
		const observer = new ResizeObserver(mutations => {
			if (mutations.at(0)?.target.clientWidth) {
				setMaxWidth(calculateMaxWidth());
			}
		});
		if (systemOverlays) {
			observer.observe(systemOverlays);
		}

		return () => {
			observer.disconnect();
		};
	}, [systemOverlays]);

	const store = useMemo(() => {
		return {
			state: {
				maxWidth
			},
			actions: { calculateObserver }
		};
	}, [maxWidth, calculateObserver]);

	return store;
};

export const [ObserverProvider, useObserverContext] = constate(useObserver);
