import { List } from "immutable";
import { IdentityProviderModel } from "models/IdentityProviderModel";
import { apiReq } from "utils/api/apiReq";

export async function getIdentityProviders(): Promise<List<IdentityProviderModel>> {
	const { data } = await apiReq("GET", "/v1/identityProviders");

	return List(data.map((identityProvider: unknown) => IdentityProviderModel.fromServerData(identityProvider)));
}

export const createIdentityProvider = async (application: string, metadataUrl: string) => {
	const { data: identityProvider } = await apiReq("POST", "/v1/identityProviders", { application, metadataUrl });

	return IdentityProviderModel.fromServerData(identityProvider);
};

export const deleteIdentityProvider = (id: string) => {
	return apiReq("DELETE", `/v1/identityProviders/${id}`);
};
