import React, { useCallback, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { ExtraOptionsButton, TRequestAction, TSelectLinkOption } from "components/common/ExtraOptionsButton";
import { IntegrationRow } from "components/pages/SettingsPage/components/Integrations/components/IntegrationRow";
import { Button, LinkButton } from "components/ui/Button";
import { TeamsLogo } from "components/ui/systemLogos/TeamsLogo";
import { useCompany } from "hooks/useCompany";
import { TNotificationIntegration, getTeamsLink } from "utils/notificationIntegrations";

export const TeamsIntegration: FC<TNotificationIntegration> = ({ integration, onDisconnect }) => {
	const company = useCompany();
	const { t } = useTranslation();

	const companyId = company?.id;

	const teamsLink = useMemo(() => (companyId ? getTeamsLink(companyId) : undefined), [companyId]);

	const extraOptions = useMemo(() => {
		const options = new Map<TRequestAction, TSelectLinkOption>();
		if (teamsLink) {
			options.set("addConnection", {
				value: "addConnection",
				label: t("pages.settings.integrations.addConnection"),
				to: teamsLink,
				target: "_blank"
			});
		}
		return options;
	}, [t, teamsLink]);

	const onDisconnectClicked = useCallback(() => {
		if (onDisconnect && integration) {
			onDisconnect(integration.id);
		}
	}, [integration, onDisconnect]);

	if (!teamsLink) return null;

	return (
		<IntegrationRow
			isIntegrated={!!integration}
			SystemLogo={TeamsLogo}
			integration={
				<Trans
					i18nKey={`pages.settings.integrations.notificationIntegrations.${integration ? "withName.teams" : "teams"}`}
					values={{
						name: integration?.name
					}}
				/>
			}
			actions={
				<>
					{integration ? (
						<>
							<Button variant="text" onClick={onDisconnectClicked} size="small">
								{t("pages.settings.integrations.disconnect")}
							</Button>
							<ExtraOptionsButton noBorder item={integration} extraOptions={extraOptions} size="medium" sort={null} />
						</>
					) : (
						<LinkButton variant="text" to={teamsLink} target="_blank" size="small">
							{t("pages.settings.integrations.connect")}
						</LinkButton>
					)}
				</>
			}
		/>
	);
};
