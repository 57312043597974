import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { DeletedIndicator } from "components/common/entities/components/indicators/DeletedIndicator";
import { getDynamicSizeIcon } from "components/ui/dynamicSizeIcon";
import { IntegrationIcon } from "components/ui/Icons/IntegrationIcon";
import { TitleBody } from "components/ui/TitleBody";
import { Tooltip } from "components/ui/Tooltip";
import { useIntegrations } from "hooks/useIntegrations";
import { Chip, TChipSize, TChipVariant } from "../Chip";
import { SkeletonChip } from "../SkeletonChip";
import type { IntegrationModel } from "models/IntegrationModel";

interface IIntegrationChipProps {
	integration: IntegrationModel | string;
	onDelete?: () => void;
	readonly?: boolean;
	selected?: boolean;
	size?: TChipSize;
	variant?: TChipVariant;
}

export const IntegrationChip: FC<IIntegrationChipProps> = ({
	className,
	innerRef,
	integration,
	onDelete,
	readonly = false,
	selected = false,
	size = "medium",
	variant = "regular"
}) => {
	const { t } = useTranslation();
	const integrations = useIntegrations(true);
	const currentIntegration = typeof integration === "string" ? integrations?.get(integration) : integration;

	const IntegrationChipIcon: IconComponent | undefined = useMemo(
		() =>
			currentIntegration?.imageUrl ? getDynamicSizeIcon(<img src={currentIntegration?.imageUrl} />) : IntegrationIcon,
		[currentIntegration?.imageUrl]
	);

	const inactive = currentIntegration?.isDeleted ?? false;
	const PrefixIcon = useMemo(
		() => (inactive ? DeletedIndicator : IntegrationChipIcon),
		[inactive, IntegrationChipIcon]
	);
	return currentIntegration ? (
		<Tooltip
			content={<TitleBody size="small" title={t("common.tooltips.integrationName")} body={currentIntegration?.name} />}>
			<Chip
				noTooltip
				readonly={readonly}
				inactive={inactive}
				variant={variant}
				size={size}
				PrefixIcon={PrefixIcon}
				onDelete={onDelete}
				selected={selected}
				className={className}
				innerRef={innerRef}>
				{currentIntegration.name}
			</Chip>
		</Tooltip>
	) : (
		<SkeletonChip size={size} />
	);
};
