import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	content: {
		"&$content$content": {
			background: "none",
			padding: "1.5rem 2.25rem"
		},
		display: "flex",
		flexDirection: "column",
		gap: "1.5rem",
		overflow: "hidden",
		padding: "1rem"
	},
	container: {
		background: "var(--color-white)",
		padding: "1.5rem 2.25rem",
		display: "flex",
		flexDirection: "column",
		flex: "1 1 auto",
		borderRadius: "var(--border-radius-semi-rounded)",
		overflow: "auto"
	},
	header: {
		alignItems: "baseline",
		color: "var(--color-grey-dark)",
		display: "flex",
		gap: "1rem",
		paddingBottom: "1rem"
	},
	headerSpacer: {
		alignSelf: "flex-end",
		borderRight: "1px solid var(--color-grey-light)",
		height: "min(80%, 1.5rem)"
	},
	link: {
		"&:hover": {
			textDecoration: "underline"
		}
	},
	navigation: {
		alignItems: "center",
		display: "flex",
		gap: "0.5rem"
	}
});
