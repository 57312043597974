import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { TooltipOnOverflow } from "components/ui/TooltipOnOverflow";
import { Typography } from "components/ui/Typography";
import { useStyles } from "./styles";
import { checkIfTokenIsNew } from "../../../utils";
import type { TPersonalAccessToken } from "../../../PersonalAccessTokensTableSection";

type TProps = {
	token: TPersonalAccessToken;
	newTokenValue?: string;
	setNewTokenValue: (value: string) => void;
};

export const TokenCell: FC<TProps> = ({ token, newTokenValue, setNewTokenValue }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const divRef = useRef<HTMLDivElement>(null);

	const handleScreenClick = useCallback(() => {
		setNewTokenValue("");
	}, [setNewTokenValue]);

	useEffect(() => {
		const handleMouseDown = (event: MouseEvent) => {
			if (divRef.current && !divRef.current.contains(event.target as Node)) {
				handleScreenClick();
			}
		};
		document.addEventListener("mousedown", handleMouseDown);
		return () => {
			document.removeEventListener("mousedown", handleMouseDown);
		};
	}, [handleScreenClick]);
	const renderToken = useMemo(() => {
		if (checkIfTokenIsNew(token)) return null;
		else if (!checkIfTokenIsNew(token) && token.value === newTokenValue && newTokenValue)
			return (
				<div ref={divRef} className={classes.tokenCell}>
					<TooltipOnOverflow content={token.value} />
				</div>
			);
		else return <Typography>{t("pages.settings.tokens.hiddenValue")}</Typography>;
	}, [classes.tokenCell, newTokenValue, t, token]);

	return renderToken;
};
