import constate from "constate";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getPaginatedSessionAuditLogs } from "api/sessionAuditLogs";
import { useLoadingState } from "hooks/useLoadingState";
import { usePagination } from "hooks/usePagination";
import type { DateRange } from "react-day-picker";
import type { TTimezone } from "utils/auditLogs/systemAuditLogTimezone";
import type { IPaginatedSearchOptions } from "utils/searchUtils";

const useSessionAuditLogs = () => {
	const { isLoading, withLoader: withSessionAuditLogsLoader } = useLoadingState();
	const [timezone, setCurrentTimezone] = useState<"local" | "global">("local");
	const [filters, setFilters] = useState<URLSearchParams>(new URLSearchParams());
	const [totalFilters, setTotalFilters] = useState<{ field: string; operator: string; value: string }[]>([]);
	const [totalLogs, setTotalLogs] = useState(0);
	const [dateRange, setDateRange] = useState<DateRange>();

	const fetchSessionAuditLogs = useCallback(
		(paginationOptions: IPaginatedSearchOptions) => {
			const withDateRange = !!dateRange?.from && !!dateRange?.to;
			return withSessionAuditLogsLoader(
				getPaginatedSessionAuditLogs(paginationOptions, filters, timezone, withDateRange ? dateRange : undefined)
			);
		},
		[dateRange, filters, timezone, withSessionAuditLogsLoader]
	);

	const {
		fetchPage,
		totalPages,
		lastPageNumber,
		totalResults,
		itemsForVirtualTable: sessionAuditLogsList
	} = usePagination({ fetchItems: fetchSessionAuditLogs, perPage: 50 });

	useEffect(() => {
		if (totalResults > totalLogs) {
			setTotalLogs(totalResults);
		}
	}, [totalLogs, totalResults]);

	const getNextPage = useCallback(async () => {
		if (!isLoading) {
			await withSessionAuditLogsLoader(fetchPage(lastPageNumber + 1));
		}
	}, [fetchPage, isLoading, lastPageNumber, withSessionAuditLogsLoader]);

	const setTimezone = useCallback((value: TTimezone) => {
		setCurrentTimezone(value);
	}, []);

	const setSearchFilters = useCallback((newFilters: URLSearchParams) => {
		setFilters(newFilters);
	}, []);

	const handleDateRangeChange = useCallback((range: DateRange) => {
		const newFromDate = range.from ? new Date(range.from) : undefined;
		const newToDate = range.to ? new Date(range.to) : undefined;
		setDateRange({ from: newFromDate, to: newToDate });
	}, []);

	const pagination = useMemo(
		() => ({ lastPageNumber, totalAuditLogs: totalResults, totalPages }),
		[lastPageNumber, totalPages, totalResults]
	);

	const store = useMemo(() => {
		const sessionAuditLogs = sessionAuditLogsList ?? [];

		return {
			state: {
				sessionAuditLogs,
				totalResults,
				pagination,
				totalLogs,
				timezone,
				filters,
				totalFilters,
				dateRange,
				isLoading
			},
			actions: { fetchPage, getNextPage, setTimezone, setSearchFilters, handleDateRangeChange, setTotalFilters }
		};
	}, [
		sessionAuditLogsList,
		totalResults,
		pagination,
		totalLogs,
		timezone,
		filters,
		totalFilters,
		dateRange,
		isLoading,
		fetchPage,
		getNextPage,
		setTimezone,
		setSearchFilters,
		handleDateRangeChange
	]);

	return store;
};

export const [SessionAuditLogsProvider, useSessionAuditLogsContext, useTimezone] = constate(
	useSessionAuditLogs,
	value => value,
	({ state }) => state.timezone
);
