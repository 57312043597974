import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	button: {
		color: "var(--color-white)",
		"&:hover": {
			cursor: "pointer",
			backgroundColor: "var(--color-grey-700)"
		},
		"&:active": {
			backgroundColor: "var(--color-purple-500)"
		},
		backgroundColor: "transparent"
	},
	section: {
		justifyItems: "flex-start",
		alignItems: "center",
		width: "var(--side-nav-width-collapsed)",
		display: "flex",
		flexDirection: "column",
		paddingBottom: "20px"
	}
});
