import classNames from "classnames";
import React from "react";
import { IntegrationIcon } from "components/ui/Icons/IntegrationIcon";
import { useStyles } from "./styles";
import { LogoAvatar } from "../LogoAvatar";
import type { TIconProps } from "components/ui/Icon";
import type { IntegrationModel } from "models/IntegrationModel";

type TProps = {
	integration?: { imageUrl?: string | null };
	noBackground?: boolean;
	noBorder?: boolean;
	noWrap?: boolean;
	size?: "xs" | "small" | "medium" | "large" | number;
};

export const IntegrationImage: FC<TProps> = ({
	className,
	innerRef,
	integration,
	noBackground = false,
	noBorder = false,
	noWrap = false,
	size = "medium"
}) => {
	const classes = useStyles({ noBackground });

	const imageUrl = integration?.imageUrl;
	if (imageUrl) {
		return (
			<LogoAvatar
				className={classNames(classes.integrationImage, className)}
				innerRef={innerRef}
				size={size}
				noWrap={noWrap}
				noBorder={noBorder}>
				<img src={imageUrl} className={classes.integrationImage} />
			</LogoAvatar>
		);
	} else {
		return (
			<IntegrationIcon
				className={classNames(classes.integrationImage, className)}
				innerRef={innerRef}
				size={size === "xs" ? 20 : size}
			/>
		);
	}
};

export const getIntegrationIcon = (integration: IntegrationModel): IconComponent => {
	const IntegrationIcon = ({ size }: TIconProps) => {
		return <IntegrationImage size={size === "xl" ? 44 : size} integration={integration} noBackground />;
	};
	IntegrationIcon.isIconComponent = true as const;
	return IntegrationIcon;
};
