import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	header: {
		padding: "0.75em 1.5em",
		borderRadius: "8px 8px 0 0",
		background: "var(--color-almost-white)"
	},
	resourceHeaderBlock: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		gap: "0.5rem"
	},
	resourceHeaderSection: {
		display: "inline-flex",
		flexDirection: "column",
		alignItems: "center"
	},
	connectionField: {
		marginBottom: "1rem"
	},
	settingsModal: {
		maxWidth: "50rem"
	},
	description: {
		hyphens: "none"
	},
	hiddenInput: {
		display: "none"
	}
});
