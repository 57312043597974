import { useCallback, useRef } from "react";
import { useStopPropagation } from "./useStopPropagation";

const MAX_PARENTS_TO_CHECK = 50;
function getInterruptParent(element?: HTMLElement) {
	let iterations = 0;
	while (element?.parentElement && iterations < MAX_PARENTS_TO_CHECK) {
		if (element.parentElement.getAttribute("data-interrupt-parent-tooltip")) {
			return element.parentElement;
		}
		element = element.parentElement;
		iterations++;
	}
	return undefined;
}

export const useParentTooltipInterrupt = () => {
	const parentInterrupt = useRef<HTMLElement>();
	const stopPropagation = useStopPropagation();

	const interruptParentOver = useCallback(
		(e: React.MouseEvent<HTMLElement>) => {
			const interruptParent = getInterruptParent(e.currentTarget);
			if (interruptParent) {
				parentInterrupt.current = interruptParent;
				interruptParent.dispatchEvent(new MouseEvent("mouseleave"));
			}
			stopPropagation(e);
		},
		[stopPropagation]
	);

	const reinstateParentState = useCallback(() => {
		if (parentInterrupt.current) {
			parentInterrupt.current.dispatchEvent(new MouseEvent("mouseenter"));
			parentInterrupt.current = undefined;
		}
	}, []);

	return { interruptParentOver, reinstateParentState };
};
