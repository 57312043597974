import constate from "constate";
import { Map } from "immutable";
import { useCallback } from "react";
import {
	getApiTokens,
	createApiToken as apiCreateApiToken,
	deleteApiToken as apiDeleteApiToken,
	updateApiToken as apiUpdateApiToken
} from "api/apiTokens";
import { useFetchedState } from "hooks/useFetchedState";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { ApiTokenModel } from "models/ApiTokenModel";

const useApiTokens = () => {
	const { data: apiTokens, setData: setApiTokens, loadData: loadApiTokens } = useFetchedState(getApiTokens);

	const openGlobalErrorModal = useOpenGlobalErrorModal();

	const getMap = useCallback(async (): Promise<Map<string, ApiTokenModel>> => {
		if (apiTokens) return apiTokens;
		try {
			return await getApiTokens();
		} catch (error) {
			openGlobalErrorModal(error as Error);
			return Map();
		}
	}, [apiTokens, openGlobalErrorModal]);

	const createApiToken = useCallback(
		async (name: string, duration: number | null) => {
			try {
				const createdToken = await apiCreateApiToken({ name, duration });
				setApiTokens((await getMap()).set(createdToken.id, createdToken));
				return createdToken;
			} catch (error) {
				openGlobalErrorModal(error as Error);
				return;
			}
		},
		[getMap, openGlobalErrorModal, setApiTokens]
	);

	const updateApiToken = useCallback(
		async (tokenId: string, name: string) => {
			try {
				const token = await apiUpdateApiToken(tokenId, name);
				const updatedToken = (await getMap()).get(token.id)?.set("name", token.name);

				if (!updatedToken) return;
				setApiTokens((await getMap()).set(token.id, updatedToken));

				return updatedToken;
			} catch (error) {
				openGlobalErrorModal(error as Error);
				return;
			}
		},
		[getMap, openGlobalErrorModal, setApiTokens]
	);

	const deleteApiToken = useCallback(
		async (tokenId: string) => {
			try {
				await apiDeleteApiToken(tokenId);

				setApiTokens((await getMap()).remove(tokenId));
			} catch (error) {
				openGlobalErrorModal(error as Error);
				return;
			}
		},
		[getMap, openGlobalErrorModal, setApiTokens]
	);

	return {
		state: { apiTokens },
		actions: { createApiToken, updateApiToken, deleteApiToken, loadApiTokens }
	};
};

export const [ApiTokensProvider, useApiTokensContext] = constate(useApiTokens);
