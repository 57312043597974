import { List, Map } from "immutable";
import { useMemo, useState } from "react";

const splitToPages = <T>(items: List<T>, pageSize: number) =>
	Map<number, List<T>>(
		items.reduce((acc, item, i) => {
			const pageNumber = Math.floor(i / pageSize);
			if (!acc.has(pageNumber)) return acc.set(pageNumber, List<T>([item]));

			return acc.set(pageNumber, acc.get(pageNumber)!.push(item));
		}, Map<number, List<T>>())
	);

export const useLocalPagination = <T>(items: List<T>, pageSize = 10) => {
	const [currentPageNumber, setCurrentPage] = useState(1);
	const pageResults = useMemo(() => splitToPages(items, pageSize), [items, pageSize]);

	const totalPages = Math.ceil(items.count() / pageSize);
	const page = pageResults.get(currentPageNumber - 1) || List<T>();

	return {
		totalPages,
		items,
		currentPageNumber,
		setCurrentPage,
		page
	};
};
