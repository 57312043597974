import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	container: {
		alignItems: "center",
		display: "inline-flex",
		gap: "0.25rem",
		justifyContent: "center"
	},
	inputContainer: {
		display: "flex",
		flexDirection: "row",
		gap: "0.5rem"
	},
	hiddenInput: {
		minWidth: "17.15rem",
		"& input": {
			"&$regular": {
				fontSize: "1rem"
			},
			"&$big": {
				fontSize: "1.3rem"
			}
		}
	},
	hoverActions: {
		"&:hover": {
			border: "1px solid var(--color-grey-light)",
			borderRadius: "var(--border-radius-sharp)",
			color: "var(--color-grey-dark)"
		}
	},
	value: {
		fontWeight: "300",
		color: "var(--color-grey-dark)",
		padding: "0.1rem",
		"&$regular": {
			fontSize: "1rem"
		},
		"&$big": {
			fontSize: "1.3rem"
		}
	},
	regular: {},
	big: {}
});
