import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	archiveAllButton: {
		alignSelf: "flex-end",
		textAlign: "left",
		marginRight: "2rem"
	},
	columnTitle: {
		display: "flex",
		flexDirection: "row",
		gap: "1rem",
		flexGrow: 0,
		justifyContent: "start",
		alignItems: "start",
		overflow: "hidden",
		userSelect: "none",
		height: "2rem",
		paddingLeft: "1.5rem"
	},
	spacer: {
		height: "1rem"
	},
	taskContainer: {
		paddingLeft: "0.5rem",
		paddingRight: "1rem"
	},
	taskStatusColumn: {
		"& > $taskStatusColumnContentContainer": {
			height: "100%",
			overflowY: "auto"
		},
		display: "flex",
		flex: 1,
		flexDirection: "column",
		overflow: "hidden",
		padding: "2rem 1rem",
		backgroundColor: "var(--color-grey-background)",
		borderRadius: "var(--border-radius-semi-rounded)"
	},
	taskStatusColumnContent: {
		display: "flex",
		flexDirection: "column",
		gap: "1rem",
		height: "100%",
		padding: "1rem",
		"& > * > * > *:first-child > $taskContainer > $spacer": {
			height: "0.25rem"
		}
	},
	taskStatusColumnContentContainer: {},
	taskStatusColumnsContainer: {
		display: "flex",
		flex: 1,
		gap: "1.5rem",
		overflowY: "hidden"
	},
	doneTitle: {
		display: "flex",
		justifyContent: "space-between",
		width: "100%"
	}
});
