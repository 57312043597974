import { createUseStyles } from "react-jss";

const DROPDOWN_DEFAULT_MAX_HEIGHT = 288;
type TProps = { maxHeight?: number } | undefined;
export const useStyles = createUseStyles({
	groupContainer: {
		boxSizing: "border-box",
		display: "flex",
		flexDirection: "column",
		width: "100%"
	},
	groupLabel: {
		fontWeight: "var(--font-weight-bold)",
		padding: "var(--spacing-x2, 8px) var(--spacing-x4, 16px)",
		pointerEvents: "none"
	},

	maxHeight: {
		maxHeight: (props: TProps) => `${props?.maxHeight || DROPDOWN_DEFAULT_MAX_HEIGHT}px`
	},
	noOptions: {
		alignItems: "center",
		backgroundColor: "var(--color-white)",
		boxSizing: "border-box",
		cursor: "default",
		display: "flex",
		height: "52px",
		minWidth: "100%",
		padding: "var(--spacing-x2, 8px) var(--spacing-x4, 16px)",
		width: "fit-content"
	},
	searchForMore: {
		alignItems: "center",
		display: "flex",
		justifyContent: "center",
		padding: "var(--spacing-x1, 4px) 0"
	}
});
