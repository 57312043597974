import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	binaryToggleContainer: {
		"&$even": {
			gridTemplateColumns: "1fr 1fr"
		},
		borderRadius: "var(--border-radius-rounder)",
		boxSizing: "border-box",
		display: "inline-grid",
		gridTemplateColumns: "auto auto"
	},
	binaryToggleOption: {
		"&.selected": {
			"&:hover:not(.disabled)": {
				backgroundColor: "var(--toggle-button-active-hover-background-color)"
			},
			backgroundColor: "var(--toggle-button-active-background-color)",
			color: "var(--color-purple)"
		},
		"&:first-child": {
			"&:not(.selected)": {
				borderRight: "none",
				boxShadow: "var(--toggle-button-inactive-first-box-shadow)"
			},
			borderRadius: "var(--border-radius-rounder) 0 0 var(--border-radius-rounder)"
		},
		"&:hover:not(.disabled)": {
			backgroundColor: "var(--toggle-button-inactive-hover-background-color)",
			cursor: "pointer"
		},
		"&:last-child": {
			"&:not(.selected)": {
				borderLeft: "none",
				boxShadow: "var(--toggle-button-inactive-last-box-shadow)"
			},
			borderRadius: "0 var(--border-radius-rounder) var(--border-radius-rounder) 0"
		},
		backgroundColor: "var(--toggle-button-inactive-background-color)",
		border: "var(--surface-block-border)",
		boxSizing: "border-box",
		color: "var(--button-disabled-font-color)",
		display: "inline-flex",
		justifyContent: "center",
		padding: "0.375em 0.625em",
		transitionDelay: "0.1s",
		willChange: "color, background-color, border-radius, box-shadow"
	},
	even: {},
	toggleButton: {},
	toggleButtonGroup: {
		"& > $toggleButton": {
			fontSize: "1em"
		}
	}
});
