import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	title: {
		display: "flex",
		justifyContent: "space-between"
	},
	noForwards: {
		textAlign: "center",
		width: "100%"
	}
});
