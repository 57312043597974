import { createUseStyles } from "react-jss";

const REGULAR_HEIGHT_PX = 20;
const REGULAR_WIDTH_PX = 40;
const REGULAR_SLIDER_SIZE_PX = 16;

const SMALL_HEIGHT_PX = 16;
const SMALL_WIDTH_PX = 32;
const SMALL_SLIDER_SIZE_PX = 12;

const BORDER_SIZE_PX = 2;

export const useStyles = createUseStyles({
	disabled: {},
	small: {},
	switch: {
		position: "relative",
		display: "inline-block",
		width: `${REGULAR_WIDTH_PX}px`,
		height: `${REGULAR_HEIGHT_PX}px`,
		"&$disabled": {
			opacity: 0.5,
			"& > $slider": {
				cursor: "default"
			}
		},
		"&$small": {
			width: `${SMALL_WIDTH_PX}px`,
			height: `${SMALL_HEIGHT_PX}px`,
			"&:before": {
				left: `${SMALL_SLIDER_SIZE_PX - BORDER_SIZE_PX}px`
			}
		},
		"& > input": {
			opacity: 0,
			height: 0,
			width: 0,
			"&:checked": {
				"& + $slider": {
					backgroundColor: "var(--color-purple-500)",
					"&:before": {
						left: `${REGULAR_SLIDER_SIZE_PX + BORDER_SIZE_PX}px`
					}
				}
			}
		}
	},
	slider: {
		position: "absolute",
		cursor: "pointer",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		transition: ".4s",
		borderRadius: `${REGULAR_HEIGHT_PX / 2}px`,
		backgroundColor: "var(--color-purple-200)",
		border: "2px solid var(--color-purple-600)",
		"&:before": {
			position: "absolute",
			content: '""',
			height: `${REGULAR_SLIDER_SIZE_PX}px`,
			width: `${REGULAR_SLIDER_SIZE_PX}px`,
			left: `-${BORDER_SIZE_PX}px`,
			bottom: `-${BORDER_SIZE_PX}px`,
			borderRadius: "50%",
			border: "2px solid var(--color-purple-600)",
			backgroundColor: "white",
			transition: ".4s"
		},
		"&$small": {
			borderRadius: `${SMALL_HEIGHT_PX / 2}px`,
			"&:before": {
				height: `${SMALL_SLIDER_SIZE_PX}px`,
				width: `${SMALL_SLIDER_SIZE_PX}px`
			}
		}
	}
});
