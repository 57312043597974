import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	appImage: {
		border: "1px solid var(--color-grey-light)",
		borderRadius: "var(--border-radius-sharp)",
		display: "inline-block",
		height: "1em",
		overflow: "hidden",
		padding: "0.1em",
		verticalAlign: "middle",
		width: "1em"
	},
	page: {
		borderRadius: "var(--border-radius-semi-rounded)"
	},
	header: {
		backgroundColor: "var(--color-white)",
		borderRadius: "8px 8px 0 0",
		border: "0.1px solid #80808030"
	},
	approvalAlgorithmContainer: {
		overflow: "hidden"
	},
	backButton: {
		color: "var(--typography-link-font-color)",
		textDecoration: "none"
	},
	back: {
		display: "flex",
		alignItems: "center"
	},
	container: {
		display: "flex",
		gap: "2rem"
	},
	editConfigurationButton: {
		alignItems: "center",
		display: "inline-flex",
		flexDirection: "row",
		gap: "0.35rem"
	},
	integrationForm: {
		display: "block",
		flexShrink: 0,
		width: "25rem"
	},
	integrationNameContainer: {
		alignItems: "center",
		display: "flex",
		gap: "0.5rem"
	},
	integrationTitle: {
		"& $integrationNameContainer": {
			fontWeight: "var(--typography-font-weight-medium)"
		},
		alignItems: "center",
		display: "flex",
		justifyContent: "space-between"
	},
	integrationTitleBlock: {
		alignItems: "center",
		display: "inline-flex",
		flexDirection: "row",
		gap: "0.5rem"
	},
	pageContent: {
		display: "flex",
		flexDirection: "column",
		backgroundColor: "var(--color-grey-background)",
		height: "100%",
		borderRadius: "var(--border-radius-semi-rounded)"
	},
	sendRequestButtonContainer: {
		alignItems: "center",
		display: "flex",
		justifyContent: "right",
		paddingTop: "0.5rem"
	},
	spinner: {
		margin: "auto"
	},
	content: {
		display: "flex",
		flexDirection: "column",
		gap: "1.1em",
		padding: "1.5em 2.25em"
	}
});
