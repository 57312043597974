import React from "react";

export const PagerDutyLogo: FC = ({ className }) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" className={className}>
		<path
			d="M8.90123 17.118V22H6.16663V17.118H8.90123ZM11.6333 3.66663C13.9764 3.66909 14.992 3.79988 16.4353 4.5678C18.0209 5.4058 19 7.07192 19 9.14471C19 11.0724 18.2019 12.738 16.6694 13.7345C15.2658 14.6564 13.6686 14.7822 11.5406 14.7822H6.16663V3.66663H11.6333ZM11.9872 6.02319L8.90123 6.04441V12.4562H12.1682C14.4562 12.4562 16.2012 11.5551 16.2012 9.17729C16.2012 6.9668 14.7975 6.00246 11.9872 6.02319Z"
			fill="#06AC38"
		/>
	</svg>
);
