import React from "react";
import { useTranslation } from "react-i18next";
import { Typography as LegacyTypography } from "components/ui/legacy/Typography";
import { Tooltip } from "components/ui/Tooltip";
import { Typography } from "components/ui/Typography";
import { useStyles } from "./styles";
import { UserEntity } from "../entities/UserEntity";
import type { UserModel } from "models/UserModel";

export const LegacyInheritOwnerOption: FC<{ user: UserModel; emphasis: "text" | "user"; wrap?: boolean }> = ({
	user,
	emphasis,
	wrap = false
}) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const textVariant = emphasis === "user" ? "small" : "standard";
	return (
		<div className={classes.inheritOwnerOption}>
			<LegacyTypography variant={textVariant}>{t("shared.integrationDefault")}</LegacyTypography>
			<UserEntity withIcon user={user} noWrap={!wrap} />
		</div>
	);
};

export const InheritOwnerOption: FC<{ user: UserModel }> = ({ user }) => {
	const { t } = useTranslation();
	return (
		<Tooltip content={<UserEntity withIcon user={user} />}>
			<Typography variant="text_title_sb">{t("shared.integrationDefault")}</Typography>
		</Tooltip>
	);
};
