import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	statusTooltip: {
		justifyContent: "center",
		display: "flex",
		alignItems: "center",
		gap: "var(--spacing-x1, 4px)"
	},
	pendingTooltipWrapper: {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start"
	},
	pendingTitle: {
		marginBottom: "var(--spacing-x5, 20px)"
	},
	pendingTooltip: {
		display: "block",
		overflow: "scroll",
		maxWidth: "min(70%, 952px)",
		maxHeight: "600px",
		padding: "var(--spacing-x5, 20px)",
		zIndex: 0
	},
	pendingIcon: {
		marginRight: "var(--spacing-x2, 8px)"
	}
});
