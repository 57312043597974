import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "components/ui/legacy/Typography";
import { getApprovalIconAndText, TicketStatus } from "utils/tickets/ticketStatus";
import { useStyles } from "./styles";

interface IProps {
	status?: string;
	bold?: boolean;
}

export const TicketStatusWithIcon: FC<IProps> = ({ bold = true, status, className }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const [Icon, statusType] = getApprovalIconAndText(status as TicketStatus, t);

	const statusIcon = <Icon className={classes.icon} />;
	return (
		<div className={classNames(classes.content, className)}>
			{statusIcon}
			<Typography relative component="span" className={classNames(classes.status, { [classes.bold]: bold })}>
				{statusType}
			</Typography>
		</div>
	);
};
