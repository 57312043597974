import React from "react";
import { makeIcon } from "components/ui/Icon";

export const PrerequisiteIcon = makeIcon(
	<svg viewBox="0 0 24 24" fill="none">
		<path
			d="M14 9.00001C13.7167 9.00001 13.4792 8.90417 13.2875 8.71251C13.0959 8.52084 13 8.28334 13 8.00001C13 7.71667 13.0959 7.47917 13.2875 7.28751C13.4792 7.09584 13.7167 7.00001 14 7.00001H21C21.2834 7.00001 21.5209 7.09584 21.7125 7.28751C21.9042 7.47917 22 7.71667 22 8.00001C22 8.28334 21.9042 8.52084 21.7125 8.71251C21.5209 8.90417 21.2834 9.00001 21 9.00001H14ZM14 17C13.7167 17 13.4792 16.9042 13.2875 16.7125C13.0959 16.5208 13 16.2833 13 16C13 15.7167 13.0959 15.4792 13.2875 15.2875C13.4792 15.0958 13.7167 15 14 15H21C21.2834 15 21.5209 15.0958 21.7125 15.2875C21.9042 15.4792 22 15.7167 22 16C22 16.2833 21.9042 16.5208 21.7125 16.7125C21.5209 16.9042 21.2834 17 21 17H14ZM4.85005 10.3L2.70005 8.15001C2.51672 7.96667 2.42505 7.73334 2.42505 7.45001C2.42505 7.16667 2.51672 6.93334 2.70005 6.75001C2.88338 6.56667 3.11672 6.47501 3.40005 6.47501C3.68338 6.47501 3.91672 6.56667 4.10005 6.75001L5.52505 8.17501L9.07505 4.62501C9.27505 4.42501 9.50838 4.32917 9.77505 4.33751C10.0417 4.34584 10.275 4.45001 10.475 4.65001C10.6584 4.85001 10.75 5.08334 10.75 5.35001C10.75 5.61667 10.6584 5.85001 10.475 6.05001L6.25005 10.3C6.05005 10.5 5.81672 10.6 5.55005 10.6C5.28338 10.6 5.05005 10.5 4.85005 10.3ZM4.85005 18.3L2.70005 16.15C2.51672 15.9667 2.42505 15.7333 2.42505 15.45C2.42505 15.1667 2.51672 14.9333 2.70005 14.75C2.88338 14.5667 3.11672 14.475 3.40005 14.475C3.68338 14.475 3.91672 14.5667 4.10005 14.75L5.52505 16.175L9.07505 12.625C9.27505 12.425 9.50838 12.3292 9.77505 12.3375C10.0417 12.3458 10.275 12.45 10.475 12.65C10.6584 12.85 10.75 13.0833 10.75 13.35C10.75 13.6167 10.6584 13.85 10.475 14.05L6.25005 18.3C6.05005 18.5 5.81672 18.6 5.55005 18.6C5.28338 18.6 5.05005 18.5 4.85005 18.3Z"
			fill="currentColor"
		/>
	</svg>
);
