import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	tokenCell: {
		display: "flex",
		width: "100%",
		height: "100%",
		textAlign: "center",
		alignItems: "center",
		justifyContent: "flex-start"
	}
});
