import { Record } from "immutable";
import { PolicyConflictsModel } from "./PolicyConflictsModel";

const defaults = {
	bundleIds: [] as string[],
	conflicts: null as PolicyConflictsModel | null,
	directoryGroupIds: [] as string[],
	id: "",
	integrationResourceRoleIds: [] as string[],
	onCallIntegrationScheduleIds: [] as string[],
	sortOrder: 0,
	number: 0
};

export class PolicyModel extends Record<typeof defaults>(defaults) {
	static fromServerData(data: unknown, conflicts: unknown = null) {
		const parsedData = data as typeof defaults;

		return new PolicyModel({
			...parsedData,
			conflicts: conflicts ? PolicyConflictsModel.fromServerData(conflicts) : null
		});
	}
}
