import React from "react";
import { ApprovalFlowRequestModel } from "models/ApprovalFlowRequestModel";
import { useApprovalFlowStepEntities } from "./useApprovalFlowStepEntities";
import { ShouldBeApproved } from "../ShouldBeApproved";

export const ApprovalFlowStep: FC<{ approvalFlowRequest: ApprovalFlowRequestModel }> = ({
	approvalFlowRequest,
	className
}) => {
	const { approvers, notified, operator } = approvalFlowRequest;
	const approversEntities = useApprovalFlowStepEntities(approvers);
	const notifiedEntities = useApprovalFlowStepEntities(notified);

	return (
		<ShouldBeApproved
			approverEntities={approversEntities}
			notifiedEntities={notifiedEntities}
			className={className}
			operator={operator}
		/>
	);
};
