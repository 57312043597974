import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	block: {
		display: "block",
		borderRadius: "var(--border-radius-semi-rounded)",
		backgroundColor: "var(--surface-block-background-color)",
		boxShadow: "var(--surface-block-box-shadow)",
		padding: "1.5em",
		margin: "0.5em 0"
	},
	helpBlock: {
		backgroundColor: "var(--color-almost-white)",
		boxShadow: "none",
		color: "var(--color-grey-dark)",
		border: "1px solid rgba(0, 0, 0, 0.1)"
	}
});
