import { useMemo, useState } from "react";
import { getUserPastTickets, getUserAllPastTickets, getUserRespondedTickets } from "api/user";
import { usePagination } from "hooks/usePagination";
import { useSortState } from "hooks/useSortState";
import type { TSearchTicketsParams } from "api/tickets";

export const REQUESTS_PAGE_SIZE = 20;
export const MY_REQUESTS = "myRequests";
export const ALL_REQUESTS = "allRequests";
export const RESPONDED_TO_REQUESTS = "respondedToRequests";

export type TRequestListType = typeof MY_REQUESTS | typeof ALL_REQUESTS | typeof RESPONDED_TO_REQUESTS;

const DEFAULT_TYPE_SORT_STATE = { defaultSortField: "ticketNumber", defaultSortOrder: "DESC" as const };

const notNothing = (value: unknown) => {
	if (typeof value === "string") return value !== "";
	if (Array.isArray(value)) return value.length > 0;
	if (typeof value === "undefined") return false;
	return value && Object.values(value).some(notNothing);
};

export const usePastRequests = () => {
	const [requestsListType, setRequestsListType] = useState<TRequestListType>(MY_REQUESTS);
	const myRequestsSortState = useSortState(DEFAULT_TYPE_SORT_STATE);
	const [myRequestsFilters, setMyRequestsFilters] = useState<TSearchTicketsParams>({});
	const allRequestsSortState = useSortState(DEFAULT_TYPE_SORT_STATE);
	const [allRequestsFilters, setAllRequestsFilters] = useState<TSearchTicketsParams>({});
	const respondedToRequestsSortState = useSortState(DEFAULT_TYPE_SORT_STATE);
	const [respondedToRequestsFilters, setRespondedToRequestsFilters] = useState<TSearchTicketsParams>({});

	const sortState = useMemo(() => {
		switch (requestsListType) {
			case MY_REQUESTS:
				return myRequestsSortState;
			case ALL_REQUESTS:
				return allRequestsSortState;

			case RESPONDED_TO_REQUESTS:
				return respondedToRequestsSortState;
		}
	}, [requestsListType, myRequestsSortState, allRequestsSortState, respondedToRequestsSortState]);

	const paginationParams = useMemo(() => {
		const params = {
			perPage: REQUESTS_PAGE_SIZE,
			sortOrder: sortState.sortOrder,
			sortFields: sortState.sortFields
		};
		switch (requestsListType) {
			case MY_REQUESTS:
				return {
					fetchItems: getUserPastTickets,
					body: myRequestsFilters,
					...params
				};
			case ALL_REQUESTS:
				return {
					fetchItems: getUserAllPastTickets,
					body: allRequestsFilters,
					...params
				};
			case RESPONDED_TO_REQUESTS:
				return {
					fetchItems: getUserRespondedTickets,
					body: respondedToRequestsFilters,
					...params
				};
		}
	}, [requestsListType, sortState, myRequestsFilters, allRequestsFilters, respondedToRequestsFilters]);

	const { body: filters } = paginationParams;

	const { itemsForVirtualTable, getPage, totalResults, isLoading } = usePagination(paginationParams);
	const requests = useMemo(() => itemsForVirtualTable ?? [], [itemsForVirtualTable]);

	const activeFilters = useMemo(() => {
		const { target, ...restFilters } = filters;
		const baseFiltersAmount = Object.values(restFilters).filter(notNothing).length;
		const targetFiltersAmount = Object.values(target ?? {}).filter(notNothing).length;
		const totalAmount = baseFiltersAmount + targetFiltersAmount;
		return totalAmount > 0 ? ` (${totalAmount})` : "";
	}, [filters]);

	const onFiltersChange = useMemo(() => {
		switch (requestsListType) {
			case MY_REQUESTS:
				return setMyRequestsFilters;

			case ALL_REQUESTS:
				return setAllRequestsFilters;

			case RESPONDED_TO_REQUESTS:
				return setRespondedToRequestsFilters;
		}
	}, [requestsListType]);

	return {
		activeFilters,
		setRequestsListType,
		filters,
		getPage,
		isLoading,
		onFiltersChange,
		requestsListType,
		requests,
		sortState,
		totalResults
	};
};
