import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	entitleLogsSection: {
		paddingTop: "var(--spacing-x7, 28px)"
	},
	selectContainer: {
		width: "fit-content",
		flexGrow: 0
	}
});
