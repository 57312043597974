import React from "react";
import { ApprovalAlgorithmRuleModel } from "models/ApprovalAlgorithmRuleModel";
import { AccessRequest } from "../AccessRequest";
import { OperatorBlock } from "../OperatorBlock";

const AlgorithmRuleComponent = ({ algorithmRule }: { algorithmRule: ApprovalAlgorithmRuleModel }) => {
	if (!algorithmRule) return null;
	return (
		<>
			<OperatorBlock operator="if" />
			<AccessRequest rule={algorithmRule} />
		</>
	);
};

export const AlgorithmRule = React.memo(AlgorithmRuleComponent);
