import classNames from "classnames";
import orderBy from "lodash/orderBy";
import React, { Fragment, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { AreYouSureModal } from "components/common/AreYouSureModal";
import { AddRoleFormModal } from "components/common/PrerequisitePermissionsBlock/AddRoleFormModal";
import { IconButton } from "components/ui/IconButton";
import { AddIcon } from "components/ui/Icons/AddIcon";
import { CloseIcon } from "components/ui/Icons/CloseIcon";
import { Typography } from "components/ui/legacy/Typography";
import { useIsOpenState } from "hooks/useIsOpenState";
import { useLoadingState } from "hooks/useLoadingState";
import { PrerequisitePermissionClauseModel } from "models/PrerequisitePermissionClauseModel";
import { notEmpty } from "utils/comparison";
import { PrerequisitePermissionRole } from "./PrerequisitePermissionRole";
import { useStyles } from "./styles";

interface IProps {
	clause: PrerequisitePermissionClauseModel;
	onUpdate: () => Promise<void>;
	singleClause: boolean;
	onRoleCreation: (integrationResourceRoleId: string, clauseId: string) => Promise<void>;
	onRoleUpdate: (integrationResourceRoleId: string, clauseId: string) => Promise<void>;
	onClauseRemove: (prerequisitePermissionClauseId: string) => Promise<void>;
	onRoleRemove: (prerequisitePermissionRoleId: string) => Promise<void>;
	prohibitedIds?: {
		integrationResourceIds?: string[] | null;
		integrationResourceRoleIds?: string[] | null;
	};
}

export const PrerequisitePermissionClause: FC<IProps> = ({
	clause,
	onUpdate,
	className,
	onRoleUpdate,
	onRoleCreation,
	onClauseRemove,
	onRoleRemove,
	prohibitedIds
}) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { open: openAddRoleModal, isOpen: isAddRoleModalOpen, close: closeAddRoleModal } = useIsOpenState();
	const { isLoading, withLoader } = useLoadingState();
	const areYouSureModal = useIsOpenState();

	const prerequisitePermissionRoles = useMemo(
		() => clause.prerequisitePermissionRoles?.toArray() || [],
		[clause.prerequisitePermissionRoles]
	);

	const orderedPrerequisitePermissionRoles = useMemo(
		() => orderBy(prerequisitePermissionRoles, "defaultOption", "desc"),
		[prerequisitePermissionRoles]
	);

	const onAddRole = useCallback(
		async (integrationResourceRoleId: string) => {
			await onRoleCreation(integrationResourceRoleId, clause.id);
			await onUpdate();
		},
		[onRoleCreation, clause, onUpdate]
	);

	const removeClause = useCallback(async () => {
		await withLoader(onClauseRemove(clause.id));
	}, [withLoader, onClauseRemove, clause]);

	const existingRoles = useMemo(() => {
		return prerequisitePermissionRoles.map(({ integrationResourceRole }) => integrationResourceRole).filter(notEmpty);
	}, [prerequisitePermissionRoles]);

	return (
		<div className={classNames(classes.container, className)}>
			<AreYouSureModal
				title={t("prerequisitePermissions.clauses.remove.title")}
				content={t("prerequisitePermissions.clauses.remove.content")}
				isOpen={areYouSureModal.isOpen}
				onClose={areYouSureModal.close}
				onAction={removeClause}
				actionLabel={t("buttons.remove")}
			/>
			<AddRoleFormModal
				onSaveRole={onAddRole}
				type="role"
				isOpen={isAddRoleModalOpen}
				onClose={closeAddRoleModal}
				existingClauseRoles={existingRoles}
				prohibitedIds={prohibitedIds}
			/>
			<div className={classes.rolesContainer}>
				{orderedPrerequisitePermissionRoles.map((role, index) => (
					<Fragment key={role.id}>
						<PrerequisitePermissionRole
							role={role}
							removable={orderedPrerequisitePermissionRoles.length > 1}
							onUpdate={onRoleUpdate}
							onRemove={onRoleRemove}
						/>
						{index < orderedPrerequisitePermissionRoles.length - 1 && (
							<Typography>{t("prerequisitePermissions.clauses.or")}</Typography>
						)}
					</Fragment>
				))}
				<IconButton size="medium" onClick={openAddRoleModal} className={classes.addRole}>
					<AddIcon />
				</IconButton>
			</div>
			<IconButton size="medium" onClick={areYouSureModal.open} className={classes.removeClause} loading={isLoading}>
				<CloseIcon clickable tooltip={t("prerequisitePermissions.clauses.remove.title")} />
			</IconButton>
		</div>
	);
};
