import { createUseStyles } from "react-jss";
import { BP_MEDIA_QUERIES } from "utils/ui/breakpoints";

type TStylesOptions = {
	size?: number;
	absoluteOffset?: { bottom?: number; top?: number };
};

export const useStyles = createUseStyles({
	paddedContent: {
		display: "flex",
		overflow: "auto",
		height: "100%",
		flexDirection: "column",
		gap: "var(--spacing-x10, 40px)",
		padding: "var(--spacing-x5, 20px) var(--spacing-x5, 20px) var(--spacing-x10, 40px) var(--spacing-x5, 20px)",
		width: "auto",
		[BP_MEDIA_QUERIES.FORTY]: {
			width: (options?: TStylesOptions) => (options?.size ? `${options.size}px` : "auto")
		}
	},
	absoluteContainer: {
		background: "var(--color-white, #FFF)",
		bottom: (options?: TStylesOptions) => options?.absoluteOffset?.bottom ?? 0,
		height: (options?: TStylesOptions) =>
			options?.absoluteOffset
				? `calc(100% - ${options.absoluteOffset.bottom ?? 0}px - ${options.absoluteOffset.top ?? 0}px)`
				: "auto",
		position: "absolute",
		overflow: "auto",
		transform: "translateY(100%)",
		// transform: (options?: TStylesOptions) => `translateY(${options?.size || 0}px)`,
		transition: "transform 0.7s ease-in-out",
		width: "100%",
		maxHeight: "100%",
		zIndex: 100,
		"&$open": {
			transform: "none",
			boxShadow: "0px -4px 5px 1px rgba(0, 0, 0, 0.20)"
		}
	},
	relativeContainer: {
		background: "var(--color-white, #FFF)",
		borderRadius: "20px 0px 0px 0px",
		boxShadow: "-13px 0px 9.3px -5px rgba(0, 0, 0, 0.20)",
		height: "100%",
		overflow: "hidden",
		position: "relative",
		transition: "width 0.7s ease-in-out",
		width: 0,
		"&$open": {
			width: (options?: TStylesOptions) => (options?.size ? `${options.size}px` : "auto")
		}
	},
	open: {}
});
