import { Record } from "immutable";
import { ApprovalFlowEntityModel } from "./ApprovalFlowEntityModel";

const NO_IDENTIFIER_APPROVAL_FLOW_NOTIFIED_ENTITY_TYPE = [
	"DirectManager" as const,
	"IntegrationMaintainer" as const,
	"IntegrationOwner" as const,
	"ResourceMaintainer" as const,
	"ResourceOwner" as const,
	"TeamMember" as const
];

type TNoIdentifierApprovalFlowNotifiedEntityType = (typeof NO_IDENTIFIER_APPROVAL_FLOW_NOTIFIED_ENTITY_TYPE)[number];

type TApprovalFlowNotifiedEntityType =
	| TNoIdentifierApprovalFlowNotifiedEntityType
	| "DirectoryGroup"
	| "OnCallIntegrationSchedule"
	| "HR"
	| "Webhook"
	| "User";

const isNoIdentifierApprovalFlowNotifiedEntityType = (
	entityType: TApprovalFlowNotifiedEntityType
): entityType is TNoIdentifierApprovalFlowNotifiedEntityType =>
	NO_IDENTIFIER_APPROVAL_FLOW_NOTIFIED_ENTITY_TYPE.includes(entityType as TNoIdentifierApprovalFlowNotifiedEntityType);

const defaults = {
	identifier: null as string | null,
	type: "" as TApprovalFlowNotifiedEntityType
};

export interface IApprovalFlowNotifiedEntitySchema {
	identifier: string | null;
	type: TApprovalFlowNotifiedEntityType;
}

export class ApprovalFlowNotifiedEntityModel extends Record<typeof defaults>(defaults) {
	static fromServerData(data: unknown) {
		const { identifier, type } = data as IApprovalFlowNotifiedEntitySchema;

		return new ApprovalFlowNotifiedEntityModel({
			identifier: isNoIdentifierApprovalFlowNotifiedEntityType(type) ? type : identifier,
			type
		});
	}

	toServerData(): IApprovalFlowNotifiedEntitySchema {
		const { identifier, type } = this;
		return {
			identifier: isNoIdentifierApprovalFlowNotifiedEntityType(type) ? null : identifier,
			type
		};
	}

	toApprovalEntity(): ApprovalFlowEntityModel {
		const { identifier, type } = this;
		return new ApprovalFlowEntityModel({ identifier, type });
	}
}
