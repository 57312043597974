import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	durations: {
		display: "grid",
		gridTemplateColumns: "repeat(2, 1fr)",
		"@media (min-width: 1024px)": {
			gridTemplateColumns: "repeat(3, 1fr)"
		}
	},
	disabled: {
		opacity: 0.5
	}
});
