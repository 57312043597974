import classNames from "classnames";
import React from "react";
import { PriorityCell } from "components/common/Rules/RulesList/RuleCells";
import { Divider } from "components/ui/Divider";
import { PolicyModel } from "models/PolicyModel";
import { ChipsCell } from "./components/ChipsCell";
import { PolicyActionsCell } from "./components/PolicyActionsCell/PolicyActionsCell";
import { useStyles } from "./styles";

export type TPolicyBarProps = {
	policy: PolicyModel;
	onDelete: (policyId: string) => void;
	onEdit: (policy: PolicyModel) => void;
};
const PolicyBarComponent: FC<TPolicyBarProps> = ({ policy, onDelete, onEdit }) => {
	const classes = useStyles();

	return (
		<div key={policy.id} className={classNames(classes.barContainer, classes.policyBarContainer)}>
			<PriorityCell priority={policy.sortOrder} />
			<Divider vertical />
			<ChipsCell type="group" policy={policy} />
			<Divider vertical />
			<ChipsCell type="role" policy={policy} />
			<Divider vertical />
			<ChipsCell type="bundle" policy={policy} />
			<PolicyActionsCell policy={policy} onDelete={onDelete} onEdit={onEdit} />
		</div>
	);
};

export const PolicyBar = React.memo(PolicyBarComponent);
