import { List, Map as ImmutableMap } from "immutable";
import { useCallback, useMemo } from "react";
import { useIdentityGraphContext } from "components/pages/IdentityGraphPage/identityGraphContext";
import { useIntegrations } from "hooks/useIntegrations";
import { useMaxEntities } from "hooks/useMaxEntities";
import { useMultiUsers } from "hooks/useMultiUsers";
import { AccountVertexModel } from "models/IdentityGraph/AccountVertexModel";
import { RoleVertexModel } from "models/IdentityGraph/RoleVertexModel";
import type { UserVertexModel } from "models/IdentityGraph/UserVertexModel";
import type { UserModel } from "models/UserModel";

const DEFAULT_INCREASE = 4;
const MAX_LARGE_VERTICES = 10;
const MAX_ALLOWED_SHOW = 100;

const singularVertexSortField = (vertex: AccountVertexModel | UserVertexModel, user?: UserModel) => {
	if (vertex.type === "account") {
		return vertex.entity.accountName;
	} else if (user) {
		return user?.fullName;
	} else {
		return vertex.id;
	}
};

export const useSingularColumn = (vertices: List<AccountVertexModel | UserVertexModel>) => {
	const {
		maxEntities: maxVertices,
		increaseMax,
		decreaseMax
	} = useMaxEntities(MAX_LARGE_VERTICES, MAX_ALLOWED_SHOW, DEFAULT_INCREASE);
	const {
		actions: { clearVerticesLinesFromMap }
	} = useIdentityGraphContext();

	const vertexUserIds = useMemo(
		() => vertices?.map(vertex => (vertex.type === "user" ? vertex.entity.userId : undefined)).toArray() || [],
		[vertices]
	);
	const vertexUsers = useMultiUsers(vertexUserIds);

	const { showedVertices, extraVerticesIds } = useMemo(() => {
		if (!vertices) return { showedVertices: null, extraVerticesIds: null };
		const sortedVertices = vertices.sortBy(vertex =>
			singularVertexSortField(vertex, (vertex.type === "user" && vertexUsers?.get(vertex.entity.userId)) || undefined)
		);
		const showedVertices = sortedVertices.take(maxVertices).toArray();
		const extraVerticesIds = sortedVertices
			.skip(maxVertices)
			.map(vertex => vertex.id)
			.toArray();
		return { showedVertices, extraVerticesIds };
	}, [maxVertices, vertices, vertexUsers]);

	const increase = useCallback(() => {
		increaseMax();
		clearVerticesLinesFromMap(extraVerticesIds?.slice(0, DEFAULT_INCREASE) || []);
	}, [clearVerticesLinesFromMap, extraVerticesIds, increaseMax]);

	const decrease = useCallback(() => {
		decreaseMax();
		clearVerticesLinesFromMap(showedVertices?.slice(-DEFAULT_INCREASE).map(vertex => vertex.id) || []);
	}, [clearVerticesLinesFromMap, decreaseMax, showedVertices]);

	const showCollapse = useMemo(() => {
		if (!vertices) return false;
		return vertices.size <= maxVertices && maxVertices > MAX_LARGE_VERTICES;
	}, [vertices, maxVertices]);

	return {
		showedVertices,
		extraVerticesIds,
		increase,
		decrease,
		showCollapse,
		maxVertices,
		finalMaxEntities: MAX_ALLOWED_SHOW
	};
};

export const useCollectiveColumn = (collectiveVertices: ImmutableMap<string, List<RoleVertexModel>>) => {
	const { maxEntities, increaseMax, decreaseMax } = useMaxEntities(
		MAX_LARGE_VERTICES,
		MAX_ALLOWED_SHOW,
		DEFAULT_INCREASE
	);
	const {
		actions: { clearVerticesLinesFromMap }
	} = useIdentityGraphContext();

	const integrations = useIntegrations();

	const { showedCollectives, extraCollectives } = useMemo(() => {
		if (!collectiveVertices || !collectiveVertices.size) return { showedCollectives: null, extraCollectives: null };
		const sortedVertices = collectiveVertices.sortBy((_, key) => integrations?.get(key)?.name || key);
		const showedCollectives = sortedVertices.take(maxEntities);
		const extraCollectives = sortedVertices.skip(maxEntities);
		return { showedCollectives, extraCollectives };
	}, [maxEntities, collectiveVertices, integrations]);

	const increase = useCallback(() => {
		increaseMax();
		clearVerticesLinesFromMap(
			extraCollectives
				?.take(DEFAULT_INCREASE)
				.toList()
				.flatMap(vertices => vertices.map(vertex => vertex.id))
				.toArray() || []
		);
	}, [clearVerticesLinesFromMap, extraCollectives, increaseMax]);

	const decrease = useCallback(() => {
		decreaseMax();
		clearVerticesLinesFromMap(
			showedCollectives
				?.takeLast(DEFAULT_INCREASE)
				.toList()
				.flatMap(vertices => vertices.map(vertex => vertex.id))
				.toArray() || []
		);
	}, [clearVerticesLinesFromMap, decreaseMax, showedCollectives]);

	const showCollapse = useMemo(() => {
		if (!collectiveVertices) return false;
		return collectiveVertices.size <= maxEntities && maxEntities > MAX_LARGE_VERTICES;
	}, [collectiveVertices, maxEntities]);

	return {
		showedCollectives,
		extraCollectives,
		increase,
		decrease,
		showCollapse,
		maxEntities,
		clearVerticesLinesFromMap,
		finalMaxEntities: MAX_ALLOWED_SHOW
	};
};
