import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	actions: {
		alignItems: "flex-end",
		display: "flex",
		marginLeft: "auto"
	},
	bulkActionInProgressContainer: {
		display: "flex",
		flexDirection: "column",
		gap: "var(--spacing-x3, 12px)"
	},
	bulkActionChange: {
		display: "flex",
		flexDirection: "column",
		gap: "var(--spacing-x2, 8px)"
	},
	bulkActionInProgress: {
		"&$center": {
			justifyContent: "center",
			width: "100%"
		},
		alignItems: "center",
		display: "flex",
		flexDirection: "row",
		gap: "var(--spacing-x20, 80px)"
	},
	bulkActionLoader: {
		display: "flex",
		alignItems: "center",
		gap: "var(--spacing-x5, 20px)"
	},
	bulkActionChangeData: {
		display: "flex",
		alignItems: "center",
		gap: "var(--spacing-x5, 20px)"
	},
	center: {},
	container: {
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
		gap: "var(--spacing-x10, 40px)",
		height: "100%",
		position: "relative",
		width: "100%"
	},
	inputPart: {
		display: "flex",
		flexBasis: "0",
		flexDirection: "column",
		flexGrow: 1,
		gap: "var(--spacing-x3, 12px)",
		maxWidth: "452px",
		minWidth: "180px"
	},
	inputs: {
		display: "flex",
		flexDirection: "row",
		flexGrow: 1,
		flexWrap: "wrap",
		gap: "var(--spacing-x5, 20px)"
	},
	greenIcon: {
		color: "var(--color-green-400)"
	},
	redIcon: {
		color: "var(--color-red-700)"
	},
	wrapper: {
		backgroundColor: "var(--color-purple-100)",
		borderRadius: "12px",
		display: "flex",
		padding: "var(--spacing-x7, 28px) var(--spacing-x10, 40px)",
		width: "100%",
		minHeight: "158px"
	}
});
