import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useIntegrations } from "hooks/useIntegrations";
import { IntegrationSelectInput } from "../IntegrationSelectInput";
import { ResourceSelectInput } from "../ResourceSelectInput";
import { RoleSelectInput } from "../RoleSelectInput";
import type { TSubscriptionFunc } from "hooks/useSubscriber";
import type { IntegrationModel } from "models/IntegrationModel";
import type { IntegrationResourceModel } from "models/IntegrationResourceModel";
import type { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";

export const VirtualizedRoleSelectInputs: FC<{
	setVirtualizedRoleId: (value: string) => void;
	subscribeToClearForm?: TSubscriptionFunc;
}> = ({ setVirtualizedRoleId, subscribeToClearForm }) => {
	const { t } = useTranslation();
	const integrations = useIntegrations();
	const nonVirtualIntegrations = useMemo(() => integrations?.filter(({ virtual }) => !virtual) ?? null, [integrations]);
	const [virtualIntegration, setVirtualIntegration] = useState<IntegrationModel | null>(null);
	const [virtualIntegrationResource, setVirtualIntegrationResource] = useState<IntegrationResourceModel | null>(null);
	const [virtualIntegrationResourceRole, setVirtualIntegrationResourceRole] =
		useState<IntegrationResourceRoleModel | null>(null);

	const handleIntegrationChange = useCallback((value: IntegrationModel | null) => {
		if (value) {
			setVirtualIntegration(value);
			setVirtualIntegrationResource(null);
			setVirtualIntegrationResourceRole(null);
		}
	}, []);

	const handleResourceChange = useCallback((value: IntegrationResourceModel | null) => {
		if (value) {
			setVirtualIntegrationResource(value);
			setVirtualIntegrationResourceRole(null);
		}
	}, []);

	const handleRoleChange = useCallback(
		(value: IntegrationResourceRoleModel | null) => {
			if (value) {
				setVirtualizedRoleId(value.id);
				setVirtualIntegrationResourceRole(value);
			}
		},
		[setVirtualizedRoleId]
	);

	useEffect(() => {
		if (subscribeToClearForm) {
			subscribeToClearForm("virtualRoleSelectInput", () => {
				setVirtualIntegration(null);
				setVirtualIntegrationResource(null);
				setVirtualIntegrationResourceRole(null);
			});
		}
	}, [subscribeToClearForm]);

	return (
		<>
			<IntegrationSelectInput
				label={t("common.virtualizedRoleSelectInput.integrationLabel")}
				onChange={handleIntegrationChange}
				options={nonVirtualIntegrations}
				placeholder={t("common.integrationSelectInput.placeholder")}
				required
				value={virtualIntegration}
			/>
			<ResourceSelectInput
				inputLabel={t("common.virtualizedRoleSelectInput.resourceLabel")}
				onChange={handleResourceChange}
				placeholder={t("common.resourceSelectInput.placeholder")}
				required
				selected={virtualIntegrationResource}
				selectedIntegrationId={virtualIntegration?.id}
			/>
			<RoleSelectInput
				disabled={!virtualIntegrationResource}
				inputLabel={t("common.virtualizedRoleSelectInput.roleLabel")}
				integrationResourceId={virtualIntegrationResource?.id}
				onChange={handleRoleChange}
				placeholder={t("common.roleSelectInput.placeholder")}
				required
				selectedRole={virtualIntegrationResourceRole}
			/>
		</>
	);
};
