import classNames from "classnames";
import React, { HTMLAttributes, useMemo } from "react";
import { useStyles } from "./styles";

interface IProps {
	disabled?: boolean;
	size?: "xs" | "small" | "medium" | "large" | number;
	noBorder?: boolean;
	noWrap?: boolean;
}

const isReactElement = (
	element: React.ReactNode
): element is React.ReactElement<unknown, string | React.JSXElementConstructor<unknown>> => {
	return React.isValidElement(element);
};

export const LogoAvatar: FC<IProps> = ({
	disabled,
	noBorder,
	className,
	innerRef,
	children,
	size = "medium",
	noWrap = false
}) => {
	const classes = useStyles({ size: typeof size === "number" ? size : undefined });

	const sizeClassName = useMemo(() => {
		if (typeof size === "number") {
			return classes.customSize;
		}
		if (!Object.prototype.hasOwnProperty.call(classes, size)) return undefined;
		return size ? classes[size] : undefined;
	}, [size, classes]);
	const Logo = useMemo(() => {
		return noWrap
			? children
			: React.Children.map(children, child =>
					isReactElement(child)
						? React.cloneElement(child, {
								className: classNames(classes.logo, child?.props?.className)
							} as HTMLAttributes<HTMLElement>)
						: null
				)?.filter(Boolean);
	}, [children, classes.logo, noWrap]);

	return (
		<div
			ref={innerRef as React.Ref<HTMLDivElement>}
			className={classNames(classes.logoContainer, sizeClassName, className, {
				[classes.noBorder]: noBorder,
				[classes.disabled]: disabled
			})}>
			{Logo || null}
		</div>
	);
};
