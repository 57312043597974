import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "components/ui/Form";
import { Input } from "components/ui/Input";
import { removeRedundantSpaces } from "utils/strings";
import { CommonConfigFields } from "../CommonConfigFields";
import type { IConfigForm } from "types/directoryConfiguration";

const TRANSLATION_PREFIX = "pages.settings.directoryConfiguration.onelogin";

export const OneLoginConfigurationForm: FC<IConfigForm> = ({ onSave, error, enableSave, shouldReset, inEditMode }) => {
	const { t } = useTranslation("translation", { keyPrefix: TRANSLATION_PREFIX });
	const [clientId, setClientId] = useState("");
	const [clientSecret, setClientSecret] = useState("");
	const [region, setRegion] = useState("");
	const [managerField, setManagerField] = useState("");

	const isValid = useMemo(() => {
		return (
			enableSave &&
			!!(removeRedundantSpaces(clientSecret) && removeRedundantSpaces(clientId) && removeRedundantSpaces(region))
		);
	}, [clientId, clientSecret, enableSave, region]);

	const submit = useCallback(
		async (agentTokenId?: string | null, isDirectManagerSource?: boolean) => {
			if (!isValid) return;

			const configuration = {
				clientSecret,
				clientId,
				region,
				options: {
					// eslint-disable-next-line camelcase
					manager_field_name: managerField ? removeRedundantSpaces(managerField) : undefined
				}
			};
			await onSave(configuration, isDirectManagerSource, agentTokenId);
		},
		[isValid, clientSecret, clientId, region, managerField, onSave]
	);

	useEffect(() => {
		if (shouldReset) {
			setClientId("");
			setClientSecret("");
			setRegion("");
			setManagerField("");
		}
	}, [shouldReset]);

	return (
		<>
			<Form.Field>
				<Input label={t("clientId")} value={clientId} onValueChange={setClientId} isRequired />
			</Form.Field>
			<Form.Field>
				<Input label={t("clientSecret")} value={clientSecret} onValueChange={setClientSecret} isRequired />
			</Form.Field>
			<Form.Field>
				<Input label={t("region")} value={region} onValueChange={setRegion} isRequired />
			</Form.Field>
			<Form.Field>
				<Input
					label={t("managerField")}
					value={managerField}
					onValueChange={setManagerField}
					placeholder={t("managerFieldPlaceholder")}
				/>
			</Form.Field>
			<CommonConfigFields inEditMode={inEditMode} onSave={submit} error={error} disabled={!isValid} />
		</>
	);
};
