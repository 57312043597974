import classNames from "classnames";
import React from "react";
import { useStyles } from "./styles";
import { AccordionContext } from "../";

export const AccordionSummary: FC<{
	expandIcon: React.ReactElement;
}> = ({ children, className, expandIcon }) => {
	const classes = useStyles();

	const { disabled, expanded, onToggle } = React.useContext(AccordionContext);

	return (
		<div className={classNames(classes.summary, className, { [classes.disabled]: disabled })} onClick={onToggle}>
			<div className={classes.title}>{children}</div>
			<div
				className={classNames(classes.expandIcon, {
					[classes.expanded]: expanded
				})}>
				{expandIcon}
			</div>
		</div>
	);
};
