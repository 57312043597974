import { createUseStyles } from "react-jss";
import { BP_MEDIA_QUERIES } from "utils/ui/breakpoints";

export const useStyles = createUseStyles({
	range: {
		display: "grid",
		gridTemplateColumns: "1fr",
		gap: "1rem",
		[BP_MEDIA_QUERIES.FORTY]: {
			gridTemplateColumns: "1fr 1fr"
		}
	}
});
