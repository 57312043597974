import classNames from "classnames";
import React, { useCallback, useMemo } from "react";
import { useStyles } from "./styles";
import type { TBaseEntityNodeProps } from "components/common/entities/types";

export type TEntityOverrideProps = {
	size: "medium";
	variant?: Exclude<TBaseEntityNodeProps["variant"], "link">;
	inactive?: boolean;
};

type TSelectOptionProps<T> = {
	disabled?: boolean;
	isSelected?: boolean;
	onSelect: (event: React.SyntheticEvent, value: T) => void;
	readonly?: boolean;
	value: T;
} & (
	| {
			renderOption: (entityProps: TEntityOverrideProps) => React.ReactNode;
			PrefixIcon?: never;
	  }
	| { PrefixIcon?: IconComponent; renderOption?: never }
);

export const SelectOption = <T,>({
	children,
	PrefixIcon,
	renderOption,
	className,
	disabled = false,
	isSelected = false,
	onSelect,
	readonly = false,
	value
}: TProps<TSelectOptionProps<T>>) => {
	const classes = useStyles();

	const onClick = useCallback(
		(event: React.MouseEvent) => {
			if (!disabled) {
				onSelect(event, value);
			}
		},
		[disabled, onSelect, value]
	);

	const entityProps: TEntityOverrideProps = useMemo(
		() => ({ size: "medium", variant: isSelected ? "bold" : undefined, inactive: disabled }),
		[isSelected, disabled]
	);

	return (
		<div
			onClick={onClick}
			className={classNames(
				classes.option,
				{
					[classes.disabled]: disabled,
					[classes.selected]: isSelected,
					[classes.readonly]: readonly
				},
				className
			)}>
			{PrefixIcon && <PrefixIcon size={20} />}
			{renderOption && renderOption(entityProps)}
			{children}
		</div>
	);
};
