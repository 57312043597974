import { matchSorter, MatchSorterOptions } from "match-sorter";
import { useCallback, useState } from "react";
import type { TTargetValue } from "components/ui/Select";

export const useRankedSort = <T>(sortOptions: MatchSorterOptions<T>) => {
	const [query, setQuery] = useState("");

	const onInputChange = useCallback((event: TTargetValue | React.ChangeEvent<HTMLInputElement>) => {
		setQuery(event.target.value);
	}, []);

	const sort = useCallback((options: T[]) => matchSorter(options, query, sortOptions), [query, sortOptions]);

	return { sort, onInputChange, query };
};
