import React, { useMemo } from "react";
import { GroupIcon } from "components/ui/Icons/GroupIcon";
import { ON_CALL_INTEGRATIONS_LOGOS } from "utils/ui/onCall";
import { EntityNodeContent } from "../components/EntityNodeContent";
import { DeletedIndicator } from "../components/indicators/DeletedIndicator";
import { EntityLabel } from "../EntityLabel";
import type { TOnCallType } from "models/OnCallIntegrationScheduleModel";
import type { TBaseEntityNodeProps } from "../types";

type TProps = TBaseEntityNodeProps & {
	withIcon?: boolean;
	onCallIntegrationSchedule: { type?: TOnCallType; name: string; isDeleted?: boolean };
};

export const OnCallIntegrationScheduleEntity: FC<TProps> = ({
	onCallIntegrationSchedule,
	withIcon,
	size,
	className,
	variant,
	inactive: propInactive,
	...entityNodeProps
}) => {
	const indicators = useMemo(() => {
		const indicators: React.ReactNode[] = [];
		if (onCallIntegrationSchedule.isDeleted) {
			indicators.push(<DeletedIndicator />);
		}
		return indicators;
	}, [onCallIntegrationSchedule.isDeleted]);

	const inactive = propInactive || onCallIntegrationSchedule.isDeleted;

	const Icon = useMemo(
		() => (onCallIntegrationSchedule.type ? ON_CALL_INTEGRATIONS_LOGOS.get(onCallIntegrationSchedule.type) : GroupIcon),
		[onCallIntegrationSchedule.type]
	);
	const content = useMemo(() => {
		return (
			<EntityNodeContent
				tooltipOnOverflow
				text={onCallIntegrationSchedule.name}
				inactive={inactive}
				withIcon={withIcon}
				size={size}
				icon={Icon && <Icon />}
				variant={variant}
				{...entityNodeProps}
			/>
		);
	}, [onCallIntegrationSchedule.name, Icon, withIcon, size, variant, entityNodeProps, inactive]);

	return <EntityLabel content={content} size={size} indicators={indicators} className={className} />;
};
