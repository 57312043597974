import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	teamsLayout: {
		height: "100vh",
		width: "100vw",
		display: "flex",
		color: "var(--color-white)",
		justifyContent: "center",
		alignItems: "center"
	}
});
