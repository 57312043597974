import React, { useCallback } from "react";
import { IntegrationEntity } from "components/common/entities";
import { IntegrationModel } from "models/IntegrationModel";
import { SelectOption, type TEntityOverrideProps } from "../SelectOption";
import type { TOptionComponent } from "utils/ui/select";

const IntegrationOption: TOptionComponent<IntegrationModel> = ({
	disabled = false,
	isSelected = false,
	onSelect,
	option,
	optionKey
}) => {
	const renderOption = useCallback(
		(entityProps: TEntityOverrideProps) => (
			<IntegrationEntity withIcon noWrap relative integration={option} {...entityProps} />
		),
		[option]
	);

	return (
		<SelectOption
			disabled={disabled}
			isSelected={isSelected}
			key={optionKey}
			onSelect={onSelect}
			value={option}
			renderOption={renderOption}
		/>
	);
};

IntegrationOption.isSelectOption = true;

export { IntegrationOption };
