import React from "react";
import { makeIcon } from "components/ui/Icon";

export const ChevronLeftIcon = makeIcon(
	<svg viewBox="0 0 24 24" fill="none">
		<path
			d="M13.3 17.3L8.70005 12.7C8.60005 12.6 8.52922 12.4917 8.48755 12.375C8.44588 12.2584 8.42505 12.1334 8.42505 12C8.42505 11.8667 8.44588 11.7417 8.48755 11.625C8.52922 11.5084 8.60005 11.4 8.70005 11.3L13.3 6.70005C13.4834 6.51672 13.7167 6.42505 14 6.42505C14.2834 6.42505 14.5167 6.51672 14.7 6.70005C14.8834 6.88338 14.975 7.11672 14.975 7.40005C14.975 7.68338 14.8834 7.91672 14.7 8.10005L10.8 12L14.7 15.9C14.8834 16.0834 14.975 16.3167 14.975 16.6C14.975 16.8834 14.8834 17.1167 14.7 17.3C14.5167 17.4834 14.2834 17.575 14 17.575C13.7167 17.575 13.4834 17.4834 13.3 17.3Z"
			fill="currentColor"
		/>
	</svg>
);
