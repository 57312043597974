import { createUseStyles } from "react-jss";
import type { TIconPrefixSize } from "./IconPrefix";

const getGapBySize = (size: TIconPrefixSize) => {
	switch (size) {
		case "tiny":
			return "var(--spacing-x1, 4px)";
		case "small":
			return "var(--spacing-x2, 8px)";
		case "medium":
		case "large":
		case "huge":
			return "var(--spacing-x3, 12px)";
	}
};

type TStylesProps = {
	disabled?: boolean;
	size: TIconPrefixSize;
};

export const useStyles = createUseStyles({
	container: {
		alignItems: "center",
		color: ({ disabled }: TStylesProps) => (disabled ? "var(--color-grey-500)" : "currentcolor"),
		display: "flex",
		gap: ({ size }: TStylesProps) => getGapBySize(size),
		minWidth: 0
	}
});
