import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ApprovalAlgorithm } from "components/common/ApprovalAlgorithm";
import { DeletedIndicator } from "components/common/entities/components/indicators/DeletedIndicator";
import { WorkflowsIcon } from "components/ui/Icons/WorkflowsIcon";
import { Tooltip } from "components/ui/Tooltip";
import { useApprovalAlgorithms } from "hooks/useApprovalAlgorithms";
import { useApprovalFlows } from "hooks/useApprovalFlows";
import { ApprovalAlgorithmModel } from "models/ApprovalAlgorithmModel";
import { useStyles } from "./styles";
import { Chip, TChipSize, TChipVariant } from "../Chip";
import { SkeletonChip } from "../SkeletonChip";
import type { PopperOptions } from "react-popper-tooltip";

interface IWorkflowChipProps {
	value: ApprovalAlgorithmModel | string;
	readonly?: boolean;
	size?: TChipSize;
	variant: TChipVariant;
	selected?: boolean;
	onDelete?: () => void;
}

const POPPER_OPTIONS: PopperOptions = { placement: "bottom-start" };
export const WorkflowChip: FC<IWorkflowChipProps> = ({
	innerRef,
	className,
	value,
	size = "medium",
	readonly = false,
	variant = "regular",
	selected = false,
	onDelete
}) => {
	const classes = useStyles();
	const approvalAlgorithms = useApprovalAlgorithms();
	const approvalFlows = useApprovalFlows();
	const currentApprovalAlgorithm = typeof value === "string" ? approvalAlgorithms?.get(value) : value;

	const openWorkflowEditor = useCallback(() => {
		const url = "/workflows/edit/" + currentApprovalAlgorithm!.id;
		window.open(url, "_blank");
	}, [currentApprovalAlgorithm]);

	const inactive = currentApprovalAlgorithm?.isDeleted;

	const PrefixIcon = useMemo(() => (inactive ? DeletedIndicator : WorkflowsIcon), [inactive]);

	if (!approvalFlows || !currentApprovalAlgorithm) {
		return <SkeletonChip size={size} />;
	}

	return (
		<Tooltip
			className={classes.tooltipContainer}
			popperOptions={POPPER_OPTIONS}
			content={
				<ApprovalAlgorithm
					className={classes.tooltipContentContainer}
					key={currentApprovalAlgorithm.id}
					algorithm={currentApprovalAlgorithm}
					approvalFlows={approvalFlows}
					onEdit={openWorkflowEditor}
					withDelete={false}
				/>
			}>
			<Chip
				noTooltip
				inactive={inactive}
				readonly={readonly}
				variant={variant}
				size={size}
				PrefixIcon={PrefixIcon}
				onDelete={onDelete}
				selected={selected}
				className={className}
				innerRef={innerRef}>
				{currentApprovalAlgorithm.name}
			</Chip>
		</Tooltip>
	);
};

export const InheritWorkflowChip: FC<{
	size?: TChipSize;
	variant?: TChipVariant;
	selected?: boolean;
	noTooltip?: boolean;
	onDelete?: () => void;
	from: "integration" | "resource";
}> = React.memo(function InheritWorkflowChip({ size, variant, selected, from, onDelete, noTooltip }) {
	const { t } = useTranslation("translation", { keyPrefix: "shared" });
	const content = t(from === "integration" ? "integrationDefault" : "resourceDefault");
	const chip = useMemo(
		() => (
			<Chip noTooltip size={size} variant={variant} PrefixIcon={WorkflowsIcon} selected={selected} onDelete={onDelete}>
				{content}
			</Chip>
		),
		[variant, size, selected, onDelete, content]
	);
	if (noTooltip) {
		return chip;
	}
	return (
		<Tooltip popperOptions={POPPER_OPTIONS} content={content}>
			{chip}
		</Tooltip>
	);
});
