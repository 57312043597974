import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	userName: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		gap: "var(--spacing-x3, 12px)",
		background: "var(--color-white)"
	}
});
