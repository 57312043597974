import classNames from "classnames";
import React, { useCallback } from "react";
import { TChipSize } from "components/ui/chips/Chip";
import { ExternalLinkChip } from "components/ui/chips/ExternalLinkChip";
import { getDynamicSizeIcon } from "components/ui/dynamicSizeIcon";
import { TicketingIntegrationTicketModel } from "models/TicketingIntegrationTicketModel";
import { getTicketingIntegrationLogoByType } from "utils/tickets/ticketingIntegrationLogos";
import { useStyles } from "./styles";

export const TicketingIntegrationTicketChip: FC<{
	ticketingIntegrationTicket: TicketingIntegrationTicketModel;
	size?: TChipSize;
	label?: React.JSX.Element;
}> = ({ ticketingIntegrationTicket, className, size = "medium", label = null }) => {
	const classes = useStyles();
	const Logo = getTicketingIntegrationLogoByType(ticketingIntegrationTicket.ticketingIntegration)!;
	const TicketingSystemIcon = getDynamicSizeIcon(<Logo />);

	const openTicketUrl = useCallback(
		(e: React.MouseEvent) => {
			e.stopPropagation();
			window.open(ticketingIntegrationTicket.url, "_blank");
		},
		[ticketingIntegrationTicket]
	);

	const text = `${ticketingIntegrationTicket.projectName} - ${ticketingIntegrationTicket.name}`;

	return (
		<div className={classNames(classes.ticketingIntegrationTicketChipContainer, className)}>
			{label}
			<ExternalLinkChip
				className={classes.ticketingIntegrationTicketChip}
				variant="regular"
				size={size}
				PrefixIcon={TicketingSystemIcon}
				onSuffixIconClick={openTicketUrl}
				onChipClick={openTicketUrl}>
				{text}
			</ExternalLinkChip>
		</div>
	);
};
