import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { DeletedIndicator } from "components/common/entities/components/indicators/DeletedIndicator";
import { getDynamicSizeIcon } from "components/ui/dynamicSizeIcon";
import { MaintainersIcon } from "components/ui/Icons/MaintainersIcon";
import { OwnerIcon } from "components/ui/Icons/OwnerIcon";
import { TitleBody } from "components/ui/TitleBody";
import { Tooltip } from "components/ui/Tooltip";
import { getDirectoryGroupInfo } from "hooks/useGroupInfo";
import { DirectoryGroupModel } from "models/DirectoryGroupModel";
import { OnCallIntegrationScheduleModel } from "models/OnCallIntegrationScheduleModel";
import { ON_CALL_INTEGRATIONS_LOGOS } from "utils/ui/onCall";
import { useStyles } from "./styles";
import { Chip, TChipVariant, TChipSize } from "../Chip";
import type { TEntityOption } from "utils/entityOptionType";

interface IGroupChipProps {
	value: DirectoryGroupModel | OnCallIntegrationScheduleModel | TEntityOption;
	icon?: IconComponent;
	readonly?: boolean;
	size?: TChipSize;
	variant?: TChipVariant;
	selected?: boolean;
	onDelete?: () => void;
}

const MAINTAINER_TEXT = "maintainer";
const OWNER_TEXT = "owner";

export const GroupChip: FC<IGroupChipProps> = ({
	innerRef,
	className,
	value,
	icon,
	size = "medium",
	readonly = false,
	selected = false,
	variant = "regular",
	onDelete
}) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const { displayName, type, GroupIcon } = useMemo(() => {
		const checkRegexForDisplayName = (displayName: string) => {
			const { Icon, name, sourceTranslationKey } = getDirectoryGroupInfo(displayName);
			return {
				displayName: name,
				GroupIcon: Icon
					? getDynamicSizeIcon(<Icon className={size === "small" ? classes.smallIconSize : classes.regularIconSize} />)
					: undefined,
				type: sourceTranslationKey ? t(sourceTranslationKey, { context: "capital" }) : undefined
			};
		};
		if (value instanceof DirectoryGroupModel) {
			return checkRegexForDisplayName(value.name);
		} else if (value instanceof OnCallIntegrationScheduleModel) {
			return {
				displayName: value.name,
				GroupIcon: value.type ? ON_CALL_INTEGRATIONS_LOGOS.get(value.type) : undefined,
				type: value.type ? t(`common.groups.${value.type}`, { context: "capital" }) : undefined
			};
		}
		const { displayName, GroupIcon, type } = checkRegexForDisplayName(
			value.displayName || t("common.approvalFlow.deletedOnCallEntitySchedule")
		);
		return {
			displayName,
			GroupIcon:
				GroupIcon ?? (value.onCallScheduleType ? ON_CALL_INTEGRATIONS_LOGOS.get(value.onCallScheduleType) : undefined),
			type
		};
	}, [classes.regularIconSize, classes.smallIconSize, size, value, t]);

	const iconType = useMemo(() => {
		if (!GroupIcon && displayName?.toLocaleLowerCase().includes(MAINTAINER_TEXT)) {
			return MaintainersIcon;
		} else if (!GroupIcon && displayName.toLocaleLowerCase().includes(OWNER_TEXT)) {
			return OwnerIcon;
		} else {
			return icon;
		}
	}, [GroupIcon, displayName, icon]);

	const inactive = value.isDeleted;
	const PrefixIcon = useMemo(
		() => (inactive ? DeletedIndicator : GroupIcon || iconType),
		[inactive, GroupIcon, iconType]
	);

	const tooltipContent = useMemo(
		() => (
			<div className={classes.tooltip}>
				<TitleBody size="small" title={type} body={displayName} />
			</div>
		),
		[type, displayName, classes.tooltip]
	);

	return (
		<Tooltip content={tooltipContent}>
			<Chip
				noTooltip
				readonly={readonly}
				variant={variant}
				size={size}
				inactive={inactive}
				selected={selected}
				PrefixIcon={PrefixIcon}
				className={className}
				innerRef={innerRef}
				onDelete={onDelete}>
				{displayName}
			</Chip>
		</Tooltip>
	);
};
