import { AbortError } from "utils/errors/abortError";
import { apiReq, type TBody, type TMethod, type TOptions, type TResult } from "./apiReq";

const requests = new Map<string, AbortController[]>();

const isAbortError = (error: unknown): boolean =>
	error instanceof Error && error.name === "Error" && error.message === "Request aborted";

// Make sure you catch the error / use ignoreAbortError to handle the error
export async function apiReqAbortOld({
	key,
	method,
	path,
	body = null,
	options = {},
	wrapAbortError = true
}: {
	key: string;
	method: TMethod;
	path: string;
	body?: TBody;
	options?: TOptions;
	wrapAbortError?: boolean;
}): Promise<TResult> {
	if (!requests.has(key)) requests.set(key, []);
	requests.get(key)?.forEach(controller => controller.abort());

	const controller = new AbortController();
	requests.get(key)?.push(controller);
	try {
		const response = await apiReq(method, path, body, { ...options, abortSignal: controller.signal, noCache: true });
		requests.get(key)?.splice(requests.get(key)!.indexOf(controller), 1);
		return response;
	} catch (error) {
		requests.get(key)?.splice(requests.get(key)!.indexOf(controller), 1);
		if (wrapAbortError && isAbortError(error)) {
			throw new AbortError("Request aborted");
		}
		throw error;
	}
}
