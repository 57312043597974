import { Record } from "immutable";
import uniq from "lodash/uniq";
import {
	getMaintainerPossibleValue,
	validateFilterEqualityOperator,
	validateFilterInclusionOperator
} from "utils/validation/filterValidation";
import type { IFilter } from "types/filters";

const filterName = "integrationResourceMaintainer";

const defaults = {
	name: filterName as typeof filterName,
	value: [] as string[] | null,
	operator: "contains" as "contains" | "is" | "isNot" | "notContains"
};

type TDefaults = typeof defaults;

export class IntegrationResourceMaintainerFilter
	extends Record<TDefaults>(defaults)
	implements IFilter<TDefaults["value"], TDefaults["operator"], TDefaults["name"]>
{
	static filterName: typeof filterName = filterName;

	static fromURLSearchParams(urlSearchParams: URLSearchParams): IntegrationResourceMaintainerFilter | null {
		const integrationResourceMaintainer = uniq(urlSearchParams.getAll("integrationResourceMaintainer"));
		const integrationResourceMaintainerOperator =
			urlSearchParams.get("integrationResourceMaintainerOperator") || undefined;
		if (
			integrationResourceMaintainer.length === 0 ||
			!(
				validateFilterEqualityOperator(integrationResourceMaintainerOperator) ||
				validateFilterInclusionOperator(integrationResourceMaintainerOperator)
			)
		) {
			return null;
		}
		return new IntegrationResourceMaintainerFilter({
			value: getMaintainerPossibleValue(integrationResourceMaintainer),
			operator: integrationResourceMaintainerOperator
		});
	}

	toURLSearchParams(): URLSearchParams {
		const params = new URLSearchParams();
		if (this.value === null) {
			params.append("integrationResourceMaintainer", "null");
		} else if (!this.value.length) {
			return params;
		} else {
			for (const value of uniq(this.value)) {
				params.append("integrationResourceMaintainer", value);
			}
		}
		params.append("integrationResourceMaintainerOperator", this.operator);
		return params;
	}
}
