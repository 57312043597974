import constate from "constate";
import { cloneDeep } from "lodash";
import isNumber from "lodash/isNumber";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { COMPACT_ROW_HEIGHT_PX, DEFAULT_ROW_HEIGHT_PX } from "./components";
import type { List } from "immutable";
import type { TResizeableColumnOptions, TVirtualTableWidth } from "./types";

export interface IVirtualTableContextProps {
	compact: boolean;
	rowHeight: typeof COMPACT_ROW_HEIGHT_PX | typeof DEFAULT_ROW_HEIGHT_PX;
	selectable: boolean;
	resizeable: boolean;
	columnsWidthOptions: List<TVirtualTableWidth>;
}

const useVirtualTable = ({
	compact,
	rowHeight,
	selectable,
	resizeable = false,
	columnsWidthOptions: propColumnsWidthOptions
}: IVirtualTableContextProps) => {
	const tableContainerRef = useRef<HTMLDivElement>(null);
	const [resizeIndex, setResizeIndex] = useState(-1);
	const [columnsWidthOptions, setColumnsWidthOptions] = useState<List<TVirtualTableWidth>>(propColumnsWidthOptions);

	const changeColumnWidth = useCallback((index: number, width: number) => {
		setColumnsWidthOptions(prev => {
			const newItem = { ...(prev.get(index) as TResizeableColumnOptions), width };
			return prev.splice(index, 1, newItem);
		});
	}, []);

	const gridTemplateColumns = useMemo(
		() =>
			columnsWidthOptions
				.map((widthOptions, index) => {
					if (
						isNumber(widthOptions) ||
						typeof widthOptions === "string" ||
						("width" in widthOptions && widthOptions.width)
					) {
						let width;
						if (isNumber(widthOptions)) {
							width = `${widthOptions}px`;
						} else if (typeof widthOptions === "string") {
							width = widthOptions;
						} else if ("width" in widthOptions && widthOptions.width) {
							width = `${widthOptions.width}px`;
						}

						return index === columnsWidthOptions.size - 1 && resizeable ? `minmax(${width}, 1fr)` : width;
					}

					const min = "min" in widthOptions ? widthOptions.min : 0;
					const max =
						"max" in widthOptions && (index !== columnsWidthOptions.size - 1 || !resizeable) ? widthOptions.max : "1fr";
					return `minmax(${min}px, ${isNumber(max) ? `${max}px` : max})`;
				})
				.join(" "),
		[columnsWidthOptions, resizeable]
	);

	useEffect(
		() => setColumnsWidthOptions(propColumnsWidthOptions.map(column => cloneDeep(column))),
		[propColumnsWidthOptions]
	);

	const store = useMemo(
		() => ({
			state: {
				compact,
				tableContainerRef,
				rowHeight,
				gridTemplateColumns,
				selectable,
				resizeable,
				columnsWidths: columnsWidthOptions,
				resizeIndex
			},
			actions: { changeColumnWidth, setResizeIndex }
		}),
		[
			compact,
			rowHeight,
			gridTemplateColumns,
			selectable,
			resizeable,
			columnsWidthOptions,
			resizeIndex,
			changeColumnWidth
		]
	);

	return store;
};

export const [VirtualTableProvider, useVirtualTableContext] = constate(useVirtualTable);
