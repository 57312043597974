import { createUseStyles } from "react-jss";

export type TNodeColor = "green" | "red" | "purple" | "magenta" | "blue";

interface INodeTheme {
	primaryColor: string;
	boldColor: string;
	hoverColor?: string;
	deletedColor?: string;
}

const generateNodeTheme = (
	primaryColor: string,
	boldColor: string,
	hoverColor?: string,
	deletedColor?: string
): INodeTheme => ({ primaryColor, boldColor, hoverColor, deletedColor });

const GREEN_NODE_THEME = generateNodeTheme(
	"var(--color-green-400)",
	"var(--color-green-500)",
	"var(--color-green-200)"
);
const RED_NODE_THEME = generateNodeTheme("var(--color-red-700)", "var(--color-red-dark)");
const PURPLE_NODE_THEME = generateNodeTheme(
	"var(--color-purple-600)",
	"var(--color-purple-700)",
	"var(--color-purple-200)",
	"var(--color-purple-500)"
);
const MAGENTA_NODE_THEME = generateNodeTheme(
	"var(--color-magenta-500)",
	"var(--color-magenta-700)",
	"var(--color-magenta-200)"
);
const BLUE_NODE_THEME = generateNodeTheme("var(--color-blue-500)", "var(--color-blue-dark)", "var(--color-blue-200)");

const THEMES = new Map<TNodeColor, INodeTheme>([
	["green", GREEN_NODE_THEME],
	["red", RED_NODE_THEME],
	["purple", PURPLE_NODE_THEME],
	["magenta", MAGENTA_NODE_THEME],
	["blue", BLUE_NODE_THEME]
]);

export const ENTITY_NODE_HEIGHT = 55;

export const useStyles = createUseStyles(
	({
		color,
		borderRadius: propBorderRadius,
		iconAsImg
	}: {
		color: TNodeColor;
		borderRadius?: number;
		iconAsImg?: boolean;
	}) => {
		const theme = THEMES.get(color)!;
		const deletedColor = theme.deletedColor || theme.primaryColor;
		const borderRadius = propBorderRadius ? `${propBorderRadius}px` : undefined;

		return {
			alignedContent: {
				alignItems: "center",
				display: "flex",
				flexDirection: "row",
				gap: "4px",
				minWidth: 0
			},
			container: {
				alignItems: "center",
				alignSelf: "stretch",
				backgroundColor: "var(--color-white)",
				border: `3px solid ${theme.primaryColor}`,
				borderLeftWidth: "0px",
				borderRadius,
				color: "var(--color-black)",
				display: "flex",
				flexDirection: "row",
				height: `${ENTITY_NODE_HEIGHT}px`,
				minWidth: "160px",
				maxWidth: "220px",
				position: "relative",
				"&$fixedWidth": {
					maxWidth: "160px"
				},
				"&:hover": {
					backgroundColor: theme.hoverColor || "inherit"
				},
				"&$selected": {
					backgroundColor: theme.primaryColor,
					color: "var(--color-white)",
					"&:hover": {
						backgroundColor: theme.boldColor
					}
				},
				"&$deleted": {
					borderColor: deletedColor,
					color: "var(--color-grey-600)",
					"& > $iconContainer": {
						color: deletedColor,
						borderColor: deletedColor
					},
					"& img": {
						opacity: "50%"
					},
					"& > $iconContainer > svg:first-child, & > $iconContainer > div:first-child > svg": {
						opacity: iconAsImg ? "50%" : "unset"
					},
					"&$selected": {
						backgroundColor: deletedColor,
						color: theme.hoverColor || "var(--color-white)",
						"& > $iconContainer": {
							color: deletedColor
						},
						"&:hover": {
							backgroundColor: theme.primaryColor
						}
					}
				}
			},
			contentContainer: {
				minWidth: 0,
				padding: "8px 12px",
				width: "100%"
			},
			contentWithActions: {
				alignItems: "center",
				display: "flex",
				flexDirection: "row",
				justifyContent: "space-between"
			},
			iconContainer: {
				alignItems: "center",
				backgroundColor: "var(--color-white)",
				border: `3px solid ${theme.primaryColor}`,
				borderRadius,
				color: theme.primaryColor,
				display: "flex",
				flexShrink: 0,
				height: `${ENTITY_NODE_HEIGHT}px`,
				justifyContent: "center",
				padding: "5px",
				width: `${ENTITY_NODE_HEIGHT}px`,
				position: "relative"
			},
			icon: {
				fontSize: "32px"
			},
			deletedLinesIcon: {
				position: "absolute",
				top: "0",
				left: "0",
				padding: "inherit",
				height: "100%",
				width: "100%"
			},
			tooltip: {
				borderRadius: "6px",
				padding: 0
			},
			clickable: {
				"&:hover": {
					cursor: "pointer"
				}
			},
			deleteButton: {
				position: "absolute",
				right: "-12px",
				top: "-20px"
			},
			deleted: {},
			fixedWidth: {},
			selected: {}
		};
	}
);
