import classNames from "classnames";
import isEqual from "lodash/isEqual";
import React, { memo, useMemo } from "react";
import { SCROLLBAR_SIZE_PX } from "globalStylesVariables";
import { ResizeableCellWrapper } from "./ResizeableCellWrapper";
import { useStyles } from "./styles";
import { useVirtualTableContext } from "../../virtualTableContext";
import type { TColumn } from "../../types";

const TableHeader = <T extends object>({
	columns,
	hasVerticalScroll,
	headerRef
}: {
	columns: TColumn<T>[];
	hasVerticalScroll: boolean;
	headerRef: React.RefObject<HTMLDivElement>;
}) => {
	const classes = useStyles();

	const {
		state: { selectable, gridTemplateColumns, resizeable }
	} = useVirtualTableContext();

	const gridStyle = useMemo(
		() => ({
			gridTemplateColumns: hasVerticalScroll ? `${gridTemplateColumns} ${SCROLLBAR_SIZE_PX}px` : gridTemplateColumns
		}),
		[hasVerticalScroll, gridTemplateColumns]
	);

	return (
		<div className={classes.header} ref={headerRef}>
			<div className={classNames(classes.row, { [classes.selectable]: selectable })} style={gridStyle}>
				{columns.map((column, index) => (
					<div
						key={column.key}
						className={classNames(classes.cell, column.headerContainerClassName, { [classes.resizeable]: resizeable })}>
						{resizeable ? (
							<ResizeableCellWrapper columnIndex={index} isLastColumn={index === columns.length - 1}>
								{column.header}
							</ResizeableCellWrapper>
						) : (
							column.header
						)}
					</div>
				))}
				{hasVerticalScroll && <div className={classNames(classes.cell, classes.scrollPlaceholder)} />}
			</div>
		</div>
	);
};

const HeadersMemo = memo(TableHeader, isEqual) as typeof TableHeader;

export { HeadersMemo as TableHeader };
