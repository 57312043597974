import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { IconButton } from "components/ui/IconButton";
import { AddIcon } from "components/ui/Icons/AddIcon";
import { Typography } from "components/ui/Typography";
import { routes } from "routes/routes";
import { useStyles } from "./styles";

export const NewRequestSection: FC = () => {
	const classes = useStyles();
	const { t } = useTranslation();

	const navigate = useNavigate();

	const handleClick = useCallback(() => {
		navigate(routes.newTicket.main);
	}, [navigate]);

	return (
		<div className={classes.section}>
			<IconButton variant="primary" className={classes.button} onClick={handleClick} size="large">
				<div className={classes.buttonContent}>
					<AddIcon />
					<Typography variant="text_title_sb">{t("navigation.newTicket")}</Typography>
				</div>
			</IconButton>
		</div>
	);
};
