import classNames from "classnames";
import React from "react";
import { Transitionable } from "components/ui/SwitchTransitions/";
import { useStyles } from "./styles";

export const TransitionablePage: FC = ({ className, children, id }) => {
	const classes = useStyles();

	return (
		<Transitionable id={id || ""} className={classNames(className, classes.page)}>
			{children}
		</Transitionable>
	);
};
