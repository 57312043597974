import constate from "constate";
import { useCallback } from "react";
import { addAllowedDuration, editCompany, getCompany, removeAllowedDuration } from "api/company";
import {
	removeHRIntegration as apiRemoveHRIntegration,
	removeTicketingIntegration as apiRemoveTicketingIntegration,
	removeOnCallIntegration as apiRemoveOnCallIntegration,
	THRType
} from "api/companyExternalIntegrations";
import { useFetchedState } from "hooks/useFetchedState";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import type { TRoutineReminderSchedule } from "models/CompanyModel";
import type { TOnCallType } from "models/OnCallIntegrationScheduleModel";
import type { TTicketingIntegrationType } from "models/TicketingIntegrationTicketModel";
import type { TTicketDuration } from "utils/durationsOptions";

const useCompany = () => {
	const { data: company, setData: setCompany, loadData: loadCompany } = useFetchedState(getCompany);

	const openGlobalErrorModal = useOpenGlobalErrorModal();

	const getModel = useCallback(async () => {
		if (company) return company;
		try {
			return await getCompany();
		} catch (error) {
			openGlobalErrorModal(error as Error);
			return;
		}
	}, [company, openGlobalErrorModal]);

	const integrateWithOnCallService = useCallback(
		(onCallService: TOnCallType) => {
			setCompany(
				current => current?.set("integratedToOnCalls", current.integratedToOnCalls.push(onCallService)) || null
			);
		},
		[setCompany]
	);

	const removeOnCallIntegration = useCallback(
		async (onCallService: TOnCallType) => {
			await apiRemoveOnCallIntegration(onCallService);

			setCompany(current =>
				current
					? current.set(
							"integratedToOnCalls",
							current.integratedToOnCalls.filterNot(value => value === onCallService)
						)
					: null
			);
		},
		[setCompany]
	);

	const integrateWithHrSystem = useCallback(
		(hrSystem: THRType) => {
			setCompany(current => current?.set("integratedToHRs", current?.integratedToHRs.push(hrSystem)) || null);
		},
		[setCompany]
	);

	const integrateWithTicketingIntegration = useCallback(
		(ticketingIntegration: TTicketingIntegrationType) => {
			setCompany(
				current =>
					current?.set(
						"integratedToTicketingIntegrations",
						current?.integratedToTicketingIntegrations.push(ticketingIntegration)
					) || null
			);
		},
		[setCompany]
	);

	const removeHrIntegration = useCallback(
		async (hrSystem: THRType) => {
			await apiRemoveHRIntegration(hrSystem);
			setCompany(current =>
				current
					? current.set(
							"integratedToHRs",
							current.integratedToHRs.filterNot(value => value === hrSystem)
						)
					: null
			);
		},
		[setCompany]
	);

	const removeTicketingIntegration = useCallback(
		async (ticketingIntegration: TTicketingIntegrationType) => {
			await apiRemoveTicketingIntegration(ticketingIntegration);
			setCompany(current =>
				current
					? current.set(
							"integratedToTicketingIntegrations",
							current.integratedToTicketingIntegrations.filterNot(value => value === ticketingIntegration)
						)
					: null
			);
		},
		[setCompany]
	);

	const addDuration = useCallback(
		(duration: TTicketDuration) => {
			setCompany(current => current?.set("allowedDurations", current?.allowedDurations.push(duration)) || null);
		},
		[setCompany]
	);

	const removeDuration = useCallback(
		(duration: TTicketDuration) => {
			setCompany(
				current =>
					current?.set(
						"allowedDurations",
						current?.allowedDurations.filterNot(value => value === duration)
					) || null
			);
		},
		[setCompany]
	);

	const savePendingRequestsReminder = useCallback(
		async (pendingRequestsReminderSchedule: TRoutineReminderSchedule) => {
			const company = await editCompany({ pendingRequestsReminderSchedule });
			setCompany(company);
		},
		[setCompany]
	);

	const saveAccessReportsReminder = useCallback(
		async (pendingAccessReportsReminderSchedule: TRoutineReminderSchedule) => {
			const company = await editCompany({ pendingAccessReportsReminderSchedule });
			setCompany(company);
		},
		[setCompany]
	);

	const saveTimezone = useCallback(
		async (timeZone: string | null) => {
			const company = await editCompany({ timeZone });
			setCompany(company);
		},
		[setCompany]
	);

	const toggleAllowedDuration = useCallback(
		async (duration: TTicketDuration) => {
			const company = await getModel();
			if (!company) return;
			try {
				if (company.allowedDurations.includes(duration)) {
					await removeAllowedDuration(duration);
					removeDuration(duration);
				} else {
					await addAllowedDuration(duration);
					addDuration(duration);
				}
			} catch (error) {
				openGlobalErrorModal(error as Error);
				return;
			}
		},
		[addDuration, getModel, openGlobalErrorModal, removeDuration]
	);

	const saveForceAccessReviewImmediateRevoke = useCallback(
		async (forceAccessReviewsImmediateRevoke: boolean) => {
			const response = await editCompany({ forceAccessReviewsImmediateRevoke });
			setCompany(response);
		},
		[setCompany]
	);

	return {
		state: { company },
		actions: {
			loadCompany,
			integrateWithHrSystem,
			integrateWithTicketingIntegration,
			integrateWithOnCallService,
			removeOnCallIntegration,
			toggleAllowedDuration,
			savePendingRequestsReminder,
			saveAccessReportsReminder,
			saveTimezone,
			removeHrIntegration,
			removeTicketingIntegration,
			saveForceAccessReviewImmediateRevoke
		}
	};
};

export const [CompanyProvider, useCompanyContext] = constate(useCompany);
