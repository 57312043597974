import classNames from "classnames";
import React, { useCallback } from "react";
import { FilterHeader } from "components/ui/filters/FilterExpression/components/FilterHeader";
import { Select } from "components/ui/Select";
import { getLabel, type TOptionRenderer } from "utils/ui/select";
import { useStyles } from "./styles";

type TSingleSelectAttributeExpressionProps<T> = {
	emptyState?: React.ReactNode;
	filter?: ((options: T[], inputValue: string) => T[]) | null;
	getMoreOptions?: (search?: string) => void;
	getOptionLabel?: (option: T) => string;
	inputPlaceholder?: string;
	inputValue?: string;
	isLoading?: boolean;
	onInputChange?: (event: { target: { value: string } }) => void;
	onOptionSelect: (option: T) => void;
	onRemoveAttribute?: () => void;
	onReset?: () => void;
	optionRenderer: TOptionRenderer<NoInfer<T>>;
	options: T[];
	relation?: "and" | "or";
	renderSelected: (option: T) => React.ReactNode;
	selected: T | undefined;
	sort?: ((options: T[]) => T[]) | null;
	title: string | React.ReactNode;
};
export function SingleSelectAttributeExpression<T>({
	className,
	emptyState = null,
	filter,
	getMoreOptions,
	getOptionLabel,
	id,
	innerRef,
	inputPlaceholder,
	inputValue,
	isLoading,
	onInputChange: onInputChangeProp,
	onOptionSelect,
	onRemoveAttribute,
	onReset,
	optionRenderer,
	options,
	renderSelected,
	selected,
	sort,
	title
}: TProps<TSingleSelectAttributeExpressionProps<T>>) {
	const classes = useStyles();

	const handleValueChange = useCallback(
		(option: T | null) => {
			if (option) {
				onOptionSelect(option);
			}
		},
		[onOptionSelect]
	);

	const onInputChange = useCallback(
		(event: { target: { value: string } }) => {
			getMoreOptions?.(event.target.value);
			onInputChangeProp?.(event);
		},
		[getMoreOptions, onInputChangeProp]
	);

	const isOptionEqualToValue = useCallback((option: T) => selected === option, [selected]);

	return (
		<div className={classNames(classes.container, className)} id={id} ref={innerRef}>
			<FilterHeader
				className={className}
				title={title}
				onRemoveFilter={onRemoveAttribute}
				onReset={onReset}
				innerRef={innerRef}
				hasSelection={selected !== undefined}
				inputs={
					<Select
						className={classes.select}
						filter={filter}
						getOptionLabel={getOptionLabel || getLabel}
						hideClear
						inputValue={inputValue}
						isOptionEqualToValue={isOptionEqualToValue}
						loading={isLoading}
						onChange={handleValueChange}
						onInputChange={onInputChange}
						options={options}
						placeholder={inputPlaceholder}
						renderOption={optionRenderer}
						resetValueOnSelect
						sort={sort}
						value={null}
					/>
				}
			/>
			<div className={classes.content}>{selected !== undefined ? renderSelected(selected) : emptyState}</div>
		</div>
	);
}
