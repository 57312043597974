import { Record, List } from "immutable";
import { TicketModel } from "./TicketModel";

const defaults = {
	id: "",
	content: "",
	isDeleted: false,
	createdAt: new Date(0),
	updatedAt: new Date(0),
	ticket: null as TicketModel | null,
	creatorId: null as string | null,
	replies: null as List<TicketCommentModel> | null
};

export class TicketCommentModel extends Record<typeof defaults>(defaults) {
	static fromServerData(data: unknown): TicketCommentModel {
		const { id, content, isDeleted, createdAt, updatedAt, ticket, creatorId, replies } = data as typeof defaults;

		return new TicketCommentModel({
			id,
			content,
			isDeleted,
			createdAt: new Date(createdAt),
			updatedAt: new Date(updatedAt),
			ticket: ticket ? TicketModel.fromServerData(ticket) : null,
			creatorId: creatorId || null,
			replies: replies ? replies.map(TicketCommentModel.fromServerData) : null
		});
	}
}
