import React from "react";
import { ISelectProps, Select } from "components/ui/Select";
import { useVirtualTableContext } from "../../virtualTableContext";
import type { IRowOptions } from "../../types";

export function DropdownCellContent<T>(props: ISelectProps<T> & IRowOptions) {
	const {
		state: { compact }
	} = useVirtualTableContext();

	return <Select {...props} variant="table" size={compact ? "medium" : "large"} />;
}
