import constate from "constate";
import { Dispatch, SetStateAction, useMemo, useState } from "react";

const setOverlays = (setter: Dispatch<SetStateAction<HTMLDivElement | null>>) => {
	return (value: HTMLDivElement | null) => {
		if (value === null || value instanceof HTMLDivElement) {
			setter(value);
		}
	};
};

const useOverlays = () => {
	const [pageOverlays, setPageOverlays] = useState<HTMLDivElement | null>(null);
	const [systemOverlays, setSystemOverlays] = useState<HTMLDivElement | null>(null);

	const pageOverlaysSetter = useMemo(() => setOverlays(setPageOverlays), []);
	const systemOverlaysSetter = useMemo(() => setOverlays(setSystemOverlays), []);

	const store = useMemo(
		() => ({
			state: { systemOverlays, pageOverlays },
			actions: { setSystemOverlays: systemOverlaysSetter, setPageOverlays: pageOverlaysSetter }
		}),
		[systemOverlays, pageOverlays, systemOverlaysSetter, pageOverlaysSetter]
	);

	return store;
};

export const [OverlaysProvider, useOverlaysContext, usePageOverlays, useSystemOverlays] = constate(
	useOverlays,
	value => value,
	({ state: { pageOverlays } }) => pageOverlays,
	({ state: { systemOverlays } }) => systemOverlays
);
