import React from "react";
import { UserModel } from "models/UserModel";
import { Entity } from "../Entity";
import { UserTooltip } from "../UserTooltip";

export const TextUser: FC<{ user?: UserModel }> = ({ user, className }) => {
	if (!user) return null;
	return (
		<UserTooltip user={user}>
			<Entity content={user.fullName} className={className} />
		</UserTooltip>
	);
};
