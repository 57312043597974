import React, { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useAccessReviews } from "hooks/useAccessReviews";
import { boldComponent } from "i18n";
import { AccessReviewAuditLogModel } from "models/auditLogs";
import { dateToShortText } from "utils/dates/date";
import { useStyles } from "./styles";
import { useTimezone } from "../../auditLogsContext";
import { AuditLogUser } from "../AuditLogUser";
import type { TAuditLogContentComponent } from "./AuditLogContent.types";

const TRANSLATION_PREFIX = "pages.auditLog.auditLogList.accessReview.";

export const AccessReviewAuditLogContent: TAuditLogContentComponent<AccessReviewAuditLogModel> = ({
	auditLog,
	logUser
}) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const { accessReviews } = useAccessReviews();
	const timezone = useTimezone();

	const accessReviewId = auditLog.accessReviewId;
	const accessReview = accessReviews?.get(accessReviewId);

	const values = useMemo(
		() => ({
			createdAt: accessReview?.createdAt && dateToShortText(accessReview.createdAt, timezone),
			name: accessReview?.name || t("common.ticketActivity.ticketActivityItem.unknownAccessReview")
		}),
		[accessReview, t, timezone]
	);

	const props = useMemo(
		() => ({
			values,
			components: { bold: boldComponent, user: <AuditLogUser user={logUser} className={classes.userText} /> },
			i18nKey: auditLog.action ? (`${TRANSLATION_PREFIX}${auditLog.action}` as const) : ("shared.emptyString" as const)
		}),
		[auditLog.action, classes.userText, logUser, values]
	);

	return <Trans t={t} {...props} />;
};
