import { Record } from "immutable";
import { TVertexType, VertexModel } from "./VertexModel";

const defaults = {
	id: "",
	type: "role" as Exclude<TVertexType, "account" | "integration" | "user">,
	entity: {
		roleId: "",
		resourceId: "",
		integrationId: "",
		roleName: "",
		resourceName: "",
		resourceType: "",
		managed: false
	},
	step: 0
};

export class RoleVertexModel extends Record(defaults) implements VertexModel {
	static fromServerData(data: unknown): RoleVertexModel {
		const { id, type, entity, step } = data as typeof defaults;

		return new RoleVertexModel({
			id,
			type,
			entity: {
				roleId: entity.roleId,
				resourceId: entity.resourceId,
				integrationId: entity.integrationId,
				roleName: entity.roleName,
				resourceName: entity.resourceName,
				resourceType: entity.resourceType,
				managed: entity.managed
			},
			step
		});
	}
}
