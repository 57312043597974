import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	container: {
		alignItems: "center",
		display: "flex",
		flexWrap: "nowrap",
		gap: "4px",
		maxWidth: "100%"
	},
	tooltipContentContainer: {
		display: "flex",
		maxHeight: "288px",
		minWidth: "100px",
		maxWidth: "240px",
		justifyContent: "flex-start",
		alignItems: "flex-start",
		overflow: "auto",
		flexDirection: "column",
		gap: "var(--spacing-x3, 12px)",
		paddingBottom: "var(--spacing-x2, 8px)",
		paddingTop: "var(--spacing-x2, 8px)"
	},
	invisible: {
		visibility: "hidden"
	}
});
