import { Map, List } from "immutable";
import { useEffect, useMemo } from "react";
import { useApprovalAlgorithmsContext } from "context/approvalAlgorithmsContext";
import { ApprovalAlgorithmModel } from "models/ApprovalAlgorithmModel";

export const useApprovalAlgorithms = (withDeleted = false) => {
	const {
		state: { approvalAlgorithms },
		actions: { loadApprovalAlgorithms }
	} = useApprovalAlgorithmsContext();

	useEffect(() => {
		void loadApprovalAlgorithms();
	}, [loadApprovalAlgorithms]);

	const filteredApprovalAlgorithms: Map<string, ApprovalAlgorithmModel> | null = useMemo(() => {
		return withDeleted || !approvalAlgorithms
			? approvalAlgorithms
			: approvalAlgorithms.filter(approvalAlgorithm => !approvalAlgorithm.isDeleted);
	}, [approvalAlgorithms, withDeleted]);

	return filteredApprovalAlgorithms;
};

export const useApprovalAlgorithmsList = (withDeleted = false) => {
	const {
		state: { sortedApprovalAlgorithms },
		actions: { loadApprovalAlgorithms }
	} = useApprovalAlgorithmsContext();

	useEffect(() => {
		void loadApprovalAlgorithms();
	}, [loadApprovalAlgorithms]);

	const filteredApprovalAlgorithms = useMemo<List<ApprovalAlgorithmModel> | undefined>(() => {
		return withDeleted || !sortedApprovalAlgorithms
			? sortedApprovalAlgorithms
			: sortedApprovalAlgorithms.filter(approvalAlgorithm => !approvalAlgorithm.isDeleted);
	}, [sortedApprovalAlgorithms, withDeleted]);

	return filteredApprovalAlgorithms;
};
