import constate from "constate";
import { useMemo, useState } from "react";
import { useSortState } from "hooks/useSortState";
import type { TSortOrder } from "types/pagination";

const useSortTable = ({
	defaultSortField,
	defaultSortOrder,
	secondaryField
}: {
	defaultSortField: string;
	defaultSortOrder: TSortOrder;
	secondaryField?: string;
}) => {
	const { sortOrder, setSortState, sortFields } = useSortState({ defaultSortField, defaultSortOrder, secondaryField });
	const [hoveredField, setHoveredField] = useState<string | undefined>(undefined);

	return useMemo(
		() => ({ state: { hoveredField, sortOrder, sortFields }, actions: { setSortState, setHoveredField } }),
		[hoveredField, sortOrder, sortFields, setSortState]
	);
};

export const [SortTableProvider, useSortTableContext] = constate(useSortTable);
export const [RespondedTicketsTableProvider, useRespondedTicketsTableContext] = constate(useSortTable);
