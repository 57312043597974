import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { IconButton } from "components/ui/IconButton";
import { DeleteIcon } from "components/ui/Icons/DeleteIcon";
import { EditIcon } from "components/ui/Icons/EditIcon";
import { Tooltip } from "components/ui/Tooltip";
import { BundleModel } from "models/BundleModel";
import { useStyles } from "./styles";

type TProps = {
	bundle: BundleModel;
	onEdit?: ((bundle: BundleModel) => void) | null;
	onRemove?: ((bundle: BundleModel) => void) | null;
};

export const ActionsCell: FC<TProps> = ({ bundle, onEdit, onRemove }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const handleEditClick = useCallback(() => onEdit && onEdit(bundle), [bundle, onEdit]);
	const handleRemoveClick = useCallback(() => onRemove && onRemove(bundle), [bundle, onRemove]);
	return (
		<div className={classes.actionsCell}>
			{onEdit && (
				<Tooltip content={t("buttons.edit")}>
					<IconButton size="medium" onClick={handleEditClick}>
						<EditIcon />
					</IconButton>
				</Tooltip>
			)}
			{onRemove && (
				<Tooltip content={t("buttons.delete")}>
					<IconButton size="medium" onClick={handleRemoveClick}>
						<DeleteIcon />
					</IconButton>
				</Tooltip>
			)}
		</div>
	);
};
