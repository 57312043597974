import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	deletedAndUnmanagedTooltip: {
		minWidth: "452px"
	},
	unmanagedTooltip: {
		minWidth: "fit-content"
	}
});
