import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { RequestBar } from "components/common/RequestBar";
import { REQUEST_BAR_HEIGHT } from "components/common/RequestBar/styles";
import { RequestDetails } from "components/common/RequestDetails";
import { VirtualRequestList } from "components/common/RequestList";
import { TicketFiltersModal } from "components/common/TicketFilters";
import { PageTitleContent } from "components/templates/PageTitleContentTemplate";
import { Button } from "components/ui/Button";
import { ButtonGroup, type IButtonOptions } from "components/ui/ButtonGroup";
import { StaticChip } from "components/ui/chips/StaticChip";
import { DropdownSortButton } from "components/ui/DropdownSortButton";
import { FilterIcon } from "components/ui/Icons/FilterIcon";
import { Section } from "components/ui/Section";
import { Skeleton } from "components/ui/Skeleton";
import { Typography } from "components/ui/Typography";
import { TicketRenewalProvider } from "context/renewalTicketContext";
import { useIsOpenState } from "hooks/useIsOpenState";
import {
	ALL_REQUESTS,
	MY_REQUESTS,
	REQUESTS_PAGE_SIZE,
	RESPONDED_TO_REQUESTS,
	TRequestListType,
	usePastRequests
} from "./pastRequests.hooks";
import { useStyles } from "./styles";
import type { TicketModel } from "models/TicketModel";

export const PastRequestsPage: FC = ({ className, innerRef }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { close, isOpen, open } = useIsOpenState();
	const {
		activeFilters,
		setRequestsListType,
		filters,
		getPage,
		isLoading,
		onFiltersChange,
		requestsListType,
		requests,
		sortState,
		totalResults
	} = usePastRequests();

	const renderRequest = useCallback((request?: TicketModel) => {
		if (!request) return <Skeleton height={REQUEST_BAR_HEIGHT} />;
		return <RequestBar key={request.id} request={request} type="past" openSidebarOnClick />;
	}, []);

	const sortOptions = useMemo(() => {
		return [
			{
				label: t("pages.pastRequests.sortOptions.ticketNumber"),
				value: "ticketNumber"
			},
			{
				label: t("pages.pastRequests.sortOptions.duration"),
				value: "duration"
			},
			{
				label: t("pages.pastRequests.sortOptions.status"),
				value: "status"
			},
			{
				label: t("pages.pastRequests.sortOptions.receiver"),
				value: "receiver"
			}
		];
	}, [t]);

	const requestListTypes = useMemo<IButtonOptions<TRequestListType>[]>(() => {
		return [
			{
				id: MY_REQUESTS,
				header: t("pages.pastRequests.requestTypes.myRequests")
			},
			{
				id: RESPONDED_TO_REQUESTS,
				header: t("pages.pastRequests.requestTypes.respondedTo")
			},
			{
				id: ALL_REQUESTS,
				header: t("pages.pastRequests.requestTypes.all")
			}
		];
	}, [t]);

	const breadcrumbs = useMemo(() => {
		return [
			{
				title: t("navigation.requests"),
				url: "/requests"
			},
			{
				title: t("pages.pastRequests.title")
			}
		];
	}, [t]);

	const title = useMemo(() => {
		return (
			<>
				<Typography variant="body_med"> {t("pages.pastRequests.total")}</Typography>
				<StaticChip size="small" variant="regular">
					{isLoading ? "-" : t("number", { value: totalResults })}
				</StaticChip>
			</>
		);
	}, [isLoading, totalResults, t]);

	const contentSectionActions = useMemo(() => {
		return (
			<div className={classes.actionsContainer}>
				<ButtonGroup
					buttons={requestListTypes}
					defaultActiveButton={MY_REQUESTS}
					onActivateButton={setRequestsListType}
					activeButton={requestsListType}
					size="medium"
				/>
				<DropdownSortButton sortState={sortState} options={sortOptions} />
				<Button
					size="medium"
					variant={activeFilters.length ? "secondary" : "primary"}
					prefix={<FilterIcon />}
					onClick={open}>
					{t("buttons.filters") + activeFilters}
				</Button>
			</div>
		);
	}, [
		activeFilters,
		classes.actionsContainer,
		open,
		sortOptions,
		sortState,
		t,
		requestListTypes,
		requestsListType,
		setRequestsListType
	]);

	return (
		<TicketRenewalProvider>
			<PageTitleContent innerRef={innerRef} className={className}>
				<RequestDetails />
				<PageTitleContent.Header>
					<PageTitleContent.Header.Top breadcrumbs={breadcrumbs} />
					<PageTitleContent.Header.Bottom>
						<PageTitleContent.Header.Title title={t("pages.pastRequests.title")} />
					</PageTitleContent.Header.Bottom>
				</PageTitleContent.Header>
				<PageTitleContent.Body>
					<TicketFiltersModal
						isOpen={isOpen}
						onClose={close}
						onFiltersChange={onFiltersChange}
						ticketFilters={filters}
					/>
					<Section titleActions={contentSectionActions} title={title} fullHeight>
						<VirtualRequestList
							fetchPage={getPage}
							fullHeight
							innerRef={innerRef}
							perPage={REQUESTS_PAGE_SIZE}
							requestRenderer={renderRequest}
							requests={requests}
							totalRequests={totalResults}
						/>
					</Section>
				</PageTitleContent.Body>
			</PageTitleContent>
		</TicketRenewalProvider>
	);
};
