import classNames from "classnames";
import React from "react";
import { TooltipOnOverflow } from "components/ui/TooltipOnOverflow";
import { Typography, type TTypographyVariant } from "components/ui/Typography";
import { useStyles } from "./styles";
import type { Config } from "react-popper-tooltip";

interface IProps {
	text?: string;
	tooltipOnOverflow?: boolean;
	textVariant?: TTypographyVariant;
	maxLines?: number;
	tooltipPlacement?: Config["placement"];
}

const MultiLineEllipsisComponent: FC<IProps> = ({
	className,
	text,
	maxLines = 3,
	tooltipOnOverflow,
	tooltipPlacement,
	textVariant = "text_sm_reg"
}) => {
	const classes = useStyles({ maxLines });
	const textClassName = classNames(className, classes.container);

	return tooltipOnOverflow ? (
		<TooltipOnOverflow
			tooltipPlacement={tooltipPlacement}
			className={textClassName}
			content={text}
			textVariant={textVariant}
			multiline
		/>
	) : (
		<Typography variant={textVariant} className={textClassName}>
			{text}
		</Typography>
	);
};

export const MultiLineEllipsis = React.memo(MultiLineEllipsisComponent);
