import React from "react";

export const EntitleDarkLogo: FC = ({ className }) => (
	<svg className={className} width="250" height="60" viewBox="0 0 1000 239" fill="none">
		<path
			d="M196.67 236.6H237.78V141.64C237.78 120.82 252.01 105.05 271.93 105.05C291.85 105.05 303.87 118.61 303.87 138.81V236.6H344.98V128.08C344.98 92.7501 322.21 66.5601 284.89 66.5601C261.17 66.5601 244.73 78.5501 238.08 90.8501H235.87V70.6601H196.66V236.6H196.67Z"
			fill="#1C1627"
		/>
		<path
			d="M459.01 236.6H489.69V202.53H467.55C451.74 202.53 444.78 193.7 444.78 179.82V103.48H490.64V70.67H444.78V24.92H406.2V58.36C406.2 66.25 401.46 70.98 394.18 70.98H375.52V103.47H403.67V181.39C403.67 217.04 424.54 236.6 459.01 236.6Z"
			fill="#1C1627"
		/>
		<path d="M525.44 236.38H566.55V70.45H525.44V236.39V236.38Z" fill="#1C1627" />
		<path
			d="M545.98 46.96C558.948 46.96 569.46 36.4476 569.46 23.48C569.46 10.5124 558.948 0 545.98 0C533.012 0 522.5 10.5124 522.5 23.48C522.5 36.4476 533.012 46.96 545.98 46.96Z"
			fill="url(#paint0_linear_1_47)"
		/>
		<path
			d="M685.24 236.6H715.92V202.53H693.78C677.97 202.53 671.01 193.7 671.01 179.82V103.48H716.87V70.67H671.01V24.92H632.43V58.36C632.43 66.25 627.69 70.98 620.41 70.98H601.75V103.47H629.9V181.39C629.9 217.04 650.77 236.6 685.24 236.6Z"
			fill="#1C1627"
		/>
		<path
			d="M797 236.6H812.5V200.64H805.86C797.32 200.64 792.58 195.59 792.58 186.13V0H751.47V189.91C751.47 219.25 767.92 236.6 797.01 236.6H797Z"
			fill="#1C1627"
		/>
		<path
			d="M917.46 238.72C960.47 238.72 990.2 216.64 998.42 182.88H960.15C954.46 196.45 938.96 205.91 918.72 205.91C892.15 205.91 874.76 188.87 873.5 163.64H1000V150.71C1000 104.02 970.91 64.59 917.46 64.59C868.76 64.59 833.65 100.24 833.65 152.61C833.65 202.14 868.44 238.73 917.46 238.73V238.72ZM874.13 134.62C876.66 113.8 894.37 97.39 917.46 97.39C940.55 97.39 958.89 112.22 960.79 134.62H874.14H874.13Z"
			fill="#1C1627"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M5.39 188.37H44.44C53.7 198.56 66.97 205.26 81.7 205.26C96.43 205.26 109.71 198.56 118.97 188.37H158.02C144.91 217.35 115.65 237.55 81.7 237.55C47.75 237.55 18.49 217.36 5.38 188.37H5.39Z"
			fill="url(#paint1_linear_1_47)"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M162.42 164.16V148.67C162.42 103.08 134.01 64.58 81.83 64.58C34.28 64.58 0 99.39 0 150.52C0 154.19 0.2 160.65 0.58 164.17H162.43L162.42 164.16ZM81.83 98.11C59.29 98.11 42 114.13 39.53 134.46H124.14C122.29 112.59 105.3 98.11 81.84 98.11H81.83Z"
			fill="#1C1627"
		/>
		<defs>
			<linearGradient
				id="paint0_linear_1_47"
				x1="522.5"
				y1="23.48"
				x2="583.97"
				y2="23.48"
				gradientUnits="userSpaceOnUse">
				<stop stopColor="#F78764" />
				<stop offset="1" stopColor="#F0D3F7" />
			</linearGradient>
			<linearGradient
				id="paint1_linear_1_47"
				x1="5.39"
				y1="212.96"
				x2="205.16"
				y2="212.96"
				gradientUnits="userSpaceOnUse">
				<stop stopColor="#F78764" />
				<stop offset="1" stopColor="#F0D3F7" />
			</linearGradient>
			<clipPath id="clip0_1_47">
				<rect width="1000" height="238.72" fill="white" />
			</clipPath>
		</defs>
	</svg>
);
