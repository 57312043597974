import { useCallback, useState } from "react";

export const useRerenders = () => {
	const [rerenderCount, setRerenderCount] = useState(0);

	const forceUpdate = useCallback(() => {
		setRerenderCount(prev => prev + 1);
	}, []);

	return { rerenderCount, forceUpdate };
};
