import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IntegrationAccountsBlock } from "components/common/IntegrationAccountsBlock";
import { Button } from "components/ui/Button";
import { StaticChip } from "components/ui/chips/StaticChip";
import { AddIcon } from "components/ui/Icons/AddIcon";
import { Section } from "components/ui/Section";
import { Typography } from "components/ui/Typography";
import { useUserIntegrationActors } from "hooks/useUserIntegrationActors";
import { UserModel } from "models/UserModel";
import { AddIntegrationActorModal } from "./components/AddIntegrationModal";
import { useStyles } from "./styles";
import type { IntegrationActorModel } from "models/IntegrationActorModel";
import type { IntegrationModel } from "models/IntegrationModel";

type TUserPermissionsSectionProps = {
	user: UserModel;
};

export const UserAccountsSection: FC<TUserPermissionsSectionProps> = ({ user }) => {
	const { t } = useTranslation();
	const { actorsByIntegrationId, isLoading, createActor, deleteActor } = useUserIntegrationActors(user.id);
	const [selectedIntegration, setSelectedIntegration] = useState<IntegrationModel>();
	const [isAddIntegrationModalOpen, setAddIntegrationModalOpen] = useState(false);
	const classes = useStyles();

	const title = useMemo(() => {
		return (
			<>
				<Typography variant="body_med" noWrap>
					{t("pages.user.total")}
				</Typography>
				{!isLoading ? (
					<StaticChip size="small" variant="regular">
						{t("number", { value: actorsByIntegrationId?.size ?? 0 })}
					</StaticChip>
				) : null}
			</>
		);
	}, [actorsByIntegrationId?.size, isLoading, t]);

	const titleActions = useMemo(
		() => (
			<Button prefix={<AddIcon />} variant="secondary" size="medium" onClick={() => setAddIntegrationModalOpen(true)}>
				{t("pages.user.accountsTab.addIntegration")}
			</Button>
		),
		[t]
	);

	const closeAddIntegrationModal = useCallback(() => {
		setSelectedIntegration(undefined);
		setAddIntegrationModalOpen(false);
	}, []);

	const createNewActor = useCallback(
		async (integrationActor: IntegrationActorModel) => {
			await createActor(integrationActor);
			closeAddIntegrationModal();
		},
		[closeAddIntegrationModal, createActor]
	);

	useEffect(() => {
		if (selectedIntegration) {
			setAddIntegrationModalOpen(true);
		}
	}, [selectedIntegration]);

	return (
		<Section
			className={classes.sectionContainer}
			title={title}
			titleActions={titleActions}
			contentClassName={classes.contentContainer}>
			<AddIntegrationActorModal
				loading={isLoading}
				isOpen={isAddIntegrationModalOpen}
				actorsByIntegrationId={actorsByIntegrationId}
				onClose={closeAddIntegrationModal}
				selectedIntegration={selectedIntegration}
				onSubmit={createNewActor}
			/>
			{actorsByIntegrationId
				?.entrySeq()
				.toArray()
				.map(([integrationId, actors]) => (
					<IntegrationAccountsBlock
						key={integrationId}
						addActorForIntegration={setSelectedIntegration}
						deleteActor={deleteActor}
						integrationId={integrationId}
						integrationActors={actors}
					/>
				)) ?? null}
		</Section>
	);
};
