import { Map } from "immutable";
import { routes } from "./routes";
import type { Route, TPageId } from "./route";

let routesMap: Map<string, string>;

function generateRoutesMap(): Map<string, string> {
	function traverseRoutes(route: Route, prefix: string) {
		const location = prefix + route.main;

		if (route.pageId) {
			routesMap = routesMap.set(route.pageId, location);
		}

		if (route.subRoutes) {
			for (const subRoute of Map(route.subRoutes).values()) {
				traverseRoutes(subRoute, location);
			}
		}
	}
	routesMap = Map();
	traverseRoutes({ subRoutes: routes, main: "" } as unknown as Route, "");

	return routesMap;
}

export function getPageLocation(pageId: TPageId) {
	if (!routesMap) {
		generateRoutesMap();
	}

	return routesMap.get(pageId);
}
