import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	sub: {
		padding: "0"
	},
	page: {
		boxSizing: "border-box",
		display: "flex",
		flexDirection: "column",
		overflow: "auto",
		backgroundColor: "var(--color-grey-background)",
		"&:not($sub)": {
			"& $title": {
				padding: "1.5rem 2.25rem",
				fontSize: "var(--typography-h2-font-size)",
				flexShrink: "0",
				backgroundColor: "var(--surface-header-background-color)"
			},
			"& $content": {
				padding: "1.5rem 2.25rem",
				display: "flex",
				flexDirection: "column",
				flex: "1 1 auto",
				overflow: "auto"
			}
		},
		"&$sub": {
			background: "var(--surface-page-contrast-background-color)",
			border: "1px solid var(--color-almost-white)",
			display: "flex",
			flexDirection: "column",
			"& $title": {
				flexShrink: 0,
				padding: "1.1em 1.4em",
				background: "var(--color-almost-white)",
				fontSize: "1.1em"
			},
			"& $content": {
				border: "1px solid var(--color-grey-background)",
				borderTopLeftRadius: "var(--border-radius-rounded)",
				borderTopRightRadius: "var(--border-radius-rounded)",
				background: "#fff",
				flex: 1,
				"&$noBorder": {
					border: "none"
				},
				"&$noBackground": {
					background: "transparent"
				}
			}
		}
	},
	overlays: {
		width: "100%",
		height: "100%",
		position: "absolute",
		zIndex: "100",
		pointerEvents: "none",
		left: 0,
		top: 0,
		"&:empty": {
			display: "none"
		},
		"&$detached": {
			zIndex: "110"
		},
		"& > *": {
			pointerEvents: "initial"
		}
	},
	title: {},
	content: {},
	detached: {},
	noBorder: {},
	noBackground: {}
});
