import { List } from "immutable";
import { RuleModel } from "models/RuleModel";
import { apiReq } from "utils/api/apiReq";

export async function getRolesRules() {
	const { data } = await apiReq("GET", "/v1/integrationResourceRoleRules");

	return List<RuleModel>(data.map((rule: unknown) => RuleModel.fromServerData(rule, "roles")));
}

export async function getResourcesRules() {
	const { data } = await apiReq("GET", "/v1/integrationResourceRules");

	return List<RuleModel>(data.map((rule: unknown) => RuleModel.fromServerData(rule, "resources")));
}

export const createResourcesRule = async (rule: RuleModel, applyToExisting: boolean) => {
	const schema = { ...rule.toServerData(), immediatelyApplyChanges: applyToExisting };
	const { data: newRule } = await apiReq("POST", "/v1/integrationResourceRules", schema);

	return RuleModel.fromServerData(newRule, "resources");
};

export const createRolesRule = async (rule: RuleModel, applyToExisting: boolean) => {
	const schema = { ...rule.toServerData(), immediatelyApplyChanges: applyToExisting };
	const { data: newRule } = await apiReq("POST", "/v1/integrationResourceRoleRules", schema);

	return RuleModel.fromServerData(newRule, "roles");
};

export const updateResourcesRule = async (rule: RuleModel, applyToExisting: boolean) => {
	const schema = { ...rule.toServerData(), immediatelyApplyChanges: applyToExisting };
	const { data: updatedRule } = await apiReq("PUT", `/v1/integrationResourceRules/${rule.id}`, schema);

	return RuleModel.fromServerData(updatedRule, "resources");
};

export const updateRolesRule = async (rule: RuleModel, applyToExisting: boolean) => {
	const schema = { ...rule.toServerData(), immediatelyApplyChanges: applyToExisting };
	const { data: updatedRule } = await apiReq("PUT", `/v1/integrationResourceRoleRules/${rule.id}`, schema);

	return RuleModel.fromServerData(updatedRule, "roles");
};

export const deleteResourcesRule = async (ruleId: string) => {
	return apiReq("DELETE", `/v1/integrationResourceRules/${ruleId}`);
};

export const deleteRolesRule = async (ruleId: string) => {
	return apiReq("DELETE", `/v1/integrationResourceRoleRules/${ruleId}`);
};
