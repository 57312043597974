import type { TAccessReviewPermissionStatus } from "models/AccessReviewPermissionModel";

export interface IEntitlementsStatistics {
	approved: number;
	denied: number;
	flagged: number;
	pending: number;
	deniedManaged: number;
	deniedUnmanaged: number;
}

export type TPermissionStatusOptions = "pending" | "approve" | "deny" | "flag";

export const STATUS_DICT = new Map<TPermissionStatusOptions, TAccessReviewPermissionStatus>([
	["approve", "approved"],
	["deny", "denied"],
	["flag", "flagged"]
]);

export const DESCRIPTION_MAX_LENGTH = 2048;

export const reduceStatistics = (entitlements: IEntitlementsStatistics[]): IEntitlementsStatistics => {
	const defaultStatistics = { pending: 0, approved: 0, denied: 0, flagged: 0, deniedManaged: 0, deniedUnmanaged: 0 };
	return entitlements.reduce((acc, entitlement) => {
		return {
			approved: acc.approved + (entitlement?.approved || 0),
			denied: acc.denied + (entitlement?.denied || 0),
			flagged: acc.flagged + (entitlement?.flagged || 0),
			pending: acc.pending + (entitlement?.pending || 0),
			deniedManaged: acc.deniedManaged + (entitlement?.deniedManaged || 0),
			deniedUnmanaged: acc.deniedUnmanaged + (entitlement?.deniedUnmanaged || 0)
		};
	}, defaultStatistics);
};
