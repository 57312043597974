import type { IPaginationOptions, ISortOptions } from "types/pagination";
import type { TQsFilter } from "./pagination";

interface ISearchOptions {
	query?: string;
}

export interface IPaginatedSearchOptions {
	search?: ISearchOptions;
	pagination?: IPaginationOptions;
	sort?: ISortOptions;
	filters?: TQsFilter;
}

export const getPaginatedSearchQueryString = (options?: IPaginatedSearchOptions) => {
	let qsFields: [string, string | number][] = [];
	if (!options) {
		return "";
	}

	const { search, pagination, sort, filters } = options;
	if (search && search.query) {
		// Need to change this field in main-api
		qsFields.push(["search", encodeURIComponent(search.query)]);
	}

	if (pagination && pagination.perPage) {
		qsFields.push(["perPage", pagination.perPage]);
		if (pagination.page) qsFields.push(["page", pagination.page]);
	}

	if (sort) {
		if (sort?.order) qsFields.push(["sortOrder", sort.order]);
		if (sort?.sortFields) {
			const addedFields =
				typeof sort.sortFields === "string"
					? [["sortFields", sort.sortFields] as [string, string]]
					: sort.sortFields.map(field => ["sortFields", field] as [string, string]);
			qsFields = qsFields.concat(addedFields);
		}
	}

	const filtersQs = filters && filters.toQueryString ? `&${filters.toQueryString()}` : "";
	const qs = qsFields.map(([key, value]) => `${key}=${value}`).join("&");

	return qs + (qs && filtersQs ? "&" + filtersQs : "");
};
