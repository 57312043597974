import { Map } from "immutable";
import { useRef, useCallback } from "react";

export type TSubscriptionFunc<T extends (...args: any[]) => void = (...args: any[]) => void> = (
	key: string,
	fn: T
) => void;

export const useSubscriber = <T extends (...args: any[]) => void = (...args: any[]) => void>() => {
	const callbackMapRef = useRef(Map<string, T>());

	const addSubscription = useCallback<TSubscriptionFunc<T>>((key: string, fn: T) => {
		callbackMapRef.current = callbackMapRef.current.set(key, fn);
	}, []);

	const removeSubscription = useCallback((key: string) => {
		callbackMapRef.current = callbackMapRef.current.delete(key);
	}, []);

	const notify = useCallback((...args: Parameters<T>) => {
		callbackMapRef.current.forEach(fn => fn(...args));
	}, []);

	return { addSubscription, removeSubscription, notify };
};
