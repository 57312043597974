import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	pageContainer: {
		width: "100%",
		height: "100%",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		backgroundColor: "var(--color-white)"
	}
});
