import React from "react";

export const OpsgenieLogo: FC = ({ className }) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" className={className}>
		<path
			d="M12.0067 12C14.7681 12 17.0067 9.76142 17.0067 7C17.0067 4.23857 14.7681 2 12.0067 2C9.24529 2 7.00671 4.23857 7.00671 7C7.00671 9.76142 9.24529 12 12.0067 12Z"
			fill="url(#paint0_linear_8_27)"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M11.6086 21.8781C8.55956 19.7991 6.02374 16.9656 4.21565 13.6175C4.14576 13.4833 4.13056 13.3243 4.17362 13.1777C4.21668 13.0312 4.3142 12.91 4.44333 12.8426L7.97888 10.988C8.22619 10.8591 8.52466 10.9568 8.66191 11.2118C10.4494 14.4142 13.0925 16.9716 16.2512 18.5549C15.0863 19.8095 13.7962 20.9239 12.4042 21.8781C12.1608 22.0406 11.8521 22.0406 11.6086 21.8781Z"
			fill="url(#paint1_linear_8_27)"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12.4042 21.8781C15.4537 19.7996 17.9896 16.966 19.7972 13.6175C19.8673 13.4836 19.8829 13.3249 19.8403 13.1784C19.7978 13.0319 19.7008 12.9105 19.5722 12.8426L16.034 10.988C15.7867 10.8591 15.4882 10.9568 15.351 11.2118C13.5638 14.4145 10.9206 16.972 7.76169 18.5549C8.92589 19.8103 10.216 20.9248 11.6087 21.8781C11.8521 22.0406 12.1608 22.0406 12.4042 21.8781Z"
			fill="#2684FF"
		/>
		<defs>
			<linearGradient
				id="paint0_linear_8_27"
				x1="12.0067"
				y1="3.66199"
				x2="12.0067"
				y2="13.9282"
				gradientUnits="userSpaceOnUse">
				<stop stopColor="#2684FF" />
				<stop offset="0.82" stopColor="#0052CC" />
			</linearGradient>
			<linearGradient
				id="paint1_linear_8_27"
				x1="9.13297"
				y1="13.6175"
				x2="11.8836"
				y2="20.9733"
				gradientUnits="userSpaceOnUse">
				<stop stopColor="#2684FF" />
				<stop offset="0.62" stopColor="#0052CC" />
			</linearGradient>
		</defs>
	</svg>
);
