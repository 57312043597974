import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	drawer: {
		display: "flex",
		flexDirection: "column",
		width: "640px",
		padding: "var(--spacing-x5, 20px) var(--spacing-x1, 4px) var(--spacing-x10, 40px) var(--spacing-x5, 20px)",
		alignItems: "flex-start",
		gap: "var(--spacing-x2, 8px)",
		flexShrink: 0
	},
	drawerContent: {
		display: "flex",
		flexDirection: "column",
		gap: "var(--spacing-x10, 40px)",
		alignItems: "flex-start"
	},
	ruleDefinitionTitle: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		gap: "var(--spacing-x3, 12px)"
	},
	ruleDefinitionTitleContainer: {
		display: "flex",
		flexDirection: "column",
		gap: "var(--spacing-x2, 8px)",
		width: "100%"
	}
});
