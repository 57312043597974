import { getI18nTextIfExists } from "../i18n";

// string is error (message) null is valid
type TValidator<T> = (value: T) => string | null;

export const getCommentValidator = (
	minLength: number,
	maxLength: number,
	fieldNameI18NKey: string
): TValidator<string> => {
	return (value: string) => {
		if (!value || value.length === 0) return null;
		return getMinMaxLengthValidator(minLength, maxLength, fieldNameI18NKey)(value);
	};
};

const getMinMaxLengthValidator = (
	minLength: number,
	maxLength: number,
	fieldNameI18NKey: string
): TValidator<string> => {
	return (value: string) => {
		if (value.length < minLength) {
			return getI18nTextIfExists("common.textValidation.HelpMinimumValueText", {
				field: getI18nTextIfExists(fieldNameI18NKey) || getI18nTextIfExists("shared.unknown"),
				minValue: minLength
			});
		}
		if (value.length > maxLength) {
			return getI18nTextIfExists("common.textValidation.HelpMaximumValueText", {
				field: getI18nTextIfExists(fieldNameI18NKey) || getI18nTextIfExists("shared.unknown"),
				maxValue: maxLength
			});
		}
		return null;
	};
};
