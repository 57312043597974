import { apiReq } from "utils/api/apiReq";

export type TAccessReviewPermissionType = "resource" | "subordinate";

export const bulkApproveAccessReviewPermissions = async (
	permissionIds: string[],
	type: TAccessReviewPermissionType
) => {
	return apiReq("PUT", "/v1/accessReviewPermissions/bulk/approve", { permissionIds, type });
};

export const bulkDenyAccessReviewPermissions = async (permissionIds: string[], type: TAccessReviewPermissionType) => {
	return apiReq("PUT", "/v1/accessReviewPermissions/bulk/deny", { permissionIds, type });
};

export const bulkFlagAccessReviewPermissions = async (permissionIds: string[], type: TAccessReviewPermissionType) => {
	return apiReq("PUT", "/v1/accessReviewPermissions/bulk/flag", { permissionIds, type });
};
