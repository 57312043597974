import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	container: {
		alignItems: "flex-start",
		display: "flex",
		flexDirection: "column",
		gap: (props: { size: "small" | "medium" | "large" }) =>
			props.size === "small" ? 0 : props.size === "medium" ? "2px" : "4px"
	},
	body: {
		whiteSpace: "pre-line"
	}
});
