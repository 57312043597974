import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { AreYouSureModal } from "components/common/AreYouSureModal";
import { Button } from "components/ui/Button";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { useIsOpenState } from "hooks/useIsOpenState";
import { useLoadingState } from "hooks/useLoadingState";
import { UserEntitlementModel } from "models/UserEntitlementModel";

type TProps = {
	entitlement: UserEntitlementModel;
	revokePermission: (permission: UserEntitlementModel) => Promise<unknown>;
};

const USERS_PAGE_TRANSLATION_PREFIX = "pages.user.entitlements";

export const RevokeCell: FC<TProps> = ({ entitlement, revokePermission }) => {
	const { t } = useTranslation();

	const { isLoading: isLoadingRevoke, withLoader } = useLoadingState();
	const openGlobalErrorModal = useOpenGlobalErrorModal();
	const areYouSureModal = useIsOpenState();

	const onRevokePermissionConfirmed = useCallback(
		async (permission: UserEntitlementModel) => {
			try {
				await withLoader(revokePermission(permission));
			} catch (error) {
				openGlobalErrorModal(error as Error);
			} finally {
				areYouSureModal.close();
			}
		},
		[areYouSureModal, openGlobalErrorModal, revokePermission, withLoader]
	);

	return entitlement.revokeStatus !== "notRevoking" || !entitlement.role?.managed ? null : (
		<>
			<AreYouSureModal
				closeLabel={t(`${USERS_PAGE_TRANSLATION_PREFIX}.permissionsTable.revokeAreYouSureModal.close`)}
				actionLabel={t(`${USERS_PAGE_TRANSLATION_PREFIX}.permissionsTable.revokeAreYouSureModal.confirm`)}
				content={t(`${USERS_PAGE_TRANSLATION_PREFIX}.permissionsTable.revokeAreYouSureModal.content`)}
				title={t(`${USERS_PAGE_TRANSLATION_PREFIX}.permissionsTable.revokeAreYouSureModal.title`)}
				isOpen={areYouSureModal.isOpen}
				onClose={areYouSureModal.close}
				onAction={() => onRevokePermissionConfirmed(entitlement)}
			/>
			<Button variant="secondary" size="medium" loading={isLoadingRevoke} onClick={areYouSureModal.open}>
				{t(`${USERS_PAGE_TRANSLATION_PREFIX}.permissionsTable.revoke`)}
			</Button>
		</>
	);
};
