import React from "react";
import { useTranslation } from "react-i18next";
import { NoAccessCircleIcon } from "components/ui/Icons/NoAccessCircleIcon";
import { Tooltip } from "components/ui/Tooltip";
import { useParentTooltipInterrupt } from "hooks/useParentTooltipInterrupt";
import { useStyles } from "./styles";
import type { TIconProps } from "components/ui/Icon";

const DeletedIndicatorIcon = (props: TIconProps) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { interruptParentOver, reinstateParentState } = useParentTooltipInterrupt();

	return (
		<Tooltip content={t("entities.deletedEntity", { context: "capital" })} className={classes.tooltip}>
			<NoAccessCircleIcon onMouseOver={interruptParentOver} onMouseLeave={reinstateParentState} {...props} />
		</Tooltip>
	);
};
DeletedIndicatorIcon.isIconComponent = true as const;

export const DeletedIndicator: IconComponent = DeletedIndicatorIcon;
