import React from "react";
import { useTranslation } from "react-i18next";
import { StaticChip } from "components/ui/chips/StaticChip";
import type { TFilterRelation } from "types/filters";

type TProps = {
	disabled?: boolean;
	relation: TFilterRelation;
};

export const FilterExpressionRelation: FC<TProps> = ({ className, innerRef, disabled = false, relation }) => {
	const { t } = useTranslation("translation", { keyPrefix: "filters.filterRelations" });

	return (
		<StaticChip disabled={disabled} variant="regular" size="small" className={className} innerRef={innerRef}>
			{t(relation, { context: "capital" })}
		</StaticChip>
	);
};
