import classNames from "classnames";
import React, { useMemo } from "react";
import { Typography } from "components/ui/Typography";
import { useStyles } from "./styles";
import { isIcon } from "../Icon";
import { InfoIcon } from "../Icons/InfoIcon";
import type { TInputSize } from "./types";

type TTitleProps = {
	disabled?: boolean;
	icon?: React.ReactNode | IconComponent;
	info?: React.ReactNode;
	required?: boolean;
	size?: TInputSize;
	title: string;
};

export const FieldTitle: FC<TTitleProps> = ({
	className,
	title,
	disabled = false,
	icon = null,
	info = null,
	size = "large",
	required = false
}) => {
	const classes = useStyles();
	const renderedIcon = useMemo(() => {
		if (!icon) return null;
		if (isIcon(icon)) {
			const Icon = icon;
			return <Icon size={size === "large" ? 24 : 20} />;
		}
		return icon;
	}, [icon, size]);

	const renderedInfo = useMemo(() => {
		if (!info) return null;
		if (typeof info === "string") {
			return <InfoIcon size={24} tooltip={info} />;
		}
		return info;
	}, [info]);
	return (
		<div className={classNames(classes.titleContainer, { [classes.disabled]: disabled }, className)}>
			{renderedIcon}
			<Typography variant="body_sb">
				{title}
				{required ? "*" : ""}
			</Typography>
			{renderedInfo}
		</div>
	);
};

export const Description: FC<{ description?: string; disabled?: boolean; size?: TInputSize }> = ({
	className,
	description,
	disabled = false,
	size = "large"
}) => {
	const classes = useStyles();
	if (!description) return null;
	return (
		<Typography
			variant={size === "large" ? "text_reg" : "text_sm_reg"}
			className={classNames({ [classes.disabled]: disabled }, className)}>
			{description}
		</Typography>
	);
};

export const Errors: FC<{ errorMessages: string[] | null }> = ({ className, errorMessages }) => {
	const classes = useStyles();
	if (!errorMessages || errorMessages.length === 0) return null;
	return (
		<>
			{errorMessages.map((errorMessage, index) => (
				<Typography
					variant="text_tny_reg"
					className={classNames(className, classes.errors)}
					key={`${index}${errorMessage}`}>
					{errorMessage}
				</Typography>
			))}
		</>
	);
};
