import { createUseStyles } from "react-jss";
import { selectStyles } from "../commonStyles";

export const useStyles = createUseStyles({
	content: {
		alignItems: "center",
		display: "flex",
		gap: "var(--spacing-x2)",
		justifyContent: "flex-start",
		maxWidth: "100%",
		overflow: "auto",
		paddingBottom: "var(--spacing-x3)",
		paddingTop: "var(--spacing-x4)",
		width: "100%"
	},
	...selectStyles
});
