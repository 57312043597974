import React, { useCallback, useMemo, useState, type KeyboardEvent } from "react";
import { Input } from "components/ui/Input";
import { Select } from "components/ui/Select";
import { TextOption } from "components/ui/selectOptions/TextOption";
import { useStyles } from "./styles";
import { useGetOperatorLabel } from "../../useRenderOperatorLabel";
import { FilterExpressionRelation } from "../FilterExpressionRelation";
import { FilterHeader } from "../FilterHeader";
import { SelectRowEmptyState } from "../SelectRowEmptyState";
import type { TFilterOperator } from "types/filters";
import type { TMultiTextFilterProps } from "../../types";

export const MultiTextFilter: FC<TMultiTextFilterProps> = ({
	className,
	innerRef,
	title,
	onReset,
	onRemoveFilter,
	onOperatorSelect,
	inputPlaceholder,
	onOptionSelect,
	operators,
	renderSelected,
	getOperatorLabel: propGetOperatorLabel,
	selected,
	selectedOperator,
	applyOnBlur = false,
	relation = "or",
	emptyState
}) => {
	const classes = useStyles();
	const getOperatorLabel = useGetOperatorLabel();
	const [inputValue, setInputValue] = useState<string>("");

	const selectedRow = useMemo(() => {
		const items = [...selected];
		if (applyOnBlur && inputValue) {
			items.push(inputValue);
		}
		if (!items.length) {
			return <SelectRowEmptyState content={emptyState} />;
		}
		const selectedComponents = items.map((item, index) => {
			return (
				<React.Fragment key={item}>
					{renderSelected(item)}
					{index !== items.length - 1 ? <FilterExpressionRelation relation={relation} /> : null}
				</React.Fragment>
			);
		});
		return (
			<>
				{selectedComponents}
				<FilterExpressionRelation disabled relation={relation} />
			</>
		);
	}, [selected, applyOnBlur, inputValue, relation, emptyState, renderSelected]);

	const handleOperatorChange = useCallback(
		(operator: TFilterOperator | null) => {
			if (operator) {
				onOperatorSelect(operator);
			}
		},
		[onOperatorSelect]
	);

	const onBlur = useCallback(() => {
		if (inputValue) {
			onOptionSelect(inputValue);
			setInputValue("");
		}
	}, [inputValue, onOptionSelect]);

	const onKeyDown = useCallback(
		(keyEvent: KeyboardEvent) => {
			if (inputValue) {
				if (keyEvent.key === "Enter") {
					onOptionSelect(inputValue);
					setInputValue("");
				} else if (keyEvent.key === "Escape") {
					setInputValue("");
				}
			}
		},
		[inputValue, onOptionSelect]
	);

	return (
		<>
			<FilterHeader
				className={className}
				title={title}
				onRemoveFilter={onRemoveFilter}
				onReset={onReset}
				innerRef={innerRef}
				hasSelection={selected.length > 0}
				inputs={
					<>
						<Select
							className={classes.operatorSelect}
							getOptionLabel={propGetOperatorLabel ?? getOperatorLabel}
							hideClear
							onChange={handleOperatorChange}
							options={operators}
							renderOption={TextOption}
							value={selectedOperator}
						/>
						<Input
							onBlur={applyOnBlur ? onBlur : undefined}
							onKeyDown={onKeyDown}
							onValueChange={setInputValue}
							placeholder={inputPlaceholder}
							value={inputValue}
						/>
					</>
				}
			/>
			<div className={classes.content}>{selectedRow}</div>
		</>
	);
};
