import { Record } from "immutable";

const defaults = {
	connectionType: "" as "direct" | "indirect" | "both",
	end: "",
	id: "" as `${string}:${string}`,
	start: ""
};

export class EdgeModel extends Record(defaults) {
	static fromServerData(data: unknown): EdgeModel {
		const { connectionType, id, start, end } = data as typeof defaults;

		return new EdgeModel({
			connectionType,
			end,
			id,
			start
		});
	}
}
