import { useState, useCallback } from "react";

export const useInlineInputState = () => {
	const [optionsOpen, setOptionsOpen] = useState(false);
	const [showArrow, setShowArrow] = useState(false);

	const onClose = useCallback(() => setOptionsOpen(false), [setOptionsOpen]);
	const onOpen = useCallback(
		(e: React.MouseEvent) => {
			e.stopPropagation();
			setOptionsOpen(true);
		},
		[setOptionsOpen]
	);
	const onHover = useCallback(() => setShowArrow(true), [setShowArrow]);
	const onLeave = useCallback(() => setShowArrow(false), [setShowArrow]);
	return { optionsOpen, showArrow, onClose, onOpen, onHover, onLeave };
};
