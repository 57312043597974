import { Map } from "immutable";
import { useCallback, useEffect, useState } from "react";
import {
	addUserIntegrationActor,
	deleteUserIntegrationActor,
	getUserIntegrationActorsGroupedByIntegration
} from "api/userIntegrationActors";
import { IntegrationActorModel } from "models/IntegrationActorModel";
import { useLoadingState } from "./useLoadingState";

export const useUserIntegrationActors = (userId: string) => {
	const [actorsByIntegrationId, setActorsByIntegrationId] = useState<Map<string, IntegrationActorModel[]>>();
	const { isLoading, withLoader } = useLoadingState();

	const loadActors = useCallback(async () => {
		const actors = await withLoader(getUserIntegrationActorsGroupedByIntegration(userId));
		setActorsByIntegrationId(actors);
	}, [userId, withLoader]);

	useEffect(() => {
		setActorsByIntegrationId(undefined);
		void loadActors();
	}, [loadActors]);

	const createActor = useCallback(
		async (integrationActor: IntegrationActorModel) => {
			if (!isLoading) {
				const callback = async () => {
					await addUserIntegrationActor(userId, integrationActor.id);
					setActorsByIntegrationId(curr =>
						(curr ?? Map()).update(integrationActor.integrationId, actors =>
							actors ? [...actors, integrationActor] : [integrationActor]
						)
					);
				};
				await withLoader(callback());
			}
		},
		[isLoading, userId, withLoader]
	);

	const deleteActor = useCallback(
		async (integrationActor: IntegrationActorModel) => {
			if (!isLoading) {
				const callback = async () => {
					await deleteUserIntegrationActor(userId, integrationActor.id);
					setActorsByIntegrationId(curr => {
						if (!curr || !curr.has(integrationActor.integrationId)) {
							return curr;
						}
						if (curr.get(integrationActor.integrationId)!.length === 1) {
							return curr.delete(integrationActor.integrationId);
						}

						return curr.update(integrationActor.integrationId, actors =>
							actors!.filter(a => a.id !== integrationActor.id)
						);
					});
				};
				await withLoader(callback());
			}
		},
		[isLoading, userId, withLoader]
	);

	return { actorsByIntegrationId, isLoading, createActor, deleteActor };
};
