import { List, Record } from "immutable";
import { AccessReviewResourceModel } from "./AccessReviewResourceModel";
import { AccessReviewSubordinateModel } from "./AccessReviewSubordinateModel";
import type { IAccessReviewPermissionsStatistics } from "./AccessReviewModel";

export type TAccessReviewReportStatus = "pending" | "inProgress" | "done";
export interface IReportItem {
	id: string;
	reportItemId: string;
}

const defaults = {
	accessReviewId: "",
	createdAt: new Date(0),
	id: "",
	permissionCount: null as number | null,
	resources: null as List<AccessReviewResourceModel> | null,
	subordinates: null as List<AccessReviewSubordinateModel> | null,
	sortedSubordinates: null as IReportItem[] | null,
	sortedResources: null as IReportItem[] | null,
	statistics: null as IAccessReviewPermissionsStatistics | null,
	status: undefined as TAccessReviewReportStatus | undefined,
	userId: ""
};

export class AccessReviewReportModel extends Record<typeof defaults>(defaults) {
	static fromServerData(data: unknown): AccessReviewReportModel {
		const {
			accessReviewId,
			createdAt,
			id,
			permissionCount,
			resources,
			statistics,
			status,
			subordinates,
			userId,
			sortedResources,
			sortedSubordinates
		} = data as typeof defaults;
		return new AccessReviewReportModel({
			accessReviewId,
			createdAt: new Date(createdAt),
			id,
			permissionCount,
			resources: resources ? List(resources.map(AccessReviewResourceModel.fromServerData)) : null,
			sortedResources: sortedResources || [],
			statistics,
			status,
			subordinates: subordinates ? List(subordinates.map(AccessReviewSubordinateModel.fromServerData)) : null,
			sortedSubordinates: sortedSubordinates || [],
			userId
		});
	}

	public get permissionsSize(): number {
		if (this.permissionCount) return this.permissionCount;
		if (this.statistics?.permissionsStatus) {
			return Object.values(this.statistics.permissionsStatus).reduce((a, b) => a + b, 0);
		}
		return 0;
	}

	public get calculatedStatus(): TAccessReviewReportStatus {
		if (this.status) {
			return this.status;
		}
		if (this.statistics?.permissionsStatus) {
			if (this.statistics.permissionsStatus.pending === this.permissionsSize) return "pending";
			if (this.statistics.permissionsStatus.pending === 0) return "done";
			return "inProgress";
		}
		if (this.subordinates || this.resources) {
			const subordinateStatuses = this.subordinates?.map(subordinate => subordinate.status) || [];
			const resourceStatuses = this.resources?.map(resource => resource.status) || [];
			const statuses = [...subordinateStatuses, ...resourceStatuses];
			if (statuses.includes("inProgress")) return "inProgress";
			if (statuses.every(status => status === "done")) return "done";
		}
		return "pending";
	}
}
