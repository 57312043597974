import { List, Map } from "immutable";
import { isObject, isArray, camelCase, map, chain } from "lodash";
import { DirectoryConfigurationModel } from "models/DirectoryConfigurationModel";
import { apiReq } from "utils/api/apiReq";
import { removeRedundantSpaces } from "utils/strings";
import type { TConfigurationCreateBody, TConfigurationUpdateBody, TDirectory } from "types/directoryConfiguration";
import type { ICheckConfig } from "./company";

export const getDirectoryConfigurations = async (): Promise<List<DirectoryConfigurationModel>> => {
	const { data } = await apiReq("GET", "/v1/directoryConfigurations");

	return List<DirectoryConfigurationModel>(
		data.map((item: unknown) => DirectoryConfigurationModel.fromServerData(item))
	);
};

export const createDirectoryConfiguration = async (
	creationData: TConfigurationCreateBody
): Promise<DirectoryConfigurationModel> => {
	const { data } = await apiReq("POST", "/v1/directoryConfigurations", creationData);

	return DirectoryConfigurationModel.fromServerData(data);
};

export const editDirectoryConfiguration = async (
	id: string,
	body: TConfigurationUpdateBody
): Promise<DirectoryConfigurationModel> => {
	const { data } = await apiReq("PUT", `/v1/directoryConfigurations/${id}`, body);

	return DirectoryConfigurationModel.fromServerData(data);
};

export const deleteDirectoryConfiguration = async (id: string): Promise<DirectoryConfigurationModel> => {
	const { data } = await apiReq("DELETE", `/v1/directoryConfigurations/${id}`);

	return DirectoryConfigurationModel.fromServerData(data);
};

export async function syncDirectory(id: string) {
	const { data } = await apiReq("POST", `/v1/directoryConfigurations/${id}/sync`);
	return DirectoryConfigurationModel.fromServerData(data);
}

export async function checkConfig(
	directory: TDirectory,
	configuration: Record<string, unknown>,
	agentToken?: string | null
) {
	const { data } = await apiReq("POST", "/v1/directoryConfigurations/checkConfig", {
		configuration,
		agentToken,
		directory
	});
	const checkConfigResult = data as ICheckConfig;
	return { isValid: checkConfigResult.isValidConfig, message: checkConfigResult.message };
}

export const adjustConfiguration = (obj: object, toSnakeCase: boolean): Record<string, unknown> => {
	return Object.entries(obj)
		.reduce((acc, [key, value]) => {
			const fixedKey = toSnakeCase ? transformToSnakeCase(key) : key;
			return acc.set(
				fixedKey,
				isObject(value) ? adjustConfiguration(value, toSnakeCase) : removeRedundantSpaces(value)
			);
		}, Map<string, unknown>())
		.toObject();
};

const transformToSnakeCase = (val: string) => {
	const reg = new RegExp(/([A-Z])/g);
	return val.replace(reg, v => `_${v.toLowerCase()}`);
};

export const fromSnakeToCamel = (data: object): object => {
	if (isArray(data)) {
		return map(data, fromSnakeToCamel);
	}

	if (isObject(data)) {
		return chain(data)
			.mapKeys((v, k) => camelCase(k))
			.mapValues((v, k) => fromSnakeToCamel(v))
			.value();
	}

	return data;
};
