import React from "react";
import { useStyles } from "./styles";

export const Spacer: FC = () => {
	const classes = useStyles();

	return (
		<div className={classes.spacer}>
			<div className={classes.line} />
		</div>
	);
};
