import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "components/ui/Typography";
import { AttributeChip } from "./AttributeChip";
import { useStyles } from "./styles";
import { getIconByAttribute } from "../../rules.utils";
import type { TResourceUpdates, TRoleOrResourceUpdate, TRoleUpdates, TRuleType, TRuleUpdates } from "models/RuleModel";

const UpdateRow = <TAttribute extends keyof TRoleOrResourceUpdate>({
	field,
	value,
	ruleType
}: {
	field: TAttribute;
	value: TRoleOrResourceUpdate[TAttribute];
	ruleType: TRuleType;
}) => {
	const classes = useStyles();

	const PrefixIcon = useMemo(() => getIconByAttribute(field), [field]);

	const { t } = useTranslation("translation", { keyPrefix: "pages.rules.updates" });

	const chip = useMemo(
		() => <AttributeChip ruleType={ruleType} attribute={field} value={value} />,
		[field, value, ruleType]
	);
	return (
		<div className={classes.updateContainer}>
			{PrefixIcon && <PrefixIcon size="medium" type="regular" />}
			<Typography variant="text_reg" className={classes.fieldNameText}>
				{t(
					ruleType === "resources"
						? `${ruleType}.${field as keyof TResourceUpdates}`
						: `${ruleType}.${field as keyof TRoleUpdates}`
				)}
			</Typography>
			{value === undefined ? null : chip}
		</div>
	);
};

export const UpdatesCell: FC<{ updates: TRuleUpdates; ruleType?: TRuleType }> = React.memo(function UpdatesCell({
	updates,
	ruleType
}) {
	const classes = useStyles();

	const updateEntries = useMemo(
		() =>
			Object.entries(updates) as [keyof TRoleOrResourceUpdate, TRoleOrResourceUpdate[keyof TRoleOrResourceUpdate]][],
		[updates]
	);

	return (
		<div className={classes.updatesCellContainer}>
			{updateEntries.map(([field, value]) => (
				<UpdateRow key={field} ruleType={ruleType!} field={field} value={value} />
			))}
		</div>
	);
});
