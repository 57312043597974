import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	justification: {
		borderRadius: "var(--border-radius-sharp)"
	},
	textareaInput: {
		maxHeight: "10rem"
	}
});
