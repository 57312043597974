import { isImmutable } from "immutable";
import get from "lodash/get";
import React from "react";
import { IconButton } from "components/ui/IconButton";
import { ChevronDownIcon } from "components/ui/Icons/ChevronDownIcon";
import { ChevronUpIcon } from "components/ui/Icons/ChevronUpIcon";
import { CloseIcon } from "components/ui/Icons/CloseIcon";
import { LoadingSpinner } from "components/ui/LoadingSpinner";
import { devLog } from "utils/devtools/devLogging";
import { requirePropertyOf } from "utils/security";
import { isStringArray } from "utils/strings";
import { basicSort } from "../sortUtils";

type TIdObject = { id: string };
type TIdentifierObject = { identifier: string };
type TWithId = TIdObject | TIdentifierObject | (TIdObject & TIdentifierObject);

export const INHERIT = "inherit";
export type TInherit = typeof INHERIT;

export type TRenderOptionProps<T> = {
	PrefixIcon?: IconComponent;
	disabled: boolean;
	getTextContent?: (value: T) => string;
	inputValue?: string | null;
	isSelected?: boolean;
	onSelect: (event: React.SyntheticEvent, value: T) => void;
	option: T;
	optionKey: string;
};

type TSelectOption = { isSelectOption: true };

export type TOptionComponent<T> = FC<TRenderOptionProps<T>> & TSelectOption;

export type TOptionComponentProps<T> = TProps<TRenderOptionProps<T>>;

export type TOptionRenderer<T> = TOptionComponent<T> | ((props: TRenderOptionProps<T>) => React.ReactNode);

const isWithLabel = (object: unknown): object is { label: string } =>
	typeof object === "object" && object !== null && "label" in object;

const isWithValue = (object: unknown): object is { value: string } =>
	typeof object === "object" && object !== null && "value" in object;

const isWithId = (object: unknown): object is TWithId =>
	typeof object === "object" && object !== null && ("id" in object || "identifier" in object);

const getId = (object: TWithId): string => get(object, "id", get(object, "identifier", ""));

const getNameProperty = <T, K extends keyof T>(object: T): K | undefined => {
	if (!object || typeof object !== "object") return undefined;
	const keys = Object.keys(isImmutable(object) ? object.toObject() : object) as K[];
	return keys.find(key => key === "name" || key === "displayName" || key === "fullName");
};

export const getLabel = <T,>(option: T) => {
	if (typeof option === "string") {
		return option;
	}
	if (isWithLabel(option)) {
		return option.label;
	}
	const nameProperty = getNameProperty(option);
	if (nameProperty) {
		const nameValue = requirePropertyOf(option, nameProperty);
		if (nameValue && typeof nameValue === "string") {
			return nameValue;
		}
	}
	devLog({ level: "error", message: "could not get label from option", extra: { option } });
	return "";
};

export const checkEquality = (a: unknown, b: unknown): boolean => {
	if (a === b) return true;
	if (isWithId(a) && isWithId(b)) {
		return getId(a) === getId(b);
	}
	if (isWithValue(a) && isWithValue(b)) {
		return a.value === b.value;
	}
	return false;
};

export const sortOptions = (options: string[] | { label: string }[] | { value: string }[] | unknown[]) => {
	if (!options.length) return options;
	if (isStringArray(options)) {
		return basicSort(options, []);
	}
	if (isWithLabel(options[0])) {
		return basicSort(options as { label: string }[], ["label"]);
	}
	if (isWithValue(options[0])) {
		return basicSort(options as { value: string }[], ["value"]);
	}
	return null;
};

export const cleanString = (value: string) => value?.trim()?.toLowerCase() ?? "";

export const getOptionKey = <T,>(option: T, getOptionLabel?: (option: T) => string) => {
	if (typeof option === "string") return option;
	if (isWithId(option)) return getId(option);
	const label = getOptionLabel ? getOptionLabel(option) : undefined;
	return label || String(option);
};

export const getGroups = <T,>(options: T[], groupBy: (option: T) => string): Map<string, T[]> => {
	const groups = new Map<string, T[]>();
	options.forEach(option => {
		const group = groupBy(option);
		if (!groups.has(group)) {
			groups.set(group, []);
		}
		groups.get(group)?.push(option);
	});
	return groups;
};

interface ISuffixOptions {
	disabled?: boolean;
	handleClear?: (event: React.MouseEvent) => void;
	handleClose?: () => void;
	handleOpen?: () => void;
	loading?: boolean;
	open?: boolean;
	showClear?: boolean;
	suffixClassName?: string;
	suffixClearClassName?: string;
	suffix?: React.ReactNode;
}

export const getSuffix = (options: ISuffixOptions) => {
	const {
		disabled,
		handleClear,
		handleClose,
		handleOpen,
		loading,
		open,
		showClear,
		suffixClassName,
		suffixClearClassName,
		suffix
	} = options;

	let suffixElement: React.ReactNode = (
		<IconButton size="small" disabled={disabled} onClick={handleOpen}>
			<ChevronDownIcon />
		</IconButton>
	);
	if (suffix || suffix === null) {
		suffixElement = suffix;
	}
	if (open && suffix !== null) {
		suffixElement = (
			<IconButton size="small" disabled={disabled} onClick={handleClose}>
				<ChevronUpIcon />
			</IconButton>
		);
	}
	if (loading) {
		suffixElement = <LoadingSpinner inline />;
	}

	let clearIcon = null;
	if (showClear && !disabled) {
		clearIcon = (
			<IconButton size="small" className={suffixClearClassName} onClick={handleClear}>
				<CloseIcon />
			</IconButton>
		);
	}
	return (
		<div className={suffixClassName}>
			{clearIcon}
			{suffixElement}
		</div>
	);
};

export type TRenderOption<T> = (option: T, index: number, disabled?: boolean) => React.ReactNode;
