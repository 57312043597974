import { useCallback, useEffect, useState } from "react";

export const useRemToPx = (): ((rem: number) => number) => {
	const [pxInRem, setPxInRem] = useState<number>(14);
	useEffect(() => {
		const observer = new ResizeObserver(() => {
			const fontSizeMatch = window?.getComputedStyle(document?.body)?.getPropertyValue("font-size")?.match(/\d+/);
			const newPxInRem = fontSizeMatch ? Number(fontSizeMatch[0]) : 14;
			setPxInRem(newPxInRem);
		});
		observer.observe(document.body);

		return () => {
			observer.disconnect();
		};
	}, []);

	return useCallback((rem: number) => rem * pxInRem, [pxInRem]);
};
