import classNames from "classnames";
import React, { useCallback } from "react";
import { Typography } from "components/ui/legacy/Typography";
import { RadioInput } from "components/ui/RadioInput";
import { useStyles } from "./styles";

interface IRadioInputGroupProps<T> {
	onChange: (value: T) => void;
	options: { value: T; label: string; disabled?: boolean }[];
	selectedValue: T;
	small?: boolean;
	title?: string;
}
export function RadioInputGroup<T>({
	className,
	onChange,
	options,
	selectedValue,
	small,
	title
}: TProps<IRadioInputGroupProps<T>>) {
	const classes = useStyles();

	const onClick = useCallback(
		(radioButtonValue: T) => {
			if (!options.find(option => option.value === selectedValue)?.disabled && selectedValue !== radioButtonValue) {
				onChange(radioButtonValue);
			}
		},
		[onChange, options, selectedValue]
	);

	return (
		<div className={classNames(classes.radioGroup, className)}>
			{title?.length && (
				<Typography variant="h3" className={classes.title}>
					{title}
				</Typography>
			)}
			{options.map(option => (
				<RadioInput
					disabled={option.disabled}
					key={typeof option.value === "string" ? option.value : option.label}
					label={option.label}
					onClick={onClick}
					selected={option.value === selectedValue}
					small={small}
					value={option.value}
				/>
			))}
		</div>
	);
}
