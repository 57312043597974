import { createUseStyles } from "react-jss";

const ROLE_ROW_HEIGHT = 38;
const GAP_SIZE = 4;
const PADDING_SIZE = 8;
export const MAX_VISIBLE_ROLES = 6;
const MAX_HEIGHT = ROLE_ROW_HEIGHT * MAX_VISIBLE_ROLES + GAP_SIZE * (MAX_VISIBLE_ROLES - 1) + PADDING_SIZE; // height times visible amount of roles and the gap in the middle

export const useStyles = createUseStyles({
	container: {
		display: "flex",
		flexDirection: "column",
		gap: "var(--spacing-x2)",
		width: "100%"
	},
	rolesList: {
		alignItems: "flex-start",
		display: "flex",
		flexDirection: "column",
		gap: "var(--spacing-x1)",
		maxHeight: `${MAX_HEIGHT}px`,
		minHeight: `${ROLE_ROW_HEIGHT}px`,
		overflow: "auto",
		padding: "var(--spacing-x1) 0",
		"&$loading": {
			minHeight: "80px"
		}
	},
	roleRow: {
		alignItems: "center",
		display: "grid",
		gap: "var(--spacing-x5)",
		gridTemplateColumns: "auto 1fr",
		height: `${ROLE_ROW_HEIGHT}px`,
		minHeight: `${ROLE_ROW_HEIGHT}px`,
		padding: "var(--spacing-x2) var(--spacing-x4) var(--spacing-x2) var(--spacing-x6)",
		width: "100%",
		"&:hover": {
			backgroundColor: "var(--color-blue-300)"
		},
		"&$selectedRow:not(:hover)": {
			backgroundColor: "var(--color-blue-200)"
		}
	},
	searchContainer: {
		alignSelf: "stretch",
		alignItems: "center",
		display: "flex",
		justifyContent: "center",
		padding: "0px var(--spacing-x4)"
	},
	radioInput: {
		padding: 0,
		gap: 0
	},
	selectedRow: {},
	loading: {},
	clickable: {
		cursor: "pointer",
		userSelect: "none",
		msUserSelect: "none",
		MozUserSelect: "none",
		WebkitUserSelect: "none"
	},
	noResults: {
		display: "flex",
		padding: "var(--spacing-x8, 32px) 0 var(--spacing-x8, 32px) var(--spacing-x4, 16px)",
		alignItems: "center",
		alignSelf: "stretch",
		color: "var(--color-grey-700)"
	}
});
