import { Map } from "immutable";
import React, { useMemo } from "react";
import { ImageGrid } from "components/common/ImageGrid";
import { useApplications } from "hooks/useApplications";
import { useIntegrations } from "hooks/useIntegrations";
import { notEmpty } from "utils/comparison";
import type { ApplicationModel } from "models/ApplicationModel";
import type { IntegrationModel } from "models/IntegrationModel";
import type { TNewTicketOption } from "../types";

const getImageByIntegrationId = (
	integrations: Map<string, IntegrationModel> | null,
	applications: Map<string, ApplicationModel> | null,
	integrationId?: string
) => {
	if (!integrationId || !integrations) return null;

	const integration = integrations.get(integrationId);
	if (!integration) return null;

	if (integration.imageUrl) return integration.imageUrl;
	if (!applications || !integration.applicationId) return null;

	const application = applications.get(integration.applicationId);
	if (!application) return null;

	return application.imageUrl;
};

export const useOptionImage = (option: TNewTicketOption, className?: string) => {
	const applications = useApplications();
	const integrations = useIntegrations();

	const image = useMemo(() => {
		if (option.type === "bundle") {
			const sources = option.value.bundleItems
				?.toArray()
				.map(bundleItem =>
					getImageByIntegrationId(
						integrations,
						applications,
						bundleItem.integrationResourceRole.integrationResource?.integrationId
					)
				)
				.filter(notEmpty);
			if (!sources || !sources.length) return null;
			return <ImageGrid images={sources} imageGridClass={className} />;
		}

		const imageUrl = getImageByIntegrationId(
			integrations,
			applications,
			option.value.integrationResource?.integrationId
		);
		if (!imageUrl) return null;
		return <img src={imageUrl} className={className} />;
	}, [applications, className, integrations, option.type, option.value]);

	return image;
};
