import React, { useCallback } from "react";
import { AccountEntity } from "components/common/entities/AccountEntity";
import { IntegrationActorModel } from "models/IntegrationActorModel";
import { SelectOption, type TEntityOverrideProps } from "../SelectOption";
import type { TOptionComponent } from "utils/ui/select";

const AccountOption: TOptionComponent<IntegrationActorModel> = ({
	disabled = false,
	isSelected = false,
	onSelect,
	option,
	optionKey
}) => {
	const renderOption = useCallback(
		(entityProps: TEntityOverrideProps) => <AccountEntity tooltipOnOverflow actor={option} withIcon {...entityProps} />,
		[option]
	);

	return (
		<SelectOption
			renderOption={renderOption}
			disabled={disabled}
			isSelected={isSelected}
			key={optionKey}
			onSelect={onSelect}
			value={option}
		/>
	);
};

AccountOption.isSelectOption = true;

export { AccountOption };
