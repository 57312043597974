import classNames from "classnames";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ApprovalAlgorithmSelect } from "components/common/ApprovalAlgorithmSelect";
import { ProgressBar } from "components/common/ProgressBar";
import { type TUserSelectOption, UserSelect } from "components/common/UserSelect";
import { Button } from "components/ui/Button";
import { IconPrefix } from "components/ui/IconPrefix";
import { AddIcon } from "components/ui/Icons/AddIcon";
import { ArrowRightIcon } from "components/ui/Icons/ArrowRightIcon";
import { CloseIcon } from "components/ui/Icons/CloseIcon";
import { GrantedIcon } from "components/ui/Icons/GrantedIcon";
import { MaintainersIcon } from "components/ui/Icons/MaintainersIcon";
import { OwnerIcon } from "components/ui/Icons/OwnerIcon";
import { RefreshIcon } from "components/ui/Icons/RefreshIcon";
import { RemoveIcon } from "components/ui/Icons/RemoveIcon";
import { RequestsIcon } from "components/ui/Icons/RequestsIcon";
import { UserCircleIcon } from "components/ui/Icons/UserCircleIcon";
import { WorkflowsIcon } from "components/ui/Icons/WorkflowsIcon";
import { LoadingDots } from "components/ui/LoadingDots";
import { Select } from "components/ui/Select";
import { TextOption } from "components/ui/selectOptions/TextOption";
import { UserNodeOption } from "components/ui/selectOptions/UserNodeOption";
import { Typography } from "components/ui/Typography";
import { useApprovalAlgorithm } from "hooks/useApprovalAlgorithm";
import { useUser } from "hooks/useUser";
import { getLabel, type TOptionComponentProps } from "utils/ui/select";
import { MaintainersSelect } from "./components/MaintainersSelect";
import { RequestableSelect } from "./components/RequestableSelect";
import { useStyles } from "./styles";
import { useBulkActionsContext } from "../../bulkActions.context";
import {
	type TAction,
	type TChange,
	type TMaintainerOption,
	type TMaintainersAction,
	ADD,
	AVAILABLE_ACTIONS,
	AVAILABLE_CHANGES,
	AVAILABLE_ROLE_CHANGES,
	getChangeKeyToChangeField,
	MAINTAINER,
	OWNER,
	REMOVE,
	REPLACE,
	REQUESTABLE,
	WORKFLOW
} from "../../utils";
import type { TBulkActionUpdateData } from "context/adminLiveUpdatesContext";
import type { ApprovalAlgorithmModel } from "models/ApprovalAlgorithmModel";
import type { UserModel } from "models/UserModel";
import type { TBulkActionTabOption } from "../../types";

const PROGRESS_BAR_WIDTH = "540px";

type TRemoteMaintainerValue = { id: string; type: "directoryGroup" | "user" };
type TRemoteMaintainerChangeValue = {
	add?: TRemoteMaintainerValue[];
	remove?: TRemoteMaintainerValue[];
	replace?: TRemoteMaintainerValue[];
};

const getTabByModel = (model: TBulkActionUpdateData["model"]): TBulkActionTabOption => {
	if (model === "IntegrationResource") return "resource";
	else if (model === "IntegrationResourceRole") return "role";
	return "integration";
};

const BulkActionSubmitted: FC<{
	getIcon: (change: TChange | null) => IconComponent | undefined;
	isBulkActionDone: boolean;
	isBulkActionFailed: boolean;
}> = React.memo(function BulkActionSubmitted({ isBulkActionFailed, className, getIcon, innerRef, isBulkActionDone }) {
	const { t } = useTranslation("translation", { keyPrefix: "pages.bulkActions.bulkActions" });
	const {
		state: { currentBulkAction }
	} = useBulkActionsContext();

	const { field, value } = useMemo(() => {
		const updateEntries = Object.entries(currentBulkAction?.updates ?? {});
		if (!updateEntries.length) return {};

		const [[updatedField, updatedValue]] = updateEntries as [
			[string, string | boolean | TRemoteMaintainerChangeValue | null]
		];
		return { field: getChangeKeyToChangeField(updatedField), value: updatedValue };
	}, [currentBulkAction]);

	const { approvalAlgorithm } = useApprovalAlgorithm(typeof value === "string" ? value : "");
	const user = useUser(typeof value === "string" ? value : "");

	const classes = useStyles();

	const changeValueIcon = useMemo(() => {
		switch (field) {
			case MAINTAINER:
				return undefined;
			case OWNER:
				return UserCircleIcon;
			case REQUESTABLE:
				return value ? GrantedIcon : CloseIcon;
			case WORKFLOW:
				return WorkflowsIcon;
			default:
				return undefined;
		}
	}, [field, value]);

	const changeValueText = useMemo(() => {
		if (field === OWNER && user) return user.fullName;
		if (field === WORKFLOW && approvalAlgorithm) return approvalAlgorithm.name;
		if (field === REQUESTABLE) return value ? t("inProgress.canRequest") : t("inProgress.cantRequest");

		return "";
	}, [approvalAlgorithm, field, t, user, value]);

	const changingText = useMemo(() => {
		const count = currentBulkAction?.totalItems ?? 0;
		let action: TAction | "changing" = "changing";

		if (field === MAINTAINER) {
			action = Object.keys(value as TRemoteMaintainerChangeValue).at(0)! as TAction;
		}
		const tab = currentBulkAction?.model ? getTabByModel(currentBulkAction.model) : undefined;

		return t(`inProgress.${action}`, { count, context: tab }) + " ";
	}, [currentBulkAction?.model, currentBulkAction?.totalItems, field, t, value]);

	const content = useMemo(() => {
		if (isBulkActionDone) {
			return (
				<div className={classes.bulkActionLoader}>
					<Typography variant="title_sb">{t("inProgress.isDone")}</Typography>
					<GrantedIcon size={44} className={classes.greenIcon} />
				</div>
			);
		}

		if (isBulkActionFailed) {
			return (
				<div className={classes.bulkActionLoader}>
					<Typography variant="title_sb">{t("inProgress.isFailed")}</Typography>
					<CloseIcon size={44} className={classes.redIcon} />
				</div>
			);
		}

		return null;
	}, [classes, isBulkActionDone, isBulkActionFailed, t]);

	return (
		<div className={classNames(classes.wrapper, className)} ref={innerRef}>
			<div
				className={classNames(classes.bulkActionInProgress, {
					[classes.center]: isBulkActionDone || !field || isBulkActionFailed
				})}>
				{content ? (
					content
				) : field ? (
					<>
						<div className={classes.bulkActionInProgressContainer}>
							<Typography variant="title_sb">{t("inProgress.inProgress")}</Typography>
							<div className={classes.bulkActionChangeData}>
								<div className={classes.bulkActionChange}>
									<Typography variant="text_reg">{changingText}</Typography>
									{field ? <IconPrefix Icon={getIcon(field)} content={t(`inProgress.${field}` as const)} /> : null}
								</div>
								{value !== null && field !== "maintainer" ? (
									<>
										<ArrowRightIcon size={24} />
										<div className={classes.bulkActionChange}>
											<Typography variant="text_reg">{t("inProgress.to")}</Typography>
											<IconPrefix Icon={changeValueIcon} content={changeValueText} />
										</div>
									</>
								) : null}
							</div>
						</div>
						<ProgressBar percentage={currentBulkAction?.progress ?? 0} width={PROGRESS_BAR_WIDTH} />
					</>
				) : (
					<LoadingDots size="medium" center />
				)}
			</div>
		</div>
	);
});

export const BulkActions: FC = ({ className, innerRef }) => {
	const { t } = useTranslation("translation", { keyPrefix: "pages.bulkActions.bulkActions" });
	const { t: entitiesTranslation } = useTranslation("translation", { keyPrefix: "entities.plural" });
	const classes = useStyles();
	const [selectedChange, setSelectedChange] = useState<TChange | null>(null);
	const [selectedAction, setSelectedAction] = useState<TMaintainersAction | null>(null);
	const [selectedWorkflowValue, setSelectedWorkflowValue] = useState<ApprovalAlgorithmModel | null>(null);
	const [selectedRequestableValue, setSelectedRequestableValue] = useState<boolean | null>(null);
	const [selectedMaintainerValue, setSelectedMaintainerValue] = useState<TMaintainerOption[] | null>(null);
	const [selectedOwnerValue, setSelectedOwnerValue] = useState<UserModel | null>(null);

	const {
		state: { currentSelection, totalAmount, selectedTab, isBulkActionDone, currentBulkAction, isBulkActionFailed },
		actions: { setIsBulkActionSelected, onBulkAction, addOnDoneCallback }
	} = useBulkActionsContext();

	const reset = useCallback(() => {
		setSelectedChange(null);
		setSelectedAction(null);
		setSelectedWorkflowValue(null);
		setSelectedRequestableValue(null);
		setSelectedMaintainerValue(null);
		setSelectedOwnerValue(null);
		setIsBulkActionSelected(false);
	}, [setIsBulkActionSelected]);

	useEffect(() => {
		addOnDoneCallback("bulkActionSectionReset", reset);
	}, [addOnDoneCallback, reset]);

	const selectedAmount = useMemo(() => {
		return currentSelection.getSelectedAmount(totalAmount);
	}, [currentSelection, totalAmount]);

	const isValid = useMemo(() => {
		if (!selectedChange || !currentSelection.anySelected) return false;
		switch (selectedChange) {
			case MAINTAINER:
				return !!selectedMaintainerValue?.length;
			case OWNER:
				return !!selectedOwnerValue;
			case REQUESTABLE:
				return selectedRequestableValue !== null;
			case WORKFLOW:
				return !!selectedWorkflowValue;
			default:
				return false;
		}
	}, [
		currentSelection.anySelected,
		selectedChange,
		selectedMaintainerValue?.length,
		selectedOwnerValue,
		selectedRequestableValue,
		selectedWorkflowValue
	]);

	const modelTranslation = useMemo(() => {
		if (selectedTab === "integration") return entitiesTranslation("Integration");
		if (selectedTab === "resource") return entitiesTranslation("IntegrationResource");
		if (selectedTab === "role") return entitiesTranslation("IntegrationResourceRole");
		return "";
	}, [selectedTab, entitiesTranslation]);

	const getChangeIcon = useCallback((change: TChange | null) => {
		switch (change) {
			case MAINTAINER:
				return MaintainersIcon;
			case OWNER:
				return OwnerIcon;
			case REQUESTABLE:
				return RequestsIcon;
			case WORKFLOW:
				return WorkflowsIcon;
			default:
				return undefined;
		}
	}, []);

	const getActionIcon = useCallback((action: TMaintainersAction | null): IconComponent | undefined => {
		switch (action) {
			case ADD:
				return AddIcon;
			case REMOVE:
				return RemoveIcon;
			case REPLACE:
				return RefreshIcon;
			default:
				return;
		}
	}, []);

	const onUserChange = useCallback((user: TUserSelectOption | null) => {
		if (typeof user === "string") return;
		setSelectedOwnerValue(user);
	}, []);

	const onChangeSelectedChange = useCallback(
		(value: TChange | null) => {
			setIsBulkActionSelected(!!value);
			let didChange = false;
			setSelectedChange(current => {
				if (current !== value) {
					didChange = true;
				}
				return value;
			});
			if (didChange) {
				setSelectedAction(value === MAINTAINER ? ADD : null);
				setSelectedWorkflowValue(null);
				setSelectedRequestableValue(null);
				setSelectedMaintainerValue([]);
				setSelectedOwnerValue(null);
			}
		},
		[setIsBulkActionSelected]
	);

	const onSubmit = useCallback(async () => {
		if (!selectedChange || !isValid) return;
		let bulkActionOptions;
		switch (selectedChange) {
			case MAINTAINER:
				bulkActionOptions =
					selectedMaintainerValue?.length && selectedAction
						? {
								change: selectedChange,
								action: selectedAction,
								value: selectedMaintainerValue
							}
						: undefined;
				break;
			case OWNER:
				bulkActionOptions = selectedOwnerValue
					? {
							change: selectedChange,
							value: selectedOwnerValue
						}
					: undefined;
				break;
			case REQUESTABLE:
				bulkActionOptions =
					typeof selectedRequestableValue === "boolean"
						? {
								change: selectedChange,
								value: selectedRequestableValue
							}
						: undefined;
				break;
			case WORKFLOW:
				bulkActionOptions = selectedWorkflowValue
					? {
							change: selectedChange,
							value: selectedWorkflowValue
						}
					: undefined;
				break;
			default:
				break;
		}
		if (!bulkActionOptions) return;
		await onBulkAction(bulkActionOptions);
	}, [
		isValid,
		onBulkAction,
		selectedAction,
		selectedChange,
		selectedMaintainerValue,
		selectedOwnerValue,
		selectedRequestableValue,
		selectedWorkflowValue
	]);

	useEffect(() => {
		onChangeSelectedChange(null);
	}, [onChangeSelectedChange, selectedTab]);

	const getChangeOptionLabel = useCallback((option: TChange) => t(`inputs.changeSelected.${option}` as const), [t]);

	const renderChangeLabel = useCallback(
		(option: TChange) => {
			return <IconPrefix size="small" Icon={getChangeIcon(option)} content={getChangeOptionLabel(option)} />;
		},
		[getChangeOptionLabel, getChangeIcon]
	);

	const getActionOptionLabel = useCallback((option: TMaintainersAction) => t(`inputs.withThisAction.${option}`), [t]);

	const renderActionLabel = useCallback(
		(option: TMaintainersAction) => {
			let Icon = undefined;
			switch (option) {
				case ADD:
					Icon = AddIcon;
					break;
				case REMOVE:
					Icon = RemoveIcon;
					break;
				case REPLACE:
					Icon = RefreshIcon;
					break;
				default:
					break;
			}
			return <IconPrefix size="small" Icon={Icon} content={getActionOptionLabel(option)} />;
		},
		[getActionOptionLabel]
	);

	const getOptionRendererForChange = useCallback(
		(props: TOptionComponentProps<TChange>) => {
			return <TextOption {...props} PrefixIcon={getChangeIcon(props.option)} />;
		},
		[getChangeIcon]
	);

	const getOptionRendererForAction = useCallback(
		(props: TOptionComponentProps<TMaintainersAction>) => {
			return <TextOption {...props} PrefixIcon={getActionIcon(props.option)} />;
		},
		[getActionIcon]
	);

	const toValueSelect = useMemo(() => {
		const label = t("inputs.toThis.value");
		const placeholder = t("inputs.toThis.placeholder", { context: selectedChange || "null" });
		switch (selectedChange) {
			case MAINTAINER:
				return (
					<MaintainersSelect
						value={selectedMaintainerValue}
						onChange={setSelectedMaintainerValue}
						withDeleted={selectedAction === "remove"}
					/>
				);
			case OWNER:
				return (
					<UserSelect
						value={selectedOwnerValue}
						onChange={onUserChange}
						label={label}
						placeholder={placeholder}
						renderOption={UserNodeOption}
					/>
				);
			case REQUESTABLE:
				return (
					<RequestableSelect label={label} value={selectedRequestableValue} onChange={setSelectedRequestableValue} />
				);
			case WORKFLOW:
				return (
					<ApprovalAlgorithmSelect
						value={selectedWorkflowValue}
						onChange={setSelectedWorkflowValue}
						label={label}
						placeholder={placeholder}
					/>
				);
			default:
				return (
					<Select
						disabled
						options={[]}
						getOptionLabel={getLabel}
						label={label}
						placeholder={placeholder}
						renderOption={TextOption}
					/>
				);
		}
	}, [
		onUserChange,
		selectedAction,
		selectedChange,
		selectedMaintainerValue,
		selectedOwnerValue,
		selectedRequestableValue,
		selectedWorkflowValue,
		t
	]);

	useEffect(() => {
		if (!selectedAmount) {
			reset();
		}
	}, [reset, selectedAmount]);

	if (currentBulkAction || isBulkActionDone || isBulkActionFailed) {
		return (
			<BulkActionSubmitted
				isBulkActionFailed={isBulkActionFailed}
				className={className}
				getIcon={getChangeIcon}
				innerRef={innerRef}
				isBulkActionDone={isBulkActionDone}
			/>
		);
	}

	return (
		<div className={classNames(classes.wrapper, className)} ref={innerRef}>
			<div className={classes.container}>
				<div className={classes.inputs}>
					<div className={classes.inputPart}>
						<Typography variant="body_sb">{t("inputs.changeSelected.label")}</Typography>
						<Select
							disabled={selectedAmount === 0}
							getOptionLabel={getChangeOptionLabel}
							label={t("inputs.changeSelected.attribute")}
							onChange={onChangeSelectedChange}
							options={selectedTab === "role" ? AVAILABLE_ROLE_CHANGES : AVAILABLE_CHANGES}
							sort={null}
							placeholder={t("inputs.changeSelected.placeholder")}
							renderLabel={renderChangeLabel}
							renderOption={getOptionRendererForChange}
							value={selectedChange}
						/>
					</div>
					{selectedChange === "maintainer" ? (
						<div className={classes.inputPart}>
							<Typography variant="body_sb">{t("inputs.withThisAction.label")}</Typography>
							<Select
								getOptionLabel={getActionOptionLabel}
								hideClear
								label={t("inputs.withThisAction.action")}
								onChange={setSelectedAction}
								options={AVAILABLE_ACTIONS}
								placeholder={t("inputs.withThisAction.placeholder")}
								renderLabel={renderActionLabel}
								value={selectedAction}
								renderOption={getOptionRendererForAction}
							/>
						</div>
					) : null}
					<div className={classes.inputPart}>
						<Typography variant="body_sb">{t("inputs.toThis.label")}</Typography>
						{toValueSelect}
					</div>
				</div>
				<div className={classes.actions}>
					<Button size="medium" disabled={!isValid} onClick={onSubmit}>
						{t("applyChanges", { count: selectedAmount, model: modelTranslation })}
					</Button>
				</div>
			</div>
		</div>
	);
};
