import React from "react";
import { useCompanyContext } from "context/companyContext";
import { Reminder } from "../Reminder";

export const PendingRequestsReminder: FC = () => {
	const {
		state: { company },
		actions: { savePendingRequestsReminder }
	} = useCompanyContext();

	if (!company) return null;

	return (
		<Reminder
			reminderSchedule={company.pendingRequestsReminderSchedule}
			onSave={savePendingRequestsReminder}
			type="pendingRequests"
		/>
	);
};
