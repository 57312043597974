import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "components/ui/legacy/Typography";
import { IChangeConfig } from "hooks/useChangeConfig";
import { useStyles } from "./styles";
import { AuthorizeWithOAuth } from "../AuthorizeWithOAuth";

export const OAuthInput = ({ changeConfig }: { changeConfig: IChangeConfig }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { adapterName, changeOauthConfig, isConfigLoading, oauthError, onOauthError, validConfig } = changeConfig;
	const handleOAuthError = useCallback(
		(error: string, code?: number) => {
			onOauthError({ message: error, code: code !== undefined ? code : 0 });
		},
		[onOauthError]
	);

	const oauthErrorMessage = useMemo(() => {
		if (oauthError) {
			const code = [0, 1, 2].includes(oauthError.code) ? `${oauthError.code}` : "0";
			return t(`pages.integration.oauthError${code as "0" | "1" | "2"}` as const);
		}
		return undefined;
	}, [oauthError, t]);
	return (
		<div className={classes.oauthContainer}>
			<AuthorizeWithOAuth
				adapterName={adapterName}
				className={classes.toggleOauthButton}
				onAuthorize={changeOauthConfig}
				onError={handleOAuthError}
				valid={validConfig === "valid"}
				validating={isConfigLoading}
			/>
			{oauthErrorMessage && <Typography variant="small">{oauthErrorMessage}</Typography>}
		</div>
	);
};
