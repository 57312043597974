import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	selectItem: {
		alignItems: "center",
		backgroundColor: "var(--color-white)",
		boxSizing: "border-box",
		cursor: "pointer",
		display: "flex",
		height: "fit-content",
		margin: "0.2rem 0",
		maxWidth: "100%",
		minHeight: "3.4rem",
		minWidth: "100%",
		overflow: "hidden",
		padding: "0.5rem 1rem",
		textOverflow: "ellipsis",
		transition: "background-color 0.1s",
		whiteSpace: "nowrap",
		width: "fit-content",
		willChange: "background-color",
		"&$highlighted": {
			backgroundColor: "var(--color-grey-background)"
		},
		"&$isWithinGroup": {
			padding: "0.5rem 1.75rem"
		},
		"&.selected": {
			backgroundColor: "var(--color-purple-5)",
			"&$highlighted": {
				backgroundColor: "var(--color-grey-background)"
			}
		},
		"&.disabled": {
			backgroundColor: "var(--color-almost-white)",
			color: "var(--color-grey-disabled)",
			cursor: "default",
			pointerEvents: "none"
		}
	},
	highlighted: {},
	isWithinGroup: {}
});
