import { Record } from "immutable";
import { TVertexType, VertexModel } from "./VertexModel";

const defaults = {
	id: "",
	type: "integration" as Exclude<TVertexType, "account" | "role" | "user">,
	entity: {
		integrationId: "",
		integrationName: "",
		resourcesCount: 0
	},
	step: 0
};

export class IntegrationVertexModel extends Record(defaults) implements VertexModel {
	static fromServerData(data: unknown): IntegrationVertexModel {
		const { id, type, entity, step } = data as typeof defaults;

		return new IntegrationVertexModel({
			id,
			type,
			entity: {
				integrationId: entity.integrationId,
				integrationName: entity.integrationName,
				resourcesCount: entity.resourcesCount
			},
			step
		});
	}
}
