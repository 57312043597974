import constate from "constate";
import { Map } from "immutable";
import { useCallback, useState } from "react";
import { createWebhook, deleteWebhook, enableWebhook, getAllWebhooks } from "api/auditLogsWebhooks";
import { useLoadingState } from "hooks/useLoadingState";
import { AuditLogsWebhookModel } from "models/AuditLogsWebhookModel";

const useAuditLogsWebhooks = () => {
	const [auditLogsWebhooks, setAuditLogsWebhooks] = useState<Map<string, AuditLogsWebhookModel> | null>(null);
	const { withLoader, isLoading } = useLoadingState();

	const loadWebhooks = useCallback(async () => {
		const loadedWebhooks = await withLoader(getAllWebhooks());
		setAuditLogsWebhooks(Map<string, AuditLogsWebhookModel>(loadedWebhooks.map(webhook => [webhook.id, webhook])));
	}, [withLoader]);

	const createAuditLogsWebhook = useCallback(
		async (params: {
			url: string;
			headers: Record<string, unknown> | null;
			additionalAuditLogParams: Record<string, unknown> | null;
		}) => {
			const createdWebhook = await createWebhook(params);
			setAuditLogsWebhooks(current => current?.set(createdWebhook.id, createdWebhook) ?? current);
		},
		[]
	);

	const deleteAuditLogsWebhook = useCallback(async (webhookId: string) => {
		await deleteWebhook(webhookId);
		setAuditLogsWebhooks(current => (current?.has(webhookId) ? current.delete(webhookId) : current));
	}, []);

	const enableAuditLogsWebhook = useCallback(async (webhookId: string) => {
		await enableWebhook(webhookId);
		setAuditLogsWebhooks(current => (current?.has(webhookId) ? current.setIn([webhookId, "enabled"], true) : current));
	}, []);

	return {
		state: { auditLogsWebhooks, isLoading },
		actions: { loadWebhooks, createAuditLogsWebhook, deleteAuditLogsWebhook, enableAuditLogsWebhook }
	};
};

export const [AuditLogsWebhooksProvider, useAuditLogsWebhooksContext] = constate(useAuditLogsWebhooks);
