import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "components/ui/Form";
import { Input } from "components/ui/Input";
import { TextAreaInput } from "components/ui/TextAreaInput";
import { removeRedundantSpaces } from "utils/strings";
import { useStyles } from "./styles";
import { CommonConfigFields } from "../CommonConfigFields";
import type { IConfigForm } from "types/directoryConfiguration";

export const AzureConfigurationForm: FC<IConfigForm> = ({ onSave, error, enableSave, shouldReset, inEditMode }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const [tenant, setTenant] = useState("");
	const [clientId, setClientId] = useState("");
	const [secret, setSecret] = useState("");

	const isValid = useMemo(() => {
		return (
			enableSave &&
			!!(removeRedundantSpaces(tenant) && removeRedundantSpaces(clientId) && removeRedundantSpaces(secret))
		);
	}, [clientId, enableSave, secret, tenant]);

	const submit = useCallback(
		async (agentTokenId?: string | null, isDirectManagerSource?: boolean) => {
			if (!isValid) return;

			const configuration = {
				tenant,
				clientId,
				secret
			};
			await onSave(configuration, isDirectManagerSource, agentTokenId);
		},
		[clientId, isValid, onSave, secret, tenant]
	);

	useEffect(() => {
		if (shouldReset) {
			setTenant("");
			setClientId("");
			setSecret("");
		}
	}, [shouldReset]);

	return (
		<>
			<Form.Field>
				<Input
					label={t("pages.settings.directoryConfiguration.azureAD.tenant")}
					value={tenant}
					onValueChange={setTenant}
					isRequired
				/>
			</Form.Field>
			<Form.Field>
				<Input
					label={t("pages.settings.directoryConfiguration.azureAD.clientId")}
					value={clientId}
					onValueChange={setClientId}
					isRequired
				/>
			</Form.Field>
			<Form.Field>
				<TextAreaInput
					autoResize
					inputClassName={classes.textareaInput}
					isRequired
					label={t("pages.settings.directoryConfiguration.azureAD.secret")}
					onValueChange={setSecret}
					textAreaClassName={classes.textarea}
					value={secret}
				/>
			</Form.Field>
			<CommonConfigFields inEditMode={inEditMode} onSave={submit} error={error} disabled={!isValid} />
		</>
	);
};
