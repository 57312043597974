import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { LoadingSpinner } from "components/ui/LoadingSpinner";
import { useOnMount } from "hooks/useOnMount";
import { RuleModel } from "models/RuleModel";
import { RulesProvider, useRulesContext } from "../RulesPage/RulesContext";
import { RuleForm } from "./";

const EditRulePageContent: FC = () => {
	const { ruleId } = useParams<{ ruleId: string }>();
	const {
		state: { rulesMap }
	} = useRulesContext();

	const [initialRule, setInitialRule] = useState<RuleModel | undefined>(undefined);
	useOnMount(() => {
		setInitialRule(ruleId ? rulesMap.get(ruleId) : undefined);
	});

	return initialRule ? <RuleForm initialRule={initialRule} /> : null;
};
const EditRulePageContentWrapper: FC = () => {
	const {
		state: { isLoading }
	} = useRulesContext();

	if (isLoading) return <LoadingSpinner size="big" />;
	return <EditRulePageContent />;
};

export const EditRulePage: FC = () => {
	return (
		<RulesProvider>
			<EditRulePageContentWrapper />
		</RulesProvider>
	);
};
