import { createUseStyles } from "react-jss";

type TStyleProps = {
	size?: number;
};

const getSize = ({ size }: TStyleProps) => {
	return size ? `${size}px` : "1em";
};
export const useStyles = createUseStyles({
	icon: {
		"& > *": {
			height: "100%",
			width: "100%"
		},
		height: "1em",
		width: "1em",
		display: "inline-flex",
		alignItems: "center",
		flexShrink: 0,
		"&$customSize": {
			height: getSize,
			width: getSize,
			fontSize: getSize
		},
		"&$small": {
			fontSize: "12px"
		},
		"&$medium": {
			fontSize: "16px"
		},
		"&$large": {
			fontSize: "16px"
		},
		"&$xl": {
			fontSize: "var(--icon-xl-size)"
		}
	},
	small: {},
	medium: {},
	large: {},
	xl: {},
	customSize: {}
});
