import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Checkbox } from "components/ui/Checkbox";
import { TTicketDuration } from "utils/durationsOptions";
import { useStyles } from "./styles";

interface IProps {
	duration: TTicketDuration;
	onToggle: (duration: TTicketDuration) => Promise<void> | void;
	isChecked: (duration: TTicketDuration) => boolean;
	isDisabled?: (duration: TTicketDuration) => boolean;
}

export const AllowedDuration: FC<IProps> = ({ duration, onToggle, isChecked, isDisabled }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const toggle = useCallback(() => onToggle(duration), [duration, onToggle]);
	const checked = useMemo(() => isChecked(duration), [duration, isChecked]);
	const disabled = useMemo(() => (isDisabled ? isDisabled(duration) : false), [duration, isDisabled]);

	return (
		<div className={classes.container}>
			<Checkbox selected={checked} disabled={disabled} onClick={toggle} label={t(`common.durations.${duration}`)} />
		</div>
	);
};
