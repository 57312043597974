import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import type { TTicketDuration } from "utils/durationsOptions";

export const useAllowedDurationsTextConvertor = () => {
	const { t } = useTranslation();

	const convertAllowedDurationsText = useCallback(
		(value: unknown) => {
			if (!value) return;

			const parsedDurations = [] as string[];
			value = (value as number[]).sort((a, b) => a - b);

			for (const duration of value as number[]) {
				parsedDurations.push(t(`common.durations.${duration as TTicketDuration}`));
			}

			return parsedDurations.join(", ");
		},
		[t]
	);

	return { convertAllowedDurationsText };
};
