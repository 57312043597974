import { Record as ImmutableRecord } from "immutable";
import type { IEntityOption, TEntityOption } from "utils/entityOptionType";

export type TOnCallType = "PagerDuty" | "Opsgenie";

const defaults = {
	id: "",
	name: "",
	onCallIntegrationId: "",
	isDeleted: false,
	type: "" as TOnCallType
};

export class OnCallIntegrationScheduleModel
	extends ImmutableRecord<typeof defaults>(defaults)
	implements IEntityOption<"OnCallIntegrationSchedule">
{
	static fromServerData(data: unknown) {
		const parsedData = data as typeof defaults;
		return new OnCallIntegrationScheduleModel(parsedData);
	}

	toEntityOption(): TEntityOption<"OnCallIntegrationSchedule"> {
		return {
			displayName: this.name,
			identifier: this.id,
			onCallScheduleType: this.type,
			type: "OnCallIntegrationSchedule",
			isDeleted: this.isDeleted
		};
	}
}
