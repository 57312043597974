import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	contentRow: {
		alignItems: "center",
		display: "flex",
		gap: "3px",
		justifyContent: "space-between",
		width: "100%"
	},
	resourcesText: {
		flexGrow: 1
	},
	rolesText: { flexGrow: 1 }
});
