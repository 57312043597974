import React from "react";
import { useCompanyContext } from "context/companyContext";
import { Reminder } from "../Reminder";

export const PendingAccessReportsReminder: FC = () => {
	const {
		state: { company },
		actions: { saveAccessReportsReminder }
	} = useCompanyContext();

	if (!company) return null;

	return (
		<Reminder
			reminderSchedule={company.pendingAccessReportsReminderSchedule}
			onSave={saveAccessReportsReminder}
			type="accessReports"
		/>
	);
};
