import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { getIntegrationResourceTypes } from "api/accessRequestForm";
import { MultipleSelect } from "components/ui/MultipleSelect";
import { TextOption } from "components/ui/selectOptions/TextOption";
import { useSelectSearchProps } from "hooks/useSelectSearchProps";
import { getLabel } from "utils/ui/select";
import type { TInputSize } from "components/ui/fieldHelpers/types";

const LIMIT = 100;

interface IProps {
	integrationIds: string[];
	setResourceTypes: (value: string[] | null) => void;
	userId: string;
	resourceTypes: string[] | null;
	inputClassName?: string;
	size?: TInputSize;
}

export const ResourceTypesInput: FC<IProps> = ({
	className,
	integrationIds,
	inputClassName,
	userId,
	setResourceTypes,
	size,
	resourceTypes
}) => {
	const { t } = useTranslation(undefined, { keyPrefix: "pages.newTicket.newTicketForm.resourceTypesInput" });

	const fetchResourceTypes = useCallback(
		async (query: string) => {
			if (!integrationIds.length) return [];
			const response = await getIntegrationResourceTypes({ integrationIds, userId, search: query, perPage: LIMIT });
			return response.result.toArray();
		},
		[integrationIds, userId]
	);
	const { selectProps } = useSelectSearchProps(fetchResourceTypes, resourceTypes, true);

	return (
		<MultipleSelect
			{...selectProps}
			className={className}
			disabled={!integrationIds.length}
			filter={null}
			inputClassName={inputClassName}
			label={t("label")}
			limit={LIMIT}
			getOptionLabel={getLabel}
			onChange={setResourceTypes}
			renderOption={TextOption}
			placeholder={t("placeholder")}
			size={size}
			sort={null}
			value={resourceTypes}
		/>
	);
};
