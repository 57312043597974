import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getIntegrationResources, type TFullIntegrationResourceModel } from "api/integrationResources";
import { FilterExpressionEmptyState } from "components/common/FilterExpressionEmptyState";
import { ResourceChip } from "components/ui/chips/ResourceChip";
import { FilterExpression } from "components/ui/filters/FilterExpression";
import { ResourcesIcon } from "components/ui/Icons/ResourcesIcon";
import { ResourceOption } from "components/ui/selectOptions/ResourceOption";
import { IntegrationResourceIdFilter } from "filters/integrationResource";
import { IntegrationResourceRoleIntegrationResourceIdFilter } from "filters/integrationResourceRole";
import { useIntegrations } from "hooks/useIntegrations";
import { getIntegrationResourceFilters } from "utils/api/filters";
import { notEmpty } from "utils/comparison";
import { getLabel, type TOptionComponent } from "utils/ui/select";
import { useFetchModels, useFilterFormExpression, useInitialLoading } from "./filter.hooks";
import type { IntegrationResourceModel } from "models/IntegrationResourceModel";
import type { TFilterOperator } from "types/filters";
import type { Constructor } from "types/utilTypes";

type TIntegrationResourceIdFilters = IntegrationResourceIdFilter | IntegrationResourceRoleIntegrationResourceIdFilter;

type TIntegrationResourceIdFilterProps = {
	filter: TIntegrationResourceIdFilters;
	onChange: (filter: TIntegrationResourceIdFilters | undefined, isValid: boolean) => void;
};

function getFilter(filterName: TIntegrationResourceIdFilters["name"]): Constructor<TIntegrationResourceIdFilters> {
	switch (filterName) {
		case IntegrationResourceIdFilter.filterName:
			return IntegrationResourceIdFilter;
		default:
			return IntegrationResourceRoleIntegrationResourceIdFilter;
	}
}

const isValidOperator = (operator: TFilterOperator): operator is TIntegrationResourceIdFilters["operator"] =>
	operator === "is" || operator === "isNot";

const OPERATORS = ["is", "isNot"] as TFilterOperator[];

export const ResourceIdFilterExpression: FC<TIntegrationResourceIdFilterProps> = ({
	className,
	innerRef,
	filter,
	onChange
}) => {
	const { t } = useTranslation("translation", { keyPrefix: "pages.bulkActions.filters" });

	const {
		selectedModels: selectedResourcesModels,
		isInitialLoading,
		setSelectedModels
	} = useInitialLoading({
		fetch: getIntegrationResources,
		fetchOptions: {
			filters: getIntegrationResourceFilters({ id: filter.value }),
			perPage: filter.value.length
		},
		canFetch: !!filter?.value.length
	});

	const { items, isLoading, setSearchQuery } = useFetchModels({
		fetch: getIntegrationResources,
		getFilters: getIntegrationResourceFilters
	});

	const integrations = useIntegrations();

	const resourceOptions = useMemo(() => {
		return items?.toArray() || [];
	}, [items]);

	const selectedResources = useMemo(() => {
		if (!filter) return [];
		return filter.value.map(id => selectedResourcesModels.get(id)).filter(notEmpty);
	}, [filter, selectedResourcesModels]);

	const { clearFilter, removeFilter } = useFilterFormExpression<TIntegrationResourceIdFilters>({
		filterName: filter.name,
		onChange,
		getFilter
	});

	const onOperatorSelect = useCallback(
		(operator: TFilterOperator) => {
			if (!filter) return;
			if (!isValidOperator(operator)) return;
			onChange(filter.set("operator", operator), filter.value.length > 0);
		},
		[filter, onChange]
	);

	const onOptionSelect = useCallback(
		(option: IntegrationResourceModel) => {
			if (!filter || !option) return;
			const currentValue = filter.value;
			const newValue = currentValue.includes(option.id)
				? currentValue.filter(id => id !== option.id)
				: [...currentValue, option.id];
			onChange(filter.set("value", newValue), newValue.length > 0);
			setSelectedModels(prev => prev.set(option.id, option as TFullIntegrationResourceModel));
		},
		[filter, onChange, setSelectedModels]
	);

	const renderSelected = useCallback(
		(option: IntegrationResourceModel) => (
			<ResourceChip size="large" integrationResource={option} selected onDelete={() => onOptionSelect(option)} />
		),
		[onOptionSelect]
	);

	return (
		<FilterExpression
			className={className}
			emptyState={<FilterExpressionEmptyState text={t("values.emptyResources")} Icon={ResourcesIcon} />}
			filter={null}
			getMoreOptions={setSearchQuery}
			getOptionLabel={getLabel}
			innerRef={innerRef}
			inputPlaceholder={t("placeholders.resourceId")}
			isLoading={!integrations || isInitialLoading || isLoading}
			onOperatorSelect={onOperatorSelect}
			onOptionSelect={onOptionSelect}
			onRemoveFilter={removeFilter}
			onReset={clearFilter}
			operators={OPERATORS}
			optionRenderer={ResourceOption as TOptionComponent<TFullIntegrationResourceModel>}
			options={resourceOptions}
			renderSelected={renderSelected}
			selected={selectedResources}
			selectedOperator={filter.operator}
			title={t(`title.${filter.name}`)}
			type="multiSelect"
		/>
	);
};
