import { List, Record } from "immutable";
import { AccessReviewReportModel } from "./AccessReviewReportModel";
import { AccessReviewResourcePermissionModel } from "./AccessReviewResourcePermissionModel";
import { IntegrationResourceModel } from "./IntegrationResourceModel";

interface IAccessReviewResourceStatistics {
	permissionsStatus: {
		pending: number;
		approved: number;
		denied: number;
		flagged: number;
	};
}

const defaults = {
	accessReviewReportId: "",
	accessReviewReport: null as AccessReviewReportModel | null,
	accessReviewResourcePermissions: null as List<AccessReviewResourcePermissionModel> | null,
	id: "",
	integrationResource: null as IntegrationResourceModel | null,
	integrationResourceId: "",
	permissionsCount: null as number | null,
	statistics: null as IAccessReviewResourceStatistics | null
};

export type TAccessReviewResourceModel = typeof defaults;

export class AccessReviewResourceModel extends Record<TAccessReviewResourceModel>(defaults) {
	static fromServerData(data: unknown): AccessReviewResourceModel {
		const {
			accessReviewReportId,
			accessReviewReport,
			accessReviewResourcePermissions,
			id,
			integrationResource,
			integrationResourceId,
			permissionsCount,
			statistics
		} = data as TAccessReviewResourceModel;
		return new AccessReviewResourceModel({
			accessReviewReportId,
			accessReviewReport: accessReviewReport ? AccessReviewReportModel.fromServerData(accessReviewReport) : null,
			accessReviewResourcePermissions: accessReviewResourcePermissions
				? List(accessReviewResourcePermissions.map(AccessReviewResourcePermissionModel.fromServerData))
				: null,
			id,
			integrationResource: integrationResource ? IntegrationResourceModel.fromServerData(integrationResource) : null,
			integrationResourceId,
			permissionsCount,
			statistics
		});
	}

	public get permissionsSize(): number {
		return this.permissionsCount || this.accessReviewResourcePermissions?.size || 0;
	}

	public get notPendingPermissionsSize(): number {
		if (this.statistics) {
			return this.permissionsSize - (this.statistics.permissionsStatus.pending || 0);
		}
		if (this.accessReviewResourcePermissions) {
			return this.accessReviewResourcePermissions.filter(permission => permission.status !== "pending").size;
		}
		return 0;
	}

	public get status(): "pending" | "inProgress" | "done" {
		if (this.notPendingPermissionsSize > 0) {
			return this.notPendingPermissionsSize === this.permissionsSize ? "done" : "inProgress";
		}
		return "pending";
	}
}
