import Ajv, { Options } from "ajv";
import addFormats, { FormatName } from "ajv-formats";

const formats: FormatName[] = [
	"date-time",
	"time",
	"date",
	"email",
	"hostname",
	"ipv4",
	"ipv6",
	"uri",
	"uri-reference",
	"uuid",
	"uri-template",
	"json-pointer",
	"relative-json-pointer",
	"regex"
];

export const generateAjv = (opts?: Options) =>
	addFormats(new Ajv(opts), { formats }).addKeyword("kind").addKeyword("modifier").addKeyword("name");
