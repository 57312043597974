import { compiledClientConfig } from "config";

export const isDevelopment =
	compiledClientConfig.appEnv === "development" || compiledClientConfig.appEnv === "localhost";

export const devLog = (logOptions: {
	message: string;
	extra?: object;
	level?: "debug" | "info" | "warn" | "error";
}) => {
	if (!isDevelopment) return;
	const { message, extra, level = "info" } = logOptions;
	switch (level) {
		case "debug":
			console.debug(message, extra);
			break;
		case "info":
			// no-dd-sa:typescript-best-practices/no-console
			console.info(message, extra);
			break;
		case "warn":
			// no-dd-sa:typescript-best-practices/no-console
			console.warn(message, extra);
			break;
		case "error":
			// no-dd-sa:typescript-best-practices/no-console
			console.error(message, extra);
			break;
		default:
			break;
	}
};
