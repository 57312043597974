import React, { useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { ViewErrorButton } from "components/common/ViewErrorButton";
import { List } from "components/ui/List";
import { LoadingDots } from "components/ui/LoadingDots";
import { useIntegrationsContext } from "context/integrationsContext";
import { useAgentTokens } from "hooks/useAgentTokens";
import { useApprovalAlgorithms } from "hooks/useApprovalAlgorithms";
import { useAsyncImpact } from "hooks/useAsyncImpact";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { useIntegrations } from "hooks/useIntegrations";
import { useUser } from "hooks/useUser";
import { boldComponent } from "i18n";
import { IntegrationAuditLogModel } from "models/auditLogs";
import { IIntegrationAuditLogDiffData } from "models/auditLogs/IntegrationAuditLogModel";
import { IntegrationModel } from "models/IntegrationModel";
import { UserModel } from "models/UserModel";
import { getAuditLogDataValues } from "utils/auditLogs/auditLogUtils";
import { IsNullError } from "utils/errors/isNullError";
import { TC } from "utils/tickets/ticketActivity";
import { useStyles } from "./styles";
import { AuditLogUser } from "../AuditLogUser";
import type { TTicketDuration } from "utils/durationsOptions";
import type { TAuditLogContentComponent } from "./AuditLogContent.types";

const TRANSLATION_PREFIX = "pages.auditLog.auditLogList.integration";

type TTranslationTypes =
	| "IntegrationCreated.title"
	| "IntegrationCreated.titleWithUser"
	| "IntegrationCreated.owner"
	| "IntegrationCreated.workflow"
	| "IntegrationDeleted"
	| "IntegrationSyncAccountsFailed"
	| "IntegrationSyncResourcesFailed"
	| "IntegrationSyncPermissionsFailed"
	| "IntegrationAccessGrantFailed"
	| "IntegrationAccessRevokeFailed"
	| "IntegrationSyncAccountsSucceeded"
	| "IntegrationSyncResourcesSucceeded"
	| "IntegrationSyncPermissionsSucceeded"
	| "IntegrationUpdatedAllowRequests.allow"
	| "IntegrationUpdatedAllowRequests.prevent"
	| "IntegrationUpdatedWorkflow"
	| "IntegrationUpdatedConnection"
	| "IntegrationUpdatedAllowRequestsByDefault.enabled"
	| "IntegrationUpdatedAllowRequestsByDefault.disabled"
	| "IntegrationUpdatedAllowRequestsByDefault.enabledAndUpdate"
	| "IntegrationUpdatedAllowRequestsByDefault.disabledAndUpdate"
	| "IntegrationUpdatedMaintainers"
	| "IntegrationUpdatedName.same"
	| "IntegrationUpdatedName.different"
	| "IntegrationUpdatedNotifyAboutExternalPermissionsChanges.enabled"
	| "IntegrationUpdatedNotifyAboutExternalPermissionsChanges.disabled"
	| "IntegrationUpdatedOwner"
	| "IntegrationUpdatedReadOnly"
	| "IntegrationUpdatedAutoAssignRecommendedResourceMaintainers.start"
	| "IntegrationUpdatedAutoAssignRecommendedResourceMaintainers.stop"
	| "IntegrationUpdatedAutoAssignRecommendedResourceOwners.start"
	| "IntegrationUpdatedAutoAssignRecommendedResourceOwners.stop"
	| "IntegrationUpdatedIcon"
	| "IntegrationUpdatedOverrideAllowedDurations.enabled"
	| "IntegrationUpdatedOverrideAllowedDurations.disabled"
	| "IntegrationUpdatedOverrideAllowedDurations.changed"
	| "IntegrationUpdatedAllowChangingAccountPermissions.enabled"
	| "IntegrationUpdatedAllowChangingAccountPermissions.disabled"
	| "IntegrationUpdatedAllowCreatingAccounts.enabled"
	| "IntegrationUpdatedAllowCreatingAccounts.disabled";

const getEventTranslationPath = (
	auditLog: IntegrationAuditLogModel,
	integration: IntegrationModel | null,
	user?: UserModel | null
): TTranslationTypes[] => {
	const action = auditLog.action;
	if (action === "IntegrationCreated") {
		const titlePath = user ? ("IntegrationCreated.titleWithUser" as const) : ("IntegrationCreated.title" as const);
		return [titlePath, "IntegrationCreated.owner" as const, "IntegrationCreated.workflow" as const];
	}

	if (action === "IntegrationUpdatedName") {
		if (!auditLog.data?.name) return [];
		const { to } = auditLog.data.name as { to?: string };
		return [
			integration?.name === to && to
				? ("IntegrationUpdatedName.same" as const)
				: ("IntegrationUpdatedName.different" as const)
		];
	}

	if (action === "IntegrationUpdatedNotifyAboutExternalPermissionsChanges") {
		if (!auditLog.data?.notifyAboutExternalPermissions) return [];
		const { to } = auditLog.data.notifyAboutExternalPermissions as { to?: boolean };
		return [`IntegrationUpdatedNotifyAboutExternalPermissionsChanges.${to ? "enabled" : "disabled"}` as const];
	}

	if (action === "IntegrationUpdatedAllowRequests") {
		if (!auditLog.data?.allowsRequests) return [];
		const { to } = auditLog.data.allowsRequests as { to?: boolean };
		return [`IntegrationUpdatedAllowRequests.${to ? "allow" : "prevent"}` as const];
	}

	if (action === "IntegrationUpdatedAllowRequestsByDefault") {
		if (!auditLog.data) return [];
		const { to } = auditLog.data.defaultAllowsRequests as { to?: boolean };
		const { updateAllResources } = auditLog.data;
		const subEvent = `${to ? "enabled" : "disabled"}${updateAllResources ? "AndUpdate" : ""}` as const;
		return [`IntegrationUpdatedAllowRequestsByDefault.${subEvent}` as const];
	}

	if (action === "IntegrationUpdatedAutoAssignRecommendedResourceMaintainers") {
		if (!auditLog.data) return [];
		const { to } = auditLog.data.autoAssignRecommendedResourceMaintainers as { to?: boolean };
		const subEvent = to ? "start" : ("stop" as const);
		return [`IntegrationUpdatedAutoAssignRecommendedResourceMaintainers.${subEvent}` as const];
	}

	if (action === "IntegrationUpdatedAutoAssignRecommendedResourceOwners") {
		if (!auditLog.data) return [];
		const { to } = auditLog.data.autoAssignRecommendedResourceOwner as { to?: boolean };
		const subEvent = to ? "start" : ("stop" as const);
		return [`IntegrationUpdatedAutoAssignRecommendedResourceOwners.${subEvent}` as const];
	}

	if (action === "IntegrationUpdatedAllowCreatingAccounts") {
		if (!auditLog.data) return [];
		const { to } = auditLog.data.canCreateActors as { to?: boolean };
		const subEvent = to ? "enabled" : ("disabled" as const);
		return [`IntegrationUpdatedAllowCreatingAccounts.${subEvent}` as const];
	}

	if (action === "IntegrationUpdatedAllowChangingAccountPermissions") {
		if (!auditLog.data) return [];
		const { to } = auditLog.data.canEditPermissions as { to?: boolean };
		const subEvent = to ? "enabled" : ("disabled" as const);
		return [`IntegrationUpdatedAllowChangingAccountPermissions.${subEvent}` as const];
	}

	if (action === "IntegrationUpdatedOverrideAllowedDurations") {
		if (!auditLog.data) return [];
		const { to, from } = auditLog.data.allowedDurations as { to?: number[] | null; from?: number[] | null };
		if (!to?.length) {
			return ["IntegrationUpdatedOverrideAllowedDurations.disabled" as const];
		} else if (!from?.length) {
			return ["IntegrationUpdatedOverrideAllowedDurations.enabled" as const];
		}
		return ["IntegrationUpdatedOverrideAllowedDurations.changed" as const];
	}

	return [action];
};

const Content: TAuditLogContentComponent<IntegrationAuditLogModel> = ({ auditLog, logUser }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const approvalAlgorithms = useApprovalAlgorithms();
	const tokens = useAgentTokens();
	const {
		actions: { loadIntegration }
	} = useIntegrationsContext();
	const integrations = useIntegrations(true);
	const [integration, setIntegration] = useState<IntegrationModel | null>(null);
	const openGlobalErrorModal = useOpenGlobalErrorModal();

	useAsyncImpact(async () => {
		if (integrations) {
			let newIntegration = integrations.get(auditLog.integrationId);
			if (!newIntegration) {
				newIntegration = await loadIntegration(auditLog.integrationId);
				if (!newIntegration) {
					openGlobalErrorModal(
						IsNullError.from({
							location: "integrationAuditLogContentIntegration",
							parentObject: {
								name: "integrationAuditLog",
								value: auditLog.toJS()
							},
							requestedProperty: "integration"
						})
					);
					newIntegration = new IntegrationModel();
				}
			}
			setIntegration(newIntegration ?? null);
		}
	}, [auditLog, integrations, loadIntegration, openGlobalErrorModal]);

	const values = useMemo(() => {
		if (!integration) return {};
		const data = auditLog.data || ({} as IIntegrationAuditLogDiffData);
		return getAuditLogDataValues(data, [
			{
				field: "name",
				valueField: "integrationName",
				valueConvertor: (value: unknown) => value as string | undefined,
				currentValue: integration.name
			},
			{
				field: "defaultApprovalAlgorithmId",
				valueField: "approvalAlgorithmName",
				valueConvertor: (value: unknown) => approvalAlgorithms?.get(value as string)?.name || ""
			},
			{
				field: "ownerUserId",
				valueField: "owner"
			},
			{
				field: "token",
				valueField: "token",
				valueConvertor: (value: unknown) => tokens?.find(token => token.id === value)?.name
			},
			{
				field: "allowedDurations",
				valueField: "allowedDurations",
				valueConvertor: (value: unknown) => {
					const parsedDurations = [] as string[];
					if (!value) return;
					value = (value as number[]).sort((a, b) => a - b);
					for (const duration of value as number[]) {
						parsedDurations.push(t(`common.durations.${duration as TTicketDuration}`));
					}
					return parsedDurations.join(", ");
				}
			}
		]);
	}, [approvalAlgorithms, auditLog.data, integration, t, tokens]);

	const receiverUser = useUser(auditLog.data?.receiverUserId);

	const integrationLogUser = logUser || receiverUser;

	const props = useMemo(
		() => ({
			values,
			components: {
				bold: boldComponent,
				user: integrationLogUser ? <AuditLogUser user={integrationLogUser} className={classes.userText} /> : <TC />,
				oldOwner: values.owner?.old ? <AuditLogUser user={values.owner.old} className={classes.userText} /> : <TC />,
				owner: values.owner ? (
					<AuditLogUser
						user={values.owner.current ? values.owner.current : values.owner.new!}
						className={classes.userText}
					/>
				) : (
					<TC />
				),
				viewError: auditLog.data?.errorMessage ? <ViewErrorButton error={auditLog.data.errorMessage} /> : <TC />
			}
		}),
		[auditLog.data?.errorMessage, classes.userText, integrationLogUser, values]
	);

	const [title, ...details] = useMemo(() => {
		const paths = getEventTranslationPath(auditLog, integration, integrationLogUser);
		return paths.map(key => `${TRANSLATION_PREFIX}.${key}` as const);
	}, [auditLog, integration, integrationLogUser]);

	return integrations && integration ? (
		<>
			<div>
				<Trans t={t} {...props} i18nKey={title} />
			</div>
			{details && (
				<List
					items={details.map(key => (
						<Trans t={t} key={key} i18nKey={key} {...props} />
					))}
				/>
			)}
		</>
	) : (
		<LoadingDots />
	);
};

export const IntegrationAuditLogContent: TAuditLogContentComponent<IntegrationAuditLogModel> = React.memo(Content);
