import { Record, List } from "immutable";
import { IntegrationActorModel } from "./IntegrationActorModel";
import { IntegrationResourceRoleModel } from "./IntegrationResourceRoleModel";
import { TicketPermissionModel } from "./TicketPermissionModel";
import type { TRevokeStatus } from "models/IntegrationActorPermissionModel";

const defaults = {
	id: "",
	revokeStatus: "notRevoking" as TRevokeStatus,
	actor: null as IntegrationActorModel | null,
	role: null as IntegrationResourceRoleModel | null,
	ticketPermissions: null as List<TicketPermissionModel> | null
};

export class UserEntitlementModel extends Record<typeof defaults>(defaults) {
	static fromServerData(data: unknown) {
		const { role, actor, id, revokeStatus, ticketPermissions } = data as typeof defaults;
		return new UserEntitlementModel({
			id,
			revokeStatus,
			role: role ? IntegrationResourceRoleModel.fromServerData(role) : null,
			actor: actor ? IntegrationActorModel.fromServerData(actor) : null,
			ticketPermissions: ticketPermissions ? List(ticketPermissions.map(TicketPermissionModel.fromServerData)) : List()
		});
	}
}
