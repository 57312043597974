import { List } from "immutable";
import { AccessReviewReportModel } from "models/AccessReviewReportModel";
import { useAccessReviewReport } from "./useAccessReviewReport";
import { useUserAccessReviewReports } from "./useUserAccessReviewReports";

interface IAccessReviewReportState {
	accessReviewReport: AccessReviewReportModel | undefined;
	getAccessReviewReport: () => Promise<AccessReviewReportModel | null>;
	loading: boolean;
	reports: List<AccessReviewReportModel> | null;
}

export const useAccessReviewReportState = (reportId: string): IAccessReviewReportState => {
	const { accessReviewReport, getAccessReviewReport, getActionLoading } = useAccessReviewReport(reportId);

	const { reports, isLoading } = useUserAccessReviewReports();

	if (!reportId)
		return { accessReviewReport: undefined, getAccessReviewReport, loading: false, reports: reports ?? null };

	return {
		accessReviewReport,
		getAccessReviewReport,
		loading: getActionLoading || isLoading,
		reports: reports ?? null
	};
};
