import dayjs from "dayjs";
import "dayjs/locale/en"; // import locale
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale";

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.locale("en");
dayjs.updateLocale("en", {
	relativeTime: {
		future: "in %s",
		past: "%s ago",
		mm: "%d minutes",
		hh: "%d hours",
		dd: "%d days",
		MM: "%d months",
		yy: "%d years",
		s: "1 minute",
		m: "1 minute",
		h: "1 hour",
		d: "1 day",
		M: "1 month",
		y: "1 year"
	}
});

export { dayjs };
