import { Map, Record } from "immutable";
import { AccessReviewModel } from "models/AccessReviewModel";

type TAccessReviewAuditLogAction = "UserAccessReviewActivated" | "UserAccessReviewCreated" | "UserAccessReviewDeleted";

const defaults = {
	action: "UserAccessReviewActivated" as TAccessReviewAuditLogAction,
	accessReview: null as AccessReviewModel | null,
	accessReviewId: "",
	id: "",
	createdAt: new Date(0),
	data: null as Map<string, unknown> | null,
	userId: ""
};

export class AccessReviewAuditLogModel extends Record<typeof defaults>(defaults) {
	static readonly type = "accessReview";

	static fromServerData(data: unknown) {
		const { id, createdAt, data: _data, action, accessReview, accessReviewId, userId } = data as typeof defaults;

		return new AccessReviewAuditLogModel({
			id,
			createdAt: new Date(createdAt),
			data: _data && Map(_data),
			action,
			accessReview: accessReview && AccessReviewModel.fromServerData(accessReview),
			accessReviewId,
			userId
		});
	}
}
