import { createUseStyles } from "react-jss";

const COLUMN_WIDTH = 240;

export const useStyles = createUseStyles({
	columnContainer: {
		minWidth: COLUMN_WIDTH,
		marginRight: "80px",
		marginTop: "100px"
	},
	column: {
		alignItems: "flex-start",
		display: "flex",
		flexDirection: "column",
		gap: "20px"
	}
});
