import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	targetRow: {
		alignItems: "center",
		alignSelf: "stretch",
		display: "flex",
		gap: "var(--spacing-x2, 8px)"
	},
	chooseActorWrap: {
		borderRadius: "10px",
		border: "2px solid var(--color-red-700)"
	},
	bundleTooltipHeader: {
		alignItems: "center",
		display: "flex",
		gap: "var(--spacing-x2, 8px)"
	}
});
