import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { BundleIcon } from "components/ui/Icons/BundleIcon";
import { TitleBody } from "components/ui/TitleBody";
import { Tooltip } from "components/ui/Tooltip";
import { useStyles } from "./styles";
import { EntityNodeContent } from "../components/EntityNodeContent";
import { DeletedIndicator } from "../components/indicators/DeletedIndicator";
import { EntityLabel } from "../EntityLabel";
import type { TBaseEntityNodeProps } from "../types";

const BundleTooltipContent: FC<{ name: string; description?: string | null }> = ({ name, description }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<div className={classes.tooltipContainer}>
			<TitleBody size="small" title={t("common.roleBar.roleBarBundle.bundleName")} body={name} />
			{description ? (
				<TitleBody size="small" title={t("common.roleBar.roleBarBundle.bundleDescription")} body={description} />
			) : null}
		</div>
	);
};

type TProps = TBaseEntityNodeProps & {
	bundle: { name: string; description?: string | null; isDeleted?: boolean };
	withIcon?: boolean;
};
export const BundleEntity: FC<TProps> = ({
	bundle,
	withIcon,
	size,
	className,
	inactive: propInactive,
	...entityNodeProps
}) => {
	const indicators = useMemo(() => {
		const indicators: React.ReactNode[] = [];
		if (bundle.isDeleted) {
			indicators.push(<DeletedIndicator />);
		}

		return indicators;
	}, [bundle.isDeleted]);

	const inactive = propInactive || bundle.isDeleted;

	const content = useMemo(() => {
		return (
			<Tooltip content={<BundleTooltipContent name={bundle.name} description={bundle.description} />}>
				<EntityNodeContent
					text={bundle.name}
					withIcon={withIcon}
					size={size}
					icon={<BundleIcon />}
					inactive={inactive}
					{...entityNodeProps}
				/>
			</Tooltip>
		);
	}, [bundle.name, bundle.description, inactive, withIcon, size, entityNodeProps]);

	return <EntityLabel content={content} size={size} indicators={indicators} className={className} />;
};
