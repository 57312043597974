import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	container: {
		alignItems: "center",
		border: "1px solid var(--color-grey-background)",
		borderRadius: "var(--border-radius-semi-rounded)",
		display: "flex",
		height: "13rem",
		padding: "1rem",
		width: "fit-content"
	},
	labelContainer: {
		display: "flex",
		flexDirection: "column",
		gap: "0.5rem",
		justifyContent: "center",
		maxWidth: "8rem",
		paddingLeft: "1rem"
	},
	labelText: {
		color: "var(--color-grey-dark)"
	},
	legendText: {
		color: "var(--color-black)",
		paddingLeft: "0.5rem"
	},
	spacer: {
		borderLeft: "1px solid var(--color-grey-background)",
		height: "80%",
		margin: "0 2rem",
		width: "0"
	}
});
