import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	hiddenChip: {
		"&$hiddenChip$hiddenChip$hiddenChip": {
			minWidth: "unset",
			maxWidth: "none"
		}
	}
});
