import { useCallback, useEffect } from "react";
import { useOnCallIntegrationSchedulesContext } from "context/onCallIntegrationSchedulesContext";

export const useOnCallIntegrationSchedules = () => {
	const {
		state: { onCallIntegrationSchedules },
		actions: { loadOnCallIntegrationSchedules }
	} = useOnCallIntegrationSchedulesContext();

	const fetchItems = useCallback(async () => {
		if (!onCallIntegrationSchedules) {
			await loadOnCallIntegrationSchedules();
		}
	}, [loadOnCallIntegrationSchedules, onCallIntegrationSchedules]);

	useEffect(() => {
		void fetchItems();
	}, [fetchItems]);

	return onCallIntegrationSchedules;
};
