import * as AuditLogs from "models/auditLogs";
import type { IApprovalAlgorithmAuditLogDiffData } from "models/auditLogs/ApprovalAlgorithmAuditLogModel";
import type {
	IBundleCreatedAuditLogDiffData,
	IBundleUpdatedAuditLogDiffData
} from "models/auditLogs/BundleAuditLogModel";
import type { ICompanyAuditLogData } from "models/auditLogs/CompanyAuditLogModel";
import type { IIntegrationAuditLogDiffData } from "models/auditLogs/IntegrationAuditLogModel";
import type { IIntegrationResourceAuditLogDiffData } from "models/auditLogs/IntegrationResourceAuditLogModel";
import type { IIntegrationResourceRoleAuditLogDiffData } from "models/auditLogs/IntegrationResourceRoleAuditLogModel";

export type TDiffObject<T> = { from?: T; to?: T };
export type TDiff<T> = TDiffObject<T> | T;
export type TAuditLogConstructor =
	| typeof AuditLogs.AccessReviewAuditLogModel
	| typeof AuditLogs.CompanyAuditLogModel
	| typeof AuditLogs.ApprovalAlgorithmAuditLogModel
	| typeof AuditLogs.ApprovalFlowsWebhookAuditLogModel
	| typeof AuditLogs.BundleAuditLogModel
	| typeof AuditLogs.IntegrationAuditLogModel
	| typeof AuditLogs.TicketAuditLogModel
	| typeof AuditLogs.IntegrationResourceAuditLogModel
	| typeof AuditLogs.IntegrationResourceRoleAuditLogModel
	| typeof AuditLogs.PolicyAuditLogModel;

type TAuditLogType = TAuditLogConstructor["type"];
const auditLogModels = new Map<TAuditLogType, TAuditLogConstructor>([
	[AuditLogs.AccessReviewAuditLogModel.type, AuditLogs.AccessReviewAuditLogModel],
	[AuditLogs.ApprovalAlgorithmAuditLogModel.type, AuditLogs.ApprovalAlgorithmAuditLogModel],
	[AuditLogs.ApprovalFlowsWebhookAuditLogModel.type, AuditLogs.ApprovalFlowsWebhookAuditLogModel],
	[AuditLogs.BundleAuditLogModel.type, AuditLogs.BundleAuditLogModel],
	[AuditLogs.CompanyAuditLogModel.type, AuditLogs.CompanyAuditLogModel],
	[AuditLogs.IntegrationAuditLogModel.type, AuditLogs.IntegrationAuditLogModel],
	[AuditLogs.IntegrationResourceAuditLogModel.type, AuditLogs.IntegrationResourceAuditLogModel],
	[AuditLogs.IntegrationResourceRoleAuditLogModel.type, AuditLogs.IntegrationResourceRoleAuditLogModel],
	[AuditLogs.TicketAuditLogModel.type, AuditLogs.TicketAuditLogModel],
	[AuditLogs.PolicyAuditLogModel.type, AuditLogs.PolicyAuditLogModel]
]);

export const auditLogFactory = (data: Record<string, unknown> & { type?: TAuditLogType }) => {
	const model = data.type ? auditLogModels.get(data.type) : null;
	return model ? model.fromServerData(data) : null;
};

type TAuditLogData =
	| IIntegrationAuditLogDiffData
	| IBundleCreatedAuditLogDiffData
	| IBundleUpdatedAuditLogDiffData
	| ICompanyAuditLogData
	| IApprovalAlgorithmAuditLogDiffData
	| IIntegrationResourceAuditLogDiffData
	| IIntegrationResourceRoleAuditLogDiffData;

const isDiffObject = <T>(value: unknown): value is TDiffObject<T> => {
	return typeof value === "object" && value !== null && ("from" in value || "to" in value);
};

type TValueConverter<T extends TAuditLogData, S extends keyof T> = (value: T[S]) => string | undefined | null;

interface IDiffField<T extends TAuditLogData, S extends keyof T> {
	field?: S;
	fromField?: S;
	toField?: S;
	valueField: string;
	currentValue?: T[S];
	valueConvertor?: TValueConverter<T, S>;
}

interface IAuditLogTranslationValue {
	old?: string;
	new?: string;
	current?: string;
}
const defaultValueConvertor = (value: unknown) => value as string;

function covertValue<T extends TAuditLogData, S extends keyof T>(
	value: T[S] | undefined,
	valueConvertor: TValueConverter<T, S>
): string | undefined | null {
	if (value === undefined) return undefined;
	if (value === null) return null;
	return valueConvertor(value);
}

const getField = <T extends TAuditLogData, S extends keyof T>(data: T, field: S | undefined) => {
	if (!field || !Object.prototype.hasOwnProperty.call(data, field)) return undefined;
	return data[field];
};

export function getAuditLogDataValues<T extends TAuditLogData, S extends keyof T>(
	auditLogData: T,
	fields: IDiffField<T, S>[]
) {
	const values: Record<string, IAuditLogTranslationValue> = Object.create(null);
	fields.forEach(({ field, valueField, currentValue, valueConvertor = defaultValueConvertor, fromField, toField }) => {
		const fieldValue = getField(auditLogData, field);
		const fromFieldValue = getField(auditLogData, fromField);
		const toFieldValue = getField(auditLogData, toField);

		let newFieldValue = {
			old: undefined as string | undefined | null,
			new: undefined as string | undefined | null,
			current: undefined as string | undefined | null
		};

		if (fromFieldValue !== undefined || toFieldValue !== undefined) {
			newFieldValue = {
				old: covertValue(fromFieldValue, valueConvertor),
				new: covertValue(toFieldValue, valueConvertor),
				current: currentValue ? valueConvertor(currentValue) : undefined
			};

			if (!currentValue && toFieldValue !== undefined) newFieldValue.current = valueConvertor(toFieldValue);
		} else if (fieldValue !== undefined) {
			if (isDiffObject<T[S]>(fieldValue)) {
				const { from, to } = fieldValue;

				newFieldValue = {
					old: covertValue(from, valueConvertor),
					new: covertValue(to, valueConvertor),
					current: currentValue ? valueConvertor(currentValue) : undefined
				};

				if (!currentValue && to !== undefined) newFieldValue.current = valueConvertor(to);
			} else {
				newFieldValue.new = valueConvertor(fieldValue);
			}
		} else if (currentValue) {
			newFieldValue.current = valueConvertor(currentValue);
		}
		Object.defineProperty(values, valueField, {
			value: newFieldValue,
			writable: true,
			enumerable: true,
			configurable: true
		});
	});

	return values;
}

export const arrayLengthValueConvertor = (value: unknown) => (Array.isArray(value) ? value.length.toString() : "0");
