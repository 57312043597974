import { Record } from "immutable";
import { ApprovalFlowNotifiedEntityModel } from "./ApprovalFlowNotifiedEntityModel";

const NO_IDENTIFIER_APPROVAL_FLOW_ENTITY_TYPE = [
	"Automatic" as const,
	"DirectManager" as const,
	"IntegrationMaintainer" as const,
	"IntegrationOwner" as const,
	"ResourceMaintainer" as const,
	"ResourceOwner" as const,
	"TeamMember" as const
];

type TNoIdentifierApprovalFlowEntityType = (typeof NO_IDENTIFIER_APPROVAL_FLOW_ENTITY_TYPE)[number];

export type TApprovalFlowEntityType =
	| TNoIdentifierApprovalFlowEntityType
	| "DirectoryGroup"
	| "OnCallIntegrationSchedule"
	| "HR"
	| "Webhook"
	| "User";

export const isNoIdentifierApprovalFlowEntityType = (
	entityType: TApprovalFlowEntityType
): entityType is TNoIdentifierApprovalFlowEntityType =>
	NO_IDENTIFIER_APPROVAL_FLOW_ENTITY_TYPE.includes(entityType as TNoIdentifierApprovalFlowEntityType);

const defaults = {
	identifier: null as string | null,
	type: "" as TApprovalFlowEntityType
};

export interface IApprovalFlowEntitySchema {
	identifier: string | null;
	type: TApprovalFlowEntityType;
}

export class ApprovalFlowEntityModel extends Record<typeof defaults>(defaults) {
	static fromServerData(data: unknown) {
		const { identifier, type } = data as IApprovalFlowEntitySchema;

		return new ApprovalFlowEntityModel({
			identifier: isNoIdentifierApprovalFlowEntityType(type) ? type : identifier,
			type
		});
	}

	toServerData(): IApprovalFlowEntitySchema {
		const { identifier, type } = this;
		return {
			identifier: isNoIdentifierApprovalFlowEntityType(type) ? null : identifier,
			type
		};
	}

	toNotifiedEntity(): ApprovalFlowNotifiedEntityModel {
		const { identifier, type } = this;
		if (type === "Automatic") throw new Error("Cannot convert automatic type entity to notified entity");
		return new ApprovalFlowNotifiedEntityModel({
			identifier,
			type
		});
	}
}
