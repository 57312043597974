import { Record } from "immutable";

const defaults = {
	id: "",
	value: "",
	name: "",
	createdAt: new Date(0)
};

export class AgentTokenModel extends Record<typeof defaults>(defaults) {
	static fromServerData(data: unknown) {
		const { createdAt, id, name, value } = data as typeof defaults;
		return new AgentTokenModel({
			createdAt: new Date(createdAt),
			id,
			name,
			value
		});
	}
}
