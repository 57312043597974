import { ErrorObject } from "ajv";
import { Map } from "immutable";
import isEqual from "lodash/isEqual";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { getErrorMessages } from "./utils";
import type { TUpdateApprovalFlowsWebhookParams } from "api/approvalFlowsWebhooks";
import type { ApprovalFlowsWebhookModel } from "models/ApprovalFlowsWebhookModel";

const TRANSLATION_PREFIX = "pages.workflowsWebhooks.table";
const DEFAULT_JSON_VALUE = Object.freeze({});

export function useWebhookRow(webhook: ApprovalFlowsWebhookModel | null, create: boolean) {
	const [errors, setErrors] = useState(Map<"url" | "headers" | "additionalParams", string[]>());
	const [name, setName] = useState(webhook?.name ?? "");
	const [url, setUrl] = useState(webhook?.url ?? "");
	const [headers, setHeaders] = useState(webhook?.headers ?? DEFAULT_JSON_VALUE);
	const [additionalParams, setAdditionalParams] = useState(webhook?.additionalParams ?? DEFAULT_JSON_VALUE);

	const { t } = useTranslation();
	const urlValidityChecks = useMemo(
		() => [
			(url: string) => {
				if (URL.canParse(url)) {
					return null;
				} else {
					return t(`${TRANSLATION_PREFIX}.invalidUrl`);
				}
			}
		],
		[t]
	);

	const setFieldError = useCallback(
		(field: "url" | "headers" | "additionalParams", err: Partial<ErrorObject>[] | string[] | null) => {
			setErrors(curr => {
				if (err?.length) {
					const messages = getErrorMessages(err, t);
					return curr.get(field)?.join() !== messages.join() ? curr.set(field, messages) : curr;
				}
				return !err && curr.has(field) ? curr.delete(field) : curr;
			});
		},
		[t]
	);

	const setAdditionalParamsError = useCallback(
		(err: Partial<ErrorObject>[] | null) => {
			setFieldError("additionalParams", err);
		},
		[setFieldError]
	);

	const setHeadersError = useCallback(
		(err: Partial<ErrorObject>[] | null) => {
			setFieldError("headers", err);
		},
		[setFieldError]
	);

	const setUrlError = useCallback(
		(err: string[] | null) => {
			setFieldError("url", err);
		},
		[setFieldError]
	);

	const handleAdditionalParamsChange = useCallback((value: Record<string, unknown>) => {
		setAdditionalParams(value);
	}, []);

	const handleHeadersChange = useCallback((value: Record<string, unknown>) => {
		setHeaders(value as Record<string, string>);
	}, []);

	const { changes, saveEnabled } = useMemo(() => {
		const hasErrors = errors.size > 0 && errors.some(errors => errors.length > 0);
		const saveEnabled = !hasErrors && name !== "" && url !== "";
		const changes: TUpdateApprovalFlowsWebhookParams = {};

		if (!create && webhook) {
			if (name !== webhook.name) changes.name = name;
			if (url !== webhook.url) changes.url = url;
			if (!isEqual(webhook.headers, headers)) changes.headers = headers;
			if (!isEqual(webhook.additionalParams, additionalParams)) changes.additionalParams = additionalParams;
		}

		return {
			changes: Object.keys(changes).length > 0 ? changes : null,
			saveEnabled
		};
	}, [additionalParams, create, errors, headers, name, url, webhook]);

	return {
		state: {
			urlValidityChecks,
			errors,
			name,
			additionalParams,
			headers,
			changes,
			saveEnabled,
			url
		},
		actions: {
			setName,
			setUrl,
			setAdditionalParams,
			setHeaders,
			setAdditionalParamsError,
			setHeadersError,
			setUrlError,
			handleAdditionalParamsChange,
			handleHeadersChange
		}
	};
}
