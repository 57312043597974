import classNames from "classnames";
import React, { useCallback, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { AreYouSureModal } from "components/common/AreYouSureModal";
import { JSONTextArea } from "components/common/JSONTextArea";
import { Button } from "components/ui/Button";
import { Entity } from "components/ui/Entity";
import { ErrorIcon } from "components/ui/Icons/ErrorIcon";
import { WorkflowsIcon } from "components/ui/Icons/WorkflowsIcon";
import { Input } from "components/ui/Input";
import { Table } from "components/ui/Table";
import { Typography } from "components/ui/Typography";
import { useApprovalFlowsWebhooksContext } from "context/approvalFlowsWebhooksContext";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { useIsOpenState } from "hooks/useIsOpenState";
import { ApprovalFlowsWebhookModel } from "models/ApprovalFlowsWebhookModel";
import { useStyles } from "./styles";
import { useWebhookRow } from "./useWebhookRow";
import { HEADERS_SCHEMA } from "./utils";
import type { TUpdateApprovalFlowsWebhookParams } from "api/approvalFlowsWebhooks";

type TProps = { withLoader: <T>(promise: Promise<T>) => Promise<T> } & (
	| {
			webhook: ApprovalFlowsWebhookModel;
			openWebhookConnectedWorkflowsModal: (webhook: ApprovalFlowsWebhookModel) => void;
			create?: never;
			closeCreate?: never;
	  }
	| { create: true; webhook?: never; closeCreate: () => void; openWebhookConnectedWorkflowsModal?: never }
);

const TRANSLATION_PREFIX = "pages.workflowsWebhooks.table";

const ActionsCell: FC<{
	webhook?: ApprovalFlowsWebhookModel;
	openConnectedWorkflowsModal?: () => void;
	deleteWebhook?: () => void | Promise<void>;
	enableWebhook?: () => Promise<void>;
}> = React.memo(function Cell({ className, webhook, deleteWebhook, enableWebhook, openConnectedWorkflowsModal }) {
	const classes = useStyles();
	const { t } = useTranslation();

	const { icon, text } = useMemo(() => {
		if (webhook?.disabled) {
			return { text: t(`${TRANSLATION_PREFIX}.webhookDisabled`), icon: <ErrorIcon size={24} /> };
		}

		if (webhook?.approvalAlgorithmIds.size) {
			const text = t(`${TRANSLATION_PREFIX}.webhookInUse`, { context: webhook.disabled ? "disabled" : undefined });
			return { text, icon: null };
		}

		return { text: null, icon: null };
	}, [t, webhook]);

	return (
		<Table.Cell className={className}>
			{icon}
			{text ? (
				<Typography variant="text_sm_reg" className={classes.actionsText}>
					{text}
				</Typography>
			) : null}
			<div className={classes.actionsContainer}>
				{enableWebhook ? (
					<Button size="small" variant="secondary" onClick={enableWebhook}>
						{t("buttons.reactivate")}
					</Button>
				) : null}
				{deleteWebhook ? (
					<Button size="small" variant="secondary" onClick={deleteWebhook}>
						{t("buttons.remove")}
					</Button>
				) : null}
				{openConnectedWorkflowsModal ? (
					<Button variant="secondary" size="small" prefix={<WorkflowsIcon />} onClick={openConnectedWorkflowsModal}>
						{t(`${TRANSLATION_PREFIX}.listOfWorkflows`)}
					</Button>
				) : null}
			</div>
		</Table.Cell>
	);
});

const EditModeActionsCell: FC<{
	save: () => Promise<void>;
	cancel: () => void;
	enablesOnSave?: boolean;
	allowSave: boolean;
	isCreate?: boolean;
}> = React.memo(function Cell({ className, isCreate, save, cancel, enablesOnSave = false, allowSave }) {
	const classes = useStyles();
	const { t } = useTranslation();
	return (
		<Table.Cell className={className}>
			<div className={classes.actionsContainer}>
				<Button variant="secondary" size="small" onClick={cancel}>
					{isCreate ? t("buttons.remove") : t("buttons.cancel")}
				</Button>
				<Button size="small" disabled={!allowSave} onClick={save}>
					{enablesOnSave ? t(`${TRANSLATION_PREFIX}.saveAndReactivate`) : t("buttons.save")}
				</Button>
			</div>
		</Table.Cell>
	);
});

export const WorkflowsWebhookRow: FC<TProps> = React.memo(function TableRow({
	webhook,
	create,
	closeCreate,
	withLoader,
	openWebhookConnectedWorkflowsModal
}) {
	const {
		state: { additionalParams, changes, errors, headers, name, saveEnabled, url, urlValidityChecks },
		actions: {
			handleAdditionalParamsChange,
			handleHeadersChange,
			setName,
			setAdditionalParams,
			setAdditionalParamsError,
			setHeaders,
			setHeadersError,
			setUrlError,
			setUrl
		}
	} = useWebhookRow(webhook ?? null, create ?? false);
	const [editMode, setEditMode] = useState(create ?? false);
	const edit = useCallback(() => {
		setEditMode(true);
	}, []);
	const {
		close: closeDeleteAreYouSureModal,
		isOpen: isDeleteAreYouSureModalOpen,
		open: openDeleteAreYouSureModal
	} = useIsOpenState();
	const openErrorModal = useOpenGlobalErrorModal();

	const {
		actions: {
			deleteApprovalFlowsWebhook,
			createApprovalFlowsWebhook,
			updateApprovalFlowsWebhook,
			enableApprovalFlowsWebhook
		}
	} = useApprovalFlowsWebhooksContext();

	const classes = useStyles();
	const { t } = useTranslation();

	const cancel = useCallback(() => {
		if (create) {
			closeCreate();
		} else {
			setEditMode(false);
			setName(webhook.name);
			setUrl(webhook.url);
			setHeaders(webhook.headers);
			setAdditionalParams(webhook.additionalParams);
		}
	}, [closeCreate, create, setAdditionalParams, setHeaders, setName, setUrl, webhook]);

	const save = useCallback(async () => {
		if (!saveEnabled) return undefined;
		if (changes === null && !create) return undefined;
		try {
			if (create) {
				await withLoader(createApprovalFlowsWebhook({ name, url, headers, additionalParams }));
				closeCreate();
			} else {
				const toChange: TUpdateApprovalFlowsWebhookParams = { ...(changes || {}) };
				if (webhook?.disabled) {
					toChange.enabled = true;
				}
				await withLoader(updateApprovalFlowsWebhook(webhook.id, toChange));
				setEditMode(false);
			}
		} catch (error) {
			openErrorModal(error as Error);
		}
	}, [
		additionalParams,
		changes,
		closeCreate,
		create,
		createApprovalFlowsWebhook,
		headers,
		name,
		openErrorModal,
		saveEnabled,
		updateApprovalFlowsWebhook,
		url,
		webhook?.disabled,
		webhook?.id,
		withLoader
	]);

	const deleteWebhook = useCallback(async () => {
		if (webhook?.approvalAlgorithmIds.size === 0) {
			try {
				await withLoader(deleteApprovalFlowsWebhook(webhook.id));
			} catch (error) {
				openErrorModal(error as Error);
			}
		}
	}, [deleteApprovalFlowsWebhook, openErrorModal, webhook?.approvalAlgorithmIds.size, webhook?.id, withLoader]);

	const enableWebhook = useCallback(async () => {
		if (webhook?.disabled) {
			try {
				await withLoader(enableApprovalFlowsWebhook(webhook));
			} catch (error) {
				openErrorModal(error as Error);
			}
		}
	}, [enableApprovalFlowsWebhook, openErrorModal, webhook, withLoader]);

	const openConnectedWorkflowsModal = useCallback(() => {
		if (webhook) {
			openWebhookConnectedWorkflowsModal(webhook);
		}
	}, [openWebhookConnectedWorkflowsModal, webhook]);

	const onCellClick = !editMode ? edit : undefined;

	return (
		<Table.Row>
			{isDeleteAreYouSureModalOpen && (
				<AreYouSureModal onClose={closeDeleteAreYouSureModal} onAction={deleteWebhook} isOpen />
			)}
			<Table.Cell onClick={onCellClick} className={classNames(classes.cell, classes.nameCell)}>
				{editMode ? (
					<Input value={name} onValueChange={setName} placeholder={t(`${TRANSLATION_PREFIX}.nameInputPlaceholder`)} />
				) : (
					<Entity
						content={name}
						textVariant="text_sm_sb"
						isDeleted={webhook?.disabled}
						contentClassName={webhook?.disabled ? classes.disabledWebhook : undefined}
					/>
				)}
			</Table.Cell>
			<Table.Cell onClick={onCellClick} className={classNames(classes.cell, classes.nameCell)}>
				<Input
					disabled={!editMode}
					onError={setUrlError}
					onValueChange={setUrl}
					placeholder="https://example.com/webhook"
					validators={urlValidityChecks}
					value={url}
				/>
			</Table.Cell>
			<Table.Cell onClick={onCellClick} className={classes.cell}>
				<JSONTextArea
					disabled={!editMode}
					textAreaClassName={classes.cellTextArea}
					value={headers}
					onChange={handleHeadersChange}
					onError={setHeadersError}
					validationSchema={HEADERS_SCHEMA}
					errors={errors.get("headers")}
				/>
			</Table.Cell>
			<Table.Cell onClick={onCellClick} className={classes.cell}>
				<JSONTextArea
					disabled={!editMode}
					value={additionalParams}
					textAreaClassName={classes.cellTextArea}
					onChange={handleAdditionalParamsChange}
					onError={setAdditionalParamsError}
					errors={errors.get("additionalParams")}
				/>
			</Table.Cell>
			{editMode ? (
				<EditModeActionsCell
					className={classNames(classes.cell, classes.actionsCell)}
					save={save}
					cancel={cancel}
					isCreate={create}
					enablesOnSave={webhook?.disabled}
					allowSave={saveEnabled && Boolean(changes !== null || create)}
				/>
			) : (
				<ActionsCell
					className={classNames(classes.cell, classes.actionsCell)}
					webhook={webhook}
					openConnectedWorkflowsModal={webhook?.approvalAlgorithmIds?.size ? openConnectedWorkflowsModal : undefined}
					enableWebhook={webhook?.disabled ? enableWebhook : undefined}
					deleteWebhook={!webhook?.approvalAlgorithmIds.size ? openDeleteAreYouSureModal : undefined}
				/>
			)}
		</Table.Row>
	);
});
