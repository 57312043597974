import { List, Map } from "immutable";
import { UserEntitlementModel } from "models/UserEntitlementModel";
import { type IPaginationResponse, withPagination } from "utils/pagination";
import { type IPaginatedSearchOptions, getPaginatedSearchQueryString } from "utils/searchUtils";
import { UserModel, type TAdminUser, type TUserRole } from "../models/UserModel";
import { apiReq } from "../utils/api/apiReq";

export async function getPaginatedUsers(
	paginationOptions?: IPaginatedSearchOptions,
	{ includeDeleted = true }: { includeDeleted?: boolean } = {}
): Promise<IPaginationResponse<UserModel>> {
	return withPagination(
		(user: Record<string, unknown>) => UserModel.fromServerData(user),
		() => {
			const paginationQs = getPaginatedSearchQueryString(paginationOptions);
			const url = `/v1/users?withDeleted=${includeDeleted}${paginationQs ? `&${paginationQs}` : ""}`;
			return apiReq("GET", url);
		}
	);
}

export async function multiGetUsers(
	ids: string[],
	options: { includeDeleted?: boolean } = {}
): Promise<List<UserModel>> {
	if (!ids.length) return List<UserModel>();
	const { data } = await apiReq(
		"GET",
		`/v1/users/multiGet?withDeleted=${!!options.includeDeleted}&${ids.map(id => "ids=" + id).join("&")}`
	);

	return List<UserModel>(data.map((item: unknown) => UserModel.fromServerData(item)));
}

const isAdminUser = (user: UserModel): user is TAdminUser => user.role === "admin";

export async function getAdmins(): Promise<Map<string, TAdminUser>> {
	const { data } = await apiReq<unknown[]>("GET", "/v1/users/admins");
	return Map(
		data.map((user: unknown) => {
			const model = UserModel.fromServerData(user);
			if (!isAdminUser(model)) throw new Error("Expected admin user");
			return [model.id, model];
		})
	);
}

export async function getUserEntitlements(id: string, withUnmanaged = false): Promise<List<UserEntitlementModel>> {
	const { data } = await apiReq("GET", `/v1/users/${id}/entitlements?withUnmanaged=${withUnmanaged}`);
	return List(data.map((permission: unknown) => UserEntitlementModel.fromServerData(permission)));
}

export async function changeUsersRole(userId: string, role: TUserRole): Promise<UserModel> {
	const { data } = await apiReq("PUT", "/v1/users/" + userId, { role });

	return UserModel.fromServerData(data);
}

export async function getUser(id: string): Promise<UserModel> {
	const { data } = await apiReq("GET", `/v1/users/${id}`);

	return UserModel.fromServerData(data);
}
