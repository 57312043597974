import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	inlineInputContainer: {
		display: "flex",
		flexDirection: "column",
		width: "100%"
	},
	inlineInputLabelContainer: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		cursor: "pointer",
		alignItems: "center"
	},
	label: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		gap: "0.5ch"
	},
	approvalAlgorithmOption: {
		display: "flex",
		flexDirection: "row",
		width: "100%",
		alignItems: "center",
		gap: "0.5rem",
		paddingLeft: "1rem",
		paddingRight: "1rem",
		cursor: "pointer",
		minHeight: "40px"
	},
	list: {
		maxHeight: "400px"
	},
	arrowDownInline: {
		visibility: (props: { showArrow?: boolean }) => (props?.showArrow ? "visible" : "hidden")
	},
	floatingSelect: {
		minWidth: "23rem"
	}
});
