import classNames from "classnames";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { AccessReviewReportSubjectHeader } from "components/common//AccessReviewReportSubjectHeader";
import { Typography } from "components/ui/legacy/Typography";
import { useAccessReportResourcesContext } from "context/accessReportResourcesContext";
import {
	ResourcesPermissionsReviewContextProvider,
	useResourcePermissionsReviewContext
} from "context/resourcePermissionReviewContext";
import { useOnMount } from "hooks/useOnMount";
import { AccessReviewReportModel, IReportItem } from "models/AccessReviewReportModel";
import { AccessReviewResourceModel } from "models/AccessReviewResourceModel";
import { ResourcePermissionsTable } from "./components/ResourcePermissionsTable";
import { ResourcePermissionTableHeader } from "./components/ResourcePermissionTableHeader";
import { useStyles } from "./styles";
import type { IntegrationResourceModel } from "models/IntegrationResourceModel";

interface IProps {
	report: AccessReviewReportModel;
	onChangeResource?: (resourceId: string) => void;
	isAdmin?: boolean;
	isDone?: boolean;
	sortedResources?: IReportItem[];
	resource: AccessReviewResourceModel;
	immediateRevoke: boolean;
}

const ResourcePermissionsReviewTable: FC<Omit<IProps, "resource" | "report">> = ({
	className,
	id,
	innerRef,
	isAdmin,
	isDone,
	onChangeResource,
	sortedResources,
	immediateRevoke
}) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const {
		state: { integrationResources },
		actions: { fetchIntegrationResource }
	} = useAccessReportResourcesContext();
	const [nextIntegrationResource, setNextIntegrationResource] = useState<IntegrationResourceModel>();
	const [prevIntegrationResource, setPrevIntegrationResource] = useState<IntegrationResourceModel>();

	const {
		state: { report, resource },
		actions: { getIsSelected, handlePermissionCheckboxChange, removePermissionCheckbox }
	} = useResourcePermissionsReviewContext();

	const resourceIndex = useMemo(
		() => (resource ? sortedResources?.findIndex(res => res.id === resource.id) : -1),
		[sortedResources, resource]
	);

	const loadPreviousResource = useCallback(async () => {
		if (resourceIndex && sortedResources && resourceIndex > 0) {
			const prevResourceId = sortedResources[resourceIndex - 1].reportItemId;
			let resource = integrationResources.get(prevResourceId);
			if (!resource) {
				resource = await fetchIntegrationResource(prevResourceId);
			}
			setPrevIntegrationResource(resource);
		} else {
			setPrevIntegrationResource(undefined);
		}
	}, [fetchIntegrationResource, integrationResources, resourceIndex, sortedResources]);

	const loadNextResource = useCallback(async () => {
		if (resourceIndex !== undefined && sortedResources && resourceIndex < (sortedResources?.length || 0) - 1) {
			const nextResourceId = sortedResources[resourceIndex + 1].reportItemId;
			let resource = integrationResources.get(nextResourceId);
			if (!resource) {
				resource = await fetchIntegrationResource(nextResourceId);
			}
			setNextIntegrationResource(resource);
		} else {
			setNextIntegrationResource(undefined);
		}
	}, [fetchIntegrationResource, integrationResources, resourceIndex, sortedResources]);

	const toPreviousResource = useCallback(() => {
		if (resourceIndex && resourceIndex > 0) {
			onChangeResource?.(sortedResources![resourceIndex - 1].id);
			void loadPreviousResource();
		}
	}, [resourceIndex, onChangeResource, sortedResources, loadPreviousResource]);

	const toNextResource = useCallback(() => {
		if (sortedResources && resourceIndex !== undefined && resourceIndex < (sortedResources?.length || 0) - 1) {
			onChangeResource?.(sortedResources[resourceIndex + 1].id);
			void loadNextResource();
		}
	}, [sortedResources, resourceIndex, onChangeResource, loadNextResource]);

	const nextAction = useMemo(
		() => ({
			onClick: toNextResource,
			disabled: !nextIntegrationResource,
			tooltip: nextIntegrationResource
				? t("common.resourcePermissionsReviewTable.nextResource", { name: nextIntegrationResource.name })
				: undefined
		}),
		[nextIntegrationResource, t, toNextResource]
	);

	const previousAction = useMemo(
		() => ({
			onClick: toPreviousResource,
			disabled: !prevIntegrationResource,
			tooltip: prevIntegrationResource
				? t("common.resourcePermissionsReviewTable.previousResource", { name: prevIntegrationResource.name })
				: undefined
		}),
		[prevIntegrationResource, t, toPreviousResource]
	);

	useOnMount(() => {
		void loadNextResource();
		void loadPreviousResource();
	});

	if (!resource || !resource.accessReviewResourcePermissions) return null;
	const readonly = isAdmin || isDone;

	return (
		<>
			<div className={classNames(classes.container, className)} id={id} ref={innerRef}>
				<AccessReviewReportSubjectHeader
					doneAmount={resource.notPendingPermissionsSize}
					next={nextAction}
					previous={previousAction}
					reporterUserId={report.userId}
					totalAmount={resource.permissionsSize}
					isAdmin={isAdmin}
					sortedReportSubjects={sortedResources}
					integrationResource={resource.integrationResource!}
				/>
				{resource.permissionsSize === 0 ? (
					<Typography variant="h3" className={classes.emptyList}>
						{t("common.resourcePermissionsReviewTable.noPermissions")}
					</Typography>
				) : (
					<>
						{!readonly && <ResourcePermissionTableHeader immediateRevoke={immediateRevoke} />}
						<ResourcePermissionsTable
							readonly={readonly}
							getIsSelected={getIsSelected}
							changeCheckbox={handlePermissionCheckboxChange}
							removeCheckbox={removePermissionCheckbox}
							immediateRevoke={immediateRevoke}
						/>
					</>
				)}
			</div>
		</>
	);
};

const ResourcePermissionReviewTableWithProvider: FC<IProps> = ({ resource, report, ...componentProps }) => {
	return (
		<ResourcesPermissionsReviewContextProvider
			resourceId={resource.id}
			report={report}
			immediateRevoke={componentProps.immediateRevoke}>
			<ResourcePermissionsReviewTable {...componentProps} />
		</ResourcesPermissionsReviewContextProvider>
	);
};

export { ResourcePermissionReviewTableWithProvider as ResourcePermissionsReviewTable };
