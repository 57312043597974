import { createUseStyles } from "react-jss";
import { entitySharedStyles } from "../styles";

export const useStyles = createUseStyles({
	...entitySharedStyles,
	tooltipContainer: {
		display: "flex",
		flexDirection: "column",
		gap: "var(--spacing-x5, 20px)",
		padding: "var(--spacing-x3, 12px)"
	},
	icon: {
		"&$icon": {
			height: "100%",
			width: "100%"
		}
	},
	iconWithBackground: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		borderRadius: "6px",
		backgroundColor: "var(--color-white)",
		border: "none",
		"&$medium": {
			height: "28px",
			width: "28px"
		},
		"&$small": {
			height: "20px",
			width: "20px"
		},
		"& $icon": {
			height: "calc(100% - 8px)",
			width: "calc(100% - 8px)"
		}
	},
	withBackground: {}
});
