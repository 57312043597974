import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	sidebarContent: {
		overflow: "hidden",
		"& ::-webkit-scrollbar-thumb": {
			background: "transparent"
		},
		display: "flex",
		justifyContent: "flex-start",
		alignItems: "flex-start",
		height: "100%",
		paddingLeft: "var(--spacing-x6, 24px)",
		paddingTop: "var(--spacing-x7, 28px)",
		paddingRight: "var(--spacing-x4, 16px)",
		paddingBottom: "var(--spacing-x7, 28px)",
		flexDirection: "column",
		width: "100%",
		gap: "var(--spacing-x6, 24px)"
	},
	title: {
		alignItems: "center",
		justifyContent: "flex-start",
		display: "flex"
	},
	detailsContainer: {
		width: "100%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-start",
		paddingRight: "var(--spacing-x4, 16px)",
		alignItems: "flex-start",
		gap: "var(--spacing-x3, 12px)"
	}
});
