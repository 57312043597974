import classNames from "classnames";
import { List, Set as ImmutableSet } from "immutable";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { AccountIcon } from "components/ui/Icons/AccountIcon";
import { IntegrationIcon } from "components/ui/Icons/IntegrationIcon";
import { ResourcesIcon } from "components/ui/Icons/ResourcesIcon";
import { RoleIcon } from "components/ui/Icons/RoleIcon";
import { UserFilledIcon } from "components/ui/Icons/UserFilledIcon";
import { Typography } from "components/ui/legacy/Typography";
import { Separator } from "components/ui/Separator";
import { RoleVertexModel } from "models/IdentityGraph/RoleVertexModel";
import { VertexModel } from "models/IdentityGraph/VertexModel";
import { toGroupMapBy } from "utils/toMapBy";
import { useStyles } from "./styles";
import { LINE_BOTH_COLOR, LINE_DIRECT_COLOR, LINE_INDIRECT_COLOR } from "../../styles";

type TLegendData = {
	users: number;
	accounts: number;
	integrations: number;
	resources: number;
	roles: number;
};

type TLegendProps = {
	vertices?: List<VertexModel>;
	loading?: boolean;
};

export const Legend: FC<TLegendProps> = ({ className, vertices, innerRef, loading }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const legendData: TLegendData = useMemo(() => {
		const data = {
			users: 0,
			accounts: 0,
			integrations: 0,
			resources: 0,
			roles: 0
		};

		if (vertices) {
			const groupedVertices = toGroupMapBy(vertices, vertex => vertex.type);
			data.users = groupedVertices.get("user")?.length || 0;
			data.accounts = groupedVertices.get("account")?.length || 0;
			data.integrations = groupedVertices.get("integration")?.length || 0;
			const roleVertices = groupedVertices.get("role");
			if (roleVertices) {
				data.roles = roleVertices.length;
				let resourcesSet = ImmutableSet<string>();
				let integrationsSet = ImmutableSet<string>();
				(roleVertices as RoleVertexModel[]).forEach(vertex => {
					resourcesSet = resourcesSet.add(vertex.entity.resourceId);
					integrationsSet = integrationsSet.add(vertex.entity.integrationId);
				});
				data.resources = resourcesSet.size;
				data.integrations = integrationsSet.size;
			}
		}

		return data;
	}, [vertices]);

	return (
		<div className={classNames(classes.legend, className)} ref={innerRef}>
			<div className={classes.legendItem}>
				<UserFilledIcon className={classNames(classes.symbol, classes.symbolUser)} />
				<Typography variant="small" noWrap>
					{t("pages.identityGraph.legend.users")}
				</Typography>
				<Typography variant="small" className={classes.legendItemAmount} noWrap>
					{loading ? "-" : legendData.users}
				</Typography>
			</div>
			<div className={classes.legendItem}>
				<AccountIcon className={classNames(classes.symbol, classes.symbolAccount)} />
				<Typography variant="small" noWrap>
					{t("pages.identityGraph.legend.accounts")}
				</Typography>
				<Typography variant="small" className={classes.legendItemAmount} noWrap>
					{loading ? "-" : legendData.accounts}
				</Typography>
			</div>
			<div className={classes.legendItem}>
				<IntegrationIcon className={classNames(classes.symbol, classes.symbolIntegrationAndResource)} />
				<Typography variant="small" noWrap>
					{t("pages.identityGraph.legend.integrations")}
				</Typography>
				<Typography variant="small" className={classes.legendItemAmount} noWrap>
					{loading ? "-" : legendData.integrations}
				</Typography>
			</div>
			<div className={classes.legendItem}>
				<ResourcesIcon className={classNames(classes.symbol, classes.symbolIntegrationAndResource)} />
				<Typography variant="small" noWrap>
					{t("pages.identityGraph.legend.resources")}
				</Typography>
				<Typography variant="small" className={classes.legendItemAmount} noWrap>
					{loading ? "-" : legendData.resources}
				</Typography>
			</div>
			<div className={classes.legendItem}>
				<RoleIcon className={classNames(classes.symbol, classes.symbolRole)} />
				<Typography variant="small" noWrap>
					{t("pages.identityGraph.legend.roles")}
				</Typography>
				<Typography variant="small" className={classes.legendItemAmount} noWrap>
					{loading ? "-" : legendData.roles}
				</Typography>
			</div>
			<Separator />
			<div className={classes.legendLineItem}>
				<div>
					<svg width="12" height="12" viewBox="0 0 12 12" fill="none">
						<circle cx="6" cy="6" r="6" fill={LINE_DIRECT_COLOR} />
					</svg>
					<Typography variant="small" noWrap>
						{t("pages.identityGraph.legend.direct")}
					</Typography>
				</div>
				<div>
					<svg width="12" height="12" viewBox="0 0 12 12" fill="none">
						<circle cx="6" cy="6" r="6" fill={LINE_INDIRECT_COLOR} />
					</svg>
					<Typography variant="small" noWrap>
						{t("pages.identityGraph.legend.indirect")}
					</Typography>
				</div>
				<div>
					<svg width="12" height="12" viewBox="0 0 12 12" fill="none">
						<circle cx="6" cy="6" r="6" fill={LINE_BOTH_COLOR} />
					</svg>
					<Typography variant="small" noWrap>
						{t("pages.identityGraph.legend.both")}
					</Typography>
				</div>
			</div>
		</div>
	);
};
