import { createUseStyles } from "react-jss";

export const TOP_NAV_HEIGHT_PX = 60;

export const useStyles = createUseStyles({
	container: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		height: `${TOP_NAV_HEIGHT_PX}px`,
		paddingRight: "var(--spacing-x6, 24px)",
		paddingLeft: "var(--spacing-x6, 24px)",
		backgroundColor: "var(--nav-background-color)",
		gap: "var(--spacing-x5, 20px)"
	},
	userContainer: {
		display: "flex",
		maxWidth: "300px",
		minWidth: "50px"
	},
	userActionsModal: {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start",
		width: "212px",
		padding: "0",
		zIndex: 9999,
		borderRadius: "6px",
		backgroundColor: "var(--color-white)",
		boxShadow: "0px 8px 7px 7px rgba(0, 0, 0, 0.20)"
	},
	userIcon: {
		"&$userIcon": {
			maxWidth: "20px",
			maxHeight: "20px"
		}
	},
	externalLink: {
		height: "28px",
		width: "28px"
	},
	rightNav: {
		display: "flex",
		gap: "var(--spacing-x5, 20px)",
		justifyContent: "flex-start",
		alignItems: "center",
		maxWidth: "300px",
		minWidth: "50px",
		height: "32px"
	}
});
