import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	policyBarContainer: {
		padding: "var(--spacing-x4, 16px)",
		borderRadius: "20px",
		border: "2px solid var(--color-purple-50)",
		background: "var(--color-purple-50)",
		"&:hover": {
			background: "var(--color-purple-100)"
		}
	},
	barContainer: {
		padding: "var(--spacing-x4, 16px)",
		gap: "var(--spacing-x5, 20px)",
		display: "grid",
		gridTemplateColumns: "80px 1px minmax(84px, 1fr) 1px minmax(272px, 1fr) 1px minmax(248px, 1fr) 40px",
		width: "100%"
	}
});
