import { useCallback, useEffect, useState } from "react";
import { getIntegrationResourceRole } from "api/integrationResourceRoles";
import { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";
import { useOpenGlobalErrorModal } from "./useGlobalError";

export const useIntegrationResourceRole = (integrationResourceRoleId: string, withDeleted = false) => {
	const [isLoading, setIsLoading] = useState(false);
	const openGlobalErrorModal = useOpenGlobalErrorModal();
	const [role, setRole] = useState<IntegrationResourceRoleModel | undefined | null>(undefined);

	const loadRole = useCallback(async () => {
		if (!isLoading && integrationResourceRoleId) {
			try {
				setIsLoading(true);
				const fetchedRole = await getIntegrationResourceRole(integrationResourceRoleId, withDeleted);
				setRole(fetchedRole);
			} catch (error) {
				setRole(null);
				openGlobalErrorModal(error as Error);
			}
			setIsLoading(false);
		}
	}, [integrationResourceRoleId, isLoading, openGlobalErrorModal, withDeleted]);

	useEffect(() => {
		if (role === undefined) {
			void loadRole();
		}
	}, [role, loadRole]);

	return { role, loadRole, isLoading };
};
