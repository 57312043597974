import React from "react";

export const JiraServiceManagementLogo: FC = ({ className }) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" className={className}>
		<mask
			id="mask0_1_204"
			style={{ maskType: "luminance" }}
			maskUnits="userSpaceOnUse"
			x="-2"
			y="-2"
			width="28"
			height="28">
			<path d="M25.9999 -2H-2.00006V26H25.9999V-2Z" fill="white" />
		</mask>
		<g mask="url(#mask0_1_204)">
			<path
				d="M13.8921 9.78003L19.5921 9.81004C20.312 9.81004 20.732 10.65 20.312 11.25L11.8821 22.5C9.24204 20.52 8.70207 16.77 10.682 14.1L13.8921 9.78003Z"
				fill="url(#paint0_linear_1_204)"
			/>
			<path
				d="M10.6821 14.1L5.01206 14.07C4.29209 14.07 3.8721 13.23 4.2921 12.66L12.6321 1.5C15.2721 3.48 15.812 7.23 13.8321 9.89998L10.6821 14.1Z"
				fill="#2684FF"
			/>
		</g>
		<defs>
			<linearGradient
				id="paint0_linear_1_204"
				x1="13.6748"
				y1="10.6344"
				x2="16.0767"
				y2="17.8952"
				gradientUnits="userSpaceOnUse">
				<stop stopColor="#0052CC" />
				<stop offset="1" stopColor="#2684FF" />
			</linearGradient>
		</defs>
	</svg>
);
