import { createUseStyles } from "react-jss";

export const DEFAULT_ROW_HEIGHT_PX = 60;
export const COMPACT_ROW_HEIGHT_PX = 32;

export const useStyles = createUseStyles({
	cell: {
		"&$checkboxPlaceholder": {
			width: "38px"
		},
		alignItems: "center",
		borderBottom: "1px solid var(--table-cell-border-color)",
		display: "flex",
		flexShrink: 0,
		gap: "var(--spacing-x2)",
		justifyContent: "flex-start",
		margin: 0,
		overflow: "hidden",
		padding: "var(--spacing-x1) var(--spacing-x5)",
		width: "100%",
		position: "relative",
		// Border has to be declaired with pseudo element because of the border width changes on resizing
		"&::before": {
			content: '""',
			position: "absolute",
			top: 0,
			left: 0,
			bottom: 0,
			width: "1px",
			backgroundColor: "var(--table-cell-border-color)",
			display: "none"
		},
		"&:not(:first-child)::before": {
			display: "block"
		},
		"&$resizing::before": {
			width: "2px",
			backgroundColor: "var(--color-blue-500)"
		},
		"&$cellOverflow": {
			overflow: "visible"
		}
	},
	clickable: {
		"&$highlightedOnHover": {
			cursor: "pointer"
		}
	},
	row: {
		"&:last-child > $cell": {
			borderBottom: "0"
		},
		"&$highlighted:not($selected) > $cell": {
			backgroundColor: "var(--table-even-row-background-color)"
		},
		"&$highlightedOnHover:not($selected):not($disabled):hover > $cell": {
			backgroundColor: "var(--table-hover-row-background-color)"
		},
		"&$compact": {
			height: `${COMPACT_ROW_HEIGHT_PX}px`
		},
		"&$disabled > $cell": {
			opacity: 0.5,
			pointerEvents: "none"
		},
		"&$selected > $cell": {
			backgroundColor: "var(--table-selected-row-background-color)"
		},
		"&$clicked": {
			backgroundColor: "var(--color-purple-200)"
		},
		display: "grid",
		height: `${DEFAULT_ROW_HEIGHT_PX}px`
	},
	selectable: {
		"& > $cell:first-child": {
			padding: "var(--spacing-x1) var(--spacing-x3)"
		}
	},
	evenRow: {
		backgroundColor: "var(--table-even-row-background-color)"
	},
	scrollPlaceholder: {},
	highlighted: {},
	selected: {},
	clicked: {},
	checkboxPlaceholder: {},
	compact: {},
	disabled: {},
	highlightedOnHover: {},
	resizing: {},
	cellOverflow: {}
});
