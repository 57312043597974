import React from "react";
import { useTranslation } from "react-i18next";
import { FilterExpressionEmptyState } from "components/common/FilterExpressionEmptyState";
import { IntegrationIcon } from "components/ui/Icons/IntegrationIcon";
import { IntegrationOption } from "components/ui/selectOptions/IntegrationOption";
import { useIntegrations } from "hooks/useIntegrations";
import { IntegrationLogsMultiSelectFilterExpression } from "../IntegrationLogsMultiSelectFilterExpression";

type TIntegrationFilterExpressionProps = {
	onRemove: () => void;
};

export const IntegrationFilterExpression: FC<TIntegrationFilterExpressionProps> = ({ onRemove }) => {
	const integrations = useIntegrations(true);
	const { t } = useTranslation();

	const emptyState = (
		<FilterExpressionEmptyState
			text={t("pages.auditLog.integrationLogs.filter.integrationEmptyState")}
			Icon={IntegrationIcon}
		/>
	);

	return (
		<IntegrationLogsMultiSelectFilterExpression
			fetchValues={integrations!.toList()}
			filterEmptyState={emptyState}
			filterName="sessionAuditLogIntegrationId"
			inputPlaceholder={t("pages.auditLog.integrationLogs.filter.integrationPlaceholder")}
			onFilterRemove={onRemove}
			optionRenderer={IntegrationOption}
			renderType="IntegrationChip"
			title={t("pages.auditLog.integrationLogs.filter.integration")}
		/>
	);
};
