import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	actions: {
		alignItems: "center",
		display: "flex",
		gap: "1rem"
	},
	content: {
		"&$content$content": {
			background: "none",
			border: "none",
			padding: "1.5rem 2.25rem"
		},
		display: "flex",
		flexDirection: "column",
		gap: "1.5rem"
	},
	createdBy: {
		width: "initial"
	},
	formContainer: {
		padding: "0 2.25em"
	},
	groupFilterTypeRadio: {
		flexDirection: "row",
		padding: 0
	},
	groupSelect: {
		marginLeft: "0.5rem",
		width: "70rem"
	},
	header: {
		display: "flex",
		justifyContent: "space-between"
	},
	label: {
		color: "var(--color-grey-dark)",
		display: "block"
	},
	navigation: {
		display: "flex",
		gap: "0.25rem",
		justifyContent: "space-between"
	},
	templateForm: {
		background: "white",
		borderRadius: "var(--border-radius-semi-rounded)",
		margin: "0 1rem",
		padding: "1rem",
		whiteSpace: "pre"
	},
	title: {
		background: "var(--color-almost-white)",
		display: "flex",
		justifyContent: "space-between",
		padding: "0.75em 1.5em"
	},
	titleContent: {
		alignItems: "center",
		display: "flex",
		gap: "1.5rem",
		justifyContent: "start"
	}
});
