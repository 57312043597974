import { List } from "immutable";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "components/ui/Button";
import { StaticChip } from "components/ui/chips/StaticChip";
import { IconPrefix } from "components/ui/IconPrefix";
import { AddIcon } from "components/ui/Icons/AddIcon";
import { TokenIcon } from "components/ui/Icons/TokenIcon";
import { Section } from "components/ui/Section";
import { usePersonalAccessTokensContext } from "context/personalAccessTokensContext";
import { PersonalAccessTokenModel } from "models/PersonalAccessTokenModel";
import { sortByCreatedAt } from "utils/tickets/ticketActivity";
import { PersonalAccessTokensTable } from "../PersonalAccessTokensTable/PersonalAccessTokensTable";

export type TPersonalAccessToken = PersonalAccessTokenModel | { isTokenNew: true };

export const PersonalAccessTokensTableSection: FC = ({ className, innerRef }) => {
	const { t } = useTranslation();
	const [tokens, setTokens] = useState<List<TPersonalAccessToken>>();
	const [isTokenNew, setIsTokenNew] = useState(false);

	const {
		state: { personalAccessTokens },
		actions: { loadTokens }
	} = usePersonalAccessTokensContext();

	const openCreateInput = useCallback((event: React.MouseEvent) => {
		event.stopPropagation();
		setIsTokenNew(true);
		setTokens(current => current?.unshift({ isTokenNew: true }));
	}, []);

	const title = useMemo(() => {
		return (
			<>
				<IconPrefix
					content={t("pages.profile.changeProfileForm.personalAccessTokens.title")}
					Icon={TokenIcon}
					semibold
				/>
				<StaticChip size="small" variant="regular">
					{t("number", { value: personalAccessTokens?.size || 0 })}
				</StaticChip>
			</>
		);
	}, [t, personalAccessTokens?.size]);

	const actions = useMemo(
		() => (
			<Button onClick={openCreateInput} variant="secondary" size="medium" prefix={<AddIcon />} disabled={isTokenNew}>
				{t("buttons.add")}
			</Button>
		),
		[isTokenNew, openCreateInput, t]
	);

	useEffect(() => {
		if (!personalAccessTokens) {
			void loadTokens();
		}
	}, [personalAccessTokens, loadTokens]);

	useEffect(() => {
		setTokens(
			List(
				personalAccessTokens
					?.valueSeq?.()
					.toArray()
					.sort((a, b) => sortByCreatedAt(a, b, "desc"))
			)
		);
	}, [personalAccessTokens]);

	const tokensArray = useMemo(() => tokens?.toArray(), [tokens]);

	return (
		<Section fullHeight title={title} titleActions={actions}>
			<PersonalAccessTokensTable
				personalAccessTokens={tokensArray}
				setIsTokenNew={setIsTokenNew}
				setPersonalAccessTokens={setTokens}
			/>
		</Section>
	);
};
