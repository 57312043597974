import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { createIntegrationResourceRole, TIntegrationResourceRole } from "api/integrationResourceRoles";
import { Modal } from "components/ui/Modal";
import { TitleBody } from "components/ui/TitleBody";
import { useSubscriber } from "hooks/useSubscriber";
import { IntegrationResourceModel } from "models/IntegrationResourceModel";
import { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";
import { CreateRoleForm } from "./CreateRoleForm";
import { useStyles } from "./styles";

interface IProps {
	resource: IntegrationResourceModel;
	isOpen?: boolean;
	onClose: () => void;
	onSubmit?: (role: IntegrationResourceRoleModel) => void;
}

export const CreateRoleFormModal: FC<IProps> = ({ resource, onSubmit, isOpen = false, onClose }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const { addSubscription: subscribeToResetForm, notify: notifyResetForm } = useSubscriber();

	const closeAndReset = useCallback(() => {
		notifyResetForm();
		onClose();
	}, [notifyResetForm, onClose]);

	const handleOnSubmit = useCallback(
		async (roleToUpdate: TIntegrationResourceRole) => {
			const updatedRole = await createIntegrationResourceRole(roleToUpdate);
			onSubmit?.(updatedRole);
			onClose();
		},
		[onClose, onSubmit]
	);

	return (
		<Modal
			isOpen={isOpen}
			onClose={closeAndReset}
			content={
				<div className={classes.modalContent}>
					<TitleBody size="large" title={t("pages.integration.resource.createRole")} />
					<CreateRoleForm resource={resource} onSubmit={handleOnSubmit} subscribeToResetForm={subscribeToResetForm} />
				</div>
			}
		/>
	);
};
