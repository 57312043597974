import classNames from "classnames";
import React from "react";
import { ChevronDownIcon } from "components/ui/Icons/ChevronDownIcon";
import { ChevronUpIcon } from "components/ui/Icons/ChevronUpIcon";
import { ASC, DESC } from "hooks/usePagination";
import { useStyles } from "./styles";
import type { TSortOrder } from "types/pagination";

type SortButtonProps = {
	field: string;
	order: TSortOrder;
	setOrder: (field: string, order: TSortOrder) => void;
};
export const SortButton: FC<SortButtonProps> = ({ field, order, setOrder, className }) => {
	const classes = useStyles();
	return (
		<div className={classNames(classes.container, className)}>
			<ChevronUpIcon
				className={classNames(order === ASC ? classes.active : undefined, classes.arrow, classes.arrowUp)}
				onClick={() => setOrder(field, ASC)}
			/>
			<ChevronDownIcon
				className={classNames(order === DESC ? classes.active : undefined, classes.arrow, classes.arrowDown)}
				onClick={() => setOrder(field, DESC)}
			/>
		</div>
	);
};
