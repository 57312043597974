import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	"@global": {
		".rdp-root": {
			"--rdp-accent-color": "var(--color-black)",
			"--rdp-font-family": "Poppins, sans-serif",
			"--rdp-month_caption-font":
				"var(--typography-font-weight-semibold) var(--typography-font-size-body) var(--rdp-font-family)",
			"--rdp-range_middle-font": "inherit",
			"--rdp-selected-border": "transparent",
			"--rdp-selected-font": "inherit",
			fontSize: "var(--typography-font-size-text)",
			fontWeight: "var(--typography-font-weight-regular)"
		},
		".rdp-button_next, .rdp-button_previous, .rdp-day_button": {
			"&:hover": {
				background: "var(--color-purple-100)",
				borderRadius: "50%"
			}
		}
	},
	datePicker: {
		alignItems: "flex-start",
		background: "var(--color-white, #FFF)",
		borderRadius: "8px",
		border: "1px solid var(--color-gray-500, #B2B4B8)",
		display: "flex",
		padding: "var(--spacing-x5, 20px)",
		flexDirection: "row",
		gap: "var(--spacing-x6, 24px)",
		width: "100%"
	},
	calender: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		gap: "var(--spacing-x3, 12px)",
		margin: "0px",
		width: "100%"
	},
	rangeEnd: {
		backgroundColor: "var(--color-purple-600, #DFD2E4)",
		borderRadius: "0px 8px 8px 0px",
		color: "var(--color-white, #FFFFFF)",
		"& > .rdp-day_button": {
			background: "transparent",
			borderRadius: 0
		},
		"&:hover": {
			background: "var(--color-purple-300)",
			borderRadius: "0px 8px 8px 0px",
			color: "var(--color-black)",
			" > .rdp-day_button": {
				background: "transparent",
				borderRadius: 0
			}
		}
	},
	rangeStart: {
		backgroundColor: "var(--color-purple-600, #DFD2E4)",
		borderRadius: "8px 0px 0px 8px",
		color: "var(--color-white, #FFFFFF)",
		"& > .rdp-day_button": {
			background: "transparent",
			borderRadius: 0
		},
		"&:hover": {
			background: "var(--color-purple-300)",
			borderRadius: "8px 0px 0px 8px",
			color: "var(--color-black)"
		}
	},
	todayDate: {
		color: "var(--color-blue-500, #4285F4)"
	},
	options: {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start",
		paddingTop: "var(--spacing-x1, 4px)",
		gap: "var(--spacing-x05, 2px)",
		width: "100%"
	},
	option: {
		display: "flex",
		alignItems: "flex-start",
		width: "100%",
		padding: "var(--spacing-x2, 8px) var(--spacing-x3, 12px)",
		cursor: "pointer",
		color: "var(--color-grey-600, #838589)",
		"&$selectedOption": {
			color: "var(--color-purple-600, #5E1D79)",
			background: "var(--color--purple-100, #F7F4F8)"
		}
	},
	oneDate: {
		display: "flex",
		height: "32px",
		padding: "var(--spacing-x2, 8px)",
		justifyContent: "center",
		alignItems: "center",
		borderRadius: "8px",
		background: "var(--color-purple-600, #5E1D79)"
	},
	selectedOption: {}
});
