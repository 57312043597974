import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	modal: {
		padding: "20px"
	},
	modalContent: {
		minWidth: "480px",
		display: "flex",
		flexDirection: "column",
		gap: "20px",
		paddingBottom: "20px"
	}
});
