import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	tooltipHeader: {
		alignItems: "center",
		display: "flex",
		flexDirection: "row",
		gap: "var(--spacing-x2, 8px)"
	},
	maintainersContainer: {
		alignItems: "center",
		justifyContent: "center",
		display: "flex",
		flexGrow: 1
	},
	tooltipBody: {
		display: "flex",
		flexDirection: "column",
		gap: "var(--spacing-x2, 8px)",
		width: "100%"
	}
});
