import React, { useMemo } from "react";
import { ApprovalFlowModel } from "models/ApprovalFlowModel";
import { ApprovalFlowStep } from "../ApprovalFlowStep";
import { OperatorBlock } from "../OperatorBlock";

export const ApprovalFlow: FC<{ approvalFlow: ApprovalFlowModel }> = ({ approvalFlow }) => {
	const { requests } = approvalFlow;
	const orderedSteps = useMemo(() => requests?.sortBy(request => request.sortOrder), [requests]);
	if (!orderedSteps || orderedSteps.size === 0) return null;
	return (
		<>
			{orderedSteps.map(step => (
				<React.Fragment key={step.sortOrder}>
					<OperatorBlock operator="then" />
					<ApprovalFlowStep approvalFlowRequest={step} />
				</React.Fragment>
			))}
		</>
	);
};
