import React from "react";
import { useTranslation } from "react-i18next";
import { IdentityGraphProvider } from "components/pages/IdentityGraphPage/identityGraphContext";
import { PageTemplate } from "components/templates/PageTemplate";
import { IdentityGraph } from "./components/IdentityGraph";
import { IdentityGraphHeader } from "./components/IdentityGraphHeader";
import { useStyles } from "./styles";

export const IdentityGraphPage: FC = ({ className }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	return (
		<IdentityGraphProvider>
			<PageTemplate className={className}>
				<PageTemplate.Title>
					{t("pages.identityGraph.title")}
					<IdentityGraphHeader />
				</PageTemplate.Title>
				<PageTemplate.Content className={classes.noPadding}>
					<IdentityGraph />
				</PageTemplate.Content>
			</PageTemplate>
		</IdentityGraphProvider>
	);
};
