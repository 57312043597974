import React from "react";
import { makeIcon } from "components/ui/Icon";

export const ArrowDownIcon = makeIcon(
	<svg viewBox="0 0 24 24" fill="none">
		<path
			d="M12 19.575C11.8667 19.575 11.7417 19.5542 11.625 19.5125C11.5084 19.4708 11.4 19.4 11.3 19.3L4.70002 12.7C4.50002 12.5 4.40002 12.2625 4.40002 11.9875C4.40002 11.7125 4.50002 11.475 4.70002 11.275C4.90002 11.075 5.13336 10.975 5.40002 10.975C5.66669 10.975 5.90002 11.075 6.10002 11.275L11 16.175V4.975C11 4.69167 11.0959 4.45833 11.2875 4.275C11.4792 4.09167 11.7167 4 12 4C12.2834 4 12.5209 4.09583 12.7125 4.2875C12.9042 4.47917 13 4.71667 13 5V16.175L17.9 11.275C18.1 11.075 18.3334 10.975 18.6 10.975C18.8667 10.975 19.1 11.075 19.3 11.275C19.5 11.475 19.6 11.7125 19.6 11.9875C19.6 12.2625 19.5 12.5 19.3 12.7L12.7 19.3C12.6 19.4 12.4917 19.4708 12.375 19.5125C12.2584 19.5542 12.1334 19.575 12 19.575Z"
			fill="currentColor"
		/>
	</svg>
);
