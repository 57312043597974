import React from "react";
import { Checkbox, TCheckboxProps } from "components/ui/Checkbox";
import { useStyles } from "./styles";
import type { IRowOptions } from "../../types";

function CheckboxCellContent<T>(props: TCheckboxProps<T> & IRowOptions) {
	const classes = useStyles();
	return (
		<div className={classes.centeredCellContent}>
			<Checkbox className={classes.checkbox} {...props} />
		</div>
	);
}

const CheckboxCellContentMemo = React.memo(CheckboxCellContent) as typeof CheckboxCellContent;

export { CheckboxCellContentMemo as CheckboxCellContent };
