export const FADE_BOTTOM_IN_ANIMATION_NAME = "fade-move-from-bottom-in";
export const FADE_BOTTOM_OUT_ANIMATION_NAME = "fade-move-from-bottom-out";

export const styles = {
	[`@keyframes ${FADE_BOTTOM_IN_ANIMATION_NAME}`]: {
		"0%": {
			opacity: "0",
			transform: "translateY(10%)"
		},

		"100%": {
			opacity: "1",
			transform: "translateY(0%)"
		}
	},

	[`@keyframes ${FADE_BOTTOM_OUT_ANIMATION_NAME}`]: {
		"0%": {
			opacity: "1",
			transform: "translateY(0%)"
		},

		"100%": {
			opacity: "0",
			transform: "translateY(-10%)"
		}
	},
	transitionable: {
		"&.animate": {
			'&[data-animation="fade-move-from-bottom"]': {
				"&.in": {
					animation: `var(--animation-duration) var(--transition-easing) forwards $${FADE_BOTTOM_IN_ANIMATION_NAME}`,
					zIndex: "10"
				},

				"&.out": {
					animation: `var(--animation-duration) var(--transition-easing) forwards $${FADE_BOTTOM_OUT_ANIMATION_NAME}`,
					zIndex: "5"
				},

				"&.reverse": {
					"&.in": {
						animation: `var(--animation-duration) var(--transition-easing-reversed) forwards reverse $${FADE_BOTTOM_OUT_ANIMATION_NAME}`
					},

					"&.out": {
						animation: `var(--animation-duration) var(--transition-easing-reversed) forwards reverse $${FADE_BOTTOM_IN_ANIMATION_NAME}`
					}
				}
			}
		}
	}
};
