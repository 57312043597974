import { Record, List } from "immutable";
import { TicketApprovalEntityModel } from "./TicketApprovalEntityModel";

const defaults = {
	id: "",
	ticketApprovalEntity: null as TicketApprovalEntityModel | null,
	ticketApprovalEntityId: "",
	userIds: List<string>()
};

export class TicketApprovalEntityApproverModel extends Record<typeof defaults>(defaults) {
	static fromServerData(data: unknown): TicketApprovalEntityApproverModel {
		const { id, ticketApprovalEntity, ticketApprovalEntityId, userIds } = data as typeof defaults;
		return new TicketApprovalEntityApproverModel({
			id,
			ticketApprovalEntity: ticketApprovalEntity
				? TicketApprovalEntityModel.fromServerData(ticketApprovalEntity)
				: null,
			ticketApprovalEntityId,
			userIds: List(userIds)
		});
	}
}
