import React from "react";

export const HibobLogo: FC = ({ className }) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" className={className}>
		<path
			d="M5.44406 10.2036C4.60373 10.203 3.79196 10.5273 3.16055 11.116V7.60585C3.1593 7.44381 3.09747 7.28894 2.98868 7.17528C2.87989 7.06163 2.73303 6.9985 2.5804 6.99977C2.42777 6.9985 2.28091 7.06163 2.17212 7.17528C2.06332 7.28894 2.0015 7.44381 2.00024 7.60585V13.8022C2.00024 15.7861 3.54637 17.4003 5.4443 17.4003C7.34223 17.4003 8.88883 15.7861 8.88883 13.8022C8.88883 11.8183 7.34271 10.2036 5.44406 10.2036ZM5.44406 16.1886C4.1848 16.1886 3.16055 15.1181 3.16055 13.803C3.16055 12.4878 4.18504 11.4165 5.44406 11.4165C6.70309 11.4165 7.72757 12.4873 7.72757 13.803C7.72757 15.1187 6.70309 16.1879 5.44406 16.1879V16.1886Z"
			fill="#D52B47"
		/>
		<path
			d="M12.4041 10.4871C10.6767 10.4871 9.27136 11.9815 9.27136 13.8205C9.27136 15.6594 10.6767 17.1498 12.4041 17.1498C14.1317 17.1498 15.5372 15.6554 15.5372 13.8185C15.5372 11.9815 14.1317 10.4871 12.4041 10.4871ZM12.4041 16.4592C11.0351 16.4592 9.92121 15.2748 9.92121 13.8192C9.92121 12.3636 11.0351 11.1785 12.4041 11.1785C13.773 11.1785 14.8871 12.3631 14.8871 13.819C14.8871 15.2748 13.773 16.4592 12.4041 16.4592Z"
			fill="#D52B47"
		/>
		<path
			d="M19.0296 10.6597C18.4813 10.6608 17.944 10.8229 17.4772 11.1281C17.0103 11.4333 16.6321 11.8697 16.3845 12.3891V7.26268C16.3831 7.21876 16.3661 7.17704 16.3368 7.146C16.3075 7.11495 16.2682 7.0969 16.2268 7.09551C16.1839 7.09442 16.1423 7.11138 16.1111 7.14269C16.0799 7.17401 16.0617 7.21713 16.0603 7.26268V13.8187C16.0603 15.5611 17.3924 16.9778 19.0305 16.9778C20.6687 16.9778 22.0001 15.5606 22.0001 13.8189C22.0001 12.0772 20.6673 10.6597 19.0296 10.6597ZM19.0296 16.6316C17.5712 16.6316 16.3845 15.3689 16.3845 13.8184C16.3845 12.2679 17.571 11.006 19.0296 11.006C20.4882 11.006 21.6749 12.2686 21.6749 13.8189C21.6749 15.3692 20.4882 16.6321 19.0296 16.6321V16.6316Z"
			fill="#D52B47"
		/>
	</svg>
);
