import React, { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, Link } from "react-router-dom";
import { Avatar } from "components/common/Avatar";
import { BeyondTrustLogoGroup } from "components/common/BeyondTrustLogoGroup";
import { Link as ExternalLink } from "components/common/Link";
import { Divider } from "components/ui/Divider";
import { IconButton } from "components/ui/IconButton";
import { HelpIcon } from "components/ui/Icons/HelpIcon";
import { SignOutIcon } from "components/ui/Icons/SignOutIcon";
import { UserCircleIcon } from "components/ui/Icons/UserCircleIcon";
import { Tooltip } from "components/ui/Tooltip";
import { useAuthContext } from "context/authContext";
import { useIsBeta } from "context/betaContext";
import { useAuthenticatedUser } from "hooks/useAuthenticatedUser";
import { useOnClickOutside } from "hooks/useOnClickOutside";
import { useTooltip } from "hooks/useTooltip";
import { routes } from "routes/routes";
import { NavbarButton } from "./components/NavbarButton";
import { SelectionOption } from "./components/SelectionOption";
import { useStyles } from "./styles";

const README_URL =
	"https://docs.entitle.io/password?redirect=/docs/gcp-service-integration-explicit-service-account-option";

export const TopNav: FC = () => {
	const classes = useStyles();
	const { t } = useTranslation();
	const isBeta = useIsBeta();

	const { user } = useAuthenticatedUser();

	const userActionsRef = useRef<HTMLDivElement>(null);

	const [isUserActionsModalOpen, setIsUserActionsModalOpen] = useState(false);
	const { visible, setTooltipRef, getTooltipProps, setTriggerRef } = useTooltip({
		visible: isUserActionsModalOpen,
		offset: [0, 6],
		placement: "bottom-start"
	});

	const { signOut } = useAuthContext();
	const navigate = useNavigate();

	const closeModal = useCallback(() => setIsUserActionsModalOpen(false), []);
	useOnClickOutside(userActionsRef || undefined, closeModal);

	const handleClick = useCallback(() => {
		setIsUserActionsModalOpen(state => !state);
	}, []);

	const navigateToProfile = useCallback(() => {
		closeModal();
		navigate(routes.profile.main);
	}, [closeModal, navigate]);

	const handleSignOut = useCallback(() => {
		closeModal();
		void signOut();
	}, [closeModal, signOut]);

	const userButtonPrefix = user?.imageUrl ? (
		<Avatar user={user} className={classes.userIcon} />
	) : (
		<UserCircleIcon className={classes.userIcon} />
	);

	return (
		<div className={classes.container}>
			<Link to={isBeta ? routes.requests.main : routes.myRequests.main}>
				<BeyondTrustLogoGroup />
			</Link>
			<div className={classes.rightNav}>
				{user?.isAdmin ? (
					<>
						<ExternalLink
							external
							to={README_URL}
							target="_blank"
							rel="noopener noreferrer"
							className={classes.externalLink}>
							<Tooltip content={t("navigation.linkToEntitleDocs")} placement="left">
								<IconButton size="large" variant="reverse">
									<HelpIcon />
								</IconButton>
							</Tooltip>
						</ExternalLink>
						<Divider vertical />
					</>
				) : null}
				<div className={classes.userContainer} ref={userActionsRef}>
					<NavbarButton innerRef={setTriggerRef} onClick={handleClick} prefix={userButtonPrefix} active={visible}>
						{user!.fullName}
					</NavbarButton>
					{visible && (
						<div ref={setTooltipRef} {...getTooltipProps()} className={classes.userActionsModal}>
							<SelectionOption onClick={navigateToProfile} prefix={<UserCircleIcon />}>
								{t("navigation.profile")}
							</SelectionOption>
							<SelectionOption onClick={handleSignOut} prefix={<SignOutIcon />}>
								{t("navigation.signOut")}
							</SelectionOption>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};
