import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "components/ui/Button";
import { Modal } from "components/ui/Modal";
import { TitleBody } from "components/ui/TitleBody";
import { useStyles } from "./styles";

interface ISuccessModalProps {
	onClose: () => void;
	isOpen: boolean;
}

export const SuccessModal: FC<ISuccessModalProps> = ({ onClose, isOpen }) => {
	const { t } = useTranslation();
	const classes = useStyles();

	return (
		<Modal
			content={
				<TitleBody
					size="large"
					title={t("pages.newTicket.newTicketForm.successModal.title")}
					body={t("pages.newTicket.newTicketForm.successModal.information")}
					className={classes.modalContent}
				/>
			}
			actions={
				<Button size="medium" onClick={onClose}>
					{t("buttons.close")}
				</Button>
			}
			isOpen={isOpen}
			onClose={onClose}
		/>
	);
};
