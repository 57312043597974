import { Record } from "immutable";
import uniq from "lodash/uniq";
import { validateFilterEqualityOperator } from "utils/validation/filterValidation";
import type { IFilter } from "types/filters";

const filterName = "integrationResourceRoleIntegrationId";

const defaults = {
	name: filterName as typeof filterName,
	value: [] as string[],
	operator: "is" as "is" | "isNot"
};

type TDefaults = typeof defaults;

export class IntegrationResourceRoleIntegrationIdFilter
	extends Record<TDefaults>(defaults)
	implements IFilter<TDefaults["value"], TDefaults["operator"], TDefaults["name"]>
{
	static filterName: typeof filterName = filterName;

	static fromURLSearchParams(urlSearchParams: URLSearchParams): IntegrationResourceRoleIntegrationIdFilter | null {
		const integrationResourceRoleIntegrationId = uniq(urlSearchParams.getAll("integrationResourceRoleIntegrationId"));
		const integrationResourceRoleIntegrationIdOperator =
			urlSearchParams.get("integrationResourceRoleIntegrationIdOperator") || undefined;
		if (
			integrationResourceRoleIntegrationId.length === 0 ||
			!validateFilterEqualityOperator(integrationResourceRoleIntegrationIdOperator)
		) {
			return null;
		}
		return new IntegrationResourceRoleIntegrationIdFilter({
			value: integrationResourceRoleIntegrationId,
			operator: integrationResourceRoleIntegrationIdOperator
		});
	}

	toURLSearchParams(): URLSearchParams {
		const params = new URLSearchParams();
		if (!this.value.length) {
			return params;
		}
		for (const value of uniq(this.value)) {
			params.append("integrationResourceRoleIntegrationId", value);
		}
		params.append("integrationResourceRoleIntegrationIdOperator", this.operator);
		return params;
	}
}

export type TIntegrationResourceRoleIntegrationIdFilter = Omit<TDefaults, "name">;
