import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	imageGrid: {
		overflow: "hidden",
		flexDirection: "column",
		display: "flex"
	},
	imageRow: {
		display: "flex",
		flexDirection: "row",
		overflow: "hidden",
		justifyContent: "start",
		flex: 1
	},
	image: {
		objectFit: "contain",
		width: "100%",
		height: "100%",
		flex: 1
	},
	emptyCell: {
		flexGrow: 1
	}
});
