import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	formArea: {
		"&.error": {
			border: "1px solid var(--color-red)"
		},
		width: "100%"
	},
	toggleOauthButton: {
		alignSelf: "center"
	},
	label: {
		color: "var(--color-grey-dark)"
	},
	select: {
		marginBottom: "1.5rem"
	},
	oauthContainer: {
		height: "calc(11rem + 2px);",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flexDirection: "column"
	}
});
