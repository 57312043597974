import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "components/ui/Button";
import { Modal } from "components/ui/Modal";
import { TitleBody } from "components/ui/TitleBody";
import { useStyles } from "./styles";

interface IProps {
	isOpen: boolean;
	state: "edit" | "create";
	editTitle: string;
	createTitle: string;
	content: string;
	onClose: () => void;
}

export const EditorSuccessModal: FC<IProps> = ({ isOpen, state, onClose, editTitle, createTitle, content }) => {
	const { t } = useTranslation();
	const classes = useStyles();

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			content={
				<div className={classes.modalContent}>
					<TitleBody size="large" title={state === "edit" ? editTitle : createTitle} body={content} />
				</div>
			}
			actions={
				<Button size="medium" onClick={onClose}>
					{t("buttons.close")}
				</Button>
			}></Modal>
	);
};
