import { createUseStyles } from "react-jss";

const getSize = ({ size }: { size?: string | number }) => {
	return size && typeof size === "number" ? `${size}px` : "1em";
};

export const useStyles = createUseStyles({
	logoContainer: {
		flexShrink: 0,
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
		boxSizing: "border-box",
		border: "1px solid var(--color-grey-background)",
		borderRadius: "var(--border-radius-semi-rounded)",
		"& > *": {
			height: "100%",
			width: "100%"
		},
		"&$xs": {
			height: "20px",
			width: "20px",
			"& > $logo": {
				height: "16px",
				width: "16px"
			}
		},
		"&$small": {
			height: "28px",
			width: "28px",
			"& > $logo": {
				height: "20px",
				width: "20px"
			}
		},
		"&$medium": {
			height: "40px",
			width: "40px",
			"& > $logo": {
				height: "24px",
				width: "24px"
			}
		},
		"&$large": {
			height: "48px",
			width: "48px",
			"& > $logo": {
				height: "28px",
				width: "28px"
			}
		},
		"&$disabled": {
			border: "none",
			backgroundColor: "var(--color-grey-background)",
			"& > *": {
				mixBlendMode: "luminosity"
			}
		},
		"&$noBorder": {
			border: "none"
		},
		"&$customSize": {
			height: getSize,
			width: getSize,
			fontSize: getSize
		}
	},
	customSize: {},
	disabled: {},
	large: {},
	logo: {},
	medium: {},
	noBorder: {},
	small: {},
	xs: {},
	xl: {}
});
