import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	container: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center"
	},
	rolesContainer: {
		display: "flex",
		alignItems: "center",
		flexWrap: "wrap",
		gap: "1rem",
		padding: "1rem 0.5rem"
	},
	addRole: {
		width: "fit-content",
		height: "fit-content",
		"&$addRole": {
			padding: "0.5rem 0.75rem"
		}
	},
	removeClause: {
		margin: "0.5rem",
		height: "fit-content",
		"&$removeClause$removeClause$removeClause": {
			border: "none",
			"&:hover": {
				backgroundColor: "transparent"
			}
		}
	}
});
