import classNames from "classnames";
import React from "react";
import { Button } from "components/ui/Button";
import { useStyles } from "./styles";

type TProps = { active?: boolean; onClick?: () => void; prefix?: React.ReactNode };

export const NavbarButton: FC<TProps> = ({ children, active, onClick, prefix, innerRef }) => {
	const classes = useStyles();

	return (
		<Button
			prefix={prefix}
			onClick={onClick}
			innerRef={innerRef}
			size="medium"
			className={classNames(classes.button, { [classes.active]: active })}>
			{children}
		</Button>
	);
};
