import { Map } from "immutable";
import { OnCallIntegrationScheduleModel } from "models/OnCallIntegrationScheduleModel";
import { apiReq } from "utils/api/apiReq";

export async function getOnCallIntegrationSchedules(): Promise<Map<string, OnCallIntegrationScheduleModel>> {
	const { data } = await apiReq("GET", "/v1/onCallIntegrationSchedules");

	return Map<string, OnCallIntegrationScheduleModel>(
		data.map((schedule: Record<string, unknown>) => [
			schedule.id as string,
			OnCallIntegrationScheduleModel.fromServerData(schedule)
		])
	);
}
