import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { UserEntity } from "components/common/entities/UserEntity";
import { WorkflowEntity } from "components/common/entities/WorkflowEntity";
import { ResourceHeader } from "components/common/ResourceHeader";
import { Typography } from "components/ui/legacy/Typography";
import { LoadingSpinner } from "components/ui/LoadingSpinner";
import { Separator } from "components/ui/Separator";
import { useUser } from "hooks/useUser";
import { IntegrationModel } from "models/IntegrationModel";
import { useStyles } from "./styles";

interface IProps {
	integration: IntegrationModel;
}

export const IntegrationCard: FC<IProps> = ({ className, integration }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const integrationOwner = useUser(integration?.ownerId);

	return (
		<div className={classNames(classes.card, className)}>
			<Link to={`/integrations/${integration.id}`} className={classes.content}>
				{integration.ownerId && (
					<div className={classNames(classes.cell, classes.summaryCell)}>
						<ResourceHeader
							name={integration.name}
							imageUrl={integration.imageUrl}
							ownerId={integration.ownerId}
							childrenCount={integration.resourcesCount || 0}
							resourceType="integration"
						/>
					</div>
				)}
				<Separator />
				{!integrationOwner ? (
					<LoadingSpinner className={classes.spinner} size="extra-small" />
				) : (
					<div className={classes.cell}>
						<Typography relative>
							<UserEntity withIcon user={integrationOwner} />
						</Typography>
					</div>
				)}
				<Separator />
				<div className={classes.cell}>
					{integration.defaultApprovalAlgorithm ? (
						<WorkflowEntity withIcon size="medium" approvalAlgorithm={integration.defaultApprovalAlgorithm} />
					) : (
						<Typography>{t("pages.integrations.noApprovalAlgorithm")}</Typography>
					)}
				</div>
			</Link>
		</div>
	);
};
