import { useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { respondToTicket as apiRespondToTicket } from "api/tickets";
import { getUserPendingTickets } from "api/user";
import { useTicketUpdatesContext } from "context/ticketUpdatesContext";
import { useLoadingState } from "hooks/useLoadingState";
import { DESC, usePagination } from "hooks/usePagination";
import { TicketModel } from "models/TicketModel";
import type { TSortOrder } from "types/pagination";

export const PENDING_PER_PAGE = 30;
const PENDING_REQUESTS_PAGE_SUBSCRIPTION_ID = "pendingRequestsPage";

export const usePendingTickets = (sortOrder: TSortOrder = DESC, sortFields?: string[]) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const { isLoading, withLoader } = useLoadingState();
	const {
		getPage,
		totalPages,
		lastPageNumber,
		itemsForVirtualTable: pendingTickets,
		setItem,
		removeItem,
		totalResults: totalPendingTickets
	} = usePagination({ fetchItems: getUserPendingTickets, perPage: PENDING_PER_PAGE, sortOrder, sortFields });
	const { unsubscribeTicketUpdates, subscribeTicketUpdates } = useTicketUpdatesContext();

	const onTicketRedirected = useCallback(
		(updatedTicket: TicketModel) => {
			removeItem(updatedTicket.id);
			if (searchParams.get("ticketId")) {
				searchParams.delete("ticketId");
				setSearchParams(searchParams);
			}
		},
		[removeItem, searchParams, setSearchParams]
	);

	const getNextPage = useCallback(() => {
		if (!isLoading) {
			const loadNextPage = async () => {
				await getPage(lastPageNumber + 1);
			};
			void withLoader(loadNextPage());
		}
	}, [getPage, isLoading, lastPageNumber, withLoader]);

	useEffect(() => {
		if (!isLoading && (pendingTickets === null || lastPageNumber === 0)) {
			getNextPage();
		}
	}, [pendingTickets, isLoading, lastPageNumber, getNextPage]);

	const onTicketUpdated = useCallback(
		(ticket: TicketModel) => {
			setItem(ticket);
		},
		[setItem]
	);

	useEffect(() => {
		subscribeTicketUpdates(PENDING_REQUESTS_PAGE_SUBSCRIPTION_ID, onTicketUpdated);
		return () => {
			unsubscribeTicketUpdates(PENDING_REQUESTS_PAGE_SUBSCRIPTION_ID);
		};
	}, [onTicketUpdated, subscribeTicketUpdates, unsubscribeTicketUpdates]);

	const respondToTicket = useCallback(
		async (ticketId: string, response: boolean) => {
			const ticket = await apiRespondToTicket(ticketId, response);
			setItem(ticket);
			return ticket;
		},
		[setItem]
	);

	return {
		onTicketRedirected,
		pendingTickets,
		getNextPage,
		lastPageNumber,
		totalPages,
		totalPendingTickets,
		respondToTicket,
		getPage
	};
};
