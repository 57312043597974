import classNames from "classnames";
import React from "react";
import { Typography } from "components/ui/legacy/Typography";
import { useStyles } from "./styles";

export const FlowBlock: FC<{ label: string | React.JSX.Element }> = ({ label, className, children }) => {
	const classes = useStyles();
	if (!children) return null;
	return (
		<div className={classNames(classes.flowBlock, className)}>
			<div className={classes.flowBlockCaption}>
				<Typography className={classes.flowBlockCaptionTypography} component="div">
					{label}
				</Typography>
			</div>
			{children}
		</div>
	);
};
