import classNames from "classnames";
import get from "lodash/get";
import React from "react";
import { devLog } from "utils/devtools/devLogging";
import { useStyles } from "./styles";

export type TVariant = "h1" | "h2" | "h3" | "standard" | "small" | "tiny" | "sectionTitle" | "button";

interface ITypographyProps {
	component?: keyof React.ReactHTML;
	fade?: boolean;
	multiLine?: boolean;
	noWrap?: boolean;
	relative?: boolean;
	prefixIcon?: React.ReactNode;
	variant?: TVariant;
}
type TTypographyProps = React.HTMLAttributes<HTMLElement> & ITypographyProps;

export const Typography: FC<TTypographyProps> = ({
	children,
	className,
	component = null,
	fade = false,
	innerRef,
	multiLine = false,
	noWrap = false,
	prefixIcon = null,
	relative = false,
	variant = "standard",
	...componentProps
}) => {
	const classes = useStyles();
	const componentType = component || variantToComponentNameMapping.get(variant) || "div";
	const finalChildren = prefixIcon ? (
		<>
			{prefixIcon}
			{children}
		</>
	) : (
		children
	);
	return componentNameToComponent(componentType, {
		className: classNames(
			classes.base,
			get(classes, variant),
			{
				[classes.noWrap]: noWrap,
				[classes.relative]: relative,
				[classes.multiLine]: multiLine,
				[classes.fade]: fade,
				[classes.prefixIcon]: !!prefixIcon
			},
			className
		),
		children: finalChildren,
		ref: innerRef as React.Ref<HTMLElement>,
		...componentProps
	});
};

const variantToComponentNameMapping: Map<TVariant, keyof React.ReactHTML> = new Map([
	["h1", "h1"],
	["h2", "h2"],
	["h3", "h3"],
	["standard", "div"],
	["small", "div"],
	["tiny", "div"],
	["sectionTitle", "h3"],
	["button", "div"]
]);

const componentNameToComponent = (
	componentName: keyof React.ReactHTML,
	props: React.HTMLAttributes<HTMLElement> & { ref: React.Ref<HTMLElement> }
) => {
	try {
		return React.createElement(componentName, props);
	} catch (error) {
		devLog({ message: "Error creating component", extra: { componentName, props, error }, level: "warn" });
		return React.createElement("p", props);
	}
};
