import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	approvalAlgorithmOption: {
		display: "flex",
		flexDirection: "row",
		width: "100%",
		alignItems: "center",
		gap: "var(--spacing-x2, 8px)",
		padding: "0 var(--spacing-x4, 16px)",
		cursor: "pointer",
		minHeight: "40px"
	},
	emptyState: {
		height: "41px"
	}
});
