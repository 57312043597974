import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	container: {
		alignSelf: "stretch",
		display: "flex",
		width: "100%",
		alignItems: "center",
		justifyContent: "center"
	},
	label: {
		alignItems: "center",
		display: "flex",
		gap: "var(--spacing-x1, 4px)"
	},
	labelTooltip: {
		maxWidth: "322px"
	}
});
