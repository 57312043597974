import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	emptyStateContainer: {
		display: "flex",
		justifyContent: "center",
		alignItems: "flex-start",
		paddingTop: "80px",
		paddingBottom: "80px",
		color: "var(--color-grey-700)"
	}
});
