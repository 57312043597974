import React from "react";
import { makeIcon } from "components/ui/Icon";

export const PermissionsIcon = makeIcon(
	<svg viewBox="0 0 24 24" fill="none">
		<path
			d="M7 18C5.33333 18 3.91667 17.4167 2.75 16.25C1.58333 15.0833 1 13.6667 1 12C1 10.3333 1.58333 8.91667 2.75 7.75C3.91667 6.58333 5.33333 6 7 6C8.1 6 9.10833 6.275 10.025 6.825C10.9417 7.375 11.6667 8.1 12.2 9H21C21.55 9 22.0208 9.19583 22.4125 9.5875C22.8042 9.97917 23 10.45 23 11V13C23 13.55 22.8042 14.0208 22.4125 14.4125C22.0208 14.8042 21.55 15 21 15V16C21 16.55 20.8042 17.0208 20.4125 17.4125C20.0208 17.8042 19.55 18 19 18H17C16.45 18 15.9792 17.8042 15.5875 17.4125C15.1958 17.0208 15 16.55 15 16V15H12.2C11.6667 15.9 10.9417 16.625 10.025 17.175C9.10833 17.725 8.1 18 7 18ZM7 16C8.1 16 8.98333 15.6625 9.65 14.9875C10.3167 14.3125 10.7167 13.65 10.85 13H17V16H19V13H21V11H10.85C10.7167 10.35 10.3167 9.6875 9.65 9.0125C8.98333 8.3375 8.1 8 7 8C5.9 8 4.95833 8.39167 4.175 9.175C3.39167 9.95833 3 10.9 3 12C3 13.1 3.39167 14.0417 4.175 14.825C4.95833 15.6083 5.9 16 7 16ZM7 14C7.55 14 8.02083 13.8042 8.4125 13.4125C8.80417 13.0208 9 12.55 9 12C9 11.45 8.80417 10.9792 8.4125 10.5875C8.02083 10.1958 7.55 10 7 10C6.45 10 5.97917 10.1958 5.5875 10.5875C5.19583 10.9792 5 11.45 5 12C5 12.55 5.19583 13.0208 5.5875 13.4125C5.97917 13.8042 6.45 14 7 14Z"
			fill="currentColor"
		/>
	</svg>
);
