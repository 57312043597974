import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { DescribedCheckbox } from "components/common/DescribedCheckbox";
import {
	AgentTokenSelect,
	DEFAULT_AGENT_TOKEN_OPTION_ID,
	ITokenOption
} from "components/pages/SettingsPage/components/AgentTokensSelect";
import { Button } from "components/ui/Button";
import { Form } from "components/ui/Form";
import { WarningIcon } from "components/ui/Icons/WarningIcon";
import { Typography } from "components/ui/legacy/Typography";
import { useLoadingState } from "hooks/useLoadingState";
import { useStyles } from "./styles";

interface IProps {
	onSave: (agentTokenId?: string | null, isDirectManagerSource?: boolean) => Promise<void>;
	isDirectManagerSource?: boolean;
	error?: string;
	disabled: boolean;
	inEditMode: boolean;
}

export const CommonConfigFields: FC<IProps> = ({ onSave, isDirectManagerSource, error, disabled, inEditMode }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const { withLoader, isLoading } = useLoadingState();
	const [agentToken, setAgentToken] = useState<ITokenOption | null>(null);
	const [directManagerSource, setDirectManagerSource] = useState(isDirectManagerSource);

	const toggleDirectManagerSource = useCallback(() => setDirectManagerSource(current => !current), []);

	const handleSave = useCallback(async () => {
		const agentTokenId = agentToken && agentToken.id !== DEFAULT_AGENT_TOKEN_OPTION_ID ? agentToken.id : null;
		await withLoader(onSave(agentTokenId, directManagerSource));
	}, [agentToken, directManagerSource, onSave, withLoader]);

	return (
		<>
			{!inEditMode && (
				<Form.Field>
					<DescribedCheckbox
						descriptionClassName={classes.description}
						label={t("pages.settings.directoryConfiguration.directManagerSource.label")}
						description={t("pages.settings.directoryConfiguration.directManagerSource.description")}
						selected={directManagerSource}
						onClick={toggleDirectManagerSource}
					/>
				</Form.Field>
			)}
			<Form.Field>
				<AgentTokenSelect value={agentToken} onChange={setAgentToken} />
			</Form.Field>
			<Form.Actions className={classes.actions}>
				{error && (
					<Typography variant="small" className={classes.error}>
						<WarningIcon />
						{error}
					</Typography>
				)}
				<Button
					variant="primary"
					size="medium"
					disabled={isLoading || disabled}
					loading={isLoading}
					onClick={handleSave}>
					{t("buttons.save")}
				</Button>
			</Form.Actions>
		</>
	);
};
