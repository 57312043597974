import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	actionButton: {
		"&$actionButton$actionButton": {
			"&:hover": {
				backgroundColor: "var(--color-grey-background)"
			},
			alignItems: "center",
			alignSelf: "center",
			borderColor: "var(--color-grey-dark)",
			color: "var(--color-grey-dark)",
			display: "inline-flex",
			fontSize: "1rem",
			height: "2rem",
			justifyContent: "space-around",
			width: "2rem"
		}
	},
	actionButtons: {
		alignItems: "center",
		justifyContent: "center",
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
		gap: "0.5rem",
		height: "100%",
		overflowWrap: "inherit",
		width: "100%"
	},
	approvedStatusChip: {
		backgroundColor: "rgba(34, 227, 114, 0.1)"
	},
	requestsContainer: {
		borderRadius: "var(--border-radius-sharp)"
	}
});
