import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { generateCLIAccessToken } from "api/personalAccessTokens";
import { Typography } from "components/ui/legacy/Typography";
import { LoadingSpinner } from "components/ui/LoadingSpinner";
import { useAsyncImpact } from "hooks/useAsyncImpact";
import { useStyles } from "./styles";

export const CLITokenGenerationPage = () => {
	const classes = useStyles();
	const [queryParams] = useSearchParams();
	const [error, setError] = useState(false);
	const [done, setDone] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const { t } = useTranslation();

	useAsyncImpact(async () => {
		const portParam = queryParams.get("port");
		const securedParam = queryParams.get("secured");
		const port = Number(portParam);

		if (!portParam || isNaN(port)) {
			setError(true);
			setErrorMessage(t("pages.cliTokenGeneration.invalidPort"));
			return;
		}
		try {
			await generateCLIAccessToken(port, securedParam);
			setDone(true);
		} catch (error) {
			setError(true);
			setErrorMessage((error as Error).message);
		}
	}, [queryParams, t]);

	return (
		<div className={classes.pageContainer}>
			{error ? (
				<Typography variant="standard">{errorMessage}</Typography>
			) : done ? (
				<Typography variant="standard">{t("pages.cliTokenGeneration.done")}</Typography>
			) : (
				<LoadingSpinner />
			)}
		</div>
	);
};
