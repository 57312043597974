import classNames from "classnames";
import get from "lodash/get";
import React from "react";
import { useStyles } from "./styles";

interface IProps {
	onClick: () => void;
	disabled?: boolean;
	selected?: boolean;
	variant?: "circle" | "oval";
}

export const PaginationButton: FC<IProps> = ({
	children,
	className,
	disabled,
	id,
	innerRef,
	onClick,
	selected = false,
	variant = "oval"
}) => {
	const classes = useStyles();

	return (
		<div
			className={classNames(classes.button, className, get(classes, variant), {
				[classes.disabled]: disabled,
				[classes.selected]: selected
			})}
			id={id}
			ref={innerRef}
			onClick={onClick}>
			{children}
		</div>
	);
};
