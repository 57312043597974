import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { getIntegrationsResources } from "api/accessRequestForm";
import { ResourceChip } from "components/ui/chips/ResourceChip";
import { IRenderChipParams, MultipleSelect } from "components/ui/MultipleSelect";
import { ResourceOption } from "components/ui/selectOptions/ResourceOption";
import { useSelectSearchProps } from "hooks/useSelectSearchProps";
import { IntegrationResourceModel } from "models/IntegrationResourceModel";

const LIMIT = 100;

interface IProps {
	setResources: (value: IntegrationResourceModel[] | null) => void;
	resources: IntegrationResourceModel[] | null;
	selectedIntegrationIds: string[];
	selectedResourceTypes: string[] | null;
	userId: string;
}

const getOptionLabel = (option: IntegrationResourceModel) => option.name;
const equality = (option: IntegrationResourceModel, value: IntegrationResourceModel) => option.id === value.id;

export const ResourcesInput: FC<IProps> = ({
	setResources,
	resources,
	selectedIntegrationIds,
	selectedResourceTypes,
	userId
}) => {
	const { t } = useTranslation(undefined, { keyPrefix: "pages.newTicket.newTicketForm.resourcesInput" });

	const fetchResources = useCallback(
		async (query: string) => {
			if (selectedIntegrationIds?.length) {
				const response = await getIntegrationsResources({
					userId,
					integrationIds: selectedIntegrationIds,
					search: query,
					resourceTypes: selectedResourceTypes || undefined,
					perPage: LIMIT
				});
				return response.result.toArray();
			}

			return [];
		},
		[selectedResourceTypes, selectedIntegrationIds, userId]
	);

	const { selectProps } = useSelectSearchProps(fetchResources, resources, true);

	const renderChip = useCallback(({ option, componentKey, ...rest }: IRenderChipParams<IntegrationResourceModel>) => {
		return (
			<ResourceChip
				variant="regular"
				integrationResource={option}
				key={componentKey}
				selected
				onDelete={rest.onRemove}
			/>
		);
	}, []);

	return (
		<MultipleSelect
			{...selectProps}
			disabled={!selectedIntegrationIds?.length}
			filter={null}
			getOptionLabel={getOptionLabel}
			isOptionEqualToValue={equality}
			label={t("label")}
			limit={LIMIT}
			limitChipType="resource"
			onChange={setResources}
			renderOption={ResourceOption}
			placeholder={t("placeholder")}
			renderChip={renderChip}
			required
			sort={null}
			value={resources}
		/>
	);
};
