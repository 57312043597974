import React from "react";
import { makeIcon } from "components/ui/Icon";

export const GrantedIcon = makeIcon(
	<svg viewBox="0 0 24 24" fill="none">
		<path
			d="M9.55003 17.575C9.4167 17.575 9.2917 17.5542 9.17503 17.5125C9.05836 17.4708 8.95003 17.4 8.85003 17.3L4.55003 13C4.3667 12.8167 4.2792 12.5792 4.28753 12.2875C4.29586 11.9958 4.3917 11.7583 4.57503 11.575C4.75836 11.3917 4.9917 11.3 5.27503 11.3C5.55836 11.3 5.7917 11.3917 5.97503 11.575L9.55003 15.15L18.025 6.67499C18.2084 6.49166 18.4459 6.39999 18.7375 6.39999C19.0292 6.39999 19.2667 6.49166 19.45 6.67499C19.6334 6.85833 19.725 7.09583 19.725 7.38749C19.725 7.67916 19.6334 7.91666 19.45 8.09999L10.25 17.3C10.15 17.4 10.0417 17.4708 9.92503 17.5125C9.80836 17.5542 9.68336 17.575 9.55003 17.575Z"
			fill="currentColor"
		/>
	</svg>
);
