import { Record } from "immutable";
import { IntegrationModel } from "models/IntegrationModel";
import type { TDiff } from "utils/auditLogs/auditLogUtils";

export interface IIntegrationAuditLogDiffData {
	token?: string;
	ownerUserId?: TDiff<string>;
	defaultApprovalAlgorithmId?: TDiff<string>;
	name?: TDiff<string>;
	notifyAboutExternalPermissions?: TDiff<boolean>;
	allowsRequests: TDiff<boolean>;
	defaultAllowsRequests: TDiff<boolean>;
	updateAllResources: boolean;
	errorMessage?: string;
	autoAssignRecommendedResourceMaintainers: TDiff<boolean>;
	autoAssignRecommendedResourceOwner: TDiff<boolean>;
	receiverUserId?: string;
	allowedDurations?: TDiff<number[]>;
	canEditPermissions?: TDiff<boolean>;
	canCreateActors?: TDiff<boolean>;
}

type TIntegrationAuditLogAction =
	| "IntegrationCreated"
	| "IntegrationDeleted"
	| "IntegrationSyncAccountsSucceeded"
	| "IntegrationSyncResourcesSucceeded"
	| "IntegrationSyncPermissionsSucceeded"
	| "IntegrationUpdatedOverrideAllowedDurations"
	| "IntegrationUpdatedAllowRequests"
	| "IntegrationUpdatedWorkflow"
	| "IntegrationUpdatedAutoAssignRecommendedResourceMaintainers"
	| "IntegrationUpdatedAutoAssignRecommendedResourceOwners"
	| "IntegrationUpdatedAllowCreatingAccounts"
	| "IntegrationUpdatedAllowChangingAccountPermissions"
	| "IntegrationUpdatedConnection"
	| "IntegrationUpdatedAllowRequestsByDefault"
	| "IntegrationUpdatedIcon"
	| "IntegrationUpdatedMaintainers"
	| "IntegrationUpdatedName"
	| "IntegrationUpdatedNotifyAboutExternalPermissionsChanges"
	| "IntegrationUpdatedOwner"
	| "IntegrationUpdatedReadOnly"
	| "IntegrationAccessGrantFailed"
	| "IntegrationAccessRevokeFailed"
	| "IntegrationSyncAccountsFailed"
	| "IntegrationSyncResourcesFailed"
	| "IntegrationSyncPermissionsFailed";

const defaults = {
	action: "IntegrationCreated" as TIntegrationAuditLogAction,
	integration: null as IntegrationModel | null,
	integrationId: "",
	id: "",
	createdAt: new Date(0),
	data: null as IIntegrationAuditLogDiffData | null,
	userId: ""
};

export class IntegrationAuditLogModel extends Record<typeof defaults>(defaults) {
	static readonly type = "integration";

	static fromServerData(data: unknown) {
		const { id, createdAt, data: auditLogData, action, integration, integrationId, userId } = data as typeof defaults;

		return new IntegrationAuditLogModel({
			id,
			createdAt: new Date(createdAt),
			data: auditLogData,
			action,
			integration: integration && IntegrationModel.fromServerData(integration),
			integrationId,
			userId
		});
	}
}
