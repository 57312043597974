import React from "react";
import { useTranslation } from "react-i18next";
import { useNewTicketFormContext } from "components/pages/NewTicketPage/newTicketContext";
import { Form } from "components/ui/Form";
import { TextAreaInput } from "components/ui/TextAreaInput";
import { useStyles } from "./styles";

export const JustificationField: FC = () => {
	const { t } = useTranslation(undefined, { keyPrefix: "pages.newTicket.newTicketForm.justificationInput" });
	const classes = useStyles();

	const {
		state: {
			formState: { justification }
		},
		actions: {
			formActions: { setJustification, justificationValidators }
		}
	} = useNewTicketFormContext();

	return (
		<Form.Field>
			<TextAreaInput
				label={t("label")}
				onValueChange={setJustification}
				placeholder={t("placeholder")}
				value={justification || ""}
				isRequired
				autoResize
				textAreaClassName={classes.justification}
				inputClassName={classes.textareaInput}
				validators={justificationValidators}
			/>
		</Form.Field>
	);
};
