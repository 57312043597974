import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { AccountIcon } from "components/ui/Icons/AccountIcon";
import { TitleBody } from "components/ui/TitleBody";
import { Tooltip } from "components/ui/Tooltip";
import { useStyles } from "./styles";
import { EntityNodeContent } from "../components/EntityNodeContent";
import { DeletedIndicator } from "../components/indicators/DeletedIndicator";
import { EntityLabel } from "../EntityLabel";
import type { TBaseEntityNodeProps } from "../types";

type TProps = TBaseEntityNodeProps & {
	withIcon?: boolean;
	tooltipOnOverflow?: boolean;
	actor: { displayName: string; email?: string; isDeleted?: boolean };
};

export const AccountEntity: FC<TProps> = ({
	actor,
	withIcon,
	size,
	className,
	tooltipOnOverflow = false,
	variant,
	...entityNodeProps
}) => {
	const classes = useStyles();
	const { t } = useTranslation("translation", { keyPrefix: "entities.IntegrationActor" });

	const tooltipContent = useMemo(() => {
		return !tooltipOnOverflow ? (
			<div className={classes.tooltipContainer}>
				<TitleBody size="small" title={t("tooltip.name")} body={actor.displayName} />
				{actor.email ? <TitleBody size="small" title={t("tooltip.email")} body={actor.email} /> : null}
			</div>
		) : null;
	}, [tooltipOnOverflow, classes.tooltipContainer, t, actor.displayName, actor.email]);

	const indicators = useMemo(() => (actor.isDeleted ? [<DeletedIndicator key="deleted" />] : []), [actor.isDeleted]);

	const content = useMemo(() => {
		const content = (
			<EntityNodeContent
				tooltipOnOverflow={!tooltipContent}
				text={actor.displayName}
				withIcon={withIcon}
				inactive={actor.isDeleted}
				size={size}
				icon={<AccountIcon />}
				variant={variant}
				{...entityNodeProps}
			/>
		);

		return tooltipContent ? <Tooltip content={tooltipContent}>{content}</Tooltip> : content;
	}, [actor.displayName, actor.isDeleted, withIcon, size, variant, entityNodeProps, tooltipContent]);

	return <EntityLabel content={content} size={size} indicators={indicators} className={className} />;
};
