import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	inheritOwnerOption: {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start",
		gap: "2px"
	}
});
