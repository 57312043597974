import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	arrow: {
		color: "var(--color-grey-disabled)"
	},
	arrowDown: {
		transform: "scaleX(-1)",
		"&:hover&:hover": {
			transform: "scaleX(-1)",
			color: "#472456"
		}
	},
	arrowUp: {
		"&:hover": {
			color: "#472456"
		}
	},
	active: {
		color: "#472456"
	},
	container: {
		display: "flex",
		justifyContent: "center",
		flexDirection: "column",
		gap: "0.2rem",
		alignItems: "center",
		padding: ".5rem",
		"&:hover": {
			backgroundColor: "var(--accordion-background-color)",
			borderRadius: "50%",
			cursor: "pointer"
		}
	}
});
