import React from "react";
import { makeIcon } from "components/ui/Icon";

export const CenterIcon = makeIcon(
	<svg viewBox="0 0 24 24" fill="none">
		<path
			d="M5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V16C3 15.7167 3.09583 15.4792 3.2875 15.2875C3.47917 15.0958 3.71667 15 4 15C4.28333 15 4.52083 15.0958 4.7125 15.2875C4.90417 15.4792 5 15.7167 5 16V19H8C8.28333 19 8.52083 19.0958 8.7125 19.2875C8.90417 19.4792 9 19.7167 9 20C9 20.2833 8.90417 20.5208 8.7125 20.7125C8.52083 20.9042 8.28333 21 8 21H5ZM19 21H16C15.7167 21 15.4792 20.9042 15.2875 20.7125C15.0958 20.5208 15 20.2833 15 20C15 19.7167 15.0958 19.4792 15.2875 19.2875C15.4792 19.0958 15.7167 19 16 19H19V16C19 15.7167 19.0958 15.4792 19.2875 15.2875C19.4792 15.0958 19.7167 15 20 15C20.2833 15 20.5208 15.0958 20.7125 15.2875C20.9042 15.4792 21 15.7167 21 16V19C21 19.55 20.8042 20.0208 20.4125 20.4125C20.0208 20.8042 19.55 21 19 21ZM3 8V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H8C8.28333 3 8.52083 3.09583 8.7125 3.2875C8.90417 3.47917 9 3.71667 9 4C9 4.28333 8.90417 4.52083 8.7125 4.7125C8.52083 4.90417 8.28333 5 8 5H5V8C5 8.28333 4.90417 8.52083 4.7125 8.7125C4.52083 8.90417 4.28333 9 4 9C3.71667 9 3.47917 8.90417 3.2875 8.7125C3.09583 8.52083 3 8.28333 3 8ZM19 8V5H16C15.7167 5 15.4792 4.90417 15.2875 4.7125C15.0958 4.52083 15 4.28333 15 4C15 3.71667 15.0958 3.47917 15.2875 3.2875C15.4792 3.09583 15.7167 3 16 3H19C19.55 3 20.0208 3.19583 20.4125 3.5875C20.8042 3.97917 21 4.45 21 5V8C21 8.28333 20.9042 8.52083 20.7125 8.7125C20.5208 8.90417 20.2833 9 20 9C19.7167 9 19.4792 8.90417 19.2875 8.7125C19.0958 8.52083 19 8.28333 19 8ZM12 15C11.1667 15 10.4583 14.7083 9.875 14.125C9.29167 13.5417 9 12.8333 9 12C9 11.1667 9.29167 10.4583 9.875 9.875C10.4583 9.29167 11.1667 9 12 9C12.8333 9 13.5417 9.29167 14.125 9.875C14.7083 10.4583 15 11.1667 15 12C15 12.8333 14.7083 13.5417 14.125 14.125C13.5417 14.7083 12.8333 15 12 15Z"
			fill="currentColor"
		/>
	</svg>
);
