import React from "react";
import { ToggleSwitch, type TToggleSwitchProps } from "components/ui/ToggleSwitch";
import { useStyles } from "./styles";
import type { IRowOptions } from "../../types";

const ToggleSwitchCellContent: FC<TToggleSwitchProps & IRowOptions> = props => {
	const classes = useStyles();
	return (
		<div className={classes.centeredCellContent}>
			<ToggleSwitch {...props} />
		</div>
	);
};

const ToggleSwitchCellContentMemo = React.memo(ToggleSwitchCellContent) as typeof ToggleSwitchCellContent;

export { ToggleSwitchCellContentMemo as ToggleSwitchCellContent };
