import { useMemo, useCallback } from "react";
import { usePopperTooltip, Config, PopperOptions } from "react-popper-tooltip";
import type { Modifier } from "@popperjs/core";

export interface IUseTooltipOptions extends Config {
	tooltipClassName?: string;
	arrowClassName?: string;
	popperOptions?: PopperOptions;
}

export const useTooltip = (options: IUseTooltipOptions = {}) => {
	const { tooltipClassName, arrowClassName, popperOptions, ...config } = options;
	const placement = useMemo(
		() => config?.placement || popperOptions?.placement || "top",
		[config?.placement, popperOptions?.placement]
	);

	const defaultModifier = getDefaultOffsetModifier(config?.offset);

	let modifiersOverwrite = popperOptions?.modifiers;
	if (!modifiersOverwrite) {
		modifiersOverwrite = [defaultModifier];
	}
	if (!modifiersOverwrite.some(modifier => modifier.name === defaultModifier.name)) {
		modifiersOverwrite.push(defaultModifier);
	}
	const {
		getArrowProps,
		getTooltipProps,
		setTooltipRef,
		setTriggerRef,
		tooltipRef,
		triggerRef,
		visible,
		...popperProps
	} = usePopperTooltip(
		{
			delayHide: 100,
			delayShow: 100,
			...config
		},
		{
			...options.popperOptions,
			placement: placement,
			modifiers: modifiersOverwrite
		}
	);
	const arrowProps = useMemo(() => getArrowProps({ className: arrowClassName }), [arrowClassName, getArrowProps]);
	const tooltipProps = useMemo(
		() => getTooltipProps({ className: tooltipClassName }),
		[getTooltipProps, tooltipClassName]
	);

	const getTooltipPropsMemoized = useCallback(() => {
		return getTooltipProps({ className: tooltipClassName });
	}, [getTooltipProps, tooltipClassName]);

	return {
		setTooltipRef, // Set the tooltip ref required to use (as ref or innerRef)
		setTriggerRef, // Set the trigger ref required to use (as ref or innerRef)
		tooltipProps, // The props for the tooltip, required to use the object or the function
		getTooltipProps: getTooltipPropsMemoized, // The props for the tooltip, required to use the object or the function
		visible, // The tooltip visibility, required to use for hover tooltip
		arrowProps, // If you want to add a little arrow for the tooltip, optional
		tooltipRef, // The tooltip ref, optional, mostly for debug purposes
		triggerRef, // The trigger ref, optional, mostly for debug purposes
		popperProps // The popper props, optional, for advanced usage
	};
};

export const SAME_WIDTH_MODIFIER: Modifier<"sameWidth", Record<string, unknown>> = {
	name: "sameWidth",
	enabled: true,
	phase: "beforeWrite",
	requires: ["computeStyles"],
	fn: ({ state }) => {
		state.styles.popper.width = `${state.rects.reference.width}px`;
	},
	effect: ({ state }) => {
		state.elements.popper.style.width = `${state.elements.reference.getBoundingClientRect().width}px`;
	}
};

const getDefaultOffsetModifier = (offset?: [number, number]) => {
	return offset && offset.length
		? {
				...DEFAULT_OFFSET_MODIFIER,
				options: {
					offset
				}
			}
		: DEFAULT_OFFSET_MODIFIER;
};

const DEFAULT_OFFSET_MODIFIER: Partial<Modifier<"offset", { offset: [number, number] }>> = {
	name: "offset",
	options: {
		offset: [0, 8]
	}
};
