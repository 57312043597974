import classNames from "classnames";
import React from "react";
import { useStyles } from "./styles";

interface IFormType {
	Field: typeof FormField;
	Actions: typeof FormActions;
}

export const Form: FC & IFormType = ({ children, className }) => {
	const classes = useStyles();

	return <div className={classNames(classes.form, className)}>{children}</div>;
};

const FormField: FC = ({ children, className }) => {
	const classes = useStyles();

	return <div className={classNames(classes.field, className)}>{children}</div>;
};

const FormActions: FC = ({ children, className }) => {
	const classes = useStyles();

	return <div className={classNames(classes.actions, className)}>{children}</div>;
};

Form.Field = FormField;
Form.Actions = FormActions;
