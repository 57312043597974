import classNames from "classnames";
import { List } from "immutable";
import React, { useCallback, useMemo } from "react";
import { Title } from "components/ui/Title";
import { DURATION_OPTIONS, TTicketDuration } from "utils/durationsOptions";
import { AllowedDuration } from "./components/AllowedDuration";
import { useStyles } from "./styles";

interface IProps {
	disabled?: boolean;
	onToggle: (duration: TTicketDuration) => Promise<void> | void;
	selectedDurations: List<TTicketDuration>;
	title?: string;
}

export const AllowedDurations: FC<IProps> = ({ onToggle, selectedDurations, disabled, title }) => {
	const classes = useStyles();

	const isChecked = useCallback(
		(duration: TTicketDuration) => selectedDurations.includes(duration),
		[selectedDurations]
	);

	const isDisabled = useCallback(
		(duration: TTicketDuration) => disabled || (isChecked(duration) && selectedDurations.count() === 1),
		[disabled, isChecked, selectedDurations]
	);

	const allowedDurations = useMemo(
		() =>
			DURATION_OPTIONS.sort((a, b) => a - b).map(duration => (
				<AllowedDuration
					key={duration}
					duration={duration}
					onToggle={onToggle}
					isChecked={isChecked}
					isDisabled={isDisabled}
				/>
			)),
		[onToggle, isChecked, isDisabled]
	);

	return (
		<div className={classNames({ [classes.disabled]: disabled })}>
			{title && (
				<Title variant="h3" noBorder extraMargin>
					{title}
				</Title>
			)}
			<div className={classes.durations}>{allowedDurations}</div>
		</div>
	);
};
