import classNames from "classnames";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { AccessReviewPermissionCommentHeader } from "components/common/AccessReviewPermissionCommentHeader";
import { RevokeModal } from "components/common/RevokeModal";
import { Button } from "components/ui/Button";
import { Checkbox } from "components/ui/Checkbox";
import { CloseIcon } from "components/ui/Icons/CloseIcon";
import { FlagIcon } from "components/ui/Icons/FlagIcon";
import { GrantedIcon } from "components/ui/Icons/GrantedIcon";
import { Typography } from "components/ui/legacy/Typography";
import { useResourcePermissionsReviewContext } from "context/resourcePermissionReviewContext";
import { useIsOpenState } from "hooks/useIsOpenState";
import { useStyles } from "./styles";

export const ResourcePermissionTableHeader: FC<{ immediateRevoke: boolean }> = ({
	className,
	id,
	innerRef,
	immediateRevoke
}) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const {
		actions: { handleSelectAllCheckboxChange, setSelected, setSelectedComment },
		state: { selectedPermissions, selectedAmount, totalAmount }
	} = useResourcePermissionsReviewContext();
	const {
		isOpen: immediateRevokeModalIsOpen,
		open: openImmediateRevokeModal,
		close: closeImmediateRevokeModal
	} = useIsOpenState();

	const [activeLoadingAction, setActiveAction] = useState<"approved" | "denied" | "flagged" | undefined>(undefined);

	const onSetApproved = useCallback(async () => {
		setActiveAction("approved");
		await setSelected("approve");
		setActiveAction(undefined);
	}, [setSelected]);

	const onSetDenied = useCallback(async () => {
		setActiveAction("denied");
		await setSelected("deny");
		setActiveAction(undefined);
		if (immediateRevokeModalIsOpen) {
			closeImmediateRevokeModal();
		}
	}, [immediateRevokeModalIsOpen, closeImmediateRevokeModal, setSelected]);

	const onSetFlagged = useCallback(async () => {
		setActiveAction("flagged");
		await setSelected("flag");
		setActiveAction(undefined);
	}, [setSelected]);

	const disableActions = selectedAmount === 0 || !!activeLoadingAction;

	const onSelectAllClick = useCallback(() => {
		if (selectedAmount === 0) {
			handleSelectAllCheckboxChange(true);
		} else {
			handleSelectAllCheckboxChange(false);
		}
	}, [handleSelectAllCheckboxChange, selectedAmount]);

	return (
		<>
			{immediateRevoke && selectedPermissions && (
				<RevokeModal
					isOpen={immediateRevokeModalIsOpen}
					onClose={closeImmediateRevokeModal}
					onAction={onSetDenied}
					permissions={selectedPermissions}
				/>
			)}
			<div className={classNames(classes.header, className)} id={id} ref={innerRef}>
				<Typography variant="h3" className={classes.amountContainer}>
					{t(`common.resourcePermissionsReviewTable.${totalAmount ? "selected" : "nothingToReview"}`, {
						amount: selectedAmount,
						total: totalAmount
					})}
				</Typography>
				{totalAmount > 0 && (
					<Checkbox
						className={classes.checkbox}
						onClick={onSelectAllClick}
						partialSelected={selectedAmount > 0 && selectedAmount < totalAmount}
						selected={selectedAmount === totalAmount}
						label={t("checkbox.selectAll")}
						tooltipText={selectedAmount === 0 ? t("checkbox.selectAll") : t("checkbox.deselectAll")}
					/>
				)}
				<div className={classes.actions}>
					<AccessReviewPermissionCommentHeader onSubmit={setSelectedComment} />
					<Button
						variant="secondary"
						size="medium"
						className={classNames(classes.buttonApprove, { [classes.disabled]: disableActions })}
						prefix={<GrantedIcon />}
						onClick={onSetApproved}
						loading={activeLoadingAction === "approved"}
						disabled={disableActions}>
						{t("common.resourcePermissionsReviewTable.approve")}
					</Button>
					<Button
						variant="secondary"
						size="medium"
						className={classNames(classes.buttonDeny, { [classes.disabled]: disableActions })}
						prefix={<CloseIcon />}
						onClick={immediateRevoke ? openImmediateRevokeModal : onSetDenied}
						loading={activeLoadingAction === "denied"}
						disabled={disableActions}>
						{t("common.resourcePermissionsReviewTable.deny")}
					</Button>
					<Button
						variant="secondary"
						size="medium"
						className={classNames(classes.buttonFlag, { [classes.disabled]: disableActions })}
						prefix={<FlagIcon />}
						onClick={onSetFlagged}
						loading={activeLoadingAction === "flagged"}
						disabled={disableActions}>
						{t("common.resourcePermissionsReviewTable.flag")}
					</Button>
				</div>
			</div>
		</>
	);
};
