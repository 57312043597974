import classNames from "classnames";
import uniqueId from "lodash/uniqueId";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Input } from "components/ui/Input";
import { Typography } from "components/ui/legacy/Typography";
import { SelectItem } from "components/ui/Select/components/SelectItem";
import { useOnClickOutside } from "hooks/useOnClickOutside";
import { SAME_WIDTH_MODIFIER, useTooltip } from "hooks/useTooltip";
import { getLabel, getOptionKey as utilsGetOptionKey, TRenderOption } from "utils/ui/select";
import { DROPDOWN_DEFAULT_MAX_HEIGHT, useStyles } from "./styles";

export type TSelectVariant = "regular" | "table" | "search";
export type TTargetValue = { target: { value: string }; [key: string]: unknown };
export type TInputChangeEvent = React.ChangeEvent<HTMLInputElement> | TTargetValue;
export type OptionsListActionPanelProps = {
	position: "top" | "bottom";
	content: React.JSX.Element;
};
export interface IRoleBarSelectProps<T> {
	getOptionKey?: (option: T) => string; // default: undefined, try get label. how to get key by option.
	getOptionLabel?: ((option: T) => string) | null; // default: option.label || String(option) || "". how to get label by option. Null will return empty function that returns ""
	isOptionEqualToValue?: (option: T, value: T) => boolean; // default: ===, equality comparator for options.
	loading?: boolean; // default: false. show loading indicator.
	maxDropdownHeight?: number; // default 288(px). dropdown max height.
	noOptionsText?: string;
	onChange?: (value: T | null) => void; // default: undefined. callback on value change.
	onInputChange?: (event: TInputChangeEvent) => void; // default: undefined. callback on input value change.
	optionSelectItemClassName?: string; // default: undefined. class name for option select item.
	options: T[]; // required. options to display.
	placeholder?: string; // default: "". placeholder for the select.
	prefix?: React.ReactNode; // default: null. icon to display before input.
	renderOption?: TRenderOption<T>; // default: undefined. how to render option.
}

const LIMIT = 30;

function RoleBarSelect<T>(props: TProps<IRoleBarSelectProps<T>>) {
	const {
		className,
		getOptionKey = undefined,
		getOptionLabel: propGetOptionLabel,
		id: propId,
		isOptionEqualToValue = (option, currentValue) => option === currentValue,
		maxDropdownHeight = DROPDOWN_DEFAULT_MAX_HEIGHT,
		noOptionsText,
		onChange: propOnChange,
		onInputChange: propOnInputChange,
		options: propOptions,
		optionSelectItemClassName = "",
		placeholder,
		prefix: propPrefix = null,
		renderOption: propRenderOption
	} = props;

	const [id] = useState(() => propId || uniqueId());
	const selectId = `select-${id}`;
	const inputId = `input-${id}`;
	const ref = useRef<HTMLDivElement>(null);
	const [value, setValue] = useState<T | null>(null);
	const [inputValue, setInputValue] = useState("");
	const [open, setOpen] = useState(false);
	const [highlightedIndex, setHighlightedIndex] = useState<number>(value ? -1 : 0);
	const inputRef = useRef<HTMLInputElement>(null);

	const classes = useStyles({
		maxHeight: maxDropdownHeight
	});

	const { visible, setTooltipRef, getTooltipProps, setTriggerRef } = useTooltip({
		visible: open,
		offset: [0, 6],
		placement: "bottom",
		popperOptions: {
			modifiers: [SAME_WIDTH_MODIFIER]
		}
	});

	const handleOpen = useCallback(() => setOpen(true), []);
	const handleClose = useCallback(() => {
		setOpen(false);
		setHighlightedIndex(value ? -1 : 0);
		inputRef.current?.blur();
	}, [value]);

	useOnClickOutside(ref, handleClose);

	const resolvedPropGetOptionLabel = useMemo(() => {
		if (propGetOptionLabel === null) return () => "";
		return propGetOptionLabel || getLabel;
	}, [propGetOptionLabel]);

	const getOptionLabel = useCallback(
		(option?: T | null) => {
			if (!option) return "";
			const optionLabel = resolvedPropGetOptionLabel(option);
			return typeof optionLabel === "string" ? optionLabel : "";
		},
		[resolvedPropGetOptionLabel]
	);

	const renderOption = useCallback(
		(option: T, index: number) => {
			if (propRenderOption) {
				return propRenderOption(option, index);
			}
			return <Typography>{getOptionLabel(option)}</Typography>;
		},
		[getOptionLabel, propRenderOption]
	);
	const resetInputValue = useCallback(
		(event?: React.SyntheticEvent | null, newValue?: T | null) => {
			const newInputValue = getOptionLabel(newValue);

			if (newInputValue === inputValue) {
				return;
			}
			setInputValue(newInputValue);

			if (propOnInputChange) {
				propOnInputChange(
					event ? { ...event, target: { ...event.target, value: newInputValue } } : { target: { value: newInputValue } }
				);
			}
		},
		[getOptionLabel, inputValue, propOnInputChange, setInputValue]
	);

	const handleValue = useCallback(
		(newValue: T | null) => {
			if (newValue === value) return;
			if (propOnChange) {
				propOnChange(newValue);
			}

			setValue(newValue);
			resetInputValue(null, newValue);
		},
		[value, propOnChange, resetInputValue]
	);

	const selectNewValue = useCallback(
		(event: React.SyntheticEvent, newValue: T) => {
			resetInputValue(event, newValue);
			handleValue(newValue);
			handleClose();
		},
		[handleClose, handleValue, resetInputValue]
	);

	const handleInputChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const newInputValue = event.target.value;
			if (newInputValue !== inputValue) {
				setInputValue(newInputValue);
				if (propOnInputChange) {
					propOnInputChange(event);
				}
			}

			if (newInputValue) {
				handleOpen();
			}
		},
		[handleOpen, inputValue, propOnInputChange, setInputValue]
	);

	const handleMouseDown = useCallback(
		(event: React.MouseEvent<HTMLDivElement>) => {
			const targetId = (event.target as HTMLDivElement).getAttribute("id");
			if (targetId !== selectId && targetId !== inputId) {
				event.preventDefault();
			}
		},
		[selectId, inputId]
	);

	const onClick = useCallback(() => {
		inputRef.current?.focus();
	}, []);

	const onKeyDown = useCallback(
		(event: React.KeyboardEvent<HTMLInputElement>) => {
			if (event.key === "ArrowUp") {
				setHighlightedIndex(Math.max(highlightedIndex - 1, 0));
				event.preventDefault();
			} else if (event.key === "ArrowDown") {
				setHighlightedIndex(Math.min(highlightedIndex + 1, LIMIT - 1, propOptions.length - 1));
				event.preventDefault();
			} else if (event.key === "Enter") {
				if (highlightedIndex === -1) {
					return;
				}
				const newValue = propOptions.at(highlightedIndex);
				if (newValue) selectNewValue(event, newValue);
			}
		},
		[highlightedIndex, propOptions, selectNewValue]
	);

	const prefix = useMemo(() => {
		if (!propPrefix) return null;
		if (!propPrefix && open) return null;

		return <Typography>{propPrefix}</Typography>;
	}, [propPrefix, open]);

	const showInput = useMemo(() => {
		if (open) return true;
		if (!value) return true;
		return false;
	}, [open, value]);

	const SelectList = useMemo(() => {
		if (!visible) return null;
		return (
			<div ref={setTooltipRef} {...getTooltipProps()} className={classes.selectItemsContainer}>
				<div className={classNames(classes.selectOptionsContainer, classes.maxHeight)}>
					<SelectItemList
						options={propOptions}
						optionClassName={optionSelectItemClassName}
						getOptionKey={getOptionKey}
						getOptionLabel={resolvedPropGetOptionLabel}
						hasMore={propOptions.length > LIMIT}
						highlightedIndex={highlightedIndex}
						isOptionEqualToValue={isOptionEqualToValue}
						maxHeight={maxDropdownHeight}
						noOptionsText={noOptionsText}
						onHighlight={setHighlightedIndex}
						onSelect={selectNewValue}
						renderOption={renderOption}
						value={value}
					/>
				</div>
			</div>
		);
	}, [
		visible,
		setTooltipRef,
		getTooltipProps,
		classes.selectItemsContainer,
		classes.selectOptionsContainer,
		classes.maxHeight,
		propOptions,
		optionSelectItemClassName,
		getOptionKey,
		resolvedPropGetOptionLabel,
		highlightedIndex,
		isOptionEqualToValue,
		maxDropdownHeight,
		noOptionsText,
		selectNewValue,
		renderOption,
		value
	]);

	return (
		<div
			className={classNames(classes.container, className)}
			id={selectId}
			onMouseDown={handleMouseDown}
			onClick={onClick}
			ref={ref}>
			<Input
				className={className}
				id={inputId}
				innerRef={setTriggerRef}
				inputRef={inputRef}
				onChange={handleInputChange}
				onKeyDown={onKeyDown}
				placeholder={placeholder}
				prefix={prefix}
				showInput={showInput}
				value={inputValue}
				variant={"search"}
			/>
			{SelectList}
		</div>
	);
}

interface ISelectItemListProps<T> {
	options: T[];
	getOptionKey?: (option: T) => string;
	getOptionLabel?: (option: T) => string;
	groupBy?: (option: T) => string;
	hasMore: boolean;
	highlightedIndex?: number | null;
	isOptionEqualToValue?: (option: T, value: T) => boolean;
	maxHeight: number;
	noOptionsText?: string;
	onHighlight?: (index: number) => void;
	onSelect: (event: React.SyntheticEvent, value: T) => void;
	optionClassName?: string;
	renderOption: TRenderOption<T>;
	value?: T | null;
	checkSelectedOnEmptyValue?: boolean;
}

function SelectItemList<T>(props: TProps<ISelectItemListProps<T>>) {
	const {
		options,
		getOptionKey: propGetOptionKey,
		getOptionLabel,
		hasMore,
		highlightedIndex,
		isOptionEqualToValue,
		maxHeight,
		noOptionsText = null,
		onHighlight,
		onSelect,
		optionClassName,
		renderOption,
		value,
		checkSelectedOnEmptyValue = false
	} = props;
	const classes = useStyles({ maxHeight });
	const getClassName = useCallback(
		(option: T) => {
			const isSelected =
				option && (value || checkSelectedOnEmptyValue) && isOptionEqualToValue && isOptionEqualToValue(option, value!);
			return classNames(optionClassName, { selected: isSelected });
		},
		[isOptionEqualToValue, optionClassName, value, checkSelectedOnEmptyValue]
	);
	const { t } = useTranslation();
	const noOptions = noOptionsText ? noOptionsText : t("common.select.noOptionsFound");

	const searchForMore = useMemo(
		() =>
			hasMore ? (
				<Typography className={classes.searchForMore} variant="small">
					{t("common.select.searchForMore")}
				</Typography>
			) : null,
		[classes.searchForMore, hasMore, t]
	);

	const getOptionKey = useCallback(
		(option: T) => {
			const key = propGetOptionKey ? propGetOptionKey(option) : utilsGetOptionKey(option, getOptionLabel);
			return key;
		},
		[getOptionLabel, propGetOptionKey]
	);

	if (!options?.length) {
		return <Typography className={classes.noOptions}>{noOptions}</Typography>;
	}

	return (
		<div className={classes.maxHeight}>
			{options.map((option, index) => {
				const optionKey = getOptionKey(option);
				return (
					<SelectItem
						className={getClassName(option)}
						highlighted={highlightedIndex === index}
						index={index}
						key={optionKey}
						onHover={onHighlight}
						onSelect={onSelect}
						renderOption={renderOption}
						value={option}
					/>
				);
			})}
			{searchForMore}
		</div>
	);
}

const MemoizedComponent = React.memo(RoleBarSelect) as typeof RoleBarSelect;

export { MemoizedComponent as RoleBarSelect };
