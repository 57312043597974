import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	cell: {
		alignItems: "center",
		display: "flex",
		minHeight: "3rem"
	},
	entitlements: {
		verticalAlign: "middle"
	},
	link: {
		color: "var(--typography-link-font-color)"
	},
	noUser: {
		color: "var(--color-grey)"
	},
	subRowCell: {
		borderTop: "none"
	},
	ticketsCell: {
		display: "flex",
		gap: "0.5rem",
		flexWrap: "wrap"
	},
	sourceCell: {
		gap: "0.5rem"
	}
});
