import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	page: {
		position: "absolute",
		top: "0",
		left: "0",
		height: "100%",
		width: "100%"
	}
});
