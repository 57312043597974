import classNames from "classnames";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "components/ui/Button";
import { RenewIcon } from "components/ui/Icons/RenewIcon";
import { useLoadingState } from "hooks/useLoadingState";
import { TicketModel } from "models/TicketModel";
import { useStyles } from "./styles";

type TProps = {
	request: TicketModel;
	retry: (request: TicketModel) => Promise<void>;
};

export const RequestRetryActions: FC<TProps> = ({ className, innerRef, retry, request }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const { withLoader, isLoading } = useLoadingState();
	const handleCancel = useCallback(async () => withLoader(retry(request)), [request, retry, withLoader]);

	return (
		<div className={classNames(classes.container, className)} ref={innerRef}>
			<Button size="medium" variant="secondary" suffix={<RenewIcon />} onClick={handleCancel} loading={isLoading}>
				{t("buttons.retry")}
			</Button>
		</div>
	);
};
