import constate from "constate";
import { Map } from "immutable";
import { useCallback, useState } from "react";
import { getAccessReviewSubordinate as apiGetAccessReviewSubordinate } from "api/accessReviewSubordinates";
import { useLoadingState } from "hooks/useLoadingState";
import { AccessReviewSubordinateModel } from "models/AccessReviewSubordinateModel";

const useAccessReviewSubordinates = () => {
	const [accessReviewSubordinates, setAccessReviewSubordinates] = useState(Map<string, AccessReviewSubordinateModel>());
	const { isLoading: isGetLoading, withLoader: withGetLoader } = useLoadingState();

	const getAccessReviewSubordinate = useCallback(
		async (id: string): Promise<AccessReviewSubordinateModel | null> => {
			if (!id) return null;

			const accessReviewSubordinate = await withGetLoader(apiGetAccessReviewSubordinate(id));
			setAccessReviewSubordinates(current => current.set(id, accessReviewSubordinate));
			return accessReviewSubordinate;
		},
		[withGetLoader]
	);

	const updateSubordinate = useCallback((id: string, subordinate: AccessReviewSubordinateModel) => {
		setAccessReviewSubordinates(current => current.set(id, subordinate));
	}, []);

	return {
		state: { accessReviewSubordinates, isGetLoading },
		actions: {
			getAccessReviewSubordinate,
			updateSubordinate
		}
	};
};

export const [AccessReviewSubordinatesProvider, useAccessReviewSubordinatesContext] =
	constate(useAccessReviewSubordinates);
