import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	inputsContainer: {
		display: "flex",
		alignItems: "flex-start",
		justifyContent: "space-between",
		width: "100%",
		gap: "var(--spacing-x3, 12px)"
	},
	timerAndInput: {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start",
		justifyContent: "flex-start",
		gap: "var(--spacing-x3, 12px)"
	},
	filterContainer: {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start",
		justifyContent: "flex-start",
		gap: "var(--spacing-x4, 16px)",
		borderRadius: "12px",
		background: "var(--color-purple-50, #FBF9FB)",
		padding: "var(--spacing-x6, 24px) var(--spacing-x5, 20px)"
	},
	inputs: {
		width: "100%"
	}
});
