import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	loadingOverlay: {
		transition: "opacity 0.3s 1s var(--transition-easing)",
		opacity: "1",
		pointerEvents: "all",
		backdropFilter: "blur(6px)",
		alignItems: "center",
		justifyContent: "center",
		userSelect: "none",
		overscrollBehavior: "contain",
		overflow: "hidden",

		"&.hide": {
			opacity: "0",
			pointerEvents: "none",
			transition: "opacity 0.2s var(--transition-easing)"
		},
		"& > div": {
			flex: "initial"
		}
	}
});
