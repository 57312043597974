import { Record } from "immutable";
import { IntegrationResourceRoleModel } from "./IntegrationResourceRoleModel";

const defaults = {
	id: "",
	integrationResourceRoleId: "",
	prerequisitePermissionClauseId: "",
	defaultOption: false,
	integrationResourceRole: new IntegrationResourceRoleModel()
};

export class PrerequisitePermissionRoleModel extends Record<typeof defaults>(defaults) {
	static fromServerData(data: unknown) {
		const { id, integrationResourceRoleId, integrationResourceRole, prerequisitePermissionClauseId, defaultOption } =
			data as typeof defaults;

		return new PrerequisitePermissionRoleModel({
			id,
			integrationResourceRole,
			integrationResourceRoleId,
			defaultOption,
			prerequisitePermissionClauseId
		});
	}
}
