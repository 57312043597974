import classNames from "classnames";
import React, { ChangeEventHandler, useCallback } from "react";
import { useStyles } from "./style";

type TToggleSwitchProps = {
	checked: boolean;
	onChange: (checked: boolean) => void;
	size?: "small" | "regular";
	disabled?: boolean;
};

const ToggleSwitch: FC<TToggleSwitchProps> = ({ checked, onChange, size = "regular", disabled = false }) => {
	const classes = useStyles();

	const onInputChange: ChangeEventHandler<HTMLInputElement> = useCallback(
		e => {
			if (disabled) return;
			onChange(e.target.checked);
		},
		[disabled, onChange]
	);

	return (
		<label className={classNames(classes.switch, { [classes.small]: size === "small", [classes.disabled]: disabled })}>
			<input type="checkbox" checked={checked} onChange={onInputChange} />
			<span className={classNames(classes.slider, { [classes.small]: size === "small" })} />
		</label>
	);
};

const ToggleSwitchMemo = React.memo(ToggleSwitch);

export { ToggleSwitchMemo as ToggleSwitch, type TToggleSwitchProps };
