import { Set } from "immutable";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IntegrationEntity } from "components/common/entities";
import { WorkflowEntity } from "components/common/entities/WorkflowEntity";
import { IntegrationIcon } from "components/ui/Icons/IntegrationIcon";
import { MaintainersIcon } from "components/ui/Icons/MaintainersIcon";
import { OwnerIcon } from "components/ui/Icons/OwnerIcon";
import { RequestsIcon } from "components/ui/Icons/RequestsIcon";
import { WorkflowsIcon } from "components/ui/Icons/WorkflowsIcon";
import { UserAvatarOption } from "components/ui/selectOptions/UserAvatarOption";
import { WorkflowOption } from "components/ui/selectOptions/WorkflowOption";
import { PaginatedVirtualTable, type TTableSelectionProps, type TColumn } from "components/ui/VirtualTable";
import {
	DropdownCellContent,
	HeaderCellContent,
	ToggleSwitchCellContent,
	UserSelectCellContent
} from "components/ui/VirtualTable/components";
import { useIntegrationsContext } from "context/integrationsContext";
import { useApprovalAlgorithmsList } from "hooks/useApprovalAlgorithms";
import { ApprovalAlgorithmModel } from "models/ApprovalAlgorithmModel";
import { UserModel } from "models/UserModel";
import { MaintainersCell } from "../MaintainersCell";
import type { IIntegrationSharedData } from "api/integrations";
import type { TGetProps } from "components/ui/VirtualTable/types";
import type { IntegrationModel, TIntegrationModel } from "models/IntegrationModel";

type TPartialIntegration = Partial<TIntegrationModel> & {
	id: string;
};

type TProps = {
	disabled?: boolean;
	fetchIntegrations: (page: number) => void | Promise<void>;
	integrations: IntegrationModel[];
	onIntegrationUpdate: (item: TPartialIntegration) => void;
	perPage?: number;
	totalIntegrations: number;
} & TTableSelectionProps<IntegrationModel>;

const COLUMNS_WIDTHS = ["minmax(196px, 1fr)", "minmax(168px, 1fr)", "minmax(200px, 1fr)", "168px", "180px"] as const;

const DEFAULT_PER_PAGE = 10;

const useIntegrationsTable = (disabled = false, onIntegrationUpdate: TProps["onIntegrationUpdate"]) => {
	const approvalAlgorithmList = useApprovalAlgorithmsList();

	const approvalAlgorithmOptions = useMemo(() => approvalAlgorithmList?.toArray() || [], [approvalAlgorithmList]);

	const [loadingIntegrations, setLoadingIntegrations] = useState(Set<string>());

	const {
		actions: { updateIntegration }
	} = useIntegrationsContext();

	const onUpdateIntegration = useCallback(
		async (data: Partial<IIntegrationSharedData> & { id: string }) => {
			setLoadingIntegrations(current => current.add(data.id));
			await updateIntegration(data);
			setLoadingIntegrations(current => current.remove(data.id));
			const update = { id: data.id } as TPartialIntegration;
			if ("defaultApprovalAlgorithmId" in data) {
				update.defaultApprovalAlgorithm = approvalAlgorithmList?.find(
					({ id }) => id === data.defaultApprovalAlgorithmId
				);
			}
			if ("allowsRequests" in data) {
				update.allowsRequests = data.allowsRequests;
			}
			if ("ownerUserId" in data) {
				update.ownerId = data.ownerUserId;
			}
			onIntegrationUpdate(update);
		},
		[approvalAlgorithmList, onIntegrationUpdate, updateIntegration]
	);

	const onUpdateAllowsRequests = useCallback(
		async (integrationId: string, allowsRequests: boolean) => {
			await onUpdateIntegration({ id: integrationId, allowsRequests });
		},
		[onUpdateIntegration]
	);

	const onUpdateOwner = useCallback(
		async (integrationId: string, newOwner: UserModel) => {
			await onUpdateIntegration({ id: integrationId, ownerUserId: newOwner.id });
		},
		[onUpdateIntegration]
	);

	const onUpdateApprovalAlgorithm = useCallback(
		async (integrationId: string, newApprovalAlgorithm: ApprovalAlgorithmModel) => {
			await onUpdateIntegration({ id: integrationId, defaultApprovalAlgorithmId: newApprovalAlgorithm.id });
		},
		[onUpdateIntegration]
	);

	const getIsDisabled = useCallback(
		(entity: string | IntegrationModel) =>
			disabled || loadingIntegrations.has(typeof entity === "string" ? entity : entity.id),
		[disabled, loadingIntegrations]
	);

	return {
		approvalAlgorithmOptions,
		getIsDisabled,
		onUpdateAllowsRequests,
		onUpdateApprovalAlgorithm,
		onUpdateOwner
	};
};

const getApprovalAlgorithmOptionLabel = (option: ApprovalAlgorithmModel) => option.name;
const renderApprovalAlgorithmOptionLabel = (option: ApprovalAlgorithmModel) => (
	<WorkflowEntity size="medium" approvalAlgorithm={option} />
);

export const IntegrationsTable: FC<TProps> = ({
	className,
	isLoading,
	disabled = false,
	fetchIntegrations,
	innerRef,
	integrations,
	onIntegrationUpdate,
	perPage = DEFAULT_PER_PAGE,
	totalIntegrations,
	...selectionProps
}) => {
	const { t } = useTranslation("translation", { keyPrefix: "common.tables.integrations" });

	const { approvalAlgorithmOptions, getIsDisabled, onUpdateAllowsRequests, onUpdateApprovalAlgorithm, onUpdateOwner } =
		useIntegrationsTable(disabled, onIntegrationUpdate);

	const selectedItemsMap = useMemo(
		() => new Map(selectionProps.selectedItems?.map(item => [item.id, item]) || []),
		[selectionProps.selectedItems]
	);

	const columns = useMemo<TColumn<IntegrationModel>[]>(() => {
		const columns: TColumn<IntegrationModel>[] = [
			{
				renderCell: integration => <IntegrationEntity withIcon noWrap integration={integration} size="medium" />,
				header: <HeaderCellContent text={t("headers.integration")} icon={<IntegrationIcon />} />,
				key: "integration",
				width: COLUMNS_WIDTHS[0]
			},
			{
				renderCell: (integration, options) => {
					const onChange = (newApprovalAlgorithm: ApprovalAlgorithmModel | null) => {
						if (newApprovalAlgorithm) {
							void onUpdateApprovalAlgorithm(integration.id, newApprovalAlgorithm);
						}
					};
					return (
						<DropdownCellContent
							options={approvalAlgorithmOptions}
							value={integration.defaultApprovalAlgorithm}
							disabled={options.disabled || getIsDisabled(integration.id)}
							onChange={onChange}
							renderLabel={renderApprovalAlgorithmOptionLabel}
							getOptionLabel={getApprovalAlgorithmOptionLabel}
							hideClear
							sort={null}
							renderOption={WorkflowOption}
						/>
					);
				},
				header: <HeaderCellContent text={t("headers.approvalAlgorithm")} icon={<WorkflowsIcon />} />,
				key: "workflow",
				width: COLUMNS_WIDTHS[1],
				overflow: true
			},
			{
				renderCell: UserSelectCellContent,
				getProps: integration => ({
					selectedIds: [integration.ownerId],
					disabled: getIsDisabled(integration.id),
					onChange: (newOwner: UserModel) => {
						void onUpdateOwner(integration.id, newOwner);
					},
					hideClear: true,
					sort: null,
					renderOption: UserAvatarOption
				}),
				header: <HeaderCellContent text={t("headers.owner")} icon={<OwnerIcon />} />,
				key: "owner",
				width: COLUMNS_WIDTHS[2],
				overflow: true
			} as TColumn<IntegrationModel>,
			{
				renderCell: (integration: IntegrationModel) => (
					<MaintainersCell
						isSelected={selectedItemsMap.has(integration.id) || selectionProps.isAllSelected}
						model={integration}
						getIsDisabled={getIsDisabled}
						tooltipHeader={t("tooltips.integrationMaintainers")}
					/>
				),
				header: <HeaderCellContent text={t("headers.maintainer")} icon={<MaintainersIcon />} />,
				key: "maintainer",
				width: COLUMNS_WIDTHS[3]
			},
			{
				renderCell: ToggleSwitchCellContent,
				getProps: (integration => {
					const onChange = (checked: boolean) => {
						void onUpdateAllowsRequests(integration.id, checked);
					};
					return {
						disabled: getIsDisabled(integration.id),
						checked: integration.allowsRequests,
						onChange
					};
				}) as TGetProps<IntegrationModel, typeof ToggleSwitchCellContent>,
				header: <HeaderCellContent text={t("headers.requestable")} icon={<RequestsIcon />} />,
				key: "requestable",
				width: COLUMNS_WIDTHS[4]
			}
		];
		return columns;
	}, [
		approvalAlgorithmOptions,
		getIsDisabled,
		onUpdateApprovalAlgorithm,
		onUpdateAllowsRequests,
		onUpdateOwner,
		selectedItemsMap,
		selectionProps.isAllSelected,
		t
	]);

	return (
		<PaginatedVirtualTable
			isLoading={isLoading}
			className={className}
			innerRef={innerRef}
			columns={columns}
			rows={integrations}
			totalRows={totalIntegrations || integrations.length}
			perPage={perPage}
			fetchPage={fetchIntegrations}
			shouldDisableRow={getIsDisabled}
			{...selectionProps}
		/>
	);
};
