import classNames from "classnames";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { AreYouSureModal } from "components/common/AreYouSureModal";
import { Button } from "components/ui/Button";
import { IconButton } from "components/ui/IconButton";
import { DeleteIcon } from "components/ui/Icons/DeleteIcon";
import { GrantedIcon } from "components/ui/Icons/GrantedIcon";
import { InProgressIcon } from "components/ui/Icons/InProgressIcon";
import { Typography } from "components/ui/legacy/Typography";
import { LoadingDots } from "components/ui/LoadingDots";
import { Table } from "components/ui/Table";
import { TextAreaInput } from "components/ui/TextAreaInput";
import { useAccessReviewsContext } from "context/accessReviewsContext";
import { useAccessReviews } from "hooks/useAccessReviews";
import { useFullAccessReview } from "hooks/useFullAccessReview";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { useIsOpenState } from "hooks/useIsOpenState";
import { useUser } from "hooks/useUser";
import { AccessReviewModel } from "models/AccessReviewModel";
import { IsNullError } from "utils/errors/isNullError";
import { useStyles } from "./styles";
import { User } from "../AccessReview/components/User";

const COLLAPSED_LIMIT = 5;

interface IProps {
	review: AccessReviewModel;
	setReviewIdToDelete: (id: string) => void;
	view: (id: string) => void;
	openAreYouSureModal: () => void;
	reviewIdToDelete: string | null;
}

const AccessReviewTable: FC<IProps> = ({
	review,
	openAreYouSureModal,
	setReviewIdToDelete,
	view,
	reviewIdToDelete
}) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const accessReviewCreator = useUser(review.creatorId);

	const onDelete = useCallback(
		(event: React.MouseEvent) => {
			event.stopPropagation();
			setReviewIdToDelete(review.id);
			openAreYouSureModal();
		},
		[openAreYouSureModal, setReviewIdToDelete, review.id]
	);

	const onClick = useCallback(() => view(review.id), [review.id, view]);

	if (!review) {
		return null;
	}

	return (
		<Table.Row key={review.id} onClick={onClick}>
			<Table.Cell>
				<Typography>
					{t("pages.accessReview.name", {
						name: review.name,
						date: review.createdAt
					})}
				</Typography>
			</Table.Cell>
			<Table.Cell>
				<TextAreaInput
					autoResize
					readonly
					placeholder={t("common.accessReview.noDescription")}
					textAreaClassName={classes.description}
					value={review.description}
				/>
			</Table.Cell>
			<Table.Cell>{accessReviewCreator && <User user={accessReviewCreator} />}</Table.Cell>
			<Table.Cell>
				<Typography>{review.reportsSize}</Typography>
			</Table.Cell>
			<Table.Cell>
				<Typography>{review.permissionsSize}</Typography>
			</Table.Cell>
			<Table.Cell>
				<Typography className={classes.status}>
					{review.status === "done" ? <GrantedIcon /> : <InProgressIcon />}
					{t(`pages.accessReview.status.${review.status === "done" ? "done" : "inProgress"}`)}
				</Typography>
			</Table.Cell>
			<Table.Cell className={classes.actions}>
				<IconButton
					size="small"
					tooltip={t("buttons.delete")}
					onClick={onDelete}
					loading={reviewIdToDelete === review.id}
					disabled={Boolean(reviewIdToDelete)}
					className={classes.deleteButton}>
					<DeleteIcon />
				</IconButton>
			</Table.Cell>
		</Table.Row>
	);
};

export const PreviousAccessReviews: FC<{ view: (accessReviewId: string) => void }> = ({
	className,
	id,
	innerRef,
	view
}) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { sortedAccessReviews } = useAccessReviews();
	const {
		state: { latestAccessReviewId },
		actions: { deleteAccessReview }
	} = useAccessReviewsContext();
	const { accessReview: latestAccessReview } = useFullAccessReview(latestAccessReviewId, true);

	const [viewAll, setViewAll] = useState(false);
	const toggleViewAll = useCallback(() => setViewAll(current => !current), []);

	const areYouSureModal = useIsOpenState();
	const openGlobalErrorModal = useOpenGlobalErrorModal();

	const [reviewIdToDelete, setReviewIdToDelete] = useState<string | null>(null);
	const onDeleteReview = useCallback(async () => {
		if (!reviewIdToDelete) {
			openGlobalErrorModal(
				IsNullError.from({
					location: "onDeleteReview",
					requestedProperty: "reviewIdToDelete"
				})
			);
		} else {
			try {
				await deleteAccessReview(reviewIdToDelete);
			} catch (error) {
				openGlobalErrorModal(error as Error);
			} finally {
				setReviewIdToDelete(null);
				areYouSureModal.close();
			}
		}
	}, [areYouSureModal, deleteAccessReview, openGlobalErrorModal, reviewIdToDelete]);

	const onCloseModal = useCallback(() => {
		setReviewIdToDelete(null);
		areYouSureModal.close();
	}, [areYouSureModal]);

	const accessReviewsWithoutLatest = useMemo(() => {
		if (!sortedAccessReviews || !latestAccessReview) return null;
		const reviews = sortedAccessReviews.filter(({ id: accessReviewId }) => accessReviewId !== latestAccessReview?.id);
		if (viewAll) return reviews;
		return reviews.slice(0, COLLAPSED_LIMIT);
	}, [sortedAccessReviews, latestAccessReview, viewAll]);

	const headers: string[] = [
		t("pages.accessReview.previousReviews.name"),
		t("common.accessReview.description"),
		t("pages.accessReview.previousReviews.issuedBy"),
		t("pages.accessReview.previousReviews.numberOfReporters"),
		t("pages.accessReview.numberOfEntitlements"),
		t("pages.accessReview.previousReviews.status"),
		t("shared.emptyString")
	];

	if (!accessReviewsWithoutLatest) return <LoadingDots center />;

	return (
		<div className={classNames(classes.container, className)} id={id} ref={innerRef}>
			<AreYouSureModal isOpen={areYouSureModal.isOpen} onClose={onCloseModal} onAction={onDeleteReview} />
			{accessReviewsWithoutLatest && (
				<>
					<div className={classes.containerHeader}>
						<Typography variant="h3">{t("pages.accessReview.previousReviews.title")}</Typography>
						{accessReviewsWithoutLatest.length > COLLAPSED_LIMIT && (
							<Button variant="text" size="medium" onClick={toggleViewAll}>
								{viewAll ? t("pages.accessReview.collapse") : t("pages.accessReview.viewAll")}
							</Button>
						)}
					</div>
					{accessReviewsWithoutLatest.length > 0 ? (
						<Table gridColumns="6fr 6fr 6fr 3fr 3fr 2fr 7.5rem" outline className={classes.table}>
							<Table.Row>
								{headers.map(title => (
									<Table.Header key={title}>
										<Typography>{title}</Typography>
									</Table.Header>
								))}
							</Table.Row>
							{accessReviewsWithoutLatest.map(review => (
								<AccessReviewTable
									openAreYouSureModal={areYouSureModal.open}
									review={review}
									reviewIdToDelete={reviewIdToDelete}
									setReviewIdToDelete={setReviewIdToDelete}
									view={view}
									key={review.id}
								/>
							))}
						</Table>
					) : (
						t("pages.accessReview.previousReviews.noPreviousReviews")
					)}
				</>
			)}
		</div>
	);
};
