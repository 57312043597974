import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { StaticChip } from "components/ui/chips/StaticChip";
import { Section } from "components/ui/Section";
import { Typography } from "components/ui/Typography";
import { UserModel } from "models/UserModel";
import { UserPermissionsTable } from "./components/UserPermissionsTable";
import { useEntitlementsTable } from "../UserEntitlements/useEntitlementsTable";

type TUserPermissionsSectionProps = {
	user: UserModel;
	toggleDrawer?: (isOpen: boolean) => void;
	drawerIsOpen?: boolean;
};

export const UserPermissionsSection: FC<TUserPermissionsSectionProps> = ({ className, innerRef, user }) => {
	const { t } = useTranslation();
	const { displayedEntitlements, onRevokePermission, loading } = useEntitlementsTable(user, true);

	const title = useMemo(() => {
		return (
			<>
				<Typography variant="body_med" noWrap>
					{t("pages.user.total")}
				</Typography>
				<StaticChip size="small" variant="regular">
					{t("number", { value: displayedEntitlements?.length || 0 })}
				</StaticChip>
			</>
		);
	}, [t, displayedEntitlements?.length]);

	return (
		<Section fullHeight title={title}>
			<UserPermissionsTable
				permissions={displayedEntitlements}
				revokePermission={onRevokePermission}
				isLoading={loading}
			/>
		</Section>
	);
};
