import { List } from "immutable";
import React from "react";
import { AccountVertex } from "components/pages/IdentityGraphPage/components/IdentityGraph/components/Vertices/AccountVertex";
import { AccountVertexModel } from "models/IdentityGraph/AccountVertexModel";
import { useSingularColumn } from "./columnsHooks";

type TProps = {
	refSetter: (ids: string[]) => (element: unknown) => void;
	vertices: List<AccountVertexModel>;
};

export const AccountsColumn: FC<TProps> = ({ refSetter, vertices }) => {
	const { decrease, extraVerticesIds, increase, showCollapse, showedVertices, maxVertices, finalMaxEntities } =
		useSingularColumn(vertices);

	if (!vertices || !showedVertices) return null;
	const showedAccountVertices = showedVertices as AccountVertexModel[];
	return (
		<>
			{showedAccountVertices.map(vertex => (
				<AccountVertex
					key={vertex.id}
					account={{
						id: vertex.entity.accountId,
						displayName: vertex.entity.accountName
					}}
					refSetter={refSetter}
				/>
			))}
			{extraVerticesIds?.length ? (
				<AccountVertex
					key={extraVerticesIds.join(",")}
					accountIds={extraVerticesIds}
					onExpand={increase}
					onCollapse={maxVertices > finalMaxEntities ? decrease : undefined}
					refSetter={refSetter}
				/>
			) : null}
			{showCollapse ? <AccountVertex collapse onCollapse={decrease} /> : null}
		</>
	);
};
