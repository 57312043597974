import { IntegrationResourceAllowRequestsFilter } from "./integrationResourceAllowRequestsFilter";
import { IntegrationResourceIdFilter } from "./integrationResourceIdFilter";
import { IntegrationResourceIntegrationIdFilter } from "./integrationResourceIntegrationIdFilter";
import { IntegrationResourceIntegrationNameFilter } from "./integrationResourceIntegrationNameFilter";
import { IntegrationResourceMaintainerFilter } from "./integrationResourceMaintainerFilter";
import { IntegrationResourceNameFilter } from "./integrationResourceNameFilter";
import { IntegrationResourceOwnerFilter } from "./integrationResourceOwnerFilter";
import { IntegrationResourceTagsFilter } from "./integrationResourceTagsFilter";
import { IntegrationResourceTypeFilter } from "./integrationResourceTypeFilter";
import { IntegrationResourceWorkflowFilter } from "./integrationResourceWorkflowFilter";

export const INTEGRATION_RESOURCE_FILTERS = [
	IntegrationResourceAllowRequestsFilter,
	IntegrationResourceIdFilter,
	IntegrationResourceIntegrationIdFilter,
	IntegrationResourceIntegrationNameFilter,
	IntegrationResourceMaintainerFilter,
	IntegrationResourceNameFilter,
	IntegrationResourceOwnerFilter,
	IntegrationResourceTagsFilter,
	IntegrationResourceTypeFilter,
	IntegrationResourceWorkflowFilter
];

export const INTEGRATION_RESOURCE_FILTERS_BY_NAME = {
	[IntegrationResourceAllowRequestsFilter.filterName]: IntegrationResourceAllowRequestsFilter,
	[IntegrationResourceIdFilter.filterName]: IntegrationResourceIdFilter,
	[IntegrationResourceIntegrationIdFilter.filterName]: IntegrationResourceIntegrationIdFilter,
	[IntegrationResourceIntegrationNameFilter.filterName]: IntegrationResourceIntegrationNameFilter,
	[IntegrationResourceMaintainerFilter.filterName]: IntegrationResourceMaintainerFilter,
	[IntegrationResourceNameFilter.filterName]: IntegrationResourceNameFilter,
	[IntegrationResourceOwnerFilter.filterName]: IntegrationResourceOwnerFilter,
	[IntegrationResourceTagsFilter.filterName]: IntegrationResourceTagsFilter,
	[IntegrationResourceTypeFilter.filterName]: IntegrationResourceTypeFilter,
	[IntegrationResourceWorkflowFilter.filterName]: IntegrationResourceWorkflowFilter
};

export {
	IntegrationResourceAllowRequestsFilter,
	IntegrationResourceIdFilter,
	IntegrationResourceIntegrationIdFilter,
	IntegrationResourceIntegrationNameFilter,
	IntegrationResourceMaintainerFilter,
	IntegrationResourceNameFilter,
	IntegrationResourceOwnerFilter,
	IntegrationResourceTagsFilter,
	IntegrationResourceTypeFilter,
	IntegrationResourceWorkflowFilter
};
