import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	actions: {
		alignItems: "center",
		justifyContent: "flex-end",
		display: "flex",
		gap: "var(--spacing-x2, 8px)",
		padding: "var(--spacing-x3, 12px) 0"
	},
	body: {
		flexGrow: 1
	},
	container: {
		background: "var(--color-grey-300)",
		alignItems: "start",
		display: "flex",
		gap: "0.75rem",
		padding: "1rem"
	},
	textArea: {
		width: "100%",
		borderRadius: "4px"
	},
	textareaInput: {
		maxHeight: "10rem"
	}
});
