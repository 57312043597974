import { Record } from "immutable";
import { TVertexType, VertexModel } from "./VertexModel";

const defaults = {
	id: "",
	type: "user" as Exclude<TVertexType, "account" | "integration" | "role">,
	entity: { userId: "" },
	step: 0
};

export class UserVertexModel extends Record(defaults) implements VertexModel {
	static fromServerData(data: unknown): UserVertexModel {
		const { id, type, entity, step } = data as typeof defaults;

		return new UserVertexModel({
			id,
			type,
			entity: { userId: entity.userId },
			step
		});
	}
}
