import React from "react";
import { makeIcon } from "components/ui/Icon";

export const RequestsIcon = makeIcon(
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none">
		<path
			d="M16.525 8.175L20.075 4.625C20.275 4.425 20.5083 4.32916 20.775 4.3375C21.0417 4.34583 21.275 4.45 21.475 4.65C21.6583 4.85 21.75 5.08333 21.75 5.35C21.75 5.61666 21.6583 5.85 21.475 6.05L17.25 10.3C17.05 10.5 16.8167 10.6 16.55 10.6C16.2833 10.6 16.05 10.5 15.85 10.3L13.7 8.15C13.5167 7.96666 13.425 7.73333 13.425 7.45C13.425 7.16666 13.5167 6.93333 13.7 6.75C13.8833 6.56666 14.1167 6.475 14.4 6.475C14.6833 6.475 14.9167 6.56666 15.1 6.75L16.525 8.175ZM3 9C2.71667 9 2.47917 8.90416 2.2875 8.7125C2.09583 8.52083 2 8.28333 2 8C2 7.71666 2.09583 7.47916 2.2875 7.2875C2.47917 7.09583 2.71667 7 3 7H10C10.2833 7 10.5208 7.09583 10.7125 7.2875C10.9042 7.47916 11 7.71666 11 8C11 8.28333 10.9042 8.52083 10.7125 8.7125C10.5208 8.90416 10.2833 9 10 9H3ZM17 17.4L15.1 19.3C14.9167 19.4833 14.6833 19.575 14.4 19.575C14.1167 19.575 13.8833 19.4833 13.7 19.3C13.5167 19.1167 13.425 18.8833 13.425 18.6C13.425 18.3167 13.5167 18.0833 13.7 17.9L15.6 16L13.7 14.1C13.5167 13.9167 13.425 13.6833 13.425 13.4C13.425 13.1167 13.5167 12.8833 13.7 12.7C13.8833 12.5167 14.1167 12.425 14.4 12.425C14.6833 12.425 14.9167 12.5167 15.1 12.7L17 14.6L18.9 12.7C19.0833 12.5167 19.3167 12.425 19.6 12.425C19.8833 12.425 20.1167 12.5167 20.3 12.7C20.4833 12.8833 20.575 13.1167 20.575 13.4C20.575 13.6833 20.4833 13.9167 20.3 14.1L18.4 16L20.3 17.9C20.4833 18.0833 20.575 18.3167 20.575 18.6C20.575 18.8833 20.4833 19.1167 20.3 19.3C20.1167 19.4833 19.8833 19.575 19.6 19.575C19.3167 19.575 19.0833 19.4833 18.9 19.3L17 17.4ZM3 17C2.71667 17 2.47917 16.9042 2.2875 16.7125C2.09583 16.5208 2 16.2833 2 16C2 15.7167 2.09583 15.4792 2.2875 15.2875C2.47917 15.0958 2.71667 15 3 15H10C10.2833 15 10.5208 15.0958 10.7125 15.2875C10.9042 15.4792 11 15.7167 11 16C11 16.2833 10.9042 16.5208 10.7125 16.7125C10.5208 16.9042 10.2833 17 10 17H3Z"
			fill="currentColor"
		/>
	</svg>
);
