import { Record } from "immutable";

const defaults = {
	application: "okta" as TIdentityProviderApplication,
	createdAt: new Date(0),
	id: "",
	updatedAt: new Date(0),
	metadataUrl: ""
};

export type TIdentityProviderApplication = "okta";
export const IDENTITY_PROVIDER_APPLICATIONS = ["okta"] as TIdentityProviderApplication[];

export class IdentityProviderModel extends Record<typeof defaults>(defaults) {
	static fromServerData(data: unknown) {
		const { application, createdAt, id, updatedAt, metadataUrl } = data as typeof defaults;
		return new IdentityProviderModel({
			application,
			createdAt: new Date(createdAt),
			id,
			updatedAt: new Date(updatedAt),
			metadataUrl
		});
	}
}
