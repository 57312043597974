import React from "react";
import { useTranslation } from "react-i18next";
import { getIntegrationLogsFields } from "api/sessionAuditLogs";
import { FilterExpressionEmptyState } from "components/common/FilterExpressionEmptyState";
import { TextOption } from "components/ui/selectOptions/TextOption";
import { getLabel } from "utils/ui/select";
import { IntegrationLogsMultiSelectFilterExpression } from "../IntegrationLogsMultiSelectFilterExpression";

type TActionFilterExpressionProps = {
	onRemove: () => void;
};

export const ActionFilterExpression: FC<TActionFilterExpressionProps> = ({ onRemove }) => {
	const { t } = useTranslation();

	const emptyState = <FilterExpressionEmptyState text={t("pages.auditLog.integrationLogs.filter.actionEmptyState")} />;

	return (
		<IntegrationLogsMultiSelectFilterExpression
			fetchOptions={getIntegrationLogsFields}
			filterEmptyState={emptyState}
			filterField="action_display_name"
			filterName="action"
			getOptionLabel={getLabel}
			inputPlaceholder={t("pages.auditLog.integrationLogs.filter.actionPlaceholder")}
			onFilterRemove={onRemove}
			optionRenderer={TextOption}
			title={t("pages.auditLog.integrationLogs.filter.action")}
		/>
	);
};
