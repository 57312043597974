import { List, isList } from "immutable";
import { DirectoryGroupMaintainerModel } from "models/DirectoryGroupMaintainerModel";
import { UserMaintainerModel } from "models/UserMaintainerModel";

export const getMaintainerModels = (maintainers: unknown): List<TMaintainerModel> | null => {
	if (!maintainers || typeof maintainers !== "object" || (!Array.isArray(maintainers) && !isList(maintainers))) {
		return null;
	}
	return List<TMaintainerModel>(
		maintainers.map(maintainer => {
			if ("userId" in maintainer) {
				return UserMaintainerModel.fromServerData(maintainer);
			}
			return DirectoryGroupMaintainerModel.fromServerData(maintainer);
		})
	);
};
