import { createUseStyles } from "react-jss";

export const DROPDOWN_DEFAULT_MAX_HEIGHT = 288;
type TProps = { optionPanelActionsPosition?: "bottom" | "top"; maxHeight: number } | undefined;
export const useStyles = createUseStyles({
	createNew: {
		display: "flex",
		gap: "var(--spacing-x2, 8px)",
		alignItems: "center",
		color: "var(--color-blue)",
		cursor: "pointer"
	},
	container: {
		flexGrow: 1,
		width: "100%"
	},
	optionsListActionsPanelContainer: {
		padding: "var(--spacing-x2, 8px)",
		borderTop: (props: TProps) =>
			props?.optionPanelActionsPosition === "bottom" ? "1px solid var(--color-grey-light)" : "unset",
		borderBottom: (props: TProps) =>
			props?.optionPanelActionsPosition === "top" ? "1px solid var(--color-grey-light)" : "unset"
	},
	optionsListActionsPanelContainerTop: {
		padding: "var(--spacing-x2, 8px)",
		borderBottom: "1px solid var(--color-grey-light)"
	},
	groupContainer: {
		boxSizing: "border-box",
		display: "flex",
		flexDirection: "column",
		width: "100%"
	},
	groupLabel: {
		fontWeight: "var(--font-weight-bold)",
		padding: "var(--spacing-x2, 8px) var(--spacing-x4, 16px)",
		pointerEvents: "none"
	},
	input: {
		"&:hover": {
			"& $suffixClear": {
				opacity: 1
			}
		}
	},
	labelContainer: {
		alignItems: "center",
		cursor: "pointer",
		display: "flex",
		flexDirection: "row",
		width: "100%",
		minWidth: "0"
	},
	maxHeight: {
		maxHeight: (props: TProps) => `${props?.maxHeight || DROPDOWN_DEFAULT_MAX_HEIGHT}px`
	},
	noOptions: {
		alignItems: "center",
		backgroundColor: "var(--color-white)",
		boxSizing: "border-box",
		cursor: "default",
		display: "flex",
		height: "52px",
		minWidth: "100%",
		padding: "var(--spacing-x2, 8px) var(--spacing-x4, 16px)",
		width: "fit-content"
	},
	searchForMore: {
		alignItems: "center",
		display: "flex",
		justifyContent: "center",
		padding: "var(--spacing-x1, 4px) 0"
	},
	selectItemsContainer: {
		zIndex: "999",
		backgroundColor: "var(--color-white)",
		boxSizing: "border-box",
		boxShadow: "var(--surface-popup-box-shadow)",
		borderRadius: "var(--border-radius-semi-rounded)",
		display: "flex",
		flexDirection: (props: TProps) => (props?.optionPanelActionsPosition === "top" ? "column-reverse" : "column")
	},
	selectOptionsContainer: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		overflow: "auto",
		padding: "0.55rem 0",
		// scrollbarWidth is only supported in Firefox right now, but it is the new css standard: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Scrollbars
		scrollbarWidth: "thin"
	},
	suffix: {
		alignItems: "center",
		display: "flex",
		flexDirection: "row",
		gap: "var(--spacing-x1, 4px)",
		height: "100%",
		justifyContent: "center"
	},
	suffixClear: {
		opacity: 0,
		transition: "opacity 0.1s",
		willChange: "opacity"
	}
});
