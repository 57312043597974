import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	smallIconSize: {
		width: "12px",
		height: "12px"
	},
	regularIconSize: {
		width: "16px",
		height: "16px"
	},
	tooltip: {
		width: "fit-content"
	}
});
