import classNames from "classnames";
import sortBy from "lodash/sortBy";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { HiddenChip } from "components/ui/chips/HiddenChip";
import { TagChip } from "components/ui/chips/TagChip";
import { Typography } from "components/ui/legacy/Typography";
import { useStyles } from "./styles";

interface IProps {
	query?: string;
	tags: string[];
	tagMaxLength: number;
	tagsShown: number;
	variant?: "standard" | "small";
}

export const LabelTags: FC<IProps> = ({
	className,
	query = "",
	tags,
	tagMaxLength,
	tagsShown,
	variant = "standard"
}) => {
	const { t } = useTranslation();
	const classes = useStyles();

	const sortedTags = useMemo(
		() =>
			sortBy(
				tags,
				tag => tag !== query,
				tag => !tag.includes(query)
			),
		[query, tags]
	);

	const tagElements = useMemo(() => {
		const chips = sortedTags.slice(0, tagsShown).map((tag, i) => (
			<TagChip key={`${i}-${tag}`} variant="regular">
				{tag.substring(0, tagMaxLength)}
			</TagChip>
		));
		const extraTagsCount = tags.length - tagsShown;
		if (extraTagsCount > 0) {
			chips.push(
				<HiddenChip variant="regular" type="tag">
					{t("common.roleLabel.more", { count: extraTagsCount })}
				</HiddenChip>
			);
		}
		return chips;
	}, [sortedTags, t, tagMaxLength, tags.length, tagsShown]);

	if (!tagsShown || !tags.length) return null;

	return (
		<Typography component="div" variant="tiny" className={classNames(classes.tags, className)}>
			{tagElements}
		</Typography>
	);
};
