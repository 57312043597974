import { Record } from "immutable";
import { TicketApprovalRequestModel } from "./TicketApprovalRequestModel";
import { TicketModel } from "./TicketModel";

export type TTicketApprovalResponseType = "approve" | "decline" | "adminApprove" | "adminDecline";

const defaults = {
	createdAt: new Date(0),
	id: "",
	request: null as TicketApprovalRequestModel | null,
	ticket: null as TicketModel | null,
	type: "" as TTicketApprovalResponseType,
	userId: null as string | null,
	webhookId: null as string | null
};

export class TicketApprovalResponseModel extends Record<typeof defaults>(defaults) {
	static fromServerData(data: unknown): TicketApprovalResponseModel {
		const { createdAt, id, request, ticket, type, userId, webhookId } = data as typeof defaults;
		return new TicketApprovalResponseModel({
			createdAt: new Date(createdAt),
			id,
			request: request ? TicketApprovalRequestModel.fromServerData(request) : null,
			ticket: ticket ? TicketModel.fromServerData(ticket) : null,
			type,
			userId,
			webhookId
		});
	}
}
