import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	approvalAlgorithm: {
		display: "flex",
		flexDirection: "column",
		gap: "1rem",
		overflow: "hidden"
	},
	container: {
		flex: "1 1 auto",
		display: "flex",
		flexDirection: "column",
		overflow: "auto",
		paddingBottom: "0.75rem"
	},
	rulesContainer: {
		display: "flex",
		flexDirection: "column",
		gap: "0.5rem",
		"& > :not(:last-child)": {
			paddingBottom: "1rem"
		},
		"&$editable": {
			padding: "1rem 0.5rem"
		}
	},
	ruleAndFlowContainer: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		padding: "0.5rem",
		gap: "0.5rem"
	},
	editable: {}
});
