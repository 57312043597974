import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	activity: {
		display: "flex",
		flexDirection: "column",
		flexGrow: 1
	},
	activityTitle: {
		padding: "var(--spacing-x5, 20px) 0 var(--spacing-x7, 28px) 0"
	},
	activityTimeline: {
		padding: "0 var(--spacing-x5, 20px)",
		height: "100%",
		overflowY: "auto"
	},
	activityTimelineList: {
		display: "flex",
		flexDirection: "column"
	},
	ticketingIntegrationTicketChip: {
		display: "inline-block",
		margin: "0 0.1rem"
	},
	auditLogEntity: {
		alignItems: "baseline",
		verticalAlign: "middle"
	},
	activityTimelineAddComment: {}
});
