import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	modalContent: {
		display: "flex",
		flexDirection: "column",
		gap: "20px"
	},
	textArea: {
		minWidth: "440px"
	},
	viewError: {
		color: "var(--color-red-dark)"
	}
});
