import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { IntegrationIdFilterExpression } from "components/pages/CreateRulePage/components/filters/IntegrationIdFilterExpression";
import { useRuleStepperContext } from "components/pages/CreateRulePage/ruleStepperContext";
import { Typography } from "components/ui/Typography";
import { useStyles } from "./styles";

export const ApplyToSection: FC = ({ className }) => {
	const { t } = useTranslation("translation", { keyPrefix: "pages.createRule.ruleDefinition.applyTo" });
	const classes = useStyles();

	const {
		state: { applyToIntegrations },
		actions: { setApplyToIntegrations }
	} = useRuleStepperContext();

	return (
		<div className={classNames(className, classes.container)}>
			<Typography variant="body_sb">{t("title")}</Typography>
			<IntegrationIdFilterExpression filter={applyToIntegrations} updateFilter={setApplyToIntegrations} />
		</div>
	);
};
