import { List } from "immutable";
import { AccessReviewModel } from "models/AccessReviewModel";
import { AccessReviewResourcePermissionModel } from "models/AccessReviewResourcePermissionModel";
import { AccessReviewSubordinatePermissionModel } from "models/AccessReviewSubordinatePermissionModel";
import { apiReq } from "utils/api/apiReq";
import { withPagination } from "utils/pagination";
import { getPaginatedSearchQueryString, IPaginatedSearchOptions } from "utils/searchUtils";
import type { TAccessReviewPermissionStatus } from "models/AccessReviewPermissionModel";

type TAccessReviewPermissionsOptions = {
	paginationOptions?: IPaginatedSearchOptions;
	status?: TAccessReviewPermissionStatus;
	managed?: boolean;
	distinct?: boolean;
};

export const getAccessReviews = async (): Promise<List<AccessReviewModel>> => {
	const { data } = await apiReq("GET", "/v1/accessReviews");

	return List<AccessReviewModel>(data.map(AccessReviewModel.fromServerData));
};

export const createAccessReview = async (
	name: string,
	description: string,
	immediateRevoke: boolean,
	templateId: string | null = null
): Promise<AccessReviewModel> => {
	const { data: accessReview } = await apiReq("POST", "/v1/accessReviews", {
		templateId,
		name,
		description,
		immediateRevoke
	});

	return AccessReviewModel.fromServerData(accessReview);
};

export const editAccessReview = async (id: string, accessReviewChanges: Partial<AccessReviewModel>) => {
	const { data: accessReview } = await apiReq("PUT", `/v1/accessReviews/${id}`, accessReviewChanges);

	return AccessReviewModel.fromServerData(accessReview);
};

export const getAccessReview = async (id: string): Promise<AccessReviewModel> => {
	const { data: accessReview } = await apiReq("GET", `/v1/accessReviews/${id}`);

	return AccessReviewModel.fromServerData(accessReview);
};

export const deleteAccessReview = async (id: string) => {
	return apiReq("DELETE", `/v1/accessReviews/${id}`);
};

export const finishAccessReview = async (id: string) => {
	return apiReq("PUT", `/v1/accessReviews/${id}/finish`);
};

export const getAccessReviewPermissions = async (id: string, options: TAccessReviewPermissionsOptions = {}) => {
	const { status, managed, distinct, paginationOptions } = options;

	const paginationQs = getPaginatedSearchQueryString(paginationOptions);
	let url = `/v1/accessReviews/${id}/permissions`;
	const urlSearchParams = new URLSearchParams(paginationQs);
	if (status) urlSearchParams.set("status", status);
	if (managed !== undefined) urlSearchParams.set("managed", managed.toString());
	if (distinct !== undefined) urlSearchParams.set("distinct", distinct.toString());

	const qs = urlSearchParams.toString();
	url += qs ? `?${qs}` : "";
	return withPagination(
		(subjectPermission: Record<string, unknown>) =>
			subjectPermission?.accessReviewSubordinateId
				? AccessReviewSubordinatePermissionModel.fromServerData(subjectPermission)
				: AccessReviewResourcePermissionModel.fromServerData(subjectPermission),
		() => apiReq("GET", url)
	);
};

export const activateAccessReview = async (id: string) => {
	const { data: accessReview } = await apiReq("PUT", `/v1/accessReviews/${id}/activate`);

	return AccessReviewModel.fromServerData(accessReview);
};
