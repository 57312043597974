import { createUseStyles } from "react-jss";
export const useStyles = createUseStyles({
	bold: {
		"&$bold": { fontWeight: "var(--typography-font-weight-bold)" }
	},
	ticketNumber: {
		alignItems: "center",
		flexDirection: "row"
	},
	content: {
		alignItems: "center",
		display: "inline-flex",
		gap: "0.1rem"
	}
});
