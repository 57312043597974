import React from "react";
import { useTranslation } from "react-i18next";
import { getIntegrationLogsFields } from "api/sessionAuditLogs";
import { FilterExpressionEmptyState } from "components/common/FilterExpressionEmptyState";
import { AccountIcon } from "components/ui/Icons/AccountIcon";
import { TextOption } from "components/ui/selectOptions/TextOption";
import { getLabel } from "utils/ui/select";
import { IntegrationLogsMultiSelectFilterExpression } from "../IntegrationLogsMultiSelectFilterExpression";

type TAccountFilterExpressionProps = {
	onRemove: () => void;
};

export const AccountFilterExpression: FC<TAccountFilterExpressionProps> = ({ onRemove }) => {
	const { t } = useTranslation();

	const emptyState = (
		<FilterExpressionEmptyState
			text={t("pages.auditLog.integrationLogs.filter.accountEmptyState")}
			Icon={AccountIcon}
		/>
	);

	return (
		<IntegrationLogsMultiSelectFilterExpression
			fetchOptions={getIntegrationLogsFields}
			filterEmptyState={emptyState}
			filterField="author_display_name"
			filterName="account"
			getOptionLabel={getLabel}
			inputPlaceholder={t("pages.auditLog.integrationLogs.filter.accountPlaceholder")}
			onFilterRemove={onRemove}
			optionRenderer={TextOption}
			renderType="AccountNode"
			title={t("pages.auditLog.integrationLogs.filter.account")}
		/>
	);
};
