import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "components/ui/Button";
import { Checkbox } from "components/ui/Checkbox";
import { ChevronDownIcon } from "components/ui/Icons/ChevronDownIcon";
import { ChevronRightIcon } from "components/ui/Icons/ChevronRightIcon";
import { SearchIcon } from "components/ui/Icons/SearchIcon";
import { Input } from "components/ui/Input";
import { useNode } from "context/accessReviewTemplateContext";
import { ASC, usePagination } from "hooks/usePagination";
import { useStyles } from "./styles";
import type { IPaginatedSearchOptions } from "utils/searchUtils";

export interface ITreeNode {
	id: string;
	path: string[];
	label: string;
	perPage: number;
	isLeaf: boolean;
	searchable: boolean;
	totalChildren: number;
	totalChildrenArray: number[];
}

export const TreeNode: FC<ITreeNode> = ({
	className,
	path,
	label,
	perPage = 10,
	isLeaf,
	searchable = true,
	totalChildren,
	totalChildrenArray
}) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const [isOpen, setIsOpen] = useState(false);
	const [searchInput, setSearchInput] = useState("");

	const { getNodeSelectionState, searchNodeChildren, selectNode } = useNode();

	const paginatedSearch = useCallback(
		(paginationOptions: IPaginatedSearchOptions) => searchNodeChildren(path, paginationOptions, searchInput),
		[path, searchInput, searchNodeChildren]
	);

	const { items, setCurrentPageNumber, totalPages, lastPageNumber, isLoading } = usePagination({
		fetchItems: paginatedSearch,
		perPage: perPage,
		sortOrder: ASC,
		initialFilter: null,
		disableSearch: !isOpen
	});

	const selectionState = useMemo(() => getNodeSelectionState(path), [getNodeSelectionState, path]);

	const childNodes = useMemo(
		() =>
			items && items.count()
				? items
						.map(child => (
							<TreeNode
								key={child.path.join("/")}
								id={child.id}
								path={child.path}
								label={child.label}
								perPage={child.perPage ?? perPage}
								isLeaf={child.isLeaf}
								searchable={child.searchable}
								totalChildren={child.totalChildren}
								totalChildrenArray={totalChildrenArray.concat(child.totalChildren)}
							/>
						))
						.toArray()
				: null,
		[items, totalChildrenArray, perPage]
	);

	const onCheckboxClick = useCallback(
		(_: unknown, path: string[]) => selectNode(path, totalChildrenArray),
		[selectNode, totalChildrenArray]
	);
	const toggleOpen = useCallback(() => setIsOpen(current => !current), []);
	const loadNextPage = useCallback(() => setCurrentPageNumber(current => current + 1), [setCurrentPageNumber]);

	const Arrow = useMemo(() => {
		if (isLeaf) return null;
		if (totalChildren === 0) return <ChevronDownIcon className={classes.invisible} />;
		if (isOpen) return <ChevronDownIcon onClick={toggleOpen} />;
		return <ChevronRightIcon onClick={toggleOpen} />;
	}, [classes.invisible, isLeaf, isOpen, toggleOpen, totalChildren]);

	const showSearch = searchable && !isLeaf && isOpen && totalChildren && totalChildren > perPage;

	return (
		<div className={className}>
			<div className={classes.treeNodeHeader}>
				<div className={classes.nodeName}>
					{Arrow}
					<Checkbox
						value={path}
						selected={selectionState.visualState === "all"}
						partialSelected={selectionState.visualState === "partial"}
						tooltipText={selectionState.isExcluded ? t("checkbox.selectAll") : t("checkbox.deselectAll")}
						label={label}
						onClick={onCheckboxClick}
					/>
				</div>
				{showSearch ? (
					<Input
						className={classes.treeNodeSearch}
						onValueChange={setSearchInput}
						placeholder={t("pages.accessReviewTemplate.search", { name: label })}
						prefix={<SearchIcon />}
						size="medium"
						value={searchInput}
						variant="search"
					/>
				) : null}
			</div>
			<div className={classes.treeNodeContent}>
				{!isLeaf && isOpen ? (
					<>
						{childNodes}
						{totalPages > 1 && lastPageNumber < totalPages ? (
							<Button
								variant="secondary"
								size="medium"
								loading={isLoading}
								className={classes.loadMore}
								onClick={loadNextPage}>
								{t("buttons.loadMore")}
							</Button>
						) : null}
					</>
				) : null}
			</div>
		</div>
	);
};
