import { Record } from "immutable";
import uniq from "lodash/uniq";
import { validateFilterEqualityOperator, validateFilterInclusionOperator } from "utils/validation/filterValidation";
import type { IFilter } from "types/filters";

const filterName = "integrationResourceRoleIntegrationResourceType";

const defaults = {
	name: filterName as typeof filterName,
	value: [] as string[],
	operator: "is" as "contains" | "is" | "isNot" | "notContains"
};

type TDefaults = typeof defaults;

export class IntegrationResourceRoleIntegrationResourceTypeFilter
	extends Record<TDefaults>(defaults)
	implements IFilter<TDefaults["value"], TDefaults["operator"], TDefaults["name"]>
{
	static filterName: typeof filterName = filterName;

	static fromURLSearchParams(
		urlSearchParams: URLSearchParams
	): IntegrationResourceRoleIntegrationResourceTypeFilter | null {
		const integrationResourceRoleIntegrationResourceType = uniq(
			urlSearchParams.getAll("integrationResourceRoleIntegrationResourceType")
		);
		const integrationResourceRoleIntegrationResourceTypeOperator =
			urlSearchParams.get("integrationResourceRoleIntegrationResourceTypeOperator") || undefined;
		if (
			integrationResourceRoleIntegrationResourceType.length === 0 ||
			!(
				validateFilterEqualityOperator(integrationResourceRoleIntegrationResourceTypeOperator) ||
				validateFilterInclusionOperator(integrationResourceRoleIntegrationResourceTypeOperator)
			)
		) {
			return null;
		}
		return new IntegrationResourceRoleIntegrationResourceTypeFilter({
			value: integrationResourceRoleIntegrationResourceType,
			operator: integrationResourceRoleIntegrationResourceTypeOperator
		});
	}

	toURLSearchParams(): URLSearchParams {
		const params = new URLSearchParams();
		if (!this.value.length) {
			return params;
		}
		for (const value of uniq(this.value)) {
			params.append("integrationResourceRoleIntegrationResourceType", value);
		}
		params.append("integrationResourceRoleIntegrationResourceTypeOperator", this.operator);
		return params;
	}
}

export type TIntegrationResourceRoleIntegrationResourceTypeFilter = Omit<TDefaults, "name">;
