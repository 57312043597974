import React from "react";
import { useTranslation } from "react-i18next";
import { IntegrationLogsDateTimeSelectFilterExpression } from "../IntegrationLogsDateTimeSelectFilterExpression";

type TDateTimeFilterExpressionProps = {
	onRemove: () => void;
};

export const DateTimeFilterExpression: FC<TDateTimeFilterExpressionProps> = ({ onRemove }) => {
	const { t } = useTranslation();

	return (
		<IntegrationLogsDateTimeSelectFilterExpression
			title={t("pages.auditLog.integrationLogs.filter.dateTime")}
			onFilterRemove={onRemove}
			filterName="timestamp"
		/>
	);
};
