import { Map } from "immutable";
import { ForwardModel } from "models/ForwardModel";
import { apiReq } from "utils/api/apiReq";

export type TForwardType = "accessRequest" | "accessReview";

export async function getForwards(type: TForwardType): Promise<Map<string, ForwardModel>> {
	const { data } = await apiReq("GET", `/v1/forwards/${type}`);

	return Map<string, ForwardModel>(
		data.map((token: unknown) => {
			const model = ForwardModel.fromServerData(token);

			return [model.id, model];
		})
	);
}

export const createForward = async (type: TForwardType, forwarderUserId: string, targetUserId: string) => {
	const { data: forward } = await apiReq("POST", `/v1/forwards/${type}`, { forwarderUserId, targetUserId });

	return ForwardModel.fromServerData(forward);
};

export const updateForward = async (type: TForwardType, id: string, targetUserId: string) => {
	const { data: forward } = await apiReq("PUT", `/v1/forwards/${type}/${id}`, { targetUserId });

	return ForwardModel.fromServerData(forward);
};

export const deleteForward = async (type: TForwardType, id: string) => {
	return apiReq("DELETE", `/v1/forwards/${type}/${id}`);
};
