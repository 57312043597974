import { useEffect } from "react";
import { useApprovalFlowsWebhooksContext } from "context/approvalFlowsWebhooksContext";

export const useApprovalFlowsWebhooksMap = () => {
	const {
		state: { approvalFlowsWebhooks },
		actions: { loadApprovalFlows }
	} = useApprovalFlowsWebhooksContext();

	useEffect(() => {
		if (!approvalFlowsWebhooks) {
			void loadApprovalFlows();
		}
	}, [approvalFlowsWebhooks, loadApprovalFlows]);

	return approvalFlowsWebhooks;
};
