import { useCallback, useMemo, useRef } from "react";

export const useAbortController = () => {
	const abortControllerRef = useRef<AbortController | null>(null);

	const abortWrapper = useCallback(async <T>(callback: (...args: any[]) => Promise<T>) => {
		const abortController = abortControllerRef.current;
		if (abortController) abortController.abort();
		const newAbortController = new AbortController();
		abortControllerRef.current = newAbortController;

		const result = await callback(newAbortController.signal);

		abortControllerRef.current = null;
		return result;
	}, []);

	const removeAbortController = useCallback(() => (abortControllerRef.current = null), []);

	return useMemo(() => ({ abortWrapper, removeAbortController }), [abortWrapper, removeAbortController]);
};
