import React, { useMemo } from "react";
import { TooltipOnOverflow } from "components/ui/TooltipOnOverflow";
import { IntegrationImage } from "../../IntegrationImage";
import { Card, TCardProps } from "../Card";
import { ResourceAndRoleCountCardBody, TResourceAndRoleCountCardBodyProps } from "../ResourceAndRoleCountCardBody";
import type { IntegrationModel } from "models/IntegrationModel";

type TIntegrationCardProps = Omit<TCardProps, "header" | "content"> &
	TResourceAndRoleCountCardBodyProps & {
		integration: IntegrationModel;
	};

export const IntegrationCard: FC<TIntegrationCardProps> = props => {
	const { integration, resourcesCount, resourcesLabel, rolesCount, rolesLabel, size, ...cardProps } = props;
	const isLarge = size === "large";

	const headerContent = useMemo(() => {
		if (integration) {
			return (
				<>
					<IntegrationImage
						integration={integration}
						size={isLarge ? "medium" : "small"}
						noBorder
						noBackground
						noWrap
					/>
					<TooltipOnOverflow textVariant={isLarge ? "title_sb" : "body_sb"} content={integration.name} />
				</>
			);
		}
		return null;
	}, [integration, isLarge]);

	return (
		<Card
			size={size}
			content={
				<ResourceAndRoleCountCardBody
					resourcesCount={resourcesCount}
					resourcesLabel={resourcesLabel}
					rolesCount={rolesCount}
					rolesLabel={rolesLabel}
					size={size}
				/>
			}
			header={headerContent}
			{...cardProps}
		/>
	);
};
