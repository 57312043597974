import { List } from "immutable";
import React, { useCallback, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { AreYouSureModal } from "components/common/AreYouSureModal";
import { RevokeModal } from "components/common/RevokeModal";
import { Button } from "components/ui/Button";
import { DeleteIcon } from "components/ui/Icons/DeleteIcon";
import { GrantedIcon } from "components/ui/Icons/GrantedIcon";
import { ViewIcon } from "components/ui/Icons/ViewIcon";
import { Typography } from "components/ui/legacy/Typography";
import { useAccessReviewsContext } from "context/accessReviewsContext";
import { useAccessReviewPermissions } from "hooks/useAccessReviewPermissions";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { useIsOpenState } from "hooks/useIsOpenState";
import { useUser } from "hooks/useUser";
import { notEmpty } from "utils/comparison";
import { useStyles } from "./styles";
import { User } from "../User";
import type { AccessReviewModel } from "models/AccessReviewModel";
import type { TAccessReviewPermissionModel } from "models/AccessReviewPermissionModel";

const TRANSLATION_PREFIX = "pages.accessReview.dashboard";

interface IProps {
	accessReview: AccessReviewModel;
	changeToLatest: () => void;
}

export const SummaryHeader: FC<IProps> = ({ accessReview, changeToLatest }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const deleteAreYouSureModal = useIsOpenState();
	const doneModal = useIsOpenState();
	const activateAreYouSureModal = useIsOpenState();
	const openErrorModal = useOpenGlobalErrorModal();
	const { immediateRevoke } = accessReview;
	const { items, totalPages, lastPageNumber, isLoading, getPage } = useAccessReviewPermissions(accessReview.id, {
		status: "denied",
		managed: false,
		distinct: true
	});

	const paginationOptions = useMemo(
		() => ({
			lastPageNumber,
			totalPages,
			isLoading,
			getPage: getPage as (page: number) => Promise<void>
		}),
		[getPage, isLoading, lastPageNumber, totalPages]
	);

	const {
		state: { isActivateLoading, isDeleteLoading, isFinishLoading },
		actions: { activateAccessReview, deleteAccessReview, finishAccessReview }
	} = useAccessReviewsContext();
	const accessReviewCreator = useUser(accessReview.creatorId);

	const onDelete = useCallback(async () => {
		try {
			await deleteAccessReview(accessReview.id);
			deleteAreYouSureModal.close();
		} catch (error) {
			openErrorModal(error as Error);
		}
	}, [accessReview.id, deleteAccessReview, deleteAreYouSureModal, openErrorModal]);

	const onFinish = useCallback(async () => {
		try {
			await finishAccessReview(accessReview.id);
			doneModal.close();
		} catch (error) {
			openErrorModal(error as Error);
		}
	}, [accessReview.id, doneModal, finishAccessReview, openErrorModal]);

	const onActivate = useCallback(async () => {
		try {
			await activateAccessReview(accessReview.id);
			activateAreYouSureModal.close();
		} catch (error) {
			openErrorModal(error as Error);
		}
	}, [accessReview.id, activateAccessReview, activateAreYouSureModal, openErrorModal]);

	const title = t(`${TRANSLATION_PREFIX}.title`);
	const deniedManaged = accessReview.statistics?.permissionsStatus.deniedManaged || 0;
	const deniedUnmanaged = accessReview.statistics?.permissionsStatus.deniedUnmanaged || 0;
	const unmanagedPermissions = useMemo(
		() =>
			items?.map(permission => permission.accessReviewPermission).filter(notEmpty) ||
			List<TAccessReviewPermissionModel>(),
		[items]
	);
	const isRevokeModal = !immediateRevoke && (deniedManaged > 1 || deniedUnmanaged);

	return (
		<div className={classes.header}>
			<AreYouSureModal
				isOpen={deleteAreYouSureModal.isOpen}
				onClose={deleteAreYouSureModal.close}
				onAction={onDelete}
			/>
			{isRevokeModal ? (
				<RevokeModal
					permissions={unmanagedPermissions}
					paginationOptions={paginationOptions}
					managedPermissionsCount={deniedManaged}
					unmanagedPermissionsCount={deniedUnmanaged}
					isOpen={doneModal.isOpen}
					onClose={doneModal.close}
					onAction={onFinish}
				/>
			) : (
				<AreYouSureModal
					title={t("pages.accessReview.complete.title")}
					content={
						<Trans
							i18nKey={"pages.accessReview.complete.description"}
							components={{ bold: <b /> }}
							context={deniedManaged || deniedUnmanaged ? "revoke" : undefined}
						/>
					}
					actionLabel={t("buttons.done")}
					isOpen={doneModal.isOpen}
					onClose={doneModal.close}
					onAction={onFinish}
				/>
			)}
			<AreYouSureModal
				title={t("pages.accessReview.activate.title")}
				content={<div className={classes.wrapContent}>{t("pages.accessReview.activate.description")}</div>}
				actionLabel={t("buttons.activate")}
				isOpen={activateAreYouSureModal.isOpen}
				onClose={activateAreYouSureModal.close}
				onAction={onActivate}
			/>
			<Typography variant="h3">{`${title} - ${accessReview.name}`}</Typography>
			{accessReview && accessReviewCreator && (
				<Typography variant="small" className={classes.headerMetadata}>
					<Trans
						i18nKey={`${TRANSLATION_PREFIX}.metadata` as const}
						values={{ date: accessReview.createdAt }}
						components={{
							user: <User minimized user={accessReviewCreator} />
						}}
					/>
				</Typography>
			)}
			{accessReview.permissionCount && accessReview.permissionCount > 0 && (
				<div className={classes.headerButtons}>
					<Button
						variant="secondary"
						size="medium"
						prefix={<DeleteIcon />}
						onClick={deleteAreYouSureModal.open}
						loading={isDeleteLoading}
						disabled={isDeleteLoading}>
						{t(`${TRANSLATION_PREFIX}.deleteReview`)}
					</Button>
					<Button variant="secondary" size="medium" prefix={<ViewIcon />} onClick={changeToLatest}>
						{t(`${TRANSLATION_PREFIX}.viewReview`)}
					</Button>
					{["active", "done"].includes(accessReview.status) ? (
						<Button
							variant={accessReview.status === "done" ? "text" : "secondary"}
							size="medium"
							prefix={<GrantedIcon />}
							disabled={accessReview.status === "done"}
							loading={isFinishLoading}
							onClick={doneModal.open}>
							{t("buttons.done")}
						</Button>
					) : (
						<Button
							variant="secondary"
							size="medium"
							loading={isActivateLoading}
							onClick={activateAreYouSureModal.open}>
							{t("buttons.activate")}
						</Button>
					)}
				</div>
			)}
		</div>
	);
};
