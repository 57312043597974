import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	radioGroup: {
		overflow: "hidden",
		textOverflow: "ellipsis",
		display: "flex",
		gap: "0.5rem",
		padding: "0.5em",
		flexDirection: "column"
	},
	title: {
		display: "flex",
		flexDirection: "row",
		letterSpacing: "0.01rem",
		textTransform: "capitalize"
	}
});
