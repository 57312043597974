import constate from "constate";
import { Map } from "immutable";
import { useCallback } from "react";
import {
	ICreateApprovalFlowsWebhookParams,
	createApprovalFlowsWebhook as apiCreateApprovalFlowsWebhook,
	deleteApprovalFlowsWebhook as apiDeleteApprovalFlowsWebhook,
	getApprovalFlowsWebhooks,
	updateApprovalFlowsWebhook as apiUpdateApprovalFlowsWebhook,
	TUpdateApprovalFlowsWebhookParams
} from "api/approvalFlowsWebhooks";
import { useFetchedState } from "hooks/useFetchedState";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { ApprovalFlowsWebhookModel } from "models/ApprovalFlowsWebhookModel";

const useApprovalFlowsWebhooks = () => {
	const {
		data: approvalFlowsWebhooks,
		setData: setApprovalFlowsWebhooks,
		loadData: loadApprovalFlowsWebhooks,
		isLoading
	} = useFetchedState(getApprovalFlowsWebhooks);

	const openGlobalErrorModal = useOpenGlobalErrorModal();

	const createApprovalFlowsWebhook = useCallback(
		async (params: ICreateApprovalFlowsWebhookParams) => {
			const webhook = await apiCreateApprovalFlowsWebhook(params);

			setApprovalFlowsWebhooks(current => (current ?? Map()).set(webhook.id, webhook));
			return webhook;
		},
		[setApprovalFlowsWebhooks]
	);

	const updateApprovalFlowsWebhook = useCallback(
		async (id: string, params: TUpdateApprovalFlowsWebhookParams) => {
			const webhook = await apiUpdateApprovalFlowsWebhook(id, params);

			setApprovalFlowsWebhooks(current => (current ?? Map()).set(webhook.id, webhook));
			return webhook;
		},
		[setApprovalFlowsWebhooks]
	);

	const enableApprovalFlowsWebhook = useCallback(
		async (webhook: ApprovalFlowsWebhookModel) => {
			await apiUpdateApprovalFlowsWebhook(webhook.id, { enabled: true });

			setApprovalFlowsWebhooks(current => (current ?? Map()).set(webhook.id, webhook.set("enabled", true)));
		},
		[setApprovalFlowsWebhooks]
	);

	const deleteApprovalFlowsWebhook = useCallback(
		async (id: string) => {
			try {
				await apiDeleteApprovalFlowsWebhook(id);
				setApprovalFlowsWebhooks(current => (current ?? Map()).delete(id));
			} catch (error) {
				openGlobalErrorModal(error as Error);
			}
		},
		[openGlobalErrorModal, setApprovalFlowsWebhooks]
	);

	return {
		state: { approvalFlowsWebhooks, isLoading },
		actions: {
			createApprovalFlowsWebhook,
			enableApprovalFlowsWebhook,
			updateApprovalFlowsWebhook,
			loadApprovalFlows: loadApprovalFlowsWebhooks,
			deleteApprovalFlowsWebhook
		}
	};
};

export const [ApprovalFlowsWebhookProvider, useApprovalFlowsWebhooksContext] = constate(useApprovalFlowsWebhooks);
