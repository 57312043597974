import React, { useMemo } from "react";
import { WebhookIcon } from "components/ui/Icons/WebhookIcon";
import { EntityNodeContent } from "../components/EntityNodeContent";
import { DeletedIndicator } from "../components/indicators/DeletedIndicator";
import { EntityLabel } from "../EntityLabel";
import type { TBaseEntityNodeProps } from "../types";

type TProps = TBaseEntityNodeProps & {
	withIcon?: boolean;
	webhook: { name: string; isDeleted?: boolean };
};

export const WebhookEntity: FC<TProps> = ({
	webhook,
	withIcon,
	size,
	className,
	variant,
	inactive: propInactive,
	...entityNodeProps
}) => {
	const indicators = useMemo(() => {
		const indicators: React.ReactNode[] = [];
		if (webhook.isDeleted) {
			indicators.push(<DeletedIndicator />);
		}

		return indicators;
	}, [webhook.isDeleted]);

	const inactive = propInactive || webhook.isDeleted;

	const content = useMemo(() => {
		return (
			<EntityNodeContent
				tooltipOnOverflow
				text={webhook.name}
				withIcon={withIcon}
				size={size}
				inactive={inactive}
				icon={<WebhookIcon />}
				variant={variant}
				{...entityNodeProps}
			/>
		);
	}, [webhook.name, withIcon, size, inactive, variant, entityNodeProps]);

	return <EntityLabel content={content} size={size} indicators={indicators} className={className} />;
};
