import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	textarea: {
		borderRadius: "var(--border-radius-sharp)"
	},
	textareaInput: {
		maxHeight: "10rem",
		wordBreak: "break-all"
	}
});
