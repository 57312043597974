import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	container: {
		alignItems: "flex-start",
		cursor: "text",
		display: "flex",
		flexDirection: "column",
		gap: "0.2rem",
		width: "100%"
	},
	userTitle: {
		alignItems: "center",
		display: "inline-flex",
		flexDirection: "row",
		gap: "0.5rem"
	}
});
