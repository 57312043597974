import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	container: {
		alignItems: "center",
		display: "inline-flex",
		gap: "0.85rem",
		justifyContent: "center"
	},
	displayName: {
		display: "flex",
		alignItems: "center",
		gap: "1rem"
	},
	descriptionTooltip: { wordBreak: "break-word" },
	name: {
		display: "flex",
		flexDirection: "row",
		gap: "0.5rem",
		maxWidth: "20rem"
	},
	lighterText: {
		color: "var(--color-grey-dark)"
	},
	entityContainer: {
		display: "flex",
		justifyContent: "center",
		gap: "0.5rem",
		alignItems: "flex-end"
	},
	hiddenResource: {
		color: "var(--color-grey-dark)"
	},
	infoIcon: {
		color: "var(--color-blue-dark)",
		cursor: "pointer"
	},
	typeContainer: {
		color: "var(--color-grey-dark)",
		display: "flex",
		alignItems: "center",
		maxWidth: "25rem"
	},
	descriptionContainer: {
		marginTop: "1.2rem",
		display: "flex",
		alignItems: "center",
		maxWidth: "80rem"
	},
	tagsContainer: {
		marginTop: "1.2rem",
		gap: "0.3rem",
		display: "flex"
	},
	resourceTagChip: {
		"&:hover": {
			backgroundColor: "var(--color-white)"
		}
	},
	type: {
		margin: "0 0.5rem"
	},
	ownerContainer: {
		display: "flex",
		flexDirection: "column",
		gap: "2px"
	},
	inheritOwner: {
		height: 0,
		display: "flex"
	}
});
