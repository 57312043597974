import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	body: {
		height: "100%",
		overflow: "hidden",
		position: "relative",
		width: "100%"
	},
	table: {
		backgroundColor: "var(--color-white)",
		borderSpacing: "0",
		display: "grid",
		gridTemplateRows: "auto minmax(0, 1fr)",
		height: "100%",
		minWidth: "100%",
		"&$limitedHeight": {
			maxHeight: "1000px"
		}
	},
	emptyTableMessage: {
		display: "flex",
		justifyContent: "center",
		color: "var(--color-grey-700, #5F6066)",
		paddingTop: "180px"
	},
	limitedHeight: {}
});
