import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	summary: {
		display: "flex",
		alignItems: "center",
		justifyContent: "start",
		width: "100%",
		cursor: "pointer",
		padding: "1rem",
		flexDirection: "row-reverse",
		minHeight: 0,
		fontSize: "0.8em",
		"& > $expandIcon": {
			transform: "rotate(90deg)"
		},
		"&$disabled": {
			pointerEvents: "none"
		}
	},
	expandIcon: {
		display: "flex",
		transform: "rotate(90deg)",
		marginRight: "1rem",
		fontSize: "1.5em",
		transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1)",
		"&$expanded": {
			transform: "rotate(180deg)"
		},
		"&:hover": {
			borderRadius: 15
		}
	},
	title: {
		display: "flex",
		justifyContent: "space-between",
		width: "100%"
	},
	expanded: {},
	disabled: {}
});
