import classNames from "classnames";
import React, { useMemo } from "react";
import { BundleEntity } from "components/common/entities";
import { Checkbox } from "components/ui/Checkbox";
import { useStyles } from "./styles";
import { RoleBarTag } from "../RoleBarTag";

type TWithCheckbox = {
	checkbox: true;
	checkboxValue: boolean;
	onToggle: () => void;
};

type TWithoutCheckbox = {
	checkbox?: false;
	checkboxValue?: never;
	onToggle?: never;
};

type TWithBundle = {
	name: string;
	description?: string | null;
	tags?: string[] | null;
};

type TWithoutBundle = {
	name?: never;
	description?: never;
	tags?: never;
};

type TCheckboxPartProps = TWithCheckbox | TWithoutCheckbox;

type TBundlePartProps = TWithBundle | TWithoutBundle;

type TProps = TCheckboxPartProps & TBundlePartProps;

const CheckboxPart: React.FC<TCheckboxPartProps> = props => {
	if (!props.checkbox) {
		return null;
	}
	return <Checkbox noMargin selected={props.checkboxValue} onClick={props.onToggle} />;
};

const BundlePart: React.FC<TBundlePartProps> = props => {
	const classes = useStyles();

	const content = useMemo(() => {
		if (!props.name) {
			return null;
		}

		const bundle = {
			name: props.name,
			description: props.description
		};

		return (
			<>
				<BundleEntity noWrap relative withIcon bundle={bundle} size="medium" />
				{props.tags ? <RoleBarTag tags={props.tags} /> : null}
			</>
		);
	}, [props.description, props.name, props.tags]);

	return <div className={classes.bundlePart}>{content}</div>;
};

export const RoleBarBundle: FC<TProps> = ({ className, innerRef, ...restProps }) => {
	const classes = useStyles();

	return (
		<div
			className={classNames(classes.container, { [classes.withCheckbox]: !!restProps.checkbox }, className)}
			ref={innerRef}>
			<CheckboxPart {...restProps} />
			<BundlePart {...restProps} />
		</div>
	);
};
