import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { UserEntity } from "components/common/entities/UserEntity";
import { UserCard } from "components/common/UserCard";
import { HiddenChip } from "components/ui/chips/HiddenChip";
import { StaticChip } from "components/ui/chips/StaticChip";
import { IconPrefix } from "components/ui/IconPrefix";
import { UserCircleIcon } from "components/ui/Icons/UserCircleIcon";
import { TitleTooltip } from "components/ui/TitleTooltip";
import { TooltipOnOverflow } from "components/ui/TooltipOnOverflow";
import { useMultiUsers } from "hooks/useMultiUsers";
import { useStyles } from "./styles";

type TProps = {
	rowUsers: string[];
	isCell: boolean;
};

export const UsersCell: FC<TProps> = ({ rowUsers, isCell }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const users = useMultiUsers(rowUsers);

	const usersTooltipHeader = useMemo(() => {
		return (
			<div className={classes.tooltipTitle}>
				<IconPrefix Icon={UserCircleIcon} semibold content={t("pages.auditLog.integrationLogs.users")} />
				<StaticChip size="small" variant="regular">
					{t("number", { value: rowUsers.length })}
				</StaticChip>
			</div>
		);
	}, [classes.tooltipTitle, t, rowUsers.length]);

	const userCards = useMemo(() => {
		if (rowUsers.length > 1) {
			const hiddenUsers = rowUsers.slice(1);
			return hiddenUsers.map(userId => {
				const user = users?.get(userId);
				return user ? <UserCard user={user} /> : null;
			});
		}
		return null;
	}, [rowUsers, users]);

	const usersTooltip = useMemo(() => {
		const firstUser = users?.get(rowUsers.at(0)!);
		return (
			<div className={classes.chipAndText}>
				<TooltipOnOverflow content={firstUser?.fullName} />
				<TitleTooltip header={usersTooltipHeader} body={userCards}>
					<HiddenChip type="user" size="medium" variant="regular">
						{`${userCards?.length}+`}
					</HiddenChip>
				</TitleTooltip>
			</div>
		);
	}, [classes.chipAndText, rowUsers, userCards, users, usersTooltipHeader]);

	const renderUsersTextCell = useMemo(() => {
		const user = users?.get(rowUsers?.at(0) || "");
		if (!user) return null;
		return <UserEntity size="medium" withoutEmail={isCell} user={user} />;
	}, [isCell, rowUsers, users]);

	return <>{rowUsers.length > 1 ? usersTooltip : renderUsersTextCell}</>;
};
