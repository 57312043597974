import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	approvalAlgorithm: {
		boxShadow: "none",
		height: "100%",
		padding: 0,
		"&::-webkit-scrollbar": {
			"-webkit-appearance": "none"
		}
	},
	contentBody: {
		overflow: "hidden"
	},
	exitModalContent: {
		width: "372px",
		paddingBottom: "var(--spacing-x12)"
	},
	exitModal: {
		padding: "var(--spacing-x5)"
	}
});
