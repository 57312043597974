import classNames from "classnames";
import React, { useMemo } from "react";
import { Skeleton } from "components/ui/Skeleton";
import { useStyles } from "./styles";
import type { TChipSize } from "../Chip";

interface ISkeletonChipProps {
	size?: TChipSize;
}

export const SkeletonChip: FC<ISkeletonChipProps> = ({ size = "medium" }) => {
	const classes = useStyles();

	const sizeClassName = useMemo(() => {
		switch (size) {
			case "small":
				return classes.small;
			case "medium":
				return classes.medium;
			case "large":
				return classes.large;
		}
	}, [classes.large, classes.medium, classes.small, size]);

	return <Skeleton variant="circle" className={classNames(sizeClassName, classes.chip)} />;
};
