import React from "react";
import { makeIcon } from "components/ui/Icon";

export const FavoriteIcon = makeIcon(
	<svg viewBox="0 0 24 24" fill="none">
		<path
			d="M12 18.275L7.85002 20.775C7.66668 20.8917 7.47502 20.9417 7.27501 20.925C7.07501 20.9083 6.90001 20.8417 6.75002 20.725C6.60001 20.6083 6.48335 20.4625 6.40002 20.2875C6.31668 20.1125 6.30002 19.9167 6.35002 19.7L7.45002 14.975L3.77502 11.8C3.60835 11.65 3.50418 11.4792 3.46252 11.2875C3.42085 11.0958 3.43335 10.9083 3.50002 10.725C3.56668 10.5417 3.66668 10.3917 3.80002 10.275C3.93335 10.1583 4.11668 10.0833 4.35002 10.05L9.20002 9.62501L11.075 5.17501C11.1583 4.97501 11.2875 4.82501 11.4625 4.72501C11.6375 4.62501 11.8167 4.57501 12 4.57501C12.1833 4.57501 12.3625 4.62501 12.5375 4.72501C12.7125 4.82501 12.8417 4.97501 12.925 5.17501L14.8 9.62501L19.65 10.05C19.8833 10.0833 20.0667 10.1583 20.2 10.275C20.3333 10.3917 20.4333 10.5417 20.5 10.725C20.5667 10.9083 20.5792 11.0958 20.5375 11.2875C20.4958 11.4792 20.3917 11.65 20.225 11.8L16.55 14.975L17.65 19.7C17.7 19.9167 17.6833 20.1125 17.6 20.2875C17.5167 20.4625 17.4 20.6083 17.25 20.725C17.1 20.8417 16.925 20.9083 16.725 20.925C16.525 20.9417 16.3333 20.8917 16.15 20.775L12 18.275Z"
			fill="currentColor"
		/>
	</svg>
);
