import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	actionsContainer: {
		gap: "0.5rem"
	},
	approveButton: {
		"&:hover, &$selected": {
			backgroundColor: "var(--color-green-dark)",
			borderColor: "var(--color-green-dark)"
		},
		"&$disabled, &$disabled:hover": {
			backgroundColor: "var(--button-disabled-background-color)",
			color: "var(--button-disabled-font-color)",
			cursor: "default",
			pointerEvents: "none"
		},
		color: "var(--color-green-dark)"
	},
	button: {
		"&&:hover, &$selected": {
			color: "var(--color-white)",
			cursor: "pointer"
		},
		alignItems: "center",
		backgroundColor: "transparent",
		border: "1px solid var(--color-grey-background)",
		borderRadius: "var(--border-radius-rounder)",
		display: "flex",
		justifyContent: "center",
		padding: "0.35rem",
		transition: "all 0.2s ease-in-out"
	},
	denyButton: {
		"&:hover, &$selected": {
			backgroundColor: "var(--color-red-dark)",
			borderColor: "var(--color-red-dark)",
			"&$disabled, &$disabled:hover": {
				cursor: "default",
				pointerEvents: "none"
			}
		},
		color: "var(--color-red-dark)"
	},
	flagButton: {
		"&:hover, &$selected": {
			backgroundColor: "var(--color-orange)",
			borderColor: "var(--color-orange)"
		},
		"&$disabled, &$disabled:hover": {
			backgroundColor: "var(--button-disabled-background-color)",
			color: "var(--button-disabled-font-color)",
			cursor: "default",
			pointerEvents: "none"
		},
		color: "var(--color-orange)"
	},
	listItemIcon: {
		color: "var(--color-grey-light)"
	},
	resourceName: {
		alignItems: "center",
		color: "var(--color-black)",
		display: "flex",
		gap: "0.5rem"
	},
	selected: {},
	disabled: {},
	table: {
		color: "var(--color-grey-dark)",
		width: "100%"
	},
	tableCell: {
		alignItems: "center",
		display: "flex",
		flexDirection: "row",
		height: "100%",
		minHeight: "3rem"
	},
	ticketsCell: {
		display: "flex",
		flexWrap: "wrap",
		gap: "0.5rem"
	}
});
