import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	policyActionsCellContainer: {
		display: "flex",
		minHeight: "120px",
		flexDirection: "column",
		alignItems: "center"
	},
	policyActionsContainer: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		padding: "var(--spacing-x4, 16px) 0px",
		gap: "var(--spacing-x4, 16px)",
		height: "100%"
	},
	policyInfoContainer: {
		display: "flex",
		flexDirection: "column",
		padding: "var(--spacing-x3, 12px)",
		alignItems: "flex-start",
		alignSelf: "stretch"
	}
});
