import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	container: {
		display: "flex",
		flexGrow: 1,
		gap: "var(--spacing-x10, 40px)"
	},
	bodyOrder: {
		display: "grid",
		gridTemplateRows: "auto 1fr"
	},
	tabsHeaderContainer: {
		maxWidth: "800px"
	}
});
