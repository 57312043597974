import React from "react";
import { makeIcon } from "components/ui/Icon";

export const TagIcon = makeIcon(
	<svg viewBox="0 0 16 16" fill="none">
		<path
			d="M8.38334 14.2663C8.11667 14.533 7.8 14.6663 7.43334 14.6663C7.06667 14.6663 6.75 14.533 6.48334 14.2663L1.71667 9.49967C1.46111 9.24412 1.33334 8.93023 1.33334 8.55801C1.33334 8.18579 1.46111 7.8719 1.71667 7.61634L7.58334 1.73301C7.70556 1.61079 7.85 1.51356 8.01667 1.44134C8.18334 1.36912 8.36111 1.33301 8.55 1.33301H13.3167C13.6833 1.33301 13.9972 1.46356 14.2583 1.72467C14.5194 1.98579 14.65 2.29967 14.65 2.66634V7.43301C14.65 7.6219 14.6139 7.79967 14.5417 7.96634C14.4694 8.13301 14.3722 8.27745 14.25 8.39967L8.38334 14.2663ZM11.65 5.33301C11.9278 5.33301 12.1639 5.23579 12.3583 5.04134C12.5528 4.8469 12.65 4.61079 12.65 4.33301C12.65 4.05523 12.5528 3.81912 12.3583 3.62467C12.1639 3.43023 11.9278 3.33301 11.65 3.33301C11.3722 3.33301 11.1361 3.43023 10.9417 3.62467C10.7472 3.81912 10.65 4.05523 10.65 4.33301C10.65 4.61079 10.7472 4.8469 10.9417 5.04134C11.1361 5.23579 11.3722 5.33301 11.65 5.33301ZM7.43334 13.333L13.3167 7.43301V2.66634H8.55L2.66667 8.56634L7.43334 13.333Z"
			fill="currentColor"
		/>
	</svg>
);
