import classNames from "classnames";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IntegrationEntity } from "components/common/entities";
import { Checkbox } from "components/ui/Checkbox";
import { IconPrefix } from "components/ui/IconPrefix";
import { IntegrationIcon } from "components/ui/Icons/IntegrationIcon";
import { TitleBody } from "components/ui/TitleBody";
import { Tooltip } from "components/ui/Tooltip";
import { useStyles } from "./styles";
import { RoleBarTag } from "../RoleBarTag";

type TWithCheckbox = {
	checkboxValue: boolean;
	onToggle: () => void;
};

type TWithoutCheckbox = {
	checkboxValue?: never;
	onToggle?: never;
};

type TSingleIntegrationProps = (TWithCheckbox | TWithoutCheckbox) & {
	name: string;
	imageUrl?: string | null;
	isDeleted?: boolean;
	amount?: never;
	withoutTooltip?: never;
};

type TMultipleIntegrationProps = {
	amount: number;
	imageUrl?: never;
	name?: never;
	isDeleted?: never;
	withoutTooltip?: boolean;
};

type TProps = (TSingleIntegrationProps | TMultipleIntegrationProps) & {
	tags?: string[];
};

export const RoleBarIntegration: FC<TProps> = ({ className, innerRef, tags, withoutTooltip, ...restProps }) => {
	const classes = useStyles();
	const { t } = useTranslation("translation", { keyPrefix: "common.roleBar.roleBarIntegration" });
	const isAmount = "amount" in restProps;
	const amount = restProps.amount || 0;

	const checkbox = useMemo(() => {
		if (isAmount || !restProps.onToggle) return null;
		return <Checkbox selected={restProps.checkboxValue} onClick={restProps.onToggle} />;
	}, [isAmount, restProps]);

	const content = useMemo(() => {
		if (isAmount) {
			if (withoutTooltip) {
				return <IconPrefix Icon={IntegrationIcon} content={amount} />;
			}

			const tooltipContent = (
				<div className={classes.tooltipContainer}>
					<TitleBody size="small" title={t("amount")} body={amount} />
				</div>
			);

			return (
				<Tooltip content={tooltipContent}>
					<IconPrefix Icon={IntegrationIcon} content={amount} />
				</Tooltip>
			);
		}
		const integration = {
			name: restProps.name,
			imageUrl: restProps.imageUrl,
			isDeleted: restProps.isDeleted
		};

		return <IntegrationEntity noWrap relative withIcon size="medium" integration={integration} />;
	}, [
		amount,
		classes.tooltipContainer,
		isAmount,
		withoutTooltip,
		restProps.imageUrl,
		restProps.name,
		restProps.isDeleted,
		t
	]);

	return (
		<div
			className={classNames(classes.container, { [classes.withCheckbox]: "onToggle" in restProps }, className)}
			ref={innerRef}>
			{checkbox}
			<div className={classes.innerContainer}>
				{content}
				{tags ? <RoleBarTag tags={tags} /> : null}
			</div>
		</div>
	);
};
