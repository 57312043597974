import copy from "copy-to-clipboard";
import { useCallback } from "react";

const stringIsValid = (str: string) => typeof str === "string" && str.length > 0;

export const useCopyToClipboard = (text: string) => {
	if (!stringIsValid(text)) {
		throw new Error("useCopyToClipboard requires a valid string");
	}
	return useCallback(() => copy(text), [text]);
};
