import classNames from "classnames";
import React, { ReactNode, useMemo } from "react";
import { requirePropertyOf } from "utils/security";
import { useStyles } from "./styles";

type TEntityLabelProps = {
	content: ReactNode;
	size: "large" | "medium" | "small" | "tiny";
	indicators?: ReactNode[];
	onClick?: React.MouseEventHandler<HTMLDivElement>;
};

export const EntityLabel: FC<TEntityLabelProps> = ({ content, size, indicators, className, onClick }) => {
	const classes = useStyles();

	const indicatorsElements = useMemo(
		() =>
			indicators?.map((indicator, index) => (
				<div
					key={index}
					className={classNames(
						classes.icon,
						requirePropertyOf(classes, size === "medium" || size === "large" ? "large" : "medium")
					)}>
					{indicator}
				</div>
			)),
		[indicators, classes, size]
	);

	return (
		<div className={classNames(classes.labelContainer, className)} onClick={onClick}>
			{content}
			{indicatorsElements}
		</div>
	);
};
