import React from "react";
import { StaticChip } from "components/ui/chips/StaticChip";
import { WebhookIcon } from "components/ui/Icons/WebhookIcon";

export type TWebhookChipVariant = "green" | "red" | "regular";

interface IProps {
	displayName: string;
	variant: TWebhookChipVariant;
}

const WebhookChipComponent: FC<IProps> = ({ displayName, variant }) => {
	return (
		<StaticChip size="large" variant={variant} PrefixIcon={WebhookIcon}>
			{displayName}
		</StaticChip>
	);
};

export const WebhookChip = React.memo(WebhookChipComponent);
