import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	loginPromptPage: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		width: "100%",
		height: "100%",
		top: "0",
		left: "0",
		position: "absolute",
		userSelect: "none",
		overflow: "auto",
		contain: "strict"
	},
	overlays: {
		zIndex: "100",
		width: "100%",
		height: "100%",
		top: "0",
		left: "0",
		position: "absolute",
		pointerEvents: "none",

		"& > *": {
			pointerEvents: "initial"
		}
	},
	loginWrapper: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		position: "relative",

		"& > #loginPromptsContainer-google": {
			position: "absolute",
			zIndex: "20",
			maxWidth: "100%",
			maxHeight: "100%",
			pointerEvents: "none",
			opacity: "1",
			transition: "0.2 opacity var(--transition-easing)",

			"&.hide": {
				opacity: "0",
				pointerEvents: "none"
			},

			"&:not(.hide) > *": {
				pointerEvents: "initial"
			}
		}
	},
	loginBlock: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		borderRadius: "var(--border-radius-rounder)",
		transition: "0.2 opacity var(--transition-easing)",
		padding: "7.5rem 4.5rem",
		background: "rgba(255, 255, 255, 0.9)",
		"&.hide": {
			opacity: "0",
			pointerEvents: "none"
		}
	},
	entitleLogo: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
		margin: "-4rem 0rem 4rem 0rem"
	},
	logo: {
		display: "flex",
		flexDirection: "row",
		padding: "0.8rem",
		alignSelf: "center"
	},
	text: {
		marginInlineStart: "1.5rem",
		color: "var(--text-color)"
	},
	buttons: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		gap: "1.0rem",
		padding: "1.0rem"
	}
});
