import React from "react";

export const EntitleLogo: FC = ({ className }) => (
	<svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M2.66371 16.5314H7.47193C8.61212 17.7769 10.2461 18.5959 12.0598 18.5959C13.8735 18.5959 15.5086 17.7769 16.6488 16.5314H21.457C19.8428 20.0737 16.24 22.5428 12.0598 22.5428C7.87949 22.5428 4.27671 20.0749 2.66248 16.5314H2.66371Z"
			fill="url(#paint0_linear_31_2)"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M21.9988 13.5721V11.6787C21.9988 6.10601 18.5006 1.39999 12.0757 1.39999C6.2209 1.39999 2 5.65497 2 11.9048C2 12.3534 2.02463 13.143 2.07142 13.5733H22L21.9988 13.5721ZM12.0757 5.49851C9.30038 5.49851 7.17146 7.4567 6.86733 9.94172H17.2854C17.0576 7.26846 14.9656 5.49851 12.077 5.49851H12.0757Z"
			fill="#1C1627"
		/>
		<defs>
			<linearGradient
				id="paint0_linear_31_2"
				x1="2.66371"
				y1="19.5371"
				x2="27.2614"
				y2="19.5371"
				gradientUnits="userSpaceOnUse">
				<stop stopColor="#F78764" />
				<stop offset="1" stopColor="#F0D3F7" />
			</linearGradient>
		</defs>
	</svg>
);
