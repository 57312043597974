import { matchSorter } from "match-sorter";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { GroupChip } from "components/ui/chips/GroupChip";
import { UserChip } from "components/ui/chips/UserChip";
import { CalendarIcon } from "components/ui/Icons/CalendarIcon";
import { GroupIcon } from "components/ui/Icons/GroupIcon";
import { UserCircleIcon } from "components/ui/Icons/UserCircleIcon";
import { UsersIcon } from "components/ui/Icons/UsersIcon";
import { WebhookIcon } from "components/ui/Icons/WebhookIcon";
import { IRenderChipParams, MultipleSelect } from "components/ui/MultipleSelect";
import { DirectoryGroupOption } from "components/ui/selectOptions/DirectoryGroupOption";
import { OnCallIntegrationScheduleOption } from "components/ui/selectOptions/OnCallIntegrationScheduleOption";
import { TextOption } from "components/ui/selectOptions/TextOption";
import { UserNodeOption } from "components/ui/selectOptions/UserNodeOption";
import { useCompany } from "hooks/useCompany";
import { useEntities } from "hooks/useEntities";
import { useRankedSort } from "hooks/useRankedSort";
import { ApprovalFlowEntityModel } from "models/ApprovalFlowEntityModel";
import { getNameAndIcon } from "utils/ui/directoryGroups";
import type { TEntityOption } from "utils/entityOptionType";
import type { TOptionComponentProps } from "utils/ui/select";

const TRANSLATION_PREFIX = "pages.workflows.workflowEditor.entitiesSelectInput.";
const SINGLE_ENTITY_TYPES = new Set([
	"Automatic",
	"DirectManager",
	"IntegrationMaintainer",
	"IntegrationOwner",
	"ResourceMaintainer",
	"ResourceOwner",
	"TeamMember"
]);

interface IProps {
	values: ApprovalFlowEntityModel[];
	onChange: (values: TEntityOption[] | null) => void;
	automaticState?: "none" | "disabled" | "enabled";
}

const getOptionLabel = ({ displayName }: TEntityOption) => displayName || "";

const getOptionKey = (option: TEntityOption) => option.identifier || option.displayName || "";

const OnCallIntegrationChip = ({
	onRemove,
	option
}: Pick<IRenderChipParams<TEntityOption>, "noBorder" | "onClick" | "onRemove" | "option" | "stretch">) => {
	return <GroupChip selected onDelete={onRemove} variant="regular" value={option} />;
};

const renderChip = (props: IRenderChipParams<TEntityOption>) => {
	if (props.option.type === "OnCallIntegrationSchedule") {
		return (
			<OnCallIntegrationChip
				key={props.componentKey}
				option={props.option}
				noBorder={props.noBorder}
				onClick={props.onClick}
				onRemove={props.onRemove}
				stretch={props.stretch}
			/>
		);
	}

	let Icon: IconComponent | undefined;
	if (props.option.type === "User" && props.option.identifier) {
		Icon = UserCircleIcon;
	} else if (props.option.type === "Webhook") {
		Icon = WebhookIcon;
	}

	return props.option.type === "User" ? (
		<UserChip selected variant="regular" onDelete={props.onRemove} value={props.option.identifier!} />
	) : (
		<GroupChip selected onDelete={props.onRemove} variant="regular" value={props.option} icon={Icon} />
	);
};

const equality = (a: TEntityOption, b: TEntityOption) => {
	if (!a || !b) {
		return false;
	}
	if (a.identifier && b.identifier) {
		return a.identifier === b.identifier;
	} else {
		return a.type === b.type && a.displayName === b.displayName;
	}
};

const SORT_OPTIONS = {
	keys: [
		{
			key: (item: TEntityOption) => getNameAndIcon(item.displayName ?? "").name,
			threshold: matchSorter.rankings.MATCHES
		},
		(item: TEntityOption) => item.type
	]
};

export const EntitiesSelectInput: FC<IProps> = ({ values: propValues, onChange, className, automaticState }) => {
	const { sort, onInputChange, query } = useRankedSort<TEntityOption>(SORT_OPTIONS);
	const { entities: remoteEntities, selectedItems, loading } = useEntities(query, propValues);
	const { t } = useTranslation();

	const company = useCompany();

	const teamEntities: TEntityOption[] = useMemo(() => {
		if (company?.integratedToHRs.size) {
			const directManager = t(`${TRANSLATION_PREFIX}entityTypes.DirectManager`);
			const teamMember = t(`${TRANSLATION_PREFIX}entityTypes.TeamMember`);

			return [
				{ displayName: directManager, identifier: "DirectManager", type: "DirectManager" },
				{ displayName: teamMember, identifier: "TeamMember", type: "TeamMember" }
			];
		}
		return [];
	}, [company, t]);

	const baseEntities = useMemo(() => {
		const automatic = t(`${TRANSLATION_PREFIX}entityTypes.Automatic`);
		const integrationMaintainer = t(`${TRANSLATION_PREFIX}entityTypes.IntegrationMaintainer`);
		const integrationOwner = t(`${TRANSLATION_PREFIX}entityTypes.IntegrationOwner`);
		const resourceMaintainer = t(`${TRANSLATION_PREFIX}entityTypes.ResourceMaintainer`);
		const resourceOwner = t(`${TRANSLATION_PREFIX}entityTypes.ResourceOwner`);

		const flowEntities: TEntityOption[] = [
			{ displayName: integrationOwner, identifier: "IntegrationOwner", type: "IntegrationOwner" },
			{ displayName: resourceOwner, identifier: "ResourceOwner", type: "ResourceOwner" },
			{ displayName: integrationMaintainer, identifier: "IntegrationMaintainer", type: "IntegrationMaintainer" },
			{ displayName: resourceMaintainer, identifier: "ResourceMaintainer", type: "ResourceMaintainer" }
		];

		if (automaticState !== "none") {
			flowEntities.push({ displayName: automatic, identifier: "Automatic", type: "Automatic" });
		}
		return flowEntities;
	}, [t, automaticState]);

	const baseAndTeamEntities = useMemo(() => baseEntities.concat(teamEntities), [baseEntities, teamEntities]);

	const approvalFlowEntities = useMemo(() => {
		if (!remoteEntities) return null;

		const flowEntities: TEntityOption[] = baseAndTeamEntities.concat(remoteEntities);

		return flowEntities;
	}, [remoteEntities, baseAndTeamEntities]);

	const values = useMemo(() => {
		return (
			(propValues
				?.map(value =>
					baseAndTeamEntities.concat(selectedItems || [])?.find(entity => entity.identifier === value.identifier)
				)
				.filter(Boolean) as TEntityOption[]) || []
		);
	}, [propValues, baseAndTeamEntities, selectedItems]);

	const options = useMemo(
		() => approvalFlowEntities?.filter(entity => !entity.isDeleted || values.includes(entity)) || [],
		[approvalFlowEntities, values]
	);

	const groupBy = useCallback(
		({ type }: TEntityOption) => (!SINGLE_ENTITY_TYPES.has(type) ? t(`${TRANSLATION_PREFIX}entityTypes.${type}`) : ""),
		[t]
	);

	const isOptionDisabled: (option: TEntityOption) => boolean = useCallback(
		({ type, isDeleted }) => {
			if (automaticState === "none") return false;
			if (isDeleted) return true;
			return type === "Automatic" && automaticState === "disabled";
		},
		[automaticState]
	);

	const placeholder = useMemo(
		() =>
			automaticState === "none" ? t(`${TRANSLATION_PREFIX}label.notified`) : t(`${TRANSLATION_PREFIX}label.approvers`),
		[t, automaticState]
	);

	const getOptionRenderer = useCallback((props: TOptionComponentProps<TEntityOption>) => {
		const option = props.option;
		const type = option.type;
		const getTextContent = () => (option.email ? `${option.displayName} - ${option.email}` : option.displayName || "");
		switch (type) {
			case "OnCallIntegrationSchedule":
				return <OnCallIntegrationScheduleOption {...props} />;
			case "User":
				return <UserNodeOption {...props} />;
			case "Webhook":
				return <TextOption {...props} getTextContent={getTextContent} PrefixIcon={WebhookIcon} />;
			case "DirectoryGroup":
				return <DirectoryGroupOption {...props} />;
			default:
				return <TextOption {...props} />;
		}
	}, []);

	const getIconFromGroup = (groupName: string) => {
		if (groupName.toLocaleLowerCase().includes("user")) {
			return UsersIcon;
		} else if (groupName.toLocaleLowerCase().includes("group")) {
			return GroupIcon;
		} else if (groupName.toLocaleLowerCase().includes("schedule")) {
			return CalendarIcon;
		} else {
			return undefined;
		}
	};

	return (
		<MultipleSelect
			className={className}
			getIconForGroup={getIconFromGroup}
			getOptionLabel={getOptionLabel}
			getOptionKey={getOptionKey}
			groupBy={groupBy}
			inputValue={query}
			isOptionDisabled={isOptionDisabled}
			isOptionEqualToValue={equality}
			loading={loading}
			onChange={onChange}
			onInputChange={onInputChange}
			options={options}
			placeholder={placeholder}
			renderChip={renderChip}
			renderOption={getOptionRenderer}
			sort={sort}
			value={values}
		/>
	);
};
