import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	container: {
		display: "flex",
		flexDirection: "column",
		gap: "1rem",
		maxHeight: "100%",
		height: "100%",
		justifyContent: "space-between"
	},
	statistics: {
		alignItems: "center",
		color: "var(--color-grey-dark)",
		display: "flex",
		gap: "0.25rem"
	},
	tableCell: {
		alignItems: "center",
		display: "flex",
		flexDirection: "row",
		height: "100%"
	}
});
