import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	baseSkeleton: {
		animationDelay: "0.5s",
		animationDuration: "2s",
		animationPlayState: "running",
		animationName: "$skeleton-loading",
		animationDirection: "alternate",
		animationIterationCount: "infinite",
		backgroundColor: "var(--color-grey-300)",
		display: "flex"
	},
	skeletonText: {
		extend: "baseSkeleton",
		borderRadius: "12px",
		height: "1em",
		width: "100%"
	},
	skeletonCircle: {
		extend: "baseSkeleton",
		borderRadius: "50%",
		height: "1.25em",
		width: "1.25em"
	},
	skeletonRectangle: {
		extend: "baseSkeleton",
		borderRadius: "4px",
		height: "1.2em",
		width: "100%"
	},
	"@keyframes skeleton-loading": {
		"0%": {
			backgroundColor: "var(--color-grey-200)"
		},
		"50%": {
			backgroundColor: "var(--color-grey-300)"
		},
		"100%": {
			backgroundColor: "var(--color-grey-400)"
		}
	}
});
