import constate from "constate";
import { getApplications } from "api/applications";
import { useFetchedState } from "hooks/useFetchedState";

const useApplications = () => {
	const { data: applications, loadData: loadApplications } = useFetchedState(getApplications);

	return {
		state: { applications },
		actions: { loadApplications }
	};
};

export const [ApplicationsProvider, useApplicationsContext] = constate(useApplications);
