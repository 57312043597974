import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	modal: {
		padding: "12px 12px 20px 32px"
	},
	content: {
		paddingBottom: "52px",
		width: "480px"
	}
});
