import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { REQUESTABLE_OPTIONS, TRequestableOption } from "components/pages/BulkActionsPage/utils";
import { FilterExpression } from "components/ui/filters/FilterExpression";
import { IconPrefix } from "components/ui/IconPrefix";
import { CloseIcon } from "components/ui/Icons/CloseIcon";
import { GrantedIcon } from "components/ui/Icons/GrantedIcon";
import { IntegrationAllowRequestsFilter } from "filters/integration";
import { IntegrationResourceAllowRequestsFilter } from "filters/integrationResource";
import { IntegrationResourceRoleAllowRequestsFilter } from "filters/integrationResourceRole";
import { useFilterFormExpression } from "./filter.hooks";
import type { Constructor } from "types/utilTypes";

type TRequestableFilters =
	| IntegrationAllowRequestsFilter
	| IntegrationResourceAllowRequestsFilter
	| IntegrationResourceRoleAllowRequestsFilter;

type TRequestableFilterProps = {
	defaultHasValue?: boolean;
	filter: TRequestableFilters;
	onChange: (filter: TRequestableFilters | undefined, isValid: boolean) => void;
};

function getFilter(filterName: TRequestableFilters["name"]): Constructor<TRequestableFilters> {
	switch (filterName) {
		case IntegrationAllowRequestsFilter.filterName:
			return IntegrationAllowRequestsFilter;
		case IntegrationResourceAllowRequestsFilter.filterName:
			return IntegrationResourceAllowRequestsFilter;
		default:
			return IntegrationResourceRoleAllowRequestsFilter;
	}
}

type TOption = TRequestableOption | null;

export const RequestableFilterExpression: FC<TRequestableFilterProps> = ({
	className,
	innerRef,
	filter,
	onChange,
	defaultHasValue = false
}) => {
	const { t } = useTranslation("translation", { keyPrefix: "pages.bulkActions.filters" });
	const [hasValue, setHasValue] = useState(defaultHasValue);

	const { clearFilter, removeFilter } = useFilterFormExpression<TRequestableFilters>({
		filterName: filter.name,
		onChange,
		getFilter
	});

	const onClearFilter = useCallback(() => {
		clearFilter(() => setHasValue(false));
	}, [clearFilter]);

	const onRemoveFilter = useCallback(() => {
		removeFilter(() => setHasValue(false));
	}, [removeFilter]);

	const onOptionSelect = useCallback(
		(option: TOption) => {
			if (!filter || !option) return;
			const optionAsBoolean = option === "yes";
			const didRemoveValue = hasValue ? filter.value === optionAsBoolean : false;
			setHasValue(!didRemoveValue);
			onChange(filter.set("value", optionAsBoolean), !didRemoveValue);
		},
		[filter, hasValue, onChange]
	);

	const renderSelected = useCallback(
		(option: TOption) => {
			if (!option) return "";
			return <IconPrefix Icon={option === "yes" ? GrantedIcon : CloseIcon} content={t(`values.${option}`)} />;
		},
		[t]
	);

	return (
		<FilterExpression
			className={className}
			innerRef={innerRef}
			options={REQUESTABLE_OPTIONS}
			onOptionSelect={onOptionSelect}
			onRemoveFilter={onRemoveFilter}
			onReset={onClearFilter}
			selected={hasValue ? (filter.value ? "yes" : "no") : null}
			renderOption={renderSelected}
			title={t(`title.${filter.name}`)}
			type="singleChoice"
		/>
	);
};
