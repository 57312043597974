import constate from "constate";
import { useCallback, useState } from "react";
import { signOut as apiSignOut } from "api/signOut";
import { devLog } from "utils/devtools/devLogging";
import type { TUserRole } from "models/UserModel";

const AUTH_TOKEN_COOKIE_NAME = "entitle_auth_token";

const getAuthCookieExists = () => {
	return typeof document.cookie === "string" && document.cookie.indexOf(AUTH_TOKEN_COOKIE_NAME) !== -1;
};

export const clearAuthCookie = () => {
	if (getAuthCookieExists()) {
		document.cookie = `${AUTH_TOKEN_COOKIE_NAME}=; SameSite=None; Secure; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
	}
};

const setAuthCookie = (token: string) => {
	if (getAuthCookieExists()) {
		clearAuthCookie();
	}
	const expires = new Date(Date.now() + 24 * 60 * 60 * 1000).toUTCString();
	const newAuthCookie = `${AUTH_TOKEN_COOKIE_NAME}=${token}; SameSite=None; Secure; path=/; expires=${expires}`;
	document.cookie = newAuthCookie;
};

interface ITokenData {
	userEmail: string;
	userRole: TUserRole;
	companyId: string;
	companyDomain: string;
	iat: number;
	exp: number;
	aud: string;
}

const useAuth = () => {
	const [isLoggedIn, setIsLoggedIn] = useState<boolean>(getAuthCookieExists());

	const signIn = useCallback((token: string) => {
		setAuthCookie(token);
		setIsLoggedIn(true);
	}, []);

	const signOut = useCallback(async () => {
		try {
			await apiSignOut();
		} catch (_error) {
			/* We are okay with sign out fail, might mean the user is already signed out */
		} finally {
			setIsLoggedIn(false);
			clearAuthCookie();
			window.location.reload();
		}
	}, []);

	const getTokenData = useCallback(() => {
		if (!getAuthCookieExists()) {
			return null;
		}
		const authToken = document.cookie
			.split("; ")
			.filter(c => c.indexOf(AUTH_TOKEN_COOKIE_NAME) !== -1)
			.at(0)
			?.substring(AUTH_TOKEN_COOKIE_NAME.length + 1);
		if (!authToken) return null;

		const authTokenDataPart = authToken.split(".").at(1);
		if (!authTokenDataPart) return null;

		try {
			const authTokenData = JSON.parse(window.atob(authTokenDataPart)) as ITokenData;
			return authTokenData;
		} catch (error) {
			devLog({ message: "Couldn't parse auth token", extra: { authToken, error }, level: "error" });
			return null;
		}
	}, []);

	return { isLoggedIn, signIn, signOut, getTokenData };
};

export const [AuthProvider, useAuthContext] = constate(useAuth);
