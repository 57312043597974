import { Record } from "immutable";
import { TApprovalAlgorithmRuleDuration } from "../utils/durationsOptions";

const defaults = {
	approvalFlowId: "",
	directoryGroupIds: [] as string[],
	onCallIntegrationScheduleIds: [] as string[],
	duration: null as TApprovalAlgorithmRuleDuration,
	id: "",
	anyOnCallIntegrationSchedules: false,
	sortOrder: 0
};

export interface IApprovalAlgorithmRuleSchema {
	approvalFlowId: string;
	directoryGroupIds: string[] | null;
	onCallIntegrationScheduleIds: string[] | null;
	anyOnCallIntegrationSchedules: boolean;
	duration: TApprovalAlgorithmRuleDuration;
	id: string | null;
	sortOrder: number;
}

export class ApprovalAlgorithmRuleModel extends Record<typeof defaults>(defaults) {
	static fromServerData(data: unknown) {
		const {
			directoryGroupIds,
			approvalFlowId,
			id,
			sortOrder,
			duration,
			onCallIntegrationScheduleIds,
			anyOnCallIntegrationSchedules
		} = data as typeof defaults;
		return new ApprovalAlgorithmRuleModel({
			approvalFlowId,
			onCallIntegrationScheduleIds,
			directoryGroupIds,
			duration,
			id,
			sortOrder,
			anyOnCallIntegrationSchedules
		});
	}

	toServerData(): IApprovalAlgorithmRuleSchema {
		const {
			id,
			directoryGroupIds,
			onCallIntegrationScheduleIds,
			approvalFlowId,
			sortOrder,
			duration,
			anyOnCallIntegrationSchedules
		} = this;
		return {
			approvalFlowId,
			directoryGroupIds: directoryGroupIds || null,
			onCallIntegrationScheduleIds,
			duration,
			id,
			sortOrder,
			anyOnCallIntegrationSchedules
		};
	}
}
