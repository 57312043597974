import classNames from "classnames";
import React from "react";
import { Typography } from "components/ui/Typography";
import { useStyles } from "./styles";

const parseProgressPercentage = (percentage: number) => {
	let parsedPercentage = Math.min(percentage, 100);
	parsedPercentage = Math.max(parsedPercentage, 0);
	parsedPercentage = Math.round(parsedPercentage);

	return `${parsedPercentage}%`;
};

interface IProps {
	width?: string | number;
	percentage: number;
	backgroundColor?: string;
	loaderColor?: string;
}

export const ProgressBar: FC<IProps> = ({ backgroundColor, loaderColor, width, className, percentage }) => {
	const parsedWidth = `calc(${width} - 5ch)`;
	const parsedPercentage = parseProgressPercentage(percentage);
	const classes = useStyles({ width: parsedWidth, backgroundColor, loaderWidth: parsedPercentage, loaderColor });

	return (
		<div className={classNames(className, classes.progressBarContainer)}>
			<div className={classes.progressBar}>
				<div className={classes.progressBarLoader}></div>
			</div>
			<Typography noWrap variant="h2_sb">
				{parsedPercentage}
			</Typography>
		</div>
	);
};
