import React from "react";
import { useTranslation } from "react-i18next";
import { Divider } from "components/ui/Divider";
import { WarningIcon } from "components/ui/Icons/WarningIcon";
import { TitleBody } from "components/ui/TitleBody";
import { useStyles } from "./styles";

const TRANSLATION_PREFIX = "pages.auditLog.integrationLogs.externalAuditLogsNotConnected";

export const ExternalLogsNotConnected: FC = () => {
	const classes = useStyles();
	const { t } = useTranslation("translation", { keyPrefix: TRANSLATION_PREFIX });

	return (
		<div className={classes.externalLogsNotConnectedWrapper}>
			<WarningIcon className={classes.icon} size={40} />
			<Divider vertical rounded width={2} color={"var(--color-black)"} />
			<TitleBody size="medium" title={t("title")} body={t("body")} />
		</div>
	);
};
