import React from "react";
import { Route, Routes } from "react-router-dom";
import { TeamsNewTicketPage } from "components/microsoftTeamsPages/NewTicketPage";
import { TeamsProfilePage } from "components/microsoftTeamsPages/userProfilePage";

export const MicrosoftTeamsRouter: FC = ({ children }) => (
	<Routes>
		<Route path="/microsoftTeams/newRequest" element={<TeamsNewTicketPage />} />
		<Route path="/microsoftTeams/profile" element={<TeamsProfilePage />} />
		<Route path="/*" element={children} />
	</Routes>
);
