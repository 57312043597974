import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	bold: {
		"&$bold": { fontWeight: "var(--typography-font-weight-bold)" }
	},
	content: {
		"&.deleted": {
			"&::after": {
				color: "var(--color-red)",
				content: "'*'"
			}
		},
		"&.prefix > *:first-child": {
			marginRight: "0.55rem"
		},
		alignItems: "center",
		flexDirection: "row"
	},
	entity: {
		alignItems: "center",
		display: "inline-flex",
		gap: "0.5rem"
	}
});
