import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	comment: {
		paddingLeft: "1rem",
		"&:hover": {
			cursor: "text"
		}
	},
	fullCell: {
		display: "flex",
		alignItems: "center",
		height: "100%",
		width: "100%"
	},
	fullHeight: {
		height: "100%"
	}
});
