import React from "react";
import { Typography } from "components/ui/Typography";
import { useStyles } from "./styles";

type TProps = { onClick: () => void; prefix: React.ReactNode };

export const SelectionOption: FC<TProps> = ({ children, onClick, prefix }) => {
	const classes = useStyles();

	return (
		<div className={classes.option} onClick={onClick}>
			{prefix && <div className={classes.prefix}>{prefix}</div>}
			<Typography noWrap variant="text_reg">
				{children}
			</Typography>
		</div>
	);
};
