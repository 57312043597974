import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Chip } from "components/ui/chips/Chip";
import { FilterExpression } from "components/ui/filters/FilterExpression";
import { IntegrationIcon } from "components/ui/Icons/IntegrationIcon";
import { ResourcesIcon } from "components/ui/Icons/ResourcesIcon";
import { RoleIcon } from "components/ui/Icons/RoleIcon";
import { IntegrationNameFilter } from "filters/integration";
import { IntegrationResourceIntegrationNameFilter, IntegrationResourceNameFilter } from "filters/integrationResource";
import {
	IntegrationResourceRoleIntegrationNameFilter,
	IntegrationResourceRoleIntegrationResourceNameFilter,
	IntegrationResourceRoleNameFilter
} from "filters/integrationResourceRole";
import { useFilterFormExpression } from "./filter.hooks";
import type { TFilterOperator } from "types/filters";
import type { Constructor } from "types/utilTypes";

type TNameFilters =
	| IntegrationNameFilter
	| IntegrationResourceIntegrationNameFilter
	| IntegrationResourceNameFilter
	| IntegrationResourceRoleNameFilter
	| IntegrationResourceRoleIntegrationResourceNameFilter
	| IntegrationResourceRoleIntegrationNameFilter;

type TNameFilterProps = {
	filter: TNameFilters;
	onChange: (filter: TNameFilters | undefined, isValid: boolean) => void;
};

function getFilter(filterName: TNameFilters["name"]): Constructor<TNameFilters> {
	switch (filterName) {
		case IntegrationNameFilter.filterName:
			return IntegrationNameFilter;
		case IntegrationResourceIntegrationNameFilter.filterName:
			return IntegrationResourceIntegrationNameFilter;
		case IntegrationResourceNameFilter.filterName:
			return IntegrationResourceNameFilter;
		case IntegrationResourceRoleNameFilter.filterName:
			return IntegrationResourceRoleNameFilter;
		case IntegrationResourceRoleIntegrationResourceNameFilter.filterName:
			return IntegrationResourceRoleIntegrationResourceNameFilter;
		default:
			return IntegrationResourceRoleIntegrationNameFilter;
	}
}

const isValidOperator = (operator: TFilterOperator): operator is TNameFilters["operator"] =>
	operator === "contains" || operator === "notContains";

const OPERATORS = ["contains", "notContains"] as TFilterOperator[];

export const NameFilterExpression: FC<TNameFilterProps> = ({ className, innerRef, filter, onChange }) => {
	const { t } = useTranslation("translation", { keyPrefix: "pages.bulkActions.filters" });

	const { clearFilter, removeFilter } = useFilterFormExpression<TNameFilters>({
		filterName: filter.name,
		onChange,
		getFilter
	});

	const onOperatorSelect = useCallback(
		(operator: TFilterOperator) => {
			if (!filter) return;
			if (!isValidOperator(operator)) return;
			onChange(filter.set("operator", operator), filter.value.length > 0);
		},
		[filter, onChange]
	);

	const onOptionSelect = useCallback(
		(option: string) => {
			if (!filter || !option) return;
			const currentValue = filter.value;
			const newValue = currentValue.includes(option) ? currentValue : [...currentValue, option];
			onChange(filter.set("value", newValue), newValue.length > 0);
		},
		[filter, onChange]
	);

	const onDeleteOption = useCallback(
		(option: string) => {
			if (!filter || !option) return;
			const newValue = filter.value.filter(value => value !== option);
			onChange(filter.set("value", newValue), newValue.length > 0);
		},
		[filter, onChange]
	);

	const renderSelected = useCallback(
		(option: string) => {
			let icon = IntegrationIcon;
			if (filter.name === IntegrationResourceRoleNameFilter.filterName) {
				icon = RoleIcon;
			}
			if (
				filter.name === IntegrationResourceNameFilter.filterName ||
				filter.name === IntegrationResourceRoleIntegrationResourceNameFilter.filterName
			) {
				icon = ResourcesIcon;
			}
			return (
				<Chip size="large" PrefixIcon={icon} selected onDelete={() => onDeleteOption(option)}>{`"${option}"`}</Chip>
			);
		},
		[filter.name, onDeleteOption]
	);

	return (
		<FilterExpression
			className={className}
			innerRef={innerRef}
			inputPlaceholder={t("placeholders.text")}
			onOperatorSelect={onOperatorSelect}
			onOptionSelect={onOptionSelect}
			onRemoveFilter={removeFilter}
			onReset={clearFilter}
			operators={OPERATORS}
			renderSelected={renderSelected}
			selected={filter.value}
			selectedOperator={filter.operator}
			title={t(`title.${filter.name}`)}
			type="multiText"
		/>
	);
};
