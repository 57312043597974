import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	filters: {
		display: "flex",
		flexWrap: "wrap",
		gap: "var(--spacing-x3, 12px)",
		width: "100%"
	}
});
