import constate from "constate";
import { useSubscriber } from "hooks/useSubscriber";

const useTicketNotifications = () => {
	const { addSubscription, removeSubscription, notify } = useSubscriber();

	return {
		notifyTicketUpdate: notify,
		unsubscribeTicketUpdates: removeSubscription,
		subscribeTicketUpdates: addSubscription
	};
};

export const [TicketUpdatesProvider, useTicketUpdatesContext] = constate(useTicketNotifications);
