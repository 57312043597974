import { List, Record } from "immutable";
import { useCallback, useMemo, useState } from "react";
import { ApprovalAlgorithmModel } from "models/ApprovalAlgorithmModel";
import { IntegrationModel } from "models/IntegrationModel";
import { IntegrationResourceModel } from "models/IntegrationResourceModel";
import { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";
import { TTicketDuration } from "utils/durationsOptions";

export type BundleItem = {
	integration: IntegrationModel | null;
	resource: IntegrationResourceModel | null;
	role: IntegrationResourceRoleModel | null;
	id?: string;
};
const NewBundleItem = Record<BundleItem>({ integration: null, resource: null, role: null });
export type BundleData = {
	bundleItems?: List<Record<BundleItem>>;
	category?: string | null;
	approvalAlgorithm?: ApprovalAlgorithmModel | null;
	allowedDurations?: List<TTicketDuration> | null;
	name?: string;
	id?: string;
	description: string | null;
	tags: string[] | null;
};
export const useBundleFormState = (initialBundle?: BundleData) => {
	const [category, setCategory] = useState(initialBundle?.category || null);
	const [name, setName] = useState(initialBundle?.name);
	const [description, setDescription] = useState(initialBundle?.description || null);
	const [tags, setTags] = useState(initialBundle?.tags || []);
	const [approvalAlgorithm, setApprovalAlgorithm] = useState(initialBundle?.approvalAlgorithm || null);
	const [bundleItems, setBundleItems] = useState(initialBundle?.bundleItems || List([]));
	const [allowedDurations, setAllowedDurations] = useState(List<TTicketDuration>());
	const [overrideAllowedDurations, setOverrideAllowedDurations] = useState(Boolean(initialBundle?.allowedDurations));
	const addNewBundleItem = useCallback(() => {
		setBundleItems(bundleItems.push(NewBundleItem()));
	}, [bundleItems]);

	const removeBundleItem = useCallback(
		(index: number) => {
			setBundleItems(bundleItems.removeIn([index]));
		},
		[bundleItems]
	);

	const state = useMemo(
		() => ({
			category,
			name,
			description,
			tags,
			approvalAlgorithm,
			bundleItems,
			id: initialBundle?.id,
			allowedDurations,
			overrideAllowedDurations
		}),
		[
			allowedDurations,
			approvalAlgorithm,
			bundleItems,
			category,
			description,
			initialBundle?.id,
			name,
			overrideAllowedDurations,
			tags
		]
	);
	return {
		state,
		actions: {
			setCategory,
			setName,
			setDescription,
			setTags,
			setApprovalAlgorithm,
			setBundleItems,
			setAllowedDurations,
			setOverrideAllowedDurations,
			addNewBundleItem,
			removeBundleItem
		}
	};
};
