import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useStepperContext } from "components/common/Stepper/stepperContext";
import { Button } from "components/ui/Button";
import { EditIcon } from "components/ui/Icons/EditIcon";

type TProps = {
	stepIndex: number;
};

export const EditButton: FC<TProps> = ({ className, innerRef, stepIndex }) => {
	const { t } = useTranslation();

	const {
		actions: { goToStep }
	} = useStepperContext();

	const handleClick = useCallback(() => {
		goToStep(stepIndex);
	}, [goToStep, stepIndex]);

	return (
		<Button
			size="small"
			prefix={<EditIcon size={12} />}
			className={className}
			onClick={handleClick}
			innerRef={innerRef}>
			{t("buttons.edit")}
		</Button>
	);
};
