import React from "react";
import { useCompanyContext } from "context/companyContext";
import { CompanyModel } from "models/CompanyModel";
import { HRIntegration } from "./components/HRIntegration";
import type { THRType } from "api/companyExternalIntegrations";

const hrIntegrations: THRType[] = ["BambooHR", "Hibob", "Freshteam", "Workday"];

export const HRIntegrations: FC<{
	company: CompanyModel;
}> = ({ company }) => {
	const {
		actions: { integrateWithHrSystem }
	} = useCompanyContext();

	return (
		<>
			{hrIntegrations.map(name => (
				<HRIntegration
					key={name}
					integration={name}
					integratedToHRs={company.integratedToHRs}
					afterIntegrate={integrateWithHrSystem}
				/>
			))}
		</>
	);
};
