import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getIntegrationAuditLogs } from "api/integrationAuditLogs";
import { CommonAccordion } from "components/common/CommonAccordion";
import { IntegrationAuditLogContent } from "components/pages/AuditLogsPage/components/AuditLogContent/IntegrationAuditLogContent";
import { AuditLogsIcon } from "components/ui/Icons/AuditLogsIcon";
import { Typography } from "components/ui/legacy/Typography";
import { LoadingDots } from "components/ui/LoadingDots";
import { PageSelect } from "components/ui/PageSelect";
import { Table } from "components/ui/Table";
import { useMultiUsers } from "hooks/useMultiUsers";
import { DESC, usePagination } from "hooks/usePagination";
import { IntegrationModel } from "models/IntegrationModel";
import { dateToShortText } from "utils/dates/date";
import { IPaginatedSearchOptions } from "utils/searchUtils";
import { useStyles } from "./styles";

const PAGE_SIZE = 10;
const TRANSLATION_PREFIX = "pages.integration.auditLogsBlock";

interface IProps {
	integration: IntegrationModel;
}

export const AuditLogsBlock: FC<IProps> = ({ integration }) => {
	const fetchAuditLogs = useCallback(
		(paginationOptions: IPaginatedSearchOptions) =>
			(integration && getIntegrationAuditLogs(integration.id, paginationOptions)) || Promise.resolve([]),
		[integration]
	);

	const classes = useStyles();
	const { t } = useTranslation();
	const headers: string[] = [t(`${TRANSLATION_PREFIX}.description`), t(`${TRANSLATION_PREFIX}.createdAt`)];
	const { totalPages, currentPageNumber, page, setCurrentPageNumber, isLoading } = usePagination({
		fetchItems: fetchAuditLogs,
		perPage: PAGE_SIZE,
		sortOrder: DESC,
		initialFilter: null
	});
	const auditLogUserIds = useMemo(() => page?.map(auditLog => auditLog.userId).toArray() || [], [page]);
	const auditLogUsers = useMultiUsers(auditLogUserIds);

	return (
		<CommonAccordion
			title={
				<Typography variant="h3" prefixIcon={<AuditLogsIcon size="large" />}>
					{t(`${TRANSLATION_PREFIX}.title`)}
				</Typography>
			}>
			<Table gridColumns="8fr 2fr">
				<Table.Row>
					{headers.map(title => (
						<Table.Header key={title}>
							<Typography>{title}</Typography>
						</Table.Header>
					))}
				</Table.Row>
				{isLoading ? (
					<LoadingDots center />
				) : (
					page!.map(auditLog => {
						const logUser = auditLog.userId ? auditLogUsers?.get(auditLog.userId) : undefined;

						return (
							<Table.Row key={auditLog.id}>
								<Table.Cell className={classes.cell}>
									<IntegrationAuditLogContent auditLog={auditLog} logUser={logUser} />
								</Table.Cell>
								<Table.Cell>{dateToShortText(auditLog.createdAt, "local")}</Table.Cell>
							</Table.Row>
						);
					})
				)}
			</Table>
			{totalPages > 1 && (
				<PageSelect
					changePage={setCurrentPageNumber}
					currentPageNumber={currentPageNumber}
					pagesCount={totalPages}
					pagesShownAmount={3}
				/>
			)}
		</CommonAccordion>
	);
};
