import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	actionsCell: {
		display: "flex",
		width: "100%",
		justifyContent: "center",
		gap: "var(--spacing-x3, 12px)",
		padding: "var(--spacing-x1, 4px) var(--spacing-x3, 12px)"
	}
});
