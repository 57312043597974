import classNames from "classnames";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { DefaultErrorBoundary } from "components/App/components/ErrorBoundary";
import { TransitionablePage } from "components/templates/TransitionablePage";
import { Typography } from "components/ui/legacy/Typography";
import { useOverlaysContext } from "context/overlaysContext";
import { usePageContext } from "context/pageContext";
import { useStyles } from "./styles";

interface IProps {
	subPage?: boolean;
}

interface IContentProps {
	noBorder?: boolean;
	noBackground?: boolean;
}

interface PageTemplateChildren {
	Title: FC;
	Content: FC<IContentProps>;
}

const PageGlobalOverlays: FC = ({ children, className }) => {
	const classes = useStyles();
	const {
		actions: { setPageOverlays }
	} = useOverlaysContext();

	return (
		<div
			className={classNames(classes.overlays, className)}
			ref={ref => {
				if (ref) {
					setPageOverlays(ref);
				}
			}}>
			{children}
		</div>
	);
};

const InnerContext = React.createContext<{ scrollableId?: string; subPage?: boolean }>({});

export const PageTemplate: FC<IProps> & PageTemplateChildren = ({ className, children, subPage = false }) => {
	const classes = useStyles();
	const [scrollableId, setScrollableId] = useState("");
	const pageContext = usePageContext();

	useEffect(() => {
		pageContext.setSubPage(subPage);
	}, [subPage, pageContext]);

	useEffect(() => {
		// we want the page to only accept the first scrollableId
		if (pageContext.scrollableId && (!scrollableId || scrollableId === "")) {
			setScrollableId(pageContext.scrollableId);
		}
	}, [subPage, pageContext, scrollableId]);

	const innerContextValue = useMemo(() => ({ scrollableId, subPage }), [scrollableId, subPage]);

	return (
		<ErrorBoundary FallbackComponent={DefaultErrorBoundary}>
			<PageGlobalOverlays />
			<TransitionablePage id={scrollableId} className={classNames(classes.page, className, { [classes.sub]: subPage })}>
				<InnerContext.Provider value={innerContextValue}>{children}</InnerContext.Provider>
			</TransitionablePage>
		</ErrorBoundary>
	);
};

const PageTemplateTitle: FC = ({ children, className }) => {
	const classes = useStyles();
	return (
		<Typography component="div" className={classNames(classes.title, className)}>
			{children}
		</Typography>
	);
};

PageTemplate.Title = PageTemplateTitle;

const PageTemplateContent: FC<IContentProps> = ({ children, className, noBorder = false, noBackground = false }) => {
	const classes = useStyles();
	const { scrollableId, subPage } = useContext(InnerContext);

	return (
		<div
			id={subPage ? undefined : scrollableId}
			className={classNames(classes.content, className, {
				[classes.noBorder]: noBorder,
				[classes.noBackground]: noBackground
			})}>
			{children}
		</div>
	);
};

PageTemplate.Content = PageTemplateContent;
