import { List, Record } from "immutable";
import { TFilterType } from "./AccessReviewTemplateModel";

const defaults = {
	id: "",
	filterType: "exclude" as TFilterType,
	resourceIds: List<string>(),
	integrationId: ""
};

type TAccessReviewTemplateIntegrationRuleModel = typeof defaults;

export class AccessReviewTemplateIntegrationRuleModel extends Record<TAccessReviewTemplateIntegrationRuleModel>(
	defaults
) {
	static fromServerData(data: unknown): AccessReviewTemplateIntegrationRuleModel {
		const { id, filterType, resourceIds, integrationId } = data as TAccessReviewTemplateIntegrationRuleModel;
		return new AccessReviewTemplateIntegrationRuleModel({
			id,
			filterType,
			resourceIds: List(resourceIds),
			integrationId
		});
	}
}
