import { createUseStyles } from "react-jss";
import { BORDER_SIZE } from "../constants";
import { INTEGRATION_NODE_WIDTH } from "../IntegrationNode/styles";

export const useStyles = createUseStyles({
	alignedContent: {
		alignItems: "center",
		display: "flex",
		flexDirection: "row",
		gap: "4px",
		minWidth: 0
	},
	actionsContainer: {
		minWidth: "fit-content"
	},
	container: {
		alignItems: "center",
		backgroundColor: "var(--color-blue-200)",
		borderBottom: `${BORDER_SIZE}px solid var(--color-blue-500)`,
		color: "var(--color-black)",
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
		height: "36px",
		minWidth: 0,
		width: `calc(${INTEGRATION_NODE_WIDTH}px - ${BORDER_SIZE * 2}px)`,
		"&:last-child": {
			borderBottom: "none",
			borderRadius: "0px 0px 16px 16px",
			height: "33px"
		}
	},
	contentContainer: {
		minWidth: 0,
		padding: "8px 12px",
		width: "100%"
	},
	contentWithActions: {
		alignItems: "center",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between"
	}
});
