import { createUseStyles } from "react-jss";
import { ENTITY_NODE_HEIGHT } from "components/common/entities/Nodes/EntityNode/styles";
import { SPACING_X3 } from "globalStylesVariables";

export const ACTOR_HEIGHT = ENTITY_NODE_HEIGHT + SPACING_X3;

export const useStyles = createUseStyles({
	modal: {
		maxWidth: "484px"
	},
	content: {
		alignItems: "flex-start",
		display: "flex",
		flexDirection: "column",
		gap: "var(--spacing-x10, 40px)",
		padding: "0 var(--spacing-x3, 12px) var(--spacing-x12, 48px) var(--spacing-x3, 12px)"
	},
	inputAndList: {
		alignItems: "flex-start",
		alignSelf: "stretch",
		display: "flex",
		flexDirection: "column",
		gap: "var(--spacing-x5, 20px)"
	},
	actorList: {
		height: ({ actorListHeight }: { actorListHeight: number }) => `${actorListHeight}px`,
		width: "100%",
		overflowY: "auto"
	},
	accountNode: {
		marginBottom: "var(--spacing-x3, 12px)"
	}
});
