import type { TTicketDuration } from "./durationsOptions";

interface INewTicketUrlParams {
	bundleIds?: string[];
	roles?: { id: string }[];
	duration?: TTicketDuration;
	justification?: string;
	ticketingIntegrationTicketId?: string;
}

const legacyFieldsParser = (urlParams: URLSearchParams): INewTicketUrlParams | null => {
	const roleId = urlParams.get("roleId") ?? null;
	const bundleId = urlParams.get("bundleId") ?? null;
	if (!roleId && !bundleId) return null;

	return {
		duration: Number(urlParams.get("duration") || "0") as TTicketDuration,
		justification: urlParams.get("justification") ?? "",
		ticketingIntegrationTicketId: urlParams.get("ticketingIntegrationTicketId") ?? "",
		roles: roleId ? [{ id: roleId }] : undefined,
		bundleIds: bundleId ? [bundleId] : undefined
	};
};

export const urlFieldsParser = (urlParams: URLSearchParams): INewTicketUrlParams | null => {
	const legacyData = legacyFieldsParser(urlParams);
	if (legacyData) return legacyData;

	const dataBase64 = urlParams.get("data");
	if (!dataBase64) return null;

	const data = dataBase64 ? JSON.parse(Buffer.from(dataBase64, "base64").toString("utf-8")) : {};
	const roles = data.roleIds || data.roles;

	const bundleIds = data.bundleIds;

	return {
		duration: Number(data.duration || "0") as TTicketDuration,
		justification: data.justification ?? "",
		ticketingIntegrationTicketId: data.ticketingIntegrationTicketId,
		roles,
		bundleIds
	};
};
