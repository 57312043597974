import { createUseStyles } from "react-jss";

interface IStyleProps {
	width?: string;
	backgroundColor?: string;
	loaderWidth: string;
	loaderColor?: string;
}

export const useStyles = createUseStyles({
	progressBarContainer: {
		display: "flex",
		justifyContent: "start",
		alignItems: "center",
		gap: "var(--spacing-x3)",
		height: "30px"
	},
	progressBar: ({ backgroundColor, loaderColor, loaderWidth, width }: IStyleProps) => ({
		height: "12px",
		width: width ?? "100%",
		background: backgroundColor ?? "var(--color-purple-300)",
		borderRadius: "10px",
		"& > $progressBarLoader": {
			height: "96%",
			borderRadius: "10px",
			width: loaderWidth,
			transition: "width 0.5s",
			background: loaderColor ?? "var(--color-purple-600)"
		}
	}),
	progressBarLoader: {}
});
