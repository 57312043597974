import classNames from "classnames";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { RequestCart } from "components/common/RequestCart";
import { StaticChip } from "components/ui/chips/StaticChip";
import { Section } from "components/ui/Section";
import { Typography } from "components/ui/Typography";
import { useRequestCartData } from "./selectedTargetsSection.hooks";
import { useStyles } from "./styles";

export const SelectedTargetsSection: FC = ({ className, innerRef }) => {
	const { t } = useTranslation("translation", { keyPrefix: "pages.newRequest.selectRolesStep.selectedTargetsSection" });
	const { t: globalTranslation } = useTranslation();
	const classes = useStyles();

	const { cart, cartItems, loadingCartItems, removeAppFromCart, removeResource, removeRole } = useRequestCartData();

	const title = useMemo(() => {
		return (
			<>
				<Typography variant="body_sb">{t("title")}</Typography>
				<StaticChip size="small" variant="regular">
					{globalTranslation("number", { value: cart.size })}
				</StaticChip>
			</>
		);
	}, [cart.size, t, globalTranslation]);

	return (
		<Section className={classNames(classes.container, className)} innerRef={innerRef} title={title} noDivider>
			<RequestCart
				cart={cart}
				cartItems={cartItems}
				loadingCartItems={loadingCartItems}
				onRemoveResource={removeResource}
				onRemoveRole={removeRole}
				removeAppFromCart={removeAppFromCart}
			/>
		</Section>
	);
};
