import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { getBundles } from "api/accessRequestForm";
import { type IRenderChipParams, MultipleSelect } from "components/ui/MultipleSelect";
import { BundleOption } from "components/ui/selectOptions/BundleOption";
import { useSelectSearchProps } from "hooks/useSelectSearchProps";
import { SearchChip } from "../../../NewTicketBySearchForm/components/SearchChip";
import type { TOptionComponent } from "utils/ui/select";
import type { TFullBundle } from "../../../../types";

const LIMIT = 100;

interface IProps {
	userId: string;
	isBundleConflicted: (bundle: TFullBundle) => boolean;
	bundleCategories: string[] | null;
	setBundles: (value: TFullBundle[] | null) => void;
	bundles: TFullBundle[];
}

const getBundleLabel = (bundle: TFullBundle) => bundle.name;
const equalizeBundles = (a: TFullBundle, b: TFullBundle) => a.id === b.id;

export const BundlesInput: FC<IProps> = ({ userId, bundleCategories, setBundles, bundles, isBundleConflicted }) => {
	const { t } = useTranslation(undefined, { keyPrefix: "pages.newTicket.newTicketForm.bundlesInput" });

	const fetchBundles = useCallback(
		async (query: string) => {
			const response = await getBundles({
				userId,
				bundleCategories: bundleCategories || undefined,
				search: query,
				perPage: LIMIT
			});
			return response.result.toArray() as TFullBundle[];
		},
		[bundleCategories, userId]
	);

	const { selectProps } = useSelectSearchProps(fetchBundles, bundles, true);

	const renderChip = useCallback((params: IRenderChipParams<TFullBundle>) => {
		const { option, ...rest } = params;
		return <SearchChip {...rest} option={{ type: "bundle", value: option }} />;
	}, []);

	return (
		<MultipleSelect
			{...selectProps}
			chipsLimit={3}
			filter={null}
			getOptionLabel={getBundleLabel}
			isOptionDisabled={isBundleConflicted}
			isOptionEqualToValue={equalizeBundles}
			label={t("label")}
			limit={LIMIT}
			limitChipType="bundle"
			onChange={setBundles}
			renderOption={BundleOption as TOptionComponent<TFullBundle>}
			placeholder={t("placeholder")}
			renderChip={renderChip}
			required
			sort={null}
			value={bundles}
		/>
	);
};
