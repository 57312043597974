import { Record } from "immutable";

const defaults = {
	attribute: "",
	companyId: "",
	createdAt: new Date(0),
	id: "",
	updatedAt: new Date(0),
	userId: "",
	value: ""
};

export class UserAttributeModel extends Record<typeof defaults>(defaults) {
	static fromServerData(data: unknown) {
		const { attribute, companyId, createdAt, id, updatedAt, userId, value } = data as typeof defaults;
		return new UserAttributeModel({
			attribute,
			companyId,
			createdAt: new Date(createdAt),
			id,
			updatedAt: new Date(updatedAt),
			userId,
			value
		});
	}
}
