import { Set } from "immutable";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FilterExpressionEmptyState } from "components/common/FilterExpressionEmptyState";
import { IntegrationChip } from "components/ui/chips/IntegrationChip";
import { FilterExpression } from "components/ui/filters/FilterExpression";
import { IntegrationIcon } from "components/ui/Icons/IntegrationIcon";
import { IntegrationOption } from "components/ui/selectOptions/IntegrationOption";
import { TIntegrationIdFilter, OPERATORS } from "filters/integration/integrationIdFilter";
import { useIntegrations } from "hooks/useIntegrations";
import { IntegrationModel } from "models/IntegrationModel";
import { notEmpty } from "utils/comparison";
import type { TFilterOperator } from "types/filters";

type TOperator = (typeof OPERATORS)[number];
type TIntegrationIdFilterExpressionProps = {
	updateFilter: (condition: TIntegrationIdFilter) => unknown;
	filter: TIntegrationIdFilter;
};
export const IntegrationIdFilterExpression: FC<TIntegrationIdFilterExpressionProps> = ({
	className,
	innerRef,
	updateFilter,
	filter
}) => {
	const { t } = useTranslation();
	const integrations = useIntegrations(true);
	const [query, setQuery] = useState<string>();
	const [selectedOperator, setSelectedOperator] = useState<TOperator>(filter.operator);

	const availableIntegrations = useMemo(
		() =>
			integrations
				?.toIndexedSeq()
				.toArray()
				.filter(integration => !integration.isDeleted)
				.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())) ?? [],
		[integrations]
	);
	const options = useMemo(
		() =>
			availableIntegrations.filter(
				integration => !query || integration.name.toLowerCase().trim().includes(query.toLowerCase().trim())
			) ?? [],
		[availableIntegrations, query]
	);
	const normalizedCondition = useMemo(
		() => ({ operator: selectedOperator, value: Set(filter.value) }),
		[filter, selectedOperator]
	);
	const onReset = useCallback(() => updateFilter({ operator: "is", value: [] }), [updateFilter]);

	useEffect(() => {
		setSelectedOperator(filter.operator);
	}, [filter.operator]);

	const removeSelectedIntegration = useCallback(
		(integration: IntegrationModel) => {
			if (normalizedCondition.value.size === 1) {
				onReset();
			} else {
				updateFilter({
					...normalizedCondition,
					value: normalizedCondition.value.delete(integration.id).toArray()
				});
			}
		},
		[normalizedCondition, updateFilter, onReset]
	);

	const toggleIntegrationSelection = useCallback(
		(integration: IntegrationModel) => {
			if (normalizedCondition.value.has(integration.id)) return removeSelectedIntegration(integration);
			updateFilter({
				...normalizedCondition,
				value: normalizedCondition.value.add(integration.id).toArray()
			});
		},
		[normalizedCondition, updateFilter, removeSelectedIntegration]
	);

	const renderSelected = useCallback(
		(integration: IntegrationModel) => (
			<IntegrationChip
				selected
				size="large"
				onDelete={() => removeSelectedIntegration(integration)}
				integration={integration}
			/>
		),
		[removeSelectedIntegration]
	);

	const onUpdateSelectedOperator = useCallback(
		(operator: TFilterOperator) =>
			filter.value.length
				? updateFilter({ value: normalizedCondition.value.toArray(), operator: operator as TOperator })
				: setSelectedOperator(operator as TOperator),
		[normalizedCondition, updateFilter, filter]
	);

	const selectedIntegrations = useMemo(
		() =>
			normalizedCondition.value
				.map(integrationId => integrations?.get(integrationId))
				.filter(notEmpty)
				.toArray(),
		[normalizedCondition.value, integrations]
	);

	const getOptionLabel = useCallback((option: IntegrationModel) => option.name, []);

	return (
		<FilterExpression
			className={className}
			emptyState={
				<FilterExpressionEmptyState
					text={t("pages.createRule.ruleDefinition.applyTo.allIntegrations")}
					Icon={IntegrationIcon}
				/>
			}
			getOptionLabel={getOptionLabel}
			getMoreOptions={setQuery}
			innerRef={innerRef}
			inputPlaceholder={t("filters.placeholders.integration")}
			onOperatorSelect={onUpdateSelectedOperator}
			onOptionSelect={toggleIntegrationSelection}
			onReset={onReset}
			operators={OPERATORS}
			optionRenderer={IntegrationOption}
			options={options}
			relation="or"
			renderSelected={renderSelected}
			selected={selectedIntegrations}
			selectedOperator={normalizedCondition.operator ?? "is"}
			title={t("pages.createRule.ruleDefinition.applyTo.filterExpressionTitle")}
			type="multiSelect"
		/>
	);
};
