import { createUseStyles } from "react-jss";

const HEADER_PADDING = "12px";

export const useStyles = createUseStyles({
	section: {
		justifyItems: "flex-start",
		alignItems: "center",
		width: "var(--side-nav-width)"
	},
	header: {
		padding: "0 28px",
		opacity: 1,
		transitionProperty: "height. opacity",
		willChange: "height, opacity",
		transitionDuration: "0.6s",
		transitionTimingFunction: "ease",
		"&$minimized": { opacity: 0, height: 1 },
		height: `calc(16px + ${HEADER_PADDING})`,
		overflow: "visible",
		// when minimized it can be in front other elements and prevent their interaction
		pointerEvents: "none"
	},
	innerHeader: { color: "var(--color-white)", paddingBottom: HEADER_PADDING },
	minimized: {}
});
