import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	page: {
		paddingBottom: "1rem",
		borderRadius: "var(--border-radius-semi-rounded)",
		overflow: "hidden"
	},
	rolePage: {
		display: "flex",
		flexDirection: "column",
		height: "100%",
		borderRadius: "var(--border-radius-semi-rounded)"
	},
	content: {
		display: "flex",
		flexDirection: "column",
		gap: "1.1em",
		padding: "1.5em 2.25em",
		overflow: "auto"
	},
	spinner: {
		margin: "auto"
	}
});
