import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	actions: {
		alignItems: "center",
		display: "flex",
		gap: "1rem"
	},
	content: {
		display: "flex",
		flexDirection: "column",
		gap: "1.5rem"
	},
	header: {
		display: "flex",
		justifyContent: "space-between"
	},
	headerTitle: {
		alignItems: "center",
		display: "flex",
		gap: "1.5rem"
	},
	backButtonContainer: {
		paddingBottom: "var(--spacing-x7, 28px)"
	},
	noReports: {
		alignItems: "center",
		display: "flex",
		flexDirection: "column",
		height: "100%",
		justifyContent: "center",
		width: "100%"
	},
	option: {
		alignItems: "center",
		display: "flex"
	},
	page: {
		backgroundColor: "var(--color-white)",
		borderRadius: "var(--border-radius-semi-rounded)",
		display: "flex",
		flexDirection: "column",
		height: "100%",
		overflow: "hidden",
		padding: "1rem",
		width: "100%"
	},
	statistics: {
		alignItems: "center",
		color: "var(--color-grey-dark)",
		display: "flex",
		gap: "0.25rem"
	},
	statusChip: {
		alignItems: "center",
		borderRadius: "var(--border-radius-sharp)",
		display: "flex",
		gap: "0.5rem",
		padding: "0.35rem 0.85rem"
	},
	statusDone: {
		backgroundColor: "var(--color-green-10)"
	},
	statusInProgress: {
		backgroundColor: "var(--color-grey-background)"
	},
	statusPending: {
		backgroundColor: "var(--color-grey-light)"
	}
});
