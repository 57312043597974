import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	container: {
		display: "flex",
		justifyContent: "center",
		gap: "var(--spacing-x1, 4px)",
		alignItems: "flex-start",
		flexDirection: "column"
	},
	listContainer: {
		display: "flex",
		justifyContent: "flex-start",
		flexWrap: "wrap",
		alignItems: "center",
		gap: "var(--spacing-x3, 12px)",
		width: "100%"
	}
});
