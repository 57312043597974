import { useEffect, useState } from "react";

// this library has a bug with strictmode enabled in react - this is a workaround
// Because react renders everything twice in strict mode the library doesn't handle it very well and cannot find it's element by the id
// Look into this: https://github.com/atlassian/react-beautiful-dnd/issues/2399#issuecomment-1167427762
// and also this: https://medium.com/@wbern/getting-react-18s-strict-mode-to-work-with-react-beautiful-dnd-47bc909348e4
export const useStrictDroppable = (id: string) => {
	const [enabled, setEnabled] = useState(false);

	useEffect(() => {
		const animation = requestAnimationFrame(() => setEnabled(true));

		return () => {
			cancelAnimationFrame(animation);
			setEnabled(false);
		};
	}, []);

	return enabled ? id : id + "-disable";
};
