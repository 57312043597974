import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	button: {
		transition: "width 0.3s ease-in-out",
		willChange: "width"
	},
	dropdown: {
		background: "var(--color-white)",
		borderRadius: "6px",
		boxShadow: "0px 8px 7px 7px rgba(0, 0, 0, 0.20)",
		display: "flex",
		flexDirection: "column",
		minWidth: "212px",
		padding: "var(--spacing-x1, 4px),0"
	},
	option: {
		padding: "var(--spacing-x4, 16px) var(--spacing-x4, 16px) var(--spacing-x4, 16px) var(--spacing-x7, 28px)",
		minHeight: "52px",
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		transition: "background-color 0.2s ease-in-out",
		willChange: "background-color",
		"&:hover, &$selected": {
			backgroundColor: "var(--color-blue-200)"
		},
		"&:hover": {
			cursor: "pointer"
		}
	},
	selected: {}
});
