import { Record as ImmutableRecord } from "immutable";

const defaults = {
	id: "",
	url: "",
	enabled: true,
	headers: null as Record<string, unknown> | null,
	additionalAuditLogParams: null as Record<string, unknown> | null,
	createdAt: new Date(0)
};

export class AuditLogsWebhookModel extends ImmutableRecord<typeof defaults>(defaults) {
	static fromServerData(data: unknown) {
		const { additionalAuditLogParams, createdAt, headers, id, url, enabled } = data as typeof defaults;
		return new AuditLogsWebhookModel({
			additionalAuditLogParams,
			createdAt: new Date(createdAt),
			headers,
			id,
			enabled,
			url
		});
	}
}
