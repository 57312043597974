import { IntegrationAuditLogModel } from "models/auditLogs";
import { apiReq } from "utils/api/apiReq";
import { withPagination } from "utils/pagination";
import { getPaginatedSearchQueryString, IPaginatedSearchOptions } from "utils/searchUtils";

export async function getIntegrationAuditLogs(integrationId: string, paginationOptions?: IPaginatedSearchOptions) {
	const querystring = paginationOptions ? `?${getPaginatedSearchQueryString(paginationOptions)}` : "";

	return withPagination(
		(error: Record<string, unknown>) => IntegrationAuditLogModel.fromServerData(error),
		() => apiReq("GET", `/v1/integrations/${integrationId}/auditLogs${querystring}`)
	);
}
