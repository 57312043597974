import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	modal: {
		minWidth: "495px"
	},
	content: {
		alignItems: "flex-start",
		display: "flex",
		flexDirection: "column",
		gap: "var(--spacing-x8, 32px)"
	},
	roleBars: {
		alignItems: "flex-start",
		alignSelf: "stretch",
		display: "flex",
		flexDirection: "column",
		gap: "var(--spacing-x2, 8px)",
		overflowX: "auto"
	}
});
