import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	container: {
		alignContent: "center",
		display: "flex",
		flexDirection: "column",
		gap: "var(--spacing-x10, 40px)",
		justifyContent: "center",
		padding: "var(--spacing-x2, 8px) var(--spacing-x3, 12px);",
		height: "100%",
		width: "100%"
	},
	tabHeaders: {
		"&$tabHeaders": {
			alignItems: "center",
			background: "var(--color-purple-200, #EFE8F2)",
			display: "flex",
			justifyContent: "center",
			maxWidth: "100%",
			width: "100%",
			"&$containerDisabled": {
				background: "var(--color-gray-300, #F3F1F3)"
			},
			"&$large": {
				borderRadius: "26px"
			},
			"&$medium": {
				borderRadius: "16px"
			}
		}
	},
	large: {},
	medium: {},
	iconSize: {
		height: "16px",
		width: "16px"
	},
	tabContent: {
		display: "flex",
		flexGrow: 1
	},
	headersContainer: {
		overflow: "auto",
		flexShrink: 0,
		paddingBottom: "var(--spacing-x2, 8px)"
	},
	containerDisabled: {}
});
