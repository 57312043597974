import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	button: {
		alignItems: "center",
		backgroundColor: "var(--color-white)",
		border: "none",
		color: "var(--typography-primary-font-color)",
		cursor: "pointer",
		display: "inline-flex",
		height: "2rem",
		justifyContent: "center",
		userSelect: "none",
		"&$selected:not($disabled)": {
			backgroundColor: "var(--color-purple)",
			color: "var(--typography-secondary-font-color)"
		},
		"&:hover:not($disabled):not($selected)": {
			backgroundColor: "var(--color-purple-5)",
			color: "var(--typography-primary-font-color)",
			transition: "all 0.2s ease-in-out"
		},
		"&$disabled": {
			cursor: "default",
			color: "var(--color-grey-disabled)",
			pointerEvents: "none"
		}
	},
	circle: {
		borderRadius: "var(--border-radius-round)",
		width: "2rem"
	},
	disabled: {},
	selected: {
		cursor: "default"
	},
	oval: {
		borderRadius: "var(--border-radius-rounder)",
		padding: "0.25rem 0.5rem"
	}
});
