import { createUseStyles, type Styles } from "react-jss";
import { iconButtonVariantStyles, type TIconButtonVariantClassnames } from "../IconButton/styles";

type TDropdownIconButtonSizeClassnames = "huge" | "large" | "medium" | "small" | "xs" | "tiny" | "wrapper" | "active";

const dropdownIconButtonVariantStyles: Styles<TIconButtonVariantClassnames | TDropdownIconButtonSizeClassnames> = {
	...iconButtonVariantStyles,
	huge: {
		borderRadius: "6px"
	},
	large: {
		borderRadius: "4px",
		padding: "var(--spacing-x05, 2px)"
	},
	medium: {
		borderRadius: "4px",
		padding: "var(--spacing-x05, 2px)"
	},
	small: {
		borderRadius: "4px",
		padding: "var(--spacing-x05, 2px)"
	},
	xs: {
		borderRadius: "3px",
		padding: "var(--spacing-x05, 2px)"
	},
	tiny: {
		borderRadius: "2px",
		padding: "0"
	},
	wrapper: {
		background: "transparent",
		bottom: 0,
		left: 0,
		position: "fixed",
		right: 0,
		top: 0,
		zIndex: 100
	},
	active: {
		"&$primary": {
			backgroundColor: "var(--icon-button-primary-background-color-pressed)"
		},
		"&$secondary": {
			backgroundColor: "var(--icon-button-secondary-background-color-pressed)"
		},
		"&$reverse": {
			backgroundColor: "var(--icon-button-reverse-background-color-pressed)"
		}
	}
};

export const useStyles = createUseStyles(dropdownIconButtonVariantStyles);
