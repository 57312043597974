import { ApprovalFlowEntityModel, TApprovalFlowEntityType } from "models/ApprovalFlowEntityModel";
import { ApprovalFlowNotifiedEntityModel } from "models/ApprovalFlowNotifiedEntityModel";
import type { TOnCallType } from "models/OnCallIntegrationScheduleModel";

interface ISharedEntityOption {
	displayName: string | null;
	identifier: string | null;
	email?: string | null;
	onCallScheduleType?: TOnCallType | null;
	isDeleted?: boolean;
}

export type TEntityOption<T extends TApprovalFlowEntityType = TApprovalFlowEntityType> = ISharedEntityOption & {
	type: T;
};

export interface IEntityOption<T extends TApprovalFlowEntityType> {
	toEntityOption(): TEntityOption<T>;
}

export const entityOptionToApprovalFlowEntity = (option: TEntityOption): ApprovalFlowEntityModel => {
	const { identifier, type } = option;
	return new ApprovalFlowEntityModel({ identifier, type });
};

export const entityOptionToApprovalFlowNotifiedEntity = (option: TEntityOption): ApprovalFlowNotifiedEntityModel => {
	const { identifier, type } = option;
	if (type === "Automatic") {
		throw new Error("Cannot convert automatic type entity to notified entity");
	}

	return new ApprovalFlowNotifiedEntityModel({ identifier, type });
};
