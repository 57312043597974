interface IProps {
	url: string;
	title: string;
	width?: number;
	height?: number;
}

export const openCenteredPopup = (props: IProps) => {
	const { url, title, width, height } = { height: 553, width: 503, ...props };

	// Fixes dual-screen position
	const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
	const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

	const calculatedWidth = window.innerWidth
		? window.innerWidth
		: document.documentElement.clientWidth
			? document.documentElement.clientWidth
			: screen.width;
	const calculatedHeight = window.innerHeight
		? window.innerHeight
		: document.documentElement.clientHeight
			? document.documentElement.clientHeight
			: screen.height;

	const systemZoom = calculatedWidth / window.screen.availWidth;
	const left = (calculatedWidth - width) / 2 / systemZoom + dualScreenLeft;
	const top = (calculatedHeight - height) / 2 / systemZoom + dualScreenTop;

	const newWindow = window.open(
		url,
		title,
		`
      scrollbars=yes,
      width=${width / systemZoom}, 
      height=${height / systemZoom}, 
      top=${top}, 
      left=${left}
      `
	);

	newWindow?.focus();

	return newWindow;
};
