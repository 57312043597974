import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	blockContainer: {
		padding: "var(--spacing-x4, 16px)",
		maxWidth: "272px",
		background: "var(--color-purple-50)",
		borderRadius: "12px",
		display: "flex",
		flexDirection: "column",
		gap: "var(--spacing-x5, 20px)",
		width: "100%"
	},
	blockHeader: {
		display: "flex",
		gap: "var(--spacing-x3, 12px)",
		justifyContent: "space-between"
	},
	chipsContainer: {
		gap: "var(--spacing-x2, 8px)"
	},
	integrationName: {
		display: "flex",
		gap: "var(--spacing-x2, 8px)",
		alignItems: "center",
		overflow: "hidden"
	}
});
