import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { UsersSelectInput } from "components/common/UsersSelectInput";
import { getArrayValue, IFilterProps } from "../../";

const TRANSLATION_PREFIX = "common.ticketFilters";

export const CreatorFilter: FC<IFilterProps> = ({ onFiltersChange, ticketFilters }) => {
	const { t } = useTranslation();

	const creators = useMemo(() => getArrayValue(ticketFilters.creator), [ticketFilters.creator]);

	const changeCreator = useCallback(
		(creator: string[]) => {
			onFiltersChange(current => ({
				...current,
				creator
			}));
		},
		[onFiltersChange]
	);

	return (
		<UsersSelectInput selectedIds={creators} onChange={changeCreator} label={t(`${TRANSLATION_PREFIX}.creator`)} />
	);
};
