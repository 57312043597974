import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	chip: {
		alignItems: "center",
		display: "inline-flex",
		justifyContent: "flex-start",
		maxWidth: "240px",
		"&$small": {
			padding: "var(--spacing-x05, 2px) var(--spacing-x2, 8px);",
			gap: "var(--spacing-x1, 4px)",
			borderRadius: "12px"
		},
		"&$medium": {
			minWidth: "44px",
			padding: "var(--spacing-x1, 4px) var(--spacing-x3, 12px);",
			gap: "var(--spacing-x1, 4px)",
			borderRadius: "16px"
		},
		"&$large": {
			minWidth: "60px",
			padding: "var(--spacing-x2, 8px) var(--spacing-x3, 12px);",
			gap: "var(--spacing-x2, 8px)",
			borderRadius: "20px"
		}
	},
	small: {},
	medium: {},
	large: {}
});
