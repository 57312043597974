import { List } from "immutable";
import { withPagination, type IPaginationResponse } from "utils/pagination";
import { type IPaginatedSearchOptions, getPaginatedSearchQueryString } from "utils/searchUtils";
import { DirectoryGroupModel } from "../models/DirectoryGroupModel";
import { apiReq } from "../utils/api/apiReq";

export async function getPaginatedDirectoryGroups(
	paginationOptions?: IPaginatedSearchOptions,
	{ includeDeleted = false }: { includeDeleted?: boolean } = {}
): Promise<IPaginationResponse<DirectoryGroupModel>> {
	return withPagination(
		(directoryGroup: Record<string, unknown>) => DirectoryGroupModel.fromServerData(directoryGroup),
		() => {
			const paginationQs = getPaginatedSearchQueryString(paginationOptions);
			const url = `/v1/directoryGroups?withDeleted=${includeDeleted}${paginationQs ? `&${paginationQs}` : ""}`;
			return apiReq("GET", url);
		}
	);
}

export async function multiGetDirectoryGroups(
	ids: string[],
	options: { includeDeleted?: boolean } = {}
): Promise<List<DirectoryGroupModel>> {
	if (!ids.length) return List<DirectoryGroupModel>();

	const { data } = await apiReq(
		"GET",
		`/v1/directoryGroups/multiGet?withDeleted=${!!options.includeDeleted}&${ids.map(id => "ids=" + id).join("&")}`
	);

	return List<DirectoryGroupModel>(data.map((item: unknown) => DirectoryGroupModel.fromServerData(item)));
}
