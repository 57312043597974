import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	pendingRequestsPage: {},
	titleContainer: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between"
	}
});
