import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	button: {
		alignItems: "center",
		border: "none",
		display: "inline-flex",
		justifyContent: "flex-start",
		minWidth: "fit-content",
		transitionDuration: "0.3s",
		transitionProperty: "background-color, border-color, color",
		transitionTimingFunction: "ease-out",
		"&$disabled": {
			transition: "none",
			pointerEvents: "none"
		},
		"&$loading": {
			pointerEvents: "none"
		},
		"&:hover": {
			cursor: "pointer"
		}
	},
	small: {
		borderRadius: "4px",
		fontSize: "var(--typography-font-size-small)",
		gap: "4px",
		height: "20px",
		padding: "2px 8px",
		"& > $prefixOrSuffix": {
			maxHeight: "12px",
			maxWidth: "12px",
			"& > div:has(> svg)": {
				maxHeight: "12px",
				maxWidth: "12px",
				fontSize: "12px"
			}
		}
	},
	medium: {
		borderRadius: "6px",
		fontSize: "var(--typography-font-size-small)",
		gap: "4px",
		height: "32px",
		padding: "2px 8px",
		"& > $prefixOrSuffix": {
			maxHeight: "20px",
			maxWidth: "20px",
			"& > div:has(> svg)": {
				maxHeight: "20px",
				maxWidth: "20px",
				fontSize: "20px"
			}
		}
	},
	large: {
		borderRadius: "6px",
		fontSize: "var(--typography-font-size-small)",
		gap: "8px",
		height: "44px",
		padding: "2px 16px",
		"& > $prefixOrSuffix": {
			maxHeight: "24px",
			maxWidth: "24px",
			"& > div:has(> svg)": {
				maxHeight: "24px",
				maxWidth: "24px",
				fontSize: "24px"
			}
		}
	},
	primary: {
		backgroundColor: "var(--dropdown-button-primary-background-color-regular)",
		color: "var(--dropdown-button-primary-color)",
		"&:hover": {
			backgroundColor: "var(--dropdown-button-primary-background-color-hover)"
		},
		"&:active, &$active": {
			border: "2px solid var(--dropdown-button-primary-border-color-pressed)",
			backgroundColor: "var(--dropdown-button-primary-background-color-pressed)",
			color: "var(--dropdown-button-primary-color-pressed)"
		},
		"&$disabled": {
			backgroundColor: "var(--dropdown-button-primary-background-color-disabled)"
		}
	},
	secondary: {
		backgroundColor: "var(--dropdown-button-secondary-background-color-regular)",
		border: "2px solid var(--dropdown-button-secondary-border-color)",
		color: "var(--dropdown-button-secondary-color)",
		"&:hover": {
			backgroundColor: "var(--dropdown-button-secondary-background-color-hover)"
		},
		"&:active, &$active": {
			border: "none",
			backgroundColor: "var(--dropdown-button-secondary-background-color-pressed)",
			color: "var(--dropdown-button-secondary-color-pressed)"
		},
		"&$disabled": {
			backgroundColor: "var(--dropdown-button-secondary-background-color-disabled)",
			border: "2px solid var(--dropdown-button-secondary-border-color-disabled)",
			color: "var(--dropdown-button-secondary-color-disabled)"
		}
	},
	dropdown: {
		background: "var(--color-white)",
		borderRadius: "6px",
		boxShadow: "0px 8px 7px 7px rgba(0, 0, 0, 0.20)",
		display: "flex",
		flexDirection: "column",
		minWidth: "212px",
		padding: "var(--spacing-x1, 4px) 0"
	},
	option: {
		padding: "var(--spacing-x3, 12px) var(--spacing-x4, 16px)",
		minHeight: "52px",
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-start",
		gap: "var(--spacing-x3, 12px)",
		transition: "background-color 0.2s ease-in-out",
		willChange: "background-color",
		"&:hover:not($disabled), &$selected:not($disabled)": {
			backgroundColor: "var(--color-blue-200)"
		},
		"&:hover": {
			cursor: "pointer"
		},
		"&$disabled": {
			cursor: "unset",
			color: "var(--color-grey-disabled)"
		}
	},
	selected: {},
	// MVP does not support text buttons
	// text: {
	// 	backgroundColor: "var(--dropdown-button-text-background-color-regular)",
	// 	color: "var(--dropdown-button-text-color)",
	// 	"&:hover": {
	// 		backgroundColor: "var(--dropdown-button-text-background-color-hover)"
	// 	},
	// 	"&:active": {
	// 		backgroundColor: "var(--dropdown-button-text-background-color-pressed)"
	// 	},
	// 	"&$disabled": {
	// 		backgroundColor: "var(--dropdown-button-text-background-color-disabled)",
	// 		color: "var(--dropdown-button-text-color-disabled)"
	// 	}
	// },
	prefixOrSuffix: {
		alignItems: "center",
		display: "flex",
		justifyContent: "center"
	},
	wrapper: {
		background: "transparent",
		bottom: 0,
		left: 0,
		position: "fixed",
		right: 0,
		top: 0,
		zIndex: 100
	},
	innerText: {
		minWidth: 0
	},
	active: {},
	disabled: {},
	loading: {}
});
