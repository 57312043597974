import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import type { AccessReviewReportModel, TAccessReviewReportStatus } from "models/AccessReviewReportModel";

const TRANSLATION_PREFIX = "pages.accessReview.dashboard";
const PER_PAGE = 5;

type TStatusOption = TAccessReviewReportStatus | "all";
const ALL_OPTION = "all";

interface IUseReportTable {
	changePage: (newPageNumber: number) => void;
	currentPageNumber: number;
	getStatusLabel: (status: TStatusOption) => string;
	headers: string[];
	page: AccessReviewReportModel[];
	setStatusFilter: (status: TStatusOption | null) => void;
	statusFilter: TStatusOption;
	statusOptions: TStatusOption[];
	title: string;
	totalPages: number;
}

const STATUS_OPTIONS: TStatusOption[] = ["done", "inProgress", "pending", "all"];

export const useReportTable = (accessReviewReports: AccessReviewReportModel[]): IUseReportTable => {
	const { t } = useTranslation();
	const [currentPageNumber, setCurrentPageNumber] = useState(1);
	const [statusFilter, setStatusFilter] = useState<TStatusOption>(ALL_OPTION);
	const totalPages = useMemo(() => Math.ceil(accessReviewReports.length / PER_PAGE), [accessReviewReports.length]);
	const headers = [
		t(`${TRANSLATION_PREFIX}.reporter`),
		t("pages.accessReview.numberOfEntitlements"),
		t(`${TRANSLATION_PREFIX}.numberOfApproved`),
		t(`${TRANSLATION_PREFIX}.numberOfDenied`),
		t(`${TRANSLATION_PREFIX}.status`)
	];

	const getStatusLabel = useCallback(
		(status: TStatusOption) => {
			if (status === ALL_OPTION) return t("shared.all");
			return t(`common.accessReview.statuses.subordinates.${status}`);
		},
		[t]
	);

	const onChangeStatusFilter = useCallback((status: TStatusOption | null) => {
		setStatusFilter(status || ALL_OPTION);
	}, []);

	const filteredReports = useMemo(
		() =>
			(statusFilter !== ALL_OPTION
				? accessReviewReports.filter(report => report.status === statusFilter)
				: accessReviewReports
			).sort((a, b) => (a.id > b.id ? 1 : -1)),
		[statusFilter, accessReviewReports]
	);

	const pageItems = useMemo(() => {
		const startIndex = (currentPageNumber - 1) * PER_PAGE;
		const endIndex = startIndex + PER_PAGE;
		return filteredReports.slice(startIndex, endIndex);
	}, [currentPageNumber, filteredReports]);

	const title = t(`${TRANSLATION_PREFIX}.reports`, {
		count: filteredReports.length
	});

	const changePage = useCallback(
		(newPageNumber: number) => {
			if (newPageNumber < 1 && newPageNumber > totalPages) return;
			setCurrentPageNumber(newPageNumber);
		},
		[totalPages]
	);

	return {
		changePage,
		currentPageNumber,
		getStatusLabel,
		headers,
		page: pageItems,
		setStatusFilter: onChangeStatusFilter,
		statusFilter,
		statusOptions: STATUS_OPTIONS,
		title,
		totalPages
	};
};
