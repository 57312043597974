import { List, Record } from "immutable";
import { TFilterType } from "./AccessReviewTemplateModel";

const defaults = {
	id: "",
	filterType: "exclude" as TFilterType,
	roleIds: List<string>(),
	integrationResourceId: "",
	integrationId: ""
};

type TAccessReviewTemplateResourceRuleModel = typeof defaults;

export class AccessReviewTemplateResourceRuleModel extends Record<TAccessReviewTemplateResourceRuleModel>(defaults) {
	static fromServerData(data: unknown): AccessReviewTemplateResourceRuleModel {
		const { id, filterType, roleIds, integrationResourceId, integrationId } =
			data as TAccessReviewTemplateResourceRuleModel;
		return new AccessReviewTemplateResourceRuleModel({
			id,
			filterType,
			roleIds: List(roleIds),
			integrationResourceId,
			integrationId
		});
	}
}
