import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { RoleBarTooltip } from "components/common/BasicRoleBar";
import { DeletedIndicator } from "components/common/entities/components/indicators/DeletedIndicator";
import { getDynamicSizeIcon } from "components/ui/dynamicSizeIcon";
import { NoAccessCircleIcon } from "components/ui/Icons/NoAccessCircleIcon";
import { RoleIcon } from "components/ui/Icons/RoleIcon";
import { Tooltip } from "components/ui/Tooltip";
import { useIntegrations } from "hooks/useIntegrations";
import { useStyles } from "./styles";
import { Chip, TChipVariant, type TChipSize } from "../Chip";
import type { TIconProps } from "components/ui/Icon";
import type { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";

interface IRoleChipProps {
	integrationResourceRole: IntegrationResourceRoleModel;
	onDelete?: () => void;
	readonly?: boolean;
	selected?: boolean;
	size?: TChipSize;
	variant?: TChipVariant;
}

const UnmanagedIndicatorIcon = (props: TIconProps) => {
	const classes = useStyles();
	const { t } = useTranslation();
	return (
		<Tooltip content={t("unmanagedRole")} className={classes.unmanagedTooltip}>
			<RoleIcon {...props} />
		</Tooltip>
	);
};
UnmanagedIndicatorIcon.isIconComponent = true as const;

const DeletedAndUnmanagedIndicatorIcon = (props: TIconProps) => {
	const classes = useStyles();
	const { t } = useTranslation();
	return (
		<Tooltip content={t("entities.deletedAndUnmanaged")} className={classes.deletedAndUnmanagedTooltip}>
			<NoAccessCircleIcon {...props} />
		</Tooltip>
	);
};
DeletedAndUnmanagedIndicatorIcon.isIconComponent = true as const;

export const RoleChip: FC<IRoleChipProps> = ({
	className,
	innerRef,
	integrationResourceRole,
	onDelete,
	readonly = false,
	selected = false,
	size = "medium",
	variant = "regular"
}) => {
	const integrations = useIntegrations();
	const integration = integrations?.get(integrationResourceRole.integrationResource?.integrationId || "");
	const image = integration?.imageUrl;

	const RoleChipIcon = useMemo(() => (image ? getDynamicSizeIcon(<img src={image} />) : RoleIcon), [image]);

	const inactive = integrationResourceRole.isDeleted || !integrationResourceRole.managed;
	const PrefixIcon = useMemo(() => {
		if (integrationResourceRole.isDeleted && !integrationResourceRole.managed) {
			return DeletedAndUnmanagedIndicatorIcon;
		}
		if (!integrationResourceRole.managed) {
			return UnmanagedIndicatorIcon;
		}
		if (integrationResourceRole.isDeleted) {
			return DeletedIndicator;
		}
		return RoleChipIcon;
	}, [integrationResourceRole.isDeleted, integrationResourceRole.managed, RoleChipIcon]);

	return (
		<RoleBarTooltip role={integrationResourceRole}>
			<Chip
				noTooltip
				readonly={readonly}
				variant={variant}
				size={size}
				inactive={inactive}
				PrefixIcon={PrefixIcon}
				onDelete={onDelete}
				selected={selected}
				className={className}
				innerRef={innerRef}>
				{integrationResourceRole.name}
			</Chip>
		</RoleBarTooltip>
	);
};
