import React, { useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { FloatingSelect, IFloatingSelectOption } from "components/common/FloatingSelect";
import { ChevronDownIcon } from "components/ui/Icons/ChevronDownIcon";
import { useApprovalAlgorithms } from "hooks/useApprovalAlgorithms";
import { useInlineInputState } from "hooks/useInlineInputState";
import { ApprovalAlgorithmModel } from "models/ApprovalAlgorithmModel";
import { INHERIT } from "utils/ui/select";
import { useStyles } from "./styles";
import { WorkflowEntity } from "../entities/WorkflowEntity";

type TProps = {
	approvalAlgorithmId: string | null;
	setApprovalAlgorithm: (approvalAlgorithm: ApprovalAlgorithmModel | undefined) => void | Promise<void>;
	inheritTranslationPath: "pages.integration.resource.inherit" | "pages.integration.resource.role.inherit";
};

const sortOptions = (options: IFloatingSelectOption[]) =>
	options.sort((a, b) => (a.value === INHERIT ? -1 : a.label.localeCompare(b.label)));

export const WorkflowInlineSelect: FC<TProps> = ({
	approvalAlgorithmId,
	setApprovalAlgorithm,
	inheritTranslationPath
}) => {
	const { t } = useTranslation();
	const { onClose, onHover, onLeave, onOpen, optionsOpen, showArrow } = useInlineInputState();
	const classes = useStyles({ showArrow });

	const approvalAlgorithms = useApprovalAlgorithms();

	const inheritOption = useMemo(
		() => new ApprovalAlgorithmModel({ id: INHERIT, name: t(inheritTranslationPath) }),
		[inheritTranslationPath, t]
	);
	const approvalAlgorithm = useMemo(
		() => approvalAlgorithms?.find(approvalAlgorithm => approvalAlgorithm.id === approvalAlgorithmId) ?? inheritOption,
		[approvalAlgorithmId, inheritOption, approvalAlgorithms]
	);

	const approvalAlgorithmOptions = useMemo(() => {
		const approvalAlgorithmsArray = approvalAlgorithms?.toList().toArray();

		let options = [inheritOption];
		if (approvalAlgorithmsArray?.length) {
			options = options.concat(approvalAlgorithmsArray);
		}
		return options.map(approvalAlgorithm => ({
			value: approvalAlgorithm.id,
			label: approvalAlgorithm.name
		}));
	}, [approvalAlgorithms, inheritOption]);

	const renderOption = useCallback(
		(option: { value: string; label: string }, props: { onClick: (event: React.MouseEvent) => void; key: string }) => {
			const approvalAlgorithm = approvalAlgorithms?.get(option.value);
			return (
				<div className={classes.approvalAlgorithmOption} {...props}>
					{approvalAlgorithm ? <WorkflowEntity size="medium" withIcon approvalAlgorithm={approvalAlgorithm} /> : null}
				</div>
			);
		},
		[classes.approvalAlgorithmOption, approvalAlgorithms]
	);
	const onChange = useCallback(
		(approvalAlgorithmId: string) => {
			const updateApprovalAlgorithm = async () => {
				const newApprovalAlgorithm = approvalAlgorithms?.get(approvalAlgorithmId);
				if (newApprovalAlgorithm || approvalAlgorithmId === INHERIT) {
					await setApprovalAlgorithm(newApprovalAlgorithm);
					onClose();
				}
			};
			void updateApprovalAlgorithm();
		},
		[onClose, setApprovalAlgorithm, approvalAlgorithms]
	);
	return (
		<div className={classes.inlineInputContainer}>
			<FloatingSelect
				filter
				sort={sortOptions}
				onClose={onClose}
				onSelect={onChange}
				open={optionsOpen}
				options={approvalAlgorithmOptions}
				optionsClassName={classes.list}
				position="bottom-start"
				placeholder={t("common.floatingSelect.searchWorkflow")}
				renderOption={renderOption}
				className={classes.floatingSelect}>
				<div
					onMouseOver={onHover}
					onMouseLeave={onLeave}
					onClick={onOpen}
					className={classes.inlineInputLabelContainer}>
					<WorkflowEntity size="medium" approvalAlgorithm={approvalAlgorithm} />
					<ChevronDownIcon className={classes.arrowDownInline} />
				</div>
			</FloatingSelect>
		</div>
	);
};
