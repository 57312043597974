import classNames from "classnames";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TitleBody } from "components/ui/TitleBody";
import { Tooltip } from "components/ui/Tooltip";
import { useStyles } from "./styles";

type TSingleResourceProps = {
	description?: string | null;
	euid?: string;
	name: string;
	type?: string;
	isDeleted?: boolean;
	amount?: never;
};

type TMultipleResourceProps = {
	amount: number;
	description?: never;
	euid?: never;
	name?: never;
	type?: never;
	isDeleted?: never;
};

export type TResourceDetailsTooltipProps = TSingleResourceProps | TMultipleResourceProps;

export const ResourceDetailsTooltip: FC<TResourceDetailsTooltipProps> = ({
	children,
	innerRef,
	className,
	...restProps
}) => {
	const { t } = useTranslation("translation", { keyPrefix: "common.resourceDetailsTooltip" });
	const classes = useStyles();

	const childrenComp = useMemo(() => {
		if (!children) return <div />;
		if (Array.isArray(children) || typeof children !== "object" || !React.isValidElement(children))
			return <div>{children}</div>;
		return children;
	}, [children]);

	const tooltipContent = useMemo(
		() => (
			<div className={classNames(classes.tooltipContainer, className)} ref={innerRef}>
				{"amount" in restProps ? (
					<TitleBody size="small" title={t("amount")} body={restProps.amount || 0} />
				) : (
					<>
						<TitleBody size="small" title={t("name")} body={restProps.name} />
						{restProps.type ? <TitleBody size="small" title={t("type")} body={restProps.type} /> : null}
						{restProps.euid ? <TitleBody size="small" title={t("euid")} body={restProps.euid} /> : null}
						{restProps.description ? (
							<TitleBody size="small" title={t("description")} body={restProps.description} />
						) : null}
					</>
				)}
			</div>
		),
		[className, classes.tooltipContainer, innerRef, restProps, t]
	);

	return <Tooltip content={tooltipContent}>{childrenComp}</Tooltip>;
};
