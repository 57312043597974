import uniq from "lodash/uniq";
import { useMemo } from "react";
import * as AuditLogs from "models/auditLogs";
import { IntegrationModel } from "models/IntegrationModel";
import { getTicketingIntegrationLogoByType } from "utils/tickets/ticketingIntegrationLogos";
import { useBundles } from "./useBundles";
import { useIntegrations } from "./useIntegrations";
import type { TAuditLogModel } from "models/auditLogs";

const addIntegrationImageIfExists = (imagesUrls: string[], integration?: IntegrationModel) => {
	if (integration && integration.imageUrl) {
		imagesUrls.push(integration.imageUrl);
	}
};

export const useAuditLogImages = (auditLog: TAuditLogModel) => {
	const integrations = useIntegrations(true);
	const bundles = useBundles(true);

	const images = useMemo(() => {
		if (
			!integrations ||
			auditLog instanceof AuditLogs.AccessReviewAuditLogModel ||
			auditLog instanceof AuditLogs.ApprovalAlgorithmAuditLogModel
		) {
			return null;
		}

		const imagesUrls: string[] = [];
		if (integrations) {
			if (auditLog instanceof AuditLogs.TicketAuditLogModel && auditLog.ticket?.ticketPermissions) {
				if (auditLog.action === "AccessRequest3rdPartyTicketClosed") {
					const ticketingLogo = auditLog.ticket.ticketingIntegrationTicket?.ticketingIntegration;
					const logo = ticketingLogo ? getTicketingIntegrationLogoByType(ticketingLogo) : null;
					return logo ? [logo] : null;
				}
				auditLog.ticket.ticketPermissions.forEach(ticketPermission => {
					if (!ticketPermission.role || !ticketPermission.role.integrationResource) return;
					const integration = integrations.get(ticketPermission.role.integrationResource.integrationId);
					addIntegrationImageIfExists(imagesUrls, integration);
				});
			} else if (
				(auditLog instanceof AuditLogs.IntegrationAuditLogModel ||
					auditLog instanceof AuditLogs.IntegrationResourceAuditLogModel ||
					auditLog instanceof AuditLogs.IntegrationResourceRoleAuditLogModel) &&
				auditLog.integrationId
			) {
				addIntegrationImageIfExists(imagesUrls, integrations.get(auditLog.integrationId));
			} else if (auditLog instanceof AuditLogs.BundleAuditLogModel && auditLog.bundleId && bundles) {
				const bundle = bundles.get(auditLog.bundleId);
				if (bundle && bundle.bundleItems) {
					bundle.bundleItems.forEach(item => {
						if (!item.integrationResourceRole || !item.integrationResourceRole.integrationResource) return;
						const integration = integrations.get(item.integrationResourceRole.integrationResource.integrationId);
						addIntegrationImageIfExists(imagesUrls, integration);
					});
				}
			}
		}

		return uniq(imagesUrls.filter(Boolean));
	}, [auditLog, bundles, integrations]);

	return images;
};
