import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	button: {
		"&$button": {
			"&:hover": {
				cursor: "pointer",
				backgroundColor: "var(--color-grey-700)"
			},
			"&:active, &$active": {
				backgroundColor: "var(--color-purple-500)"
			},
			backgroundColor: "transparent",
			minWidth: "100px",
			height: 32,
			gap: 8
		}
	},
	active: {}
});
