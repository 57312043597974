import classNames from "classnames";
import isEqual from "lodash/isEqual";
import isNumber from "lodash/isNumber";
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { useResizeDrag } from "components/common/DraggableSidebar/useResizeDrag";
import { Divider } from "components/ui/Divider";
import { useStyles } from "./styles";
import { useVirtualTableContext } from "../../../virtualTableContext";
import type { TColumnWidthFixed, TMinMaxColumnWidth, TResizeableColumnOptions } from "../../../types";

const DEFAULT_WIDTH = 120;
const DEFAULT_MAX_WIDTH = 10000;
const DIVIDER_COLOR = "var(--table-cell-border-color)";
const HIGHLIGHTED_DIVIDER_COLOR = "var(--color-blue-500)";

const isColumnWidthFixed = (options: TResizeableColumnOptions): options is TColumnWidthFixed => {
	return (options as TColumnWidthFixed).width !== undefined && (options as TColumnWidthFixed).fixed;
};

const isMinMaxColumnWidth = (options: TResizeableColumnOptions): options is TMinMaxColumnWidth => {
	return (options as TMinMaxColumnWidth).min !== undefined;
};

const useResizeableTable = ({
	widthOptions,
	columnIndex,
	dragRef
}: {
	widthOptions: TResizeableColumnOptions;
	columnIndex: number;
	dragRef: React.RefObject<SVGElement | null>;
}) => {
	const fixed = isColumnWidthFixed(widthOptions);
	const widthOptionsMax = isMinMaxColumnWidth(widthOptions) ? widthOptions.max : null;
	const widthOptionsMin = isMinMaxColumnWidth(widthOptions) ? widthOptions.min : null;
	const initialWidth = widthOptions.width || DEFAULT_WIDTH;
	const maxWidth = isNumber(widthOptionsMax) ? widthOptionsMax : DEFAULT_MAX_WIDTH;
	const [minWidth, setMinWidth] = useState(widthOptionsMin || 0);
	const containerRef = useRef<HTMLDivElement | null>(null);
	const contentRef = useRef<HTMLDivElement | null>(null);

	const {
		size: width,
		refreshInitialSize,
		isDragging
	} = useResizeDrag<SVGElement>(dragRef, initialWidth, maxWidth, minWidth, true);

	const {
		actions: { changeColumnWidth, setResizeIndex }
	} = useVirtualTableContext();

	useEffect(() => {
		if (width === widthOptions.width || !width || fixed) return;
		changeColumnWidth(columnIndex, width);
	}, [changeColumnWidth, columnIndex, width, fixed, widthOptions]); // IMPORTANT to include widthOptions and not widthOptions.width because of race conditions in set parent states that reset widthOptions.width prop and cause this effect not to change in some cases

	useEffect(() => {
		if (fixed) return;
		setResizeIndex(isDragging ? columnIndex : -1);
	}, [columnIndex, fixed, isDragging, setResizeIndex]);

	const setContainerRefAndRefreshInitialSize = useCallback(
		(ref: HTMLDivElement | null) => {
			if (!ref || containerRef.current) return;
			containerRef.current = ref;
			if (widthOptions.width) return;
			refreshInitialSize(ref.clientWidth);
		},
		[refreshInitialSize, widthOptions.width]
	);

	const setContentRefAndSetMinWidth = useCallback(
		(ref: HTMLDivElement | null) => {
			if (!ref || contentRef.current) return;
			contentRef.current = ref;
			setMinWidth(Math.max(widthOptionsMin || 0, contentRef.current.offsetWidth));
		},
		[widthOptionsMin]
	);

	return {
		isDragging,
		fixed,
		onContainerRefChange: setContainerRefAndRefreshInitialSize,
		onContentRefChange: setContentRefAndSetMinWidth
	};
};

/*
 * The column width is set on startup to prevent issues when the width is not specified and defaults to grid properties (fr/auto)
 * The minimum width is determined by the cell content or the value specified in the column options.
 */
const ResizeableCellWrapper: FC<{ columnIndex: number; isLastColumn: boolean }> = ({
	children,
	columnIndex,
	isLastColumn = false,
	className
}) => {
	const classes = useStyles();
	const {
		state: { columnsWidths }
	} = useVirtualTableContext();
	const widthOptions = columnsWidths.get(columnIndex) as TResizeableColumnOptions;
	const dragRef = useRef<SVGElement | null>(null);
	const [isDividerHovered, setIsDividerHovered] = useState(false);

	const { isDragging, fixed, onContainerRefChange, onContentRefChange } = useResizeableTable({
		widthOptions,
		columnIndex,
		dragRef
	});

	const onMouseEnter = useCallback(() => setIsDividerHovered(true), []);
	const onMouseLeave = useCallback(() => setIsDividerHovered(false), []);

	const highlightedDivider = !fixed && (isDividerHovered || isDragging);
	const dividerWidth = highlightedDivider ? 4 : 2;
	const dividerColor = highlightedDivider ? HIGHLIGHTED_DIVIDER_COLOR : DIVIDER_COLOR;

	return (
		<div ref={onContainerRefChange} className={classNames(classes.textCellResizeableWrapper, className)}>
			<div ref={onContentRefChange} className={classes.textCellResizeable}>
				{children}
			</div>
			{!isLastColumn && (
				<Divider
					className={classNames(classes.divider, {
						[classes.disableResize]: fixed,
						[classes.dividerHover]: isDividerHovered
					})}
					innerRef={dragRef}
					width={dividerWidth}
					onMouseEnter={onMouseEnter}
					onMouseLeave={onMouseLeave}
					color={dividerColor}
					vertical
				/>
			)}
		</div>
	);
};

const ResizeableCellWrapperMemo = memo(ResizeableCellWrapper, isEqual) as typeof ResizeableCellWrapper;

export { ResizeableCellWrapperMemo as ResizeableCellWrapper };
