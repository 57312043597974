import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	roleContainer: {
		display: "flex",
		flexDirection: "row",
		width: "fit-content",
		position: "relative"
	},
	displayName: {
		display: "flex",
		flexDirection: "column"
	},
	chip: {
		borderRadius: "var(--border-radius-sharp)",
		"&$chip$chip": {
			padding: "0.43rem 0.57rem 0.43rem 0.75rem",
			"&:hover": {
				backgroundColor: "transparent"
			}
		}
	},
	defaultButton: {
		"&$defaultButton$defaultButton$defaultButton": {
			border: "none",
			position: "absolute",
			left: "-0.9rem",
			top: "0.35rem",
			color: "var(--color-grey-light)",
			"&$selected": {
				color: "var(--color-blue)",
				cursor: "default"
			},
			"&:active": {
				background: "transparent"
			},
			"&:hover": {
				background: "transparent"
			}
		}
	},
	removeButton: {
		"&$removeButton$removeButton$removeButton": {
			border: "none",
			"&:hover": {
				backgroundColor: "transparent"
			}
		}
	},
	selected: {}
});
