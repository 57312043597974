import { List } from "immutable";
import { AccessReviewReportModel } from "models/AccessReviewReportModel";
import { AccessReviewResourceModel } from "models/AccessReviewResourceModel";
import { AccessReviewSubordinateModel } from "models/AccessReviewSubordinateModel";
import { apiReq } from "utils/api/apiReq";
import { IPaginationResponse, withPagination } from "utils/pagination";
import { getPaginatedSearchQueryString, IPaginatedSearchOptions } from "utils/searchUtils";

export const getAccessReviewReport = async (id: string): Promise<AccessReviewReportModel> => {
	const { data: accessReviewReport } = await apiReq("GET", `/v1/accessReviewReports/${id}`);

	return AccessReviewReportModel.fromServerData(accessReviewReport);
};

export const getAccessReviewReports = async (): Promise<List<AccessReviewReportModel>> => {
	const { data: accessReviewReports } = await apiReq("GET", "/v1/accessReviewReports");

	return List<AccessReviewReportModel>(accessReviewReports.map(AccessReviewReportModel.fromServerData));
};

export const getSubordinates = async (
	reportId: string,
	paginatedOptions?: IPaginatedSearchOptions
): Promise<IPaginationResponse<AccessReviewSubordinateModel>> => {
	const searchQs = getPaginatedSearchQueryString(paginatedOptions);
	const querystring = searchQs && `?${searchQs}`;

	return withPagination(
		(subordinate: Record<string, unknown>) => AccessReviewSubordinateModel.fromServerData(subordinate),
		() => apiReq("GET", `/v1/accessReviewReports/${reportId}/subordinates${querystring}`)
	);
};

export const getResources = async (
	reportId: string,
	paginatedOptions?: IPaginatedSearchOptions
): Promise<IPaginationResponse<AccessReviewResourceModel>> => {
	const searchQs = getPaginatedSearchQueryString(paginatedOptions);
	const querystring = searchQs && `?${searchQs}`;

	return withPagination(
		(resource: Record<string, unknown>) => AccessReviewResourceModel.fromServerData(resource),
		() => apiReq("GET", `/v1/accessReviewReports/${reportId}/resources${querystring}`)
	);
};
