import React from "react";
import { makeIcon } from "components/ui/Icon";

export const TokenIcon = makeIcon(
	<svg viewBox="0 0 24 24" fill="none">
		<path
			d="M12 21.7C11.8333 21.7 11.6667 21.6792 11.5 21.6375C11.3333 21.5958 11.175 21.5333 11.025 21.45L4.025 17.575C3.70833 17.3917 3.45833 17.1458 3.275 16.8375C3.09167 16.5292 3 16.1917 3 15.825V8.17499C3 7.80832 3.09167 7.47082 3.275 7.16249C3.45833 6.85415 3.70833 6.60832 4.025 6.42499L11.025 2.54999C11.175 2.46665 11.3333 2.40415 11.5 2.36249C11.6667 2.32082 11.8333 2.29999 12 2.29999C12.1667 2.29999 12.3333 2.32082 12.5 2.36249C12.6667 2.40415 12.825 2.46665 12.975 2.54999L19.975 6.42499C20.2917 6.60832 20.5417 6.85415 20.725 7.16249C20.9083 7.47082 21 7.80832 21 8.17499V15.825C21 16.1917 20.9083 16.5292 20.725 16.8375C20.5417 17.1458 20.2917 17.3917 19.975 17.575L12.975 21.45C12.825 21.5333 12.6667 21.5958 12.5 21.6375C12.3333 21.6792 12.1667 21.7 12 21.7ZM9.1 9.24999C9.46667 8.86665 9.89583 8.56249 10.3875 8.33749C10.8792 8.11249 11.4167 7.99999 12 7.99999C12.5833 7.99999 13.1208 8.11249 13.6125 8.33749C14.1042 8.56249 14.5333 8.86665 14.9 9.24999L17.9 7.57499L12 4.29999L6.1 7.57499L9.1 9.24999ZM11 19.15V15.875C10.1333 15.6417 9.41667 15.1708 8.85 14.4625C8.28333 13.7542 8 12.9333 8 12C8 11.8167 8.00833 11.6417 8.025 11.475C8.04167 11.3083 8.075 11.15 8.125 11L5 9.24999V15.825L11 19.15ZM12 14C12.55 14 13.0208 13.8042 13.4125 13.4125C13.8042 13.0208 14 12.55 14 12C14 11.45 13.8042 10.9792 13.4125 10.5875C13.0208 10.1958 12.55 9.99999 12 9.99999C11.45 9.99999 10.9792 10.1958 10.5875 10.5875C10.1958 10.9792 10 11.45 10 12C10 12.55 10.1958 13.0208 10.5875 13.4125C10.9792 13.8042 11.45 14 12 14ZM13 19.15L19 15.825V9.24999L15.875 11C15.925 11.1667 15.9583 11.3292 15.975 11.4875C15.9917 11.6458 16 11.8167 16 12C16 12.9333 15.7167 13.7542 15.15 14.4625C14.5833 15.1708 13.8667 15.6417 13 15.875V19.15Z"
			fill="currentColor"
		/>
	</svg>
);
