import { createUseStyles } from "react-jss";
import { BP_MEDIA_QUERIES } from "utils/ui/breakpoints";

export const useStyles = createUseStyles({
	navigationBar: {
		display: "flex",
		flexDirection: "column",
		justifyItems: "flex-start",
		alignItems: "stretch",
		userSelect: "none",
		overflow: "hidden",
		transition: "0.6s ease width",
		willChange: "width",
		height: "100%",
		backgroundColor: "var(--color-black)",
		paddingTop: "var(--spacing-x9, 36px)",
		width: "var(--side-nav-width)",
		"&$minimized": { width: "var(--side-nav-width-collapsed)" },
		[BP_MEDIA_QUERIES.FORTY]: {
			paddingTop: "var(--spacing-x3, 12px)"
		}
	},
	minimized: {},
	newRequestButton: {
		"&$newRequestButton": {
			flexShrink: "0",
			borderRadius: "var(--border-radius-rounder)",
			color: "var(--color-purple-dark)",
			backgroundColor: "var(--color-white)",
			"&:hover": {
				backgroundColor: "var(--color-purple-10)"
			},
			margin: "1rem 1.5rem 1.3rem",
			lineHeight: "1rem"
		}
	},
	newRequestText: {
		marginInlineStart: "0rem"
	},
	newRequestIcon: {
		marginInlineStart: "0.6rem"
	},
	scrollContainer: {
		overflowY: "auto",
		overflowX: "hidden",
		flex: "1 1 auto"
	}
});
