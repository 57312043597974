import { useMemo } from "react";
import { requirePropertyOf } from "utils/security";
import { sourceIcons } from "utils/ui/sourceIcons";
import type { TDirectory } from "types/directoryConfiguration";

const sourceRegex = new RegExp(/\[([A-Za-z]+)\] (.*)/);

export const getDirectoryGroupInfo = (directoryGroupName: string) => {
	const match = sourceRegex.exec(directoryGroupName);
	if (!match) {
		return { name: directoryGroupName, Icon: null };
	}

	const iconString = match[1] as TDirectory;
	const name = match[2];

	const Icon = requirePropertyOf(sourceIcons, iconString);

	return { name, Icon, sourceTranslationKey: `common.groups.${iconString}` as const };
};
export const useDirectoryGroupInfo = (directoryGroupName: string) => {
	const info = useMemo(() => {
		return getDirectoryGroupInfo(directoryGroupName);
	}, [directoryGroupName]);

	return info;
};
