import { useEffect, useState } from "react";
import { useOnMount } from "./useOnMount";

/**
 * Reactive media query hook that returns the truthy value of the media query.
 *
 * @param query - The media query to be used
 * @returns boolean value of the query
 *
 */
export function useMediaQuery(query: string): boolean | undefined {
	const [matches, setMatches] = useState<boolean>();

	useOnMount(() => {
		setMatches(window.matchMedia(query).matches);
	});

	useEffect(() => {
		const mediaQuery = window.matchMedia(query);
		const handler = (event: MediaQueryListEvent) => {
			setMatches(event.matches);
		};

		if (mediaQuery.addEventListener) {
			mediaQuery.addEventListener("change", handler);
			/* eslint-disable @typescript-eslint/no-deprecated -- Old Browsers support */
		} else if (mediaQuery.addListener) {
			mediaQuery.addListener(handler);
		}
		return () => {
			if (mediaQuery.removeEventListener) {
				mediaQuery.removeEventListener("change", handler);
			} else if (mediaQuery.removeListener) {
				mediaQuery.removeListener(handler);
				/* eslint-enable @typescript-eslint/no-deprecated */
			}
		};
	}, [query]);

	return matches;
}
