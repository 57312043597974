import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	cell: {
		justifyContent: "center",
		alignItems: "start",
		display: "flex",
		flexDirection: "column",
		minHeight: "3rem"
	},
	titleContainer: {
		display: "flex",
		flexDirection: "row",
		flexGrow: 1,
		justifyContent: "space-between"
	}
});
