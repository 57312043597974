import constate from "constate";
import { Map as ImmutableMap } from "immutable";
import { useCallback, useState } from "react";
import type { TNavigationPart } from "components/common/Breadcrumbs";
import type { TPageId } from "routes/route";

const usePage = () => {
	const [breadcrumbs, setBreadcrumbs] = useState<TNavigationPart[] | null>(null);
	const [pageLastState, setPageLastState] = useState(ImmutableMap<TPageId, Record<string, unknown>>());

	const setLastState = useCallback((pageId: TPageId, state?: Record<string, unknown>) => {
		setPageLastState(prevState => (state ? prevState.set(pageId, state) : prevState.delete(pageId)));
	}, []);

	const updateLastState = useCallback((pageId: TPageId, state: Record<string, unknown>) => {
		setPageLastState(prevState => prevState.set(pageId, { ...prevState.get(pageId), ...state }));
	}, []);

	return { state: { breadcrumbs, pageLastState }, actions: { setBreadcrumbs, setLastState, updateLastState } };
};

export const [PageProvider, usePageContext, useBreadcrumbs, usePageLastState] = constate(
	usePage,
	value => value,
	value => ({ breadcrumbs: value.state.breadcrumbs, setBreadcrumbs: value.actions.setBreadcrumbs }),
	value => ({
		pageLastState: value.state.pageLastState,
		setLastState: value.actions.setLastState,
		updateLastState: value.actions.updateLastState
	})
);
