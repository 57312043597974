import React from "react";
import { PolicyAuditLogContent } from "components/pages/AuditLogsPage/components/AuditLogContent/PolicyAuditLogContent";
import * as AuditLogs from "models/auditLogs";
import { AccessReviewAuditLogContent } from "./AccessReviewAuditLogContent";
import { ApprovalAlgorithmAuditLogContent } from "./ApprovalAlgorithmAuditLogContent";
import { ApprovalFlowsWebhookAuditLogContent } from "./ApprovalFlowsWebhookAuditLogContent";
import { BundleAuditLogContent } from "./BundleAuditLogContent";
import { CompanyAuditLogContent } from "./CompanyAuditLogContent";
import { IntegrationAuditLogContent } from "./IntegrationAuditLogContent";
import { IntegrationResourceAuditLogContent } from "./IntegrationResourceAuditLogContent";
import { IntegrationResourceRoleAuditLogContent } from "./IntegrationResourceRoleAuditLogContent";
import { TicketAuditLogContent } from "./TicketAuditLogContent";
import type { TAuditLogConstructor } from "utils/auditLogs/auditLogUtils";
import type { TAuditLogContentComponent } from "./AuditLogContent.types";

const AUDIT_LOGS_COMPONENTS = [
	[AuditLogs.TicketAuditLogModel, TicketAuditLogContent],
	[AuditLogs.CompanyAuditLogModel, CompanyAuditLogContent],
	[AuditLogs.IntegrationAuditLogModel, IntegrationAuditLogContent],
	[AuditLogs.PolicyAuditLogModel, PolicyAuditLogContent],
	[AuditLogs.AccessReviewAuditLogModel, AccessReviewAuditLogContent],
	[AuditLogs.BundleAuditLogModel, BundleAuditLogContent],
	[AuditLogs.ApprovalAlgorithmAuditLogModel, ApprovalAlgorithmAuditLogContent],
	[AuditLogs.IntegrationResourceAuditLogModel, IntegrationResourceAuditLogContent],
	[AuditLogs.IntegrationResourceRoleAuditLogModel, IntegrationResourceRoleAuditLogContent],
	[AuditLogs.ApprovalFlowsWebhookAuditLogModel, ApprovalFlowsWebhookAuditLogContent]
] as [TAuditLogConstructor, TAuditLogContentComponent][];

const AUDIT_LOGS_COMPONENTS_MAP = new Map(AUDIT_LOGS_COMPONENTS);

const AuditLogContentComponent: TAuditLogContentComponent = ({ auditLog, logUser }) => {
	const Content = AUDIT_LOGS_COMPONENTS_MAP.get(auditLog.constructor as TAuditLogConstructor);
	if (!Content) return null;

	return <Content auditLog={auditLog} logUser={logUser} />;
};

export const AuditLogContent = React.memo(AuditLogContentComponent);
