import React from "react";
import { AccountIcon } from "components/ui/Icons/AccountIcon";
import { Chip, TChipSize, TChipVariant } from "../Chip";

interface IAccountChipProps {
	SuffixIcon?: IconComponent;
	readonly?: boolean;
	variant?: TChipVariant;
	size?: TChipSize;
	selected?: boolean;
	onDelete?: () => void;
}

export const AccountChip: FC<IAccountChipProps> = ({
	innerRef,
	className,
	children,
	readonly = false,
	SuffixIcon,
	variant = "regular",
	size = "medium",
	selected = false,
	onDelete
}) => {
	return (
		<Chip
			readonly={readonly}
			variant={variant}
			size={size}
			PrefixIcon={AccountIcon}
			SuffixIcon={SuffixIcon}
			onDelete={onDelete}
			selected={selected}
			className={className}
			innerRef={innerRef}>
			{children}
		</Chip>
	);
};
