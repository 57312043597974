import React, { useMemo } from "react";
import { OnCallIntegrationScheduleEntity } from "../entities/OnCallIntegrationScheduleEntity";
import type { TOnCallType } from "models/OnCallIntegrationScheduleModel";

interface IProps {
	isDeleted?: boolean;
	onCallType?: TOnCallType;
	value: string;
}

export const OnCallIntegrationScheduleLabel: FC<IProps> = ({ onCallType, value, className, isDeleted }) => {
	const schedule = useMemo(() => ({ name: value, isDeleted, type: onCallType }), [value, isDeleted, onCallType]);
	return <OnCallIntegrationScheduleEntity className={className} size="medium" onCallIntegrationSchedule={schedule} />;
};
