import { useCallback, useEffect, useMemo, useRef } from "react";

type TMountState = [isMounted: () => boolean, ifMounted: (callback: () => void) => void];

export default function useMountState(): TMountState {
	const isMountedRef = useRef(true);

	useEffect(() => {
		isMountedRef.current = true;

		return () => {
			isMountedRef.current = false;
		};
	});

	const ifMounted = useCallback((callback: () => void) => {
		if (isMountedRef.current) callback();
	}, []);

	const getIsMounted = useCallback(() => isMountedRef.current, []);

	const mountState = useMemo<TMountState>(() => [getIsMounted, ifMounted], [getIsMounted, ifMounted]);

	return mountState;
}
