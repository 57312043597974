import React from "react";
import { makeIcon } from "components/ui/Icon";

export const IntegrationIcon = makeIcon(
	<svg viewBox="0 0 24 24" fill="none">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M10.4853 10.2929C10.8758 10.6834 10.8758 11.3166 10.4853 11.7071L8.94237 13.25L10.3566 14.6642L11.8995 13.1213C12.29 12.7307 12.9232 12.7307 13.3137 13.1213C13.7042 13.5118 13.7042 14.1449 13.3137 14.5355L11.7708 16.0784L11.8849 16.1924C12.5405 16.8481 12.7383 18.0157 11.9685 18.8299C10.1751 20.7267 7.43332 21.273 5.25484 19.766L2.7071 22.3137C2.31657 22.7042 1.68341 22.7042 1.29289 22.3137C0.902366 21.9232 0.902372 21.29 1.2929 20.8995L3.86413 18.3283C2.68609 16.393 2.96364 13.5083 4.76044 11.6766C5.57264 10.8487 6.76735 11.0749 7.41407 11.7217L7.52816 11.8358L9.07104 10.2929C9.46156 9.90235 10.0947 9.90235 10.4853 10.2929ZM6.82104 13.9571L6.06889 13.2049C4.81043 14.6206 4.94385 16.7515 5.89949 17.7072C7.20449 19.0122 9.05385 18.9137 10.4188 17.5548L6.82104 13.9571ZM10.4717 17.608C10.4717 17.608 10.4715 17.6077 10.4711 17.6071L10.4717 17.608ZM5.99841 13.1347C5.99844 13.1347 5.99884 13.1349 5.99952 13.1356L5.99841 13.1347ZM17.608 10.4719L17.6069 10.471L17.608 10.4719ZM17.5376 10.4017L16.7854 9.6495L13.1876 6.05178C14.5526 4.69284 16.402 4.59443 17.707 5.89943C18.6626 6.85508 18.796 8.98599 17.5376 10.4017ZM13.1347 5.99862L13.1353 5.99944L13.1347 5.99862ZM12.5428 8.23533L11.7216 7.41414C11.066 6.75851 10.8681 5.59093 11.638 4.77667C13.4314 2.87985 16.1731 2.3336 18.3516 3.84057L20.8993 1.29289C21.2899 0.902366 21.923 0.902372 22.3136 1.2929C22.7041 1.68343 22.7041 2.31659 22.3135 2.70711L19.7423 5.27829C20.9204 7.21361 20.6428 10.0982 18.846 11.9299C18.0338 12.7579 16.8391 12.5317 16.1924 11.8849L15.3712 11.0637L12.5428 8.23533Z"
			fill="currentColor"
		/>
	</svg>
);
