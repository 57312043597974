import { List, Record } from "immutable";
import { AccessReviewReportModel } from "./AccessReviewReportModel";
import { AccessReviewSubordinatePermissionModel } from "./AccessReviewSubordinatePermissionModel";

interface IAccessReviewSubordinateStatistics {
	permissionsStatus: {
		pending: number;
		approved: number;
		denied: number;
		flagged: number;
	};
}

const defaults = {
	accessReviewReportId: "",
	accessReviewReport: null as AccessReviewReportModel | null,
	accessReviewSubordinatePermissions: null as List<AccessReviewSubordinatePermissionModel> | null,
	id: "",
	permissionsCount: null as number | null,
	statistics: null as IAccessReviewSubordinateStatistics | null,
	userId: ""
};

export type TAccessReviewSubordinateModel = typeof defaults;

export class AccessReviewSubordinateModel extends Record<TAccessReviewSubordinateModel>(defaults) {
	static fromServerData(data: unknown): AccessReviewSubordinateModel {
		const {
			accessReviewReportId,
			accessReviewReport,
			accessReviewSubordinatePermissions,
			id,
			permissionsCount,
			statistics,
			userId
		} = data as TAccessReviewSubordinateModel;
		return new AccessReviewSubordinateModel({
			accessReviewReportId,
			accessReviewReport: accessReviewReport ? AccessReviewReportModel.fromServerData(accessReviewReport) : null,
			accessReviewSubordinatePermissions: accessReviewSubordinatePermissions
				? List(accessReviewSubordinatePermissions.map(AccessReviewSubordinatePermissionModel.fromServerData))
				: null,
			id,
			permissionsCount,
			statistics,
			userId
		});
	}

	public get permissionsSize(): number {
		return this.permissionsCount || this.accessReviewSubordinatePermissions?.size || 0;
	}

	public get notPendingPermissionsSize(): number {
		if (this.statistics) {
			return this.permissionsSize - (this.statistics.permissionsStatus.pending || 0);
		}
		if (this.accessReviewSubordinatePermissions) {
			return this.accessReviewSubordinatePermissions.filter(permission => permission.status !== "pending").size;
		}
		return 0;
	}

	public get status(): "pending" | "inProgress" | "done" {
		if (this.notPendingPermissionsSize > 0) {
			return this.notPendingPermissionsSize === this.permissionsSize ? "done" : "inProgress";
		}
		return "pending";
	}
}
