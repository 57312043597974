import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	collapsedValues: {
		alignItems: "baseline",
		display: "flex",
		flexDirection: "column",
		gap: "0.25rem"
	},
	chip: {
		// chips are to big for input causing it to grow when chips are added
		fontSize: "var(--typography-small-font-size)",
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
		"&:hover": {
			cursor: "pointer",
			backgroundColor: "var(--color-grey-background)"
		}
	},
	readonlyChip: {
		backgroundColor: "var(--color-grey-background)",
		cursor: "inherit",
		"&:hover": {
			cursor: "inherit"
		}
	}
});
