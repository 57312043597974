import React from "react";
import { AccountIcon } from "components/ui/Icons/AccountIcon";
import { useStyles } from "./styles";
import { EntityNode } from "../EntityNode";

type TProps = {
	actions?: React.ReactNode;
	content: React.ReactNode;
	icon?: React.ReactNode;
	onClick?: () => void;
	onDelete?: () => void;
	selected?: boolean;
};

const BORDER_RADIUS = 16;
export const AccountNode: FC<TProps> = props => {
	const classes = useStyles();

	return (
		<EntityNode
			id={`account-node-${props.id}`}
			icon={<AccountIcon className={classes.icon} />}
			{...props}
			color="green"
			borderRadius={BORDER_RADIUS}
		/>
	);
};
