import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	container: {
		alignItems: "flex-start",
		alignSelf: "stretch",
		display: "flex",
		flexDirection: "column",
		gap: "var(--spacing-x10, 40px)"
	},
	durationUnitRow: {
		alignItems: "flex-start",
		alignSelf: "stretch",
		display: "flex",
		flexDirection: "column",
		gap: "var(--spacing-x4, 16px)",
		justifyContent: "center"
	},
	durationUnitRowCards: {
		alignContent: "center",
		alignItems: "center",
		alignSelf: "stretch",
		display: "flex",
		flexWrap: "wrap",
		gap: "var(--spacing-x4, 16px)"
	}
});
