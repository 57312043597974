import constate from "constate";
import { List } from "immutable";
import { useCallback, useState } from "react";
import { useSearchParams, useNavigate, useMatch } from "react-router-dom";
import {
	createNewTicket as apiCreateNewTicket,
	respondToTicket as apiRespondToTicket,
	getTicket as apiGetTicket
} from "api/tickets";
import { useAuthenticatedUser } from "hooks/useAuthenticatedUser";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { useIntegrations } from "hooks/useIntegrations";
import { useIsOpenState } from "hooks/useIsOpenState";
import { useLoadingState } from "hooks/useLoadingState";
import { notEmpty } from "utils/comparison";
import ApiError from "utils/errors/apiError";
import { escapeJson } from "utils/strings";
import { useNewTicketForm } from "./components/NewTicketForm/hooks/useNewTicketForm";
import { parseNewTicketFormData } from "./components/NewTicketForm/utils/formParsingUtils";
import type { IntegrationModel } from "models/IntegrationModel";
import type { TicketModel } from "models/TicketModel";
import type { UserModel } from "models/UserModel";
import type { IsNullError } from "utils/errors/isNullError";
import type { TTicketCreation } from "./components/NewTicketForm";
import type { TFormData, TNewTicketFormType, TPrePopulatedFormData } from "./components/NewTicketForm/types";

const useNewTicket = () => {
	const { user: currentUser } = useAuthenticatedUser();
	const [formType, setFormType] = useState<TNewTicketFormType>("search");
	const [receiverUser, setReceiverUser] = useState<UserModel | null>(currentUser ?? null);
	const integrations = useIntegrations();
	const [formResult, setFormResult] = useState<"success" | "fillMissingActors" | "receiverMissingActors">("success");
	const formState = useNewTicketForm(receiverUser);

	const { isLoading: isOriginalTicketLoading, withLoader: withOriginalTicketLoader } = useLoadingState();
	const [originalTicket, setOriginalTicket] = useState<TicketModel | null>(null);
	const [integrationsWithMissingActors, setIntegrationsWithMissingActors] = useState<IntegrationModel[]>([]);

	const [searchParams, setSearchParams] = useSearchParams();
	const navigate = useNavigate();
	const isMicrosoftTeams = Boolean(useMatch("/microsoftTeams/newRequest"));

	const formModalOpenState = useIsOpenState();
	const openGlobalErrorModal = useOpenGlobalErrorModal();

	const resetPage = useCallback(() => {
		setIntegrationsWithMissingActors([]);
		setReceiverUser(currentUser ?? null);
		formState.actions.reset();
		setFormType("search");
		setSearchParams({});
	}, [currentUser, formState.actions, setSearchParams]);

	const onSuccess = useCallback(async () => {
		const createNewTicket = async () => {
			try {
				const { duration, receiverId, receiverIntegrationActorIds, targets, ticketingIntegrationTicketId } =
					parseNewTicketFormData(
						{
							duration: formState.state.duration,
							receiverIntegrationActorIds: formState.state.receiverIntegrationActorIds,
							targets: formState.state.selectedItems.toArray(),
							justification: formState.state.justification,
							ticketingIntegrationTicketId: formState.state.ticketingIntegrationTicketId,
							receiverUser
						},
						true
					) as TTicketCreation;
				await apiCreateNewTicket({
					comment: escapeJson(formState.state.justification),
					ticketingIntegrationTicketId,
					duration,
					receiverId,
					receiverIntegrationActorIds,
					targets
				});
				setFormResult("success");
				formModalOpenState.open();
				if (!isMicrosoftTeams) {
					navigate("/request");
				}
				setOriginalTicket(null);
			} catch (error) {
				const apiError = error as ApiError;
				if (
					apiError.errorId &&
					apiError.params &&
					apiError.errorId === "user.actor.notFound" &&
					apiError.params.integrationId
				) {
					setIntegrationsWithMissingActors(
						integrations?.has(apiError.params.integrationId as string)
							? [integrations.get(apiError.params.integrationId as string)!]
							: []
					);
					setFormResult("fillMissingActors");
					formModalOpenState.open();
				} else {
					openGlobalErrorModal(error as IsNullError);
				}
			}
		};

		const closeOriginalTicketIfNeeded = async () => {
			if (searchParams.get("originalTicket") && originalTicket?.id) {
				await apiRespondToTicket(originalTicket.id, false);
			}
		};

		await createNewTicket();
		await closeOriginalTicketIfNeeded();
		resetPage();
	}, [
		formModalOpenState,
		formState.state,
		integrations,
		isMicrosoftTeams,
		navigate,
		openGlobalErrorModal,
		originalTicket?.id,
		receiverUser,
		resetPage,
		searchParams
	]);

	const submitAfterFillingMissingData = useCallback(() => {
		void onSuccess();
	}, [onSuccess]);

	const onMissingActors = useCallback(
		(missingIntegrationIds: string[], receiverUser: UserModel) => {
			const missingIntegrations = missingIntegrationIds.map(id => integrations?.get(id)).filter(notEmpty);
			if (!missingIntegrations.length) return;
			setIntegrationsWithMissingActors(missingIntegrations);

			if (receiverUser?.id === currentUser?.id) {
				setFormResult("fillMissingActors");
			} else {
				setFormResult("receiverMissingActors");
			}

			formModalOpenState.open();
		},
		[currentUser?.id, formModalOpenState, integrations]
	);

	const getTicketPreview = useCallback(
		(formData: Pick<TFormData, "duration" | "receiverIntegrationActorIds" | "receiverUser" | "targets">) => {
			if (!formData.duration || !formData.targets.length || !formData.receiverUser) return;
			const ticketCreationValues = parseNewTicketFormData(formData as TFormData);
			const { duration, receiverId, receiverIntegrationActorIds, targets } = ticketCreationValues;

			return apiCreateNewTicket({
				comment: "empty",
				duration,
				receiverId,
				receiverIntegrationActorIds,
				targets,
				dry: true
			});
		},
		[]
	);

	const initFromOriginalTicket = useCallback(async () => {
		const originalTicketId = searchParams.get("originalTicket");
		if (!originalTicketId) return;

		const ticket = await withOriginalTicketLoader(apiGetTicket(originalTicketId));
		setOriginalTicket(ticket);
	}, [searchParams, withOriginalTicketLoader]);

	const setReceiverUserAndResetItems = useCallback(
		(user: UserModel | null) => {
			setReceiverUser(user);
			formState.actions.setSelectedItems(List());
		},
		[formState.actions]
	);

	const prePopulateForm = useCallback(
		(formData: TPrePopulatedFormData) => {
			if (formData.receiverUser) setReceiverUser(formData.receiverUser);
			if (formData.ticketingIntegrationTicketId)
				formState.actions.setTicketingIntegrationTicketId(formData.ticketingIntegrationTicketId ?? null);
			if (formData.justification) formState.actions.setJustification(formData.justification ?? "");
			if (formData.targets) formState.actions.setSelectedItems(formData.targets);
			if (formData.duration) formState.actions.setDuration(formData.duration);
		},
		[formState.actions]
	);

	return {
		state: {
			formType,
			formState: formState.state,
			searchParams,
			isOriginalTicketLoading,
			formModalOpenState,
			formResult,
			integrationsWithMissingActors,
			hasIntegrations: Boolean(integrations),
			originalTicket,
			receiverUser
		},
		actions: {
			setFormType,
			formActions: formState.actions,
			submitAfterFillingMissingData,
			onMissingActors,
			onSuccess,
			// this is to prevent doing hacks to bypass useEffect when we set them together (prePopulateForm)
			setReceiverUser: setReceiverUserAndResetItems,
			getTicketPreview,
			initFromOriginalTicket,
			prePopulateForm
		}
	};
};

export const [NewTicketProvider, useNewTicketPageContext, useNewTicketModalContext, useNewTicketFormContext] = constate(
	useNewTicket,
	context => {
		// useNewTicketPageContext
		return {
			state: {
				isOriginalTicketLoading: context.state.isOriginalTicketLoading,
				hasIntegrations: context.state.hasIntegrations
			},
			actions: { initFromOriginalTicket: context.actions.initFromOriginalTicket }
		};
	},
	context => {
		// useNewTicketModalContext
		const { state, actions } = context;
		return {
			state: {
				formResult: state.formResult,
				isOpen: state.formModalOpenState.isOpen,
				receiverUser: state.receiverUser,
				integrationsWithMissingActors: state.integrationsWithMissingActors
			},
			actions: {
				onClose: state.formModalOpenState.close,
				onSubmit: actions.submitAfterFillingMissingData
			}
		};
	},
	context => {
		// useNewTicketFormContext
		const { state, actions } = context;
		return {
			state: {
				formType: state.formType,
				formState: state.formState,
				receiverUser: state.receiverUser,
				originalTicket: state.originalTicket,
				searchParams: state.searchParams
			},
			actions: {
				setFormType: actions.setFormType,
				formActions: actions.formActions,
				onMissingActors: actions.onMissingActors,
				onSuccess: actions.onSuccess,
				getTicketPreview: actions.getTicketPreview,
				setReceiverUser: actions.setReceiverUser,
				prePopulateForm: actions.prePopulateForm
			}
		};
	}
);
