import { useEffect, useMemo } from "react";
import { useApiTokensContext } from "context/apiTokensContext";

export const useApiTokens = () => {
	const {
		state: { apiTokens },
		actions: { loadApiTokens }
	} = useApiTokensContext();

	useEffect(() => {
		void loadApiTokens();
	}, [loadApiTokens]);

	return useMemo(() => apiTokens?.toList(), [apiTokens]);
};
