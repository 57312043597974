import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { getIntegrationResourceTags } from "api/integrationResources";
import { FilterExpressionEmptyState } from "components/common/FilterExpressionEmptyState";
import { Chip } from "components/ui/chips/Chip";
import { FilterExpression } from "components/ui/filters/FilterExpression";
import { TagIcon } from "components/ui/Icons/TagIcon";
import { TextOption } from "components/ui/selectOptions/TextOption";
import { OPERATORS } from "filters/integrationResource/integrationResourceTagsFilter";
import { getLabel, type TOptionComponentProps } from "utils/ui/select";
import { useMultiSelectFilterExpression } from "../useFilterExpression";
import type { TConditionName } from "filters/condition.interface";
import type { TFilterExpressionProps } from "../filters.types";

const FILTER_NAME: TConditionName = "resourceTags";
export const IntegrationResourceTagsFilterExpression = ({
	filter,
	updateFilter,
	removeFilter,
	filterName
}: TFilterExpressionProps<"resourceTags">) => {
	const { t } = useTranslation("translation", { keyPrefix: "filters" });

	const renderSelected = useCallback(
		(value: string) => {
			const onDelete = () =>
				updateFilter(filterName, {
					...filter,
					value: filter.value.filter(val => val !== value)
				});
			return (
				<Chip onDelete={onDelete} PrefixIcon={TagIcon} variant="regular" size="large" selected>
					{value}
				</Chip>
			);
		},
		[updateFilter, filter, filterName]
	);

	const { onOptionSelect, onOperatorSelect, options, isLoading, getMoreOptions, onReset, onRemoveFilter } =
		useMultiSelectFilterExpression<"resourceTags">(
			{ filter, updateFilter, removeFilter, filterName },
			getIntegrationResourceTags,
			"is"
		);

	const getOptionRenderer = useCallback(
		(props: TOptionComponentProps<string>) => <TextOption {...props} PrefixIcon={TagIcon} />,
		[]
	);

	return (
		<FilterExpression
			emptyState={<FilterExpressionEmptyState text={t(`emptyState.${FILTER_NAME}`)} Icon={TagIcon} />}
			getMoreOptions={getMoreOptions}
			getOptionLabel={getLabel}
			inputPlaceholder={t(`placeholders.${FILTER_NAME}`)}
			isLoading={isLoading}
			onOperatorSelect={onOperatorSelect}
			onOptionSelect={onOptionSelect}
			onRemoveFilter={onRemoveFilter}
			onReset={onReset}
			operators={OPERATORS}
			optionRenderer={getOptionRenderer}
			options={options}
			relation="or"
			renderSelected={renderSelected}
			selected={filter.value}
			selectedOperator={filter.operator}
			title={t(`filterNames.${FILTER_NAME}`)}
			type="multiSelectAndText"
		/>
	);
};
