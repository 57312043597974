import classNames from "classnames";
import React from "react";
import { useStyles } from "./styles";
import { Tooltip } from "../Tooltip";

type TProps = {
	disabled?: boolean;
	onClick?: () => void;
	selected?: boolean;
	size?: "medium" | "large";
	tooltip?: React.ReactNode;
};

export const BasicCard: FC<TProps> = ({
	children,
	className,
	disabled = false,
	innerRef,
	onClick,
	selected = false,
	size = "large",
	tooltip
}) => {
	const classes = useStyles();

	const sizeClassName = size === "large" ? classes.large : classes.medium;

	const content = (
		<div
			className={classNames(
				classes.baseCard,
				sizeClassName,
				{ [classes.disabled]: disabled, [classes.selected]: selected, [classes.clickable]: !!onClick },
				className
			)}
			onClick={onClick}
			ref={innerRef}>
			{children}
		</div>
	);
	return tooltip ? <Tooltip content={tooltip}>{content}</Tooltip> : content;
};
