import React from "react";
import { makeIcon } from "components/ui/Icon";

export const DeletedNodeLinesIcon = makeIcon(
	<svg viewBox="0 0 24 24" fill="none">
		<rect
			width="24.1263"
			height="2.2079"
			transform="matrix(0.737255 0.675615 0.675615 -0.737255 2.21411 5.5353)"
			fill="white"
		/>
		<rect
			width="24.1263"
			height="2.2079"
			rx="1.10395"
			transform="matrix(0.737255 0.675615 0.675615 -0.737255 3.70581 3.90752)"
			fill="currentColor"
		/>
	</svg>
);
