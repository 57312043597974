import { useCallback } from "react";
import {
	bulkApproveAccessReviewPermissions as apiBulkApproveAccessReviewPermissions,
	bulkDenyAccessReviewPermissions as apiBulkDenyAccessReviewPermissions,
	bulkFlagAccessReviewPermissions as apiBulkFlagAccessReviewPermissions,
	TAccessReviewPermissionType
} from "api/accessReviewPermissions";
import { useLoadingState } from "./useLoadingState";
import type { TPermissionStatusOptions } from "utils/accessReview";

export const useAccessReviewPermissions = () => {
	const { isLoading, withLoader } = useLoadingState();

	const bulkApproveAccessReviewPermissions = useCallback(
		async (permissionIds: string[], type: TAccessReviewPermissionType) => {
			return withLoader(apiBulkApproveAccessReviewPermissions(permissionIds, type));
		},
		[withLoader]
	);

	const bulkDenyAccessReviewPermissions = useCallback(
		async (permissionIds: string[], type: TAccessReviewPermissionType) => {
			return withLoader(apiBulkDenyAccessReviewPermissions(permissionIds, type));
		},
		[withLoader]
	);

	const bulkFlagAccessReviewPermissions = useCallback(
		async (permissionIds: string[], type: TAccessReviewPermissionType) => {
			return withLoader(apiBulkFlagAccessReviewPermissions(permissionIds, type));
		},
		[withLoader]
	);

	const getStatusAction = useCallback(
		(status: Exclude<TPermissionStatusOptions, "pending">) => {
			switch (status) {
				case "approve":
					return bulkApproveAccessReviewPermissions;
				case "deny":
					return bulkDenyAccessReviewPermissions;
				case "flag":
					return bulkFlagAccessReviewPermissions;
				default:
					throw new Error("Invalid status: " + (status as string));
			}
		},
		[bulkApproveAccessReviewPermissions, bulkDenyAccessReviewPermissions, bulkFlagAccessReviewPermissions]
	);

	return {
		bulkApproveAccessReviewPermissions,
		bulkDenyAccessReviewPermissions,
		bulkFlagAccessReviewPermissions,
		isLoading,
		getStatusAction
	};
};
