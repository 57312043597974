import { useCallback, useState, RefObject } from "react";
import { useResizeDetector } from "react-resize-detector";

export const useIsOverflowed = (elementRef: RefObject<HTMLElement | null>) => {
	const [overflowedX, setOverflowedX] = useState(false);
	const [overflowedY, setOverflowedY] = useState(false);

	const calculateOverflow = useCallback(() => {
		const element = elementRef?.current;
		const scrollWidth = element?.scrollWidth ?? 0;
		const clientWidth = element?.clientWidth ?? 0;
		const scrollHeight = element?.scrollHeight ?? 0;
		const clientHeight = element?.clientHeight ?? 0;

		setOverflowedX(scrollWidth > clientWidth);
		setOverflowedY(scrollHeight > clientHeight);
	}, [elementRef]);

	useResizeDetector({ targetRef: elementRef, onResize: calculateOverflow });

	return { overflowedX, overflowedY };
};
