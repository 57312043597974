import { createUseStyles } from "react-jss";

export const ROLE_BAR_INTEGRATION_WIDTH = "minmax(100px, 400px)";

export const useStyles = createUseStyles({
	container: {
		alignItems: "center",
		display: "grid",
		gap: "28px",
		gridTemplateColumns: ROLE_BAR_INTEGRATION_WIDTH,
		"&$withCheckbox": {
			gridTemplateColumns: `auto ${ROLE_BAR_INTEGRATION_WIDTH}`
		}
	},
	innerContainer: {
		alignItems: "center",
		display: "flex",
		gap: "8px"
	},
	tooltipContainer: {
		display: "flex",
		flexDirection: "column",
		gap: "20px",
		padding: "12px"
	},
	integrationWithImage: {
		alignItems: "center",
		display: "flex",
		gap: "12px",
		minWidth: 0,
		"& > img": {
			height: "24px",
			width: "24px"
		}
	},
	withCheckbox: {}
});
