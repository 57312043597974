import React from "react";
import { HiddenChip } from "components/ui/chips/HiddenChip";
import { TagChip } from "components/ui/chips/TagChip";
import { Tooltip } from "components/ui/Tooltip";
import { useStyles } from "./styles";

type TProps = {
	tags: string[];
};

export const MAX_CHIPS_BEFORE_HIDDEN_CHIP = 1;
export const RoleBarTag: FC<TProps> = ({ className, tags, innerRef }) => {
	const classes = useStyles();

	if (tags.length === 0) {
		return null;
	}

	if (tags.length <= MAX_CHIPS_BEFORE_HIDDEN_CHIP) {
		return (
			<div className={classes.singleChip}>
				<TagChip variant="regular" className={className}>
					{tags.at(0)}
				</TagChip>
			</div>
		);
	}

	return (
		<Tooltip
			trigger="hover"
			placement="bottom"
			content={
				<div className={classes.container}>
					{tags.map(tag => (
						<TagChip key={tag} variant="regular" className={className} innerRef={innerRef}>
							{tag}
						</TagChip>
					))}
				</div>
			}>
			<HiddenChip variant="regular" type="tag" className={className}>
				{`${tags.length} +`}
			</HiddenChip>
		</Tooltip>
	);
};
