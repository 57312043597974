import { List, Record } from "immutable";
import { AccessReviewTemplateIntegrationRuleModel } from "./AccessReviewTemplateIntegrationRuleModel";
import { AccessReviewTemplateResourceRuleModel } from "./AccessReviewTemplateResourceRuleModel";
import type { Require } from "types/utilTypes";

export type TFilterType = "include" | "exclude";
export type TAccessReviewTemplateReviewers = "manager" | "resourceOwner";

const defaults = {
	accessReviewsCount: null as number | null,
	createdAt: new Date(0),
	creatorId: "",
	description: "",
	groupFilterType: "exclude" as TFilterType,
	groupIds: [] as string[],
	id: "",
	includeUserless: true,
	integrationFilterType: "exclude" as TFilterType,
	integrationIds: [] as string[],
	integrationRules: null as List<AccessReviewTemplateIntegrationRuleModel> | null,
	isDeleted: false,
	name: "",
	immediateRevoke: false,
	resourceRules: null as List<AccessReviewTemplateResourceRuleModel> | null,
	reviewerTypes: [] as TAccessReviewTemplateReviewers[]
};

type TAccessReviewTemplateModel = typeof defaults;

export class AccessReviewTemplateModel extends Record<TAccessReviewTemplateModel>(defaults) {
	static fromServerData(data: unknown): AccessReviewTemplateModel {
		const {
			accessReviewsCount,
			createdAt,
			creatorId,
			description,
			groupFilterType,
			groupIds,
			id,
			includeUserless,
			integrationFilterType,
			integrationIds,
			integrationRules,
			isDeleted,
			name,
			resourceRules,
			reviewerTypes,
			immediateRevoke
		} = data as TAccessReviewTemplateModel;
		return new AccessReviewTemplateModel({
			accessReviewsCount,
			createdAt: new Date(createdAt),
			creatorId,
			description,
			groupFilterType,
			groupIds,
			id,
			includeUserless,
			integrationFilterType,
			integrationIds,
			integrationRules: integrationRules
				? List(integrationRules.map(AccessReviewTemplateIntegrationRuleModel.fromServerData))
				: null,
			isDeleted,
			name,
			resourceRules: resourceRules
				? List(resourceRules.map(AccessReviewTemplateResourceRuleModel.fromServerData))
				: null,
			reviewerTypes,
			immediateRevoke
		});
	}
}

export type TFullTAccessReviewTemplateModel = Require<AccessReviewTemplateModel, "integrationRules" | "resourceRules">;
