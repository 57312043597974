import { Map } from "immutable";
import groupBy from "lodash/groupBy";
import { IntegrationActorModel } from "models/IntegrationActorModel";
import { apiReq } from "utils/api/apiReq";

export async function getUserIntegrationActorsGroupedByIntegration(
	userId: string
): Promise<Map<string, IntegrationActorModel[]>> {
	const { data } = await apiReq("GET", `/v1/users/${userId}/integrationActors`);

	const actors: IntegrationActorModel[] = data.map((actor: unknown) => IntegrationActorModel.fromServerData(actor));
	const actorsGrouped = groupBy(actors, actor => actor.integrationId);

	return Map(Object.entries(actorsGrouped));
}

export async function deleteUserIntegrationActor(userId: string, integrationActorId: string): Promise<{ ok: boolean }> {
	const { data } = await apiReq("DELETE", `/v1/users/${userId}/integrationActors`, { integrationActorId });
	return data as { ok: boolean };
}

export async function addUserIntegrationActor(userId: string, integrationActorId: string): Promise<{ ok: boolean }> {
	const { data } = await apiReq("POST", `/v1/users/${userId}/integrationActors`, { integrationActorId });
	return data as { ok: boolean };
}
