import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	modal: {
		overflow: "unset"
	},
	content: {
		display: "flex",
		flexDirection: "column",
		gap: "20px",
		padding: "0 20px"
	},
	roleForm: {
		display: "flex",
		gap: "24px",
		flexDirection: "column"
	},
	input: {
		width: "448px"
	},
	error: {
		color: "var(--color-red-dark)"
	},
	actions: {
		marginTop: "8px"
	}
});
