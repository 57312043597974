import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	option: {
		alignItems: "flex-start",
		display: "flex",
		flexDirection: "row",
		flexGrow: 1,
		justifyContent: "space-between",
		"&.disabled": {
			color: "var(--color-grey-disabled)"
		}
	},
	modalContent: {
		display: "flex",
		flexDirection: "column",
		gap: "12px",
		paddingBottom: "20px",
		paddingRight: "12px",
		width: "480px"
	}
});
