import isEmpty from "lodash/isEmpty";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useResizeDetector } from "react-resize-detector";
import { ChipContainer } from "components/ui/ChipContainer";
import { MAX_CHIP_WIDTH } from "components/ui/chips/Chip/styles";
import { IntegrationChip } from "components/ui/chips/IntegrationChip";
import { StaticChip } from "components/ui/chips/StaticChip";
import { DragIcon } from "components/ui/Icons/DragIcon";
import { IntegrationIcon } from "components/ui/Icons/IntegrationIcon";
import { Chips } from "components/ui/MultipleSelect/components/Chips";
import { Typography } from "components/ui/Typography";
import { getChipTypeFromCondition } from "./rules.utils";
import { useStyles } from "./styles";
import type { IRenderChipParams } from "components/ui/MultipleSelect";
import type { Conditions } from "models/RuleModel";

export const PriorityCell: FC<{ priority: number }> = React.memo(function PriorityCell({ priority }) {
	const classes = useStyles();

	return (
		<div className={classes.priorityCellContainer}>
			<DragIcon size="medium" />
			<div className={classes.priorityCellText}>
				<Typography className={classes.priorityCellText} variant="title_sb">
					{priority}
				</Typography>
			</div>
		</div>
	);
});

const IntegrationChipElement: FC<IRenderChipParams<string>> = ({ option }) => {
	return <IntegrationChip variant="regular" readonly integration={option} />;
};
const INTEGRATIONS_CHIPS_DEFAULT_WIDTH = 84;
export const ApplyToCell: FC<{ condition: Conditions["integration"] }> = React.memo(function ApplyToCell({
	condition
}) {
	const { t } = useTranslation();
	const classes = useStyles();
	const { ref: parentRef, width: parentWidth } = useResizeDetector();

	const integrationIds = condition?.value;
	const getOptionLabel = useCallback((option: string) => option, []);
	const integrationsChips = useMemo(() => {
		const maxChips = Math.max(
			0,
			Math.floor((parentWidth ?? INTEGRATIONS_CHIPS_DEFAULT_WIDTH) / MAX_CHIP_WIDTH) * 4 - 1
		);
		if (!integrationIds || isEmpty(integrationIds)) {
			return (
				<StaticChip PrefixIcon={IntegrationIcon} size="medium" variant="regular">
					{t("pages.rules.ruleBar.allIntegrations")}
				</StaticChip>
			);
		}

		return (
			<Chips
				limit={maxChips}
				limitChipType="integration"
				limitChipSize="medium"
				values={integrationIds}
				size="medium"
				getOptionLabel={getOptionLabel}
				ChipElement={IntegrationChipElement}
			/>
		);
	}, [integrationIds, t, parentWidth, getOptionLabel]);

	return (
		<div className={classes.applyToCellContainer}>
			{condition && condition.operator !== "is" ? (
				<Typography variant="text_reg">{t(`filters.filterOperators.${condition.operator}`)}:</Typography>
			) : null}
			<div className={classes.applyToCellListContainer} ref={parentRef}>
				{integrationsChips}
			</div>
		</div>
	);
});

export const ConditionsCell: FC<{ conditions: Conditions }> = React.memo(function ConditionsCell({ conditions }) {
	const { t } = useTranslation();
	const classes = useStyles();

	return (
		<div className={classes.conditionsCellContainer}>
			{conditions
				.toSeq()
				.entrySeq()
				.filter(([filterName, cond]) => filterName !== "integration" && cond?.value)
				.map(([filterName, condition]) => (
					<div key={filterName} className={classes.conditionContainer}>
						<Typography variant="text_reg" className={classes.fieldNameText}>
							<Typography noWrap variant="text_title_sb">
								{t(`filters.filterNames.${filterName}`)}
							</Typography>
							<Typography noWrap variant="text_reg">
								{t(`filters.filterOperators.${condition!.operator ?? "is"}`)}:
							</Typography>
						</Typography>
						<ChipContainer
							readonly
							className={classes.conditionChips}
							variant="regular"
							size="medium"
							limitChipType={getChipTypeFromCondition(filterName)}
							type={getChipTypeFromCondition(filterName)}
							chips={condition!.value}
						/>
					</div>
				))}
		</div>
	);
});
