import { Record } from "immutable";
import uniq from "lodash/uniq";
import { validateFilterEqualityOperator } from "utils/validation/filterValidation";
import type { IFilter } from "types/filters";

const filterName = "integrationResourceIntegrationId";

const defaults = {
	name: filterName as typeof filterName,
	value: [] as string[],
	operator: "is" as "is" | "isNot"
};

type TDefaults = typeof defaults;

export class IntegrationResourceIntegrationIdFilter
	extends Record<TDefaults>(defaults)
	implements IFilter<TDefaults["value"], TDefaults["operator"], TDefaults["name"]>
{
	static filterName: typeof filterName = filterName;

	static fromURLSearchParams(urlSearchParams: URLSearchParams): IntegrationResourceIntegrationIdFilter | null {
		const integrationResourceIntegrationId = uniq(urlSearchParams.getAll("integrationResourceIntegrationId"));
		const integrationResourceIntegrationIdOperator =
			urlSearchParams.get("integrationResourceIntegrationIdOperator") || undefined;
		if (
			integrationResourceIntegrationId.length === 0 ||
			!validateFilterEqualityOperator(integrationResourceIntegrationIdOperator)
		) {
			return null;
		}
		return new IntegrationResourceIntegrationIdFilter({
			value: integrationResourceIntegrationId,
			operator: integrationResourceIntegrationIdOperator
		});
	}

	toURLSearchParams(): URLSearchParams {
		const params = new URLSearchParams();
		if (!this.value.length) {
			return params;
		}
		for (const value of uniq(this.value)) {
			params.append("integrationResourceIntegrationId", value);
		}
		params.append("integrationResourceIntegrationIdOperator", this.operator);
		return params;
	}
}

export type TIntegrationResourceIntegrationIdFilter = Omit<TDefaults, "name">;
