import { List, Map, isRecord } from "immutable";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ignoreAbortError } from "utils/api/ignoreAbortError";
import { notEmpty } from "utils/comparison";
import type { ISortOptions } from "types/pagination";
import type { IPaginationResponse, TQsFilter } from "utils/pagination";
import type { IPaginatedSearchOptions } from "utils/searchUtils";

export const ASC = "ASC";
export const DESC = "DESC";

type TFetchItems<T> = (
	paginationOptions: IPaginatedSearchOptions,
	...args: any[]
) => Promise<IPaginationResponse<T | null> | null>;

const getPaginationLocation = (pageResults: Map<number, List<string>>, itemId: string) => {
	let pageNumber = -1,
		pageIndex = -1;
	pageResults.find((page, number) => {
		pageIndex = page.findIndex(id => id === itemId);
		pageNumber = number;
		return pageIndex > -1;
	});

	return { pageNumber, pageIndex };
};

export type TUsePaginationOptions<T> = {
	fetchItems: TFetchItems<T>;
	perPage?: number;
	sortOrder?: ISortOptions["order"];
	sortFields?: ISortOptions["sortFields"];
	initialFilter?: TQsFilter | null;
	disableSearch?: boolean;
	body?: unknown;
};

const usePagesLoadingState = () => {
	const loadingRef = useRef(new Set<number>());

	const addLoading = useCallback((page: number) => {
		loadingRef.current.add(page);
	}, []);

	const removeLoading = useCallback((page: number) => {
		loadingRef.current.delete(page);
	}, []);

	const isLoading = useCallback((page: number) => {
		return loadingRef.current.has(page);
	}, []);

	const clearLoading = useCallback(() => {
		loadingRef.current.clear();
	}, []);

	return { addLoading, clearLoading, removeLoading, isLoading };
};

export const usePagination = <T extends { id: string }>({
	fetchItems,
	perPage = 10,
	sortOrder = DESC,
	sortFields,
	initialFilter = null,
	disableSearch = false,
	body
}: TUsePaginationOptions<T>) => {
	const [totalPages, setTotalPages] = useState<number>(0);
	const [totalResults, setTotalResults] = useState<number>(0);
	const [resultsById, setResultsById] = useState(Map<string, T>());
	const [pageResults, setPageResults] = useState(Map<number, List<string>>());
	const [filters, setFilters] = useState<TQsFilter | undefined>(initialFilter || undefined);
	const [lastPageNumber, setLastPageNumber] = useState<number>(0);
	const [currentPageNumber, setCurrentPageNumber] = useState(1);
	const { addLoading, clearLoading, removeLoading, isLoading } = usePagesLoadingState();

	const clearData = useCallback(() => {
		setPageResults(current => current.clear());
		setResultsById(current => current.clear());
		setTotalResults(0);
		setTotalPages(0);
		setLastPageNumber(0);
		setCurrentPageNumber(1);
	}, []);

	const handleResponse = useCallback(
		({
			response,
			page,
			changePage = true,
			resetResults = false,
			resetLastPage = false
		}: {
			response: IPaginationResponse<T | null> | null;
			page: number;
			changePage?: boolean;
			resetResults?: boolean;
			resetLastPage?: boolean;
		}) => {
			if (!response) {
				clearData();
				return;
			}
			const { pagination } = response;
			const result = response.result as List<T>;
			setResultsById(current =>
				result.reduce((acc, element) => acc.set(element.id, element), resetResults ? current.clear() : current)
			);
			setPageResults(current =>
				(resetResults ? current.clear() : current).set(
					pagination.page,
					result.map(({ id }) => id)
				)
			);
			setTotalResults(Number(pagination.totalResults));
			setTotalPages(Number(pagination.totalPages));
			setLastPageNumber(current => (page > current || resetLastPage ? page : current));
			if (changePage) {
				setCurrentPageNumber(page);
			}
			removeLoading(page);
		},
		[clearData, removeLoading]
	);

	const fetchPage = useCallback(
		async (page: number, changePage?: boolean, resetResults?: boolean, resetLastPage?: boolean) => {
			if (resetResults) {
				clearData();
			}
			if (isLoading(page)) return;
			addLoading(page);
			const fetchOptions: IPaginatedSearchOptions = {
				pagination: { page, perPage },
				sort: { sortFields, order: sortOrder },
				filters
			};
			await ignoreAbortError(body ? fetchItems(fetchOptions, body) : fetchItems(fetchOptions), response =>
				handleResponse({ response, page, changePage, resetResults, resetLastPage })
			);
		},
		[isLoading, addLoading, perPage, sortFields, sortOrder, filters, body, fetchItems, clearData, handleResponse]
	);

	useEffect(() => {
		clearLoading();
	}, [clearLoading, fetchPage]);

	const getPage = useCallback(
		async (page: number, changePage = true, forceReload = false) => {
			if (!pageResults.has(page) || forceReload) {
				return fetchPage(page, changePage, forceReload);
			}
			return pageResults.get(page);
		},
		[pageResults, fetchPage]
	);

	const items = useMemo(() => {
		if (pageResults.valueSeq().size === 0) return null;
		return pageResults
			.keySeq()
			.sort()
			.reduce(
				(acc, cur) =>
					acc.concat(
						pageResults
							.get(cur)
							?.map(id => resultsById.get(id))
							.filter(notEmpty) || []
					),
				List<T>()
			);
	}, [pageResults, resultsById]);

	const itemsForVirtualTable = useMemo(() => {
		if (pageResults.valueSeq().size === 0) return null;
		const items = [] as (T | undefined)[];
		for (let i = 1; i <= lastPageNumber; i++) {
			const pageItems = pageResults.get(i)?.map(id => resultsById.get(id));
			items.push(...(pageItems?.toArray() || Array(perPage).fill(undefined)));
		}
		return items;
	}, [pageResults, resultsById, lastPageNumber, perPage]);

	const setItem = useCallback(
		(item: T) => setResultsById(current => (!current.has(item.id) ? current : current.set(item.id, item))),
		[]
	);

	const setPartialItem = useCallback(
		(item: Partial<T> & { id: string }) =>
			setResultsById(current => {
				if (!current.has(item.id)) return current;
				const currentElement = current.get(item.id)!;
				if (isRecord(currentElement)) {
					return current.set(item.id, currentElement.merge(item));
				}
				return current.set(item.id, Object.assign({}, currentElement, item));
			}),
		[]
	);

	const removeItem = useCallback(
		(itemId: string) => {
			if (!resultsById.has(itemId)) return;

			const { pageNumber, pageIndex } = getPaginationLocation(pageResults, itemId);
			setTotalResults(current => Math.max(current - 1, 0));
			setResultsById(current => (!current.has(itemId) ? current : current.remove(itemId)));
			setPageResults(curr => curr.removeIn([pageNumber, pageIndex]));

			// Force reload of the pages from pageNumber index if there are more pages to load
			if (currentPageNumber !== totalPages) {
				for (let i = pageNumber; i <= lastPageNumber; i++) {
					void getPage(i, true, true);
				}
			}
		},
		[currentPageNumber, getPage, lastPageNumber, pageResults, resultsById, totalPages]
	);

	useEffect(() => {
		if (disableSearch) return;
		void fetchPage(1, true, true, true);
	}, [disableSearch, fetchPage]);

	useEffect(() => {
		if (!pageResults.has(currentPageNumber) && !disableSearch) {
			void getPage(currentPageNumber);
		}
	}, [pageResults, currentPageNumber, getPage, disableSearch]);

	const page = useMemo(
		() =>
			pageResults
				.get(currentPageNumber)
				?.map(id => resultsById.get(id))
				.filter(notEmpty),
		[currentPageNumber, pageResults, resultsById]
	);

	const lastPage = useMemo(
		() =>
			pageResults
				.get(lastPageNumber)
				?.map(id => resultsById.get(id))
				.filter(notEmpty),
		[lastPageNumber, pageResults, resultsById]
	);

	const changePage = useCallback(
		(pageIndex: number) => {
			setCurrentPageNumber(pageIndex + 1);
		},
		[setCurrentPageNumber]
	);

	return {
		changePage,
		clearData,
		currentPageNumber,
		filters,
		getPage,
		fetchPage,
		isLoading: isLoading(currentPageNumber) || !pageResults.has(currentPageNumber),
		items,
		itemsForVirtualTable,
		lastPage,
		lastPageNumber,
		page,
		removeItem,
		setCurrentPageNumber,
		setFilters,
		setItem,
		setPartialItem,
		setTotalResults,
		totalPages,
		totalResults
	};
};
