import constate from "constate";
import { List, Map } from "immutable";
import { useCallback, useEffect, useMemo, useState } from "react";
import { bulkComment } from "api/accessReviewPermissionComments";
import { useAccessReviewSubordinate } from "hooks/useAccessReviewSubordinate";
import { useAuthenticatedUser } from "hooks/useAuthenticatedUser";
import { useAccessReviewPermissions } from "hooks/useBulkUpdateAccessReviewsPermissions";
import { AccessReviewPermissionCommentModel } from "models/AccessReviewPermissionCommentModel";
import { TAccessReviewPermissionModel, TAccessReviewPermissionStatus } from "models/AccessReviewPermissionModel";
import { AccessReviewReportModel } from "models/AccessReviewReportModel";
import { AccessReviewSubordinatePermissionModel } from "models/AccessReviewSubordinatePermissionModel";
import { IntegrationModel } from "models/IntegrationModel";
import { Require } from "types/utilTypes";
import { STATUS_DICT, TPermissionStatusOptions } from "utils/accessReview";

type TCheckboxStateMap = Map<string, Map<string, boolean>>;

export type TSubordinatePermission = TAccessReviewPermissionModel & {
	status: TAccessReviewPermissionStatus;
	subordinatePermissionId: string;
};

export const getIntegrationCheckboxState = (checkboxesState: TCheckboxStateMap, integration: IntegrationModel) => {
	const integrationCheckboxStates = checkboxesState.get(integration.id);
	if (!integrationCheckboxStates) return "unchecked";
	const approvedPermissions = integrationCheckboxStates.filter(Boolean);
	let currentState: "unchecked" | "checked" | "indeterminate" = "indeterminate";
	if (approvedPermissions.size === 0) {
		currentState = "unchecked";
	}
	if (approvedPermissions.size === integrationCheckboxStates.size) {
		currentState = "checked";
	}
	return currentState;
};

const groupPermissionsByIntegrationId = (
	permissions: Require<AccessReviewSubordinatePermissionModel, "accessReviewPermission">[]
): Map<string, TSubordinatePermission[]> =>
	permissions.reduce((acc, permission) => {
		const integrationId = permission.integrationId;
		return integrationId
			? acc.update(integrationId, current =>
					(current || []).concat([
						{
							status: permission.status,
							subordinatePermissionId: permission.id,
							...permission.accessReviewPermission.toObject(),
							resourceName: permission.resourceName,
							roleName: permission.roleName
						}
					])
				)
			: acc;
	}, Map<string, TSubordinatePermission[]>());

const useSubordinatePermissionReview = ({
	subordinateId,
	report,
	immediateRevoke
}: {
	subordinateId: string;
	report: AccessReviewReportModel;
	immediateRevoke: boolean;
}) => {
	const [checkboxesState, setCheckboxesState] = useState<TCheckboxStateMap>(Map());
	const { getStatusAction, isLoading } = useAccessReviewPermissions();

	const { accessReviewSubordinate, updateSubordinate } = useAccessReviewSubordinate(subordinateId);

	const { user } = useAuthenticatedUser();

	const groupedPermissions = useMemo(() => {
		if (!accessReviewSubordinate || !accessReviewSubordinate.accessReviewSubordinatePermissions) return undefined;
		return groupPermissionsByIntegrationId(
			accessReviewSubordinate.accessReviewSubordinatePermissions.toArray() as Require<
				AccessReviewSubordinatePermissionModel,
				"accessReviewPermission"
			>[]
		);
	}, [accessReviewSubordinate]);

	const totalAmount = useMemo(
		() => checkboxesState?.reduce((sum, permissionGroup) => sum + permissionGroup.count(), 0) || 0,
		[checkboxesState]
	);

	const selectedPermissionsIds = useMemo(
		() =>
			checkboxesState
				.valueSeq()
				.flatMap(permissionGroup => permissionGroup.filter(Boolean).keySeq())
				.toArray(),
		[checkboxesState]
	);

	const resetCheckboxState = useCallback(() => {
		if (groupedPermissions) {
			const newCheckboxState = groupedPermissions.reduce((acc, permissions, key) => {
				return acc.set(
					key,
					Map(
						permissions.reduce((permissionsAcc, permission) => {
							if (!(permission.status === "denied" && immediateRevoke))
								return { ...permissionsAcc, [permission.subordinatePermissionId]: false };
							return permissionsAcc;
						}, {})
					)
				);
			}, Map<string, Map<string, boolean>>());
			setCheckboxesState(newCheckboxState);
		} else {
			setCheckboxesState(Map());
		}
	}, [groupedPermissions, immediateRevoke]);

	const handlePermissionCheckboxChange = useCallback(
		(integrationId: string, permissionId: string, checked: boolean) => {
			setCheckboxesState(current => {
				if (current.has(integrationId)) {
					return current.set(integrationId, current.get(integrationId)!.set(permissionId, checked));
				}
				return current;
			});
		},
		[]
	);

	const removePermissionCheckbox = useCallback((integrationId: string, permissionId: string) => {
		setCheckboxesState(current => {
			if (current.has(integrationId)) {
				return current.set(integrationId, current.get(integrationId)!.delete(permissionId));
			}
			return current;
		});
	}, []);

	const handleIntegrationCheckboxChange = useCallback((integrationId: string, checked: boolean) => {
		setCheckboxesState(currentState => {
			if (currentState.has(integrationId)) {
				return currentState.set(
					integrationId,
					currentState.get(integrationId)!.mapEntries(([key]) => [key, checked])
				);
			}
			return currentState;
		});
	}, []);

	const updatePermissions = useCallback(
		(status: TPermissionStatusOptions, permissionIdsToChange: string[]) => {
			const newPermissions = accessReviewSubordinate?.accessReviewSubordinatePermissions?.map(permission => {
				if (permissionIdsToChange.includes(permission.id) && STATUS_DICT?.has(status)) {
					return permission.set("status", STATUS_DICT.get(status)!);
				}
				return permission;
			});
			updateSubordinate({
				accessReviewSubordinatePermissions: newPermissions
			});
		},
		[accessReviewSubordinate, updateSubordinate]
	);

	const setSelected = useCallback(
		async (status: Exclude<TPermissionStatusOptions, "pending">) => {
			if (selectedPermissionsIds.length > 0) {
				await getStatusAction(status)(selectedPermissionsIds, "subordinate");
				updatePermissions(status, selectedPermissionsIds);
			}
		},
		[getStatusAction, selectedPermissionsIds, updatePermissions]
	);

	const setSinglePermission = useCallback(
		async (permissionId: string, status: Exclude<TPermissionStatusOptions, "pending">) => {
			if (permissionId) {
				await getStatusAction(status)([permissionId], "subordinate");
				updatePermissions(status, [permissionId]);
			}
		},
		[getStatusAction, updatePermissions]
	);

	const getIsSelected = useCallback(
		(integrationId: string, permissionId: string) => {
			return checkboxesState?.get(integrationId)?.get(permissionId) || false;
		},
		[checkboxesState]
	);

	const groupedPermissionsValues = useMemo(() => groupedPermissions?.valueSeq(), [groupedPermissions]);

	const selectedPermissions = useMemo(
		() =>
			groupedPermissionsValues
				?.flatMap(permissions =>
					permissions.filter(permission => selectedPermissionsIds.includes(permission.subordinatePermissionId))
				)
				.toList(),
		[groupedPermissionsValues, selectedPermissionsIds]
	);

	const getPermissionStatus = useCallback(
		(permissionId: string): TAccessReviewPermissionStatus => {
			return (
				groupedPermissionsValues
					?.flatMap(permissions => permissions)
					?.find(permission => permission.subordinatePermissionId === permissionId)?.status || "pending"
			);
		},
		[groupedPermissionsValues]
	);

	const setComment = useCallback(
		async (permissionIds: string[], content: string) => {
			if (!permissionIds || permissionIds.length === 0) return;
			const now = new Date();
			await bulkComment(permissionIds, content);
			const newPermissions = accessReviewSubordinate?.accessReviewSubordinatePermissions?.map(permission => {
				if (permissionIds.includes(permission.accessReviewPermissionId)) {
					const accessReviewPermission = permission.accessReviewPermission!;
					let comments = accessReviewPermission.comments;
					if (comments && comments.size > 0) {
						const userCommentIndex = comments.findIndex(comment => comment.creatorId === user?.id);
						let userComment = userCommentIndex > -1 ? comments.get(userCommentIndex) : null;
						if (userComment) {
							userComment = userComment.merge({ content, updatedAt: now });
							comments = comments.set(userCommentIndex, userComment);
						} else {
							comments = comments.push(
								new AccessReviewPermissionCommentModel({ content, createdAt: now, updatedAt: now, creatorId: user?.id })
							);
						}
					} else {
						comments = List([
							new AccessReviewPermissionCommentModel({ content, createdAt: now, updatedAt: now, creatorId: user?.id })
						]);
					}

					return permission.set("accessReviewPermission", accessReviewPermission.set("comments", comments));
				}
				return permission;
			});
			updateSubordinate({
				accessReviewSubordinatePermissions: newPermissions
			});
		},
		[accessReviewSubordinate?.accessReviewSubordinatePermissions, updateSubordinate, user?.id]
	);

	const setSelectedComment = useCallback(
		async (content: string) => {
			if (!groupedPermissions) return;
			// flatMap because flatten doesn't infer type
			const flatPermissions = groupedPermissions.valueSeq().flatMap(p => p);
			const accessReviewPermissionIds = flatPermissions.reduce((acc, permission) => {
				if (selectedPermissionsIds.includes(permission.subordinatePermissionId)) {
					acc.push(permission.id);
				}
				return acc;
			}, [] as string[]);
			await setComment(accessReviewPermissionIds, content);
		},
		[groupedPermissions, selectedPermissionsIds, setComment]
	);

	useEffect(() => {
		resetCheckboxState();
	}, [resetCheckboxState]);

	return {
		state: {
			checkboxesState,
			groupedPermissions,
			isLoading,
			report,
			selectedAmount: selectedPermissionsIds.length,
			selectedPermissions,
			subordinate: accessReviewSubordinate,
			totalAmount
		},
		actions: {
			getIsSelected,
			getPermissionStatus,
			handleIntegrationCheckboxChange,
			handlePermissionCheckboxChange,
			removePermissionCheckbox,
			resetCheckboxState,
			setComment,
			setSelected,
			setSelectedComment,
			setSinglePermission
		}
	};
};

export const [SubordinatesPermissionsReviewContextProvider, useSubordinatePermissionsReviewContext] =
	constate(useSubordinatePermissionReview);
