import React from "react";
import { makeIcon } from "components/ui/Icon";

export const AppsIcon = makeIcon(
	<svg viewBox="0 0 24 24" fill="none">
		<path
			d="M16.65 12.575C16.5167 12.575 16.3917 12.5542 16.275 12.5125C16.1583 12.4708 16.05 12.4 15.95 12.3L11.7 8.05C11.6 7.95 11.5292 7.84167 11.4875 7.725C11.4458 7.60833 11.425 7.48333 11.425 7.35C11.425 7.21667 11.4458 7.09167 11.4875 6.975C11.5292 6.85833 11.6 6.75 11.7 6.65L15.95 2.4C16.05 2.3 16.1583 2.22917 16.275 2.1875C16.3917 2.14583 16.5167 2.125 16.65 2.125C16.7833 2.125 16.9083 2.14583 17.025 2.1875C17.1417 2.22917 17.25 2.3 17.35 2.4L21.6 6.65C21.7 6.75 21.7708 6.85833 21.8125 6.975C21.8542 7.09167 21.875 7.21667 21.875 7.35C21.875 7.48333 21.8542 7.60833 21.8125 7.725C21.7708 7.84167 21.7 7.95 21.6 8.05L17.35 12.3C17.25 12.4 17.1417 12.4708 17.025 12.5125C16.9083 12.5542 16.7833 12.575 16.65 12.575ZM4 11C3.71667 11 3.47917 10.9042 3.2875 10.7125C3.09583 10.5208 3 10.2833 3 10V4C3 3.71667 3.09583 3.47917 3.2875 3.2875C3.47917 3.09583 3.71667 3 4 3H10C10.2833 3 10.5208 3.09583 10.7125 3.2875C10.9042 3.47917 11 3.71667 11 4V10C11 10.2833 10.9042 10.5208 10.7125 10.7125C10.5208 10.9042 10.2833 11 10 11H4ZM14 21C13.7167 21 13.4792 20.9042 13.2875 20.7125C13.0958 20.5208 13 20.2833 13 20V14C13 13.7167 13.0958 13.4792 13.2875 13.2875C13.4792 13.0958 13.7167 13 14 13H20C20.2833 13 20.5208 13.0958 20.7125 13.2875C20.9042 13.4792 21 13.7167 21 14V20C21 20.2833 20.9042 20.5208 20.7125 20.7125C20.5208 20.9042 20.2833 21 20 21H14ZM4 21C3.71667 21 3.47917 20.9042 3.2875 20.7125C3.09583 20.5208 3 20.2833 3 20V14C3 13.7167 3.09583 13.4792 3.2875 13.2875C3.47917 13.0958 3.71667 13 4 13H10C10.2833 13 10.5208 13.0958 10.7125 13.2875C10.9042 13.4792 11 13.7167 11 14V20C11 20.2833 10.9042 20.5208 10.7125 20.7125C10.5208 20.9042 10.2833 21 10 21H4ZM5 9H9V5H5V9ZM16.675 10.2L19.5 7.375L16.675 4.55L13.85 7.375L16.675 10.2ZM15 19H19V15H15V19ZM5 19H9V15H5V19Z"
			fill="currentColor"
		/>
	</svg>
);
