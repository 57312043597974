import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	accordion: {
		borderRadius: 0,
		border: "none",
		boxShadow: "none",
		"&.noMargin": {
			margin: 0
		},
		"&.rounded": {
			borderRadius: "var(--border-radius-semi-rounded)",
			paddingRight: "1rem"
		},
		"&:before": {
			backgroundColor: "transparent"
		}
	},
	title: {
		display: "flex",
		width: "100%"
	}
});
