import React, { useMemo } from "react";
import { useWorkflowEditorContext } from "context/workflowEditorContext";
import { OperatorBlock } from "../../OperatorBlock";
import { AddFlowStep } from "../AddFlowStep";
import { ApprovalFlowStepEditable } from "../ApprovalFlowStep";

export const ApprovalFlowEditable: FC<{ index: number }> = ({ index }) => {
	const {
		actions: { getRuleFlowOrShowError }
	} = useWorkflowEditorContext();
	const [_, approvalFlow] = getRuleFlowOrShowError(index) || [];
	const { requests } = approvalFlow || {};
	const orderedSteps = useMemo(() => requests?.sortBy(request => request.sortOrder), [requests]);

	const canAddStep = useMemo(
		() =>
			orderedSteps?.every(
				request => !request || !request.approvers || request.approvers.every(approver => approver.type !== "Automatic")
			),
		[orderedSteps]
	);

	if (!orderedSteps || orderedSteps.size === 0) return <AddFlowStep index={index} />;

	return (
		<>
			{orderedSteps.map(step => (
				<React.Fragment key={step.id || `${index}-${step.sortOrder}`}>
					<OperatorBlock operator="then" />
					<ApprovalFlowStepEditable approvalFlowRequest={step} index={index} />
				</React.Fragment>
			))}
			{canAddStep ? <AddFlowStep index={index} /> : null}
		</>
	);
};
