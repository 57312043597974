import React from "react";
import { useTranslation } from "react-i18next";

interface IProps {
	unknown?: boolean;
}

export const Unknown: FC<IProps> = ({ unknown, children }) => {
	const { t } = useTranslation();
	return <>{unknown ? t("shared.unknown") : children}</>;
};
