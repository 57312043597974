import { Draft07 as Schema } from "json-schema-library";
import type { TJsonSchema } from "types/utilTypes";

export const schemaToEmptyJson = (schemaObj: TJsonSchema | null): Record<string, unknown> | null => {
	if (!schemaObj) {
		return null;
	}
	try {
		const schema = new Schema(schemaObj);
		return schema.getTemplate({}) as Record<string, unknown>;
	} catch (_error) {
		throw new Error("Schema is invalid");
	}
};
