import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	container: {
		display: "flex",
		flexDirection: "column",
		gap: "1rem",
		width: "100%"
	},
	emptyList: {
		alignItems: "center",
		display: "flex",
		justifyContent: "center"
	}
});
