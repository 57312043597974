import { List } from "immutable";
import { IntegrationResourceModel } from "models/IntegrationResourceModel";
import { apiReq, toQuerystring } from "utils/api/apiReq";
import { apiReqAbortOld } from "utils/api/apiReqAbortOld";
import { fromPaginationSortFilterOptions } from "utils/api/urlSearchParams";
import { devLog } from "utils/devtools/devLogging";
import { IPaginationResponse, withPagination } from "utils/pagination";
import { isStringArray } from "utils/strings";
import type { TPaginationSortFilterOptions } from "types/pagination";
import type { Require } from "types/utilTypes";
import type { TTicketDuration } from "utils/durationsOptions";

export type TFullIntegrationResourceModel = Require<IntegrationResourceModel, "rolesCount">;

type TIntegrationResourceQueryString = {
	full?: boolean;
	withDeleted?: boolean;
	withUnmanaged?: boolean;
};

export async function getIntegrationResources(
	options: TPaginationSortFilterOptions
): Promise<IPaginationResponse<TFullIntegrationResourceModel>> {
	const searchParams = fromPaginationSortFilterOptions(options);
	const querystring = searchParams?.size ? `?${searchParams.toString()}` : "";

	return withPagination(
		(resource: Record<string, unknown>) =>
			IntegrationResourceModel.fromServerData(resource) as TFullIntegrationResourceModel,
		() =>
			apiReqAbortOld({
				key: querystring || "[INTEGRATION_RESOURCES]",
				method: "GET",
				path: `/v1/integrationResources${querystring}`
			})
	);
}

export async function getIntegrationResource(
	integrationResourceId: string,
	queryData: TIntegrationResourceQueryString = {}
): Promise<TFullIntegrationResourceModel> {
	const query = toQuerystring(queryData);
	const { data } = await apiReq("GET", `/v1/integrationResources/${integrationResourceId}?${query}`);
	return IntegrationResourceModel.fromServerData(data) as TFullIntegrationResourceModel;
}

interface IIntegrationResourceMaintainer {
	id: string;
	type: "user" | "directoryGroup";
}

export type TIntegrationResourceBody = {
	allowedDurations?: List<TTicketDuration> | null;
	allowsRequests?: boolean;
	approvalAlgorithmId?: string | null;
	description?: string | null;
	name?: string;
	id?: string;
	integrationId?: string;
	maintainers?: IIntegrationResourceMaintainer[];
	multirole?: boolean;
	ownerUserId?: string | null;
	tags?: string[] | null;
	type?: string;
};

export type TIntegrationResourceCreateBody = TIntegrationResourceBody & {
	roles?: { name: string; virtualizedRoleId: string }[];
};

export async function createIntegrationResource(
	integrationResource: TIntegrationResourceCreateBody
): Promise<IntegrationResourceModel> {
	const { data } = await apiReq("POST", "/v1/integrationResources", integrationResource);

	return IntegrationResourceModel.fromServerData(data);
}

export async function editIntegrationResource(
	integrationResource: Require<Partial<TIntegrationResourceBody>, "id">
): Promise<IntegrationResourceModel> {
	const { data } = await apiReq("PUT", `/v1/integrationResources/${integrationResource.id}`, integrationResource);

	return IntegrationResourceModel.fromServerData(data);
}

export async function deleteIntegrationResource(id: string): Promise<IntegrationResourceModel> {
	const { data } = await apiReq("DELETE", `/v1/integrationResources/${id}`);

	return IntegrationResourceModel.fromServerData(data);
}

export async function addResourcePermission(id: string, roleId: string): Promise<TFullIntegrationResourceModel> {
	const url = `/v1/integrationResources/${id}/addResourcePermission?roleId=${roleId}`;
	const { data } = await apiReq("POST", url);

	return IntegrationResourceModel.fromServerData(data) as TFullIntegrationResourceModel;
}

export async function deleteResourcePermission(id: string, roleId: string): Promise<TFullIntegrationResourceModel> {
	const url = `/v1/integrationResources/${id}/deleteResourcePermission?roleId=${roleId}`;
	const { data } = await apiReq("DELETE", url);

	return IntegrationResourceModel.fromServerData(data) as TFullIntegrationResourceModel;
}

export async function getIntegrationResourceTypes(search?: string): Promise<string[]> {
	const urlParams = new URLSearchParams();
	if (search) {
		urlParams.append("search", search);
	}

	const { data } = await apiReq("GET", `/v1/integrationResources/types?${urlParams}`);
	if (isStringArray(data)) {
		return data;
	}
	devLog({ level: "error", message: "Integration resource types response is not an array", extra: { data } });
	return [];
}

export async function getIntegrationResourceTags(search?: string): Promise<string[]> {
	const urlParams = new URLSearchParams();
	if (search) {
		urlParams.append("search", search);
	}

	const { data } = await apiReq("GET", `/v1/integrationResources/tags?${urlParams}`);
	if (isStringArray(data)) {
		return data;
	}
	devLog({ level: "error", message: "Integration resource tags response is not an array", extra: { data } });
	return [];
}
