import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	accordion: {
		"&:first-child": {
			borderTop: "1px solid var(--color-grey-background)"
		},
		borderBottom: "1px solid var(--color-grey-background)"
	},
	amountContainer: {
		paddingRight: "1rem",
		whiteSpace: "nowrap"
	},
	buttonApprove: {
		"&$buttonApprove$buttonApprove$buttonApprove": {
			"&:hover,:active": {
				backgroundColor: "var(--color-green-dark)",
				borderColor: "var(--color-green-dark)",
				color: "var(--color-white)"
			},
			color: "var(--color-green-dark)",
			"&$disabled": {
				color: "var(--color-grey-disabled)"
			}
		}
	},
	buttonDeny: {
		"&$buttonDeny$buttonDeny$buttonDeny": {
			"&:hover,:active": {
				backgroundColor: "var(--color-red-dark)",
				borderColor: "var(--color-red-dark)",
				color: "var(--color-white)"
			},
			color: "var(--color-red-dark)",
			"&$disabled": {
				color: "var(--color-grey-disabled)"
			}
		}
	},
	buttonFlag: {
		"&$buttonFlag$buttonFlag$buttonFlag": {
			"&:hover,:active": {
				backgroundColor: "var(--color-orange)",
				borderColor: "var(--color-orange)",
				color: "var(--color-white)"
			},
			color: "var(--color-orange)",
			"&$disabled": {
				color: "var(--color-grey-disabled)"
			}
		}
	},
	checkbox: {
		gap: "1rem"
	},
	header: {
		alignItems: "center",
		justifyContent: "space-between",
		display: "flex",
		gap: "1rem"
	},
	integrationImage: {
		border: "1px solid var(--color-grey-background)",
		borderRadius: "var(--border-radius-semi-rounded)",
		height: "2.3rem",
		overflow: "hidden",
		padding: "0.45rem",
		width: "2.3rem"
	},
	integrationTitle: {
		alignItems: "center",
		display: "flex",
		gap: "1rem"
	},
	list: {
		display: "flex",
		flexDirection: "column"
	},
	listContainer: {
		display: "flex",
		flexDirection: "column",
		gap: "1rem"
	},
	subHeaderDataContainer: {
		gap: "1rem",
		display: "flex",
		justifyContent: "flex-end",
		alignItems: "center"
	},
	disabled: {}
});
