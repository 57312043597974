import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	integrationLogsSectionWrapper: {
		display: "flex",
		flexDirection: "column",
		width: "100%"
	},
	integrationLogsSection: {
		paddingTop: "var(--spacing-x7, 28px)"
	},
	timezoneDropdown: {
		width: "max-content"
	}
});
