import { useCallback, useMemo, useState } from "react";

type TIsOpenState = {
	close(): void;
	isOpen: boolean;
	open(): void;
	setIsOpen(isOpen: boolean): void;
	toggle(): void;
};

export function useIsOpenState(defaultState = false): TIsOpenState {
	const [isOpen, setIsOpenState] = useState(defaultState);

	const open = useCallback(() => {
		setIsOpenState(true);
	}, []);

	const close = useCallback(() => {
		setIsOpenState(false);
	}, []);

	const setIsOpen = useCallback((isOpen: boolean) => {
		setIsOpenState(isOpen);
	}, []);

	const toggle = useCallback(() => {
		setIsOpenState(current => !current);
	}, []);

	return useMemo(
		() => ({
			close,
			isOpen,
			open,
			setIsOpen,
			toggle
		}),
		[close, isOpen, open, setIsOpen, toggle]
	);
}
