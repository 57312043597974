const styles = {
	transitionable: {
		"&.animate": {
			'&[data-animation="none"]': {
				"&.in": {
					opacity: "1",
					zIndex: "10"
				},

				"&.out": {
					opacity: "0",
					zIndex: "5"
				}
			}
		}
	}
};

export { styles };
