import { Record } from "immutable";
import uniq from "lodash/uniq";
import { validateFilterInclusionOperator, validateFilterRelation } from "utils/validation/filterValidation";
import type { IFilter } from "types/filters";

const filterName = "integrationResourceIntegrationName";

const defaults = {
	name: filterName as typeof filterName,
	value: [] as string[],
	operator: "contains" as "contains" | "notContains",
	relation: "or" as "and" | "or"
};

type TDefaults = typeof defaults;

export class IntegrationResourceIntegrationNameFilter
	extends Record<TDefaults>(defaults)
	implements IFilter<TDefaults["value"], TDefaults["operator"], TDefaults["name"]>
{
	static filterName: typeof filterName = filterName;

	static fromURLSearchParams(urlSearchParams: URLSearchParams): IntegrationResourceIntegrationNameFilter | null {
		const integrationResourceIntegrationName = uniq(urlSearchParams.getAll("integrationResourceIntegrationName"));
		const integrationResourceIntegrationNameOperator =
			urlSearchParams.get("integrationResourceIntegrationNameOperator") || undefined;
		const integrationResourceIntegrationNameRelation =
			urlSearchParams.get("integrationResourceIntegrationNameRelation") || undefined;
		if (
			integrationResourceIntegrationName.length === 0 ||
			!validateFilterInclusionOperator(integrationResourceIntegrationNameOperator) ||
			!validateFilterRelation(integrationResourceIntegrationNameRelation)
		) {
			return null;
		}
		return new IntegrationResourceIntegrationNameFilter({
			value: integrationResourceIntegrationName,
			operator: integrationResourceIntegrationNameOperator,
			relation: integrationResourceIntegrationNameRelation
		});
	}

	toURLSearchParams(): URLSearchParams {
		const params = new URLSearchParams();
		if (!this.value.length) {
			return params;
		}
		for (const value of uniq(this.value)) {
			params.append("integrationResourceIntegrationName", value);
		}
		params.append("integrationResourceIntegrationNameOperator", this.operator);
		params.append("integrationResourceIntegrationNameRelation", this.relation);
		return params;
	}
}
