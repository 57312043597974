import classNames from "classnames";
import React, { useMemo } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { DefaultErrorBoundary } from "components/App/components/ErrorBoundary";
import { Breadcrumbs, TNavigationPart } from "components/common/Breadcrumbs";
import { TransitionablePage } from "components/templates/TransitionablePage";
import { Divider } from "components/ui/Divider";
import { Typography } from "components/ui/Typography";
import { useBreadcrumbs } from "context/new/pageContext";
import { useStyles } from "./styles";

/*
	How to use this component:
	```tsx
	<PageTitleContent>
		<PageTitleContent.Header>
			<PageTitleContent.Header.Top breadcrumbs={breadcrumbs}>
				{Close Action?}
			</PageTitleContent.Header.Top>
			<PageTitleContent.Header.Bottom>
				<PageTitleContent.Header.Title title={title} subtitle={subtitle} />
				<PageTitleContent.Header.Actions>
					{Actions}
				</PageTitleContent.Header.Actions>
			</PageTitleContent.Header.Bottom>
		</PageTitleContent.Header>
		<PageTitleContent.Body>
			{Body}
		</PageTitleContent.Body>
		<PageTitleContent.Footer>
			<PageTitleContent.Footer.Left>
				{Footer Left}
			</PageTitleContent.Footer.Left>
			<PageTitleContent.Footer.Center>
				{Footer Center}
			</PageTitleContent.Footer.Center>
			<PageTitleContent.Footer.Right>
				{Footer Right}
			</PageTitleContent.Footer.Right>
		</PageTitleContent.Footer>
	</PageTitleContent>
	```

	Options for Header.Top:
	no header top - no breadcrumbs, no actions
	breadcrumbs - NavigationParts[] | React.ReactNode
	self close - breadcrumbs, no actions

	Options for Header.Title:
	title, subtitle - string, set title and subtitle if exists (subtitle is optional)
	children - React.ReactNode, if you need custom title
*/

const PageTitleContentTemplate: FC<{
	wrapperClassName?: string;
	noTransition?: boolean;
}> = ({ className, children, id, innerRef, wrapperClassName, noTransition = false }) => {
	const classes = useStyles();

	const content = useMemo(() => {
		return (
			<div className={classNames(classes.wrapper, wrapperClassName)} ref={innerRef}>
				<div className={classNames(classes.container, className)}>{children}</div>
			</div>
		);
	}, [children, className, classes.container, classes.wrapper, innerRef, wrapperClassName]);

	return (
		<ErrorBoundary FallbackComponent={DefaultErrorBoundary}>
			{noTransition ? content : <TransitionablePage id={id}>{content}</TransitionablePage>}
		</ErrorBoundary>
	);
};

const PageTitleContentHeader: FC = ({ className, children, innerRef }) => {
	const classes = useStyles();

	return (
		<div className={classes.headerContainer}>
			<div className={classNames(classes.header, className)} ref={innerRef}>
				{children}
			</div>
			<Divider horizontal />
		</div>
	);
};

const PageTitleContentBody: FC = ({ className, children, innerRef }) => {
	const classes = useStyles();

	return (
		<div className={classNames(classes.body, className)} ref={innerRef}>
			{children}
		</div>
	);
};

type THeaderTopProps = {
	breadcrumbs?: React.ReactNode | TNavigationPart[];
};

const isNavigationParts = (breadcrumbs: React.ReactNode | TNavigationPart[]): breadcrumbs is TNavigationPart[] => {
	return Array.isArray(breadcrumbs) && breadcrumbs.every(part => !!part.title);
};

const PageTitleContentHeaderTop: FC<THeaderTopProps> = ({ breadcrumbs, className, children, innerRef }) => {
	const classes = useStyles();
	const contextBreadcrumbs = useBreadcrumbs();

	const breadcrumbsComponent = useMemo(() => {
		if (breadcrumbs) {
			if (isNavigationParts(breadcrumbs)) {
				return <Breadcrumbs parts={breadcrumbs} />;
			}
			return breadcrumbs;
		} else if (contextBreadcrumbs.breadcrumbs) {
			return <Breadcrumbs parts={contextBreadcrumbs.breadcrumbs} />;
		}
		return null;
	}, [breadcrumbs, contextBreadcrumbs.breadcrumbs]);

	return (
		<div className={classNames(classes.headerTop, className)} ref={innerRef}>
			<div className={classes.alignedCenter}>{breadcrumbsComponent}</div>
			<div className={classes.alignedCenter}>{children}</div>
		</div>
	);
};

const PageTitleContentHeaderBottom: FC = ({ className, children, innerRef }) => {
	const classes = useStyles();

	return (
		<div className={classNames(classes.headerBottom, className)} ref={innerRef}>
			{children}
		</div>
	);
};

type TTitleSubtitleProps = {
	title: string;
	subtitle?: string;
	children?: never;
};

type TChildrenTitleProps = {
	title?: never;
	subtitle?: never;
	children: React.ReactNode;
};

type THeaderTitleProps = TTitleSubtitleProps | TChildrenTitleProps;

const PageTitleContentHeaderTitle: FC<THeaderTitleProps> = props => {
	const classes = useStyles();

	const titleSubtitle = useMemo(() => {
		if ("title" in props) {
			const { title, subtitle } = props;
			return (
				<div className={classNames(classes.titleSubtitle, props.className)} ref={props.innerRef}>
					<Typography variant="header_sb">{title}</Typography>
					{subtitle ? <Typography variant="body_reg">{subtitle}</Typography> : null}
				</div>
			);
		}
		return <>{props.children || null}</>;
	}, [classes.titleSubtitle, props]);
	return titleSubtitle;
};

const PageTitleContentHeaderActions: FC = ({ className, children, innerRef }) => {
	const classes = useStyles();

	return (
		<div className={classNames(classes.headerBottomActions, className)} ref={innerRef}>
			{children}
		</div>
	);
};

const PageTitleContentFooter: FC = ({ className, children, innerRef }) => {
	const classes = useStyles();

	return (
		<div className={classNames(classes.footer, className)} ref={innerRef}>
			{children}
		</div>
	);
};

const PageTitleContentFooterLeft: FC = ({ className, children, innerRef }) => {
	const classes = useStyles();

	return (
		<div className={classNames(classes.footerLeft, className)} ref={innerRef}>
			{children}
		</div>
	);
};

const PageTitleContentFooterCenter: FC = ({ className, children, innerRef }) => {
	const classes = useStyles();

	return (
		<div className={classNames(classes.footerCenter, className)} ref={innerRef}>
			{children}
		</div>
	);
};

const PageTitleContentFooterRight: FC = ({ className, children, innerRef }) => {
	const classes = useStyles();

	return (
		<div className={classNames(classes.footerRight, className)} ref={innerRef}>
			{children}
		</div>
	);
};

type TTitleContentHeader = typeof PageTitleContentHeader & {
	Top: typeof PageTitleContentHeaderTop;
	Bottom: typeof PageTitleContentHeaderBottom;
	Title: typeof PageTitleContentHeaderTitle;
	Actions: typeof PageTitleContentHeaderActions;
};

type TTitleContentFooter = typeof PageTitleContentFooter & {
	Left: typeof PageTitleContentFooterLeft;
	Center: typeof PageTitleContentFooterCenter;
	Right: typeof PageTitleContentFooterRight;
};

type TTitleContent = typeof PageTitleContentTemplate & {
	Header: TTitleContentHeader;
	Body: typeof PageTitleContentBody;
	Footer: TTitleContentFooter;
};

const PageTitleHeader = PageTitleContentHeader as TTitleContentHeader;
PageTitleHeader.Top = PageTitleContentHeaderTop;
PageTitleHeader.Bottom = PageTitleContentHeaderBottom;
PageTitleHeader.Title = PageTitleContentHeaderTitle;
PageTitleHeader.Actions = PageTitleContentHeaderActions;

const PageTitleFooter = PageTitleContentFooter as TTitleContentFooter;
PageTitleFooter.Left = PageTitleContentFooterLeft;
PageTitleFooter.Center = PageTitleContentFooterCenter;
PageTitleFooter.Right = PageTitleContentFooterRight;

const PageTitleContent = PageTitleContentTemplate as TTitleContent;
PageTitleContent.Header = PageTitleHeader;
PageTitleContent.Body = PageTitleContentBody;
PageTitleContent.Footer = PageTitleFooter;

export { PageTitleContent };
