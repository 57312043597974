import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	descriptionEditContainer: {
		position: "relative"
	},
	container: {
		position: "relative"
	},
	contentContainer: {
		backgroundColor: "var(--color-white)",
		display: "flex",
		flexDirection: "column",
		borderRadius: "var(--border-radius-semi-rounded)",
		gap: "1rem",
		padding: "1rem"
	},
	loadingLatestAccessReview: {
		opacity: 0.1
	},
	statistics: {
		alignItems: "center",
		color: "var(--color-grey-dark)",
		display: "flex",
		gap: "0.25rem"
	},
	tableCell: {
		alignItems: "center",
		display: "flex",
		flexDirection: "row",
		height: "100%"
	},
	tableHeader: {
		alignItems: "center",
		display: "flex",
		gap: "1rem",
		width: "100%"
	},
	actions: {
		zIndex: "500",
		display: "flex",
		padding: "0.5rem 0",
		alignItems: "center",
		flexDirection: "column",
		height: "100%"
	},
	saveButton: {
		position: "relative"
	},
	cancelButton: {
		position: "relative"
	},
	editButton: {
		opacity: 0,
		transition: "opacity 0.2s ease-in-out",
		height: "fit-content"
	},
	descriptionContainer: {
		display: "flex",
		justifyContent: "space-between",
		position: "relative",
		"&:hover $editButton": {
			opacity: 1
		}
	},
	description: {
		width: "97%"
	},
	editDescription: {
		borderRadius: "var(--border-radius-semi-rounded)",
		justifyContent: "start",
		"& textarea": {
			width: "96%",
			paddingRight: "1rem"
		}
	},
	saveDisabled: {}
});
