import React from "react";
import { makeIcon } from "components/ui/Icon";
import { EntraIDLogo } from "components/ui/systemLogos/EntraIDLogo";
import { GoogleLogo } from "components/ui/systemLogos/GoogleLogo";
import { JumpCloudLogo } from "components/ui/systemLogos/JumpCloudLogo";
import { LdapLogo } from "components/ui/systemLogos/LdapLogo";
import { MicrosoftADLogo } from "components/ui/systemLogos/MicrosoftADLogo";
import { OktaLogo } from "components/ui/systemLogos/OktaLogo";
import { OneLoginLogo } from "components/ui/systemLogos/OneLoginLogo";
import type { TDirectory } from "types/directoryConfiguration";

export const sourceIcons: Record<TDirectory, IconComponent> = {
	okta: makeIcon(<OktaLogo />),
	google: makeIcon(<GoogleLogo />),
	azureAD: makeIcon(<EntraIDLogo />),
	jumpcloud: makeIcon(<JumpCloudLogo />),
	ldap: makeIcon(<LdapLogo />),
	onelogin: makeIcon(<OneLoginLogo />),
	// eslint-disable-next-line camelcase
	active_directory: makeIcon(<MicrosoftADLogo />)
};
