import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	emptyStateText: {
		alignItems: "center",
		display: "inline-flex",
		gap: "8px",
		height: "100%",
		justifyContent: "center",
		width: "100%"
	},
	emptyStateCard: {
		alignItems: "flex-start",
		borderRadius: "4px",
		backgroundColor: "var(--color-purple-200)",
		display: "inline-flex",
		fontWeight: "var(--typography-font-weight-medium)",
		gap: "4px",
		padding: "4px"
	}
});
