import { Record } from "immutable";
import type { IEntityOption, TEntityOption } from "../utils/entityOptionType";

const defaults = {
	email: null as string | null,
	id: "",
	name: "",
	isDeleted: false
};

export class DirectoryGroupModel extends Record<typeof defaults>(defaults) implements IEntityOption<"DirectoryGroup"> {
	static fromServerData(data: unknown) {
		return new DirectoryGroupModel(data as typeof defaults);
	}

	toEntityOption(): TEntityOption<"DirectoryGroup"> {
		return {
			displayName: this.name,
			email: this.email,
			identifier: this.id,
			type: "DirectoryGroup",
			isDeleted: this.isDeleted
		};
	}
}
