import classNames from "classnames";
import React from "react";
import { useStyles } from "./styles";
import { DateWithDiff } from "../DateWithDiff";
import { RequestNumberChip } from "../RequestNumberChip";

type TProps = {
	prefix?: React.ReactNode;
	number?: number;
	date: Date;
};

export const RequestDateAndNumber: FC<TProps> = ({ className, innerRef, prefix = null, number, date }) => {
	const classes = useStyles();

	return (
		<div className={classNames(classes.container, className)} ref={innerRef}>
			<div>{prefix}</div>
			<DateWithDiff date={date} />
			{number && <RequestNumberChip requestNumber={number} />}
		</div>
	);
};
