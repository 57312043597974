import classNames from "classnames";
import React, { useCallback, type AnimationEvent } from "react";
import { useTransitionAnimationContext } from "context/transitionAnimationContext";
import { useStyles } from "./styles";
import { ANIMATION_NAMES } from "./transitions";

const hasAnimationName = (animationName: string) => {
	return ANIMATION_NAMES.some(TRANSITION_ANIMATION_NAME => animationName.includes(TRANSITION_ANIMATION_NAME));
};

export const Transitionable: FC<{ id: string }> = ({ className, children, ...props }) => {
	const { transitionOptions, setAnimationIsRunning } = useTransitionAnimationContext();
	const { animate, movement, reverse, animation } = transitionOptions || {};
	const classes = useStyles();

	const onAnimationStart = useCallback(
		(event: AnimationEvent) => {
			if (hasAnimationName(event.animationName)) {
				setAnimationIsRunning?.(true);
			}
		},
		[setAnimationIsRunning]
	);

	const onAnimationEnd = useCallback(
		(event: AnimationEvent) => {
			if (hasAnimationName(event.animationName)) {
				setAnimationIsRunning?.(false);
			}
		},
		[setAnimationIsRunning]
	);

	return (
		<div
			className={classNames(
				className,
				classes.transitionable,
				{ animate, reverse },
				movement === "in" && "in",
				movement === "out" && "out"
			)}
			data-animation={animation}
			onAnimationStart={onAnimationStart}
			onAnimationEnd={onAnimationEnd}
			{...props}>
			{children}
		</div>
	);
};
