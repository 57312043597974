import { Record } from "immutable";
import difference from "lodash/difference";
import { BundleModel } from "models/BundleModel";
import { TDiff } from "utils/auditLogs/auditLogUtils";

type TBundleAuditLogAction = "BundleCreated" | "BundleDeleted" | "BundleUpdated";

const parseData = (data: IBundleUpdatedAuditLogDiffData) => {
	if (data.fromTags || data.toTags) {
		const from = data.fromTags || [];
		const to = data.toTags || [];

		const deletedTags = difference(from, to);
		const addedTags = difference(to, from);
		if (deletedTags.length) {
			data.deletedTags = deletedTags;
		}
		if (addedTags.length) {
			data.addedTags = addedTags;
		}
	}

	if (data.fromWorkflowId && !data.fromWorkflowName) {
		data.fromWorkflowName = data.fromWorkflowId;
	}

	if (data.toWorkflowId && !data.toWorkflowName) {
		data.toWorkflowName = data.toWorkflowId;
	}

	return data;
};

export interface IBundleCreatedAuditLogDiffData {
	name: string;
	workflowId: string;
	category: string | null;
	addedResourceRoles: string[];
	deletedResourceRoles: string[];
	tags: string[];
	description: string;
	allowedDurations?: string[];
}

export interface IBundleUpdatedAuditLogDiffData {
	fromName: string;
	toName: string;
	fromWorkflowId?: string;
	toWorkflowId?: string;
	fromWorkflowName?: string;
	toWorkflowName?: string;
	fromCategory?: TDiff<string | null>;
	toCategory?: TDiff<string | null>;
	addedResourceRoles?: string[];
	deletedResourceRoles?: string[];
	fromTags?: string[];
	addedTags?: string[];
	toTags?: string[];
	deletedTags?: string[];
	fromDescription?: string;
	toDescription?: string;
	fromAllowedDurations?: string[];
	toAllowedDurations?: string[];
}

const defaults = {
	action: "BundleCreated" as TBundleAuditLogAction,
	bundle: null as BundleModel | null,
	bundleId: "",
	userId: "",
	id: "",
	createdAt: new Date(0),
	data: null as IBundleCreatedAuditLogDiffData | IBundleUpdatedAuditLogDiffData | null
};

export class BundleAuditLogModel extends Record<typeof defaults>(defaults) {
	static readonly type = "bundle";

	static fromServerData(data: unknown) {
		const { id, createdAt, data: _data, action, bundle, bundleId, userId } = data as typeof defaults;

		return new BundleAuditLogModel({
			id,
			bundleId,
			createdAt: new Date(createdAt),
			data:
				_data && action === "BundleUpdated"
					? parseData(_data as IBundleUpdatedAuditLogDiffData)
					: (_data as IBundleCreatedAuditLogDiffData | null),
			action,
			bundle: bundle && BundleModel.fromServerData(bundle),
			userId
		});
	}
}
