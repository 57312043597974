import { List, Record } from "immutable";
import React, { SetStateAction, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IntegrationSelectInput } from "components/common/IntegrationSelectInput";
import { ResourceSelectInput } from "components/common/ResourceSelectInput";
import { RoleSelectInput } from "components/common/RoleSelectInput";
import { CloseIcon } from "components/ui/Icons/CloseIcon";
import { useIntegrations } from "hooks/useIntegrations";
import { IntegrationModel } from "models/IntegrationModel";
import { IntegrationResourceModel } from "models/IntegrationResourceModel";
import { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";
import { useStyles } from "./styles";
import { BundleItem } from "../../useBundleFormState";

interface IProps {
	index: number;
	bundleItem: BundleItem;
	setBundleItems: (value: SetStateAction<List<Record<BundleItem>>>) => void;
	removeBundleItem: (index: number) => void;
}

export const BundleItemForm: FC<IProps> = ({ index, bundleItem, setBundleItems, removeBundleItem }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const integrations = useIntegrations();

	const handleRoleChange = useCallback(
		(role: IntegrationResourceRoleModel | null) => {
			setBundleItems(bundleItems => bundleItems.setIn([index, "role"], role || null));
		},
		[setBundleItems, index]
	);

	const handleIntegrationResourceChange = useCallback(
		(value: IntegrationResourceModel | null) => {
			const role = new IntegrationResourceRoleModel({ integrationResource: value, integrationResourceId: value?.id });
			handleRoleChange(role);
			setBundleItems(bundleItems => bundleItems.setIn([index, "resource"], value));
		},
		[handleRoleChange, setBundleItems, index]
	);

	const handleIntegrationChange = useCallback(
		(integration: IntegrationModel | null) => {
			setBundleItems(bundleItems => bundleItems.setIn([index, "integration"], integration));
			handleIntegrationResourceChange(null);
		},
		[index, handleIntegrationResourceChange, setBundleItems]
	);

	const onRemove = useCallback(() => {
		removeBundleItem(index);
	}, [index, removeBundleItem]);

	const selectedRole = useMemo(() => {
		if (!bundleItem.role || !bundleItem.role?.id) {
			return null;
		}
		return bundleItem.role;
	}, [bundleItem.role]);

	return (
		<div className={classes.bundleItemForm}>
			<IntegrationSelectInput
				className={classes.input}
				label={t("common.integrationSelectInput.label")}
				onChange={handleIntegrationChange}
				options={integrations}
				placeholder={t("common.integrationSelectInput.placeholder")}
				required
				value={bundleItem.integration}
			/>
			<ResourceSelectInput
				className={classes.input}
				inputLabel={t("common.resourceSelectInput.label")}
				onChange={handleIntegrationResourceChange}
				placeholder={t("common.resourceSelectInput.placeholder")}
				required
				selected={bundleItem.resource}
				selectedIntegrationId={bundleItem.integration?.id || ""}
			/>
			<RoleSelectInput
				className={classes.input}
				disabled={!bundleItem.integration || !bundleItem.resource}
				inputLabel={t("common.roleSelectInput.label")}
				integrationResourceId={bundleItem.resource?.id}
				onChange={handleRoleChange}
				placeholder={t("common.roleSelectInput.placeholder")}
				required
				selectedRole={selectedRole}
			/>
			<div className={classes.deleteButtonPadding}>
				<CloseIcon onClick={onRemove} />
			</div>
		</div>
	);
};
