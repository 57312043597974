import { currentLanguage } from "../../i18n";
import type { TTimezone } from "utils/auditLogs/systemAuditLogTimezone";

const getFormatOptions = (dateStyle: "full" | "medium", timezone: TTimezone = "local") => {
	const options: Intl.DateTimeFormatOptions = { dateStyle, timeStyle: "short" };
	if (timezone === "global") {
		options.timeZone = "utc";
	}
	return options;
};

// Tuesday, October 26, 2021 at 2:11 PM
export function dateToLongText(date: Date, timezone: TTimezone = "local") {
	return new Intl.DateTimeFormat(currentLanguage, getFormatOptions("full", timezone)).format(date);
}

// Oct 26, 2021, 2:11 PM
export function dateToShortText(date: Date, timezone: TTimezone = "local") {
	return new Intl.DateTimeFormat(currentLanguage, getFormatOptions("medium", timezone)).format(date);
}
