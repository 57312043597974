import React, { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { getUserTickets } from "api/user";
import { RequestDetails } from "components/common/RequestDetails";
import { TicketFiltersModal } from "components/common/TicketFilters";
import { PageTemplate } from "components/templates/PageTemplate";
import { Button } from "components/ui/Button";
import { FilterIcon } from "components/ui/Icons/FilterIcon";
import { MyRequestsList } from "./components";
import { useStyles } from "./styles";
import type { TSearchTicketsParams } from "api/tickets";
import type { IPaginatedSearchOptions } from "utils/searchUtils";

export const MyRequestsPage: FC = ({ className }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const [filtersOpen, setFiltersOpen] = useState(false);
	const [ticketFilters, setTicketFilters] = useState<TSearchTicketsParams>({});

	const searchUserTickets = useCallback(
		(paginationOptions: IPaginatedSearchOptions) => getUserTickets(paginationOptions, ticketFilters),
		[ticketFilters]
	);

	const openFiltersModal = useCallback(() => setFiltersOpen(true), []);
	const closeFiltersModal = useCallback(() => setFiltersOpen(false), []);

	return (
		<PageTemplate className={className}>
			<RequestDetails />
			<TicketFiltersModal
				isOpen={filtersOpen}
				onClose={closeFiltersModal}
				onFiltersChange={setTicketFilters}
				ticketFilters={ticketFilters}
			/>
			<PageTemplate.Title className={classes.headerContainer}>
				{t("pages.myRequests.title")}
				<Button size="medium" variant="secondary" onClick={openFiltersModal} prefix={<FilterIcon />}>
					{t("buttons.filter")}
				</Button>
			</PageTemplate.Title>
			<PageTemplate.Content>
				<MyRequestsList searchUserTickets={searchUserTickets} />
			</PageTemplate.Content>
		</PageTemplate>
	);
};
