import constate from "constate";
import { Map } from "immutable";
import { useCallback, useMemo, useState } from "react";
import {
	createPolicy as apiCreatePolicy,
	updatePolicy as apiUpdatePolicy,
	movePolicy as apiMovePolicy,
	deletePolicy as apiDeletePolicy,
	getPolicies,
	ICreatePolicyParams
} from "api/policies";
import { useFetchedState } from "hooks/useFetchedState";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { PolicyModel } from "models/PolicyModel";

const usePolicies = () => {
	const {
		data: policies,
		setData: setPolicies,
		loadData: loadPolicies,
		retryAction: reloadPolicies
	} = useFetchedState(getPolicies);

	const lastSortOrder = useMemo(
		() => (policies ? Math.max(1, ...policies.valueSeq().map(policy => policy.sortOrder)) : 1),
		[policies]
	);

	const openGlobalErrorModal = useOpenGlobalErrorModal();
	const [selectedPolicy, setSelectedPolicy] = useState<PolicyModel>();

	const getMap = useCallback(async (): Promise<Map<string, PolicyModel>> => {
		if (policies) return policies;
		try {
			return await getPolicies();
		} catch (error) {
			openGlobalErrorModal(error as Error);
			return Map();
		}
	}, [policies, openGlobalErrorModal]);

	const createPolicy = useCallback(
		async (data: ICreatePolicyParams) => {
			try {
				const policy = await apiCreatePolicy(data);
				setPolicies((await getMap()).set(policy.id, policy));

				return policy;
			} catch (error) {
				openGlobalErrorModal(error as Error);
				return;
			}
		},
		[getMap, openGlobalErrorModal, setPolicies]
	);

	const updatePolicy = useCallback(
		async (id: string, data: ICreatePolicyParams) => {
			try {
				const policy = await apiUpdatePolicy(id, data);
				setPolicies((await getMap()).set(policy.id, policy));

				return policy;
			} catch (error) {
				openGlobalErrorModal(error as Error);
				return;
			}
		},
		[setPolicies, getMap, openGlobalErrorModal]
	);

	const movePolicy = useCallback(
		async (id: string, sortOrder: number) => {
			try {
				const currentPolicy = policies?.get(id);
				if (currentPolicy) {
					if (sortOrder === 0 || sortOrder > lastSortOrder) return;
					const direction = sortOrder < currentPolicy.sortOrder ? 1 : -1;
					const prevPolicies = policies;

					setPolicies(currPolicies => {
						if (!currPolicies) return null;

						return currPolicies.map(policy => {
							if (policy.id === id) return policy.set("sortOrder", sortOrder);
							else if (direction === 1 && policy.sortOrder >= sortOrder && policy.sortOrder < currentPolicy.sortOrder) {
								return policy.set("sortOrder", policy.sortOrder + 1);
							} else if (
								direction === -1 &&
								policy.sortOrder > currentPolicy.sortOrder &&
								policy.sortOrder <= sortOrder
							) {
								return policy.set("sortOrder", policy.sortOrder - 1);
							}
							return policy;
						});
					});

					try {
						await apiMovePolicy(id, sortOrder);
						return { ok: true };
					} catch (error) {
						setPolicies(prevPolicies);
						openGlobalErrorModal(error as Error);
						return;
					}
				}
				return;
			} catch (error) {
				openGlobalErrorModal(error as Error);
				return;
			}
		},
		[policies, lastSortOrder, setPolicies, openGlobalErrorModal]
	);

	const deletePolicy = useCallback(
		async (policyId: string) => {
			const policiesMap = await getMap();
			const policy = policiesMap.get(policyId);
			if (policy) {
				try {
					await apiDeletePolicy(policy.id);
					await reloadPolicies();
				} catch (error) {
					openGlobalErrorModal(error as Error);
					return;
				}
			}
		},
		[reloadPolicies, getMap, openGlobalErrorModal]
	);

	return {
		state: { policies, lastSortOrder, selectedPolicy },
		actions: { loadPolicies, reloadPolicies, createPolicy, updatePolicy, deletePolicy, movePolicy, setSelectedPolicy }
	};
};

export const [PoliciesProvider, usePoliciesContext] = constate(usePolicies);
