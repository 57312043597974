import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	directoryGroup: {
		flexShrink: 0,
		alignItems: "center",
		display: "inline-flex",
		gap: "0.1rem"
	},
	directoryGroupIcon: {
		alignSelf: "center"
	}
});
