import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	noPadding: {
		"&$noPadding$noPadding": { padding: 0 }
	},
	noRightPadding: {
		"&$noRightPadding$noRightPadding": { paddingRight: 0, overflow: "hidden" }
	}
});
