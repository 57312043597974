import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	tooltipTitle: {
		display: "flex",
		alignItems: "center",
		flexDirection: "row",
		gap: "var(--spacing-x2, 8px)"
	},
	userTextCell: {
		display: "flex",
		flexDirection: "column",
		gap: "var(--spacing-x1, 4px)"
	},
	chipAndText: {
		display: "flex",
		alignItems: "center",
		gap: "var(--spacing-x1, 4px)"
	}
});
