import { createUseStyles } from "react-jss";

export const LINE_DIRECT_COLOR = "var(--color-purple-600)";
export const LINE_INDIRECT_COLOR = "var(--color-green-400)";
export const LINE_BOTH_COLOR = "var(--color-orange-500)";

export const useStyles = createUseStyles({
	graphHeader: {
		alignItems: "flex-start",
		display: "flex",
		flexDirection: "row",
		gap: "12px",
		justifyContent: "space-between",
		padding: "20px",
		position: "fixed",
		width: "100%",
		zIndex: 1
	},
	fullPage: {
		alignItems: "center",
		display: "flex",
		height: "100%",
		justifyContent: "center",
		width: "100%"
	},
	identityGraph: {
		display: "flex",
		height: "100%",
		overflow: "hidden",
		position: "relative"
	},
	elementGraphContainer: {
		display: "flex",
		height: "100%",
		width: "100%"
	},
	graphContainer: {
		display: "flex",
		height: "100%",
		width: "100%",
		cursor: (props?: { cursor?: string }) => (props?.cursor || "default") + " !important"
	},
	graphView: {
		display: "flex",
		height: "100%",
		position: "relative",
		width: "100%",
		zIndex: 0,
		"& > div > div > div:has(> $graphContainer)": {
			height: "100%",
			width: "100%"
		}
	},
	transformActions: {
		alignItems: "center",
		display: "flex",
		flexDirection: "row",
		gap: "12px"
	},
	zoomActions: {
		alignItems: "center",
		display: "flex",
		flexDirection: "row",
		gap: "4px"
	}
});
