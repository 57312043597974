import { createUseStyles } from "react-jss";
import { BP } from "utils/ui/breakpoints";

const styles = {
	app: {
		backgroundColor: "var(--navigation-bar-background-color)",
		background: "linear-gradient(155.14deg, rgba(255, 255, 255, 0.15) 0.68%, rgba(255, 255, 255, 0) 38.68%)",
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start",
		height: "100%",
		width: "100%",
		minWidth: `${BP.TEN}px`
	}
};

export const useStyles = createUseStyles(styles);
