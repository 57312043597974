import { useCallback, useEffect } from "react";
import { useAccessReviewResourcesContext } from "context/accessReviewResourcesContext";
import type { TAccessReviewResourceModel } from "models/AccessReviewResourceModel";

export const useAccessReviewResource = (id: string | undefined) => {
	const {
		state: { accessReviewResources, isGetLoading },
		actions: { getAccessReviewResource: contextGetAccessReviewResource, updateResource: contextUpdateResource }
	} = useAccessReviewResourcesContext();

	const getAccessReviewResource = useCallback(async () => {
		if (!id) return undefined;
		return contextGetAccessReviewResource(id);
	}, [contextGetAccessReviewResource, id]);

	const updateResource = useCallback(
		(fields: Partial<TAccessReviewResourceModel>) => {
			if (!id) return undefined;
			return accessReviewResources?.has(id)
				? contextUpdateResource(id, accessReviewResources.get(id)!.merge(fields))
				: undefined;
		},
		[accessReviewResources, contextUpdateResource, id]
	);

	const accessReviewResource = accessReviewResources.get(id || "");

	useEffect(() => {
		if (id && !accessReviewResource) {
			void getAccessReviewResource();
		}
	}, [accessReviewResource, getAccessReviewResource, id]);

	return {
		accessReviewResource,
		getAccessReviewResource,
		isGetLoading,
		updateResource
	};
};
