import classNames from "classnames";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "components/ui/Button";
import { CancelCircleIcon } from "components/ui/Icons/CancelCircleIcon";
import { useLoadingState } from "hooks/useLoadingState";
import { useStyles } from "./styles";

type TProps = {
	cancel: (requestId: string) => void | Promise<void>;
	requestId: string;
};

export const RequestUsersPendingActions: FC<TProps> = ({ cancel, className, innerRef, requestId }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const { withLoader, isLoading } = useLoadingState();
	const handleCancel = useCallback(async () => withLoader(cancel(requestId)), [cancel, requestId, withLoader]);

	return (
		<div className={classNames(classes.container, className)} ref={innerRef}>
			<Button
				size="medium"
				variant="secondary"
				suffix={<CancelCircleIcon />}
				onClick={handleCancel}
				loading={isLoading}>
				{t("buttons.cancel")}
			</Button>
		</div>
	);
};
