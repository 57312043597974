export const FADE_IN_ANIMATION_NAME = "fade-in";
export const FADE_OUT_ANIMATION_NAME = "fade-out";

export const styles = {
	[`@keyframes ${FADE_IN_ANIMATION_NAME}`]: {
		"0%, 50%": {
			opacity: "0"
		},

		"100%": {
			opacity: "1"
		}
	},

	[`@keyframes ${FADE_OUT_ANIMATION_NAME}`]: {
		"0%": {
			opacity: "1"
		},

		"50%, 100%": {
			opacity: "0"
		}
	},
	transitionable: {
		"&.animate": {
			'&[data-animation="fade"]': {
				"&.in": {
					animation: `var(--animation-duration) var(--transition-easing) forwards $${FADE_IN_ANIMATION_NAME}`,
					zIndex: "10"
				},

				"&.out": {
					animation: `var(--animation-duration) var(--transition-easing) forwards $${FADE_OUT_ANIMATION_NAME}`,
					zIndex: "5"
				},

				"&.reverse": {
					"&.in": {
						animation: `var(--animation-duration) var(--transition-easing-reversed) forwards reverse $${FADE_OUT_ANIMATION_NAME}`
					},

					"&.out": {
						animation: `var(--animation-duration) var(--transition-easing-reversed) forwards reverse $${FADE_IN_ANIMATION_NAME}`
					}
				}
			}
		}
	}
};
