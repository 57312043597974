import React from "react";

export const BambooHRLogo: FC = ({ className }) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" className={className}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12.7781 15.9231C13.0682 16.042 13.379 16.1021 13.6925 16.1C15.15 16.1 16.0775 15.064 16.0775 13.6925C16.0775 12.4325 15.2015 11.2325 13.7155 11.2325C12.2295 11.2325 11.308 12.353 11.308 13.718C11.3062 14.0315 11.3667 14.3423 11.4859 14.6322C11.6051 14.9222 11.7807 15.1856 12.0026 15.4072C12.2244 15.6288 12.488 15.8041 12.7781 15.9231ZM9.4285 10.0885L9.885 11.5835C9.885 11.5835 9.435 11.5 9.069 11.443C8.641 11.378 8.452 11.3145 7.908 11.093C7.364 10.8715 6.8725 10.3295 6.39 9.7245C5.9075 9.1195 4.782 8.1695 4.782 8.1695C5.01785 8.18812 5.25189 8.22507 5.482 8.28C6.11151 8.45673 6.72107 8.69801 7.301 9C7.473 9.0895 7.5685 9.1475 7.7385 9.2525C8.02633 9.44197 8.29141 9.66393 8.5285 9.914C8.60816 9.9951 8.68531 10.0721 8.75991 10.1465C8.97001 10.3561 9.15988 10.5456 9.3285 10.75C9.28257 10.6655 9.22319 10.5405 9.14897 10.3843C8.82079 9.6935 8.2025 8.39206 7.1745 7.2825C7.051 7.149 8.0455 7.7605 8.3085 8.0385C8.98097 8.74634 9.12564 9.1815 9.33268 9.80428C9.36271 9.8946 9.39405 9.98888 9.4285 10.0885ZM11.5205 11.109C11.968 10.6675 12.606 10.259 13.796 10.259C15.5845 10.259 17.129 11.803 17.129 13.5995C17.129 15.534 15.6485 17.039 13.6925 17.039C11.917 17.039 10.37 15.7875 10.37 13.779V7.25H11.4V11.2395L11.5205 11.109ZM12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22Z"
			fill="#73C41D"
		/>
	</svg>
);
