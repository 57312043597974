const configurations = [
	[[0]],
	[[1]],
	[
		[1, 0],
		[0, 1]
	],
	[
		[1, 0, 1],
		[0, 1, 0]
	],
	[
		[1, 1],
		[1, 1]
	],
	[
		[1, 0, 1],
		[0, 1, 0],
		[1, 0, 1]
	],
	[
		[0, 1, 1, 0],
		[1, 0, 0, 1],
		[0, 1, 1, 0]
	],
	[
		[0, 1, 1, 0],
		[1, 1, 1],
		[0, 1, 1, 0]
	],
	[
		[1, 1, 1],
		[1, 0, 1],
		[1, 1, 1]
	],
	[
		[1, 1, 1],
		[1, 1, 1],
		[1, 1, 1]
	]
];

export const getImageGridLocations = (imageNum: number) =>
	configurations.at(imageNum) ?? configurations.at(configurations.length - 1)!;
