import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { respondToTicket, cancelTicket, retryTicket, adminRespondToTicket } from "api/tickets";
import { TicketExtraOptionsButton } from "components/common/TicketExtraOptionsButton";
import { Button } from "components/ui/Button";
import { CancelCircleIcon } from "components/ui/Icons/CancelCircleIcon";
import { CloseIcon } from "components/ui/Icons/CloseIcon";
import { GrantedIcon } from "components/ui/Icons/GrantedIcon";
import { useTicketUpdatesContext } from "context/ticketUpdatesContext";
import { useAuthenticatedUser } from "hooks/useAuthenticatedUser";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { useLoadingState } from "hooks/useLoadingState";
import { useTicketUserPermissions } from "hooks/useTicketUserPermissions";
import { useStyles } from "./styles";
import { RequestRetryActions } from "../RequestBar/components/RequestRetryActions";
import { TicketRenewalButton } from "../TicketRenewalButton";
import type { TicketModel } from "models/TicketModel";
import type { TFullTicket } from "./types";

export const RequestDetailsFooter: FC<{
	ticket: TFullTicket;
	reloadTicket: (ticket?: TicketModel) => Promise<void>;
	closeSidebar: () => void;
}> = ({ ticket, reloadTicket, closeSidebar }) => {
	const { user } = useAuthenticatedUser();
	const classes = useStyles();
	const { t } = useTranslation();
	const { isLoading, withLoader } = useLoadingState();
	const { isLoading: isLoadingAdminApprove, withLoader: withLoaderAdminApprove } = useLoadingState();
	const { isLoading: isLoadingAdminDeny, withLoader: withLoaderAdminDeny } = useLoadingState();

	const { notifyTicketUpdate } = useTicketUpdatesContext();
	const openGlobalErrorModal = useOpenGlobalErrorModal();

	const {
		hasApproverActions,
		hasReceiverActions,
		allowAdminActions,
		isApprover,
		hasCancel,
		hasRetryActions,
		isReceiver
	} = useTicketUserPermissions(ticket, user, true);

	const updateApprovalStatus = useCallback(
		async (approved: boolean) => {
			if (!hasApproverActions) return;
			const updatedRequest = await withLoader(respondToTicket(ticket.id, approved));
			await reloadTicket(updatedRequest);
			closeSidebar();
		},
		[hasApproverActions, withLoader, ticket.id, reloadTicket, closeSidebar]
	);

	const onCancel = useCallback(async () => {
		try {
			const cancelAndReload = async (id: string) => {
				await cancelTicket(id);
				return reloadTicket();
			};
			await withLoader(cancelAndReload(ticket.id));
		} catch (error) {
			const errorMessage = error instanceof Error ? error.message : "An error occurred";
			openGlobalErrorModal(new Error(`Could not cancel ticket: ${errorMessage}`));
		}
		closeSidebar();
	}, [closeSidebar, openGlobalErrorModal, reloadTicket, ticket.id, withLoader]);

	const onRetryAction = useCallback(
		async (wantedRequest: TicketModel) => {
			try {
				if (wantedRequest.status === "failed" || wantedRequest.status === "waitingForIT") {
					const updatedRequest = await retryTicket(wantedRequest.id);
					notifyTicketUpdate(updatedRequest);
				}
			} catch (error) {
				const errorMessage = error instanceof Error ? error.message : "An error occurred";
				openGlobalErrorModal(new Error(`Could not retry ticket: ${errorMessage}`));
			}
		},
		[notifyTicketUpdate, openGlobalErrorModal]
	);

	const setApproved = useCallback(async () => {
		await updateApprovalStatus(true);
	}, [updateApprovalStatus]);

	const setDeclined = useCallback(async () => {
		await updateApprovalStatus(false);
	}, [updateApprovalStatus]);

	const adminApprove = useCallback(
		async (ticket: TicketModel) => {
			const updatedRequest = await withLoader(adminRespondToTicket(ticket.id, true));
			await reloadTicket?.(updatedRequest);
			closeSidebar();
		},
		[closeSidebar, reloadTicket, withLoader]
	);

	const adminDeny = useCallback(
		async (ticket: TicketModel) => {
			const updatedRequest = await withLoader(adminRespondToTicket(ticket.id, false));
			await reloadTicket?.(updatedRequest);
			closeSidebar();
		},
		[closeSidebar, reloadTicket, withLoader]
	);

	const handleAdminApprove = useCallback(async () => {
		const updatedRequest = await withLoaderAdminApprove(adminRespondToTicket(ticket.id, true));
		await reloadTicket?.(updatedRequest);
		closeSidebar();
	}, [closeSidebar, reloadTicket, ticket.id, withLoaderAdminApprove]);

	const handleAdminDeny = useCallback(async () => {
		const updatedRequest = await withLoaderAdminDeny(adminRespondToTicket(ticket.id, false));
		await reloadTicket?.(updatedRequest);
		closeSidebar();
	}, [closeSidebar, reloadTicket, ticket.id, withLoaderAdminDeny]);

	if (!hasApproverActions && !hasReceiverActions && !hasCancel && !hasRetryActions) return null;

	const isNotApproverAndReceiver = !isApprover && !isReceiver;

	const adminApproveAction = !isNotApproverAndReceiver ? (ticket: TicketModel) => void adminApprove(ticket) : undefined;
	const adminDenyAction = !isNotApproverAndReceiver ? (ticket: TicketModel) => void adminDeny(ticket) : undefined;

	return (
		<div className={classes.bottomBar}>
			{hasApproverActions && !hasRetryActions && (
				<>
					<TicketExtraOptionsButton
						position="top-end"
						ticket={ticket}
						onClose={closeSidebar}
						allowAdminActions={allowAdminActions}
						adminApprove={adminApproveAction}
						adminDeny={adminDenyAction}
					/>
					{isApprover && (
						<>
							<Button
								size="medium"
								variant="secondary"
								loading={isLoading}
								disabled={isLoading}
								onClick={setDeclined}
								suffix={<CloseIcon />}>
								{t("common.ticketSidebar.deny")}
							</Button>
							<Button
								size="medium"
								loading={isLoading}
								disabled={isLoading}
								onClick={setApproved}
								suffix={<GrantedIcon />}>
								{t("common.ticketSidebar.approve")}
							</Button>
						</>
					)}

					{isNotApproverAndReceiver && (
						<>
							<Button
								size="medium"
								variant="secondary"
								loading={isLoadingAdminDeny}
								disabled={isLoadingAdminDeny}
								onClick={handleAdminDeny}
								suffix={<CloseIcon />}>
								{t("common.ticketSidebar.adminDeny")}
							</Button>
							<Button
								size="medium"
								loading={isLoadingAdminApprove}
								disabled={isLoadingAdminApprove}
								onClick={handleAdminApprove}
								suffix={<GrantedIcon />}>
								{t("common.ticketSidebar.adminApprove")}
							</Button>
						</>
					)}
				</>
			)}
			{hasReceiverActions && <TicketRenewalButton ticket={ticket} />}
			{hasCancel ? (
				<Button
					size="medium"
					variant="secondary"
					loading={isLoading}
					disabled={isLoading}
					onClick={onCancel}
					suffix={<CancelCircleIcon />}>
					{t("buttons.cancel")}
				</Button>
			) : null}
			{hasRetryActions && <RequestRetryActions retry={onRetryAction} request={ticket} />}
		</div>
	);
};
