import classNames from "classnames";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { BasicCard } from "components/ui/BasicCard";
import { Typography } from "components/ui/Typography";
import { DURATION_MAP, TTicketDuration } from "utils/durationsOptions";
import { useStyles } from "./styles";

type TProps = {
	disabled?: boolean;
	duration: TTicketDuration;
	selected?: boolean;
	onClick?: (duration: TTicketDuration) => void;
};

export const DurationCard: FC<TProps> = ({ className, duration, innerRef, selected, onClick, disabled }) => {
	const { t } = useTranslation();
	const classes = useStyles();

	const cardContent = useMemo(() => {
		if (duration === -1 || !DURATION_MAP.has(`${duration}`)) {
			return (
				<Typography variant="h2_sb">
					{t(duration === -1 ? "common.durations.-1" : "common.durations.unknown")}
				</Typography>
			);
		}
		const [durationValue, durationUnit] = DURATION_MAP.get(`${duration}`)!;
		return (
			<div className={classes.container}>
				<Typography variant="h2_sb">{durationValue}</Typography>
				<Typography variant="text_title_sb">{t(`common.durations.names.short.${durationUnit}`)}</Typography>
			</div>
		);
	}, [classes.container, duration, t]);

	const onClickHandler = useCallback(() => {
		if (onClick) {
			onClick(duration);
		}
	}, [duration, onClick]);

	return (
		<BasicCard
			className={classNames(classes.card, { [classes.clickable]: !!onClick }, className)}
			disabled={disabled}
			innerRef={innerRef}
			onClick={onClickHandler}
			selected={selected}
			size="large">
			{cardContent}
		</BasicCard>
	);
};
