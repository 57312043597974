import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	container: {
		alignItems: "center",
		display: "flex",
		justifyContent: "flex-end",
		width: "100%"
	}
});
