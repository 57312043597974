import React from "react";
import { makeIcon } from "components/ui/Icon";

export const MaintainersIcon = makeIcon(
	<svg viewBox="0 0 24 24" fill="none">
		<path
			d="M13.275 16.813L15.425 18.963C15.625 19.163 15.8583 19.263 16.125 19.263C16.3917 19.263 16.625 19.163 16.825 18.963L21.05 14.713C21.2333 14.513 21.325 14.2797 21.325 14.013C21.325 13.7463 21.2333 13.513 21.05 13.313C20.85 13.113 20.6167 13.0088 20.35 13.0005C20.0833 12.9922 19.85 13.088 19.65 13.288L16.1 16.838L14.675 15.413C14.4917 15.2297 14.2583 15.138 13.975 15.138C13.6917 15.138 13.4583 15.2297 13.275 15.413C13.0917 15.5963 13 15.8297 13 16.113C13 16.3963 13.0917 16.6297 13.275 16.813Z"
			fill="currentColor"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M7.175 10.825C7.95833 11.6083 8.9 12 10 12C11.1 12 12.0417 11.6083 12.825 10.825C13.6083 10.0417 14 9.1 14 8C14 6.9 13.6083 5.95833 12.825 5.175C12.0417 4.39167 11.1 4 10 4C8.9 4 7.95833 4.39167 7.175 5.175C6.39167 5.95833 6 6.9 6 8C6 9.1 6.39167 10.0417 7.175 10.825ZM10.8027 20C10.2922 19.1175 10 18.0929 10 17C10 15.5001 10.5504 14.1288 11.4601 13.0769C10.9767 13.0256 10.49 13 10 13C8.9 13 7.81667 13.1292 6.75 13.3875C5.68333 13.6458 4.63333 14.0333 3.6 14.55C3.11667 14.8 2.72917 15.1625 2.4375 15.6375C2.14583 16.1125 2 16.6333 2 17.2V18C2 18.55 2.19583 19.0208 2.5875 19.4125C2.97917 19.8042 3.45 20 4 20H10.8027Z"
			fill="currentColor"
		/>
	</svg>
);
