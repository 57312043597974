import { createUseStyles } from "react-jss";

const STATUS_ICON_MARGIN_RIGHT = "0.75rem";
const SYSTEM_LOGO_SIZE = "2em";

export const useStyles = createUseStyles({
	systemLogo: {
		width: SYSTEM_LOGO_SIZE,
		height: SYSTEM_LOGO_SIZE,
		marginRight: "0.75rem"
	},
	button: {
		userSelect: "none",
		gap: "0.4rem",
		display: "inline-flex",
		"&$disabled": {
			pointerEvents: "none",
			color: "var(--text-color)",
			borderColor: "var(--color-grey-light)"
		}
	},
	disabled: {},
	buttonContent: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		gap: "0.5em",
		width: "5em"
	},
	actions: {
		width: "100%",
		display: "flex",
		justifyContent: "center"
	},
	statusIcon: {},
	connectedStatusIcon: {
		marginRight: STATUS_ICON_MARGIN_RIGHT,
		color: "var(--color-green)"
	},
	notConnectedStatusIcon: {
		marginRight: STATUS_ICON_MARGIN_RIGHT,
		color: "var(--color-grey-disabled)"
	}
});
