import React, { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { DirectoryGroupTooltip } from "components/common/DirectoryGroupTooltip";
import { getRedirectToId } from "components/common/RequestDetails/components/TicketActivity/utils";
import { TicketingIntegrationTicketChip } from "components/common/TicketingIntegrationTicketChip";
import { TicketNumberWithLink } from "components/common/TicketNumberWithLink";
import { TicketStatusWithIcon } from "components/common/TIcketStatusWithIcon";
import { ViewErrorButton } from "components/common/ViewErrorButton";
import { LoadingDots } from "components/ui/LoadingDots";
import { useBundles } from "hooks/useBundles";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { useIntegrations } from "hooks/useIntegrations";
import { useMultiUsers } from "hooks/useMultiUsers";
import { boldComponent } from "i18n";
import { TicketAuditLogModel } from "models/auditLogs";
import { TicketModel } from "models/TicketModel";
import { UserModel } from "models/UserModel";
import { IsNullError } from "utils/errors/isNullError";
import { TC, TICKET_AUDIT_LOG_REVERT_ACTIONS, type TReasonWontRevoked } from "utils/tickets/ticketActivity";
import { useStyles } from "./styles";
import { AuditLogUser } from "../AuditLogUser";
import type { TFunction } from "i18next";
import type { TTicketAuditLogAction } from "models/auditLogs/TicketAuditLogModel";
import type { TAuditLogContentComponent } from "./AuditLogContent.types";

const REVERT_TYPES = ["permissionReverted", "sent.revert"];

const TRANSLATIONS_PREFIX = "pages.auditLog.auditLogList.ticket.";

type TTranslationTypes =
	| Exclude<
			TTicketAuditLogAction,
			| "AccessRequestCreated"
			| "AccessRequestTaskCreated"
			| "AccessRequestApproverApproved"
			| "AccessRequestApproverDeclined"
	  >
	| "AccessRequestApproverApproved.user"
	| "AccessRequestApproverDeclined.user"
	| "AccessRequestApproverApproved.webhook"
	| "AccessRequestApproverDeclined.webhook"
	| "AccessRequestCreated.differentUser"
	| "AccessRequestCreated.sameUser"
	| "AccessRequestTaskCreated.normal"
	| "AccessRequestTaskCreated.revert";

const getTranslationPath = (
	auditLog: TicketAuditLogModel,
	logUser?: UserModel,
	ticketReceiver?: UserModel
): TTranslationTypes => {
	const action = auditLog.action;
	if (action === "AccessRequestCreated") {
		if (ticketReceiver?.id !== logUser?.id) return "AccessRequestCreated.differentUser";
		return "AccessRequestCreated.sameUser";
	}
	if (action === "AccessRequestTaskCreated") {
		return `AccessRequestTaskCreated.${auditLog.data?.get("taskType") === "revert" ? "revert" : "normal"}`;
	}
	if (action === "AccessRequestApproverApproved" || action === "AccessRequestApproverDeclined") {
		return !logUser ? `${action}.webhook` : `${action}.user`;
	}

	return action;
};

function getReasonNotRevoked(log: TicketAuditLogModel, t: TFunction) {
	const reason = log.data?.get("reason");
	return t(`common.wontRevokeReason.${reason ? (reason as TReasonWontRevoked) : "permissionDoesNotExist"}`);
}

const ACTIVITY_ITEM_TRANSLATIONS_PREFIX = "common.ticketActivity.ticketActivityItem.";

export const getTicketAuditLogRoleData = (log: TicketAuditLogModel, t: TFunction) => {
	const integrationName = (log.data?.get("virtualIntegrationName") ||
		log.data?.get("integrationName") ||
		t(`${ACTIVITY_ITEM_TRANSLATIONS_PREFIX}unknownIntegration`)) as string;
	const resourceName = (log.data?.get("virtualResourceName") ||
		log.data?.get("resourceName") ||
		t(`${ACTIVITY_ITEM_TRANSLATIONS_PREFIX}unknownResource`)) as string;
	const roleName = (log.data?.get("virtualRoleName") ||
		log.data?.get("roleName") ||
		t(`${ACTIVITY_ITEM_TRANSLATIONS_PREFIX}unknownRole`)) as string;
	return { integrationName, resourceName, roleName };
};

function getExpiredRoleData(log: TicketAuditLogModel, t: TFunction) {
	const expiredRoleName = log.data?.get("expiredRoleName") || t(`${ACTIVITY_ITEM_TRANSLATIONS_PREFIX}unknownRole`);
	const toRoleName = log.data?.get("toRoleName");

	return { expiredRoleName, toRoleName };
}

// these are the types that we want to show only the bundle's name in the audit log
const ACTIONS_FOR_BUNDLE_SHOW = [
	"AccessRequestApproverApproved",
	"AccessRequestApproverDeclined",
	"AccessRequestApproved",
	"AccessRequestPassedApprovalFlowStep",
	"AccessRequestCreated"
];

const ACTIVITY_ITEM_T_PREFIX = "common.ticketActivity.ticketActivityItem.";

export const TicketAuditLogContent: TAuditLogContentComponent<TicketAuditLogModel> = ({ auditLog, logUser }) => {
	const classes = useStyles();
	const bundles = useBundles(true);
	const integrations = useIntegrations(true);
	const { t } = useTranslation();
	const openGlobalErrorModal = useOpenGlobalErrorModal();
	const ticket = useMemo(() => {
		if (!auditLog.ticket) {
			openGlobalErrorModal(
				IsNullError.from({
					location: "ticketAuditLogContentTicket",
					parentObject: { name: "ticketAuditLog", value: auditLog.toJS() },
					requestedProperty: "ticket"
				})
			);
			return new TicketModel();
		}
		return auditLog.ticket;
	}, [auditLog, openGlobalErrorModal]);

	const users = useMultiUsers([
		ticket.receiverId,
		getRedirectToId(auditLog),
		auditLog?.data?.get("from") as string | undefined,
		auditLog?.data?.get("to") as string | undefined
	]);

	const ticketReceiver = useMemo(() => (users ? users.get(ticket.receiverId) : undefined), [users, ticket.receiverId]);
	const { targets } = ticket;
	const values = useMemo(() => {
		const target = targets?.first();
		const transProps: Record<string, string | undefined> = {
			multipleTargetsAmount: targets?.size?.toString()
		};
		const isBundle = targets?.size === 1 && target?.type === "bundle";
		if (isBundle) {
			transProps.bundleName = target?.bundle?.name || (target?.targetId && bundles?.get(target?.targetId)?.name);
		}
		const isResource = targets?.size === 1 && target?.integrationResourceRole;
		let targetType = (targets?.size || 0) > 1 ? "multiple" : target?.type === "bundle" ? "bundle" : "resource";
		// set the target type to bundle if the target is bundle and the event is one of the array ACTIONS_FOR_BUNDLE_SHOW, otherwise set the target type to integration
		targetType = ACTIONS_FOR_BUNDLE_SHOW.includes(auditLog.action) && !isResource ? targetType : "integration";

		if (isResource && ticket.ticketPermissions) {
			const { integrationName, resourceName, roleName } = getTicketAuditLogRoleData(auditLog, t);
			const firstTicketResource = ticket.ticketPermissions.first();
			const role = firstTicketResource?.role;

			transProps.roleName = role?.name ?? roleName;
			transProps.resourceName = role?.integrationResource?.name ?? resourceName;
			transProps.integrationName =
				(integrations && role?.integrationResource
					? integrations?.get(role.integrationResource.integrationId)?.name
					: undefined) ?? integrationName;
		} else {
			// if the target is bundle but we want to show the data of a specific integration
			const { integrationName, resourceName, roleName } = getTicketAuditLogRoleData(auditLog, t);
			transProps.integrationName = integrationName;
			transProps.resourceName = resourceName;
			transProps.roleName = roleName;
		}

		if (auditLog.action === "AccessRequestTaskCreated") {
			transProps.taskType =
				(auditLog.data?.get("taskType") as string | undefined) || t(`${ACTIVITY_ITEM_T_PREFIX}unknownTaskType`);
		}

		transProps.targetPath = `${TRANSLATIONS_PREFIX}ticketTarget.${targetType}` as const;

		// reason the ticket is not revoking
		if (auditLog.action === "AccessRequestPermissionWontRevoke") {
			transProps.reasonWontRevoke = getReasonNotRevoked(auditLog, t);
		}

		// if the audit log is from the type of revert, we get the expired data
		if (
			auditLog.action &&
			(REVERT_TYPES.includes(auditLog.action) ||
				TICKET_AUDIT_LOG_REVERT_ACTIONS.includes(auditLog.action) ||
				auditLog.data?.get("taskType") === "revert")
		) {
			const { expiredRoleName, toRoleName } = getExpiredRoleData(auditLog, t);

			transProps.expiredRoleName = expiredRoleName as string;
			transProps.roleName = toRoleName as string;
		}

		transProps.webhookName = (auditLog.data?.get("webhookName") ?? t("shared.unknown")) as string;

		return transProps;
	}, [targets, bundles, auditLog, ticket.ticketPermissions, t, integrations]);

	// get the redirect target user
	const redirectTarget = useMemo(() => {
		if (auditLog.action === "AccessRequestRedirectedByApprover" && users) {
			const userId = getRedirectToId(auditLog);
			if (userId) return users.get(userId);
		}
		return undefined;
	}, [auditLog, users]);

	const status = auditLog.action === "AccessRequestStatusChanged" ? auditLog.data?.get("status") || "" : undefined;

	const statusWithIcon = useMemo(
		() => (status ? <TicketStatusWithIcon status={status as string} /> : <TC />),
		[status]
	);

	const [unknownUser, unknownGroup] = useMemo(() => {
		return [
			<TC key={"UNKNOWN USER"} content={t(`${ACTIVITY_ITEM_T_PREFIX}unknownUser`)} />,
			<TC key={"UNKNOWN GROUP"} content={t(`${ACTIVITY_ITEM_T_PREFIX}unknownGroup`)} />
		];
	}, [t]);

	// get task assigned user
	const assignedUserId = auditLog.data?.get("assignedUserId");
	const assignedUser = assignedUserId ? users?.get(assignedUserId as string) : null;
	const taskAssignedUser = useMemo(
		() => (assignedUser ? <AuditLogUser user={assignedUser} className={classes.userText} /> : unknownUser),
		[assignedUser, classes.userText, unknownUser]
	);

	// empty group data (when redirected because group is empty)
	const emptyGroupName = auditLog.data?.get("groupName");

	const { forwardedFrom, forwardedTo } = useMemo(() => {
		if (!users || auditLog.action !== "AccessRequestForwarded" || !auditLog.data) return {};
		return {
			forwardedFrom: users.get((auditLog.data.get("from") as string) || ""),
			forwardedTo: users.get((auditLog.data.get("to") as string) || "")
		};
	}, [auditLog, users]);

	const props = useMemo(
		() => ({
			values,
			components: {
				bold: boldComponent,
				viewError: auditLog.data?.has("errorMessage") ? (
					<ViewErrorButton error={auditLog.data.get("errorMessage")! as string} />
				) : (
					<TC />
				),
				emptyGroup: emptyGroupName ? <DirectoryGroupTooltip groupName={emptyGroupName as string} /> : unknownGroup,
				user: <AuditLogUser user={logUser} className={classes.userText} />,
				thirdPartyTicket: ticket.ticketingIntegrationTicket ? (
					<TicketingIntegrationTicketChip
						size="small"
						className={classes.ticketingIntegrationTicketChip}
						ticketingIntegrationTicket={ticket.ticketingIntegrationTicket}
					/>
				) : (
					<TC />
				),
				receiver: users ? (
					<AuditLogUser user={users.get(ticket.receiverId)} className={classes.userText} />
				) : (
					unknownUser
				),
				redirectTarget: redirectTarget ? (
					<AuditLogUser user={redirectTarget} className={classes.userText} />
				) : (
					unknownUser
				),
				forwardedFrom: forwardedFrom ? <AuditLogUser user={forwardedFrom} className={classes.userText} /> : unknownUser,
				forwardedTo: forwardedTo ? <AuditLogUser user={forwardedTo} className={classes.userText} /> : unknownUser,
				statusWithIcon,
				taskAssignedUser,
				ticketWithLink:
					ticket.id && ticket.number ? (
						<TicketNumberWithLink ticketId={ticket.id} ticketNumber={ticket.number} />
					) : (
						<TC />
					)
			},
			i18nKey: `${TRANSLATIONS_PREFIX}${getTranslationPath(auditLog, logUser, ticketReceiver)}` as const
		}),
		[
			auditLog,
			classes.ticketingIntegrationTicketChip,
			classes.userText,
			emptyGroupName,
			forwardedFrom,
			forwardedTo,
			logUser,
			redirectTarget,
			statusWithIcon,
			taskAssignedUser,
			ticket.id,
			ticket.number,
			ticket.receiverId,
			ticket.ticketingIntegrationTicket,
			ticketReceiver,
			unknownGroup,
			unknownUser,
			users,
			values
		]
	);

	return integrations ? <Trans {...props} /> : <LoadingDots />;
};
