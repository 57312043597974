import { useEffect, useId } from "react";
import { useEscKeyDownEventListenerContext } from "context/escKeyDownEventListenersContext";

export function useOnEscKeyDownEvent(callback?: () => void, alwaysListen?: boolean) {
	const {
		actions: { addEventListener, removeEventListener }
	} = useEscKeyDownEventListenerContext();

	const id = useId();

	useEffect(() => {
		if (!callback) return;

		addEventListener(id, callback, alwaysListen);
		return () => {
			removeEventListener(id);
		};
	}, [addEventListener, alwaysListen, callback, id, removeEventListener]);
}
