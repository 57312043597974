import React from "react";
import { GroupCard } from "components/common/GroupCard";
import { useDirectoryGroup } from "hooks/useDirectoryGroup";
import { DirectoryGroupModel } from "models/DirectoryGroupModel";
import { useStyles } from "./styles";
import { SelectOption } from "../SelectOption";
import type { TOptionComponentProps } from "utils/ui/select";

function DirectoryGroupNodeOption<T extends DirectoryGroupModel | string>({
	disabled = false,
	isSelected = false,
	onSelect,
	option,
	optionKey
}: TOptionComponentProps<T>) {
	const classes = useStyles();

	const currentGroup = useDirectoryGroup(typeof option === "string" ? option : undefined);
	const remoteGroup = typeof option === "string" && currentGroup ? currentGroup : (option as DirectoryGroupModel);
	return (
		<SelectOption onSelect={onSelect} disabled={disabled} value={option} readonly key={optionKey}>
			<GroupCard group={remoteGroup} selected={isSelected} className={classes.group} />
		</SelectOption>
	);
}

DirectoryGroupNodeOption.isSelectOption = true;

export { DirectoryGroupNodeOption };
