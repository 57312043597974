import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	modalContent: {
		display: "flex",
		flexDirection: "column",
		flexWrap: "wrap",
		gap: "1rem",
		flexGrow: 1,
		alignItems: "flex-start"
	},
	settingsPopup: {
		background: "var(--color-white)",
		borderRadius: "8px",
		boxShadow: "var(--shadow-bottom)",
		display: "flex",
		flexDirection: "column",
		gap: "12px",
		minHeight: "fit-content",
		minWidth: "480px",
		overflowY: "auto",
		padding: "20px",
		transition: "max-height 0.4s ease-in-out",
		zIndex: 100
	},
	actions: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-end"
	}
});
