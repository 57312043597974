import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	detailsContainer: {
		padding: "0 2rem 1rem"
	},
	loading: {
		margin: "auto"
	},
	resource: {
		alignItems: "center",
		display: "flex",
		flexDirection: "row",
		gap: "1rem"
	},
	resourceLogo: {
		height: "2rem",
		width: "2rem"
	},
	resourceLogoContainer: {
		alignItems: "start",
		display: "flex",
		height: "100%",
		justifyContent: "start"
	},
	targetContainer: {
		display: "flex",
		wordBreak: "break-word",
		gap: "0.5rem",
		height: "100%",
		justifyContent: "center"
	},
	subText: {
		color: "#5F6066"
	}
});
