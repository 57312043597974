import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	flowBlock: {
		backgroundColor: "var(--surface-content-contrast-background-color)",
		display: "flex",
		flexDirection: "column",
		borderRadius: "var(--border-radius-semi-rounded)",
		padding: "1rem",
		gap: "0.5rem",
		flexGrow: 0,
		flexShrink: 0,
		minWidth: "22rem",
		maxWidth: "33rem"
	},
	flowBlockCaption: {
		marginInlineStart: "0.5rem"
	},
	flowBlockCaptionTypography: {
		width: "100%"
	}
});
