import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	actions: {
		display: "flex",
		justifyContent: "flex-start",
		marginTop: "1rem",
		alignItems: "baseline"
	},
	field: {
		display: "flex",
		flexDirection: "column",
		gap: "0.5rem",
		"&:not(:first-child)": {
			marginTop: "1rem"
		}
	},
	form: {
		padding: "1rem 0"
	}
});
