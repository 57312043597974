import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	radioInput: {
		display: "flex",
		gap: "0.5rem",
		padding: "0.5em"
	},
	radioButton: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		width: "1rem",
		height: "1rem",
		background: "transparent",
		borderRadius: "var(--border-radius-round)",
		"&.notSelected": {
			border: "1px solid var(--color-grey-dark)",
			"&:hover:not(.disabled)": {
				opacity: "0.5",
				cursor: "pointer",
				border: "1px solid var(--color-purple)"
			}
		},
		"&:hover:not(.disabled) $filledRadioButton": {
			opacity: "0.5"
		},
		"&.disabled": {
			border: "1px solid var(--color-grey-disabled)",
			cursor: "default"
		},
		"&.selected": {
			border: "1px solid var(--color-purple)",
			"&.disabled": {
				border: "1px solid var(--color-grey-disabled)"
			}
		}
	},
	filledRadioButton: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		background: "var(--color-purple)",
		width: "0.55rem",
		height: "0.55rem",
		borderRadius: "var(--border-radius-round)",
		"&.selected": {
			border: "1px solid var(--color-purple)"
		},
		"&.notSelected": {
			border: "1px solid var(--color-purple)",
			opacity: "0"
		},
		"&.disabled": {
			background: "var(--color-grey-disabled)",
			border: "1px solid var(--color-grey-disabled)"
		}
	}
});
