import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	container: {
		alignItems: "flex-start",
		display: "flex",
		flexDirection: "column",
		gap: "var(--spacing-x4)",
		width: "100%"
	},
	addButton: {
		alignSelf: "center"
	},
	dropdown: {
		background: "var(--color-white)",
		borderRadius: "6px",
		boxShadow: "0px 8px 7px 7px rgba(0, 0, 0, 0.20)",
		display: "flex",
		flexDirection: "column",
		gap: "var(--spacing-x1)",
		minWidth: "196px",
		padding: "var(--spacing-x1, 4px) 0"
	},
	option: {
		alignItems: "center",
		display: "flex",
		justifyContent: "space-between",
		minHeight: "52px",
		padding: "0 var(--spacing-x4, 16px)",
		transition: "background-color 0.2s ease-in-out",
		width: "100%",
		willChange: "background-color",
		"&:hover, &$selected": {
			backgroundColor: "var(--color-blue-200)"
		},
		"&:hover": {
			cursor: "pointer"
		},
		"&$disabled": {
			pointerEvents: "none",
			color: "var(--color-grey-450)"
		}
	},
	selected: {},
	disabled: {}
});
