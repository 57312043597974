import { Record } from "immutable";
import type { TDirectory } from "types/directoryConfiguration";

const defaults = {
	id: "",
	displayName: "",
	directory: "" as TDirectory,
	isDirectManagerSource: false,
	lastSync: null as Date | null,
	nextSyncTime: new Date(0)
};

export class DirectoryConfigurationModel extends Record<typeof defaults>(defaults) {
	static fromServerData(data: unknown) {
		const { directory, displayName, id, isDirectManagerSource, lastSync, nextSyncTime } = data as typeof defaults;
		return new DirectoryConfigurationModel({
			directory,
			displayName,
			isDirectManagerSource,
			lastSync: lastSync ? new Date(lastSync) : null,
			id,
			nextSyncTime: new Date(nextSyncTime)
		});
	}
}
