import { Record } from "immutable";
import { AccessReviewPermissionModel, TAccessReviewPermissionStatus } from "./AccessReviewPermissionModel";
import { AccessReviewResourceModel } from "./AccessReviewResourceModel";
import { IntegrationResourceRoleModel } from "./IntegrationResourceRoleModel";

const defaults = {
	accessReviewPermission: null as AccessReviewPermissionModel | null,
	accessReviewPermissionId: "",
	accessReviewResource: null as AccessReviewResourceModel | null,
	accessReviewResourceId: "",
	virtualRole: null as IntegrationResourceRoleModel | null,
	virtualRoleId: "",
	createdAt: new Date(0),
	id: "",
	status: "" as TAccessReviewPermissionStatus
};

export class AccessReviewResourcePermissionModel extends Record<typeof defaults>(defaults) {
	static fromServerData(data: unknown): AccessReviewResourcePermissionModel {
		const {
			accessReviewPermission,
			accessReviewPermissionId,
			accessReviewResource,
			accessReviewResourceId,
			virtualRole,
			virtualRoleId,
			createdAt,
			id,
			status
		} = data as typeof defaults;
		return new AccessReviewResourcePermissionModel({
			accessReviewPermission: accessReviewPermission
				? AccessReviewPermissionModel.fromServerData(accessReviewPermission)
				: null,
			accessReviewPermissionId,
			accessReviewResource: accessReviewResource
				? AccessReviewResourceModel.fromServerData(accessReviewResource)
				: null,
			accessReviewResourceId,
			virtualRole: virtualRole ? IntegrationResourceRoleModel.fromServerData(virtualRole) : null,
			virtualRoleId,
			createdAt: new Date(createdAt),
			id,
			status
		});
	}

	get roleName() {
		return this.virtualRole ? this.virtualRole.name : this.accessReviewPermission!.roleName;
	}

	get resourceName() {
		return this.virtualRole ? this.virtualRole.integrationResource!.name : this.accessReviewPermission!.resourceName;
	}

	get integrationId() {
		return this.virtualRole
			? this.virtualRole.integrationResource!.integrationId
			: this.accessReviewPermission!.integrationActorPermission!.integrationActor!.integrationId;
	}
}
