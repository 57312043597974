import { Record } from "immutable";

const defaults = {
	id: "",
	name: "",
	expiresAt: null as Date | null,
	value: "",
	creatorId: "",
	createdAt: new Date(0)
};

export class ApiTokenModel extends Record<typeof defaults>(defaults) {
	static fromServerData(data: unknown) {
		const { createdAt, creatorId, expiresAt, id, name, value } = data as typeof defaults;
		return new ApiTokenModel({
			createdAt: new Date(createdAt),
			creatorId,
			expiresAt: expiresAt ? new Date(expiresAt) : null,
			id,
			name,
			value
		});
	}
}
