import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "components/ui/Button";
import { Checkbox } from "components/ui/Checkbox";
import { Title } from "components/ui/Title";
import { useCompanyContext } from "context/companyContext";
import { useLoadingState } from "hooks/useLoadingState";
import { useStyles } from "./styles";

export const AccessReviewImmediateRevoke: FC = () => {
	const { t } = useTranslation();
	const classes = useStyles();

	const {
		state: { company },
		actions: { saveForceAccessReviewImmediateRevoke }
	} = useCompanyContext();

	const [forceImmediateRevoke, setForceImmediateRevoke] = useState(company?.forceAccessReviewsImmediateRevoke ?? false);
	const { isLoading, withLoader } = useLoadingState();

	const hasChanged = forceImmediateRevoke !== company?.forceAccessReviewsImmediateRevoke;

	const submit = useCallback(async () => {
		if (!hasChanged) return;
		await withLoader(saveForceAccessReviewImmediateRevoke(forceImmediateRevoke));
	}, [forceImmediateRevoke, hasChanged, saveForceAccessReviewImmediateRevoke, withLoader]);

	const onChange = useCallback(() => {
		setForceImmediateRevoke(current => !current);
	}, []);

	useEffect(() => {
		setForceImmediateRevoke(company?.forceAccessReviewsImmediateRevoke ?? false);
	}, [company]);

	return (
		<>
			<Title variant="h3" noBorder>
				{t("pages.settings.accessReviewImmediateRevoke.title")}
			</Title>
			<div className={classes.container}>
				<Checkbox
					selected={forceImmediateRevoke}
					onClick={onChange}
					label={t("pages.settings.accessReviewImmediateRevoke.label")}
					description={t("pages.settings.accessReviewImmediateRevoke.description")}
				/>
				<Button size="medium" disabled={!hasChanged} onClick={submit} loading={isLoading} className={classes.save}>
					{t("buttons.save")}
				</Button>
			</div>
		</>
	);
};
