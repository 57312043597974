import { Record } from "immutable";
import { TicketApprovalRequestModel } from "./TicketApprovalRequestModel";

type TTicketApprovalNotifiedEntityType =
	| "DirectManager"
	| "DirectoryGroup"
	| "HR"
	| "IntegrationMaintainer"
	| "IntegrationOwner"
	| "ResourceMaintainer"
	| "ResourceOwner"
	| "TeamMember"
	| "User";

const defaults = {
	displayName: "",
	id: "",
	identifier: null as string | null,
	request: null as TicketApprovalRequestModel | null,
	ticketApprovalRequestId: "",
	type: "" as TTicketApprovalNotifiedEntityType
};

export class TicketApprovalNotifiedEntityModel extends Record<typeof defaults>(defaults) {
	static fromServerData(data: unknown): TicketApprovalNotifiedEntityModel {
		const { id, identifier, request, ticketApprovalRequestId, type } = data as typeof defaults;
		return new TicketApprovalNotifiedEntityModel({
			id,
			identifier,
			request: request ? TicketApprovalRequestModel.fromServerData(request) : null,
			ticketApprovalRequestId,
			type
		});
	}
}
