import { createUseStyles } from "react-jss";

const MAX_ITEMS_IN_LIST = 8;
const WORKFLOW_ICON_SIZE_PX = 24;

export const useStyles = createUseStyles({
	modal: {
		maxWidth: "546px",
		"&$loading": {
			minHeight: "350px"
		}
	},
	contentContainer: {
		display: "flex",
		flexDirection: "column",
		alignItems: "start",
		width: "392px",
		padding: "var(--spacing-x3) 0 var(--spacing-x12) var(--spacing-x3)"
	},
	title: {
		fontSize: "20px",
		marginBottom: "var(--spacing-x05)"
	},
	subTitle: {
		fontSize: "16px",
		marginBottom: "var(--spacing-x10)"
	},
	workflowList: {
		display: "flex",
		flexDirection: "column",
		gap: "var(--spacing-x3)",
		alignItems: "start",
		maxHeight: `calc(var(--spacing-x3) * ${MAX_ITEMS_IN_LIST - 1} + ${WORKFLOW_ICON_SIZE_PX}px * ${MAX_ITEMS_IN_LIST})`,
		overflowY: "auto",
		width: "100%",
		"&::-webkit-scrollbar-thumb": {
			background: "var(--color-purple-450)"
		}
	},
	workflowItem: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-start",
		gap: "var(--spacing-x3)"
	},
	workflowItemText: {
		fontSize: "16px"
	},
	loading: {}
});
