import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	label: {
		alignItems: "center",
		display: "flex",
		gap: "var(--spacing-x1, 4px)"
	},
	labelTooltip: {
		maxWidth: "322px"
	}
});
