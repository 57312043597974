import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	field: {
		display: "flex",
		justifyContent: "flex-start",
		alignItems: "flex-start",
		gap: "var(--spacing-x3, 12px)"
	},
	title: {
		minWidth: "52px"
	}
});
