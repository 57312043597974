import { useEffect } from "react";
import { useDirectoryGroupsContext } from "context/directoryGroupsContext";

export const useDirectoryGroup = (id?: string) => {
	const {
		state: { directoryGroupsById },
		actions: { loadGroups }
	} = useDirectoryGroupsContext();

	useEffect(() => {
		const isMissing = id && !directoryGroupsById.has(id);
		if (isMissing) {
			loadGroups([id]);
		}
	}, [directoryGroupsById, id, loadGroups]);

	const directoryGroup = directoryGroupsById.get(id || "") || null;

	return directoryGroup;
};
