import classNames from "classnames";
import { List } from "immutable";
import isEqual from "lodash/isEqual";
import isNumber from "lodash/isNumber";
import React, { useCallback, useEffect, useMemo, useRef, useState, memo } from "react";
import { useResizeDetector } from "react-resize-detector";
import { VariableSizeList, type ListOnItemsRenderedProps, type ListChildComponentProps } from "react-window";
import { LoadingDots } from "components/ui/LoadingDots";
import { SCROLLBAR_SIZE_PX } from "globalStylesVariables";
import { useDebounceFn } from "hooks/useDebounce";
import { useStyles } from "./styles";

export type TColumn<T extends object> = {
	header?: React.ReactNode;
	key: string;
	renderCell: (row: T) => React.ReactNode;
	width: string | number;
};

interface ITableProps<T extends object> {
	columns: TColumn<T>[];
	defaultRowHeight?: number;
	emptyTableMessage?: React.ReactNode;
	fetchRow?: (rowIndex: number) => Promise<void> | void;
	onRowClicked?: (row: T) => void;
	perPage?: number;
	rows: (T | undefined)[];
	sideBorders?: boolean;
	tableName?: string;
	totalRows: number;
	loading?: boolean;
}

type TGridRowProps<T extends object> = {
	columns: TColumn<T>[];
	defaultRowHeight: number;
	gridTemplateColumns: string;
	onRowClicked?: (row: T) => void;
	row?: T;
	rowIndex: number;
	setSize: (index: number, height: number) => void;
	style: React.CSSProperties;
};

const Cell = <T extends object>({ column, row }: { column: TColumn<T>; row?: T }) => (
	<>{row ? column.renderCell(row) : null}</>
);
const CellMemo = React.memo(Cell) as typeof Cell;

const Row = <T extends object>({
	gridTemplateColumns,
	rowIndex,
	columns,
	row,
	setSize,
	onRowClicked,
	style,
	defaultRowHeight
}: TGridRowProps<T>) => {
	const classes = useStyles();
	const handleClick = useCallback(() => onRowClicked && row && onRowClicked(row), [onRowClicked, row]);

	const { ref, height } = useResizeDetector({ handleWidth: false });

	useEffect(() => {
		if (height) {
			setSize(rowIndex, height);
		}
	}, [height, rowIndex, setSize]);

	const element = useMemo(() => {
		if (!row) {
			return (
				<div ref={ref} className={classes.tr} style={{ gridTemplateColumns, height: defaultRowHeight }}>
					{columns.map(column => (
						<div key={column.key} className={classNames(classes.cellCommon, classes.td)}></div>
					))}
				</div>
			);
		}
		return (
			<div
				ref={ref}
				className={classNames(classes.tr, { [classes.clickable]: Boolean(onRowClicked) })}
				style={{ gridTemplateColumns }}
				onClick={handleClick}>
				{columns.map(column => (
					<div key={column.key} className={classNames(classes.cellCommon, classes.td)}>
						<CellMemo column={column} row={row} />
					</div>
				))}
			</div>
		);
	}, [
		row,
		ref,
		classes.tr,
		classes.clickable,
		classes.cellCommon,
		classes.td,
		onRowClicked,
		gridTemplateColumns,
		handleClick,
		columns,
		defaultRowHeight
	]);

	return <div style={style}>{element}</div>;
};

const RowMemo = memo(Row, isEqual) as typeof Row;

export const VirtualTable = <T extends object>({
	className,
	columns,
	defaultRowHeight = 80,
	emptyTableMessage,
	fetchRow,
	onRowClicked,
	perPage,
	rows,
	totalRows,
	loading
}: TProps<ITableProps<T>>) => {
	const classes = useStyles({});

	const gridTemplateColumns = useMemo(
		() => columns.map(({ width }) => (isNumber(width) ? `${width}px` : width)).join(" "),
		[columns]
	);

	const [hasVerticalScroll, setHasVerticalScroll] = useState(false);

	const requestedRowsRef = useRef<Set<number>>(new Set());
	const handleFetchRow = useCallback(
		(rowIndex: number) => {
			if (rows.at(rowIndex) || !fetchRow) return;
			if (requestedRowsRef.current.has(rowIndex)) return;
			requestedRowsRef.current.add(rowIndex);
			void fetchRow(rowIndex);
		},
		[fetchRow, rows]
	);

	const { ref: bodyRef, width: bodyWidth, height: bodyHeight } = useResizeDetector();

	const handleItemsRendered: (params: ListOnItemsRenderedProps) => void = useCallback(
		({ overscanStopIndex, overscanStartIndex }) => {
			for (let i = overscanStartIndex; i <= overscanStopIndex; i++) {
				handleFetchRow(i);
			}
		},
		[handleFetchRow]
	);
	const debouncedHandleItemsRendered = useDebounceFn(handleItemsRendered, 50);

	const listRef = useRef<VariableSizeList>(null);
	const listScrollElementRef = useRef<HTMLDivElement>(null);
	const headerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const listScrollElement = listScrollElementRef.current;
		if (listScrollElement) {
			setHasVerticalScroll(listScrollElement.scrollHeight > listScrollElement.clientHeight);
			const syncScroll = () => {
				if (headerRef.current) {
					headerRef.current.scrollLeft = listScrollElement.scrollLeft;
				}
			};
			listScrollElement.addEventListener("scroll", syncScroll);
			return () => listScrollElement.removeEventListener("scroll", syncScroll);
		}
		return;
	}, [bodyWidth, rows]);

	const sizeMapping = useRef<List<number>>(List<number>());
	const setSize = useCallback((index: number, size: number) => {
		sizeMapping.current = sizeMapping.current.set(index, size);
		listRef.current?.resetAfterIndex(index);
	}, []);

	const getSize = useCallback(
		(index: number) => sizeMapping.current.get(index) || defaultRowHeight,
		[defaultRowHeight]
	);

	const rowRender = useCallback(
		({ index, style }: ListChildComponentProps<T>) => (
			<RowMemo
				row={rows.at(index)}
				rowIndex={index}
				style={style}
				columns={columns}
				gridTemplateColumns={gridTemplateColumns}
				setSize={setSize}
				onRowClicked={onRowClicked}
				defaultRowHeight={defaultRowHeight}
			/>
		),
		[columns, defaultRowHeight, gridTemplateColumns, onRowClicked, rows, setSize]
	);

	return (
		<div className={classNames(classes.table, className)}>
			<div className={classes.header} style={{ overflowY: "hidden", overflowX: "hidden" }} ref={headerRef}>
				<div
					className={classes.tr}
					style={{
						gridTemplateColumns: hasVerticalScroll
							? `${gridTemplateColumns} ${SCROLLBAR_SIZE_PX}px`
							: gridTemplateColumns
					}}>
					{columns.map((column, index) => (
						<div
							key={column.key}
							className={classNames(classes.cellCommon, classes.th, {
								[classes.noSideBorders]: index === columns.length - 1 && hasVerticalScroll
							})}>
							{column.header}
						</div>
					))}
					{hasVerticalScroll && <div className={classes.th}></div>}
				</div>
			</div>

			<div className={classes.body} ref={bodyRef}>
				{totalRows === 0 && emptyTableMessage ? (
					emptyTableMessage
				) : loading ? (
					<LoadingDots className={classes.loading} center />
				) : (
					<VariableSizeList
						height={bodyHeight ?? 0}
						width={bodyWidth ?? 0}
						itemCount={totalRows}
						ref={listRef}
						itemSize={getSize}
						overscanCount={perPage ? Math.round(perPage / 2) : 5}
						estimatedItemSize={defaultRowHeight}
						onItemsRendered={debouncedHandleItemsRendered}
						outerRef={listScrollElementRef}>
						{rowRender}
					</VariableSizeList>
				)}
			</div>
		</div>
	);
};

export const PaginatedVirtualTable = <T extends { id: string }>({
	perPage,
	fetchPage,
	...virtualTableProps
}: Omit<TProps<ITableProps<T>>, "fetchRow"> & {
	perPage: number;
	fetchPage: (page: number) => undefined | Promise<unknown>;
}) => {
	const requestedPagesRef = useRef<Set<number>>(new Set());
	const fetchRow = useCallback(
		(rowIndex: number) => {
			const page = Math.ceil((rowIndex + 1) / perPage);
			if (requestedPagesRef.current?.has(page)) return;
			requestedPagesRef.current?.add(page);
			void fetchPage(page);
		},
		[fetchPage, perPage]
	);

	return <VirtualTable fetchRow={fetchRow} perPage={perPage} {...virtualTableProps} />;
};
