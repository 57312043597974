import React from "react";

export const EntitleTextNewLogo: FC = ({ className }) => (
	<svg className={className} width="100" height="24" viewBox="0 0 100 24" fill="none">
		<path
			d="M19.5 23.8H23.6V14.4C23.6 12.3 25 10.8 27 10.8C29 10.8 30.2 12.1 30.2 14.1V23.8H34.3V13.1C34.3 9.6 32 7 28.3 7C24.6 7 24.3 8.2 23.7 9.4H23.5V7.4H19.6V23.8H19.5Z"
			fill="white"
		/>
		<path
			d="M45.5002 23.7998H48.5002V20.3998H46.3002C44.7002 20.3998 44.0002 19.4998 44.0002 18.1998V10.5998H48.5002V7.39981H44.0002V2.7998H40.2002V6.0998C40.2002 6.8998 39.7002 7.2998 39.0002 7.2998H37.2002V10.4998H40.0002V18.1998C40.0002 21.6998 42.1002 23.6998 45.5002 23.6998V23.7998Z"
			fill="white"
		/>
		<path d="M52 23.7998H56.1V7.2998H52V23.6998V23.7998Z" fill="white" />
		<path
			d="M54.0998 4.9999C55.3701 4.9999 56.3998 3.97016 56.3998 2.6999C56.3998 1.42965 55.3701 0.399902 54.0998 0.399902C52.8295 0.399902 51.7998 1.42965 51.7998 2.6999C51.7998 3.97016 52.8295 4.9999 54.0998 4.9999Z"
			fill="white"
		/>
		<path
			d="M67.9001 23.7998H70.9001V20.3998H68.7001C67.1001 20.3998 66.4001 19.4998 66.4001 18.1998V10.5998H70.9001V7.39981H66.4001V2.7998H62.6001V6.0998C62.6001 6.8998 62.1001 7.2998 61.4001 7.2998H59.6001V10.4998H62.4001V18.1998C62.4001 21.6998 64.5001 23.6998 67.9001 23.6998V23.7998Z"
			fill="white"
		/>
		<path
			d="M78.8998 23.7999H80.3998V20.1999H79.6998C78.8998 20.1999 78.3998 19.6999 78.3998 18.7999V0.399902H74.2998V19.1999C74.2998 22.0999 75.8998 23.7999 78.7998 23.7999H78.8998Z"
			fill="white"
		/>
		<path
			d="M90.9 23.9998C95.2 23.9998 98.1 21.7998 98.9 18.4998H95.1C94.5 19.7998 93 20.7998 91 20.7998C89 20.7998 86.6 19.0998 86.5 16.5998H99V15.2998C99 10.6998 96.1 6.7998 90.8 6.7998C85.5 6.7998 82.5 10.2998 82.5 15.4998C82.5 20.6998 85.9 23.9998 90.8 23.9998H90.9ZM86.6 13.6998C86.9 11.5998 88.6 9.9998 90.9 9.9998C93.2 9.9998 95 11.4998 95.2 13.6998H86.6Z"
			fill="white"
		/>
		<path
			d="M5.92225 15.1371V19.4743H15.1934V24H1.19336V2H14.9445V6.43143H5.92225V10.7686H14.3222V15.1371H5.92225Z"
			fill="white"
		/>
	</svg>
);
