import { createUseStyles } from "react-jss";
import { ROLE_BAR_HEIGHT_PX } from "components/common/RoleBar/styles";
import { SPACING_X3 } from "globalStylesVariables";

const COLUMN_HEIGHT_PX = 40;
const MAX_PERMISSIONS_SHOWN = 7.5;

const MAX_HEIGHT = ROLE_BAR_HEIGHT_PX * MAX_PERMISSIONS_SHOWN + SPACING_X3 * Math.floor(MAX_PERMISSIONS_SHOWN);

export const useStyles = createUseStyles({
	columnContent: {
		display: "flex",
		height: `${COLUMN_HEIGHT_PX}px`,
		alignItems: "center",
		justifyContent: "flex-start",
		gap: "var(--spacing-x3, 12px)",
		maxWidth: "100%"
	},
	gridContainer: {
		display: "flex",
		paddingBottom: "var(--spacing-x1, 4px)",
		flexDirection: "column",
		maxHeight: `calc(${MAX_HEIGHT}px + var(--spacing-x1, 4px))`,
		gap: "var(--spacing-x3, 12px)",
		overflow: "auto"
	},
	requestsGrid: {
		display: "flex",
		flexDirection: "column",
		gap: "var(--spacing-x7, 16px)"
	},
	iconColumn: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		fontSize: "20px",
		width: "28px",
		height: "28px",
		borderRadius: "4px",
		"&$success": {
			backgroundColor: "var(--color-green-400)"
		},
		"&$failure": {
			backgroundColor: "var(--color-red-700)"
		}
	},
	image: {
		padding: 0,
		height: "100%"
	},
	title: {
		display: "flex",
		justifyContent: "flex-start",
		alignItems: "center",
		gap: "var(--spacing-x1, 4px)",
		marginBottom: "var(--spacing-x3, 12px)"
	},
	tooltipContainer: {
		display: "flex",
		maxWidth: "404px",
		padding: "var(--spacing-x1, 4px)",
		gap: "var(--spacing-x5, 20px)",
		flexDirection: "column"
	},
	tooltipSection: {
		display: "flex",
		flexDirection: "column"
	},
	chevronIcon: {
		marginLeft: "var(--spacing-x2, 8px)"
	},
	additionalPermissionsTooltip: {
		maxWidth: "475px"
	},
	failure: {},
	success: {}
});
