import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	spinner: {
		margin: "auto"
	},
	bundles: {
		display: "flex",
		flexDirection: "column",
		flexWrap: "wrap",
		gap: "2.0rem"
	},
	titleContainer: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between"
	},
	addBundleButton: {
		height: "44px",
		display: "flex",
		padding: "var(--spacing-x05, 2px) var(--spacing-x4, 16px)",
		alignItems: "center",
		gap: "var(--spacing-x2, 8px)"
	}
});
