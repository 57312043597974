import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	clickable: {
		cursor: "pointer"
	},
	body: {
		height: "100%",
		overflow: "hidden",
		position: "relative",
		width: "100%"
	},
	table: {
		backgroundColor: "var(--color-white)",
		borderSpacing: "0",
		display: "grid",
		gridTemplateRows: "auto minmax(0, 1fr)",
		height: "100%",
		minWidth: "100%"
	},
	tr: {
		display: "grid",
		"&:hover": {
			backgroundColor: "var(--color-almost-white)"
		}
	},
	td: {
		flexShrink: 0,
		boxShadow: "inset -1px -1px 0px #E5E5E5"
	},
	th: {
		flexShrink: 0,
		flexBasis: "fit-content !important",
		textAlign: "initial",
		boxShadow: "inset -1px -1px 0px #E5E5E5",
		"&$noSideBorders": {
			boxShadow: "inset 0px -1px 0px #E5E5E5"
		},
		overflow: "hidden",
		whiteSpace: "nowrap",
		wordBreak: "keep-all"
	},
	cellCommon: {
		margin: "0",
		padding: "0.5rem",
		"&:last-child": {
			boxShadow: "inset 0px -1px 0px #E5E5E5"
		}
	},
	header: {
		width: "100%",
		scrollbarColor: "transparent transparent",
		"&::-webkit-scrollbar-track": {
			backgroundColor: "transparent"
		},
		"&::-webkit-scrollbar-thumb": {
			background: "transparent"
		},
		"&::-webkit-scrollbar-thumb:hover": {
			background: "transparent"
		}
	},
	loading: {
		height: "100%",
		width: "100%"
	},
	noSideBorders: {}
});
