import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	content: {
		display: "flex",
		flex: "2 1 auto",
		flexDirection: "column",
		gap: "0.5rem"
	},
	image: {
		border: "var(--surface-block-border)",
		borderRadius: "var(--border-radius-semi-rounded)",
		height: "2.9rem",
		position: "relative",
		width: "2.9rem",
		backgroundColor: " var(--color-white)"
	},
	option: {
		alignItems: "start",
		display: "flex",
		flexDirection: "row",
		gap: "1rem",
		width: "100%",
		overflow: "hidden"
	}
});
