import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	spinner: {
		margin: "auto"
	},
	header: {
		display: "flex",
		justifyContent: "space-between",
		width: "100%"
	},
	titlePart: {
		alignItems: "center",
		display: "flex",
		flexDirection: "row",
		gap: "var(--spacing-x1, 4px)"
	},
	headerCell: {
		"&$headerCell": {
			wordBreak: "break-word"
		}
	}
});
