import { Record } from "immutable";
import { IsNullError } from "utils/errors/isNullError";
import { IntegrationResourceRoleModel } from "./IntegrationResourceRoleModel";

const defaults = {
	id: "",
	integrationResourceRole: null as unknown as IntegrationResourceRoleModel
};

export interface IBundleItemSchema {
	integrationResourceRoleId: string;
}

export class BundleItemModel extends Record<typeof defaults>(defaults) {
	static fromServerData(data: unknown) {
		const { id, integrationResourceRole } = data as typeof defaults;

		if (!integrationResourceRole) {
			throw IsNullError.from({
				location: "BundleItemModel.fromServerData",
				parentObject: {
					name: "data",
					value: data
				},
				requestedProperty: "integrationResourceRole"
			});
		}

		return new BundleItemModel({
			id,
			integrationResourceRole: IntegrationResourceRoleModel.fromServerData(integrationResourceRole)
		});
	}

	toServerData(): IBundleItemSchema {
		const { integrationResourceRole } = this;
		return {
			integrationResourceRoleId: integrationResourceRole.id
		};
	}
}
