import React from "react";
import { useCompanyContext } from "context/companyContext";
import { TICKETING_INTEGRATIONS } from "models/TicketingIntegrationTicketModel";
import { TicketingIntegration } from "./components/TicketingIntegration";
import type { CompanyModel } from "models/CompanyModel";

export const TicketingIntegrations: FC<{ company: CompanyModel }> = ({ company }) => {
	const {
		actions: { integrateWithTicketingIntegration }
	} = useCompanyContext();

	return (
		<>
			{TICKETING_INTEGRATIONS.map(name => (
				<TicketingIntegration
					key={name}
					integration={name}
					companyIntegrations={company.integratedToTicketingIntegrations}
					onAfterIntegrate={integrateWithTicketingIntegration}
				/>
			))}
		</>
	);
};
