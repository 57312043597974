import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	spacer: {
		width: "100%",
		padding: "12px 0"
	},
	line: {
		borderBottom: "1px solid",
		borderBottomColor: "var(--color-grey-700)",
		height: 0,
		width: "100%"
	}
});
