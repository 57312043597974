import { Record } from "immutable";
import uniq from "lodash/uniq";
import { validateFilterEqualityOperator } from "utils/validation/filterValidation";
import type { IFilter } from "types/filters";

const filterName = "integrationResourceId";

const defaults = {
	name: filterName as typeof filterName,
	value: [] as string[],
	operator: "is" as "is" | "isNot"
};

type TDefaults = typeof defaults;

export class IntegrationResourceIdFilter
	extends Record<TDefaults>(defaults)
	implements IFilter<TDefaults["value"], TDefaults["operator"], TDefaults["name"]>
{
	static filterName: typeof filterName = filterName;

	static fromURLSearchParams(urlSearchParams: URLSearchParams): IntegrationResourceIdFilter | null {
		const integrationResourceId = uniq(urlSearchParams.getAll("integrationResourceId"));
		const integrationResourceIdOperator = urlSearchParams.get("integrationResourceIdOperator") || undefined;
		if (integrationResourceId.length === 0 || !validateFilterEqualityOperator(integrationResourceIdOperator)) {
			return null;
		}
		return new IntegrationResourceIdFilter({
			value: integrationResourceId,
			operator: integrationResourceIdOperator
		});
	}

	toURLSearchParams(): URLSearchParams {
		const params = new URLSearchParams();
		if (!this.value.length) {
			return params;
		}
		for (const value of uniq(this.value)) {
			params.append("integrationResourceId", value);
		}
		params.append("integrationResourceIdOperator", this.operator);
		return params;
	}
}
