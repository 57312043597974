import { Record } from "immutable";
import { UserModel } from "./UserModel";

const defaults = {
	id: "",
	maintainerGroupId: "",
	user: null as UserModel | null,
	userId: ""
};

export class UserMaintainerModel extends Record<typeof defaults>(defaults) implements IMaintainerModel {
	static fromServerData(data: unknown): UserMaintainerModel {
		const { id, maintainerGroupId, user, userId } = data as typeof defaults;
		return new UserMaintainerModel({
			id,
			maintainerGroupId,
			user: user ? UserModel.fromServerData(user) : null,
			userId
		});
	}

	static fromEntity(entity: UserModel): UserMaintainerModel {
		return new UserMaintainerModel({
			id: "",
			maintainerGroupId: "",
			user: entity,
			userId: entity.id
		});
	}

	get entityId(): string {
		return this.userId;
	}
	get entity(): UserModel | null {
		return this.user;
	}
}
