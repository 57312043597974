import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	logo: {
		height: "1.2em",
		width: "1.2em"
	},
	modalTitle: {
		display: "flex",
		alignItems: "center",
		gap: "8px"
	},
	modalContent: {
		display: "flex",
		flexDirection: "column",
		gap: "12px",
		padding: "0 12px 12px 0",
		width: "480px"
	}
});
