import classNames from "classnames";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { DropdownButton } from "components/ui/DropdownButton";
import { ArrowRightIcon } from "components/ui/Icons/ArrowRightIcon";
import { CloseIcon } from "components/ui/Icons/CloseIcon";
import { GrantedIcon } from "components/ui/Icons/GrantedIcon";
import { MenuIcon } from "components/ui/Icons/MenuIcon";
import { RefreshIcon } from "components/ui/Icons/RefreshIcon";
import { useIsBeta } from "context/betaContext";
import { useRedirectModalContext } from "context/redirectModalContext";
import { useIsOpenState } from "hooks/useIsOpenState";
import { TicketModel } from "models/TicketModel";
import { routes } from "routes/routes";
import { useStyles } from "./styles";
import { RedirectTicketModal } from "../RedirectTicketModal";
import type { Placement } from "@popperjs/core";

interface IProps {
	ticket: TicketModel;
	disabled?: boolean;
	adminApprove?: (ticket: TicketModel) => void;
	adminDeny?: (ticket: TicketModel) => void;
	allowAdminActions?: boolean;
	position?: Placement;
	optionClassName?: string;
	size?: "small" | "medium" | "large";
	onClose?: () => void;
}

const TRANSLATION_PREFIX = "common.extraOptions";
const TICKET_ACTIONS_TRANSLATIONS_PREFIX = "common.ticketActions";

// in order the redirect will work you need to have a redirectModal rendered in that page
export const TicketExtraOptionsButton: FC<IProps> = ({
	ticket,
	className,
	disabled = false,
	allowAdminActions = false,
	adminApprove,
	adminDeny,
	position = "bottom-start",
	onClose
}) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const navigate = useNavigate();
	const isBeta = useIsBeta();
	const { close, open, isOpen } = useIsOpenState();
	const {
		actions: { open: openRedirect, setTicket }
	} = useRedirectModalContext();

	const openRedirectModal = useCallback(() => {
		setTicket(ticket);
		openRedirect();
	}, [ticket, openRedirect, setTicket]);

	const reopenTicket = useCallback(() => {
		if (onClose) {
			onClose();
		}
		navigate({
			pathname: isBeta ? routes.newRequest.main : "/request",
			search: `originalTicket=${ticket.id}`
		});
	}, [isBeta, navigate, onClose, ticket.id]);

	const options = useMemo(() => {
		const baseOptions = [
			{
				label: t(`${TRANSLATION_PREFIX}.redirect`),
				value: "redirect",
				icon: ArrowRightIcon
			},
			{
				label: t(`${TRANSLATION_PREFIX}.reopen`),
				value: "reopen",
				icon: RefreshIcon
			}
		];

		if (allowAdminActions && adminApprove && adminDeny) {
			return [
				{
					label: t(`${TICKET_ACTIONS_TRANSLATIONS_PREFIX}.adminApprove`),
					value: "adminApprove",
					icon: GrantedIcon
				},
				{
					label: t(`${TICKET_ACTIONS_TRANSLATIONS_PREFIX}.adminDeny`),
					value: "adminDeny",
					icon: CloseIcon
				},
				...baseOptions
			];
		}
		return baseOptions;
	}, [adminApprove, adminDeny, allowAdminActions, t]);

	const onSelect = useCallback(
		(option: string) => {
			if (option === "redirect") {
				openRedirectModal();
			} else if (option === "reopen") {
				reopenTicket();
			} else if (option === "adminDeny") {
				adminDeny?.(ticket);
			} else if (option === "adminApprove") {
				adminApprove?.(ticket);
			}
		},
		[adminApprove, adminDeny, openRedirectModal, reopenTicket, ticket]
	);

	return (
		<>
			<RedirectTicketModal />
			<DropdownButton
				onClose={close}
				open={isOpen}
				onClick={open}
				onSelectOption={onSelect}
				position={position}
				options={options}
				disabled={disabled}
				className={classNames(className, classes.extraOptionsButton)}
				withoutButtonWrapper>
				<MenuIcon />
			</DropdownButton>
		</>
	);
};
