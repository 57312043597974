import { useCallback } from "react";
import { getUserRespondedTickets } from "api/user";
import { useLoadingState } from "hooks/useLoadingState";
import { DESC, usePagination } from "hooks/usePagination";
import type { TSortOrder } from "types/pagination";

export const RESPONDED_TO_PER_PAGE = 30;
export const useRespondedToTickets = (sortOrder: TSortOrder = DESC, sortFields?: string[]) => {
	const { isLoading, withLoader } = useLoadingState();
	const { getPage, totalPages, lastPageNumber, itemsForVirtualTable, clearData, totalResults } = usePagination({
		fetchItems: getUserRespondedTickets,
		perPage: RESPONDED_TO_PER_PAGE,
		sortOrder,
		sortFields
	});

	const getNextPage = useCallback(() => {
		if (!isLoading) {
			const loadNextPage = async () => {
				await getPage(lastPageNumber + 1);
			};
			void withLoader(loadNextPage());
		}
	}, [getPage, isLoading, lastPageNumber, withLoader]);

	const canFetchMore = lastPageNumber < totalPages;
	return { tickets: itemsForVirtualTable, canFetchMore, getNextPage, clearData, totalResults, getPage };
};
