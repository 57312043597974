import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	headerContainer: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		paddingRight: "2.25rem"
	}
});
