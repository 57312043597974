import React from "react";
import { makeIcon } from "components/ui/Icon";

export const ViewIcon = makeIcon(
	<svg viewBox="0 0 24 24" fill="none">
		<path
			d="M12 16C13.25 16 14.3125 15.5625 15.1875 14.6875C16.0625 13.8125 16.5 12.75 16.5 11.5C16.5 10.25 16.0625 9.1875 15.1875 8.3125C14.3125 7.4375 13.25 7 12 7C10.75 7 9.68755 7.4375 8.81255 8.3125C7.93755 9.1875 7.50005 10.25 7.50005 11.5C7.50005 12.75 7.93755 13.8125 8.81255 14.6875C9.68755 15.5625 10.75 16 12 16ZM12 14.2C11.25 14.2 10.6125 13.9375 10.0875 13.4125C9.56255 12.8875 9.30005 12.25 9.30005 11.5C9.30005 10.75 9.56255 10.1125 10.0875 9.5875C10.6125 9.0625 11.25 8.8 12 8.8C12.75 8.8 13.3875 9.0625 13.9125 9.5875C14.4375 10.1125 14.7 10.75 14.7 11.5C14.7 12.25 14.4375 12.8875 13.9125 13.4125C13.3875 13.9375 12.75 14.2 12 14.2ZM12 19C9.68338 19 7.56672 18.3875 5.65005 17.1625C3.73338 15.9375 2.28338 14.2833 1.30005 12.2C1.25005 12.1167 1.21672 12.0125 1.20005 11.8875C1.18338 11.7625 1.17505 11.6333 1.17505 11.5C1.17505 11.3667 1.18338 11.2375 1.20005 11.1125C1.21672 10.9875 1.25005 10.8833 1.30005 10.8C2.28338 8.71667 3.73338 7.0625 5.65005 5.8375C7.56672 4.6125 9.68338 4 12 4C14.3167 4 16.4334 4.6125 18.35 5.8375C20.2667 7.0625 21.7167 8.71667 22.7 10.8C22.75 10.8833 22.7834 10.9875 22.8 11.1125C22.8167 11.2375 22.825 11.3667 22.825 11.5C22.825 11.6333 22.8167 11.7625 22.8 11.8875C22.7834 12.0125 22.75 12.1167 22.7 12.2C21.7167 14.2833 20.2667 15.9375 18.35 17.1625C16.4334 18.3875 14.3167 19 12 19ZM12 17C13.8834 17 15.6125 16.5042 17.1875 15.5125C18.7625 14.5208 19.9667 13.1833 20.8 11.5C19.9667 9.81667 18.7625 8.47917 17.1875 7.4875C15.6125 6.49583 13.8834 6 12 6C10.1167 6 8.38755 6.49583 6.81255 7.4875C5.23755 8.47917 4.03338 9.81667 3.20005 11.5C4.03338 13.1833 5.23755 14.5208 6.81255 15.5125C8.38755 16.5042 10.1167 17 12 17Z"
			fill="currentColor"
		/>
	</svg>
);
