import React from "react";
import { ErrorModal } from "components/ui/ErrorModal";
import { useGlobalErrorContext } from "context/globalErrorContext";

export const GlobalErrorModal: FC = () => {
	const {
		state: { error, modalOpen },
		actions: { closeErrorModal, retryAction }
	} = useGlobalErrorContext();

	return (
		<ErrorModal isOpen={modalOpen} error={error} onClose={closeErrorModal} onRetry={retryAction} shouldCloseOnRetry />
	);
};
