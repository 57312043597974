import { createUseStyles } from "react-jss";
import { BP_MEDIA_QUERIES } from "utils/ui/breakpoints";

export const REQUEST_BAR_HEIGHT = 174;

export const useStyles = createUseStyles({
	container: {
		alignItems: "flex-start",
		backgroundColor: "var(--color-purple-50)",
		borderRadius: "16px",
		display: "grid",
		flexShrink: 0,
		gap: "var(--spacing-x2, 8px)",
		gridTemplateColumns: "minmax(140px, 220px) 176px",
		justifyContent: "space-between",
		height: `${REQUEST_BAR_HEIGHT}px`,
		minWidth: "fit-content",
		fallbacks: {
			minWidth: "-moz-fit-content"
		},
		overflowY: "hidden",
		padding: "var(--spacing-x3, 12px)",
		position: "relative",
		"&:hover": {
			backgroundColor: "var(--color-purple-100)"
		},
		"&$clickable:active": {
			backgroundColor: "var(--color-purple-200)"
		},
		"&$selected": {
			backgroundColor: "var(--color-purple-200)"
		},
		[BP_MEDIA_QUERIES.TWENTY]: {
			gridTemplateColumns: "minmax(160px, 220px) 196px"
		},
		[BP_MEDIA_QUERIES.THIRTY]: {
			justifyContent: "stretch",
			gridTemplateColumns: "minmax(160px, 220px) auto 180px"
		},
		[BP_MEDIA_QUERIES.FORTY]: {
			justifyContent: "stretch",
			gridTemplateColumns: "minmax(160px, 220px) auto 1fr 180px"
		},
		[BP_MEDIA_QUERIES.FIFTY]: {
			gap: "var(--spacing-x6, 24px)",
			padding: "var(--spacing-x4, 16px)",
			gridTemplateColumns: "minmax(160px, 220px) auto auto 1fr 196px"
		}
	},
	grow: {
		flexGrow: 1,
		flexShrink: 1
	},
	approversJustification: {
		display: "none",
		gap: "var(--spacing-x6, 24px)",
		[BP_MEDIA_QUERIES.FIFTY]: {
			display: "grid",
			gridTemplateColumns: "minmax(150px, 1fr)"
		},
		[BP_MEDIA_QUERIES.SIXTY]: {
			gridTemplateColumns: "minmax(305px, 1fr)",
			"&$withApprovers": {
				gridTemplateColumns: "minmax(150px, 1fr) minmax(130px, 220px)"
			}
		}
	},
	clickable: {
		"&:hover": {
			cursor: "pointer"
		}
	},
	withApprovers: {},
	selected: {}
});
