import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	amountContainer: {
		paddingRight: "1rem",
		whiteSpace: "nowrap"
	},
	buttonApprove: {
		"&$buttonApprove$buttonApprove$buttonApprove": {
			"&:hover,:active": {
				backgroundColor: "var(--color-green-dark)",
				borderColor: "var(--color-green-dark)",
				color: "var(--color-white)"
			},
			color: "var(--color-green-dark)",
			"&$disabled": {
				color: "var(--color-grey-disabled)"
			}
		}
	},
	buttonDeny: {
		"&$buttonDeny$buttonDeny$buttonDeny": {
			"&:hover,:active": {
				backgroundColor: "var(--color-red-dark)",
				borderColor: "var(--color-red-dark)",
				color: "var(--color-white)"
			},
			color: "var(--color-red-dark)",
			"&$disabled": {
				color: "var(--color-grey-disabled)"
			}
		}
	},
	buttonFlag: {
		"&$buttonFlag$buttonFlag$buttonFlag": {
			"&:hover,:active": {
				backgroundColor: "var(--color-orange)",
				borderColor: "var(--color-orange)",
				color: "var(--color-white)"
			},
			color: "var(--color-orange)",
			"&$disabled": {
				color: "var(--color-grey-disabled)"
			}
		}
	},
	checkbox: {
		gap: "1rem",
		whiteSpace: "nowrap"
	},
	header: {
		alignItems: "center",
		display: "flex",
		gap: "1rem"
	},
	actions: {
		alignItems: "center",
		display: "flex",
		gap: "1rem",
		justifyContent: "end",
		width: "100%"
	},
	disabled: {}
});
