import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	container: {
		display: "flex",
		flexDirection: "column",
		gap: "0.5rem",
		height: "100%"
	},
	modalContent: {
		display: "flex",
		flexDirection: "column",
		gap: "12px",
		maxWidth: "600px",
		paddingBottom: "24px"
	}
});
