import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	setConditionsSection: {
		display: "flex",
		flexDirection: "column",
		gap: "var(--spacing-x10, 40px)",
		width: "100%"
	},
	conditionContainer: {
		display: "flex",
		flexDirection: "column",
		gap: "var(--spacing-x3, 12px)"
	},
	titleContainer: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		gap: "var(--spacing-x2, 8px)",
		alignSelf: "stretch"
	},
	titleText: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		gap: "var(--spacing-x05, 2px)",
		alignSelf: "stretch"
	},
	titleExplanationTooltip: {
		minWidth: "384px"
	},
	setConditions: {
		display: "flex",
		flexDirection: "column",
		gap: "var(--spacing-x5, 20px)",
		alignSelf: "stretch",
		alignItems: "flex-start"
	},
	disabled: {
		color: "var(--color-grey-450)"
	},
	conditionsList: {
		display: "flex",
		alignItems: "flex-start",
		alignContent: "flex-start",
		gap: "var(--spacing-x3, 12px)",
		alignSelf: "stretch",
		flexWrap: "wrap"
	},
	conditionCardTooltip: {
		minWidth: "404px"
	}
});
