import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	templateRow: {
		backgroundColor: "#FAFBFE",
		"& input": {
			fontStyle: "italic",
			color: "var(--color-grey-dark)"
		}
	},
	roleRow: {
		"&:hover:not($error)": {
			backgroundColor: "#FAFBFE"
		}
	},
	roleCell: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		margin: "0",
		padding: "1rem",
		"&$iconCell": {
			justifyContent: "center"
		}
	},
	roleCellUnmanaged: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		gap: "var(--spacing-x2, 8px)",
		width: "100%"
	},
	actions: {
		height: "100%",
		width: "100%",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		gap: "0.15rem"
	},
	button: {
		height: "fit-content",
		"& > *": {
			fontWeight: "var(--typography-font-weight-medium)"
		}
	},
	link: {
		color: "var(--typography-link-font-color)"
	},
	error: {
		backgroundColor: "var(--dimmed-error-background)"
	},
	iconCell: {}
});
