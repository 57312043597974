import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { UsersSelectInput } from "components/common/UsersSelectInput";
import { getArrayValue, IFilterProps } from "../../";

const TRANSLATION_PREFIX = "common.ticketFilters";

export const ReceiverFilter: FC<IFilterProps> = ({ onFiltersChange, ticketFilters }) => {
	const { t } = useTranslation();

	const receivers = useMemo(() => getArrayValue(ticketFilters.requestedFor), [ticketFilters.requestedFor]);

	const changeReceiver = useCallback(
		(receiver: string[]) => {
			onFiltersChange(current => ({
				...current,
				requestedFor: receiver
			}));
		},
		[onFiltersChange]
	);

	return (
		<UsersSelectInput selectedIds={receivers} onChange={changeReceiver} label={t(`${TRANSLATION_PREFIX}.receiver`)} />
	);
};
