import { Map } from "immutable";
import { compiledClientConfig } from "config";

export type TSharedDurations =
	| 10
	| 20
	| 30
	| 60
	| 300
	| 600
	| 1800
	| 3600
	| 10800
	| 21600
	| 43200
	| 57600
	| 86400
	| 259200
	| 604800
	| 2628000
	| 7884000
	| 15768000
	| 31536000
	| 63072000;

export type TApprovalAlgorithmRuleDuration = TSharedDurations | null;

export type TTicketDuration = TSharedDurations | -1;

// The following constants are durations options for access request, the constants are set in seconds.

const SHORT_DURATION_OPTIONS: TTicketDuration[] = [10, 20, 30, 60, 300, 600];

export const DURATION_OPTIONS: TTicketDuration[] = [
	...(compiledClientConfig.allowShortPermissionDurations ? SHORT_DURATION_OPTIONS : []),
	-1,
	1800,
	3600,
	10800,
	21600,
	43200,
	57600,
	86400,
	259200,
	604800,
	2628000,
	7884000,
	15768000,
	31536000,
	63072000
];

export type TDurationUnit = "seconds" | "minutes" | "hours" | "days" | "weeks" | "months" | "years";

export const DURATION_MAP = Map<`${TTicketDuration}`, [number, TDurationUnit]>({
	"10": [10, "seconds"],
	"20": [20, "seconds"],
	"30": [30, "seconds"],
	"60": [1, "minutes"],
	"300": [5, "minutes"],
	"600": [10, "minutes"],
	"1800": [0.5, "hours"],
	"3600": [1, "hours"],
	"10800": [3, "hours"],
	"21600": [6, "hours"],
	"43200": [12, "hours"],
	"57600": [16, "hours"],
	"86400": [1, "days"],
	"259200": [3, "days"],
	"604800": [1, "weeks"],
	"2628000": [1, "months"],
	"7884000": [3, "months"],
	"15768000": [6, "months"],
	"31536000": [1, "years"],
	"63072000": [2, "years"]
});
