import React from "react";

export const BeyondTrustLogo: FC = ({ className }) => (
	<svg className={className} width="16" height="22" viewBox="0 0 16 22" fill="none">
		<path
			d="M14.9214 9.20547L13.1232 7.40723C13.1232 7.40723 13.0989 7.40723 13.0746 7.40723C13.0746 7.40723 13.0503 7.43153 13.0503 7.45583V19.9949C13.0503 19.9949 13.0503 20.0435 13.0746 20.0435H13.0989L14.8971 18.2453C14.97 18.1724 15.0186 18.0509 15.0186 17.9537V9.49708C15.0186 9.39987 14.97 9.25407 14.8971 9.20547"
			fill="#FF5500"
		/>
		<path
			d="M11.6886 6.35848H5.34612C5.29752 6.35848 5.24892 6.30988 5.24892 6.26128V0.988055C5.24892 0.988055 5.24892 0.939453 5.22462 0.939453H5.17602L3.37777 2.7377C3.30487 2.8106 3.25627 2.9321 3.25627 3.0293V8.30253C3.25627 8.32683 3.25627 8.35113 3.30488 8.35113H9.62302C9.67162 8.35113 9.72022 8.39973 9.72022 8.44833V9.39606C9.72022 9.54186 9.59872 9.63906 9.47721 9.63906H3.30488H3.28057C3.28057 9.63906 3.28057 9.63906 3.28057 9.66336V16.3946C3.28057 17.1722 3.91239 17.8041 4.69001 17.8041H9.52582C9.67162 17.8041 9.76882 17.9256 9.76882 18.0471V18.9948C9.76882 19.0434 9.72022 19.092 9.67162 19.092H4.69001C3.20768 19.092 1.99265 17.877 1.99265 16.3946V4.24433C1.99265 4.24433 1.99265 4.19573 1.96835 4.19573H1.91974L0.121503 5.99397C0.0486011 6.06688 0 6.18838 0 6.28558V16.9535C0 19.2135 1.84684 21.0603 4.10679 21.0603H11.7129C11.7372 21.0603 11.7615 21.0603 11.7615 21.0117V6.38278C11.7615 6.35848 11.7615 6.33418 11.7129 6.33418L11.6886 6.35848ZM9.74452 15.5684C9.74452 15.7142 9.62302 15.8114 9.50152 15.8114H5.51623C5.37042 15.8114 5.27322 15.6899 5.27322 15.5684V11.8747C5.27322 11.7289 5.39472 11.6317 5.51623 11.6317H9.50152C9.64732 11.6317 9.74452 11.7532 9.74452 11.8747V15.5684Z"
			fill="#FF5500"
		/>
	</svg>
);
