import React, { useMemo } from "react";
import { ApprovalAlgorithm } from "components/common/ApprovalAlgorithm";
import { WorkflowsIcon } from "components/ui/Icons/WorkflowsIcon";
import { Tooltip } from "components/ui/Tooltip";
import { useApprovalAlgorithms } from "hooks/useApprovalAlgorithms";
import { useApprovalFlows } from "hooks/useApprovalFlows";
import { ApprovalAlgorithmModel } from "models/ApprovalAlgorithmModel";
import { useStyles } from "./styles";
import { EntityNodeContent } from "../components/EntityNodeContent";
import { DeletedIndicator } from "../components/indicators/DeletedIndicator";
import { EntityLabel } from "../EntityLabel";
import type { PopperOptions } from "react-popper-tooltip";
import type { TBaseEntityNodeProps } from "../types";

type TProps = TBaseEntityNodeProps & {
	withIcon?: boolean;
	approvalAlgorithm: ApprovalAlgorithmModel;
	tooltipOnOverflow?: boolean;
};

const POPPER_OPTIONS: PopperOptions = { placement: "bottom-start" };
export const WorkflowEntity: FC<TProps> = ({
	approvalAlgorithm,
	withIcon,
	size,
	className,
	variant,
	tooltipOnOverflow: propTooltipOnOverflow,
	inactive: propInactive,
	...entityNodeProps
}) => {
	const classes = useStyles();
	const approvalAlgorithms = useApprovalAlgorithms();
	const approvalFlows = useApprovalFlows();
	const currentApprovalAlgorithm = approvalAlgorithm.rules
		? approvalAlgorithm
		: approvalAlgorithms?.get(approvalAlgorithm.id);

	const indicators = useMemo(() => {
		const indicators: React.ReactNode[] = [];
		if (approvalAlgorithm.isDeleted) {
			indicators.push(<DeletedIndicator />);
		}

		return indicators;
	}, [approvalAlgorithm.isDeleted]);

	const inactive = propInactive || approvalAlgorithm.isDeleted;

	const tooltipOnOverflow = propTooltipOnOverflow || !approvalFlows || !currentApprovalAlgorithm;
	const entityNodeContent = useMemo(
		() => (
			<EntityNodeContent
				tooltipOnOverflow={tooltipOnOverflow}
				text={approvalAlgorithm.name}
				withIcon={withIcon}
				inactive={inactive}
				size={size}
				icon={<WorkflowsIcon />}
				variant={variant}
				{...entityNodeProps}
			/>
		),
		[approvalAlgorithm.name, withIcon, size, variant, entityNodeProps, tooltipOnOverflow, inactive]
	);

	const content = useMemo(() => {
		return !tooltipOnOverflow ? (
			<Tooltip
				className={classes.tooltipContainer}
				popperOptions={POPPER_OPTIONS}
				content={
					<ApprovalAlgorithm
						className={classes.tooltipContentContainer}
						key={currentApprovalAlgorithm.id}
						algorithm={currentApprovalAlgorithm}
						approvalFlows={approvalFlows}
						withDelete={false}
					/>
				}>
				{entityNodeContent}
			</Tooltip>
		) : (
			entityNodeContent
		);
	}, [
		approvalFlows,
		classes.tooltipContainer,
		classes.tooltipContentContainer,
		entityNodeContent,
		currentApprovalAlgorithm,
		tooltipOnOverflow
	]);

	return <EntityLabel content={content} size={size} indicators={indicators} className={className} />;
};
