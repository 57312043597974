import React from "react";
import { makeIcon } from "components/ui/Icon";

export const RenewIcon = makeIcon(
	<svg viewBox="0 0 24 24" fill="none">
		<path
			d="M12.05 20C9.81672 20 7.91672 19.225 6.35005 17.675C4.78338 16.125 4.00005 14.2333 4.00005 12V11.825L3.10005 12.725C2.91672 12.9083 2.68338 13 2.40005 13C2.11672 13 1.88338 12.9083 1.70005 12.725C1.51672 12.5417 1.42505 12.3083 1.42505 12.025C1.42505 11.7417 1.51672 11.5083 1.70005 11.325L4.30005 8.725C4.50005 8.525 4.73338 8.425 5.00005 8.425C5.26672 8.425 5.50005 8.525 5.70005 8.725L8.30005 11.325C8.48338 11.5083 8.57505 11.7417 8.57505 12.025C8.57505 12.3083 8.48338 12.5417 8.30005 12.725C8.11672 12.9083 7.88338 13 7.60005 13C7.31672 13 7.08338 12.9083 6.90005 12.725L6.00005 11.825V12C6.00005 13.6667 6.58755 15.0833 7.76255 16.25C8.93755 17.4167 10.3667 18 12.05 18C12.3167 18 12.5792 17.9833 12.8375 17.95C13.0959 17.9167 13.35 17.8583 13.6 17.775C13.8834 17.6917 14.15 17.7 14.4 17.8C14.65 17.9 14.8417 18.075 14.975 18.325C15.1084 18.5917 15.1209 18.8542 15.0125 19.1125C14.9042 19.3708 14.7084 19.5417 14.425 19.625C14.0417 19.7583 13.65 19.8542 13.25 19.9125C12.85 19.9708 12.45 20 12.05 20ZM11.95 6C11.6834 6 11.4209 6.01667 11.1625 6.05C10.9042 6.08333 10.65 6.14167 10.4 6.225C10.1167 6.30833 9.84588 6.3 9.58755 6.2C9.32922 6.1 9.13338 5.925 9.00005 5.675C8.86672 5.425 8.85422 5.17083 8.96255 4.9125C9.07088 4.65417 9.25838 4.48333 9.52505 4.4C9.92505 4.26667 10.325 4.16667 10.725 4.1C11.125 4.03333 11.5334 4 11.95 4C14.1834 4 16.0834 4.775 17.65 6.325C19.2167 7.875 20 9.76667 20 12V12.175L20.9 11.275C21.0834 11.0917 21.3167 11 21.6 11C21.8834 11 22.1167 11.0917 22.3 11.275C22.4834 11.4583 22.575 11.6917 22.575 11.975C22.575 12.2583 22.4834 12.4917 22.3 12.675L19.7 15.275C19.5 15.475 19.2667 15.575 19 15.575C18.7334 15.575 18.5 15.475 18.3 15.275L15.7 12.675C15.5167 12.4917 15.425 12.2583 15.425 11.975C15.425 11.6917 15.5167 11.4583 15.7 11.275C15.8834 11.0917 16.1167 11 16.4 11C16.6834 11 16.9167 11.0917 17.1 11.275L18 12.175V12C18 10.3333 17.4125 8.91667 16.2375 7.75C15.0625 6.58333 13.6334 6 11.95 6Z"
			fill="currentColor"
		/>
	</svg>
);
