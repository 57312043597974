import React from "react";
import { Typography } from "components/ui/Typography";
import { useStyles } from "./styles";

type TActionField = {
	title: string;
	content: string;
	monospace?: boolean;
};

export const ActionField: FC<TActionField> = ({ title, content, monospace }) => {
	const classes = useStyles();
	return (
		<div className={classes.field}>
			<Typography variant="body_sb" className={classes.title}>
				{title}
			</Typography>
			<Typography variant="body_reg" monospace={monospace}>
				{content}
			</Typography>
		</div>
	);
};
