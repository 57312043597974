import React from "react";

export const TeamsLogo: FC = ({ className }) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" className={className}>
		<path
			d="M15.7435 9.87354H20.833C21.314 9.87354 21.7038 10.2634 21.7038 10.7443V15.3803C21.7038 17.1475 20.2708 18.5806 18.5035 18.5806H18.4885C16.7213 18.5806 15.2882 17.1484 15.2882 15.3812C15.2882 15.3812 15.2882 15.3812 15.2882 15.3803V10.3288C15.2891 10.0769 15.4925 9.87354 15.7435 9.87354Z"
			fill="#5059C9"
		/>
		<path
			d="M19.1842 8.95677C20.3228 8.95677 21.2459 8.03375 21.2459 6.89515C21.2459 5.75655 20.3228 4.83353 19.1842 4.83353C18.0456 4.83353 17.1226 5.75655 17.1226 6.89515C17.1226 8.03375 18.0456 8.95677 19.1842 8.95677Z"
			fill="#5059C9"
		/>
		<path
			d="M12.7687 8.95676C14.4136 8.95676 15.747 7.6233 15.747 5.97838C15.747 4.33347 14.4136 3 12.7687 3C11.1237 3 9.79028 4.33347 9.79028 5.97838C9.79028 7.6233 11.1237 8.95676 12.7687 8.95676Z"
			fill="#7B83EB"
		/>
		<path
			d="M16.7398 9.87354H8.33865C7.86392 9.88503 7.48731 10.2793 7.4988 10.7549V16.0425C7.4325 18.8935 9.68861 21.2602 12.5397 21.33C15.3908 21.2602 17.6469 18.8944 17.5806 16.0425V10.7549C17.5912 10.2793 17.2155 9.88503 16.7398 9.87354Z"
			fill="#7B83EB"
		/>
		<path
			opacity="0.1"
			d="M12.9977 9.87354V17.2828C12.995 17.6223 12.7899 17.9282 12.4752 18.0572C12.3753 18.0997 12.2674 18.1218 12.1587 18.1218H7.90198C7.84274 17.9706 7.78705 17.8194 7.74196 17.6638C7.58195 17.1378 7.49973 16.5915 7.49885 16.0416V10.7532C7.48824 10.2784 7.86308 9.88503 8.33781 9.87354H12.9977Z"
			fill="black"
		/>
		<path
			opacity="0.2"
			d="M12.5397 9.87354V17.7416C12.5397 17.8504 12.5176 17.9573 12.4752 18.0581C12.3461 18.372 12.0402 18.5779 11.7008 18.5806H8.11769C8.03989 18.4294 7.96651 18.2782 7.90198 18.1227C7.83744 17.9671 7.78705 17.8203 7.74196 17.6647C7.58195 17.1387 7.49973 16.5924 7.49885 16.0425V10.7532C7.48824 10.2784 7.86308 9.88503 8.33781 9.87354H12.5397Z"
			fill="black"
		/>
		<path
			opacity="0.2"
			d="M12.5397 9.87354V16.8249C12.5362 17.2863 12.1631 17.6603 11.7008 17.6638H7.74196C7.58195 17.1378 7.49973 16.5915 7.49885 16.0416V10.7532C7.48824 10.2784 7.86308 9.88503 8.33781 9.87354H12.5397Z"
			fill="black"
		/>
		<path
			opacity="0.2"
			d="M12.0809 9.87354V16.8249C12.0774 17.2863 11.7043 17.6603 11.2419 17.6638H7.74108C7.58106 17.1378 7.49885 16.5915 7.49796 16.0416V10.7532C7.48735 10.2784 7.86219 9.88503 8.33693 9.87354H12.0809Z"
			fill="black"
		/>
		<path
			opacity="0.1"
			d="M12.9976 7.50425V8.94791C12.9198 8.95233 12.8465 8.95676 12.7687 8.95676C12.6909 8.95676 12.6175 8.95233 12.5397 8.94791C12.385 8.93731 12.232 8.91344 12.0818 8.87454C11.1535 8.65441 10.387 8.00374 10.0201 7.12411C9.95648 6.97647 9.90786 6.82265 9.87338 6.66617H12.1595C12.621 6.66794 12.9959 7.04189 12.9976 7.50425Z"
			fill="black"
		/>
		<path
			opacity="0.2"
			d="M12.5397 7.96308V8.94792C12.385 8.93731 12.232 8.91344 12.0818 8.87454C11.1535 8.65441 10.387 8.00375 10.0201 7.12411H11.7016C12.1631 7.12588 12.5379 7.50072 12.5397 7.96308Z"
			fill="black"
		/>
		<path
			opacity="0.2"
			d="M12.5397 7.96308V8.94792C12.385 8.93731 12.232 8.91344 12.0818 8.87454C11.1535 8.65441 10.387 8.00375 10.0201 7.12411H11.7016C12.1631 7.12588 12.5379 7.50072 12.5397 7.96308Z"
			fill="black"
		/>
		<path
			opacity="0.2"
			d="M12.0809 7.96308V8.87454C11.1526 8.65441 10.3861 8.00375 10.0193 7.12411H11.2428C11.7051 7.12588 12.0791 7.50072 12.0809 7.96308Z"
			fill="black"
		/>
		<path
			d="M2.83985 7.12411H11.241C11.7052 7.12411 12.0809 7.49984 12.0809 7.96397V16.3651C12.0809 16.8293 11.7052 17.205 11.241 17.205H2.83985C2.37572 17.205 2 16.8293 2 16.3651V7.96397C2 7.49984 2.37572 7.12411 2.83985 7.12411Z"
			fill="url(#paint0_linear_1_37)"
		/>
		<path d="M9.25101 10.3217H7.57131V14.895H6.50161V10.3217H4.82986V9.43414H9.25101V10.3217Z" fill="white" />
		<defs>
			<linearGradient
				id="paint0_linear_1_37"
				x1="3.7513"
				y1="6.4678"
				x2="10.3299"
				y2="17.8616"
				gradientUnits="userSpaceOnUse">
				<stop stopColor="#5A62C3" />
				<stop offset="0.5" stopColor="#4D55BD" />
				<stop offset="1" stopColor="#3940AB" />
			</linearGradient>
		</defs>
	</svg>
);
