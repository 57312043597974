export const FADE_END_IN_ANIMATION_NAME = "fade-move-from-end-in";
export const FADE_END_OUT_ANIMATION_NAME = "fade-move-from-end-out";

export const styles = {
	[`@keyframes ${FADE_END_IN_ANIMATION_NAME}`]: {
		"0%": {
			opacity: "0",
			transform: "translateX(10%)"
		},
		"100%": {
			opacity: "1",
			transform: "translateX(0%)"
		}
	},

	[`@keyframes ${FADE_END_OUT_ANIMATION_NAME}`]: {
		"0%": {
			opacity: "1",
			transform: "translateX(0%)"
		},
		"100%": {
			opacity: "0",
			transform: "translateX(-10%)"
		}
	},
	transitionable: {
		"&.animate": {
			'&[data-animation="fade-move-from-end"]': {
				"&.in": {
					animation: `var(--animation-duration) var(--transition-easing) forwards ${FADE_END_IN_ANIMATION_NAME}`,
					zIndex: "10"
				},

				"&.out": {
					animation: `var(--animation-duration) var(--transition-easing) forwards ${FADE_END_OUT_ANIMATION_NAME}`,
					zIndex: "5"
				},

				"&.reverse": {
					"&.in": {
						animation: `var(--animation-duration) var(--transition-easing-reversed) forwards reverse ${FADE_END_OUT_ANIMATION_NAME}`
					},

					"&.out": {
						animation: `var(--animation-duration) var(--transition-easing-reversed) forwards reverse ${FADE_END_IN_ANIMATION_NAME}`
					}
				}
			}
		}
	}
};
