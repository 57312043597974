import { Record } from "immutable";
import type { TConditionName } from "filters/condition.interface";
import type { TIntegrationResourceIntegrationIdFilter } from "filters/integrationResource/integrationResourceIntegrationIdFilter";
import type { TIntegrationResourceNameFilter } from "filters/integrationResource/integrationResourceNameFilter";
import type { TIntegrationResourceTagsFilter } from "filters/integrationResource/integrationResourceTagsFilter";
import type { TIntegrationResourceTypeFilter } from "filters/integrationResource/integrationResourceTypeFilter";
import type { TIntegrationResourceRoleIntegrationIdFilter } from "filters/integrationResourceRole/integrationResourceRoleIntegrationIdFilter";
import type { TIntegrationResourceRoleIntegrationResourceNameFilter } from "filters/integrationResourceRole/integrationResourceRoleIntegrationResourceNameFilter";
import type { TIntegrationResourceRoleIntegrationResourceTagsFilter } from "filters/integrationResourceRole/integrationResourceRoleIntegrationResourceTagsFilter";
import type { TIntegrationResourceRoleIntegrationResourceTypeFilter } from "filters/integrationResourceRole/integrationResourceRoleIntegrationResourceTypeFilter";
import type { TIntegrationResourceRoleNameFilter } from "filters/integrationResourceRole/integrationResourceRoleNameFilter";
import type { Require } from "types/utilTypes";

type TServerRule = {
	creatorUserId: string;
	createdAt: Date;
	id: string;
	number: number;
	updates: TRuleUpdates;
	priority: number;
	filters: { [key: string]: TCondition[] };
	updatedAt: Date;
};

export type TUpdateMaintainer = { userId: string } | { directoryGroupId: string };

export type TRuleType = "roles" | "resources";

export type TRoleUpdates = {
	approvalAlgorithmId: string | null;
	allowsRequests: boolean;
};

export type TResourceUpdates = {
	approvalAlgorithmId: string | null;
	ownerUserId: string | null;
	allowsRequests: boolean;
	maintainers: TUpdateMaintainer[];
};

export type TRoleOrResourceUpdate = TRoleUpdates & TResourceUpdates;

export type TRuleUpdates = Partial<TRoleUpdates> | Partial<TResourceUpdates>;

const UPDATE_FIELD_NAMES: (keyof TRoleOrResourceUpdate)[] = [
	"approvalAlgorithmId",
	"allowsRequests",
	"ownerUserId",
	"maintainers"
];

const conditionsDefaults = {
	integration: undefined,
	resourceName: undefined,
	resourceType: undefined,
	resourceTags: undefined,
	roleName: undefined
} as {
	integration?: Omit<TIntegrationResourceRoleIntegrationIdFilter | TIntegrationResourceIntegrationIdFilter, "relation">;
	resourceType?: Omit<
		TIntegrationResourceTypeFilter | TIntegrationResourceRoleIntegrationResourceTypeFilter,
		"relation"
	>;
	resourceName?:
		| Omit<TIntegrationResourceNameFilter, "relation">
		| Omit<TIntegrationResourceRoleIntegrationResourceNameFilter, "relation">;
	resourceTags?: Omit<
		TIntegrationResourceTagsFilter | TIntegrationResourceRoleIntegrationResourceTagsFilter,
		"relation"
	>;
	roleName?: Omit<TIntegrationResourceRoleNameFilter, "relation">;
};

type TConditions = typeof conditionsDefaults;

export type TCondition = TConditions[keyof TConditions];
export class Conditions extends Record<TConditions>(conditionsDefaults) {}

const defaults = {
	creatorUserId: "" as string,
	createdAt: new Date(),
	id: "",
	number: 0 as number,
	type: undefined as TRuleType | undefined,
	updates: {} as TRuleUpdates,
	priority: 0 as number,
	conditions: {} as Conditions,
	updatedAt: new Date()
};

interface IRuleSchema {
	priority: number;
	number?: number;
	filters: { [key: string]: { operator: string; value: unknown }[] };
	updates: TRuleUpdates;
}

function mapConditionNameToFilterName(conditionName: TConditionName, ruleType: TRuleType): string {
	switch (conditionName) {
		case "resourceType":
			return ruleType === "roles" ? "integrationResourceRoleIntegrationResourceType" : "integrationResourceType";
		case "resourceName":
			return ruleType === "roles" ? "integrationResourceRoleIntegrationResourceName" : "integrationResourceName";
		case "integration":
			return ruleType === "roles" ? "integrationResourceRoleIntegrationId" : "integrationResourceIntegrationId";
		case "resourceTags":
			return ruleType === "roles" ? "integrationResourceRoleIntegrationResourceTags" : "integrationResourceTags";
		case "roleName":
			return "integrationResourceRoleName";
		default:
			return conditionName;
	}
}

const FILTER_NAME_TO_CONDITION_NAME_MAP: Map<string, string> = new Map([
	["integrationResourceType", "resourceType"],
	["integrationResourceRoleIntegrationResourceType", "resourceType"],
	["integrationResourceName", "resourceName"],
	["integrationResourceRoleIntegrationResourceName", "resourceName"],
	["integrationResourceRoleIntegrationId", "integration"],
	["integrationResourceIntegrationId", "integration"],
	["integrationResourceTags", "resourceTags"],
	["integrationResourceRoleIntegrationResourceTags", "resourceTags"],
	["integrationResourceRoleName", "roleName"]
]);

const getValidUpdates = (updates: TRuleUpdates) =>
	Object.fromEntries(
		Object.entries(updates).filter(([key]) => UPDATE_FIELD_NAMES.includes(key as keyof TRoleOrResourceUpdate))
	);

export class RuleModel extends Record<typeof defaults>(defaults) {
	static fromServerData(data: unknown, ruleType: TRuleType) {
		const { createdAt, creatorUserId, filters, id, number, priority, updatedAt, updates } = data as TServerRule;
		return new RuleModel({
			creatorUserId,
			createdAt: new Date(createdAt),
			id,
			number,
			type: ruleType,
			priority,
			conditions: new Conditions(
				Object.entries(filters).reduce(
					(acc, [key, condition]) => ({
						...acc,
						[FILTER_NAME_TO_CONDITION_NAME_MAP.get(key)!]: condition ? condition[0] : undefined
					}),
					{}
				)
			),
			updates: getValidUpdates(updates),
			updatedAt: new Date(updatedAt)
		}) as Require<RuleModel, "type">;
	}

	toServerData(): IRuleSchema {
		const { type, priority, conditions: filters, updates } = this;
		const maintainers = (updates as TResourceUpdates).maintainers;
		return {
			priority,
			filters: Object.entries(filters.toObject()).reduce(
				(acc, [key, condition]) => ({
					...acc,
					[mapConditionNameToFilterName(key as TConditionName, type!)]: condition ? [condition] : undefined
				}),
				{}
			),
			updates: {
				...updates,
				...(maintainers
					? {
							maintainers: maintainers.map(maintainer => {
								if ("userId" in maintainer) {
									return { id: maintainer.userId, type: "user" };
								}
								return { id: maintainer.directoryGroupId, type: "directoryGroup" };
							})
						}
					: {})
			}
		};
	}
}
