import { Record } from "immutable";

const defaults = {
	id: "",
	forwarderUserId: "",
	targetUserId: ""
};

export class ForwardModel extends Record<typeof defaults>(defaults) {
	static fromServerData(data: unknown) {
		return new ForwardModel(data as { id: string; forwarderUserId: string; targetUserId: string });
	}
}
