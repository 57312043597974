import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	button: {
		background: ({ baseColor }: { baseColor: string }) => baseColor,
		color: "var(--color-purple-dark)",
		alignItems: "center",
		borderRadius: "var(--border-radius-rounder)",
		border: "none",
		boxSizing: "border-box",
		cursor: "pointer",
		display: "flex",
		gap: "0.6rem",
		height: "40px",
		lineHeight: "30px",
		outline: "none",
		padding: "3px 12px",
		transition: "background-color .218s,border-color .218s",
		width: "100%",
		minWidth: "250px",
		"&$button": {
			backgroundColor: "var(--color-white)"
		},
		"&:hover,:focus": {
			opacity: 0.8
		},
		"&:active": {
			opacity: 0.6
		}
	},
	text: {
		fontSize: "14px",
		fontWeight: "var(--typography-font-weight-medium)",
		textAlign: "start",
		width: "100%"
	},
	logoContainer: {
		borderRadius: "2px",
		display: "flex",
		height: "100%",
		justifyContent: "center",
		alignItems: "center",
		width: "42px"
	},
	logo: {
		height: "22px",
		width: "22px"
	}
});
