import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	checkboxContainer: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between"
	},
	descriptionContainer: {
		width: "100%",
		display: "flex",
		flexDirection: "column",
		cursor: "pointer",
		"&$disabled": {
			opacity: 0.5,
			cursor: "initial"
		}
	},
	label: {
		color: "var(--color-grey-dark)"
	},
	description: {},
	disabled: {}
});
