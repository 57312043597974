import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	container: {
		display: "flex",
		flexDirection: "column",
		padding: "0.25rem",
		overflow: "hidden"
	},
	item: {
		display: "flex",
		flexDirection: "row",
		gap: "0.5em",
		alignItems: "center",
		overflow: "hidden"
	},
	itemIcon: {
		fontSize: "0.5rem",
		fill: "#C4C4C4"
	},
	itemContainer: {
		display: "flex",
		alignItems: "center",
		overflow: "hidden",
		gap: "0.4rem",
		whiteSpace: "nowrap"
	}
});
