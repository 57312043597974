import classNames from "classnames";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Divider } from "components/ui/Divider";
import { TooltipOnOverflow } from "components/ui/TooltipOnOverflow";
import { useMultiUsers } from "hooks/useMultiUsers";
import { UserModel } from "models/UserModel";
import { useStyles } from "./styles";

type TInfoBarProps = {
	user: UserModel;
};

export const InfoBar: FC<TInfoBarProps> = ({ className, innerRef, user }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const managers = useMultiUsers(user.managerIds?.toArray() || [])?.toArray();

	const userManagers = useMemo(
		() =>
			managers?.map(([key, manager]) => (
				<div key={manager.id} className={classes.managersContainer}>
					<Divider vertical />
					<div className={classNames(classes.column, classes.managerColumn)}>
						<TooltipOnOverflow textVariant="text_sm_reg" content={t("pages.users.columns.manager")} />
						<TooltipOnOverflow textVariant="text_title_sb" content={manager.fullName} />
					</div>
				</div>
			)),
		[classes.column, classes.managerColumn, classes.managersContainer, managers, t]
	);

	return (
		<div className={classes.container} ref={innerRef}>
			<div className={classNames(classes.column, classes.emailColumn)}>
				<TooltipOnOverflow textVariant="text_sm_reg" content={t("pages.users.columns.email")} />
				<TooltipOnOverflow textVariant="text_title_sb" content={user.email}></TooltipOnOverflow>
			</div>
			<Divider vertical />
			<div className={classNames(classes.column, classes.roleColumn)}>
				<TooltipOnOverflow textVariant="text_sm_reg" content={t("pages.users.columns.role")} />
				<TooltipOnOverflow textVariant="text_title_sb" content={user.role} />
			</div>
			{userManagers}
		</div>
	);
};
