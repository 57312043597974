import { Record } from "immutable";
import { IntegrationActorModel } from "./IntegrationActorModel";
import { IntegrationResourceRoleModel } from "./IntegrationResourceRoleModel";
import { TicketPermissionModel } from "./TicketPermissionModel";

export type TTaskType = "grant" | "revoke" | "revert";
export type TTaskStatus = "todo" | "inProgress" | "done";

const defaults = {
	assignedUserId: null as string | null,
	createdAt: new Date(),
	id: "",
	integrationActor: null as IntegrationActorModel | null,
	integrationResourceRole: null as IntegrationResourceRoleModel | null,
	oldIntegrationResourceRole: null as IntegrationResourceRoleModel | null,
	status: "" as TTaskStatus,
	ticketPermission: null as TicketPermissionModel | null,
	type: "" as TTaskType,
	updatedAt: new Date(),
	url: null as string | null
};

export class TaskModel extends Record<typeof defaults>(defaults) {
	static fromServerData(data: unknown) {
		const {
			assignedUserId,
			createdAt,
			id,
			integrationActor,
			integrationResourceRole,
			oldIntegrationResourceRole,
			status,
			ticketPermission,
			type,
			updatedAt,
			url
		} = data as typeof defaults;
		return new TaskModel({
			assignedUserId: assignedUserId || null,
			createdAt: new Date(createdAt),
			id,
			integrationActor: integrationActor ? IntegrationActorModel.fromServerData(integrationActor) : null,
			integrationResourceRole: integrationResourceRole
				? IntegrationResourceRoleModel.fromServerData(integrationResourceRole)
				: null,
			oldIntegrationResourceRole: oldIntegrationResourceRole
				? IntegrationResourceRoleModel.fromServerData(oldIntegrationResourceRole)
				: null,
			status,
			ticketPermission: ticketPermission ? TicketPermissionModel.fromServerData(ticketPermission) : null,
			type,
			updatedAt: new Date(updatedAt),
			url
		});
	}
}
