import React from "react";
import { makeIcon } from "components/ui/Icon";

export const DoneAllIcon = makeIcon(
	<svg viewBox="0 0 24 24" fill="none">
		<path
			d="M6.7 17.6C6.56667 17.6 6.44167 17.575 6.325 17.525C6.20833 17.475 6.1 17.4 6 17.3L1.775 13.075C1.59167 12.8917 1.5 12.6542 1.5 12.3625C1.5 12.0708 1.59167 11.8333 1.775 11.65C1.95833 11.4667 2.19167 11.375 2.475 11.375C2.75833 11.375 2.99167 11.4667 3.175 11.65L6.725 15.2L8.125 16.6L7.4 17.3C7.3 17.4 7.19167 17.475 7.075 17.525C6.95833 17.575 6.83333 17.6 6.7 17.6ZM12.35 17.575C12.2167 17.575 12.0917 17.5542 11.975 17.5125C11.8583 17.4708 11.75 17.4 11.65 17.3L7.4 13.05C7.21667 12.8667 7.125 12.6292 7.125 12.3375C7.125 12.0458 7.21667 11.8083 7.4 11.625C7.58333 11.4417 7.81667 11.35 8.1 11.35C8.38333 11.35 8.61667 11.4417 8.8 11.625L12.35 15.175L20.85 6.67499C21.0333 6.49166 21.2667 6.39999 21.55 6.39999C21.8333 6.39999 22.0667 6.49166 22.25 6.67499C22.4333 6.85833 22.525 7.09583 22.525 7.38749C22.525 7.67916 22.4333 7.91666 22.25 8.09999L13.05 17.3C12.95 17.4 12.8417 17.4708 12.725 17.5125C12.6083 17.5542 12.4833 17.575 12.35 17.575ZM12.35 12.35L10.925 10.95L15.175 6.69999C15.3583 6.51666 15.5958 6.42499 15.8875 6.42499C16.1792 6.42499 16.4167 6.51666 16.6 6.69999C16.7833 6.88333 16.875 7.11666 16.875 7.39999C16.875 7.68333 16.7833 7.91666 16.6 8.09999L12.35 12.35Z"
			fill="currentColor"
		/>
	</svg>
);
