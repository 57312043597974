enum Breakpoint {
	TEN = 10, // Smallest
	TWENTY = 20,
	THIRTY = 30,
	FORTY = 40,
	FIFTY = 50,
	SIXTY = 60,
	SEVENTY = 70,
	EIGHTY = 80 // Largest
}

type TBreakpointKeys = keyof typeof Breakpoint;
export type TBreakpointOption = TBreakpointKeys | Breakpoint;

// Change here if any breakpoint size changes
const TEN_BREAKPOINT = 500;
const TWENTY_BREAKPOINT = 751;
const THIRTY_BREAKPOINT = 964;
const FORTY_BREAKPOINT = 1131;
const FIFTY_BREAKPOINT = 1394;
const SIXTY_BREAKPOINT = 1571;
const SEVENTY_BREAKPOINT = 1758;
const EIGHTY_BREAKPOINT = 1871;

/*
    Access using:
    BREAKPOINTS[Breakpoint.TEN] / BREAKPOINTS[10]  / BREAKPOINTS.TEN / BREAKPOINTS["TEN"]
    or
    BP[Bp.TEN] / BP[10] / BP.TEN / BP["TEN"]
*/
const BREAKPOINTS: Record<Breakpoint | TBreakpointKeys, number> = {
	[Breakpoint.TEN]: TEN_BREAKPOINT,
	TEN: TEN_BREAKPOINT,
	[Breakpoint.TWENTY]: TWENTY_BREAKPOINT,
	TWENTY: TWENTY_BREAKPOINT,
	[Breakpoint.THIRTY]: THIRTY_BREAKPOINT,
	THIRTY: THIRTY_BREAKPOINT,
	[Breakpoint.FORTY]: FORTY_BREAKPOINT,
	FORTY: FORTY_BREAKPOINT,
	[Breakpoint.FIFTY]: FIFTY_BREAKPOINT,
	FIFTY: FIFTY_BREAKPOINT,
	[Breakpoint.SIXTY]: SIXTY_BREAKPOINT,
	SIXTY: SIXTY_BREAKPOINT,
	[Breakpoint.SEVENTY]: SEVENTY_BREAKPOINT,
	SEVENTY: SEVENTY_BREAKPOINT,
	[Breakpoint.EIGHTY]: EIGHTY_BREAKPOINT,
	EIGHTY: EIGHTY_BREAKPOINT
};

const BREAKPOINTS_MEDIA_QUERIES: Record<Breakpoint | TBreakpointKeys, string> = {
	[Breakpoint.TEN]: `@media (min-width: ${TEN_BREAKPOINT}px)`,
	TEN: `@media (min-width: ${TEN_BREAKPOINT}px)`,
	[Breakpoint.TWENTY]: `@media (min-width: ${TWENTY_BREAKPOINT}px)`,
	TWENTY: `@media (min-width: ${TWENTY_BREAKPOINT}px)`,
	[Breakpoint.THIRTY]: `@media (min-width: ${THIRTY_BREAKPOINT}px)`,
	THIRTY: `@media (min-width: ${THIRTY_BREAKPOINT}px)`,
	[Breakpoint.FORTY]: `@media (min-width: ${FORTY_BREAKPOINT}px)`,
	FORTY: `@media (min-width: ${FORTY_BREAKPOINT}px)`,
	[Breakpoint.FIFTY]: `@media (min-width: ${FIFTY_BREAKPOINT}px)`,
	FIFTY: `@media (min-width: ${FIFTY_BREAKPOINT}px)`,
	[Breakpoint.SIXTY]: `@media (min-width: ${SIXTY_BREAKPOINT}px)`,
	SIXTY: `@media (min-width: ${SIXTY_BREAKPOINT}px)`,
	[Breakpoint.SEVENTY]: `@media (min-width: ${SEVENTY_BREAKPOINT}px)`,
	SEVENTY: `@media (min-width: ${SEVENTY_BREAKPOINT}px)`,
	[Breakpoint.EIGHTY]: `@media (min-width: ${EIGHTY_BREAKPOINT}px)`,
	EIGHTY: `@media (min-width: ${EIGHTY_BREAKPOINT}px)`
};

export { Breakpoint, Breakpoint as Bp, BREAKPOINTS, BREAKPOINTS as BP, BREAKPOINTS_MEDIA_QUERIES as BP_MEDIA_QUERIES };
