import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	sectionTitle: {
		alignItems: "center",
		display: "flex",
		gap: "var(--spacing-x2, 8px)"
	},
	sectionContent: {
		flexGrow: 1
	}
});
