import { CloseCircleIcon } from "components/ui/Icons/CloseCircleIcon";
import { DoneCircleIcon } from "components/ui/Icons/DoneCircleIcon";
import { PendingIcon } from "components/ui/Icons/PendingIcon";
import { RefreshIcon } from "components/ui/Icons/RefreshIcon";
import type { TTicketAuditLogAction } from "models/auditLogs/TicketAuditLogModel";

const APPROVAL_TYPES = ["userApproved", "finalApproved", "granted", "automaticApproval"];
const REJECTION_TYPES = ["revoked", "userDeclined", "failed.grant", "failed.revoke"];
const WAITING_TYPES = ["sent.grant", "sent.revoke", "sent.revert"];
const RETRY_TYPES = ["retry.grant", "retry.revoke"];

export const getApprovalIcon = (status: TTicketAuditLogAction | null) => {
	if (status && APPROVAL_TYPES.includes(status)) return DoneCircleIcon.Green;
	if (status && REJECTION_TYPES.includes(status)) return CloseCircleIcon.Red;
	if (status && WAITING_TYPES.includes(status)) return PendingIcon;
	if (status && RETRY_TYPES.includes(status)) return RefreshIcon;

	return null;
};
