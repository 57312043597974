import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	actions: {
		alignItems: "center",
		display: "flex",
		gap: "1rem"
	},
	lineHeightButton: {
		fontSize: "1.5em"
	}
});
