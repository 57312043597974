import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "components/ui/Form";
import { Input } from "components/ui/Input";
import { removeRedundantSpaces } from "utils/strings";
import { CommonConfigFields } from "../CommonConfigFields";
import type { IConfigForm } from "types/directoryConfiguration";

export const MicrosoftAdConfigurationForm: FC<IConfigForm> = ({
	onSave,
	error,
	enableSave,
	shouldReset,
	inEditMode
}) => {
	const { t } = useTranslation();
	const [server, setServer] = useState("");
	const [username, setUserName] = useState("");
	const [password, setPassword] = useState("");
	const [baseDn, setBaseDn] = useState("");

	const isValid = useMemo(() => {
		return (
			enableSave &&
			!!(
				removeRedundantSpaces(server) &&
				removeRedundantSpaces(username) &&
				removeRedundantSpaces(password) &&
				removeRedundantSpaces(baseDn)
			)
		);
	}, [enableSave, server, username, password, baseDn]);

	const submit = useCallback(
		async (agentTokenId?: string | null, isDirectManagerSource?: boolean) => {
			if (!isValid) return;

			const configuration = {
				server,
				username,
				password,
				// eslint-disable-next-line camelcase
				base_dn: baseDn
			};
			await onSave(configuration, isDirectManagerSource, agentTokenId, false);
		},
		[isValid, server, username, password, baseDn, onSave]
	);

	useEffect(() => {
		if (shouldReset) {
			setServer("");
			setUserName("");
			setPassword("");
			setBaseDn("");
		}
	}, [shouldReset]);

	return (
		<>
			<Form.Field>
				<Input
					label={t("pages.settings.directoryConfiguration.active_directory.server")}
					value={server}
					onValueChange={setServer}
					isRequired
				/>
			</Form.Field>
			<Form.Field>
				<Input
					label={t("pages.settings.directoryConfiguration.active_directory.username")}
					value={username}
					onValueChange={setUserName}
					isRequired
				/>
			</Form.Field>
			<Form.Field>
				<Input
					label={t("pages.settings.directoryConfiguration.active_directory.password")}
					value={password}
					onValueChange={setPassword}
					isRequired
					type="password"
				/>
			</Form.Field>
			<Form.Field>
				<Input
					label={t("pages.settings.directoryConfiguration.active_directory.baseDn")}
					value={baseDn}
					onValueChange={setBaseDn}
					isRequired
				/>
			</Form.Field>
			<CommonConfigFields inEditMode={inEditMode} onSave={submit} error={error} disabled={!isValid} />
		</>
	);
};
