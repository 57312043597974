import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	container: {
		display: "flex"
	},
	text: {
		whiteSpace: "nowrap",
		textOverflow: "ellipsis",
		overflow: "hidden"
	}
});
