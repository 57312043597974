import { createUseStyles } from "react-jss";
import { BP_MEDIA_QUERIES } from "utils/ui/breakpoints";

const FOOTER_HEIGHT = 68;

export const useStyles = createUseStyles({
	container: {
		height: "100%",
		overflowY: "auto",
		width: "100%"
	},
	steps: {
		height: "100%",
		width: "100%",
		"& > *": {
			overflow: "initial",
			flexGrow: "1"
		},
		paddingBottom: `${FOOTER_HEIGHT}px`,
		display: "flex",
		flexDirection: "column"
	},
	footer: {
		alignItems: "center",
		backgroundColor: "var(--color-white)",
		bottom: 0,
		boxShadow: "0px -4px 5px 1px rgba(0, 0, 0, 0.20)",
		display: "grid",
		gap: "var(--spacing-x3, 12px)",
		gridTemplateColumns: "0 1fr 1fr",
		height: `${FOOTER_HEIGHT}px`,
		padding: "var(--spacing-x3, 12px) var(--spacing-x8, 32px)",
		position: "fixed",
		width: "100%",
		[BP_MEDIA_QUERIES.FORTY]: {
			gridTemplateColumns: "1fr 1fr 1fr"
		}
	},
	footerLeft: {},
	footerCenter: {
		display: "flex",
		justifyContent: "flex-start",
		[BP_MEDIA_QUERIES.FORTY]: {
			justifyContent: "center"
		}
	},
	footerRight: {
		display: "flex",
		gap: "var(--spacing-x2, 8px)",
		justifyContent: "flex-end"
	}
});
