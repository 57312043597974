import { createUseStyles } from "react-jss";

interface IStylesProps {
	color: string;
}

export const useStyles = createUseStyles({
	disabled: { opacity: "0.4" },
	divider: ({ color }: IStylesProps) => ({
		height: "8px",
		width: "100%",
		backgroundColor: color
	}),
	stepContainer: {
		overflow: "auto",
		width: "268px",
		minWidth: "268px"
	},
	stepEntitiesGroup: {
		backgroundColor: "var(--color-purple-100)",
		borderBottomLeftRadius: "4px",
		borderBottomRightRadius: "4px",
		padding: "12px 0",
		display: "flex",
		flexDirection: "column",
		gap: "4px",
		justifyContent: "flex-start",
		alignItems: "center"
	},
	stepEntitiesGroupContainer: {
		width: "100%",
		backgroundColor: "var(--color-purple-200)",
		borderRadius: "4px",
		color: "var(--color-purple-600)",
		textAlign: "center"
	},
	stepEntitiesGroupsContainer: {
		padding: "12px 0",
		display: "flex",
		flexDirection: "column",
		gap: "16px",
		alignItems: "center",
		justifyContent: "flex-start"
	},
	stepTitle: ({ color }: IStylesProps) => ({
		alignItems: "center",
		color,
		display: "flex",
		fontSize: "16px",
		gap: "12px",
		justifyContent: "flex-start",
		marginBottom: "4px",
		"& > $stepTitleText": {
			fontSize: "16px"
		},
		"& > $stepTitleIcon": {
			height: "24px",
			width: "24px"
		}
	}),
	groupsSeparator: {
		display: "flex",
		width: "100%",
		justifyContent: "space-between",
		gap: "var(--spacing-x3, 12px)",
		alignItems: "center",
		"&::before, &::after": {
			content: '""',
			height: "1px",
			flex: 1,
			backgroundColor: "var(--color-grey-500)"
		}
	},
	stepTitleContainer: {},
	stepTitleIcon: {},
	stepTitleText: {}
});
