import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	bold: {
		"&$bold": { fontWeight: "var(--typography-font-weight-bold)" }
	},
	content: {
		alignItems: "center",
		flexDirection: "row"
	},
	entity: {
		alignItems: "center",
		display: "inline-flex",
		gap: "0.5rem",
		flexDirection: "row"
	}
});
