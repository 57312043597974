import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Input } from "components/ui/Input";
import { usePersonalAccessTokensContext } from "context/personalAccessTokensContext";
import ApiError from "utils/errors/apiError";
import { checkIfTokenIsNew } from "../../../utils";
import type { TPersonalAccessToken } from "../../../PersonalAccessTokensTableSection";

type TProps = {
	token: TPersonalAccessToken;
	tokenName?: string;
	setNewTokenName: (name: string) => void;
	setError: (error: Error | null) => void;
};

const PROFILE_PAGE_TRANSLATION_PREFIX = "pages.profile.changeProfileForm.personalAccessTokens";
export const TokenNameCell: FC<TProps> = ({ token, tokenName, setNewTokenName, setError }) => {
	const [currentNewTokenName, setCurrentNewTokenName] = useState<string | undefined>(tokenName);
	const { t } = useTranslation();

	const {
		actions: { editToken }
	} = usePersonalAccessTokensContext();

	const handleOnBlur = useCallback(
		(e: React.FocusEvent<HTMLInputElement>) => {
			if (e.relatedTarget && e.relatedTarget.tagName === "BUTTON") return;

			if (!checkIfTokenIsNew(token) && currentNewTokenName !== "" && currentNewTokenName) {
				if (currentNewTokenName.length < 2) {
					setError(
						new ApiError({
							title: t(`${PROFILE_PAGE_TRANSLATION_PREFIX}.errors.tokenTooShort`),
							message: t(`${PROFILE_PAGE_TRANSLATION_PREFIX}.errors.tokenShortContent`)
						})
					);
					setNewTokenName(token.name);
				} else if (currentNewTokenName.length > 50) {
					setError(
						new ApiError({
							title: t(`${PROFILE_PAGE_TRANSLATION_PREFIX}.errors.tokenTooLong`),
							message: t(`${PROFILE_PAGE_TRANSLATION_PREFIX}.errors.tokenLongContent`)
						})
					);
					setNewTokenName(token.name);
				} else {
					void editToken(token.id, currentNewTokenName);
				}
			} else {
				setNewTokenName(currentNewTokenName || "");
			}
		},
		[currentNewTokenName, editToken, setError, setNewTokenName, t, token]
	);

	return (
		<Input
			value={currentNewTokenName}
			onValueChange={setCurrentNewTokenName}
			onBlur={handleOnBlur}
			variant="table"
			placeholder={t("pages.profile.changeProfileForm.name.newName")}
		/>
	);
};
