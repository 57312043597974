import classNames from "classnames";
import React, { useMemo } from "react";
import { LinkButton } from "components/ui/Button";
import { ChevronRightIcon } from "components/ui/Icons/ChevronRightIcon";
import { MoreIcon } from "components/ui/Icons/MoreIcon";
import { TooltipOnOverflow } from "components/ui/TooltipOnOverflow";
import { useBreakpoints } from "hooks/useBreakpoints";
import { Bp } from "utils/ui/breakpoints";
import { useStyles } from "./styles";
import { ExtraOptionsButton, TSelectLinkOption } from "../ExtraOptionsButton";

export type TNavigationPart = {
	title: string;
	url?: string;
};

type TProps = {
	parts: TNavigationPart[];
};

const MAX_PARTS = 4;
const TEXT_MAX_WIDTH = "180px";
const TEXT_MAX_WIDTH_SMALL = "120px";

export const Breadcrumbs: FC<TProps> = ({ className, innerRef, parts }) => {
	const classes = useStyles();
	const { isBiggerThan } = useBreakpoints(Bp.TWENTY);

	const shownParts: (TNavigationPart | TNavigationPart[])[] = useMemo(() => {
		if (parts.length <= MAX_PARTS) {
			return parts;
		}
		const firstPart = parts[0];
		const lastPart = parts[parts.length - 1];
		const middleParts = parts.slice(1, parts.length - 1);
		return [firstPart, middleParts, lastPart];
	}, [parts]);

	const shownPartsComponents = useMemo(() => {
		if (shownParts.length === 0) {
			return null;
		}
		return shownParts.map((part, index) => {
			if (Array.isArray(part)) {
				const extraOptions = new Map<string, TSelectLinkOption>();
				part
					.filter(subPart => !!subPart.url)
					.forEach(part => {
						extraOptions.set(part.title, {
							label: part.title,
							size: "medium",
							to: part.url!,
							value: part.title + (part.url || "")
						});
					});
				return (
					<React.Fragment key={"[COLLAPSE]"}>
						<ExtraOptionsButton
							extraOptions={extraOptions}
							size="medium"
							noBorder
							tooltip={null}
							sort={null}
							icon={<MoreIcon />}
						/>
						<ChevronRightIcon />
					</React.Fragment>
				);
			}
			if (index === shownParts.length - 1) {
				return (
					<TooltipOnOverflow
						textVariant="text_title_sb"
						key="[CURRENT]"
						content={part.title}
						maxInlineWidth={isBiggerThan ? TEXT_MAX_WIDTH : TEXT_MAX_WIDTH_SMALL}
						className={classes.textPadding}
					/>
				);
			}
			if (!part.url) {
				return null;
			}
			return (
				<React.Fragment key={part.title + part.url || ""}>
					<LinkButton
						className={classes.linkButton}
						to={part.url}
						variant="text"
						size="medium"
						key={index}
						overflowTooltip>
						{part.title}
					</LinkButton>
					<ChevronRightIcon />
				</React.Fragment>
			);
		});
	}, [classes.linkButton, classes.textPadding, isBiggerThan, shownParts]);

	if (!shownPartsComponents) return null;
	return (
		<div className={classNames(classes.container, className)} ref={innerRef}>
			{shownPartsComponents}
		</div>
	);
};
