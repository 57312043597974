import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	jsonDetailsContainer: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-start",
		alignItems: "flex-start",
		overflow: "hidden",
		width: "100%",
		height: "100%",
		gap: "var(--spacing-x3, 12px)"
	},
	jsonContainer: {
		backgroundColor: "var(--color-purple-50)",
		borderRadius: "16px",
		display: "flex",
		paddingTop: "var(--spacing-x5, 20px)",
		paddingBottom: "var(--spacing-x5, 20px)",
		paddingLeft: "var(--spacing-x3, 12px)",
		flexDirection: "column",
		overflow: "auto",
		width: "100%",
		height: "100%",
		alignItems: "flex-start",
		gap: "var(--spacing-x4, 16px)"
	}
});
