import React, { useMemo } from "react";
import { UserEntity } from "components/common/entities/UserEntity";
import { Typography } from "components/ui/Typography";
import { useUser } from "hooks/useUser";
import { UserModel } from "models/UserModel";
import { useStyles } from "./styles";
import { useVirtualTableContext } from "../../virtualTableContext";
import type { IRowOptions } from "../../types";

interface IProps {
	user: string | UserModel | null;
	emptyState?: React.ReactNode;
}

const UserCellContent: FC<IProps & IRowOptions> = ({ user, emptyState = null, disabled }) => {
	const classes = useStyles();
	const {
		state: { compact }
	} = useVirtualTableContext();

	const fetchedUser = useUser(typeof user === "string" ? user : "");
	const userModel = useMemo(() => (user instanceof UserModel ? user : fetchedUser), [user, fetchedUser]);

	if (!user) {
		return (
			<div className={classes.userNodeContainer}>
				<Typography className={classes.userText} relative noWrap variant="body_sb">
					{emptyState}
				</Typography>
			</div>
		);
	}
	if (!userModel) return null;

	if (compact) {
		return <UserEntity inactive={disabled} noWrap relative withIcon user={userModel} size="medium" withoutEmail />;
	}
	return <UserEntity inactive={disabled} noWrap relative withIcon user={userModel} />;
};

const UserCellContentMemo = React.memo(UserCellContent) as typeof UserCellContent;

export { UserCellContentMemo as UserCellContent };
