import classNames from "classnames";
import React, { useCallback, useEffect, useRef, useState } from "react";
import useChangedOnce from "hooks/useChangedOnce";
import { useStyles } from "./styles";

interface ICollapseProps {
	open: boolean;
}

export const Collapse: FC<ICollapseProps> = ({ children, className, open }) => {
	const ref = useRef<HTMLDivElement>(null);
	const [height, setHeight] = useState<number | undefined>(open ? undefined : 0);
	const changedOnce = useChangedOnce(open);
	const classes = useStyles();

	const onTransitionEnd = useCallback(() => {
		if (open) {
			setHeight(undefined);
		}
	}, [open]);

	useEffect(() => {
		if (changedOnce) {
			setHeight(ref?.current?.clientHeight);
			if (!open) {
				// react is dumb so this is inevitable
				requestAnimationFrame(() => requestAnimationFrame(() => setHeight(0)));
			}
		}
	}, [changedOnce, open]);

	return (
		<div className={classNames(className, classes.collapse)} style={{ height }} onTransitionEnd={onTransitionEnd}>
			<div ref={ref}>{children}</div>
		</div>
	);
};
