import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	legend: {
		display: "flex",
		flexDirection: "row",
		gap: "12px"
	},
	legendItem: {
		backgroundColor: "var(--color-white)",
		border: "1px solid var(--color-purple-400)",
		borderRadius: "12px",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		gap: "4px",
		minWidth: "75px",
		padding: "8px 12px",
		"& > $legendItemAmount": {
			fontWeight: "var(--typography-font-weight-medium)"
		}
	},
	legendItemAmount: {},
	symbol: {
		width: "20px",
		height: "20px",
		backgroundColor: "var(--color-white)",
		padding: "2px"
	},
	symbolAccount: {
		border: "2px solid var(--color-green-400)",
		borderRadius: "6px",
		color: "var(--color-green-400)"
	},
	symbolIntegrationAndResource: {
		border: "2px solid var(--color-blue-500)",
		borderRadius: "2px",
		color: "var(--color-blue-500)"
	},
	symbolRole: {
		border: "2px solid var(--color-blue-500)",
		borderRadius: "2px 2px 8px 8px",
		color: "var(--color-blue-500)"
	},
	symbolUser: {
		border: "2px solid var(--color-purple-600)",
		borderRadius: "50%",
		color: "var(--color-purple-600)"
	},
	symbolIcon: {
		fontSize: "12px",
		height: "12px",
		width: "12px"
	},
	legendLineItem: {
		extend: "legendItem",
		gap: "12px",
		"& > div": {
			alignItems: "center",
			display: "inline-flex",
			gap: "4px"
		}
	}
});
