import { useCallback, useEffect, useState } from "react";
import { useLoadingState } from "hooks/useLoadingState";
import { Conditions } from "models/RuleModel";
import type { TConditionName } from "filters/condition.interface";
import type { TFilterExpressionProps } from "./filters.types";

export const useMultiSelectFilterExpression = <TFilterName extends TConditionName>(
	{ updateFilter, removeFilter, filterName, filter }: TFilterExpressionProps<TFilterName>,
	fetchOptions: (search?: string) => Promise<NonNullable<Conditions[TFilterName]>["value"]>,
	defaultOperator: NonNullable<Conditions[TFilterName]>["operator"]
) => {
	const onOptionSelect = useCallback(
		(value: string) =>
			updateFilter(filterName, {
				...filter,
				value: filter.value.includes(value) ? filter.value.filter(option => option !== value) : [...filter.value, value]
			}),
		[updateFilter, filter, filterName]
	);

	const onOperatorSelect = useCallback(
		(operator: string) =>
			updateFilter(filterName, {
				...filter,
				operator: operator as TOperator
			}),
		[updateFilter, filter, filterName]
	);

	const [options, setOptions] = useState<NonNullable<Conditions[TFilterName]>["value"]>([]);
	const { withLoader, isLoading } = useLoadingState();

	const getMoreOptions = useCallback(
		async (search?: string) => {
			const options = await withLoader(fetchOptions(search));
			setOptions(options);
		},
		[withLoader, fetchOptions]
	);

	useEffect(() => {
		void getMoreOptions();
	}, [getMoreOptions]);

	const onRemoveFilter = useCallback(() => removeFilter(filterName), [removeFilter, filterName]);

	const onReset = useCallback(
		() =>
			updateFilter(filterName, {
				value: [] as NonNullable<Conditions[TFilterName]>["value"],
				operator: defaultOperator
			} as Conditions[TFilterName]),
		[filterName, updateFilter, defaultOperator]
	);

	return {
		options,
		isLoading,
		onOptionSelect,
		onOperatorSelect,
		onRemoveFilter,
		onReset,
		getMoreOptions
	};
};
