import { useState, useCallback } from "react";

const DEFAULT_INITIAL_MAX = 10;
const DEFAULT_INCREASE = 4;
const DEFAULT_FINAL_MAX = 100;

export const useMaxEntities = (
	initialMax = DEFAULT_INITIAL_MAX,
	finalMax = DEFAULT_FINAL_MAX,
	increase = DEFAULT_INCREASE
) => {
	const [maxEntities, setMaxEntities] = useState<number>(initialMax);

	const increaseMax = useCallback(() => {
		setMaxEntities(current => {
			const newMax = current + increase;
			return newMax > finalMax ? finalMax : newMax;
		});
	}, [increase, finalMax]);

	const decreaseMax = useCallback(() => {
		setMaxEntities(current => {
			const newMax = current - increase;
			return newMax < initialMax ? initialMax : newMax;
		});
	}, [initialMax, increase]);

	return { maxEntities, increaseMax, decreaseMax } as const;
};
