import classNames from "classnames";
import React, { useCallback, useMemo } from "react";
import { DragDropContext, Draggable, DropResult, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { Divider } from "components/ui/Divider";
import { Typography } from "components/ui/Typography";
import { usePoliciesContext } from "context/policiesContext";
import { useStrictDroppable } from "hooks/useStrictDroppable";
import { PolicyModel } from "models/PolicyModel";
import { useStyles } from "./styles";
import { PolicyBar, TPolicyBarProps } from "../PolicyBar/PolicyBar";

type TPoliciesBarListProps = {
	policies: PolicyModel[];
	onDelete: (policyId: string) => void;
	onEdit: (policy: PolicyModel) => void;
};

const DraggablePolicyBar: FC<TPolicyBarProps> = ({ policy, onDelete, onEdit }) => {
	const classes = useStyles();
	const policyId = policy.id;
	const draggableId = useStrictDroppable(policyId);

	const {
		state: { selectedPolicy }
	} = usePoliciesContext();

	const isDragDisabled = !!selectedPolicy && selectedPolicy.id !== policy.id;

	return (
		<Draggable isDragDisabled={isDragDisabled} key={draggableId} draggableId={draggableId} index={policy.sortOrder}>
			{provided => (
				<div
					ref={provided.innerRef}
					{...provided.draggableProps}
					{...provided.dragHandleProps}
					className={classNames(classes.fullWidth, { [classes.draggable]: !isDragDisabled })}>
					<PolicyBar key={policy.id} policy={policy} onDelete={onDelete} onEdit={onEdit} />
				</div>
			)}
		</Draggable>
	);
};

const PolicyListMemo = React.memo(function PolicyList({
	policies,
	disableDrag,
	onDelete,
	onEdit
}: {
	policies: PolicyModel[];
	disableDrag?: boolean;
	onDelete: (policyId: string) => void;
	onEdit: (policy: PolicyModel) => void;
}) {
	if (disableDrag) {
		return policies.map(policy => (
			<PolicyBar key={policy.id || "new"} policy={policy} onDelete={onDelete} onEdit={onEdit} />
		));
	}
	return policies.map(policy => (
		<DraggablePolicyBar onDelete={onDelete} onEdit={onEdit} key={policy.id} policy={policy} />
	));
});

export const PolicyBarList: FC<TPoliciesBarListProps> = ({ policies, onDelete, onEdit }) => {
	const classes = useStyles();
	const { t } = useTranslation("translation", { keyPrefix: "pages.policies" });
	const headers = useMemo(
		() => [
			t("table.headers.priority"),
			t("table.headers.group"),
			t("table.headers.integrationResourceRoles"),
			t("table.headers.bundles")
		],
		[t]
	);

	const {
		actions: { movePolicy }
	} = usePoliciesContext();

	const onDragEnd = useCallback(
		(result: DropResult) => {
			if (!result.destination || result.destination.index === result.source.index) {
				return;
			}

			void movePolicy(policies.find(policy => policy.id === result.draggableId)!.id, result.destination.index);
		},
		[movePolicy, policies]
	);
	const droppableId = useStrictDroppable(`policy-list-${policies[0]?.id || ""}`);

	return (
		<div className={classes.overflowContainer}>
			<div className={classes.listContainer}>
				<div className={classNames(classes.barContainer, classes.headersContainer)}>
					{headers.map((header, index) => (
						<React.Fragment key={header}>
							<Typography variant="text_reg" noWrap>
								{header}
							</Typography>
							{index < headers.length - 1 && <Divider vertical />}
						</React.Fragment>
					))}
				</div>
				<DragDropContext onDragEnd={onDragEnd}>
					<Droppable key={droppableId} type="group" droppableId={droppableId}>
						{provided => (
							<div
								ref={provided.innerRef}
								{...provided.droppableProps}
								className={classNames(classes.listContainer, classes.fullWidth)}>
								<PolicyListMemo policies={policies} onDelete={onDelete} onEdit={onEdit} />
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
			</div>
			{policies.length <= 0 ? (
				<Typography variant="body_reg" className={classes.emptyTableMessage}>
					{t("noPolicies")}
				</Typography>
			) : null}
		</div>
	);
};
