import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	container: {
		display: "flex",
		flexDirection: "column",
		gap: "var(--spacing-x3, 12px)",
		alignItems: "flex-start",
		alignSelf: "stretch"
	}
});
