import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { InfoBar } from "components/common/InfoBar";
import { RequestDetails } from "components/common/RequestDetails";
import { PageTitleContent } from "components/templates/PageTitleContentTemplate";
import { AccountIcon } from "components/ui/Icons/AccountIcon";
import { PermissionsIcon } from "components/ui/Icons/PermissionsIcon";
import { LoadingSpinner } from "components/ui/LoadingSpinner";
import { Tabs } from "components/ui/Tabs";
import { Typography } from "components/ui/Typography";
import { useBreakpoints } from "hooks/useBreakpoints";
import { useIsOpenState } from "hooks/useIsOpenState";
import { useUser } from "hooks/useUser";
import { Bp } from "utils/ui/breakpoints";
import { UserAccountsSection } from "./components/UserAccountsSection";
import { UserPermissionsSection } from "./components/UserPermissionsSection";
import { useStyles } from "./styles";

export const UserPage: FC = ({ className }) => {
	const params = useParams<{ userId: string }>();
	const userId = params.userId;
	const [activeTab, setActiveTab] = useState<"integrationAccounts" | "permissionsTable">("integrationAccounts");
	const user = useUser(userId, true, true);
	const { isBiggerThan: largeTabs } = useBreakpoints(Bp.TWENTY);
	const classes = useStyles();
	const { t } = useTranslation();
	const { isOpen: openDrawer, setIsOpen: handleDrawer } = useIsOpenState(false);

	const breadcrumbs = useMemo(() => {
		return [
			{
				title: t("navigation.users"),
				url: "/users"
			},
			{
				title: user?.fullName || ""
			}
		];
	}, [t, user?.fullName]);

	const tabs = useMemo(
		() =>
			user
				? [
						{
							header: t("pages.user.tabs.integrationAccounts"),
							Icon: largeTabs ? AccountIcon : undefined,
							id: "integrationAccounts" as const,
							content: <UserAccountsSection user={user} />
						},
						{
							header: t("pages.user.tabs.permissionsTable"),
							Icon: largeTabs ? PermissionsIcon : undefined,
							id: "permissionsTable" as const,
							content: <UserPermissionsSection user={user} toggleDrawer={handleDrawer} drawerIsOpen={openDrawer} />
						}
					]
				: [],
		[handleDrawer, largeTabs, openDrawer, t, user]
	);

	return user ? (
		<PageTitleContent className={className}>
			<RequestDetails />
			<PageTitleContent.Header>
				<PageTitleContent.Header.Top breadcrumbs={breadcrumbs} />
				<Typography variant="header_sb">{user.fullName}</Typography>
			</PageTitleContent.Header>
			<PageTitleContent.Body>
				<InfoBar user={user} />
				<Tabs
					onChangeTab={setActiveTab}
					activeTab={activeTab}
					headerContainerClassName={classes.tabsHeaderContainer}
					size={largeTabs ? "large" : "medium"}
					tabs={tabs}
				/>
			</PageTitleContent.Body>
		</PageTitleContent>
	) : (
		<LoadingSpinner />
	);
};
