import classNames from "classnames";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ApprovalAlgorithm } from "components/common/ApprovalAlgorithm";
import { PageTemplate } from "components/templates/PageTemplate";
import { Button } from "components/ui/Button";
import { AddIcon } from "components/ui/Icons/AddIcon";
import { WebhookIcon } from "components/ui/Icons/WebhookIcon";
import { LoadingSpinner } from "components/ui/LoadingSpinner";
import { useApprovalAlgorithmsList } from "hooks/useApprovalAlgorithms";
import { useApprovalFlows } from "hooks/useApprovalFlows";
import { useStyles } from "./styles";

export const WorkflowsPage: FC = ({ className }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const approvalAlgorithms = useApprovalAlgorithmsList();
	const approvalFlows = useApprovalFlows();
	const navigate = useNavigate();
	const goToWebhooksPage = useCallback(() => {
		navigate("./webhooks");
	}, [navigate]);

	const openWorkflowEditor = useCallback(
		(id: string | null) => {
			const url = id ? "/workflows/edit/" + id : "/workflows/create";
			navigate(url);
		},
		[navigate]
	);

	const handleNewWorkflowClick = useCallback(() => openWorkflowEditor(null), [openWorkflowEditor]);

	return (
		<PageTemplate className={classNames(classes.workflowsPage, className)}>
			<PageTemplate.Title className={classes.titleContainer}>
				<span>{t("pages.workflows.title")}</span>
				<div className={classes.titleActions}>
					<Button variant="secondary" size="medium" onClick={goToWebhooksPage} prefix={<WebhookIcon size={20} />}>
						{t("common.approvalAlgorithm.approvalByWebhook")}
					</Button>
					<Button size="medium" onClick={handleNewWorkflowClick} prefix={<AddIcon size={20} />}>
						{t("common.approvalAlgorithm.newWorkflow")}
					</Button>
				</div>
			</PageTemplate.Title>
			<PageTemplate.Content>
				{!!approvalAlgorithms && !!approvalFlows ? (
					<div className={classes.workflowsContainer}>
						{approvalAlgorithms.toArray().map(algorithm => (
							<ApprovalAlgorithm
								key={algorithm.id}
								algorithm={algorithm}
								approvalFlows={approvalFlows}
								onEdit={openWorkflowEditor}
							/>
						))}
					</div>
				) : (
					<LoadingSpinner className={classes.spinner} />
				)}
			</PageTemplate.Content>
		</PageTemplate>
	);
};
