import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getAllowedDurations } from "api/accessRequestForm";
import { useNewTicketFormContext } from "components/pages/NewTicketPage/newTicketContext";
import { Form } from "components/ui/Form";
import { Select } from "components/ui/Select";
import { TextOption } from "components/ui/selectOptions/TextOption";
import { useLoadingState } from "hooks/useLoadingState";
import { ignoreAbortError } from "utils/api/ignoreAbortError";
import type { TTicketDuration } from "utils/durationsOptions";

const sort = (durations: TTicketDuration[]) => (durations ?? [])?.sort((a, b) => a - b);

export const DurationField: FC = () => {
	const { t } = useTranslation();

	const {
		state: {
			formState: { selectedItems, duration: selectedDuration },
			receiverUser
		},
		actions: {
			formActions: { setDuration: setSelectedDuration }
		}
	} = useNewTicketFormContext();

	const [durations, setDurations] = useState<TTicketDuration[]>([]);

	const [loadedAtLeastOnce, setLoadedAtLeastOnce] = useState(false);

	const { isLoading, withLoader } = useLoadingState();

	useEffect(() => {
		if (receiverUser?.id && selectedItems?.size) {
			const targets = selectedItems.map(item => ({ id: item.value.id, type: item.type })).toArray();
			void ignoreAbortError(withLoader(getAllowedDurations({ targets, userId: receiverUser?.id })), durations => {
				setDurations(durations);
				setLoadedAtLeastOnce(true);
			});
		} else {
			setDurations([]);
		}
	}, [selectedItems, receiverUser?.id, withLoader]);

	// if the selected duration is not in the list of durations, reset it
	// we wait for loaded loadedAtLeastOnce so we wont empty durations that we get from url
	useEffect(() => {
		if (loadedAtLeastOnce && selectedDuration && !durations.includes(selectedDuration)) {
			setSelectedDuration(null);
		}
	}, [selectedDuration, setSelectedDuration, durations, loadedAtLeastOnce]);

	useEffect(() => {
		if (durations?.length === 1) {
			setSelectedDuration(durations[0]);
		}
	}, [setSelectedDuration, durations]);

	const getLabel = useCallback((option: TTicketDuration) => t(`common.durations.${option}`), [t]);

	return (
		<Form.Field>
			<Select
				disabled={durations.length <= 1 || !loadedAtLeastOnce}
				getOptionLabel={getLabel}
				label={t("pages.newTicket.newTicketForm.durationInput.label")}
				loading={isLoading}
				onChange={setSelectedDuration}
				renderOption={TextOption}
				options={durations}
				placeholder={t("pages.newTicket.newTicketForm.durationInput.placeholder")}
				required
				sort={sort}
				value={selectedDuration || null}
			/>
		</Form.Field>
	);
};
