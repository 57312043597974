import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	select: {
		display: "flex",
		flexDirection: "column",
		padding: "0.86rem",
		width: "100%"
	},
	selectOptions: {
		display: "flex",
		flexDirection: "column",
		overflowY: "auto",
		width: "100%",
		"& > *": {
			display: "flex",
			flexDirection: "row",
			willChange: "background-color",
			cursor: "pointer",
			"&:hover": {
				backgroundColor: "var(--color-grey-background)"
			}
		},
		"& > *:not(:last-child)": {
			borderBottom: "1px solid var(--color-grey-background)"
		}
	},
	tooltipContainer: {
		backgroundColor: "var(--tooltip-background-color)",
		borderRadius: "var(--border-radius-sharp)",
		border: "var(--tooltip-border)",
		boxShadow: "var(--tooltip-box-shadow)",
		boxSizing: "border-box",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		zIndex: 1
	},
	filter: {
		marginBottom: ".86rem",
		borderRadius: "4px",
		width: "calc(100%)"
	}
});
