import React, { useCallback, useRef, useState } from "react";
import { useSessionAuditLogsContext } from "components/pages/AuditLogsPage/sessionAuditLogContext";
import { FilterExpression } from "components/ui/filters/FilterExpression";
import { useOnMount } from "hooks/useOnMount";
import { TFilterOperator } from "types/filters";

type TSingleOption = { value: string; label: string };

interface ISingleChoice {
	initialOperator?: TFilterOperator;
	initialValue?: string;
	singleChoiceOptions?: TSingleOption[];
	title: string;
	filterName: string;
	renderOption?: (value: string) => React.JSX.Element;
	onReset?: () => void;
	onFilterRemove: () => void;
}

const OPERATOR_SUFFIX = "Operator";

export const IntegrationLogsSingleSelectFilterExpression: FC<ISingleChoice> = ({
	initialOperator,
	initialValue,
	singleChoiceOptions,
	title,
	filterName,
	renderOption: propsRenderOption,
	onReset: propsOnReset,
	onFilterRemove
}) => {
	const ref = useRef<HTMLDivElement>(null);
	const [selectedSingleValue, setSelectedSingleValue] = useState(initialValue || null);
	const [selectedOperator, setSelectedOperator] = useState<TFilterOperator>(initialOperator || "is");

	const {
		state: { filters },
		actions: { setSearchFilters, setTotalFilters }
	} = useSessionAuditLogsContext();

	const toURLSearchParams = useCallback(
		(value: string, operator?: TFilterOperator) => {
			const prevParams = filters;
			prevParams.delete(filterName);
			prevParams.delete(`${filterName}${OPERATOR_SUFFIX}`);
			setTotalFilters(curr => curr.filter(filter => filter.field !== filterName));
			const params = new URLSearchParams(prevParams);
			if (!value) {
				setSearchFilters(params);
				return;
			}
			const wantedValue = singleChoiceOptions?.find(
				(option: { value: string; label: string }) => option.label === value
			)?.value;
			if (wantedValue) {
				params.append(filterName, wantedValue);
				setTotalFilters(curr => [
					...curr,
					{ field: filterName, operator: operator ?? selectedOperator, value: wantedValue }
				]);
			}

			if (params.get(filterName)) {
				params.append(`${filterName}${OPERATOR_SUFFIX}`, operator ?? selectedOperator);
				setSearchFilters(params);
			}
		},
		[filterName, filters, selectedOperator, setSearchFilters, setTotalFilters, singleChoiceOptions]
	);

	const removeFromURLSearchParams = useCallback(
		(value?: string) => {
			const params = new URLSearchParams(filters);
			if (value) {
				params.delete(filterName, value);
				if (!params.get(filterName)) {
					params.delete(filterName);
					params.delete(`${filterName}${OPERATOR_SUFFIX}`);
				}
			} else {
				params.delete(filterName);
				params.delete(`${filterName}${OPERATOR_SUFFIX}`);
			}
			setTotalFilters(curr => {
				return curr.filter(filter => filter.value !== value);
			});
			setSearchFilters(params);
		},
		[filterName, filters, setSearchFilters, setTotalFilters]
	);

	const handleOptionSelection = useCallback(
		(value: string) => {
			if (selectedSingleValue === value) {
				removeFromURLSearchParams(value);
				setSelectedSingleValue(initialValue || "");
				toURLSearchParams(initialValue || "");
			} else {
				setSelectedSingleValue(value);
				toURLSearchParams(value);
			}
		},
		[initialValue, removeFromURLSearchParams, selectedSingleValue, toURLSearchParams]
	);

	const handleFilterRemove = useCallback(() => {
		onFilterRemove();
		if (filters.size !== 0) {
			removeFromURLSearchParams();
		}

		setTotalFilters(curr => curr.filter(filter => filter.field !== filterName));
	}, [filterName, filters.size, onFilterRemove, removeFromURLSearchParams, setTotalFilters]);

	const handleFilterReset = useCallback(() => {
		setSelectedSingleValue("");
		removeFromURLSearchParams();
		setTotalFilters(curr => curr.filter(filter => filter.field !== filterName));
	}, [filterName, removeFromURLSearchParams, setTotalFilters]);

	const renderOption = useCallback((value: string) => {
		return <>{value}</>;
	}, []);

	useOnMount(() => {
		ref.current?.scrollIntoView({ behavior: "smooth", block: "start" });
	});

	return (
		<FilterExpression
			onOptionSelect={handleOptionSelection}
			options={singleChoiceOptions!.map(option => option.label)}
			renderOption={propsRenderOption || renderOption}
			selected={selectedSingleValue}
			innerRef={ref}
			onReset={propsOnReset || handleFilterReset}
			onRemoveFilter={handleFilterRemove}
			title={title}
			type="singleChoice"
		/>
	);
};
