import { Map } from "immutable";
import { useCallback, useEffect, useState } from "react";
import { deleteNotificationIntegration, getNotificationIntegrations } from "api/notificationIntegrations";
import { NotificationIntegrationModel } from "models/NotificationIntegrationModel";
import { toGroupMapBy } from "utils/toMapBy";

export const useNotificationIntegrations = () => {
	const [integrations, setIntegrations] = useState<Map<string, NotificationIntegrationModel[]>>(Map());

	const loadNotificationIntegrations = useCallback(async () => {
		const notificationIntegrations = await getNotificationIntegrations();
		const notificationIntegrationsByType = toGroupMapBy(notificationIntegrations, ({ type }) => type);
		setIntegrations(notificationIntegrationsByType);
	}, []);

	const removeNotificationIntegration = useCallback(
		async (notificationIntegrationId: string) => {
			const deletedIntegration = await deleteNotificationIntegration(notificationIntegrationId);
			const remainingIntegrations =
				integrations.get(deletedIntegration.type)?.filter(integration => integration.id !== deletedIntegration.id) ||
				[];
			setIntegrations(current => current.set(deletedIntegration.type, remainingIntegrations));
		},
		[integrations]
	);

	useEffect(() => {
		void loadNotificationIntegrations();
	}, [loadNotificationIntegrations]);

	return {
		state: {
			slackIntegrations: integrations.get("slack") || [],
			teamsIntegrations: integrations.get("teams") || []
		},
		actions: { removeNotificationIntegration }
	};
};
