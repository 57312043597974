import { List } from "immutable";
import partition from "lodash/partition";
import { BundleModel } from "models/BundleModel";
import { IntegrationModel } from "models/IntegrationModel";
import { IntegrationResourceModel } from "models/IntegrationResourceModel";
import { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";
import { TicketModel } from "models/TicketModel";
import { apiReq } from "utils/api/apiReq";
import { apiReqAbortOld } from "utils/api/apiReqAbortOld";
import { devLog } from "utils/devtools/devLogging";
import { DURATION_OPTIONS, type TTicketDuration } from "utils/durationsOptions";
import { IPaginationResponse, withPagination } from "utils/pagination";
import type { TNewTicketOption } from "components/pages/NewTicketPage/components/NewTicketForm/types";
import type { Require } from "types/utilTypes";
import type { ITargetData } from "./tickets";

export async function getIntegrations(options: {
	userId: string;
	search?: string;
	perPage?: number;
	page?: number;
}): Promise<IPaginationResponse<IntegrationModel>> {
	const { userId, search, perPage = 50, page = 1 } = options;

	const urlParams = new URLSearchParams({
		userId,
		perPage: perPage.toString(),
		page: page.toString()
	});
	if (search) {
		urlParams.append("search", search);
	}

	return withPagination(
		(integration: unknown) => IntegrationModel.fromServerData(integration),
		() =>
			apiReqAbortOld({
				key: "accessRequestForm.getIntegrations",
				method: "GET",
				path: `/v1/accessRequestForm/integrations?${urlParams}`
			})
	);
}

export async function getIntegration(id: string, userId: string): Promise<IntegrationModel> {
	const urlParams = new URLSearchParams({ userId });
	const { data } = await apiReqAbortOld({
		key: "accessRequestForm.getIntegration",
		method: "GET",
		path: `/v1/accessRequestForm/integrations/${id}?${urlParams}`
	});
	return IntegrationModel.fromServerData(data);
}

export async function getTicket(options: { id: string }): Promise<TicketModel> {
	const { id } = options;
	const { data } = await apiReq("GET", `/v1/tickets/${id}`);
	return TicketModel.fromServerData(data);
}

export async function getIntegrationResourceTypes(options: {
	integrationIds: string[];
	userId: string;
	search?: string;
	perPage?: number;
	page?: number;
}): Promise<IPaginationResponse<string>> {
	const { userId, integrationIds, search, perPage = 50, page = 1 } = options;

	const urlParams = new URLSearchParams({
		userId,
		perPage: perPage.toString(),
		page: page.toString(),
		integrationIds: integrationIds.join(",")
	});
	if (search) {
		urlParams.append("search", search);
	}

	return withPagination(
		(type: unknown) => String(type),
		() =>
			apiReqAbortOld({
				key: "accessRequestForm.getResourceTypes",
				method: "GET",
				path: `/v1/accessRequestForm/resourceTypes?${urlParams}`
			})
	);
}

export async function getIntegrationsResources(options: {
	userId: string;
	integrationIds: string[];
	search?: string;
	resourceTypes?: string[];
	perPage?: number;
	page?: number;
}): Promise<IPaginationResponse<IntegrationResourceModel>> {
	const { userId, integrationIds, search, resourceTypes, perPage = 50, page = 1 } = options;

	const urlParams = new URLSearchParams({
		userId,
		perPage: perPage.toString(),
		page: page.toString(),
		integrationIds: integrationIds.join(",")
	});

	if (resourceTypes?.length) {
		urlParams.append("resourceTypes", resourceTypes.join(","));
	}
	if (search) {
		urlParams.append("search", search);
	}

	return withPagination(
		(resource: unknown) => IntegrationResourceModel.fromServerData(resource),
		() =>
			apiReqAbortOld({
				key: "accessRequestForm.getIntegrationsResources",
				method: "GET",
				path: `/v1/accessRequestForm/resources?${urlParams}`
			})
	);
}

export async function getIntegrationResource(id: string, userId: string): Promise<IntegrationResourceModel> {
	const urlParams = new URLSearchParams({ userId });
	const { data } = await apiReqAbortOld({
		key: "accessRequestForm.getIntegrationResource",
		method: "GET",
		path: `/v1/accessRequestForm/resources/${id}?${urlParams}`
	});
	return IntegrationResourceModel.fromServerData(data);
}

export async function getIntegrationResourcesRoles(options: {
	integrationResourceIds: string[];
	userId: string;
	search?: string;
	perPage?: number;
	page?: number;
}): Promise<IPaginationResponse<Require<IntegrationResourceRoleModel, "integrationResource">>> {
	const { userId, search, integrationResourceIds, perPage = 50, page = 1 } = options;

	const urlParams = new URLSearchParams({
		userId,
		perPage: perPage.toString(),
		page: page.toString(),
		resourceIds: integrationResourceIds.join(",")
	});
	if (search) {
		urlParams.append("search", search);
	}

	return withPagination(
		(role: unknown) =>
			IntegrationResourceRoleModel.fromServerData(role) as Require<IntegrationResourceRoleModel, "integrationResource">,
		() =>
			apiReqAbortOld({
				key: "accessRequestForm.getIntegrationResourcesRoles",
				method: "GET",
				path: `/v1/accessRequestForm/roles?${urlParams}`
			})
	);
}

export async function getIntegrationResourceRole(id: string, userId: string): Promise<IntegrationResourceRoleModel> {
	const urlParams = new URLSearchParams({ userId });
	const { data } = await apiReq("GET", `/v1/accessRequestForm/roles/${id}?${urlParams}`);
	return IntegrationResourceRoleModel.fromServerData(data);
}

export async function getBundles(options: {
	userId: string;
	bundleCategories?: string[];
	search?: string;
	perPage?: number;
	page?: number;
}): Promise<IPaginationResponse<Require<BundleModel, "bundleItems">>> {
	const { userId, bundleCategories, search, perPage = 50, page = 1 } = options;

	const urlParams = new URLSearchParams({ userId, perPage: perPage.toString(), page: page.toString() });
	if (bundleCategories?.length) {
		urlParams.append("bundleCategories", bundleCategories.join(","));
	}
	if (search) {
		urlParams.append("search", search);
	}

	return withPagination(
		(bundle: unknown) => BundleModel.fromServerData(bundle) as Require<BundleModel, "bundleItems">,
		() =>
			apiReqAbortOld({
				key: "accessRequestForm.getBundles",
				method: "GET",
				path: `/v1/accessRequestForm/bundles?${urlParams}`
			})
	);
}

export async function getBundle(id: string, userId: string): Promise<BundleModel> {
	const urlParams = new URLSearchParams({ userId });

	const { data } = await apiReq("GET", `/v1/accessRequestForm/bundles/${id}?${urlParams}`);
	return BundleModel.fromServerData(data);
}

export async function getBundleCategories(options: {
	userId: string;
	search?: string;
	perPage?: number;
	page?: number;
}): Promise<IPaginationResponse<string>> {
	const { userId, search, perPage = 50, page = 1 } = options;

	const urlParams = new URLSearchParams({ userId, perPage: perPage.toString(), page: page.toString() });
	if (search) {
		urlParams.append("search", search);
	}

	return withPagination(
		(category: unknown) => String(category),
		() =>
			apiReqAbortOld({
				key: "accessRequestForm.getBundleCategories",
				method: "GET",
				path: `/v1/accessRequestForm/bundleCategories?${urlParams}`
			})
	);
}

const transformNewTicketOption = (result: unknown): TNewTicketOption | null => {
	const asNewTicketOption = result as TNewTicketOption;
	if (
		!asNewTicketOption ||
		typeof asNewTicketOption !== "object" ||
		!["bundle", "role"].includes(asNewTicketOption.type)
	) {
		return null;
	}

	if (asNewTicketOption.type === "bundle") {
		return Object.assign({}, asNewTicketOption, { value: BundleModel.fromServerData(asNewTicketOption.value) });
	}

	return Object.assign({}, asNewTicketOption, {
		value: IntegrationResourceRoleModel.fromServerData(asNewTicketOption.value)
	});
};

export async function search(options: {
	search: string;
	perPage?: number;
	page?: number;
	userId: string;
}): Promise<IPaginationResponse<TNewTicketOption>> {
	const { search, userId, perPage = 50, page = 1 } = options;
	const urlParams = new URLSearchParams({ userId, search, perPage: perPage.toString(), page: page.toString() });

	return withPagination(transformNewTicketOption, () =>
		apiReqAbortOld({
			key: "accessRequestForm.search",
			method: "GET",
			path: `/v1/accessRequestForm/search?${urlParams}`
		})
	);
}

const isTicketDuration = (result: unknown): result is TTicketDuration => {
	return typeof result === "number" && DURATION_OPTIONS.includes(result as TTicketDuration);
};

export async function getAllowedDurations(options: {
	targets: ITargetData[];
	userId: string;
}): Promise<TTicketDuration[]> {
	const { targets, userId } = options;

	const [roles, bundles] = partition(targets, target => target.type === "role");

	const urlParams = new URLSearchParams({ userId });
	const roleIdsQuery = roles.length ? `&${roles.map(({ id }) => `roleIds=${id}`).join("&")}` : "";
	const bundleIdsQuery = bundles.length ? `&${bundles.map(({ id }) => `bundleIds=${id}`).join("&")}` : "";

	const { data } = await apiReqAbortOld({
		key: "accessRequestForm.getAllowedDurations",
		method: "GET",
		path: `/v1/accessRequestForm/allowedDurations?${urlParams}${roleIdsQuery}${bundleIdsQuery}`
	});

	if (!data) {
		return [];
	}
	if (Array.isArray(data) && data.every(isTicketDuration)) {
		return data;
	}
	devLog({ message: "Invalid response from the server", extra: { data }, level: "error" });
	return [];
}

export async function getPrerequisitePermissions(
	receiverId: string,
	targets: { type: "role" | "bundle"; id: string }[]
): Promise<List<IntegrationResourceRoleModel>> {
	const [bundleTargets, roleTargets] = partition(targets, target => target.type === "bundle");
	const urlQuery = new URLSearchParams({ receiverId });
	bundleTargets.forEach(({ id }) => urlQuery.append("bundleIds", id));
	roleTargets.forEach(({ id }) => urlQuery.append("roleIds", id));
	const { data } = await apiReqAbortOld({
		key: "accessRequestForm.getPrerequisitePermissions",
		method: "GET",
		path: `/v1/accessRequestForm/prerequisites?${urlQuery.toString()}`
	});

	return List(data.map(IntegrationResourceRoleModel.fromServerData));
}
