import { createUseStyles } from "react-jss";
import { entitySharedStyles } from "../styles";

export const useStyles = createUseStyles({
	tooltipContainer: {
		display: "flex",
		flexDirection: "column",
		gap: "var(--spacing-x5, 20px)",
		padding: "var(--spacing-x3, 12px)"
	},
	unmanagedTooltip: {
		minWidth: "400px"
	},
	...entitySharedStyles
});
