import { Record } from "immutable";
import { BundleModel } from "./BundleModel";
import { IntegrationResourceRoleModel } from "./IntegrationResourceRoleModel";

type TTicketTargetType = "bundle" | "role";

const defaults = {
	id: "",
	targetId: "",
	type: "" as TTicketTargetType,
	integrationResourceRole: null as IntegrationResourceRoleModel | null,
	bundle: null as BundleModel | null
};

export class TicketTargetModel extends Record<typeof defaults>(defaults) {
	static fromServerData(data: unknown): TicketTargetModel {
		const { id, targetId, type, integrationResourceRole, bundle } = data as typeof defaults;
		return new TicketTargetModel({
			id,
			targetId,
			type,
			integrationResourceRole: integrationResourceRole
				? IntegrationResourceRoleModel.fromServerData(integrationResourceRole)
				: null,
			bundle: bundle ? BundleModel.fromServerData(bundle) : null
		});
	}
}
