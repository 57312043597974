import React from "react";
import { useTranslation } from "react-i18next";
import { ChipContainer } from "components/ui/ChipContainer";
import { AllowRequestsChip } from "components/ui/chips/AllowRequestsChip";
import { Chip } from "components/ui/chips/Chip";
import { GroupChip } from "components/ui/chips/GroupChip";
import { UserChip } from "components/ui/chips/UserChip";
import { InheritWorkflowChip, WorkflowChip } from "components/ui/chips/WorkflowChip/WorkflowChip";
import { NoAccessCircleIcon } from "components/ui/Icons/NoAccessCircleIcon";
import { UserCircleIcon } from "components/ui/Icons/UserCircleIcon";
import { Tooltip } from "components/ui/Tooltip";
import { useApprovalAlgorithms } from "hooks/useApprovalAlgorithms";
import { useDirectoryGroup } from "hooks/useDirectoryGroup";
import { useUser } from "hooks/useUser";
import { notEmpty } from "utils/comparison";
import { useStyles } from "./styles";
import type { TRoleOrResourceUpdate, TRuleType, TUpdateMaintainer } from "models/RuleModel";

const DeletedAttributeChip: FC<{ text: React.ReactNode }> = ({ text }) => {
	const { t } = useTranslation();
	const classes = useStyles();

	return (
		<Tooltip className={classes.disabledRuleTooltip} content={t("pages.rules.ruleBar.updates.disabledRuleMessage")}>
			<Chip noTooltip variant="red" inactive size="medium" PrefixIcon={NoAccessCircleIcon}>
				{text}
			</Chip>
		</Tooltip>
	);
};

const OwnerChip = ({ value }: { value: TRoleOrResourceUpdate["ownerUserId"] }) => {
	const { t } = useTranslation();
	const currentUser = useUser(value);

	if (value === null) {
		return (
			<Tooltip content={t("shared.integrationDefault")} placement="top">
				<Chip noTooltip variant="regular" size="medium" PrefixIcon={UserCircleIcon}>
					{t("shared.integrationDefault")}
				</Chip>
			</Tooltip>
		);
	}

	if (currentUser?.isDeleted) {
		return <DeletedAttributeChip text={currentUser.fullName} />;
	}
	return <UserChip variant="regular" size="medium" value={value} />;
};

const MaintainerChip = ({ value }: { value: TUpdateMaintainer }) => {
	const isUser = "userId" in value;
	const maintainerUser = useUser(isUser ? value.userId : undefined, true);
	const maintainerGroup = useDirectoryGroup(isUser ? undefined : value.directoryGroupId);

	if (isUser && maintainerUser) {
		return maintainerUser.isDeleted ? (
			<DeletedAttributeChip text={maintainerUser.fullName} />
		) : (
			<UserChip variant="regular" size="medium" value={maintainerUser} />
		);
	} else if (maintainerGroup) {
		return maintainerGroup.isDeleted ? (
			<DeletedAttributeChip text={maintainerGroup.name} />
		) : (
			<GroupChip variant="regular" size="medium" value={maintainerGroup} />
		);
	}
	return null;
};

const WorkflowAttributeChip: FC<{
	value: TRoleOrResourceUpdate["approvalAlgorithmId"] | null;
	ruleType: TRuleType;
}> = ({ value, ruleType }) => {
	const approvalAlgorithms = useApprovalAlgorithms();

	if (value === null) {
		return (
			<InheritWorkflowChip
				size="medium"
				variant="regular"
				from={ruleType === "resources" ? "integration" : "resource"}
			/>
		);
	}
	const approvalAlgorithm = approvalAlgorithms?.get(value);
	return approvalAlgorithm?.isDeleted ? (
		<DeletedAttributeChip text={approvalAlgorithm.name} />
	) : (
		<WorkflowChip value={value} variant="regular" size="medium" />
	);
};

export function AttributeChip<TAttribute extends keyof TRoleOrResourceUpdate>({
	attribute,
	value,
	ruleType
}: {
	attribute: TAttribute;
	value: TRoleOrResourceUpdate[TAttribute];
	ruleType: TRuleType;
}) {
	const classes = useStyles();
	if (attribute === "approvalAlgorithmId") {
		return (
			<WorkflowAttributeChip
				key={value as TRoleOrResourceUpdate["approvalAlgorithmId"]}
				value={value as TRoleOrResourceUpdate["approvalAlgorithmId"]}
				ruleType={ruleType}
			/> // for some reason TS does not infer this type by himself
		);
	}
	if (attribute === "allowsRequests") {
		return (
			<AllowRequestsChip
				key={value?.toString()}
				variant="regular"
				size="medium"
				value={value as TRoleOrResourceUpdate["allowsRequests"]}
			/>
		);
	}
	if (attribute === "ownerUserId") {
		return (
			<OwnerChip
				key={value as TRoleOrResourceUpdate["ownerUserId"]}
				value={value as TRoleOrResourceUpdate["ownerUserId"]}
			/>
		);
	}
	if (attribute === "maintainers") {
		const maintainers = (value as TRoleOrResourceUpdate["maintainers"]) || [];
		const maintainerChips = maintainers
			.map(maintainer => (
				<MaintainerChip
					value={maintainer}
					key={"userId" in maintainer ? maintainer.userId : maintainer.directoryGroupId}
				/>
			))
			.filter(notEmpty);
		return <ChipContainer className={classes.chipContainer} chips={maintainerChips} variant="regular" size="medium" />;
	}
	return null;
}
