import classNames from "classnames";
import React from "react";
import { Button, TBaseButtonProps } from "components/ui/Button";
import { useStyles } from "./styles";
import { useVirtualTableContext } from "../../virtualTableContext";
import type { IRowOptions } from "../../types";

const ButtonCellContent: FC<Omit<TBaseButtonProps, "size" | "component"> & IRowOptions> = ({
	className,
	disabled,
	...props
}) => {
	const classes = useStyles();
	const {
		state: { compact }
	} = useVirtualTableContext();

	return (
		<div className={classNames(className, classes.centeredCellContent)}>
			<Button {...props} onClick={disabled ? undefined : props.onClick} size={compact ? "small" : "medium"} />
		</div>
	);
};

const ButtonCellContentMemo = React.memo(ButtonCellContent) as typeof ButtonCellContent;

export { ButtonCellContentMemo as ButtonCellContent };
