import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Token } from "components/common/Token";
import { Button } from "components/ui/Button";
import { AddIcon } from "components/ui/Icons/AddIcon";
import { Typography } from "components/ui/legacy/Typography";
import { LoadingSpinner } from "components/ui/LoadingSpinner";
import { Table } from "components/ui/Table";
import { Title } from "components/ui/Title";
import { useAgentTokensContext } from "context/agentTokensContext";
import { useAgentTokens } from "hooks/useAgentTokens";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { AgentTokenModel } from "models/AgentTokenModel";
import { sortByCreatedAt } from "utils/tickets/ticketActivity";
import { useStyles } from "./styles";
import type { IAreYouSureModalProps } from "../..";

interface IProps {
	openAreYouSureModal: (props: IAreYouSureModalProps) => void;
}

export const AgentTokensList: FC<IProps> = ({ openAreYouSureModal }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const {
		actions: { createAgentToken, updateAgentToken, deleteAgentToken }
	} = useAgentTokensContext();

	const handleError = useOpenGlobalErrorModal();
	const [newToken, setNewToken] = useState<AgentTokenModel | null>(null);
	const [editAgentTokenId, setEditAgentTokenId] = useState<string | null>(null);

	const agentTokens = useAgentTokens();

	const handleCancel = useCallback(() => {
		setNewToken(null);
		setEditAgentTokenId(null);
	}, []);

	const createToken = useCallback(() => {
		handleCancel();
		setNewToken(new AgentTokenModel({ name: "" }));
	}, [handleCancel]);

	const editToken = useCallback(
		(tokenId: string) => {
			handleCancel();
			setEditAgentTokenId(tokenId);
		},
		[handleCancel]
	);

	const isNameTaken = useCallback(
		(name: string) => Boolean(agentTokens?.some(token => token.name === name)),
		[agentTokens]
	);

	const deleteHandler = useCallback(
		(id: string) => {
			const onAction = async () => {
				try {
					await deleteAgentToken(id);
				} catch (error) {
					handleError(error as Error);
				}
			};

			openAreYouSureModal({
				onAction,
				title: t("pages.settings.agentTokens.areYouSure.title"),
				content: t("pages.settings.agentTokens.areYouSure.content")
			});
		},
		[deleteAgentToken, handleError, openAreYouSureModal, t]
	);

	const tokens = useMemo(() => agentTokens?.sort((a, b) => sortByCreatedAt(a, b, "desc")).toList(), [agentTokens]);

	useEffect(() => {
		handleCancel();
	}, [handleCancel, agentTokens]);

	return (
		<>
			<Title variant="h3" noBorder className={classes.title} extraMargin>
				<span>{t("pages.settings.agentTokens.title")}</span>
				<Button onClick={createToken} variant="secondary" size="medium" prefix={<AddIcon />}>
					{t("buttons.add")}
				</Button>
			</Title>
			<div>
				{!!agentTokens || <LoadingSpinner />}

				{agentTokens?.isEmpty() && !newToken ? (
					<Typography className={classes.noForwards} variant="h2">
						{t("pages.settings.tokens.noTokens")}
					</Typography>
				) : (
					<Table gridColumns="1fr 1fr 15rem">
						<Table.Row>
							<Table.Header>
								<Typography variant="small">{t("pages.settings.tokens.name")}</Typography>
							</Table.Header>
							<Table.Header>
								<Typography variant="small">{t("pages.settings.tokens.tokenTitle")}</Typography>
							</Table.Header>
						</Table.Row>
						{newToken && (
							<Token
								token={newToken}
								create
								onCancel={handleCancel}
								afterSave={handleCancel}
								isNameTaken={isNameTaken}
								onCreate={createAgentToken}
								withExpiration={false}
							/>
						)}
						{tokens?.map(agentToken => (
							<Token
								key={agentToken.id}
								token={agentToken}
								onCancel={handleCancel}
								afterSave={handleCancel}
								onDelete={deleteHandler}
								edit={editAgentTokenId === agentToken.id}
								enterEdit={editToken}
								isNameTaken={isNameTaken}
								onEdit={updateAgentToken}
								withExpiration={false}
							/>
						))}
					</Table>
				)}
			</div>
		</>
	);
};
