import classNames from "classnames";
import React, { ReactNode, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FloatingSelect } from "components/common/FloatingSelect";
import { Button, LinkButton } from "components/ui/Button";
import { IconButton } from "components/ui/IconButton";
import { MenuIcon } from "components/ui/Icons/MenuIcon";
import { Tooltip } from "components/ui/Tooltip";
import { useStyles } from "./styles";
import type { Placement } from "@popperjs/core";

export type TRequestAction =
	| "redirect"
	| "reopen"
	| "disconnect"
	| "hr"
	| "addConnection"
	| "editConnection"
	| "adminApprove"
	| "adminDeny"
	| "adminDenied";

export type TExtraOptions<T> = Map<TRequestAction, TSelectClickableOption<T>>;

interface IOption {
	label: string;
	value: string;
}

type TBaseProps = {
	className?: string;
	position?: Placement;
	disabled?: boolean;
	icon?: React.JSX.Element;
	noBorder?: boolean;
	size?: "small" | "medium" | "large";
	optionClassName?: string;
	sort?: ((options: IOption[]) => IOption[]) | null;
	tooltip?: ReactNode;
};

type TOnlyLinkProps = TBaseProps & {
	extraOptions: Map<string, TSelectLinkOption>;
};
type TProps<T> = TBaseProps & {
	extraOptions: Map<string, TSelectClickableOption<T> | TSelectLinkOption>;
	item: T;
};

type TBaseOption = {
	value: string;
	label: string;
	Icon?: React.JSX.Element;
	size?: "small" | "medium" | "large";
};

export type TSelectClickableOption<T> = TBaseOption & {
	onClick: (item: T) => void;
};

export type TSelectLinkOption = TBaseOption & {
	to: string;
	target?: "_blank" | "_self" | "_parent" | "_top";
};

export function ExtraOptionsButton<T>(props: TOnlyLinkProps | TProps<T>) {
	const { noBorder = false, className, disabled = false, size = "small", sort, tooltip, icon = <MenuIcon /> } = props;
	const [selectOpen, setSelectOpen] = useState(false);
	const classes = useStyles();
	const { t } = useTranslation();

	const toggleSelectOpen = useCallback(
		(e: React.MouseEvent) => {
			e.stopPropagation();
			setSelectOpen(!selectOpen);
		},
		[selectOpen]
	);

	const closeSelect = useCallback(() => setSelectOpen(false), []);
	const selectOptions = useMemo(() => Array.from(props.extraOptions.values()), [props.extraOptions]);

	const onClickHandler = useCallback(
		(action: string) => {
			if ("item" in props) {
				const { item, extraOptions } = props;
				if (extraOptions.has(action)) {
					const onClick = (extraOptions.get(action) as TSelectClickableOption<T>).onClick;
					if (onClick) onClick(item);
					closeSelect();
				}
			}
		},
		[closeSelect, props]
	);

	const renderOption: (
		option: {
			label: string;
			value: string;
			to?: string;
			target?: string;
			size?: "small" | "medium" | "large";
		},
		props: {
			key: string;
			onClick?: (event: React.MouseEvent) => void;
		}
	) => React.JSX.Element = useCallback(
		({ value, label, to, target, size: optionSize }, { onClick, key }) => {
			const extraOptions = props.extraOptions;
			const Icon = extraOptions.has(value) && extraOptions.get(value)!.Icon ? extraOptions.get(value)?.Icon : undefined;
			if (to)
				return (
					<LinkButton
						key={key}
						to={to}
						target={target}
						variant="text"
						size={optionSize || size}
						className={classNames(classes.selectOption, props.optionClassName)}
						prefix={Icon}
						onClick={closeSelect}>
						{label}
					</LinkButton>
				);
			return (
				<Button
					key={key}
					variant="text"
					size={optionSize || size}
					className={classNames(classes.selectOption, props.optionClassName)}
					prefix={Icon}
					onClick={onClick}>
					{label}
				</Button>
			);
		},
		[classes.selectOption, closeSelect, props.extraOptions, props.optionClassName, size]
	);

	const tooltipText = !selectOpen
		? typeof tooltip === "undefined"
			? t("common.extraOptions.moreActionsButtonTooltip")
			: tooltip
		: "";

	const iconButton = (
		<IconButton
			variant={noBorder ? "primary" : "secondary"}
			size={size}
			onClick={toggleSelectOpen}
			disabled={disabled}
			className={className}>
			{icon}
		</IconButton>
	);

	return (
		<FloatingSelect
			onClose={closeSelect}
			onSelect={onClickHandler}
			open={selectOpen}
			options={selectOptions}
			position={props.position || "bottom-start"}
			renderOption={renderOption}
			sort={sort}>
			<span className={classes.innerTooltipContainer}>
				{tooltipText ? <Tooltip content={tooltipText}>{iconButton}</Tooltip> : iconButton}
			</span>
		</FloatingSelect>
	);
}
