import React from "react";
import { RoleBarTag } from "components/common/RoleBar/components/RoleBarTag";
import { TooltipOnOverflow } from "components/ui/TooltipOnOverflow";
import { BundleModel } from "models/BundleModel";

type TProps = {
	bundle: BundleModel;
};

export const BundleNameCell: FC<TProps> = ({ bundle }) => {
	return (
		<>
			<TooltipOnOverflow content={bundle.name} />
			{bundle.tags ? <RoleBarTag tags={bundle.tags} /> : null}
		</>
	);
};
