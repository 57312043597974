import React from "react";

export const useChildLink = (onClick?: (e?: React.MouseEvent<HTMLElement>) => void) => {
	const ref = React.useRef<HTMLLinkElement>(null);
	const handleClick = React.useCallback(
		(e?: React.MouseEvent<HTMLElement>) => {
			onClick?.(e);
			ref.current?.click();
		},
		[onClick]
	);

	return {
		ref,
		onClick: handleClick
	};
};
