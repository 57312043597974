import { Record } from "immutable";
import { TVertexType, VertexModel } from "./VertexModel";

const defaults = {
	id: "",
	type: "account" as Exclude<TVertexType, "integration" | "role" | "user">,
	entity: {
		accountId: "",
		integrationId: "",
		accountName: ""
	},
	step: 0
};

export class AccountVertexModel extends Record(defaults) implements VertexModel {
	static fromServerData(data: unknown): AccountVertexModel {
		const { id, type, entity, step } = data as typeof defaults;

		return new AccountVertexModel({
			id,
			type,
			entity: {
				accountId: entity.accountId,
				integrationId: entity.integrationId,
				accountName: entity.accountName
			},
			step
		});
	}
}
