import classNames from "classnames";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "components/ui/Button";
import { CloseIcon } from "components/ui/Icons/CloseIcon";
import { GrantedIcon } from "components/ui/Icons/GrantedIcon";
import { useLoadingState } from "hooks/useLoadingState";
import { useStyles } from "./styles";

type TProps = {
	respond: (response: "approve" | "deny") => void | Promise<void>;
};

export const RequestApprovalActions: FC<TProps> = ({ className, innerRef, respond }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const { withLoader: denyWithLoader, isLoading: denyIsLoading } = useLoadingState();
	const { withLoader: approveWithLoader, isLoading: approveIsLoading } = useLoadingState();

	const handleApprove = useCallback(async () => approveWithLoader(respond("approve")), [approveWithLoader, respond]);
	const handleDeny = useCallback(async () => denyWithLoader(respond("deny")), [denyWithLoader, respond]);

	return (
		<div className={classNames(classes.container, className)} ref={innerRef}>
			<Button
				size="medium"
				variant="secondary"
				suffix={<CloseIcon />}
				onClick={handleDeny}
				loading={denyIsLoading}
				disabled={approveIsLoading}>
				{t("buttons.deny")}
			</Button>
			<Button
				size="medium"
				suffix={<GrantedIcon />}
				onClick={handleApprove}
				loading={approveIsLoading}
				disabled={denyIsLoading}>
				{t("buttons.approve")}
			</Button>
		</div>
	);
};
