import React from "react";

export const GoogleLogo: FC = ({ className }) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" className={className}>
		<path
			d="M22 12.2224C22 11.4003 21.932 10.7999 21.7846 10.1777H12.204V13.8889H17.8273C17.7143 14.8113 17.1016 16.2001 15.741 17.1337L15.7222 17.2581L18.7513 19.558L18.961 19.5789C20.8884 17.8337 22 15.2664 22 12.2224Z"
			fill="#4285F4"
		/>
		<path
			d="M12.204 22C14.9587 22 17.2719 21.1113 18.961 19.5781L15.741 17.1337C14.8793 17.7229 13.7226 18.1339 12.204 18.1339C9.50573 18.1339 7.21542 16.3895 6.39875 13.978L6.27915 13.9876L3.12958 16.3767L3.08862 16.489C4.76621 19.7555 8.21313 22 12.204 22Z"
			fill="#34A853"
		/>
		<path
			d="M6.39875 13.978C6.18332 13.3558 6.05881 12.6888 6.05881 12C6.05881 11.3112 6.18332 10.6441 6.38729 10.022L6.38155 9.88952L3.19266 7.46277L3.08863 7.51174C2.39646 8.8668 2 10.3888 2 12C2 13.6111 2.39646 15.1332 3.08863 16.489L6.39875 13.978Z"
			fill="#FBBC05"
		/>
		<path
			d="M12.204 5.8669C14.1199 5.8669 15.4125 6.67769 16.1497 7.35602L19.0298 4.60014C17.2605 2.989 14.9587 2 12.204 2C8.21313 2 4.76621 4.24452 3.08862 7.51096L6.3881 10.022C7.21543 7.6113 9.50573 5.8669 12.204 5.8669Z"
			fill="#EB4335"
		/>
	</svg>
);
