import classNames from "classnames";
import React from "react";
import { IconButton } from "components/ui/IconButton";
import { CloseIcon } from "components/ui/Icons/CloseIcon";
import { ResetIcon } from "components/ui/Icons/ResetIcon";
import { TooltipOnOverflow } from "components/ui/TooltipOnOverflow";
import { useStyles } from "./styles";
import type { TBaseFilterProps } from "../../types";

type TProps = TBaseFilterProps & {
	inputs?: React.ReactNode;
	hasSelection?: boolean;
};

export const FilterHeader: FC<TProps> = ({
	className,
	inputs,
	innerRef,
	title,
	onRemoveFilter,
	onReset,
	hasSelection = false
}) => {
	const classes = useStyles();

	return (
		<div className={classNames(classes.header, className)} ref={innerRef}>
			<div className={classes.headerLeft}>
				{typeof title === "string" ? <TooltipOnOverflow textVariant="body_sb" content={title} /> : title}
			</div>
			<div className={classes.headerRight}>
				{inputs ? <div className={classes.inputs}>{inputs}</div> : null}

				<div className={classes.headerActions}>
					{onReset ? (
						<IconButton size="small" onClick={onReset} disabled={!hasSelection}>
							<ResetIcon />
						</IconButton>
					) : null}
					{onRemoveFilter ? (
						<IconButton size="small" onClick={onRemoveFilter}>
							<CloseIcon />
						</IconButton>
					) : null}
				</div>
			</div>
		</div>
	);
};
