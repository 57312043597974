import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	content: {
		display: "flex",
		flexDirection: "column",
		gap: "20px",
		padding: "0 20px"
	}
});
