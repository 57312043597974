import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	tasksPage: {
		background: "var(--color-white)"
	},
	backButtonContainer: {
		paddingBottom: "var(--spacing-x7, 28px)"
	},
	titleContainer: {},
	pageContent: {}
});
