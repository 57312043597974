import { List } from "immutable";
import { useMemo } from "react";
import { useNewTicketFormContext } from "components/pages/NewTicketPage/newTicketContext";
import { useBundles } from "hooks/useBundles";
import { useUser } from "hooks/useUser";
import type { TFullBundle, TFullRole, TNewTicketOption, TPrePopulatedFormData } from "../types";

export const useOriginalTicketData = (): TPrePopulatedFormData | null => {
	const {
		state: { originalTicket }
	} = useNewTicketFormContext();

	const receiverUser = useUser(originalTicket?.receiverId);
	const bundles = useBundles();

	return useMemo((): TPrePopulatedFormData | null => {
		if (!originalTicket || !bundles) return null;
		const targets = List<TNewTicketOption>(
			originalTicket.targets?.map(target => {
				if (target.type === "bundle") {
					return {
						type: target.type,
						value: (target.bundle || bundles.get(target.targetId)) as TFullBundle
					};
				}
				return {
					type: target.type,
					value: target.integrationResourceRole as TFullRole
				};
			})
		);

		return {
			ticketingIntegrationTicketId: originalTicket.ticketingIntegrationTicket?.id,
			justification: originalTicket.justification,
			duration: originalTicket.duration,
			receiverUser,
			targets
		};
	}, [originalTicket, bundles, receiverUser]);
};
