import { apiReq } from "utils/api/apiReq";
import { exportToCSV } from "utils/api/downloadFile";
import { auditLogFactory } from "utils/auditLogs/auditLogUtils";
import {
	createDateWithoutTimezone,
	getTimezoneQuerystring,
	TTimezone,
	getDateString
} from "utils/auditLogs/systemAuditLogTimezone";
import { withPagination } from "utils/pagination";
import { getPaginatedSearchQueryString, IPaginatedSearchOptions } from "utils/searchUtils";

export const getAuditLogsDates = async (timezone: TTimezone = "local") => {
	const timezoneOffsetQs = getTimezoneQuerystring(timezone);
	const { data } = await apiReq("GET", "/v1/auditLogs/dates?" + timezoneOffsetQs);

	return (data as `${number}-${number}-${number}T${string}`[]).map(date => createDateWithoutTimezone(date)).reverse();
};

export async function exportAuditLogsToCSV(date: Date, timezone: TTimezone = "local"): Promise<void> {
	const timezoneOffsetQs = getTimezoneQuerystring(timezone);
	await exportToCSV(
		`/v1/auditLogs/downloadCsv?date=${getDateString(date)}${timezoneOffsetQs ? `&${timezoneOffsetQs}` : ""}`,
		"audit-log.csv"
	);
}

export function getPaginatedAuditLogsByDate(
	date: Date | null,
	timezone: TTimezone = "local",
	paginationOptions?: IPaginatedSearchOptions
) {
	const timezoneOffsetQs = getTimezoneQuerystring(timezone);
	return withPagination(
		(auditLog: Record<string, unknown>) => auditLogFactory(auditLog),
		() => {
			if (date) {
				let url = `/v1/auditLogs?date=${getDateString(date)}${timezoneOffsetQs ? `&${timezoneOffsetQs}` : ""}`;
				const paginationQs = getPaginatedSearchQueryString(paginationOptions);
				url += paginationQs ? "&" + paginationQs : "";
				return apiReq("GET", url);
			}
			return Promise.resolve({ data: [] });
		}
	);
}
