import merge from "lodash/merge";
import { Styles } from "react-jss";
import { styles as fade, FADE_IN_ANIMATION_NAME, FADE_OUT_ANIMATION_NAME } from "./fadeAnimation.styles";
import {
	styles as fadeFromBottom,
	FADE_BOTTOM_IN_ANIMATION_NAME,
	FADE_BOTTOM_OUT_ANIMATION_NAME
} from "./fadeMovedFromBottomAnimation.styles";
import {
	styles as fadeFromCenter,
	FADE_CENTER_IN_ANIMATION_NAME,
	FADE_CENTER_OUT_ANIMATION_NAME
} from "./fadeMovedFromCenterAnimation.styles";
import {
	styles as fadeFromEnd,
	FADE_END_IN_ANIMATION_NAME,
	FADE_END_OUT_ANIMATION_NAME
} from "./fadeMovedFromEndAnimation.styles";
import {
	styles as moveFromSide,
	MOVE_SIDE_IN_ANIMATION_NAME,
	MOVE_SIDE_OUT_ANIMATION_NAME
} from "./moveFromSideAnimation.styles";
import { styles as noAnimation } from "./noneAnimation.styles";

// using merge and not safe merge because we want the key to contain special characters - "[], ()"
export const animations: Styles<"transitionable"> = merge(
	fade,
	fadeFromBottom,
	fadeFromCenter,
	fadeFromEnd,
	moveFromSide,
	noAnimation
);

export const ANIMATION_NAMES = [
	FADE_BOTTOM_IN_ANIMATION_NAME,
	FADE_BOTTOM_OUT_ANIMATION_NAME,
	FADE_CENTER_IN_ANIMATION_NAME,
	FADE_CENTER_OUT_ANIMATION_NAME,
	FADE_END_IN_ANIMATION_NAME,
	FADE_END_OUT_ANIMATION_NAME,
	FADE_IN_ANIMATION_NAME,
	FADE_OUT_ANIMATION_NAME,
	MOVE_SIDE_IN_ANIMATION_NAME,
	MOVE_SIDE_OUT_ANIMATION_NAME
];
