import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { AuditLogsWebhook } from "components/pages/SettingsPage/components/AuditLogsWebhooks/AuditLogsWebhook";
import { Button } from "components/ui/Button";
import { AddIcon } from "components/ui/Icons/AddIcon";
import { Typography } from "components/ui/legacy/Typography";
import { LoadingSpinner } from "components/ui/LoadingSpinner";
import { Table } from "components/ui/Table";
import { Title } from "components/ui/Title";
import { useAuditLogsWebhooksContext } from "context/auditLogsWebhooksContext";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { AuditLogsWebhookModel } from "models/AuditLogsWebhookModel";
import { sortByCreatedAt } from "utils/tickets/ticketActivity";
import { useStyles } from "./styles";
import type { IAreYouSureModalProps } from "../..";

interface IProps {
	openAreYouSureModal: (props: IAreYouSureModalProps) => void;
}

export const AuditLogsWebhooks: FC<IProps> = ({ openAreYouSureModal }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const {
		state: { auditLogsWebhooks, isLoading },
		actions: { loadWebhooks, createAuditLogsWebhook, deleteAuditLogsWebhook, enableAuditLogsWebhook }
	} = useAuditLogsWebhooksContext();

	const handleError = useOpenGlobalErrorModal();
	const [newWebhook, setNewWebhook] = useState<AuditLogsWebhookModel | null>(null);

	const clearNewWebhook = useCallback(() => {
		setNewWebhook(null);
	}, []);

	const openInput = useCallback(() => {
		setNewWebhook(new AuditLogsWebhookModel({ url: "" }));
	}, []);

	const deleteHandler = useCallback(
		(id: string) => {
			const onAction = async () => {
				try {
					await deleteAuditLogsWebhook(id);
				} catch (error) {
					handleError(error as Error);
				}
			};

			openAreYouSureModal({
				onAction,
				title: t("pages.settings.auditLogsWebhooks.areYouSure.title"),
				content: t("pages.settings.auditLogsWebhooks.areYouSure.content")
			});
		},
		[deleteAuditLogsWebhook, handleError, openAreYouSureModal, t]
	);

	const enableHandler = useCallback(
		async (id: string) => {
			try {
				await enableAuditLogsWebhook(id);
			} catch (error) {
				handleError(error as Error);
			}
		},
		[enableAuditLogsWebhook, handleError]
	);

	const sortedWebhooks = useMemo(
		() => auditLogsWebhooks?.sort((a, b) => sortByCreatedAt(a, b, "desc")).toList(),
		[auditLogsWebhooks]
	);

	useEffect(() => {
		clearNewWebhook();
	}, [clearNewWebhook]);

	useEffect(() => {
		if (!auditLogsWebhooks) {
			void loadWebhooks();
		}
	}, [auditLogsWebhooks, loadWebhooks]);

	return (
		<>
			<Title variant="h3" noBorder className={classes.title} extraMargin>
				<span>{t("pages.settings.auditLogsWebhooks.title")}</span>
				<Button onClick={openInput} variant="secondary" size="medium" prefix={<AddIcon />}>
					{t("buttons.add")}
				</Button>
			</Title>
			<div>
				{!isLoading ? (
					auditLogsWebhooks?.isEmpty() && !newWebhook ? (
						<Typography className={classes.noForwards} variant="h2">
							{t("pages.settings.auditLogsWebhooks.empty")}
						</Typography>
					) : (
						<Table gridColumns="1fr 1fr 1fr 10rem">
							<Table.Row>
								<Table.Header>
									<Typography variant="small">{t("pages.settings.auditLogsWebhooks.url")}</Typography>
								</Table.Header>
								<Table.Header>
									<Typography variant="small">{t("pages.settings.auditLogsWebhooks.headers")}</Typography>
								</Table.Header>
								<Table.Header>
									<Typography variant="small">
										{t("pages.settings.auditLogsWebhooks.additionalAuditLogParams")}
									</Typography>
								</Table.Header>
							</Table.Row>
							{newWebhook && (
								<AuditLogsWebhook create closeInput={clearNewWebhook} createHandler={createAuditLogsWebhook} />
							)}
							{sortedWebhooks?.map(webhook => (
								<AuditLogsWebhook
									key={webhook.id}
									webhook={webhook}
									removeHandler={deleteHandler}
									enableHandler={enableHandler}
								/>
							))}
						</Table>
					)
				) : (
					<LoadingSpinner />
				)}
			</div>
		</>
	);
};
