import { useCallback, useEffect, useMemo, useState } from "react";
import { useAccessReviewsContext } from "context/accessReviewsContext";
import { useOpenGlobalErrorModal } from "./useGlobalError";
import { useLoadingState } from "./useLoadingState";

export const useAccessReviews = () => {
	const {
		state: { accessReviews, accessReviewsOrder },
		actions: { loadAccessReviews: contextLoadAccessReviews, editAccessReview }
	} = useAccessReviewsContext();

	const [isError, setIsError] = useState<boolean>(false);
	const handleError = useOpenGlobalErrorModal();
	const getLoadingState = useLoadingState();

	const loadAccessReviews = useCallback(async () => {
		if (!isError) {
			try {
				await getLoadingState.withLoader(contextLoadAccessReviews());
			} catch (error) {
				handleError(error as Error);
				setIsError(true);
			}
		}
	}, [isError, getLoadingState, contextLoadAccessReviews, handleError]);

	useEffect(() => {
		if (!accessReviews && !getLoadingState.isLoading && !isError) {
			void loadAccessReviews();
		}
	}, [accessReviews, getLoadingState.isLoading, isError, loadAccessReviews]);

	const sortedAccessReviews = useMemo(
		() => (accessReviewsOrder && accessReviews ? accessReviewsOrder.map(id => accessReviews.get(id)!) : null),
		[accessReviews, accessReviewsOrder]
	);

	return {
		accessReviews,
		sortedAccessReviews,
		editAccessReview,
		getLoading: getLoadingState.isLoading,
		loadAccessReviews,
		isError
	};
};
