import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Select } from "components/ui/Select";
import { TextOption } from "components/ui/selectOptions/TextOption";
import { DURATION_OPTIONS, TTicketDuration } from "utils/durationsOptions";
import { useStyles } from "./styles";
import type { IFilterProps } from "../../";

const TRANSLATION_PREFIX = "common.ticketFilters";

export const DurationFilter: FC<IFilterProps> = ({ onFiltersChange, ticketFilters }) => {
	const { t } = useTranslation();
	const classes = useStyles();

	const minDuration = useMemo(() => ticketFilters.accessDuration?.min || null, [ticketFilters.accessDuration?.min]);
	const maxDuration = useMemo(() => ticketFilters.accessDuration?.max || null, [ticketFilters.accessDuration?.max]);

	const minDurationsOptions = useMemo(
		() => DURATION_OPTIONS.filter(duration => duration !== -1).sort((a, b) => a - b),
		[]
	);
	const maxDurationsOptions = useMemo(
		() => DURATION_OPTIONS.filter(duration => duration !== -1 && (minDuration || 0) <= duration).sort((a, b) => a - b),
		[minDuration]
	);

	const changeMinDuration = useCallback(
		(duration: TTicketDuration | null) => {
			onFiltersChange(current => {
				if (duration && current.accessDuration)
					return {
						...current,
						accessDuration: {
							min: duration,
							max:
								current.accessDuration.max && current.accessDuration.max < duration
									? undefined
									: current.accessDuration.max
						}
					};
				return { ...current, accessDuration: { min: duration || undefined, max: current.accessDuration?.max } };
			});
		},
		[onFiltersChange]
	);
	const changeMaxDuration = useCallback(
		(duration: TTicketDuration | null) => {
			onFiltersChange(current => ({
				...current,
				accessDuration: { min: current.accessDuration?.min, max: duration || undefined }
			}));
		},
		[onFiltersChange]
	);

	const getOptionLabel = useCallback(
		(option: TTicketDuration): string => {
			return t(`common.durations.${option}` as const);
		},
		[t]
	);

	return (
		<div className={classes.range}>
			<Select
				getOptionLabel={getOptionLabel}
				label={t(`${TRANSLATION_PREFIX}.minDuration`)}
				onChange={changeMinDuration}
				renderOption={TextOption}
				options={minDurationsOptions}
				sort={null}
				value={minDuration}
			/>
			<Select
				getOptionLabel={getOptionLabel}
				label={t(`${TRANSLATION_PREFIX}.maxDuration`)}
				onChange={changeMaxDuration}
				renderOption={TextOption}
				options={maxDurationsOptions}
				sort={null}
				value={maxDuration}
			/>
		</div>
	);
};
