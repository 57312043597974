import React from "react";

export const LdapLogo: FC = ({ className }) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" className={className}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M10.2536 13.7477L12.2484 10.2303L9.84927 6L7.85444 9.5174L10.2536 13.7477ZM6.20835 7.89609L7.49114 10.158L9.89029 14.3883L9.8903 14.3883L9.5846 14.9273L8.50926 16.8234H1.14539L6.20835 7.89609ZM15.2776 12.15L13.5819 9.16009L10.2536 15.0289L9.23585 16.8234L8.51898 18.0874H18.6449L17.928 16.8234L15.2776 12.15ZM22.7531 16.8234H18.6546L15.6409 11.5094L17.6901 7.89609L22.7531 16.8234Z"
			fill="#CB2026"
		/>
	</svg>
);
