import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	active: {},
	disabled: {
		color: "var(--color-grey-disabled-font-color)"
	},
	tabBody: {
		display: "flex",
		height: "100%",
		overflow: "auto",
		width: "100%"
	},
	tabHeader: {
		alignItems: "center",
		backgroundColor: "var(--color-white)",
		display: "flex",
		justifyContent: "space-between"
	},
	tabHeaderItems: {
		display: "flex"
	},
	tabHeaderItem: {
		"&$active": {
			boxShadow: "0px 2px 0px var(--color-purple)",
			color: "var(--typography-primary-font-color)"
		},
		"&:hover:not($disabled)": {
			backgroundColor: "var(--color-grey-background)",
			cursor: "pointer"
		},
		alignItems: "center",
		color: "var(--color-grey-dark)",
		display: "flex",
		justifyContent: "center",
		padding: "0.5em",
		textAlign: "center",
		willChange: "background-color, box-shadow, color"
	},
	tabPane: {
		height: "100%",
		width: "100%"
	},
	tabs: {
		display: "flex",
		flexDirection: "column",
		gap: "0.5rem",
		height: "100%",
		overflow: "auto",
		width: "100%"
	},
	suffix: {
		justifySelf: "flex-end"
	}
});
