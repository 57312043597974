import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "components/ui/Button";
import { Input } from "components/ui/Input";
import { useLoadingState } from "hooks/useLoadingState";
import { getCommentValidator } from "utils/validation/fieldValidation";
import { useStyles } from "./styles";

interface IProps {
	onSubmit: (comment: string) => Promise<void>;
}

const COMMENT_VALIDATORS = getCommentValidator(1, 1024, "entities.Comment");

export const AccessReviewPermissionCommentHeader: FC<IProps> = ({ className, id, innerRef, onSubmit }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const [content, setContent] = useState("");
	const { isLoading, withLoader } = useLoadingState();

	const saveComment = useCallback(async () => {
		if (content) {
			await withLoader(onSubmit(content));
		}
	}, [content, onSubmit, withLoader]);

	const isDisabled = useMemo(() => Boolean(!content || isLoading || COMMENT_VALIDATORS(content)), [content, isLoading]);

	return (
		<div className={classes.comment}>
			<Input
				className={className}
				placeholder={t("common.addComment.placeholder")}
				value={content || ""}
				onValueChange={setContent}
				size="medium"
				id={id}
				innerRef={innerRef}
			/>
			<Button
				variant="secondary"
				size="medium"
				tooltip={COMMENT_VALIDATORS(content || "") || undefined}
				disabled={isDisabled}
				loading={isLoading}
				onClick={saveComment}>
				{t("buttons.save")}
			</Button>
		</div>
	);
};
