import React, { useCallback, useMemo } from "react";
import { useResizeDetector } from "react-resize-detector";
import { MAX_CHIP_WIDTH } from "components/ui/chips/Chip/styles";
import { Chips } from "components/ui/MultipleSelect/components/Chips";
import { useBundles } from "hooks/useBundles";
import { useIntegrationResourceRoles } from "hooks/useIntegrationResourceRoles";
import { useMultiDirectoryGroups } from "hooks/useMultiDirectoryGroups";
import { useOnCallIntegrationSchedules } from "hooks/useOnCallIntegrationSchedules";
import { BundleModel } from "models/BundleModel";
import { DirectoryGroupModel } from "models/DirectoryGroupModel";
import { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";
import { OnCallIntegrationScheduleModel } from "models/OnCallIntegrationScheduleModel";
import { PolicyModel } from "models/PolicyModel";
import { useStyles } from "./styles";

type TChipsCellProps = {
	type: "bundle" | "group" | "role";
	policy: PolicyModel;
	showActions?: boolean;
};

const INTEGRATIONS_CHIPS_DEFAULT_WIDTH = 84;

export const ChipsCell: FC<TChipsCellProps> = ({ type, policy }) => {
	const classes = useStyles();
	const { ref: parentRef, width: parentWidth } = useResizeDetector();
	const maxChips = useMemo(
		() => Math.max(0, Math.floor((parentWidth ?? INTEGRATIONS_CHIPS_DEFAULT_WIDTH) / MAX_CHIP_WIDTH) * 4 - 1),
		[parentWidth]
	);

	const bundles = useBundles(true);
	const policyBundles = useMemo(
		() => (bundles ? policy.bundleIds.map(id => bundles.get(id)).filter(bundle => bundle !== undefined) : null),
		[bundles, policy.bundleIds]
	);

	const policyDirectoryGroups = useMultiDirectoryGroups(policy.directoryGroupIds);
	const onCallIntegrationSchedules = useOnCallIntegrationSchedules();

	const roles = useIntegrationResourceRoles(policy.integrationResourceRoleIds, true);

	const policyOnCallIntegrationSchedules = useMemo(() => {
		const schedules = policy.onCallIntegrationScheduleIds
			.map(id => onCallIntegrationSchedules?.find(schedule => schedule.id === id))
			.filter(Boolean);
		return schedules as OnCallIntegrationScheduleModel[];
	}, [onCallIntegrationSchedules, policy.onCallIntegrationScheduleIds]);

	const policyOnCallsAndGroups = useMemo(() => {
		return policyDirectoryGroups?.concat(policyOnCallIntegrationSchedules).toArray() || [];
	}, [policyDirectoryGroups, policyOnCallIntegrationSchedules]);

	const getOptionLabel = useCallback(
		(option: DirectoryGroupModel | OnCallIntegrationScheduleModel | BundleModel | IntegrationResourceRoleModel) =>
			option.name,
		[]
	);

	const chipsValues = useMemo(() => {
		switch (type) {
			case "bundle": {
				return policyBundles;
			}
			case "group": {
				return policyOnCallsAndGroups;
			}
			case "role": {
				return roles;
			}
			default: {
				return [];
			}
		}
	}, [policyBundles, policyOnCallsAndGroups, roles, type]);

	return (
		<div className={classes.container}>
			<div className={classes.listContainer} ref={parentRef}>
				<Chips
					limit={maxChips}
					type={type}
					limitChipSize="medium"
					limitChipType={type}
					values={chipsValues}
					getOptionLabel={getOptionLabel}
				/>
			</div>
		</div>
	);
};
