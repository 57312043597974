import classNames from "classnames";
import isEqual from "lodash/isEqual";
import uniqWith from "lodash/uniqWith";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TApprovalStatus } from "components/common/ApprovalStatusChip";
import { Approver, TRespondersInGroup } from "components/common/TicketsListComponents";
import { ArrowDownIcon } from "components/ui/Icons/ArrowDownIcon";
import { Typography } from "components/ui/legacy/Typography";
import { TicketApprovalEntityModel } from "models/TicketApprovalEntityModel";
import { TicketApprovalRequestModel } from "models/TicketApprovalRequestModel";
import { useStyles } from "./styles";
import type { TDirection } from "../";

interface IProps {
	stepRequests: TicketApprovalRequestModel[];
	withStatus: boolean;
	getApprovalStatus: (entity: TicketApprovalEntityModel) => TApprovalStatus;
	getRespondersInGroup: (userIds: string[]) => TRespondersInGroup;
	direction: TDirection;
	noModal?: boolean;
}

const ApprovalFlowRequest: FC<{ request: TicketApprovalRequestModel } & Omit<IProps, "stepRequests" | "direction">> = ({
	request,
	withStatus,
	getApprovalStatus,
	getRespondersInGroup,
	noModal
}) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const uniqueApprovers = useMemo(() => {
		return uniqWith(request.approvalEntities?.toArray(), (first, second) =>
			isEqual(
				first.approverIds?.size ? first.approverIds : first.identifier,
				second.approverIds?.size ? second.approverIds : second.identifier
			)
		);
	}, [request]);

	return (
		<div className={classes.approvers}>
			<Typography className={classes.operator}>
				{t(`common.approvalFlowSimulation.operators.${request.operator}`)}
			</Typography>
			<div className={classes.entities}>
				{uniqueApprovers?.map(entity => (
					<Approver
						key={entity.id}
						entity={entity}
						getApprovalStatus={getApprovalStatus}
						getRespondersInGroup={getRespondersInGroup}
						className={classes.entity}
						showStatus={withStatus}
						withIcon={false}
						noBorder={false}
						noModal={noModal}
						size="small"
					/>
				))}
			</div>
		</div>
	);
};

export const ApprovalFlowStep: FC<IProps> = ({
	stepRequests,
	getApprovalStatus,
	getRespondersInGroup,
	withStatus,
	direction,
	noModal
}) => {
	const classes = useStyles();
	const vertical = direction === "vertical";

	return (
		<>
			<div className={classes.step}>
				{stepRequests.map(request => (
					<ApprovalFlowRequest
						getApprovalStatus={getApprovalStatus}
						getRespondersInGroup={getRespondersInGroup}
						request={request}
						withStatus={withStatus}
						key={request.id}
						noModal={noModal}
					/>
				))}
			</div>
			<ArrowDownIcon className={classNames(classes.arrow, { [classes.horizontal]: !vertical })} />
		</>
	);
};
