import constate from "constate";
import { Set } from "immutable";
import { useCallback, useMemo, useState } from "react";
import { renewTicket as apiRenewTicket } from "api/tickets";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";

const useTicketRenewal = () => {
	const [renewedIds, setRenewedIds] = useState(Set<string>());
	const openGlobalErrorModal = useOpenGlobalErrorModal();

	const renewTicket = useCallback(
		async (ticketId: string) => {
			try {
				await apiRenewTicket(ticketId);
				setRenewedIds(curr => curr.add(ticketId));
			} catch (error) {
				openGlobalErrorModal(error as Error);
			}
		},
		[openGlobalErrorModal]
	);

	return useMemo(
		() => ({
			state: { renewedIds },
			actions: { renewTicket }
		}),
		[renewTicket, renewedIds]
	);
};

export const [TicketRenewalProvider, useTicketRenewalContext] = constate(useTicketRenewal);
