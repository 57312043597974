import { Record } from "immutable";

interface IPolicyConflictRole {
	roleId: string;
	roleName?: string;
	bundleName?: string;
}

interface IRoleDuplication {
	resourceName: string;
	integrationName: string;
	takenRole: IPolicyConflictRole;
	ignoredRoles: IPolicyConflictRole[];
}

const defaults = {
	roleDuplications: {} as { [key: string]: IRoleDuplication },
	actorlessRoles: [] as IPolicyConflictRole[]
};

export class PolicyConflictsModel extends Record<typeof defaults>(defaults) {
	static fromServerData(data: unknown) {
		const parsedData = data as typeof defaults;

		return new PolicyConflictsModel(parsedData);
	}
}
