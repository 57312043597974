import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	approvalProcessContainer: {
		display: "flex",
		flexDirection: "column",
		gap: "12px",
		justifyContent: "flex-start"
	},
	stepsContainer: {
		width: "100%",
		gap: "16px",
		overflow: "auto",
		display: "flex",
		justifyContent: "flex-start"
	}
});
