import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	scrollable: {
		maxHeight: "40rem",
		overflowY: "auto"
	},
	option: {
		alignItems: "flex-start",
		display: "flex",
		flexDirection: "row",
		flexGrow: 1,
		justifyContent: "space-between",
		"&.disabled": {
			color: "var(--color-grey-disabled)"
		}
	},
	integrationsTitle: {
		marginBottom: "0.5rem"
	},
	saveButton: {
		width: "fit-content"
	},
	spinner: {
		margin: "auto"
	}
});
