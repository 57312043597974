import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	checkbox: {
		margin: 0
	},
	checkboxLabel: {
		display: "inline-flex",
		alignItems: "center",
		gap: "4px"
	}
});
