import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PageTitleContent } from "components/templates/PageTitleContentTemplate";
import { IconButton } from "components/ui/IconButton";
import { ArrowLeftIcon } from "components/ui/Icons/ArrowLeftIcon";
import { LoadingDots } from "components/ui/LoadingDots";
import { Typography } from "components/ui/Typography";
import { useAuthenticatedUser } from "hooks/useAuthenticatedUser";
import { ProfileSections } from "./components/ProfileSections";

export const ProfilePage: FC<{ hideBackButton?: boolean }> = ({ className, hideBackButton = false }) => {
	const { t } = useTranslation();

	const { user } = useAuthenticatedUser();

	const navigate = useNavigate();

	const handleBack = useCallback(() => navigate(-1), [navigate]);

	return (
		<PageTitleContent className={className}>
			<PageTitleContent.Header>
				<PageTitleContent.Header.Top
					breadcrumbs={
						!hideBackButton ? (
							<IconButton size="large" onClick={handleBack}>
								<ArrowLeftIcon />
							</IconButton>
						) : null
					}></PageTitleContent.Header.Top>
				<Typography variant="header_sb">{t("pages.profile.title")}</Typography>
			</PageTitleContent.Header>
			<PageTitleContent.Body>{user ? <ProfileSections user={user} /> : <LoadingDots />}</PageTitleContent.Body>
		</PageTitleContent>
	);
};
