import React from "react";
import { makeIcon } from "components/ui/Icon";

export const BundleIcon = makeIcon(
	<svg viewBox="0 0 24 24" fill="none">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12 14C11.45 14 10.9792 13.8042 10.5875 13.4125C10.1958 13.0208 10 12.55 10 12V4C10 3.45 10.1958 2.97917 10.5875 2.5875C10.9792 2.19583 11.45 2 12 2H20C20.55 2 21.0208 2.19583 21.4125 2.5875C21.8042 2.97917 22 3.45 22 4V12C22 12.55 21.8042 13.0208 21.4125 13.4125C21.0208 13.8042 20.55 14 20 14H12ZM12 12H20V4H12V12ZM6.5875 17.4125C6.97917 17.8042 7.45 18 8 18H16C16.55 18 17.0208 17.8042 17.4125 17.4125C17.8042 17.0208 18 16.55 18 16V15H16V16H8V8H9V6H8C7.45 6 6.97917 6.19583 6.5875 6.5875C6.19583 6.97917 6 7.45 6 8V16C6 16.55 6.19583 17.0208 6.5875 17.4125ZM4 22C3.45 22 2.97917 21.8042 2.5875 21.4125C2.19583 21.0208 2 20.55 2 20V12C2 11.45 2.19583 10.9792 2.5875 10.5875C2.97917 10.1958 3.45 10 4 10H5V12H4V20H12V19H14V20C14 20.55 13.8042 21.0208 13.4125 21.4125C13.0208 21.8042 12.55 22 12 22H4Z"
			fill="currentColor"
		/>
	</svg>
);
