import { List } from "immutable";
import { PersonalAccessTokenModel } from "models/PersonalAccessTokenModel";
import { apiReq } from "utils/api/apiReq";

type TCreatePersonalAccessToken = {
	name: string;
	duration: number | null;
};

export async function getPersonalAccessTokens() {
	const { data } = await apiReq("GET", "/v1/userAccessTokens");

	return List<PersonalAccessTokenModel>(data.map(PersonalAccessTokenModel.fromServerData));
}

export async function generateCLIAccessToken(port: number, secured: string | null) {
	return apiReq("POST", `/v1/userAccessTokens/cli?port=${port}${secured ? `&secured=${secured}` : ""}`, undefined, {
		redirect: "follow",
		expectJson: false
	});
}

export const createPersonalAccessToken = async (token: TCreatePersonalAccessToken) => {
	const { data: newToken } = await apiReq("POST", "/v1/userAccessTokens", token);

	return PersonalAccessTokenModel.fromServerData(newToken);
};

export const updatePersonalAccessToken = async (id: string, name: string) => {
	const { data: updatedToken } = await apiReq("PUT", `/v1/userAccessTokens/${id}`, { name });

	return PersonalAccessTokenModel.fromServerData(updatedToken);
};

export const deletePersonalAccessToken = async (id: string) => {
	return apiReq("DELETE", `/v1/userAccessTokens/${id}`);
};
