import React from "react";
import { TagIcon } from "components/ui/Icons/TagIcon";
import { Chip, TChipVariant, TChipSize } from "../Chip";

interface ITagChipProps {
	SuffixIcon?: IconComponent;
	onDelete?: () => void;
	readonly?: boolean;
	selected?: boolean;
	size?: TChipSize;
	variant?: TChipVariant;
}

export const TagChip: FC<ITagChipProps> = ({
	SuffixIcon,
	children,
	className,
	innerRef,
	onDelete,
	readonly = false,
	selected = false,
	size = "medium",
	variant = "regular"
}) => {
	return (
		<Chip
			PrefixIcon={TagIcon}
			SuffixIcon={SuffixIcon}
			className={className}
			innerRef={innerRef}
			onDelete={onDelete}
			readonly={readonly}
			selected={selected}
			size={size}
			variant={variant}>
			{children}
		</Chip>
	);
};
