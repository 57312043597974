import React from "react";
import { makeIcon } from "components/ui/Icon";

export const RuleIcon = makeIcon(
	<svg viewBox="0 0 24 24" fill="none">
		<path
			d="M5 19H15C15.2833 19 15.5208 19.0959 15.7125 19.2875C15.9042 19.4792 16 19.7167 16 20C16 20.2834 15.9042 20.5209 15.7125 20.7125C15.5208 20.9042 15.2833 21 15 21H5C4.71667 21 4.47917 20.9042 4.2875 20.7125C4.09583 20.5209 4 20.2834 4 20C4 19.7167 4.09583 19.4792 4.2875 19.2875C4.47917 19.0959 4.71667 19 5 19ZM8.225 14.725L5.4 11.9C5.01667 11.5167 4.82083 11.0459 4.8125 10.4875C4.80417 9.92919 4.99167 9.45835 5.375 9.07502L6.1 8.35002L11.8 14L11.075 14.725C10.6917 15.1084 10.2167 15.3 9.65 15.3C9.08333 15.3 8.60833 15.1084 8.225 14.725ZM16 9.80002L10.35 4.10002L11.075 3.37502C11.4583 2.99169 11.9292 2.80419 12.4875 2.81252C13.0458 2.82086 13.5167 3.01669 13.9 3.40002L16.725 6.22502C17.1083 6.60836 17.3 7.08335 17.3 7.65002C17.3 8.21669 17.1083 8.69169 16.725 9.07502L16 9.80002ZM19.9 19.3L7.55 6.95002L8.95 5.55002L21.3 17.9C21.4833 18.0834 21.575 18.3167 21.575 18.6C21.575 18.8834 21.4833 19.1167 21.3 19.3C21.1167 19.4834 20.8833 19.575 20.6 19.575C20.3167 19.575 20.0833 19.4834 19.9 19.3Z"
			fill="currentColor"
		/>
	</svg>
);
