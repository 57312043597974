import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	sectionContainer: {
		width: "100%"
	},
	contentContainer: {
		display: "flex",
		gap: "var(--spacing-x4, 16px)",
		flexWrap: "wrap"
	}
});
