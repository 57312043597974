import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { DescribedCheckbox } from "components/common/DescribedCheckbox";
import { DurationsOverrideSelect } from "components/common/DurationsOverrideSelect";
import { SettingsPopup } from "components/common/SettingsPopup";
import { TagsInput } from "components/common/TagsInput";
import { OwnerSelect } from "components/pages/CreateIntegrationPage/components/OwnerSelect";
import { Form } from "components/ui/Form";
import { Input } from "components/ui/Input";
import { IntegrationModel } from "models/IntegrationModel";
import { IntegrationResourceModel } from "models/IntegrationResourceModel";
import { getDescriptionValidators, getNameValidators, getTypeValidators } from "utils/validation/validationUtils";
import { TFormData, useSettingsPopupState } from "./useSettingsPopupState";
import { useStyles } from "../styles";

interface IResourceSettingsPopupProps {
	adapterless: boolean;
	initialFormData: TFormData;
	integration: IntegrationModel | undefined;
	integrationResource: IntegrationResourceModel;
	onClose?: () => void;
	onSave: (data: TFormData) => Promise<void>;
	type?: string;
}

export const ResourceSettingsPopup: FC<IResourceSettingsPopupProps> = ({
	adapterless,
	integrationResource,
	integration,
	initialFormData: {
		owner: propOwner,
		description: propDescription,
		tags: propTags,
		allowsRequests: propAllowsRequests,
		allowedDurations: propAllowedDurations,
		overrideAllowedDurations: propOverrideAllowedDurations,
		resourceType: propResourceType,
		name: propName
	},
	onClose: propOnClose,
	onSave: propOnSave,
	type
}) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const {
		allowsRequests,
		allowedDurations,
		description,
		overrideAllowedDurations,
		owner,
		resourceType,
		tags,
		name,
		toggleAllowsRequests,
		setName,
		setAllowedDurations,
		setDescription,
		setOverrideAllowedDurations,
		setOwner,
		setResourceType,
		setTags,
		onSave,
		onClose
	} = useSettingsPopupState({
		propAllowedDurations,
		propAllowsRequests,
		propDescription,
		propName: propName,
		propOnSave,
		propOverrideAllowedDurations,
		propOwner,
		propResourceType,
		propTags
	});

	const nameValidators = useMemo(() => getNameValidators(t("common.resourceHeader.name")), [t]);
	const typeValidators = useMemo(() => getTypeValidators(t("common.resourceHeader.type"), true), [t]);
	const descriptionValidators = useMemo(
		() => getDescriptionValidators(t("common.resourceHeader.description"), true),
		[t]
	);

	const isValid = useMemo(() => {
		const nameIsInvalid = adapterless ? nameValidators.some(validate => validate(name)) : false;
		const typeIsInvalid = typeValidators.some(validate => validate(resourceType || ""));
		const descriptionIsInvalid = descriptionValidators.some(validate => validate(description || ""));

		return !(nameIsInvalid || typeIsInvalid || descriptionIsInvalid);
	}, [description, descriptionValidators, name, nameValidators, resourceType, typeValidators, adapterless]);

	const handleSave = useCallback(async () => {
		if (!isValid) return;
		return onSave();
	}, [isValid, onSave]);

	const handleClose = useCallback(() => {
		propOnClose?.();
		onClose();
	}, [propOnClose, onClose]);

	useEffect(() => {
		setName(propName);
	}, [propName, setName]);

	return (
		<SettingsPopup onClose={handleClose} onSave={handleSave} className={classes.settingsModal} disable={!isValid}>
			<Form.Field>
				<OwnerSelect
					autoAssigned={integration?.autoAssignRecommendedResourceOwner}
					ownerId={typeof owner === "string" ? owner : owner.id}
					setOwner={setOwner}
					integrationId={integrationResource.integrationId || integration?.id}
				/>
			</Form.Field>
			<Form.Field>
				<Input
					label={t("common.resourceHeader.name")}
					value={name}
					onValueChange={setName}
					validators={nameValidators}
					disabled={!adapterless}
				/>
			</Form.Field>
			<Form.Field>
				{type && adapterless && (
					<Input
						label={t("common.resourceHeader.type")}
						value={resourceType}
						onValueChange={setResourceType}
						validators={typeValidators}
					/>
				)}
			</Form.Field>
			<Form.Field>
				<Input
					label={t("common.resourceHeader.description")}
					value={description}
					placeholder={integrationResource.description || ""}
					onValueChange={setDescription}
					validators={descriptionValidators}
				/>
			</Form.Field>
			<Form.Field>
				<TagsInput
					label={t("common.resourceHeader.tags")}
					values={tags || []}
					onChange={setTags}
					readonlyValues={integrationResource.tags || undefined}
				/>
			</Form.Field>
			<Form.Field>
				<DescribedCheckbox
					label={t("pages.integration.requestableHeader")}
					selected={integration?.allowsRequests && allowsRequests}
					description={t("pages.integration.requestableDescription")}
					onClick={toggleAllowsRequests}
					disabled={!integration?.allowsRequests}
				/>
			</Form.Field>
			<Form.Field>
				<DescribedCheckbox
					label={t("pages.integration.resource.multirole")}
					selected={integrationResource.multirole}
					description={t("pages.integration.resource.multiroleHelp")}
					disabled
				/>
			</Form.Field>
			<Form.Field>
				<DurationsOverrideSelect
					overrideAllowedDurations={overrideAllowedDurations}
					setOverrideAllowedDurations={setOverrideAllowedDurations}
					allowedDurations={allowedDurations}
					setAllowedDurations={setAllowedDurations}
					disabled={!integration}
					type="resource"
				/>
			</Form.Field>
		</SettingsPopup>
	);
};
