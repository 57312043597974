import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	imageLarge: {},
	imageMedium: {},
	imageSmall: {},
	integrationImage: {
		"&$imageLarge": {
			height: "4rem",
			padding: "0.75rem",
			width: "4rem"
		},
		"&$imageMedium": {
			height: "3.0rem",
			padding: "0.5rem",
			width: "3.0rem"
		},
		"&$imageSmall": {
			height: "2.3rem",
			padding: "0.45rem",
			width: "2.3rem"
		},
		flex: "1 0 auto",
		overflow: "hidden"
	},
	resourceContainer: {
		alignItems: "center",
		display: "flex",
		flexDirection: "row",
		gap: "0.5rem"
	},
	resourceName: {
		alignItems: "flex-start",
		display: "flex",
		flexDirection: "column",
		gap: "0.25rem"
	},
	resourceNameText: {
		"&.deleted": {
			"&::after": {
				color: "var(--color-red)",
				content: "'*'"
			}
		}
	}
});
