import { Record } from "immutable";
import { ApprovalAlgorithmModel } from "models/ApprovalAlgorithmModel";
import type { TDiff } from "utils/auditLogs/auditLogUtils";

type TApprovalAlgorithmAuditLogAction = "WorkflowCreated" | "WorkflowDeleted" | "WorkflowUpdated";

export interface IApprovalAlgorithmAuditLogDiffData {
	name: TDiff<string>;
}

const defaults = {
	action: "WorkflowCreated" as TApprovalAlgorithmAuditLogAction,
	approvalAlgorithm: null as ApprovalAlgorithmModel | null,
	approvalAlgorithmId: "",
	id: "",
	createdAt: new Date(0),
	data: null as IApprovalAlgorithmAuditLogDiffData | null,
	userId: ""
};

export class ApprovalAlgorithmAuditLogModel extends Record<typeof defaults>(defaults) {
	static readonly type = "approvalAlgorithm";

	static fromServerData(data: unknown) {
		const {
			id,
			createdAt,
			data: _data,
			action,
			approvalAlgorithm,
			approvalAlgorithmId,
			userId
		} = data as typeof defaults;

		return new ApprovalAlgorithmAuditLogModel({
			id,
			createdAt: new Date(createdAt),
			data: _data || ({} as IApprovalAlgorithmAuditLogDiffData),
			action,
			approvalAlgorithm: approvalAlgorithm && ApprovalAlgorithmModel.fromServerData(approvalAlgorithm),
			approvalAlgorithmId,
			userId
		});
	}
}
