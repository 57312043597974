import { useEffect, useMemo } from "react";
import { useApprovalAlgorithmsContext } from "context/approvalAlgorithmsContext";
import { useApprovalAlgorithms } from "./useApprovalAlgorithms";

export const useApprovalAlgorithm = (id: string, withDeleted = false) => {
	const {
		state: { isLoading },
		actions: { loadApprovalAlgorithms }
	} = useApprovalAlgorithmsContext();
	const approvalAlgorithms = useApprovalAlgorithms(withDeleted);
	const approvalAlgorithm = useMemo(() => approvalAlgorithms?.get(id), [approvalAlgorithms, id]);

	useEffect(() => {
		if (!approvalAlgorithms) {
			void loadApprovalAlgorithms();
		}
	}, [approvalAlgorithms, loadApprovalAlgorithms]);

	return { approvalAlgorithm, loading: isLoading || !approvalAlgorithms };
};
