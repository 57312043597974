import { notEmpty } from "utils/comparison";
import type { ReactNode } from "react";

export function isEmptyReactNode(value: ReactNode): boolean {
	return (
		value === undefined ||
		value === null ||
		Number.isNaN(value) ||
		(Array.isArray(value) && value.filter(notEmpty).length === 0) ||
		(typeof value === "object" && Object.keys(value).length === 0) ||
		(typeof value === "string" && value.trim().length === 0)
	);
}
