import { useCallback, useEffect, useState } from "react";
import { useAuthenticatedUserContext } from "context/AuthenticatedUserContext";
import useMountState from "./useMountState";

export const useAuthenticatedUser = () => {
	const {
		state: { user, error },
		actions: { loadUser: contextLoadUser, clearUser }
	} = useAuthenticatedUserContext();

	const [_, ifMounted] = useMountState();
	const [loading, setLoading] = useState(false);

	const loadUser = useCallback(async () => {
		setLoading(true);
		await contextLoadUser();
		ifMounted(() => setLoading(false));
	}, [contextLoadUser, ifMounted]);

	useEffect(() => {
		if (!user && !error) {
			void loadUser();
		}
	}, [error, loadUser, user]);

	return {
		clearUser,
		reloadUser: loadUser,
		error,
		loading,
		user
	};
};
