import { useCallback, useEffect, useMemo, useState } from "react";
import { TSearch, useSearchOptions } from "./useSearchOptions";
import type { TTargetValue } from "components/ui/Select";

const isChangeEvent = (event: React.SyntheticEvent | TTargetValue): event is React.ChangeEvent<HTMLInputElement> =>
	event.type === "change";

interface ISelectSearchProps<T> {
	selectProps: {
		options: T[];
		loading: boolean;
		onInputChange: (value: React.ChangeEvent<HTMLInputElement> | TTargetValue) => void;
		onFocus: () => void;
	};
	query: string | null;
}

export const useSelectSearchProps = <T>(
	search: TSearch<T>,
	value: T | T[] | null,
	withDebounce = false,
	initialQuery = null
): ISelectSearchProps<T> => {
	const [isFocused, setIsFocused] = useState(false);
	const [options, searchOptions, loading, query] = useSearchOptions(search, withDebounce, initialQuery);

	const onInputChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement> | TTargetValue) => {
			if (isFocused) {
				searchOptions(isChangeEvent(event) ? event.target.value : "");
			}
		},
		[isFocused, searchOptions]
	);

	const focus = useCallback(() => {
		setIsFocused(true);
	}, []);

	useEffect(() => {
		searchOptions("");
	}, [searchOptions]);

	const selectOptions = useMemo(() => options || [], [options]);

	return { selectProps: { options: selectOptions, loading, onInputChange, onFocus: focus }, query };
};
