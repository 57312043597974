import { ErrorObject } from "ajv";
import classNames from "classnames";
import React, { useCallback, useEffect, useMemo, useRef, useState, FocusEvent } from "react";
import { useTranslation } from "react-i18next";
import { TextAreaInput, type ITextAreaProps } from "components/ui/TextAreaInput";
import { generateAjv } from "utils/ajvUtils";
import { useStyles } from "./styles";
import type { TJsonSchema } from "types/utilTypes";

interface IProps {
	onBlur?: (data: { e: FocusEvent<HTMLTextAreaElement>; value: Record<string, unknown> }) => void;
	onChange?: (data: Record<string, unknown>) => void;
	onError?: (errors: Partial<ErrorObject>[] | null) => void;
	validationSchema?: TJsonSchema;
	value?: Record<string, unknown> | null;
}

type TProps = Omit<ITextAreaProps, "onBlur" | "onChange" | "onError" | "value"> & IProps;

export const JSONTextArea: FC<TProps> = ({
	className,
	onBlur,
	onChange: userOnChange,
	onError: userOnError,
	textAreaClassName,
	validationSchema,
	value: userValue,
	...restTextAreaProps
}) => {
	const { t } = useTranslation();
	const [value, setValue] = useState<string>(JSON.stringify(userValue || {}, null, 2));
	const ajv = useMemo(() => generateAjv({ strictSchema: false }), []);
	const classes = useStyles();
	const textArea = useRef<HTMLTextAreaElement>(null);

	const onJsonError = useCallback(
		(error: Error) => {
			if (userOnError && error?.name === "SyntaxError") {
				userOnError([{ keyword: "syntax", message: t("common.invalidJson") }]);
			}
		},
		[t, userOnError]
	);

	const onChange = useCallback(
		(newValue: string) => {
			setValue(newValue);
			try {
				const json = JSON.parse(newValue);

				if (userOnChange) {
					userOnChange(json);
				}
				if (userOnError) {
					if (validationSchema) {
						try {
							const valid = ajv.validate(validationSchema, json);
							userOnError(valid ? null : ajv.errors || null);
						} catch (_error) {
							throw new SyntaxError("failed to validate JSON");
						}
					} else {
						userOnError(null);
					}
				}
			} catch (error) {
				onJsonError(error as Error);
			}
		},
		[userOnChange, userOnError, validationSchema, ajv, onJsonError]
	);

	useEffect(() => {
		if (userValue) {
			setValue(JSON.stringify(userValue, null, 2));
		}
	}, [userValue]);

	const format = useCallback(
		(e: FocusEvent<HTMLTextAreaElement>) => {
			try {
				const json = JSON.parse(value);
				const newValue = JSON.stringify(json, null, 2);
				setValue(newValue);
				if (onBlur) {
					onBlur({ e, value: json });
				}
			} catch (error) {
				onJsonError(error as Error);
			}
		},
		[onBlur, value, onJsonError]
	);

	return (
		<TextAreaInput
			className={className}
			inputRef={textArea}
			onBlur={format}
			onValueChange={onChange}
			spellCheck={false}
			textAreaClassName={textAreaClassName ? classNames(classes.textArea, textAreaClassName) : classes.textArea}
			value={value}
			{...restTextAreaProps}
		/>
	);
};
