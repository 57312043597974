import constate from "constate";
import { useCallback } from "react";
import {
	getTasks,
	setTaskStatus as apiSetTaskStatus,
	archiveTask as apiArchiveTask,
	archiveDone as apiArchiveDone
} from "api/tasks";
import { useFetchedState } from "hooks/useFetchedState";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { useLoadingState } from "hooks/useLoadingState";
import type { TTaskStatus } from "models/TaskModel";

const useTasks = () => {
	const {
		data: tasks,
		setData: setTasks,
		loadData: loadTasks,
		retryAction: reloadTasks,
		isLoading: tasksIsLoading
	} = useFetchedState(getTasks);
	const openGlobalErrorModal = useOpenGlobalErrorModal();
	const { isLoading: taskStatusIsLoading, withLoader: taskStatusWithLoader } = useLoadingState();
	const { isLoading: archiveTaskIsLoading, withLoader: archiveTaskWithLoader } = useLoadingState();
	const { isLoading: archiveAllDoneTasksIsLoading, withLoader: archiveAllDoneTasksWithLoader } = useLoadingState();

	const setTaskStatus = useCallback(
		async (id: string, status: TTaskStatus) => {
			try {
				const updatedTask = await taskStatusWithLoader(apiSetTaskStatus(id, status));
				setTasks(current => current?.set(updatedTask.id, updatedTask) || null);
			} catch (error) {
				openGlobalErrorModal(error as Error);
			}
		},
		[openGlobalErrorModal, setTasks, taskStatusWithLoader]
	);

	const archiveTask = useCallback(
		async (id: string) => {
			try {
				const updatedTask = await archiveTaskWithLoader(apiArchiveTask(id));
				setTasks(current => current?.delete(updatedTask.id) || null);
			} catch (error) {
				openGlobalErrorModal(error as Error);
			}
		},
		[archiveTaskWithLoader, openGlobalErrorModal, setTasks]
	);

	const archiveAllDoneTasks = useCallback(async () => {
		try {
			const updatedTasks = await archiveAllDoneTasksWithLoader(apiArchiveDone());
			setTasks(updatedTasks);
		} catch (error) {
			openGlobalErrorModal(error as Error);
		}
	}, [archiveAllDoneTasksWithLoader, openGlobalErrorModal, setTasks]);

	return {
		state: { tasks, tasksIsLoading, taskStatusIsLoading, archiveAllDoneTasksIsLoading, archiveTaskIsLoading },
		actions: { loadTasks, reloadTasks, setTaskStatus, archiveTask, archiveAllDoneTasks }
	};
};

export const [TasksProvider, useTasksContext] = constate(useTasks);
