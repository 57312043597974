import classnames from "classnames";
import React from "react";
import { Typography } from "components/ui/Typography";
import { useStyles } from "./styles";

interface IProps {
	header?: string;
	minimized: boolean;
}

export const Section: FC<IProps> = ({ header, children, minimized }) => {
	const classes = useStyles();

	return (
		<div className={classes.section}>
			{header && (
				<div className={classnames(classes.header, { [classes.minimized]: minimized })}>
					<div className={classes.innerHeader}>
						<Typography variant="text_sm_reg">{header}</Typography>
					</div>
				</div>
			)}
			{children}
		</div>
	);
};
