import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	tooltipTitle: {
		display: "flex",
		alignItems: "center",
		flexDirection: "row",
		gap: "var(--spacing-x2, 8px)"
	},
	permissionChip: {
		display: "flex",
		overflow: "hidden",
		gap: "var(--spacing-x2)",
		justifyContent: "center",
		alignItems: "center",
		width: "100%",
		height: "100%"
	},
	tooltipBodyContainer: {
		maxWidth: "70vw"
	}
});
