import { createUseStyles } from "react-jss";
import { BP_MEDIA_QUERIES } from "utils/ui/breakpoints";

const REQUEST_DURATION_WIDTH = 152;
const MIN_REQUEST_DURATION_WIDTH = 100;

export const useStyles = createUseStyles({
	container: {
		display: "none",
		gap: "8px",
		width: ({ fixedWidth }: { fixedWidth: boolean }) => (fixedWidth ? `${REQUEST_DURATION_WIDTH}px` : "fit-content"),
		maxWidth: `${REQUEST_DURATION_WIDTH}px`,
		minWidth: `${MIN_REQUEST_DURATION_WIDTH}px`,
		[BP_MEDIA_QUERIES.FORTY]: {
			display: "flex",
			alignItems: "flex-start",
			flexDirection: "column",
			justifyContent: "flex-start"
		}
	},
	duration: {
		alignItems: "center",
		display: "flex",
		flexWrap: "nowrap",
		gap: "4px"
	}
});
