import { List } from "immutable";
import React, { useCallback, useMemo } from "react";
import { Form } from "components/ui/Form";
import { SearchInput } from "./components/SearchInput";
import { useTabState } from "../../hooks/useTabState";
import type { TNewTicketOption } from "../../types";

export const NewTicketBySearchForm: FC = () => {
	const { receiverUser, selectedItems, setSelectedItems, getIsItemConflicted } = useTabState();
	const selectedItemsArray = useMemo(() => selectedItems.toArray(), [selectedItems]);
	const setSelectedItemsArray = useCallback(
		(newValue: TNewTicketOption[] | null) => {
			setSelectedItems(List(newValue ?? []));
		},
		[setSelectedItems]
	);

	const isItemConflicted = useMemo(() => getIsItemConflicted(), [getIsItemConflicted]);

	if (receiverUser === null) return <></>;

	return (
		<Form.Field>
			<SearchInput
				isItemConflicted={isItemConflicted}
				setSelectedItems={setSelectedItemsArray}
				selectedItems={selectedItemsArray}
				userId={receiverUser.id}
			/>
		</Form.Field>
	);
};
