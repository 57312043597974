import constate from "constate";
import { ReactNode, useCallback, useMemo, useRef, useState } from "react";
import type { TStep } from "./types";

type TStepperActions = {
	next?: ReactNode;
	back?: ReactNode;
	complete?: ReactNode;
};

export type TStepperContextProps = {
	activeStepIndex?: number;
	canContinue?: boolean;
	footerActions?: TStepperActions;
	steps?: TStep[];
	onComplete?: () => void;
};

const useStepper = ({
	activeStepIndex: activeStepIndexProp = 0,
	canContinue: canContinueProp = false,
	footerActions: footerActionsProp = {},
	onComplete: onCompleteProp,
	steps: stepsProp = []
}: TStepperContextProps) => {
	const [activeStepIndex, setActiveStepIndex] = useState(activeStepIndexProp);
	const [canContinue, setCanContinue] = useState(canContinueProp);
	const [footerActions, setFooterActions] = useState<TStepperActions>(footerActionsProp);
	const [footerActionsLabels, setFooterActionsLabels] = useState<TStepperActions>();
	const [onComplete, setOnComplete] = useState(onCompleteProp ? () => onCompleteProp : undefined);
	const [steps, setSteps] = useState<TStep[]>(stepsProp);
	const stepperContainerRef = useRef<HTMLDivElement>(null);

	const goToStep = useCallback(
		(stepIndex: number) => {
			const stepToSet = Math.min(Math.max(stepIndex, 0), steps.length - 1);
			setActiveStepIndex(stepToSet);
		},
		[steps.length]
	);

	const nextStep = useCallback(() => {
		if (canContinue) {
			setActiveStepIndex(current => Math.min(current + 1, steps.length - 1));
			setCanContinue(false);
		}
	}, [canContinue, steps.length]);

	const prevStep = useCallback(() => {
		setActiveStepIndex(current => Math.max(current - 1, 0));
	}, []);

	const setFooterAction = useCallback((type: keyof TStepperActions, value?: ReactNode) => {
		setFooterActions(current => ({ ...current, [type]: value }));
	}, []);

	const setFooterActionLabel = useCallback(
		(type: keyof TStepperActions, value: ReactNode) =>
			setFooterActionsLabels(current => ({ ...current, [type]: value })),
		[]
	);

	return useMemo(
		() => ({
			state: { steps, activeStepIndex, canContinue, footerActions, stepperContainerRef, footerActionsLabels },
			actions: {
				goToStep,
				nextStep,
				onComplete,
				prevStep,
				setCanContinue,
				setFooterAction,
				setFooterActionLabel,
				setOnComplete,
				setSteps
			}
		}),
		[
			activeStepIndex,
			canContinue,
			footerActions,
			footerActionsLabels,
			goToStep,
			nextStep,
			onComplete,
			prevStep,
			setFooterAction,
			setFooterActionLabel,
			steps
		]
	);
};

export const [StepperProvider, useStepperContext] = constate(useStepper);
