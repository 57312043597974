import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { RequestDetails } from "components/common/RequestDetails";
import { SubordinatePermissionsReviewTable } from "components/common/SubordinatePermissionsReviewTable";
import { PageTemplate } from "components/templates/PageTemplate";
import { Typography } from "components/ui/legacy/Typography";
import { Loading } from "components/ui/Loading";
import { useAccessReviewsContext } from "context/accessReviewsContext";
import { useAccessReviewReport } from "hooks/useAccessReviewReport";
import { useAccessReviewSubordinate } from "hooks/useAccessReviewSubordinate";
import { useAsyncImpact } from "hooks/useAsyncImpact";
import { useLoadingState } from "hooks/useLoadingState";
import { useUser } from "hooks/useUser";
import { useStyles } from "./styles";

export const SubordinateAccessReportPage: FC = ({ className }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const { subordinateId } = useParams<{ subordinateId: string }>();
	const navigate = useNavigate();
	const {
		accessReviewSubordinate,
		isGetLoading: subordinateLoading,
		getAccessReviewSubordinate
	} = useAccessReviewSubordinate(subordinateId);
	const { accessReviewReport, getActionLoading: reporterLoading } = useAccessReviewReport(
		accessReviewSubordinate?.accessReviewReportId
	);
	const {
		state: { accessReviews },
		actions: { loadAccessReviews }
	} = useAccessReviewsContext();

	const subordinateUser = useUser(accessReviewSubordinate?.userId);

	const { withLoader, isLoading: accessReviewsLoading } = useLoadingState();

	useAsyncImpact(async () => {
		if (!accessReviews && !accessReviewsLoading) {
			await withLoader(loadAccessReviews());
		}
	}, [accessReviews, accessReviewsLoading, loadAccessReviews, withLoader]);

	useAsyncImpact(async () => {
		if (subordinateId) await getAccessReviewSubordinate();
	}, [getAccessReviewSubordinate, subordinateId]);

	const sortedSubordinates = useMemo(() => accessReviewReport?.sortedSubordinates || [], [accessReviewReport]);

	const onChangeSubordinate = useCallback(
		(subordinateId: string) => {
			if (subordinateId) {
				navigate(`/accessReport/${accessReviewReport?.accessReviewId}/subordinates/${subordinateId}`);
			}
		},
		[accessReviewReport?.accessReviewId, navigate]
	);

	const currentAccessReview = accessReviews?.get(accessReviewReport?.accessReviewId || "");
	const reviewDone = currentAccessReview ? currentAccessReview.status === "done" : null;

	const getContent = useCallback(() => {
		if (currentAccessReview && accessReviewSubordinate && accessReviewReport) {
			return (
				<div className={classes.permissionList}>
					<SubordinatePermissionsReviewTable
						report={accessReviewReport}
						onChangeSubordinate={onChangeSubordinate}
						sortedSubordinates={sortedSubordinates}
						subordinate={accessReviewSubordinate}
						isDone={reviewDone || undefined}
						immediateRevoke={currentAccessReview.immediateRevoke}
					/>
				</div>
			);
		}
		return (
			<Typography className={classes.center} variant="h2">
				{t("pages.subordinateReview.subordinateNotFound")}
			</Typography>
		);
	}, [
		accessReviewReport,
		accessReviewSubordinate,
		classes,
		currentAccessReview,
		onChangeSubordinate,
		reviewDone,
		sortedSubordinates,
		t
	]);

	const breadcrumbs = useMemo(() => {
		if (!accessReviewReport || !subordinateUser || !currentAccessReview) return [];

		return [
			{
				title: t("pages.subordinateReview.accessReview", {
					name: currentAccessReview.name,
					date: accessReviewReport.createdAt
				}),
				url: `/accessReport/${accessReviewReport.accessReviewId}`
			},
			{
				title: subordinateUser.fullName
			}
		];
	}, [accessReviewReport, currentAccessReview, subordinateUser, t]);

	return (
		<PageTemplate className={className} subPage>
			<RequestDetails />
			<PageTemplate.Title className={classes.header}>
				<Breadcrumbs parts={breadcrumbs} />
			</PageTemplate.Title>
			<PageTemplate.Content className={classes.content}>
				<Loading loading={subordinateLoading || reporterLoading || accessReviewsLoading} render={getContent} />
			</PageTemplate.Content>
		</PageTemplate>
	);
};
