import { Record } from "immutable";

const defaults = {
	createdAt: new Date(0),
	expiresAt: null as Date | null,
	id: "",
	name: "",
	value: undefined as string | undefined
};

export class PersonalAccessTokenModel extends Record<typeof defaults>(defaults) {
	static fromServerData(data: unknown) {
		const { createdAt, expiresAt, id, name, value } = data as typeof defaults;
		return new PersonalAccessTokenModel({
			createdAt: new Date(createdAt),
			expiresAt: expiresAt ? new Date(expiresAt) : null,
			id,
			name,
			value
		});
	}
}
