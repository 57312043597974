import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { GoogleLogo } from "components/ui/systemLogos/GoogleLogo";
import { compiledClientConfig } from "config";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { openCenteredPopup } from "utils/ui/openCenteredPopup";
import { BaseLoginButton } from "../BaseLoginButton";

const LOGIN_URL = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${compiledClientConfig.googleLoginClientId}&redirect_uri=${window.location.protocol}//${window.location.host}/api/v1/signIn/google&response_type=code&scope=openid%20profile%20email`;

export const GoogleLoginButton: FC = () => {
	const { t } = useTranslation();
	const openGlobalErrorModal = useOpenGlobalErrorModal();

	const login = useCallback(() => {
		const popupWindow = openCenteredPopup({
			url: LOGIN_URL,
			width: 503,
			height: 553,
			title: t("pages.signIn.sso.google.signInWith")
		});

		if (!popupWindow) {
			openGlobalErrorModal(new Error("Cannot open popup window for google login"));
		}
	}, [t, openGlobalErrorModal]);

	return <BaseLoginButton onClick={login} text={t("pages.signIn.sso.google.signInWith")} Logo={GoogleLogo} />;
};
