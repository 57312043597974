import React from "react";
import { ChipContainer, type TChipContainerProps } from "components/ui/ChipContainer";
import { useStyles } from "./styles";
import type { IRowOptions } from "../../types";

const ChipContainerCellContent: FC<TChipContainerProps & IRowOptions> = ({ size, ...props }) => {
	const classes = useStyles();
	return (
		<div className={classes.centeredCellContent}>
			<ChipContainer type="static" size={size} {...props} />
		</div>
	);
};

const ChipContainerCellContentMemo = React.memo(ChipContainerCellContent) as typeof ChipContainerCellContent;

export { ChipContainerCellContentMemo as ChipContainerCellContent };
