import { List } from "immutable";
import React, { useMemo, useCallback } from "react";
import { useWorkflowEditorContext } from "context/workflowEditorContext";
import { ApprovalFlowModel } from "models/ApprovalFlowModel";
import { ApprovalFlowRequestModel } from "models/ApprovalFlowRequestModel";
import { NotNull } from "types/utilTypes";
import {
	TEntityOption,
	entityOptionToApprovalFlowEntity,
	entityOptionToApprovalFlowNotifiedEntity
} from "utils/entityOptionType";
import { ShouldBeApprovedEditable } from "../ShouldBeApproved";

const hasRequests = (
	approvalFlow: ApprovalFlowModel | undefined
): approvalFlow is NotNull<ApprovalFlowModel, "requests"> => Boolean(approvalFlow?.requests);

export const ApprovalFlowStepEditable: FC<{ index: number; approvalFlowRequest: ApprovalFlowRequestModel }> = ({
	index,
	approvalFlowRequest
}) => {
	const approvers = useMemo(() => approvalFlowRequest?.approvers?.toArray() || [], [approvalFlowRequest?.approvers]);
	const notified = useMemo(
		() => approvalFlowRequest?.notified.map(entity => entity.toApprovalEntity()).toArray() || [],
		[approvalFlowRequest?.notified]
	);
	const {
		actions: { updateFlow, getRuleFlowOrShowError, updateFlowRequest }
	} = useWorkflowEditorContext();
	const flow = getRuleFlowOrShowError(index)?.[1];

	const canRemove = useMemo(() => hasRequests(flow) && flow.requests.size > 1, [flow]);
	const canMakeAutomatic = useMemo(() => flow?.requests?.size === 1, [flow?.requests]);

	const removeStep = useCallback(() => {
		if (!canRemove || !hasRequests(flow)) {
			return;
		}
		const updatedRequests = flow.requests
			.filter(({ sortOrder }) => sortOrder !== approvalFlowRequest.sortOrder)
			.map(request => {
				if (request.sortOrder >= approvalFlowRequest.sortOrder) {
					return request.set("sortOrder", request.sortOrder - 1);
				}
				return request;
			});
		updateFlow(index, { requests: updatedRequests });
	}, [approvalFlowRequest.sortOrder, canRemove, flow, index, updateFlow]);

	const updateRequest = useCallback(
		(updatedRequest: ApprovalFlowRequestModel) => {
			updateFlowRequest(index, updatedRequest);
		},
		[index, updateFlowRequest]
	);

	const onChangeApprovers = useCallback(
		(newApprovers: TEntityOption[] | null) => {
			if (newApprovers) {
				const automaticApproverIndex = newApprovers.findIndex(({ type }) => type === "Automatic");
				if (automaticApproverIndex >= 0) {
					if (automaticApproverIndex + 1 === newApprovers.length) {
						const approver = newApprovers.at(automaticApproverIndex) as TEntityOption;
						updateRequest(approvalFlowRequest.set("approvers", List([entityOptionToApprovalFlowEntity(approver)])));
					} else {
						updateRequest(
							approvalFlowRequest.set(
								"approvers",
								List(newApprovers.filter(({ type }) => type !== "Automatic").map(entityOptionToApprovalFlowEntity))
							)
						);
					}
				} else {
					updateRequest(approvalFlowRequest.set("approvers", List(newApprovers.map(entityOptionToApprovalFlowEntity))));
				}
			} else {
				updateRequest(approvalFlowRequest.set("approvers", List()));
			}
		},
		[approvalFlowRequest, updateRequest]
	);

	const onChangeNotified = useCallback(
		(newNotifiedEntities: TEntityOption[]) => {
			updateRequest(
				approvalFlowRequest.set("notified", List(newNotifiedEntities).map(entityOptionToApprovalFlowNotifiedEntity))
			);
		},
		[approvalFlowRequest, updateRequest]
	);

	const onChangeOperator = useCallback(
		(operator: "and" | "or") => {
			const updatedRequest = approvalFlowRequest.set("operator", operator);
			updateRequest(updatedRequest);
		},
		[approvalFlowRequest, updateRequest]
	);

	return (
		<ShouldBeApprovedEditable
			approvalEntities={approvers}
			canMakeAutomatic={canMakeAutomatic}
			notifiedEntities={notified}
			onChangeApprovers={onChangeApprovers}
			onChangeNotified={onChangeNotified}
			onChangeOperator={onChangeOperator}
			onRemove={canRemove ? removeStep : undefined}
			operator={approvalFlowRequest.operator}
		/>
	);
};
