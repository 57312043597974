import { useCallback, useState } from "react";
import { getAccessReviewPermissions } from "api/accessReviews";
import { usePagination } from "./usePagination";
import type { TAccessReviewPermissionStatus } from "models/AccessReviewPermissionModel";
import type { IPaginatedSearchOptions } from "utils/searchUtils";

type TStatusOption = TAccessReviewPermissionStatus | "all";
type TAccessReviewPermissionsOptions = {
	status?: TStatusOption;
	managed?: boolean;
	distinct?: boolean;
};

const PER_PAGE = 5;
const ALL_OPTION = "all";

export const useAccessReviewPermissions = (accessReviewId: string, options: TAccessReviewPermissionsOptions = {}) => {
	const { status = ALL_OPTION, managed, distinct } = options;
	const [statusFilter, setStatusFilter] = useState<TStatusOption>(status);

	const fetchSubjectPermissions = useCallback(
		async (paginationOptions: IPaginatedSearchOptions) => {
			return getAccessReviewPermissions(accessReviewId, {
				paginationOptions,
				status: statusFilter === ALL_OPTION ? undefined : statusFilter,
				managed,
				distinct
			});
		},
		[accessReviewId, distinct, managed, statusFilter]
	);

	const {
		page,
		items,
		lastPage,
		lastPageNumber,
		setCurrentPageNumber,
		currentPageNumber,
		totalPages,
		getPage,
		isLoading,
		totalResults
	} = usePagination({ fetchItems: fetchSubjectPermissions, perPage: PER_PAGE });

	return {
		currentPageNumber,
		isLoading,
		lastPage,
		lastPageNumber,
		page,
		items,
		getPage,
		setCurrentPageNumber,
		setStatusFilter,
		statusFilter,
		totalPages,
		totalResults
	};
};
