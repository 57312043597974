import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getIntegrationResourceRoles, TFullIntegrationResourceRole } from "api/integrationResourceRoles";
import { FilterExpressionEmptyState } from "components/common/FilterExpressionEmptyState";
import { RoleChip } from "components/ui/chips/RoleChip";
import { FilterExpression } from "components/ui/filters/FilterExpression";
import { RoleIcon } from "components/ui/Icons/RoleIcon";
import { RoleOption } from "components/ui/selectOptions/RoleOption";
import { IntegrationResourceRoleIdFilter } from "filters/integrationResourceRole";
import { useIntegrations } from "hooks/useIntegrations";
import { getIntegrationResourceRoleFilters } from "utils/api/filters";
import { notEmpty } from "utils/comparison";
import { getLabel, type TOptionComponent } from "utils/ui/select";
import { useFetchModels, useFilterFormExpression, useInitialLoading } from "./filter.hooks";
import type { TFilterOperator } from "types/filters";

type TIntegrationResourceIdFilterProps = {
	filter: IntegrationResourceRoleIdFilter;
	onChange: (filter: IntegrationResourceRoleIdFilter | undefined, isValid: boolean) => void;
};

const isValidOperator = (operator: TFilterOperator): operator is IntegrationResourceRoleIdFilter["operator"] =>
	operator === "is" || operator === "isNot";

const OPERATORS = ["is", "isNot"] as TFilterOperator[];

const getFilter = () => IntegrationResourceRoleIdFilter;

export const RoleIdFilterExpression: FC<TIntegrationResourceIdFilterProps> = ({
	className,
	innerRef,
	filter,
	onChange
}) => {
	const { t } = useTranslation("translation", { keyPrefix: "pages.bulkActions.filters" });
	const { clearFilter, removeFilter } = useFilterFormExpression<IntegrationResourceRoleIdFilter>({
		filterName: filter.name,
		onChange,
		getFilter
	});

	const {
		selectedModels: selectedRolesModels,
		isInitialLoading,
		setSelectedModels: setSelectedRolesModels
	} = useInitialLoading({
		fetch: getIntegrationResourceRoles,
		fetchOptions: {
			filters: getIntegrationResourceRoleFilters({ id: filter.value }),
			perPage: filter.value.length
		},
		canFetch: !!filter?.value.length
	});

	const { items, isLoading, setSearchQuery } = useFetchModels({
		fetch: getIntegrationResourceRoles,
		getFilters: getIntegrationResourceRoleFilters
	});

	const integrations = useIntegrations();

	const roleOptions = useMemo(() => {
		return items?.toArray() || [];
	}, [items]);

	const selectedRoles = useMemo(() => {
		if (!filter) return [];
		return filter.value.map(id => selectedRolesModels.get(id)).filter(notEmpty);
	}, [filter, selectedRolesModels]);

	const onOperatorSelect = useCallback(
		(operator: TFilterOperator) => {
			if (!filter) return;
			if (!isValidOperator(operator)) return;
			onChange(filter.set("operator", operator), filter.value.length > 0);
		},
		[filter, onChange]
	);

	const onOptionSelect = useCallback(
		(option: TFullIntegrationResourceRole) => {
			if (!filter || !option) return;
			const currentValue = filter.value;
			const newValue = currentValue.includes(option.id)
				? currentValue.filter(id => id !== option.id)
				: [...currentValue, option.id];
			onChange(filter.set("value", newValue), newValue.length > 0);
			setSelectedRolesModels(prev => prev.set(option.id, option));
		},
		[filter, onChange, setSelectedRolesModels]
	);

	const renderSelected = useCallback(
		(option: TFullIntegrationResourceRole) => (
			<RoleChip size="large" integrationResourceRole={option} selected onDelete={() => onOptionSelect(option)} />
		),
		[onOptionSelect]
	);

	return (
		<FilterExpression
			className={className}
			emptyState={<FilterExpressionEmptyState text={t("values.emptyRoles")} Icon={RoleIcon} />}
			filter={null}
			getMoreOptions={setSearchQuery}
			getOptionLabel={getLabel}
			innerRef={innerRef}
			inputPlaceholder={t("placeholders.roleId")}
			isLoading={!integrations || isInitialLoading || isLoading}
			onOperatorSelect={onOperatorSelect}
			onOptionSelect={onOptionSelect}
			onRemoveFilter={removeFilter}
			onReset={clearFilter}
			operators={OPERATORS}
			optionRenderer={RoleOption as TOptionComponent<TFullIntegrationResourceRole>}
			options={roleOptions}
			renderSelected={renderSelected}
			selected={selectedRoles}
			selectedOperator={filter.operator}
			title={t(`title.${filter.name}`)}
			type="multiSelect"
		/>
	);
};
