import { Record } from "immutable";
import uniq from "lodash/uniq";
import { validateFilterInclusionOperator, validateFilterRelation } from "utils/validation/filterValidation";
import type { IFilter, TFilterOperator } from "types/filters";

export const OPERATORS = ["contains", "notContains"] as const satisfies TFilterOperator[];

const filterName = "integrationResourceRoleName";

const defaults = {
	name: filterName as typeof filterName,
	value: [] as string[],
	operator: "contains" as (typeof OPERATORS)[number],
	relation: "or" as "and" | "or"
};

type TDefaults = typeof defaults;

export class IntegrationResourceRoleNameFilter
	extends Record<TDefaults>(defaults)
	implements IFilter<TDefaults["value"], TDefaults["operator"]>
{
	static filterName: typeof filterName = filterName;

	static fromURLSearchParams(urlSearchParams: URLSearchParams): IntegrationResourceRoleNameFilter | null {
		const integrationResourceRoleName = uniq(urlSearchParams.getAll("integrationResourceRoleName"));
		const integrationResourceRoleNameOperator = urlSearchParams.get("integrationResourceRoleNameOperator") || undefined;
		const integrationResourceRoleNameRelation = urlSearchParams.get("integrationResourceRoleNameRelation") || undefined;
		if (
			integrationResourceRoleName.length === 0 ||
			!validateFilterInclusionOperator(integrationResourceRoleNameOperator) ||
			!validateFilterRelation(integrationResourceRoleNameRelation)
		) {
			return null;
		}
		return new IntegrationResourceRoleNameFilter({
			value: integrationResourceRoleName,
			operator: integrationResourceRoleNameOperator,
			relation: integrationResourceRoleNameRelation
		});
	}

	toURLSearchParams(): URLSearchParams {
		const params = new URLSearchParams();
		if (!this.value.length) {
			return params;
		}
		for (const value of uniq(this.value)) {
			params.append("integrationResourceRoleName", value);
		}
		params.append("integrationResourceRoleNameOperator", this.operator);
		params.append("integrationResourceRoleNameRelation", this.relation);
		return params;
	}
}

export type TIntegrationResourceRoleNameFilter = Omit<TDefaults, "name">;
