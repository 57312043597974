import React, { useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { Stepper } from "components/common/Stepper";
import { TransitionablePage } from "components/templates/TransitionablePage";
import { LoadingSpinner } from "components/ui/LoadingSpinner";
import { useIsOpenState } from "hooks/useIsOpenState";
import { useOnEscKeyDownEvent } from "hooks/useOnEscKeyDownEvent";
import { Conditions, RuleModel } from "models/RuleModel";
import { ApplyRuleStep } from "./components/ApplyRuleStep";
import { BuildRuleStep } from "./components/BuildRuleStep";
import { ExitUpsertRuleModal } from "./components/ExitUpsertRuleModal";
import { RuleStepperProvider } from "./ruleStepperContext";
import { RulesProvider, useRulesContext } from "../RulesPage/RulesContext";

export const RuleForm: FC<{ initialRule?: RuleModel }> = ({ className, initialRule }) => {
	const { open: openExitModal, close: closeExitModal, isOpen: isExitModalOpen } = useIsOpenState();

	const onExitClick = useCallback(() => {
		openExitModal();
	}, [openExitModal]);
	const {
		state: { isLoading }
	} = useRulesContext();

	useOnEscKeyDownEvent(onExitClick);

	const editMode = !!initialRule?.id;
	const steps = useMemo(() => {
		return [
			{
				index: 0,
				step: <BuildRuleStep onExit={onExitClick} editMode={editMode} />
			},
			{
				index: 1,
				step: <ApplyRuleStep onExit={onExitClick} />
			}
		];
	}, [onExitClick, editMode]);

	if (isLoading) return <LoadingSpinner size="big" />;
	return (
		<RuleStepperProvider rule={initialRule}>
			<TransitionablePage>
				<ExitUpsertRuleModal editMode={editMode} close={closeExitModal} isOpen={isExitModalOpen} />
				<Stepper steps={steps} className={className} />
			</TransitionablePage>
		</RuleStepperProvider>
	);
};

export const CreateRulePage: FC = ({ className }) => {
	const location = useLocation();
	const { integrationId } = (location.state ?? {}) as { integrationId: string };
	const initialRule = useMemo(
		() =>
			integrationId
				? new RuleModel({
						conditions: new Conditions({
							integration: { value: [integrationId], operator: "is" }
						}),
						updates: {}
					})
				: undefined,
		[integrationId]
	);
	return (
		<RulesProvider>
			<RuleForm className={className} initialRule={initialRule} />
		</RulesProvider>
	);
};
