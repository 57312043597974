import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	chip: {
		display: "flex",
		alignItems: "center"
	},
	select: {
		marginLeft: "0.5rem",
		minWidth: "15rem"
	},
	title: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		gap: "0.5rem",
		width: "100%"
	},
	titleActions: {
		display: "flex",
		alignItems: "center",
		gap: "0.5rem"
	},
	entitiesContainer: {
		alignItems: "center",
		backgroundColor: "var(--surface-content-background-color)",
		borderRadius: "var(--border-radius-sharp)",
		boxShadow: "0px 0px 0px 1px rgb(0 0 0 / 30%)",
		display: "grid",
		gridTemplateColumns: "auto 1fr 7fr",
		gap: "var(--spacing-x2, 8px)",
		padding: "0.5em",
		whiteSpace: "pre",
		"& > *:first-child": {
			marginRight: "0.5rem"
		}
	},
	labelContainer: {
		maxWidth: "6rem"
	}
});
