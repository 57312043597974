import { set } from "lodash";
import { apiReq } from "utils/api/apiReq";
import type { TFilterName } from "filters";
import type { IFilter } from "types/filters";

type TMaintainer = {
	id: string;
	type: "directoryGroup" | "user";
};

type TMaintainerUpdate = {
	replace?: TMaintainer[];
	add?: TMaintainer[];
	remove?: TMaintainer[];
};

type TFilterBody = Partial<Record<TFilterName, Pick<IFilter, "value" | "operator">[]>>;

export type TIntegrationsUpdates = {
	allowsRequests?: boolean;
	defaultApprovalAlgorithmId?: string;
	maintainers?: TMaintainerUpdate;
	ownerUserId?: string;
};

export type TIntegrationResourcesUpdates = {
	allowsRequests?: boolean;
	approvalAlgorithmId?: string;
	maintainers?: TMaintainerUpdate;
	ownerUserId?: string;
};

export type TIntegrationResourceRoleUpdates = {
	allowsRequests?: boolean;
	approvalAlgorithmId?: string;
};

type TPartialFilters = Partial<Record<TFilterName, IFilter[]>>;

const objectifyFilters = (filters: TPartialFilters) => {
	return Object.entries(filters).reduce<TFilterBody>((acc, [filterName, filters]) => {
		return set(acc, filterName as TFilterName, filters?.map(({ value, operator }) => ({ value, operator })) ?? []);
	}, {});
};

export async function bulkEditIntegrations(filters: TPartialFilters, updates: TIntegrationsUpdates): Promise<void> {
	await apiReq("PUT", "/v1/integrations/bulkEdit", { filters: objectifyFilters(filters), updates });
}

export async function bulkEditResources(
	filters: TPartialFilters,
	updates: TIntegrationResourcesUpdates
): Promise<void> {
	await apiReq("PUT", "/v1/integrationResources/bulkEdit", { filters: objectifyFilters(filters), updates });
}

export async function bulkEditRoles(filters: TPartialFilters, updates: TIntegrationResourceRoleUpdates): Promise<void> {
	await apiReq("PUT", "/v1/integrationResourceRoles/bulkEdit", { filters: objectifyFilters(filters), updates });
}
