import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	formContainer: {
		"&$formContainer$formContainer": {
			padding: "unset"
		},
		flex: "1 0 auto",
		maxWidth: "34rem",
		gap: "1rem"
	},
	newTicketForm: {
		display: "flex",
		flexDirection: "row",
		flexGrow: 1,
		gap: "5rem",
		margin: "unset"
	},
	wideForm: {
		maxWidth: "50rem"
	},
	tabs: {
		height: "auto"
	},
	tab: {
		padding: "0.5rem"
	}
});
