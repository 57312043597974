import classNames from "classnames";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { AccessReviewReportSubjectHeader } from "components/common/AccessReviewReportSubjectHeader";
import { Typography } from "components/ui/legacy/Typography";
import {
	SubordinatesPermissionsReviewContextProvider,
	useSubordinatePermissionsReviewContext
} from "context/subordinatePermissionReviewContext";
import { useMultiUsers } from "hooks/useMultiUsers";
import { AccessReviewReportModel, IReportItem } from "models/AccessReviewReportModel";
import { AccessReviewSubordinateModel } from "models/AccessReviewSubordinateModel";
import { UserModel } from "models/UserModel";
import { SubordinatePermissionsList } from "./components/SubordinatePermissionsList";
import { useStyles } from "./styles";

interface IProps {
	report: AccessReviewReportModel;
	onChangeSubordinate?: (subordinateId: string) => void;
	isAdmin?: boolean;
	isDone?: boolean;
	sortedSubordinates?: IReportItem[];
	subordinate: AccessReviewSubordinateModel;
	immediateRevoke: boolean;
}

const SubordinatePermissionsReviewTable: FC<Omit<IProps, "subordinate" | "report" | "reloadSubordinate">> = ({
	className,
	id,
	innerRef,
	onChangeSubordinate,
	isAdmin,
	isDone,
	sortedSubordinates,
	immediateRevoke
}) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const {
		state: { report, subordinate }
	} = useSubordinatePermissionsReviewContext();

	const subordinateIndex = useMemo(
		() => (subordinate ? sortedSubordinates?.findIndex(sub => sub.id === subordinate.id) : -1),
		[sortedSubordinates, subordinate]
	);
	const reportItemIds = useMemo(() => {
		if (!sortedSubordinates) return [];
		return sortedSubordinates.map(sub => sub.reportItemId);
	}, [sortedSubordinates]);

	const users = useMultiUsers(reportItemIds);

	const toPreviousSubordinate = useCallback(() => {
		if (subordinateIndex && subordinateIndex > 0) {
			onChangeSubordinate?.(sortedSubordinates![subordinateIndex - 1].id);
		}
	}, [onChangeSubordinate, sortedSubordinates, subordinateIndex]);

	const toNextSubordinate = useCallback(() => {
		if (
			sortedSubordinates &&
			subordinateIndex !== undefined &&
			subordinateIndex < (sortedSubordinates?.length || 0) - 1
		) {
			onChangeSubordinate?.(sortedSubordinates[subordinateIndex + 1].id);
		}
	}, [onChangeSubordinate, sortedSubordinates, subordinateIndex]);

	const previousSubordinate: UserModel | undefined = useMemo(() => {
		if (!subordinateIndex || !sortedSubordinates) {
			return undefined;
		}
		return sortedSubordinates && subordinateIndex > 0
			? users?.get(sortedSubordinates?.[subordinateIndex - 1].reportItemId)
			: undefined;
	}, [subordinateIndex, users, sortedSubordinates]);

	const nextSubordinate: UserModel | undefined = useMemo(() => {
		if (subordinateIndex === undefined || !sortedSubordinates) {
			return undefined;
		}
		return subordinateIndex < (sortedSubordinates?.length || 0) - 1
			? users?.get(sortedSubordinates[subordinateIndex + 1].reportItemId)
			: undefined;
	}, [subordinateIndex, users, sortedSubordinates]);

	const nextAction = useMemo(
		() => ({
			onClick: toNextSubordinate,
			disabled: !nextSubordinate,
			tooltip: nextSubordinate
				? t("common.subordinatePermissionsReviewTable.nextPerson", { name: nextSubordinate.fullName })
				: undefined
		}),
		[nextSubordinate, t, toNextSubordinate]
	);

	const previousAction = useMemo(
		() => ({
			onClick: toPreviousSubordinate,
			disabled: !previousSubordinate,
			tooltip: previousSubordinate
				? t("common.subordinatePermissionsReviewTable.previousPerson", { name: previousSubordinate.fullName })
				: undefined
		}),
		[previousSubordinate, t, toPreviousSubordinate]
	);

	if (!subordinate || !subordinate.accessReviewSubordinatePermissions) return null;

	return (
		<div className={classNames(classes.container, className)} id={id} ref={innerRef}>
			<AccessReviewReportSubjectHeader
				doneAmount={subordinate.notPendingPermissionsSize}
				next={nextAction}
				previous={previousAction}
				reporterUserId={report.userId}
				totalAmount={subordinate.permissionsSize}
				isAdmin={isAdmin}
				sortedReportSubjects={sortedSubordinates}
				subordinateUserId={subordinate.userId}
			/>
			{subordinate.permissionsSize === 0 ? (
				<Typography variant="h3" className={classes.emptyList}>
					{t("common.subordinatePermissionsReviewTable.noPermissions")}
				</Typography>
			) : (
				<SubordinatePermissionsList readonly={isAdmin || isDone} immediateRevoke={immediateRevoke} />
			)}
		</div>
	);
};

const SubordinatePermissionReviewTableWithProvider: FC<IProps> = ({ subordinate, report, ...componentProps }) => {
	return (
		<SubordinatesPermissionsReviewContextProvider
			subordinateId={subordinate.id}
			report={report}
			immediateRevoke={componentProps.immediateRevoke}>
			<SubordinatePermissionsReviewTable {...componentProps} />
		</SubordinatesPermissionsReviewContextProvider>
	);
};

export { SubordinatePermissionReviewTableWithProvider as SubordinatePermissionsReviewTable };
