import { useEffect, useRef, useState } from "react";
import requestRenderFrame from "utils/ui/requestRenderFrame";

/**
 * @param isOpen - The open state of the component
 * @param closeUnmountDelay - The delay in milliseconds to unmount the component after it's closed
 * @param animationFrameTimeout - The timeout in milliseconds to wait before rendering the component
 * @returns [shouldMount, shouldOpen] - A tuple of two booleans indicating whether the component should mount and open
 */
export default function useShouldMount(
	isOpen: boolean,
	closeUnmountDelay: number,
	animationFrameTimeout: number | null = 500
): [boolean, boolean] {
	const [shouldMount, setShouldMount] = useState(isOpen);
	const [shouldOpen, setShouldOpen] = useState(isOpen);
	const firstRenderHappenedRef = useRef(false);

	useEffect(() => {
		if (!firstRenderHappenedRef.current) {
			firstRenderHappenedRef.current = true;
			return () => void 0;
		}

		if (isOpen) {
			setShouldMount(true);

			const renderFrameHandle = requestRenderFrame(() => {
				setShouldOpen(true);
			}, animationFrameTimeout);

			return () => {
				renderFrameHandle.destroy();
			};
		} else {
			setShouldOpen(false);

			const unmountTimeoutHandle = window.setTimeout(() => {
				setShouldMount(false);
			}, closeUnmountDelay);

			return () => {
				window.clearTimeout(unmountTimeoutHandle);
			};
		}
	}, [animationFrameTimeout, closeUnmountDelay, isOpen]);

	return [shouldMount, shouldOpen];
}
