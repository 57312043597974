import React, { useMemo } from "react";
import { UserEntity } from "components/common/entities/UserEntity";
import { UserTooltip } from "components/common/UserTooltip";
import { Typography } from "components/ui/legacy/Typography";
import { Unknown } from "components/ui/Unknown";
import { UserModel } from "models/UserModel";

export const User: FC<{ user?: UserModel; minimized?: boolean }> = ({ user, minimized = false }) => {
	const content = useMemo(() => {
		if (!user) return null;
		return minimized ? (
			<UserTooltip user={user}>
				<Typography variant="small">{user.fullName}</Typography>
			</UserTooltip>
		) : (
			<UserEntity withIcon user={user} />
		);
	}, [minimized, user]);
	return <Unknown unknown={!user}>{content}</Unknown>;
};
