import classNames from "classnames";
import React, { useMemo } from "react";
import { useStyles } from "./styles";
import { TTooltipProps, Tooltip } from "../Tooltip";

type TTitleTooltipContentProps = { header?: React.ReactNode; body?: React.ReactNode };
type TProps = Omit<TTooltipProps, "content" | "clean"> & TTitleTooltipContentProps;

const TitleTooltipContent: FC<TTitleTooltipContentProps> = ({ header, body, className }) => {
	const classes = useStyles();

	return (
		<div className={classNames(classes.container, className)}>
			<div className={classes.header}>{header}</div>
			<div className={classes.body}>{body}</div>
		</div>
	);
};

export const TitleTooltip: FC<TProps> = ({ className, children, innerRef, header, body, ...restProps }) => {
	const content = useMemo(() => {
		if (!header && !body) return null;
		return <TitleTooltipContent className={className} header={header} body={body} />;
	}, [body, className, header]);
	if (!content) return <>{children}</>;
	return (
		<Tooltip clean innerRef={innerRef} content={content} {...restProps}>
			{children}
		</Tooltip>
	);
};
