import classNames from "classnames";
import sortBy from "lodash/sortBy";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Select, type ISelectProps } from "components/ui/Select";
import { WorkflowOption } from "components/ui/selectOptions/WorkflowOption";
import { useApprovalAlgorithmsList } from "hooks/useApprovalAlgorithms";
import { useStyles } from "./styles";
import { WorkflowEntity } from "../entities/WorkflowEntity";
import type { ApprovalAlgorithmModel } from "models/ApprovalAlgorithmModel";

const getLabel = (option: ApprovalAlgorithmModel) => option?.name || "";
const equality = (option?: ApprovalAlgorithmModel, value?: ApprovalAlgorithmModel) => option?.id === value?.id;
const renderLabel = (option: ApprovalAlgorithmModel) =>
	option ? <WorkflowEntity withIcon size="small" approvalAlgorithm={option} /> : "";

const sortOptions = (options: ApprovalAlgorithmModel[]) => sortBy(options, getLabel);

const TRANSLATION_PREFIX = "common.approvalAlgorithmSelect";

type TApprovalAlgorithmSelectProps = Omit<
	ISelectProps<ApprovalAlgorithmModel>,
	"options" | "renderOption" | "renderLabel" | "getOptionLabel"
> & {
	getOptionLabel?: (option: ApprovalAlgorithmModel) => string;
	options?: ApprovalAlgorithmModel[];
};
export const ApprovalAlgorithmSelect: FC<TApprovalAlgorithmSelectProps> = ({
	className,
	innerRef,
	onChange,
	options: propOptions,
	placeholder: propsPlaceholder,
	...selectProps
}) => {
	const classes = useStyles();
	const { t } = useTranslation("translation", { keyPrefix: TRANSLATION_PREFIX });
	const approvalAlgorithms = useApprovalAlgorithmsList(false);

	const options = useMemo(() => {
		if (propOptions) return propOptions;
		return approvalAlgorithms ? approvalAlgorithms.toArray() : [];
	}, [propOptions, approvalAlgorithms]);

	const handleValueChange = useCallback(
		(approvalAlgorithm: ApprovalAlgorithmModel | null) => {
			onChange?.(approvalAlgorithm);
		},
		[onChange]
	);

	const placeholder = propsPlaceholder ?? t("placeholder");

	return (
		<div className={classNames(classes.container, className)} ref={innerRef}>
			<Select
				getOptionLabel={getLabel}
				isOptionEqualToValue={equality}
				onChange={handleValueChange}
				options={options}
				placeholder={placeholder}
				renderLabel={renderLabel}
				sort={sortOptions}
				{...selectProps}
				renderOption={WorkflowOption}
			/>
		</div>
	);
};
