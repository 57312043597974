import classNames from "classnames";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ResourceEntity } from "components/common/entities";
import { useGraphResources } from "components/pages/IdentityGraphPage/graphHooks";
import { Select } from "components/ui/Select";
import { ResourceOption } from "components/ui/selectOptions/ResourceOption";
import { IntegrationResourceModel } from "models/IntegrationResourceModel";
import { sortByName } from "utils/sortUtils";
import { useStyles } from "./styles";

type TBaseProps = {
	disabled?: boolean;
	integrationId: string | null;
	resourceType?: string | null;
};

type TIdProps = TBaseProps & {
	onChange: (resourceId: string | null) => void;
	value: string | null;
	byId: true;
};

type TValueProps = TBaseProps & {
	onChange: (resource: IntegrationResourceModel | null) => void;
	value: IntegrationResourceModel | null;
	byId?: false;
};
type TProps = TIdProps | TValueProps;

const isEqual = (option: IntegrationResourceModel, value: IntegrationResourceModel) => option.id === value.id;
const getLabel = (option: IntegrationResourceModel) => option.name;

export const ResourcesSelect: FC<TProps> = ({
	className,
	disabled,
	innerRef,
	onChange: propOnChange,
	value: propValue,
	integrationId,
	resourceType,
	byId
}) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const { resources, isLoading } = useGraphResources(integrationId, resourceType);
	const options = useMemo(() => (resources ? resources.valueSeq().toArray() : []), [resources]);

	const renderLabel = useCallback(
		(option: IntegrationResourceModel) => <ResourceEntity withIcon size="medium" resource={option} />,
		[]
	);

	const onChange = useCallback(
		(value: IntegrationResourceModel | null) => {
			if (byId) {
				propOnChange(value?.id ?? null);
			} else {
				propOnChange(value);
			}
		},
		[byId, propOnChange]
	);

	const value = useMemo(() => {
		if (byId) {
			return resources?.get(propValue || "") ?? null;
		} else {
			return propValue;
		}
	}, [byId, resources, propValue]);

	return (
		<Select
			className={classNames(classes.container, className)}
			disabled={disabled}
			getOptionLabel={getLabel}
			innerRef={innerRef}
			isOptionEqualToValue={isEqual}
			label={t("common.resourceSelectInput.label")}
			limit={20}
			loading={isLoading}
			onChange={onChange}
			renderOption={ResourceOption}
			options={options}
			placeholder={t("common.resourceSelectInput.placeholder")}
			renderLabel={renderLabel}
			sort={sortByName}
			value={value}
		/>
	);
};
