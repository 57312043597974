import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	divider: {
		position: "relative",
		minHeight: 0,
		minWidth: 0,
		"&:not($horizontal)": {
			height: "100%",
			minWidth: (props: { width: number }) => `${props.width}px`
		},
		"&$horizontal": {
			minHeight: (props: { width: number }) => `${props.width}px`,
			width: "100%"
		}
	},
	horizontal: {},
	svg: {
		position: "absolute",
		"&:not($horizontal)": {
			width: (props: { width: number }) => `${props.width}px`,
			height: "100%",
			top: "0",
			left: "50%",
			transform: "translateX(-50%)"
		},
		"&$horizontal": {
			width: "100%",
			height: (props: { width: number }) => `${props.width}px`,
			position: "absolute",
			top: "50%",
			left: "0",
			transform: "translateY(-50%)"
		}
	},
	rounded: {
		borderRadius: "15px"
	}
});
