import classNames from "classnames";
import React, { useCallback, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import {
	AccessReviewReportSubjectActions,
	IAccessReviewReportSubjectActionsProps
} from "components/common/AccessReviewReportSubjectActions";
import { ResourceName } from "components/common/ResourceName";
import { TextUser } from "components/common/TextUser";
import { Typography } from "components/ui/legacy/Typography";
import { Loading } from "components/ui/Loading";
import { Unknown } from "components/ui/Unknown";
import { useIntegrations } from "hooks/useIntegrations";
import { useUser } from "hooks/useUser";
import { useStyles } from "./styles";
import type { IntegrationResourceModel } from "models/IntegrationResourceModel";

interface ISubordinateProps {
	integrationResource?: never;
	subordinateUserId?: string;
}

interface IResourceProps {
	integrationResource?: IntegrationResourceModel;
	subordinateUserId?: never;
}

interface IProps extends IAccessReviewReportSubjectActionsProps {
	doneAmount: number;
	isAdmin?: boolean;
	reporterUserId: string;
	totalAmount: number;
}

type TProps = IProps & (ISubordinateProps | IResourceProps);

export const AccessReviewReportSubjectHeader: FC<TProps> = ({
	className,
	doneAmount,
	id,
	innerRef,
	integrationResource,
	isAdmin,
	next,
	previous,
	reporterUserId,
	sortedReportSubjects,
	subordinateUserId,
	totalAmount
}) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const integrations = useIntegrations();
	const subordinateUser = useUser(subordinateUserId);
	const integration = integrationResource ? integrations?.get(integrationResource.integrationId || "") : null;
	const reporter = useUser(reporterUserId);

	const SubjectNameComponent = useMemo(() => {
		if (subordinateUser) {
			return <TextUser user={subordinateUser} />;
		}
		if (integration) {
			return (
				<ResourceName
					integrationName={integration.name}
					size="medium"
					imageUrl={integration.imageUrl}
					name={integrationResource?.name}
				/>
			);
		}
		return null;
	}, [integration, subordinateUser, integrationResource]);

	const getName = useCallback(
		() => <Unknown unknown={!SubjectNameComponent}>{SubjectNameComponent}</Unknown>,
		[SubjectNameComponent]
	);

	if (!sortedReportSubjects || !sortedReportSubjects.length) {
		return null;
	}
	return (
		<div className={classNames(classes.header, className)} id={id} ref={innerRef}>
			<div className={classes.headerTitle}>
				<Typography variant="h2">
					<Loading loading={integration === undefined && subordinateUser === undefined} render={getName} />
				</Typography>
				<div className={classes.spacer} />
				<Typography className={classes.greyText}>
					{t("common.accessReview.reportSubjectHeader.reviewedAmount", {
						amount: doneAmount,
						total: totalAmount
					})}
				</Typography>
				{isAdmin && (
					<>
						<div className={classes.spacer} />
						<Typography className={classes.greyText}>
							<Trans
								i18nKey="common.accessReview.reportSubjectHeader.reporter"
								components={{ user: <TextUser user={reporter} /> }}
							/>
						</Typography>
					</>
				)}
			</div>
			<AccessReviewReportSubjectActions sortedReportSubjects={sortedReportSubjects} next={next} previous={previous} />
		</div>
	);
};
