import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IntegrationResourceRolesTable } from "components/common/tables/IntegrationResourceRolesTable";
import { IntegrationResourcesTable } from "components/common/tables/IntegrationResourcesTable";
import { IntegrationsTable } from "components/common/tables/IntegrationsTable";
import { SORT_ORDERS, TABLES_SORT_FIELDS_TRANSLATION_MAP } from "components/common/tables/utils";
import { Button } from "components/ui/Button";
import { StaticChip } from "components/ui/chips/StaticChip";
import { DropdownSortButton } from "components/ui/DropdownSortButton";
import { FilterIcon } from "components/ui/Icons/FilterIcon";
import { Section } from "components/ui/Section";
import { Typography } from "components/ui/Typography";
import { useRerenders } from "hooks/useRerenders";
import { requirePropertyOf } from "utils/security";
import { useStyles } from "./styles";
import { useBulkActionsContext } from "../../bulkActions.context";
import {
	useIntegrationsTable,
	useIntegrationResourceRolesTable,
	useIntegrationResourcesTable
} from "../../bulkActions.hooks";
import { INTEGRATION, RESOURCE, ROLE } from "../../utils";
import type { TSortState } from "hooks/useSortState";
import type { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";
import type { IFilter } from "types/filters";
import type { Require } from "types/utilTypes";
import type { TBulkActionModels } from "../../types";

type TBaseProps = {
	toggleFilters: () => void;
	isFiltersOpen?: boolean;
};
type TIntegrationProps = TBaseProps & { type: typeof INTEGRATION };
type TResourceProps = TBaseProps & { type: typeof RESOURCE };
type TRoleProps = TBaseProps & { type: typeof ROLE };
type TProps = TIntegrationProps | TResourceProps | TRoleProps;
type TTableProps = {
	disabled?: boolean;
	filters: IFilter[];
	sortState: TSortState;
};

const getFiltersKey = (filters: IFilter[]) => filters.map(filter => filter.toURLSearchParams().toString()).join("&");
const getSortKey = (sortState: TSortState) => `${sortState.sortOrder}-${sortState.sortFields.join(",")}`;
const getUniqueTableKey = (filters: IFilter[], sortState: TSortState) =>
	`${getFiltersKey(filters)}-${getSortKey(sortState)}`;
const getModelEquality = (prevModel: TBulkActionModels, nextModel: TBulkActionModels) => prevModel.id === nextModel.id;

const BulkActionIntegrationsTable: FC<TTableProps> = ({ disabled, filters, sortState }) => {
	const {
		state: { integrationsBulkActionSelection },
		actions: { setTotalAmount, addOnDoneCallback }
	} = useBulkActionsContext();
	const { excludedItems, isAllSelected, toggleSelectAll, selectedItems, toggleItemSelection } =
		integrationsBulkActionSelection;
	const { items, remoteTotalItems, fetchPage, perPage, totalItems, clearData, setPartialItem, isLoading } =
		useIntegrationsTable(filters, {
			order: sortState.sortOrder,
			sortFields: sortState.sortFields
		});

	useEffect(() => {
		setTotalAmount(remoteTotalItems);
	}, [setTotalAmount, remoteTotalItems]);

	useEffect(() => {
		addOnDoneCallback("integrationTable", clearData);
	}, [addOnDoneCallback, clearData]);

	return (
		<IntegrationsTable
			isLoading={isLoading}
			disabled={disabled}
			excludedItems={excludedItems}
			fetchIntegrations={fetchPage}
			getIsEqual={getModelEquality}
			integrations={items}
			isAllSelected={isAllSelected}
			key={getUniqueTableKey(filters, sortState)}
			onIntegrationUpdate={setPartialItem}
			perPage={perPage}
			selectAll={toggleSelectAll}
			selectable
			selectableTrigger="checkbox"
			selectedItems={selectedItems}
			toggleRowSelection={toggleItemSelection}
			totalIntegrations={totalItems}
		/>
	);
};

const BulkActionIntegrationResourcesTable: FC<TTableProps> = ({ disabled, filters, sortState }) => {
	const {
		state: { resourcesBulkActionSelection },
		actions: { setTotalAmount, addOnDoneCallback }
	} = useBulkActionsContext();
	const { excludedItems, isAllSelected, toggleSelectAll, selectedItems, toggleItemSelection } =
		resourcesBulkActionSelection;
	const { items, remoteTotalItems, fetchPage, perPage, totalItems, clearData, setPartialItem, isLoading } =
		useIntegrationResourcesTable(filters, {
			order: sortState.sortOrder,
			sortFields: sortState.sortFields
		});

	useEffect(() => {
		setTotalAmount(remoteTotalItems);
	}, [setTotalAmount, remoteTotalItems]);

	useEffect(() => {
		addOnDoneCallback("resourcesTable", clearData);
	}, [addOnDoneCallback, clearData]);

	return (
		<IntegrationResourcesTable
			isLoading={isLoading}
			disabled={disabled}
			excludedItems={excludedItems}
			fetchIntegrationResources={fetchPage}
			getIsEqual={getModelEquality}
			integrationResources={items}
			isAllSelected={isAllSelected}
			key={getUniqueTableKey(filters, sortState)}
			onResourceUpdate={setPartialItem}
			perPage={perPage}
			selectAll={toggleSelectAll}
			selectable
			selectableTrigger="checkbox"
			selectedItems={selectedItems}
			toggleRowSelection={toggleItemSelection}
			totalIntegrationResources={totalItems}
		/>
	);
};

const BulkActionIntegrationResourceRolesTable: FC<TTableProps> = ({ disabled, filters, sortState }) => {
	const {
		state: { rolesBulkActionSelection },
		actions: { setTotalAmount, addOnDoneCallback }
	} = useBulkActionsContext();
	const { excludedItems, isAllSelected, toggleSelectAll, selectedItems, toggleItemSelection } =
		rolesBulkActionSelection;
	const { items, remoteTotalItems, fetchPage, perPage, totalItems, clearData, setPartialItem, isLoading } =
		useIntegrationResourceRolesTable(filters, {
			order: sortState.sortOrder,
			sortFields: sortState.sortFields
		});

	useEffect(() => {
		setTotalAmount(remoteTotalItems);
	}, [setTotalAmount, remoteTotalItems]);

	useEffect(() => {
		addOnDoneCallback("rolesTable", clearData);
	}, [addOnDoneCallback, clearData]);

	return (
		<IntegrationResourceRolesTable
			disabled={disabled}
			excludedItems={excludedItems as Require<IntegrationResourceRoleModel, "integrationResource">[]}
			fetchIntegrationResourceRoles={fetchPage}
			getIsEqual={getModelEquality}
			integrationResourceRoles={items}
			isAllSelected={isAllSelected}
			isLoading={isLoading}
			key={getUniqueTableKey(filters, sortState)}
			onRoleUpdate={setPartialItem}
			perPage={perPage}
			selectAll={toggleSelectAll}
			selectable
			selectableTrigger="checkbox"
			selectedItems={selectedItems as Require<IntegrationResourceRoleModel, "integrationResource">[]}
			toggleRowSelection={toggleItemSelection}
			totalIntegrationResourceRoles={totalItems}
		/>
	);
};

export const TableSection: FC<TProps> = ({ className, innerRef, toggleFilters, isFiltersOpen = false, type }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const { forceUpdate, rerenderCount } = useRerenders();

	const {
		actions: { addOnDoneCallback, removeOnDoneCallback },
		state: {
			bulkActionsSort,
			currentSelection,
			initialized,
			isBulkActionDone,
			isBulkActionInProgress,
			totalAmount,
			validIntegrationFilters,
			validResourceFilters,
			validRoleFilters
		}
	} = useBulkActionsContext();

	useEffect(() => {
		addOnDoneCallback("shouldRerender", () => {
			forceUpdate();
		});
		return () => {
			removeOnDoneCallback("shouldRerender");
		};
	}, [addOnDoneCallback, forceUpdate, removeOnDoneCallback]);

	const [currentSortState, currentFilters] = useMemo(() => {
		if (type === INTEGRATION) {
			return [bulkActionsSort.integrationSortState, validIntegrationFilters];
		}
		if (type === RESOURCE) {
			return [bulkActionsSort.resourceSortState, validResourceFilters];
		}
		return [bulkActionsSort.roleSortState, validRoleFilters];
	}, [bulkActionsSort, type, validIntegrationFilters, validResourceFilters, validRoleFilters]);

	const table = useMemo(() => {
		if (!initialized) return null;

		const disabled = isBulkActionInProgress || isBulkActionDone;

		if (type === INTEGRATION) {
			return (
				<BulkActionIntegrationsTable
					key={`integrations-${rerenderCount}`}
					disabled={disabled}
					filters={validIntegrationFilters}
					sortState={bulkActionsSort.integrationSortState}
				/>
			);
		}
		if (type === RESOURCE) {
			return (
				<BulkActionIntegrationResourcesTable
					key={`resources-${rerenderCount}`}
					disabled={disabled}
					filters={validResourceFilters}
					sortState={bulkActionsSort.resourceSortState}
				/>
			);
		}
		if (type === ROLE) {
			return (
				<BulkActionIntegrationResourceRolesTable
					key={`roles-${rerenderCount}`}
					disabled={disabled}
					filters={validRoleFilters}
					sortState={bulkActionsSort.roleSortState}
				/>
			);
		}
		return null;
	}, [
		bulkActionsSort.integrationSortState,
		bulkActionsSort.resourceSortState,
		bulkActionsSort.roleSortState,
		initialized,
		isBulkActionDone,
		isBulkActionInProgress,
		rerenderCount,
		type,
		validIntegrationFilters,
		validResourceFilters,
		validRoleFilters
	]);

	const sectionTitle = useMemo(() => {
		let titleText = t("pages.bulkActions.tableSection.title");
		let chipAmount = totalAmount;
		const { anySelected, getSelectedAmount } = currentSelection;
		if (anySelected) {
			titleText = t("pages.bulkActions.tableSection.selected");
			chipAmount = getSelectedAmount(totalAmount);
		}
		return (
			<div className={classes.sectionTitle}>
				<Typography variant="body_sb">{titleText}</Typography>
				<StaticChip size="small" variant="regular">
					{t("number", { value: chipAmount })}
				</StaticChip>
			</div>
		);
	}, [classes.sectionTitle, currentSelection, t, totalAmount]);

	const getSortOptions = useCallback(() => {
		const sortOrder = requirePropertyOf(SORT_ORDERS, type);
		const objectType = requirePropertyOf(TABLES_SORT_FIELDS_TRANSLATION_MAP, type);

		return sortOrder.map(label => {
			const sortValue = Object.entries(objectType).find(([key, value]) => value === label)?.[0] || "";
			return {
				label: t(`common.tables.sortFields.${label}`),
				value: sortValue
			};
		});
	}, [t, type]);

	const titleActions = useMemo(() => {
		const hasFilters = currentFilters.length > 0;
		const isSecondaryFilterButton = hasFilters || isFiltersOpen;

		const sortOptions = getSortOptions();

		return (
			<>
				<DropdownSortButton sortState={currentSortState} options={sortOptions} disabled={isBulkActionInProgress} />
				<Button
					disabled={isBulkActionInProgress}
					variant={isSecondaryFilterButton ? "secondary" : "primary"}
					size="medium"
					onClick={toggleFilters}
					prefix={<FilterIcon />}>
					{t("buttons.filters") + (hasFilters ? ` (${currentFilters.length})` : "")}
				</Button>
			</>
		);
	}, [
		currentFilters.length,
		isFiltersOpen,
		getSortOptions,
		currentSortState,
		isBulkActionInProgress,
		toggleFilters,
		t
	]);

	return (
		<Section className={className} innerRef={innerRef} title={sectionTitle} titleActions={titleActions} fullHeight>
			{table}
		</Section>
	);
};
