import React from "react";
import type { TTicketAuditLogAction } from "models/auditLogs/TicketAuditLogModel";

export const TICKET_AUDIT_LOG_REVERT_ACTIONS = [
	"AccessRequestTaskCreated",
	"AccessRequestPermissionReverted",
	"AccessRequestPermissionTryRevert",
	"AccessRequestPermissionRetryRevert"
] as TTicketAuditLogAction[];

export type TReasonWontRevoked = "permissionExistsForLongerTime" | "permissionDoesNotExist";

export const sortByCreatedAt = (
	a: { createdAt: string | Date },
	b: { createdAt: string | Date },
	order: "desc" | "asc" = "asc"
) => {
	if (order === "desc") {
		return new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf();
	}
	return new Date(a.createdAt).valueOf() - new Date(b.createdAt).valueOf();
};

// Trans requires a self closing component, so we use this one to wrap everything
const TransContent: FC<{ content?: React.ReactNode | null }> = ({ content }) => {
	return <>{content || null}</>;
};
export const TC = TransContent;
