import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	workflowsPage: {},
	workflowsContainer: {
		display: "flex",
		flexDirection: "column",
		gap: "2rem"
	},
	spinner: {
		margin: "auto"
	},
	titleContainer: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		"& > $titleActions": {
			display: "flex",
			alignItems: "center",
			gap: "var(--spacing-x3, 12px)"
		}
	},
	titleActions: {}
});
