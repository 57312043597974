import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	image: {
		height: "32px",
		width: "32px",
		borderRadius: "50%"
	},
	maxNodeWidth: {
		width: "200px"
	}
});
