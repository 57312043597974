import throttle from "lodash/throttle";
import { MutableRefObject, useEffect, useMemo, useRef } from "react";
import { devLog } from "utils/devtools/devLogging";

export interface ThrottleOptions {
	leading?: boolean;
	trailing?: boolean;
	maxWait?: number;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TAnyFunc = (...args: any[]) => any;

function useLatest<T>(value: T): MutableRefObject<T> {
	const ref = useRef(value);
	ref.current = value;

	return ref;
}

const isNotFunction = (fn: TAnyFunc) => {
	if (typeof fn !== "function") {
		devLog({ message: `useThrottleFn expected parameter is a function, got ${typeof fn}`, level: "warn" });
	}
};

const useUnmount = (fn: () => void) => {
	isNotFunction(fn);

	const fnRef = useLatest(fn);

	useEffect(
		() => () => {
			fnRef.current();
		},
		[fnRef]
	);
};

export function useThrottleFn<T extends TAnyFunc>(fn: T, wait = 1000, options?: ThrottleOptions) {
	isNotFunction(fn);

	const fnRef = useLatest(fn);

	const throttled = useMemo(
		() =>
			throttle(
				(...args: Parameters<T>): ReturnType<T> => {
					// eslint-disable-next-line @typescript-eslint/no-unsafe-return
					return fnRef.current(...args);
				},
				wait,
				options
			),
		[fnRef, options, wait]
	);

	useUnmount(() => {
		throttled.cancel();
	});

	return throttled;
}
