import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	accessRequestContainer: {
		alignItems: "center",
		backgroundColor: "var(--surface-content-background-color)",
		borderRadius: "var(--border-radius-sharp)",
		boxShadow: "0px 0px 0px 1px rgb(0 0 0 / 30%)",
		display: "grid",
		gap: "var(--spacing-x2, 8px)",
		gridTemplateColumns: "1fr 300px",
		padding: "0.5em",
		whiteSpace: "pre"
	},
	fieldTitle: {
		display: "flex",
		width: "6.5rem",
		flexShrink: 0,
		alignItems: "center",
		wordBreak: "break-word",
		whiteSpace: "pre",
		"& > *:first-child": {
			marginRight: "0.5rem"
		}
	},
	input: {
		flexShrink: 0,
		flexGrow: 1
	}
});
