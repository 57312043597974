import { SessionAuditLogModel } from "models/auditLogs/SessionAuditLogModel";
import { apiReq } from "utils/api/apiReq";
import { withPagination } from "utils/pagination";
import { getPaginatedSearchQueryString, IPaginatedSearchOptions } from "utils/searchUtils";
import { isStringArray } from "utils/strings";
import type { DateRange } from "react-day-picker";
import type { TTimezone } from "utils/auditLogs/systemAuditLogTimezone";

export const getPaginatedSessionAuditLogs = (
	paginationOptions?: IPaginatedSearchOptions,
	filters?: URLSearchParams,
	timezone: TTimezone = "local",
	dateRange?: DateRange
) => {
	return withPagination(
		(sessionAuditLog: Record<string, unknown>) => new SessionAuditLogModel(sessionAuditLog),
		() => {
			let dateQueryParam = "";
			if (dateRange && dateRange.from && dateRange.to) {
				const from = encodeURIComponent(dateRange.from.toISOString());
				const to = encodeURIComponent(dateRange.to.toISOString());
				dateQueryParam = dateRange ? `&fromDate=${from}&toDate=${to}&` : "";
			}
			const timezoneQueryParam = `timezoneType=${timezone}&`;
			const filtersQueryParam = filters?.size !== 0 ? `${filters}&` : "";
			const url = `/v1/sessionAuditLogs?${filtersQueryParam}${timezoneQueryParam}${dateQueryParam}${getPaginatedSearchQueryString(paginationOptions)}`;
			return apiReq("GET", url);
		}
	);
};

export const getIntegrationLogsFields = async (field: string, search: string): Promise<string[]> => {
	const url = `/v1/sessionAuditLogs/fields?field=${field}&search=${search}`;
	const { data } = await apiReq("GET", url);
	const rows = data.rows;
	return isStringArray(rows) ? rows : [];
};
