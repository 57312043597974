import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	disabled: {
		pointerEvents: "none"
	},
	modalContent: {
		padding: "0 12px 12px 0",
		display: "flex",
		flexDirection: "column",
		gap: "24px",
		minWidth: "400px"
	},
	policyItemsContainer: {
		borderRadius: "1rem",
		marginTop: "1rem",
		padding: "1rem"
	},
	sectionTitle: {
		borderBottom: "1px solid var(--color-grey)",
		width: "100%",
		display: "flex",
		justifyContent: "space-between",
		padding: "0.2rem 0"
	},
	modal: {
		minWidth: "min(800px, var(--modal-max-height))"
	}
});
