import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	container: ({ width, maxInlineWidth }: { width: string | number; maxInlineWidth: string | number }) => ({
		maxWidth: "100%",
		verticalAlign: "middle",
		width: width,
		maxInlineSize: maxInlineWidth
	})
});
