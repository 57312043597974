import { Record } from "immutable";
import { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";

const defaults = {
	id: "",
	date: new Date(),
	description: "",
	actionType: "",
	integrationId: "" as string | null,
	source: "",
	users: [] as string[],
	permissions: [] as IntegrationResourceRoleModel[],
	account: "",
	authorType: "",
	action: "",
	subject: "",
	target: "",
	status: "",
	json: {}
};

export class SessionAuditLogModel extends Record<typeof defaults>(defaults) {
	static readonly type = "sessionAuditLog";
	static fromServerData(data: unknown) {
		const {
			id,
			date,
			description,
			actionType,
			integrationId,
			source,
			users,
			permissions,
			account,
			authorType,
			action,
			subject,
			target,
			status,
			json
		} = data as typeof defaults;

		return new SessionAuditLogModel({
			id,
			date,
			description,
			actionType,
			integrationId,
			source,
			users,
			permissions: permissions.map(IntegrationResourceRoleModel.fromServerData),
			account,
			authorType,
			action,
			subject,
			target,
			status,
			json
		});
	}
}
