import React from "react";

export const ZendeskLogo: FC = ({ className }) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" className={className}>
		<path d="M12.7143 4L12.7143 15.2245L22 4H12.7143Z" fill="#07363D" />
		<path
			d="M6.64289 9C9.18301 9 11.2857 6.43985 11.2857 4.00001H2.00003C2.00003 6.43985 4.10276 9 6.64289 9Z"
			fill="#07363D"
		/>
		<path
			d="M12.7143 19.7143C12.7143 17.2744 14.817 14.7143 17.3572 14.7143C19.8973 14.7143 22 17.2744 22 19.7143H12.7143Z"
			fill="#07363D"
		/>
		<path d="M11.2857 19.7143V8.48979L2.00001 19.7143H11.2857Z" fill="#07363D" />
	</svg>
);
