import { List } from "immutable";
import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { ApprovalAlgorithm } from "components/common/ApprovalAlgorithm";
import { DescribedCheckbox } from "components/common/DescribedCheckbox";
import { DurationsOverrideSelect } from "components/common/DurationsOverrideSelect";
import { IntegrationEntity } from "components/common/entities";
import { Link as ExternalLink } from "components/common/Link";
import {
	AgentTokenSelect,
	DEFAULT_AGENT_TOKEN_OPTION_ID,
	ITokenOption
} from "components/pages/CreateIntegrationPage/components/AgentTokensSelect";
import { PageTemplate } from "components/templates/PageTemplate";
import { Block } from "components/ui/Block";
import { Button } from "components/ui/Button";
import { getDynamicSizeIcon } from "components/ui/dynamicSizeIcon";
import { ErrorModal } from "components/ui/ErrorModal";
import { Form } from "components/ui/Form";
import { ChevronLeftIcon } from "components/ui/Icons/ChevronLeftIcon";
import { ExportIcon } from "components/ui/Icons/ExportIcon";
import { IntegrationIcon } from "components/ui/Icons/IntegrationIcon";
import { Input } from "components/ui/Input";
import { Typography } from "components/ui/legacy/Typography";
import { Select } from "components/ui/Select";
import { ApplicationOption } from "components/ui/selectOptions/ApplicationOption";
import { WorkflowOption } from "components/ui/selectOptions/WorkflowOption";
import { compiledClientConfig } from "config";
import { useIntegrationsContext } from "context/integrationsContext";
import { useApplications } from "hooks/useApplications";
import { useApprovalAlgorithms } from "hooks/useApprovalAlgorithms";
import { useApprovalFlows } from "hooks/useApprovalFlows";
import { useAuthenticatedUser } from "hooks/useAuthenticatedUser";
import { useChangeConfig } from "hooks/useChangeConfig";
import { useCompany } from "hooks/useCompany";
import useErrorModalState from "hooks/useErrorModalState";
import { useLoadingState } from "hooks/useLoadingState";
import { ApplicationModel } from "models/ApplicationModel";
import { ApprovalAlgorithmModel } from "models/ApprovalAlgorithmModel";
import { UserModel } from "models/UserModel";
import { sortByName } from "utils/sortUtils";
import { removeRedundantSpaces } from "utils/strings";
import { ConfigurationEdit } from "./components/ConfigurationEdit";
import { OwnerSelect, type TOwnerOption } from "./components/OwnerSelect";
import { useStyles } from "./styles";
import type { TTicketDuration } from "utils/durationsOptions";

const renderIntegrationLabel = (option: ApplicationModel) => (
	<IntegrationEntity withIcon size="medium" integration={option} />
);

export const CreateIntegrationPage: FC = ({ className }) => {
	const { withLoader, isLoading } = useLoadingState();
	const { user } = useAuthenticatedUser();
	const company = useCompany();

	const {
		actions: { createIntegration }
	} = useIntegrationsContext();

	const { t } = useTranslation();
	const navigate = useNavigate();
	const classes = useStyles();
	const {
		errorModalSetError,
		errorModalIsOpen,
		errorModalError,
		errorModalClose: closeErrorModal
	} = useErrorModalState();

	const applications = useApplications();
	const approvalAlgorithms = useApprovalAlgorithms();
	const approvalFlows = useApprovalFlows();
	const [agentToken, setAgentToken] = useState<ITokenOption | null>(null);
	const [allowedDurations, setAllowedDurations] = useState(List<TTicketDuration>());
	const [allowsRequests, setAllowsRequests] = useState(true);
	const [application, setApplication] = useState<ApplicationModel | null>(null);
	const [applicationError, setApplicationError] = useState("");
	const [approvalAlgorithm, setApprovalAlgorithm] = useState<ApprovalAlgorithmModel | null>(null);
	const [approvalAlgorithmError, setApprovalAlgorithmError] = useState("");
	const [autoAssignRecommendedResourceMaintainers, setUseRecommendedResourceMaintainers] = useState(false);
	const [autoAssignRecommendedResourceOwner, setUseRecommendedResourceOwner] = useState(false);
	const [canCreateActors, setCanCreateActors] = useState(false);
	const [canEditPermissions, setCanEditPermissions] = useState(true);
	const [defaultAllowsRequests, setDefaultAllowsRequests] = useState(true);
	const [name, setName] = useState("");
	const [nameError, setNameError] = useState("");
	const [notifyAboutExternalPermissions, setNotifyAboutExternalPermissions] = useState(false);
	const [overrideAllowedDurations, setOverrideAllowedDurations] = useState(false);
	const [owner, setOwner] = useState(user);
	const [readonly, setIsReadonly] = useState(false);
	const changeConfig = useChangeConfig(
		application,
		agentToken?.id !== DEFAULT_AGENT_TOKEN_OPTION_ID ? agentToken?.id : undefined
	);
	const { reset: resetConfiguration, checkConfig, config, validConfig, configState } = changeConfig;
	const disableConfiguration = !(
		application &&
		!application.adapterless &&
		(application.configurationSchema || application.oauthConfigurationSchema)
	);

	useEffect(() => {
		if (company?.allowedDurations) {
			setAllowedDurations(company.allowedDurations);
		}
	}, [company]);

	const resetHandler = useCallback(() => {
		resetConfiguration();
		setApprovalAlgorithm(null);
		setApprovalAlgorithmError("");
		setName("");
		setNameError("");
	}, [resetConfiguration]);

	const onApplicationChange = useCallback(
		(value: ApplicationModel | null) => {
			if (!applications || !value) return;
			const newApplication = applications.get(value.id) || null;
			resetHandler();
			setApplication(newApplication);
			setApplicationError("");
		},
		[applications, resetHandler]
	);

	const onAlgorithmChange = useCallback(
		(value: ApprovalAlgorithmModel | null) => {
			if (!approvalAlgorithms) return;
			const newApprovalAlgorithm = approvalAlgorithms.get(value?.id || "") || null;
			setApprovalAlgorithm(newApprovalAlgorithm);
			setApprovalAlgorithmError("");
		},
		[approvalAlgorithms]
	);

	const onNameChange = useCallback((value: string) => {
		setName(value);
		setNameError("");
	}, []);

	const validate = useCallback(() => {
		let valid = true;

		const clearedName = removeRedundantSpaces(name);
		setName(clearedName);
		if (clearedName.length < 2 || clearedName.length > 50) {
			valid = false;
			setNameError(t("validationErrors.global.nameLength"));
		}

		if (application === null) {
			valid = false;
			setApplicationError(
				t("validationErrors.global.cannotBeEmpty", { field: t("modelsFields.integration.application") })
			);
		}

		if (approvalAlgorithm === null) {
			valid = false;
			setApprovalAlgorithmError(
				t("validationErrors.global.cannotBeEmpty", { field: t("modelsFields.integration.defaultApprovalAlgorithm") })
			);
		}

		if (!disableConfiguration && validConfig === "invalid") {
			valid = false;
		}

		return valid;
	}, [application, approvalAlgorithm, disableConfiguration, validConfig, name, t]);

	useEffect(() => {
		if (application) {
			if (application.adapterless) {
				setIsReadonly(false);
				setAllowsRequests(true);
				setNotifyAboutExternalPermissions(false);
				if (application.virtual) {
					setCanEditPermissions(false);
					setCanCreateActors(false);
					setUseRecommendedResourceMaintainers(false);
					setUseRecommendedResourceOwner(false);
				}
			}
			if (application.canCreateActors !== "optional") {
				setCanCreateActors(application.canCreateActors === "required");
			}
			if (application.canEditPermissions !== "optional") {
				setCanEditPermissions(application.canEditPermissions === "required");
			}
		}
	}, [application]);

	const submit = useCallback(async () => {
		if (validate()) {
			if (!owner) return;
			try {
				if (!disableConfiguration && validConfig === "unchecked") {
					const isValid = await checkConfig();
					if (!isValid) return;
				}
				const agentTokenId = agentToken && agentToken.id !== DEFAULT_AGENT_TOKEN_OPTION_ID ? agentToken.id : null;
				await withLoader(
					createIntegration({
						agentTokenId,
						allowedDurations: overrideAllowedDurations ? allowedDurations : null,
						allowsRequests,
						applicationId: application!.id,
						canCreateActors,
						canEditPermissions,
						configuration: disableConfiguration
							? null
							: {
									...config,
									configurationSchemaName: configState?.label
								},
						defaultAllowsRequests,
						defaultApprovalAlgorithmId: approvalAlgorithm!.id,
						name,
						notifyAboutExternalPermissions,
						oauthConfiguration: configState?.type === "oauth",
						ownerUserId: owner.id,
						readonly,
						autoAssignRecommendedResourceMaintainers,
						autoAssignRecommendedResourceOwner
					})
				);
				navigate("/integrations");
			} catch (error) {
				errorModalSetError(error as Error);
			}
		}
	}, [
		validate,
		application,
		approvalAlgorithm,
		owner,
		disableConfiguration,
		validConfig,
		agentToken,
		withLoader,
		createIntegration,
		overrideAllowedDurations,
		allowedDurations,
		allowsRequests,
		canCreateActors,
		canEditPermissions,
		config,
		defaultAllowsRequests,
		name,
		notifyAboutExternalPermissions,
		configState?.type,
		configState?.label,
		readonly,
		autoAssignRecommendedResourceMaintainers,
		autoAssignRecommendedResourceOwner,
		navigate,
		checkConfig,
		errorModalSetError
	]);

	const applicationOptions = useMemo(() => applications?.toList().toArray() || [], [applications]);
	const approvalAlgorithmOptions = useMemo(() => approvalAlgorithms?.toList().toArray() || [], [approvalAlgorithms]);

	const toggleReadonly = useCallback(
		() =>
			setIsReadonly(curr => {
				if (!curr) {
					setCanCreateActors(false);
				} else if (application?.canCreateActors === "required") {
					setCanCreateActors(true);
				}
				return !curr;
			}),
		[application?.canCreateActors]
	);

	const toggleAllowsRequests = useCallback(() => setAllowsRequests(curr => !curr), []);
	const toggleCanCreateActors = useCallback(() => setCanCreateActors(curr => !curr), []);
	const toggleDefaultAllowsRequests = useCallback(() => setDefaultAllowsRequests(curr => !curr), []);
	const toggleNotifyAboutExternalPermissions = useCallback(() => setNotifyAboutExternalPermissions(curr => !curr), []);
	const toggleUseRecommendedResourceMaintainers = useCallback(
		() => setUseRecommendedResourceMaintainers(curr => !curr),
		[]
	);
	const toggleUseRecommendedResourceOwner = useCallback(() => setUseRecommendedResourceOwner(curr => !curr), []);
	const toggleCanEditPermissions = useCallback(
		() =>
			setCanEditPermissions(curr => {
				if (curr) {
					setCanCreateActors(true);
					setIsReadonly(false);
				}
				return !curr;
			}),
		[]
	);

	const updateOwner = useCallback(
		(owner: TOwnerOption) => {
			if (owner instanceof UserModel) {
				setOwner(owner);
			}
		},
		[setOwner]
	);

	useEffect(() => {
		if (user && owner === null) {
			setOwner(user);
		}
	}, [owner, user]);

	const DocsIcon = useMemo(() => {
		if (application) {
			if (application.imageUrl) {
				return getDynamicSizeIcon(<img src={application.imageUrl} />);
			}
			return IntegrationIcon;
		}
		return null;
	}, [application]);

	return (
		<PageTemplate className={className}>
			<ErrorModal error={errorModalError} isOpen={errorModalIsOpen} onClose={closeErrorModal} />
			<PageTemplate.Title className={classes.integrationTitle}>
				<div className={classes.integrationTitleBlock}>
					<Link className={classes.backButton} to="/integrations">
						<ChevronLeftIcon />
					</Link>

					<Typography variant="h2">
						{(name || t("pages.integrations.addIntegration")) + " "}
						{application ? (
							<>
								(
								<img className={classes.appImage} src={application.imageUrl} />
								{application.name})
							</>
						) : null}
					</Typography>
				</div>
			</PageTemplate.Title>
			<PageTemplate.Content>
				<div className={classes.container}>
					<Block className={classes.block}>
						<div className={classes.integrationForm}>
							<Form>
								<Form.Field className={classes.applicationField}>
									<Select
										disabled={applicationOptions?.length === 0}
										errors={[applicationError]}
										getOptionLabel={getNameLabel}
										isOptionEqualToValue={equality}
										label={t("pages.integration.application")}
										loading={!applications}
										onChange={onApplicationChange}
										options={applicationOptions}
										limit={100}
										sort={sortByName}
										required
										renderLabel={renderIntegrationLabel}
										value={application || null}
										renderOption={ApplicationOption}
									/>
									{application?.docsKey && (
										<Button
											prefix={DocsIcon ? <DocsIcon /> : null}
											suffix={<ExportIcon />}
											size="medium"
											variant="secondary">
											<ExternalLink
												className={classes.applicationFieldLink}
												external
												target="_blank"
												rel="noopener noreferrer"
												noDecoration
												to={`${compiledClientConfig.applicationsDocsUrl}-${application.docsKey}`}>
												{t("pages.integration.docs", { applicationName: application.name })}
											</ExternalLink>
										</Button>
									)}
								</Form.Field>
								<Form.Field>
									<Input
										label={t("pages.integration.name")}
										value={name}
										onValueChange={onNameChange}
										errors={[nameError]}
									/>
								</Form.Field>
								<Form.Field>{owner ? <OwnerSelect ownerId={owner.id} setOwner={updateOwner} /> : null}</Form.Field>
								<Form.Field>
									<Select
										disabled={approvalAlgorithmOptions?.length === 0}
										errors={[approvalAlgorithmError]}
										getOptionLabel={getNameLabel}
										isOptionEqualToValue={equality}
										label={t("pages.integration.defaultApprovalAlgorithm")}
										onChange={onAlgorithmChange}
										options={approvalAlgorithmOptions}
										renderOption={WorkflowOption}
										required
										sort={sortByName}
										value={approvalAlgorithm || null}
									/>
								</Form.Field>
								<Form.Field>
									<DescribedCheckbox
										label={t("pages.integration.readonlyHeader")}
										selected={readonly}
										description={t("pages.integration.readonlyDescription")}
										onClick={toggleReadonly}
										disabled={application?.adapterless || !canEditPermissions}
									/>
								</Form.Field>
								<Form.Field>
									<DescribedCheckbox
										label={t("pages.integration.canEditPermissions.label")}
										onClick={toggleCanEditPermissions}
										selected={canEditPermissions}
										description={t("pages.integration.canEditPermissions.description")}
										disabled={application?.canEditPermissions !== "optional" || readonly || application?.virtual}
									/>
								</Form.Field>
								<Form.Field>
									<DescribedCheckbox
										label={t("pages.integration.canCreateActors.label")}
										onClick={toggleCanCreateActors}
										selected={canCreateActors}
										description={t("pages.integration.canCreateActors.description")}
										disabled={
											application?.canCreateActors !== "optional" ||
											!canEditPermissions ||
											readonly ||
											application?.virtual
										}
									/>
								</Form.Field>
								<Form.Field className={classes.checkboxField}>
									<DescribedCheckbox
										label={t("pages.integration.requestableHeader")}
										selected={allowsRequests}
										description={t("pages.integration.requestableDescription")}
										onClick={toggleAllowsRequests}
									/>
								</Form.Field>
								<Form.Field>
									<DescribedCheckbox
										label={t("pages.integration.defaultAllowsRequests.label")}
										onClick={toggleDefaultAllowsRequests}
										selected={defaultAllowsRequests}
										description={t("pages.integration.defaultAllowsRequests.description")}
									/>
								</Form.Field>
								<Form.Field>
									<DescribedCheckbox
										label={t("pages.integration.autoAssignRecommendedResourceOwner")}
										description={t("pages.integration.autoAssignRecommendedResourceOwnerHelp")}
										selected={autoAssignRecommendedResourceOwner}
										onClick={toggleUseRecommendedResourceOwner}
										disabled={application?.virtual}
									/>
								</Form.Field>
								<Form.Field>
									<DescribedCheckbox
										label={t("pages.integration.autoAssignRecommendedResourceMaintainers")}
										description={t("pages.integration.autoAssignRecommendedResourceMaintainersHelp")}
										selected={autoAssignRecommendedResourceMaintainers}
										onClick={toggleUseRecommendedResourceMaintainers}
										disabled={application?.virtual}
									/>
								</Form.Field>
								<Form.Field>
									<DescribedCheckbox
										label={t("pages.integration.notifyAboutExternalPermissions")}
										description={t("pages.integration.notifyAboutExternalPermissionsHelp")}
										selected={notifyAboutExternalPermissions}
										onClick={toggleNotifyAboutExternalPermissions}
										disabled={application?.adapterless}
									/>
								</Form.Field>
								<Form.Field>
									<DurationsOverrideSelect
										disabled={!company}
										allowedDurations={allowedDurations}
										setAllowedDurations={setAllowedDurations}
										overrideAllowedDurations={overrideAllowedDurations}
										setOverrideAllowedDurations={setOverrideAllowedDurations}
										type="integration"
									/>
								</Form.Field>
								<Form.Field>
									<AgentTokenSelect disabled={disableConfiguration} value={agentToken} onChange={setAgentToken} />
								</Form.Field>
								<Form.Field>
									<ConfigurationEdit changeConfig={changeConfig} />
								</Form.Field>
								<Form.Actions>
									<Button variant="primary" type="submit" disabled={isLoading} loading={isLoading} onClick={submit}>
										{t("buttons.save")}
									</Button>
								</Form.Actions>
							</Form>
						</div>
						{approvalAlgorithms && approvalFlows && approvalAlgorithm ? (
							<div className={classes.approvalAlgorithmContainer}>
								<ApprovalAlgorithm algorithm={approvalAlgorithm} approvalFlows={approvalFlows} />
							</div>
						) : null}
					</Block>
				</div>
			</PageTemplate.Content>
		</PageTemplate>
	);
};

const getNameLabel = (option: { name: string }) => option.name;
const equality = (option: { id: string }, value: { id: string }) => option.id === value.id;
