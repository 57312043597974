import { Map } from "immutable";
import flatten from "lodash/flatten";
import isEmpty from "lodash/isEmpty";
import { useCallback, useMemo, useState } from "react";
import { IntegrationActorModel } from "models/IntegrationActorModel";
import { IntegrationModel } from "models/IntegrationModel";

const sortByIsDiscouraged = (a: IntegrationActorModel, b: IntegrationActorModel) =>
	a.isUsed === b.isUsed ? 0 : a.isUsed ? 1 : -1;

type TActorsInputProps<TValue extends IntegrationActorModel | IntegrationActorModel[]> = {
	integrationId: string;
	label: string;
	sortBy: (a: IntegrationActorModel, b: IntegrationActorModel) => number;
	onChange: (newValue: TValue, integrationId: string) => void;
	value: TValue | null;
};

export function useIntegrationActors<TValue extends IntegrationActorModel | IntegrationActorModel[]>(
	integrations: (IntegrationModel | null | undefined)[],
	defaultIntegrationsActors: Map<string, TValue> | undefined
) {
	const [integrationActors, setIntegrationActors] = useState<Map<string, TValue>>(defaultIntegrationsActors || Map());
	const onChange = useCallback(
		(newValue: TValue, integrationId: string) => setIntegrationActors(integrationActors?.set(integrationId, newValue)),
		[integrationActors]
	);

	const requiredIntegrationActors = useMemo(
		() =>
			integrations.filter(
				integration =>
					integration &&
					integration.application &&
					!integration.application.adapterless &&
					integration.canEditPermissions
			) as IntegrationModel[],
		[integrations]
	);

	const integrationActorsOptions = useMemo(
		() =>
			requiredIntegrationActors.map<TActorsInputProps<TValue>>(integration => {
				const value = integrationActors.get(integration.id) || null;
				return {
					integrationId: integration.id,
					label: integration.name,
					onChange,
					sortBy: sortByIsDiscouraged,
					value
				};
			}),
		[requiredIntegrationActors, integrationActors, onChange]
	);

	const unfilledIntegrations = useMemo(
		() =>
			requiredIntegrationActors.some(integration =>
				integration ? isEmpty(integrationActors.get(integration.id)) : false
			),
		[integrationActors, requiredIntegrationActors]
	);
	const integrationsActorsList = useMemo(
		() => flatten(integrationActors?.valueSeq().toArray()).map(actor => actor.id),
		[integrationActors]
	);
	return {
		integrationActorsOptions,
		integrationActors: integrationsActorsList,
		unfilledIntegrations,
		setIntegrationActors
	};
}
