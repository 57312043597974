import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	entityContainer: {
		alignItems: "center",
		cursor: "default",
		display: "flex",
		overflow: "hidden",
		paddingLeft: "0.25rem"
	},
	entityList: {
		gap: "0.5rem"
	}
});
