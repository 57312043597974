import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	container: {
		display: "flex",
		width: "100%"
	},
	searchOption: {
		padding: 0
	}
});
