import { Record } from "immutable";

export const TICKETING_INTEGRATIONS = ["jira", "jira-service-management", "zendesk"] as const;
export type TTicketingIntegrationType = (typeof TICKETING_INTEGRATIONS)[number];

const defaults = {
	id: "",
	name: "",
	euid: "",
	isDeleted: false,
	ticketingIntegration: "" as TTicketingIntegrationType,
	status: "",
	assigneeId: null as string | null,
	url: "",
	publicEuid: "",
	projectName: "",
	projectEuid: ""
};

export class TicketingIntegrationTicketModel extends Record<typeof defaults>(defaults) {
	static fromServerData(data: unknown): TicketingIntegrationTicketModel {
		const { id, name, euid, integration, status, assigneeId, url, publicEuid, projectName, projectEuid, isDeleted } =
			data as Omit<typeof defaults, "ticketingIntegration"> & { integration: { type: TTicketingIntegrationType } };

		return new TicketingIntegrationTicketModel({
			id,
			name,
			euid,
			isDeleted,
			ticketingIntegration: integration.type,
			status,
			assigneeId,
			url,
			publicEuid,
			projectName,
			projectEuid
		});
	}
}
