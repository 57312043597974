import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	container: {
		display: "flex",
		flexDirection: "column",
		gap: "1rem"
	},
	timeZoneSelection: {
		width: "28rem",
		padding: "1rem 0 0.75rem"
	},
	save: {
		width: "fit-content"
	}
});
