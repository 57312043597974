import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	modalContent: {
		display: "flex",
		flexDirection: "column",
		gap: "12px",
		padding: "0 12px 12px 0",
		maxWidth: "700px",
		minWidth: "480px"
	},
	form: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		height: "100%"
	}
});
