import React from "react";
import { TooltipOnOverflow } from "components/ui/TooltipOnOverflow";
import { useStyles } from "./styles";

export const NameWithEmail: FC<{ name: string; email: string }> = ({ email, name }) => {
	const classes = useStyles();
	return (
		<div className={classes.nameAndEmailContainer}>
			<TooltipOnOverflow textVariant="body_med" content={name} />
			<TooltipOnOverflow textVariant="text_sm_reg" content={email} />
		</div>
	);
};
