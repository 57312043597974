import { IntegrationResourceRoleAllowRequestsFilter } from "./integrationResourceRoleAllowRequestsFilter";
import { IntegrationResourceRoleIdFilter } from "./integrationResourceRoleIdFilter";
import { IntegrationResourceRoleIntegrationIdFilter } from "./integrationResourceRoleIntegrationIdFilter";
import { IntegrationResourceRoleIntegrationNameFilter } from "./integrationResourceRoleIntegrationNameFilter";
import { IntegrationResourceRoleIntegrationResourceIdFilter } from "./integrationResourceRoleIntegrationResourceIdFilter";
import { IntegrationResourceRoleIntegrationResourceNameFilter } from "./integrationResourceRoleIntegrationResourceNameFilter";
import { IntegrationResourceRoleIntegrationResourceTagsFilter } from "./integrationResourceRoleIntegrationResourceTagsFilter";
import { IntegrationResourceRoleIntegrationResourceTypeFilter } from "./integrationResourceRoleIntegrationResourceTypeFilter";
import { IntegrationResourceRoleNameFilter } from "./integrationResourceRoleNameFilter";
import { IntegrationResourceRoleWorkflowFilter } from "./integrationResourceRoleWorkflowFilter";

export const INTEGRATION_RESOURCE_ROLE_FILTERS = [
	IntegrationResourceRoleAllowRequestsFilter,
	IntegrationResourceRoleIdFilter,
	IntegrationResourceRoleIntegrationIdFilter,
	IntegrationResourceRoleIntegrationNameFilter,
	IntegrationResourceRoleIntegrationResourceIdFilter,
	IntegrationResourceRoleIntegrationResourceNameFilter,
	IntegrationResourceRoleIntegrationResourceTagsFilter,
	IntegrationResourceRoleIntegrationResourceTypeFilter,
	IntegrationResourceRoleNameFilter,
	IntegrationResourceRoleWorkflowFilter
];

export const INTEGRATION_RESOURCE_ROLE_FILTERS_BY_NAME = {
	[IntegrationResourceRoleAllowRequestsFilter.filterName]: IntegrationResourceRoleAllowRequestsFilter,
	[IntegrationResourceRoleIdFilter.filterName]: IntegrationResourceRoleIdFilter,
	[IntegrationResourceRoleIntegrationIdFilter.filterName]: IntegrationResourceRoleIntegrationIdFilter,
	[IntegrationResourceRoleIntegrationNameFilter.filterName]: IntegrationResourceRoleIntegrationNameFilter,
	[IntegrationResourceRoleIntegrationResourceIdFilter.filterName]: IntegrationResourceRoleIntegrationResourceIdFilter,
	[IntegrationResourceRoleIntegrationResourceNameFilter.filterName]:
		IntegrationResourceRoleIntegrationResourceNameFilter,
	[IntegrationResourceRoleIntegrationResourceTagsFilter.filterName]:
		IntegrationResourceRoleIntegrationResourceTagsFilter,
	[IntegrationResourceRoleIntegrationResourceTypeFilter.filterName]:
		IntegrationResourceRoleIntegrationResourceTypeFilter,
	[IntegrationResourceRoleNameFilter.filterName]: IntegrationResourceRoleNameFilter,
	[IntegrationResourceRoleWorkflowFilter.filterName]: IntegrationResourceRoleWorkflowFilter
};

export {
	IntegrationResourceRoleAllowRequestsFilter,
	IntegrationResourceRoleIdFilter,
	IntegrationResourceRoleIntegrationIdFilter,
	IntegrationResourceRoleIntegrationNameFilter,
	IntegrationResourceRoleIntegrationResourceIdFilter,
	IntegrationResourceRoleIntegrationResourceNameFilter,
	IntegrationResourceRoleIntegrationResourceTagsFilter,
	IntegrationResourceRoleIntegrationResourceTypeFilter,
	IntegrationResourceRoleNameFilter,
	IntegrationResourceRoleWorkflowFilter
};
