import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	statistics: {
		alignItems: "center",
		color: "var(--color-grey-dark)",
		display: "flex",
		gap: "0.25rem"
	},
	reporterContainer: {
		display: "flex",
		flexDirection: "column",
		gap: "0.75rem"
	},
	reporterType: {
		marginLeft: "2.5rem"
	},
	user: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		gap: "1rem"
	}
});
