import copy from "copy-to-clipboard";
import React, { useCallback } from "react";
import { IconButton } from "components/ui/IconButton";
import { CopyIcon } from "components/ui/Icons/CopyIcon";
import { TooltipOnOverflow } from "components/ui/TooltipOnOverflow";
import { useStyles } from "../styles";

interface IProps {
	token: string;
}

export const TokenDisplay: FC<IProps> = ({ token }) => {
	const classes = useStyles();
	const onCopy = useCallback(() => {
		if (token) copy(token);
	}, [token]);
	return (
		<div className={classes.token}>
			<TooltipOnOverflow content={token} />
			<IconButton variant="secondary" size="small" onClick={onCopy}>
				<CopyIcon />
			</IconButton>
		</div>
	);
};
