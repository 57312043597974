import { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";
import { UserModel } from "models/UserModel";

export const getUserIntegrationActor = (user: UserModel, integrationId: string) => {
	const userIntegrationActors = user.integrationActors?.filter(
		userIntegrationActor => userIntegrationActor.integrationId === integrationId
	);

	if (!userIntegrationActors || userIntegrationActors?.count() === 0) return null;

	if (userIntegrationActors?.count() === 1) {
		return userIntegrationActors.first();
	}

	return userIntegrationActors?.toArray();
};

// When determining some properties of a role (e.g. whether it can create actors),
// we need to use the actual integration of the role and not the virtual one.
export const getBackingIntegrationId = (integrationResourceRole: IntegrationResourceRoleModel) => {
	const virtualizedRole = integrationResourceRole.virtualizedRole;
	return virtualizedRole
		? virtualizedRole.integrationResource?.integrationId
		: integrationResourceRole.integrationResource?.integrationId;
};
