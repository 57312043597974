import { useEffect, useMemo } from "react";
import { useAgentTokensContext } from "context/agentTokensContext";

export const useAgentTokens = () => {
	const {
		state: { agentTokens },
		actions: { loadAgentTokens }
	} = useAgentTokensContext();

	useEffect(() => {
		if (!agentTokens) {
			void loadAgentTokens();
		}
	}, [agentTokens, loadAgentTokens]);

	const tokensList = useMemo(() => agentTokens?.toList(), [agentTokens]);
	return tokensList;
};
