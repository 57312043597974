import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	container: {
		alignItems: "flex-start",
		display: "flex",
		flexDirection: "column",
		gap: "var(--spacing-x10, 40px)"
	},
	fieldContainer: {
		alignItems: "flex-start",
		display: "flex",
		flexDirection: "column",
		gap: "var(--spacing-x1, 4px)",
		maxWidth: "452px",
		minWidth: "108px",
		width: "452px"
	},
	thirdPartyTicketLabel: {
		display: "flex",
		alignItems: "center",
		gap: "var(--spacing-x2, 8px)"
	},
	tooltip: {
		maxWidth: "490px"
	}
});
