import { Record } from "immutable";
import type { IFilter } from "types/filters";

const filterName = "integrationAllowRequests";

const defaults = {
	name: filterName as typeof filterName,
	value: false as boolean,
	operator: "is" as const
};

type TDefaults = typeof defaults;

export class IntegrationAllowRequestsFilter
	extends Record<TDefaults>(defaults)
	implements IFilter<TDefaults["value"], TDefaults["operator"], TDefaults["name"]>
{
	static filterName: typeof filterName = filterName;

	static fromURLSearchParams(urlSearchParams: URLSearchParams): IntegrationAllowRequestsFilter | null {
		const integrationAllowRequests = urlSearchParams.get("integrationAllowRequests");
		const integrationAllowRequestsOperator = urlSearchParams.get("integrationAllowRequestsOperator") || undefined;
		if (
			integrationAllowRequests === null ||
			(typeof integrationAllowRequestsOperator === "string" && integrationAllowRequestsOperator !== "is")
		) {
			return null;
		}
		return new IntegrationAllowRequestsFilter({
			value: integrationAllowRequests === "true",
			operator: integrationAllowRequestsOperator
		});
	}

	toURLSearchParams(): URLSearchParams {
		const params = new URLSearchParams();
		params.append("integrationAllowRequests", this.value.toString());
		params.append("integrationAllowRequestsOperator", this.operator);
		return params;
	}
}
