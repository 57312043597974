import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useAccessReviewPermissions } from "hooks/useAccessReviewPermissions";
import type { TAccessReviewPermissionStatus } from "models/AccessReviewPermissionModel";
import type { AccessReviewResourcePermissionModel } from "models/AccessReviewResourcePermissionModel";
import type { AccessReviewSubordinatePermissionModel } from "models/AccessReviewSubordinatePermissionModel";

const TRANSLATION_PREFIX = "pages.accessReview.dashboard";

type TStatusOption = TAccessReviewPermissionStatus | "all";
const ALL_OPTION = "all";

interface IUseEntitlementsTable {
	changePage: (page: number) => void;
	currentPageNumber: number;
	getStatusLabel: (status: TStatusOption) => string;
	headers: string[];
	isLoading: boolean;
	page?: Immutable.List<AccessReviewSubordinatePermissionModel | AccessReviewResourcePermissionModel>;
	setStatusFilter: (value: TStatusOption | null) => void;
	statusFilter: TStatusOption;
	statusOptions: TStatusOption[];
	title: string;
	totalPages: number;
}

const STATUS_OPTIONS: TStatusOption[] = ["approved", "denied", "flagged", "pending", "all"];

export const useEntitlementsTable = (accessReviewId: string): IUseEntitlementsTable => {
	const { t } = useTranslation();
	const {
		currentPageNumber,
		isLoading,
		lastPage,
		page,
		setCurrentPageNumber,
		setStatusFilter,
		statusFilter,
		totalPages,
		totalResults
	} = useAccessReviewPermissions(accessReviewId);
	const headers = [
		t(`${TRANSLATION_PREFIX}.entitlement`),
		t(`${TRANSLATION_PREFIX}.reporter`),
		t(`${TRANSLATION_PREFIX}.account`),
		t(`${TRANSLATION_PREFIX}.user`),
		t(`${TRANSLATION_PREFIX}.comment`),
		t(`${TRANSLATION_PREFIX}.status`)
	];

	const title = t(`${TRANSLATION_PREFIX}.entitlementsTitle`, {
		count: totalResults
	});

	const getStatusLabel = useCallback(
		(status: TAccessReviewPermissionStatus | "all") => {
			if (status === ALL_OPTION) return t("shared.all");
			return t(`common.accessReview.statuses.permissions.${status}`);
		},
		[t]
	);

	const onChangeStatusFilter = useCallback(
		(status: TStatusOption | null) => {
			setStatusFilter(status || ALL_OPTION);
		},
		[setStatusFilter]
	);

	return {
		changePage: setCurrentPageNumber,
		currentPageNumber,
		getStatusLabel,
		headers,
		isLoading,
		page: isLoading ? lastPage : page,
		setStatusFilter: onChangeStatusFilter,
		statusOptions: STATUS_OPTIONS,
		statusFilter,
		title,
		totalPages
	};
};
