import { useCallback, useMemo } from "react";
import { matchPath, useLocation, Location } from "react-router-dom";
import usePreviousLocation from "./usePreviousLocation";
import type { TSwitchAnimation } from "context/transitionAnimationContext";

/**
 * Try to match the first rule that's relevant based on the current and previous locations in order to get
 * the animation to use for a SwitchTransitions element
 * @param resolveMatches - an array of rules to resolve the animation
 * @param currentLocation - defaults to useLocation()
 * @param previousLocation - defaults to usePreviousLocation(currentLocation)
 * @returns the animation to use and whether it should be reversed
 */
export default function useAnimationResolver(
	resolveMatches: (TResolveMatch | TResolveOrderMatch)[],
	{ currentLocation = null, previousLocation = null }: TUseAnimationResolverOptions = {}
): { animation: TSwitchAnimation; reverse: boolean } {
	const currentLocationHook = useLocation();
	const _currentLocation = currentLocation ?? currentLocationHook;

	const previousLocationHook = usePreviousLocation(_currentLocation);
	const _previousLocation = previousLocation ?? previousLocationHook;

	const testRoute = useCallback((location: Location | null, route?: TRouteProps): boolean => {
		if (!location || !route) return true;

		let paths: string[];
		if (typeof route === "string") {
			paths = [route];
		} else if (Array.isArray(route)) {
			paths = route;
		} else if (Array.isArray(route.path)) {
			paths = route.path;
		} else {
			paths = [route.path];
		}

		return paths.some(path => !!matchPath(location.pathname, path));
	}, []);

	const resolveOrderMatch = useCallback(
		(resolver: TResolveOrderMatch) => {
			const currentLocationIndex = resolver.order.findIndex(route => testRoute(_currentLocation, route));
			const previousLocationIndex = resolver.order.findIndex(route => testRoute(_previousLocation, route));

			if (currentLocationIndex === -1 || previousLocationIndex === -1) return null;

			const currentLocationIsAfterPrevious = currentLocationIndex >= previousLocationIndex;
			const resolverReverseAnimation = resolver.reverse ?? false;
			return {
				animation: resolver.animation,
				reverse: currentLocationIsAfterPrevious ? resolverReverseAnimation : !resolverReverseAnimation
			};
		},
		[_currentLocation, _previousLocation, testRoute]
	);

	const resolvedAnimation = useMemo((): { animation: TSwitchAnimation; reverse: boolean } => {
		for (const resolver of resolveMatches) {
			if ("order" in resolver && resolver.order !== null) {
				const result = resolveOrderMatch(resolver);
				if (result) return result;
			} else {
				const matchResolver = resolver as TResolveMatch;
				const matches =
					testRoute(_previousLocation, matchResolver.from) &&
					testRoute(_currentLocation, matchResolver.to) &&
					(matchResolver.test ? matchResolver.test(_previousLocation, _currentLocation) : true);

				if (matches)
					return {
						animation: resolver.animation,
						reverse: resolver.reverse ?? false
					};
			}
		}

		return {
			animation: "none" as TSwitchAnimation,
			reverse: false
		};
	}, [resolveOrderMatch, resolveMatches, testRoute, _previousLocation, _currentLocation]);

	return resolvedAnimation;
}

type TRouteProps =
	| string
	| string[]
	| {
			path: string | string[];
			exact?: boolean;
			sensitive?: boolean;
	  };

export type TResolveMatch = {
	animation: TSwitchAnimation;
	from?: TRouteProps;
	to?: TRouteProps;
	test?(lastLocation: Location | null, currentLocation: Location): boolean;
	reverse?: boolean;
};
export type TResolveOrderMatch = {
	order: TRouteProps[];
	animation: TSwitchAnimation;
	reverse?: boolean;
};

type TUseAnimationResolverOptions = {
	currentLocation?: Location | null;
	previousLocation?: Location | null;
};
