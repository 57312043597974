import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	modal: {
		maxWidth: "484px"
	},
	content: {
		alignItems: "flex-start",
		display: "flex",
		flexDirection: "column",
		gap: "var(--spacing-x10, 40px)",
		padding: "0 0 var(--spacing-x12, 48px) var(--spacing-x3, 12px)"
	}
});
