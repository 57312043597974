import React from "react";
import { makeIcon } from "components/ui/Icon";

export const ResetIcon = makeIcon(
	<svg viewBox="0 0 24 24" fill="none">
		<path
			d="M12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C10.85 4 9.75 4.2375 8.7 4.7125C7.65 5.1875 6.75 5.86667 6 6.75V5C6 4.71667 5.90417 4.47917 5.7125 4.2875C5.52083 4.09583 5.28333 4 5 4C4.71667 4 4.47917 4.09583 4.2875 4.2875C4.09583 4.47917 4 4.71667 4 5V10C4 10.2833 4.09583 10.5208 4.2875 10.7125C4.47917 10.9042 4.71667 11 5 11H10C10.2833 11 10.5208 10.9042 10.7125 10.7125C10.9042 10.5208 11 10.2833 11 10C11 9.71667 10.9042 9.47917 10.7125 9.2875C10.5208 9.09583 10.2833 9 10 9H6.8C7.33333 8.06667 8.0625 7.33333 8.9875 6.8C9.9125 6.26667 10.9167 6 12 6C13.6667 6 15.0833 6.58333 16.25 7.75C17.4167 8.91667 18 10.3333 18 12C18 13.6667 17.4167 15.0833 16.25 16.25C15.0833 17.4167 13.6667 18 12 18C10.8667 18 9.82917 17.7125 8.8875 17.1375C7.94583 16.5625 7.21667 15.7917 6.7 14.825C6.56667 14.5917 6.37917 14.4292 6.1375 14.3375C5.89583 14.2458 5.65 14.2417 5.4 14.325C5.13333 14.4083 4.94167 14.5833 4.825 14.85C4.70833 15.1167 4.71667 15.3667 4.85 15.6C5.53333 16.9333 6.50833 18 7.775 18.8C9.04167 19.6 10.45 20 12 20Z"
			fill="currentColor"
		/>
	</svg>
);
