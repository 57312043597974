import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	"@keyframes spinner-rotate": {
		from: {
			transform: "rotate(0deg)"
		},

		to: {
			transform: "rotate(360deg)"
		}
	},
	"@keyframes spinner-dash": {
		"0%": {
			strokeDashoffset: "187",
			transform: "rotate(0deg)"
		},

		"50%": {
			strokeDashoffset: "46.75",
			transform: "rotate(90deg)"
		},

		"100%": {
			strokeDashoffset: "187",
			transform: "rotate(360deg)"
		}
	},
	"extra-small": {
		width: "0.5em",
		height: "0.5em"
	},
	small: {
		width: "0.75em",
		height: "0.75em"
	},
	medium: {
		width: "1em",
		height: "1em"
	},
	big: {
		width: "1.25em",
		height: "1.25em"
	},
	customSize: {
		width: (props?: { size?: number }) => `${props?.size || 16}px`,
		height: (props?: { size?: number }) => `${props?.size || 16}px`
	},
	loadingSpinner: {
		fontSize: "4rem",
		width: "1em",
		height: "1em",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		margin: "auto",
		"&.inline": {
			fontSize: "1em",
			display: "inline-flex",
			verticalAlign: "text-top",
			"& > svg > $path": {
				stroke: "currentcolor"
			}
		}
	},
	spinner: {
		width: "100%",
		height: "100%",
		fill: "transparent",
		animation: "$spinner-rotate 1.2s linear infinite"
	},
	path: {
		strokeDasharray: "187",
		strokeDashoffset: "0",
		strokeWidth: "0.375rem",
		strokeLinecap: "round",
		transformOrigin: "center",
		stroke: "var(--color-purple)",
		animation: "$spinner-dash 1.6s ease-in-out infinite"
	}
});
