import constate from "constate";
import { Map } from "immutable";
import { useCallback, useRef, useState } from "react";
import { getOnCallIntegrationSchedules } from "api/onCallIntegrationSchedules";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { OnCallIntegrationScheduleModel } from "models/OnCallIntegrationScheduleModel";

const useOnCallIntegrationSchedules = () => {
	const [onCallIntegrationSchedules, setOnCallIntegrationSchedules] = useState<Map<
		string,
		OnCallIntegrationScheduleModel
	> | null>(null);
	const openGlobalErrorModal = useOpenGlobalErrorModal();
	const loadingRef = useRef(false);

	const loadOnCallIntegrationSchedules = useCallback(async () => {
		if (loadingRef.current) return;
		try {
			loadingRef.current = true;
			const newSchedules = await getOnCallIntegrationSchedules();
			loadingRef.current = false;
			setOnCallIntegrationSchedules(newSchedules);
		} catch (error) {
			loadingRef.current = false;
			openGlobalErrorModal(error as Error);
		}
	}, [openGlobalErrorModal]);

	return { state: { onCallIntegrationSchedules }, actions: { loadOnCallIntegrationSchedules } };
};

export const [OnCallIntegrationSchedulesProvider, useOnCallIntegrationSchedulesContext] =
	constate(useOnCallIntegrationSchedules);
