import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { createIntegrationResource, TIntegrationResourceCreateBody } from "api/integrationResources";
import { IconPrefix } from "components/ui/IconPrefix";
import { AddIcon } from "components/ui/Icons/AddIcon";
import { Modal } from "components/ui/Modal";
import { useSubscriber } from "hooks/useSubscriber";
import { IntegrationResourceModel } from "models/IntegrationResourceModel";
import { useStyles } from "./styles";
import { ResourceForm } from "../ResourceForm";

interface IProps {
	integrationId: string;
	isOpen?: boolean;
	onClose: () => void;
	onUpdate?: (resource: IntegrationResourceModel) => Promise<void>;
}

export const ResourceFormModal: FC<IProps> = ({ integrationId, onUpdate, onClose, isOpen = false }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const { addSubscription: subscribeToClearForm, notify: notifyClearForm } = useSubscriber();

	const onSubmit = useCallback(
		async (resourceToUpdate: TIntegrationResourceCreateBody) => {
			const updatedResource = await createIntegrationResource(resourceToUpdate);
			await onUpdate?.(updatedResource);
			notifyClearForm();
			onClose();
		},
		[notifyClearForm, onClose, onUpdate]
	);

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			content={
				<div className={classes.content}>
					<IconPrefix size="large" semibold Icon={AddIcon} content={t("pages.integration.createResource")} />
					<ResourceForm integrationId={integrationId} onSubmit={onSubmit} subscribeToResetForm={subscribeToClearForm} />
				</div>
			}
		/>
	);
};
