import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	emptyRulesContainer: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flex: "1 0 0",
		borderRadius: "var(--spacing-x4, 16px)",
		background: "var(--color-purple-50)"
	},
	rulesSectionContainer: {
		display: "flex",
		flexDirection: "column",
		gap: "var(--spacing-x10, 40px)"
	},
	ruleTypeSection: {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start",
		gap: "var(--spacing-x3, 12px)"
	},
	ruleTypeSectionHeader: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "flex-start",
		gap: "var(--spacing-x4, 16px)"
	},
	ruleTypeSectionContent: {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start",
		gap: "var(--spacing-x3, 12px)"
	},
	explanationTooltip: {
		maxWidth: "444px"
	},
	sectionContent: {
		overflow: "auto"
	}
});
