import classNames from "classnames";
import React from "react";
import { IconPrefix } from "components/ui/IconPrefix";
import { useStyles } from "./styles";

type TProps = { text: string; Icon?: IconComponent; variant?: "node" | "chip" };

export const FilterExpressionEmptyState: FC<TProps> = ({ className, text, variant = "chip", Icon, innerRef }) => {
	const classes = useStyles();

	return (
		<IconPrefix
			className={classNames(
				classes.emptyState,
				variant === "chip" ? classes.chipEmptyState : classes.nodeEmptyState,
				className
			)}
			content={text}
			Icon={Icon}
			innerRef={innerRef}
		/>
	);
};
