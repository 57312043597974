import constate from "constate";
import { List, Map } from "immutable";
import { useCallback, useState } from "react";
import {
	createAccessReviewTemplate as apiCreateAccessReviewTemplate,
	deleteAccessReviewTemplate as apiDeleteAccessReviewTemplate,
	getAccessReviewTemplate as apiGetAccessReviewTemplate,
	getAccessReviewTemplates as apiGetAccessReviewTemplates,
	editAccessReviewTemplate as apiEditAccessReviewTemplate
} from "api/accessReviewTemplates";
import { AccessReviewTemplateModel, TFullTAccessReviewTemplateModel } from "models/AccessReviewTemplateModel";

const useAccessReviewTemplates = () => {
	const [accessReviewTemplates, setAccessReviewTemplates] = useState<List<AccessReviewTemplateModel> | null>(null);
	const [fullAccessReviewTemplates, setFullAccessReviewTemplates] =
		useState(Map<string, TFullTAccessReviewTemplateModel>());

	const loadAccessReviewTemplates = useCallback(async () => {
		const accessReviewTemplates = await apiGetAccessReviewTemplates();
		setAccessReviewTemplates(accessReviewTemplates);
		return accessReviewTemplates;
	}, []);

	const createAccessReviewTemplate = useCallback(async (template: AccessReviewTemplateModel) => {
		const accessReviewTemplate = await apiCreateAccessReviewTemplate(template);
		setAccessReviewTemplates(current => (current ? current.push(accessReviewTemplate) : null));
		return accessReviewTemplate;
	}, []);

	const editAccessReviewTemplate = useCallback(async (id: string, template: Partial<AccessReviewTemplateModel>) => {
		const accessReviewTemplate = await apiEditAccessReviewTemplate(id, template);
		setAccessReviewTemplates(current => {
			if (!current) {
				return current;
			}
			const updatedIndex = current.findIndex(({ id }) => accessReviewTemplate.id === id);
			const newAccessReviewTemplate = accessReviewTemplate.set(
				"accessReviewsCount",
				current.get(updatedIndex)!.accessReviewsCount
			);
			return current.set(updatedIndex, newAccessReviewTemplate);
		});
		setFullAccessReviewTemplates(current => current.set(accessReviewTemplate.id, accessReviewTemplate));
		return accessReviewTemplate;
	}, []);

	const getAccessReviewTemplate = useCallback(async (id: string): Promise<TFullTAccessReviewTemplateModel> => {
		const accessReviewTemplate = await apiGetAccessReviewTemplate(id);
		setFullAccessReviewTemplates(current => current.set(id, accessReviewTemplate));
		return accessReviewTemplate;
	}, []);

	const deleteAccessReviewTemplate = useCallback(async (id: string) => {
		await apiDeleteAccessReviewTemplate(id);
		setFullAccessReviewTemplates(current => current.delete(id));
		setAccessReviewTemplates(current => {
			if (!current) {
				return current;
			}
			return current.delete(current.findIndex(template => template.id === id));
		});
	}, []);

	return {
		state: { accessReviewTemplates, fullAccessReviewTemplates },
		actions: {
			loadAccessReviewTemplates,
			createAccessReviewTemplate,
			editAccessReviewTemplate,
			getAccessReviewTemplate,
			deleteAccessReviewTemplate
		}
	};
};

export const [AccessReviewTemplatesProvider, useAccessReviewTemplatesContext] = constate(useAccessReviewTemplates);
