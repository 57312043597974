import i18next, { ParseKeys, TFunction, TOptions } from "i18next";

class I18nText {
	public readonly key: string[] | string;
	public readonly options?: TOptions;

	constructor(key: string[] | string, options?: TOptions) {
		this.key = key;
		this.options = options;
	}
}

export function i18nText(key: string[] | string, options?: TOptions) {
	return new I18nText(key, options);
}

export type i18nText = I18nText | string;

export function getResolvedText(t: TFunction, i18nTextOrText: I18nText | string) {
	if (i18nTextOrText instanceof I18nText) {
		return t(i18nTextOrText.key as ParseKeys, i18nTextOrText.options ?? {});
	}

	return i18nTextOrText;
}

export function getI18nTextIfExists(key: string[] | string, options?: TOptions) {
	if (i18next.exists(key)) return i18next.t(key as ParseKeys, options ?? {});

	return null;
}

export function getI18nErrorTexts(
	errorId: string,
	params?: TOptions,
	defaultToErrorId = true
): { title: string | null; message: string | null } {
	if (i18next.exists(`errors.${errorId}`)) {
		if (i18next.exists(`errors.${errorId}.title`) || i18next.exists(`errors.${errorId}.message`))
			return {
				title: getI18nTextIfExists(`errors.${errorId}.title`, params),
				message: getI18nTextIfExists(`errors.${errorId}.message`, params)
			};

		return {
			title: null,
			message: getI18nTextIfExists(`errors.${errorId}`, params)
		};
	}

	return {
		title: null,
		message: defaultToErrorId ? errorId : null
	};
}
