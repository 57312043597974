import { createUseStyles } from "react-jss";

const COLUMN_HEIGHT_PX = 40;

export const useStyles = createUseStyles({
	columnContent: {
		height: `${COLUMN_HEIGHT_PX}px`,
		maxWidth: "100%",
		display: "flex",
		alignItems: "center",
		gap: "var(--spacing-x2, 8px)"
	},
	image: {
		padding: 0,
		height: "100%"
	},
	tooltip: {
		width: "fit-content",
		maxWidth: "fit-content"
	},
	tooltipTitle: {
		display: "flex",
		alignItems: "center",
		flexDirection: "row",
		gap: "var(--spacing-x2, 8px)"
	}
});
