import classNames from "classnames";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { respondToTicket, adminRespondToTicket } from "api/tickets";
import { Button } from "components/ui/Button";
import { SendIcon } from "components/ui/Icons/SendIcon";
import { TextAreaInput } from "components/ui/TextAreaInput";
import { Typography } from "components/ui/Typography";
import { usePageContext } from "context/pageContext";
import { useAuthenticatedUser } from "hooks/useAuthenticatedUser";
import { useLoadingState } from "hooks/useLoadingState";
import { checkUserIsApprover, shouldAllowAdminApprove } from "hooks/useTicketUserPermissions";
import { removeRedundantSpaces } from "utils/strings";
import { getCommentValidators } from "utils/validation/validationUtils";
import { useStyles } from "./styles";
import type { TFullTicket } from "components/common/RequestDetails";

interface IProps {
	addComment: (comment: string, reload: boolean) => Promise<void>;
	ticket: TFullTicket | null;
}

export const AddTicketComment: FC<IProps> = ({ addComment, ticket, className }) => {
	const { user } = useAuthenticatedUser();
	const { t } = useTranslation("translation");
	const classes = useStyles();
	const [comment, setComment] = useState("");
	const { scrollableId: pageId } = usePageContext();
	const { isLoading: respondIsLoading, withLoader: withRespondLoader } = useLoadingState();
	const { isLoading: postIsLoading, withLoader: withPostLoading } = useLoadingState();
	const loading = respondIsLoading || postIsLoading;

	const allowAdminApprove = shouldAllowAdminApprove(user?.isAdmin ?? false, pageId);
	const isApprover = useMemo(() => Boolean(ticket && user && checkUserIsApprover(ticket, user)), [ticket, user]);
	const hasActions = ticket && (isApprover || allowAdminApprove) && ticket.status === "waitingForApproval";

	const updateApprovalStatus = useCallback(
		async (approved: boolean) => {
			if (!hasActions) return;
			const respondAction = allowAdminApprove ? adminRespondToTicket : respondToTicket;
			await withRespondLoader(respondAction(ticket.id, approved));
		},
		[hasActions, allowAdminApprove, withRespondLoader, ticket?.id]
	);

	const commentValidators = useMemo(() => getCommentValidators(t("common.addComment.comment")), [t]);
	const isValid = useMemo(() => !commentValidators.some(validate => validate(comment)), [comment, commentValidators]);
	const commentIsEmpty = comment.trim().length === 0;

	const postComment = useCallback(
		async (reload: boolean) => {
			if (!isValid) return;
			await withPostLoading(addComment(removeRedundantSpaces(comment), reload));
			setComment("");
		},
		[addComment, comment, isValid, withPostLoading]
	);

	const postAndApprove = useCallback(async () => {
		await updateApprovalStatus(true);
		await postComment(true);
	}, [postComment, updateApprovalStatus]);

	const postAndDeny = useCallback(async () => {
		await updateApprovalStatus(false);
		await postComment(true);
	}, [postComment, updateApprovalStatus]);

	const onPostClick = useCallback(() => {
		void postComment(false);
	}, [postComment]);

	return (
		<div className={classNames(classes.container, className)}>
			<div className={classes.body}>
				<Typography variant="text_title_sb">{t("common.requestDetails.activity.comment.title")}</Typography>
				<TextAreaInput
					autoResize
					className={classes.textArea}
					inputClassName={classes.textareaInput}
					onValueChange={setComment}
					placeholder={t("common.requestDetails.activity.comment.placeholder")}
					value={comment}
					validators={commentValidators}
				/>
				<div className={classes.actions}>
					<Button
						variant="secondary"
						size="small"
						suffix={<SendIcon />}
						loading={postIsLoading}
						disabled={postIsLoading || !isValid}
						onClick={onPostClick}>
						{t("common.requestDetails.activity.actions.post")}
					</Button>
					{hasActions && (
						<>
							<Button
								size="small"
								loading={loading}
								disabled={loading || commentIsEmpty}
								onClick={postAndApprove}
								tooltip={
									allowAdminApprove ? t("common.requestDetails.activity.actions.tooltips.adminApproval") : undefined
								}>
								{t("common.requestDetails.activity.actions.postAndApprove")}
							</Button>
							<Button
								size="small"
								loading={loading}
								disabled={loading || commentIsEmpty}
								onClick={postAndDeny}
								tooltip={
									allowAdminApprove ? t("common.requestDetails.activity.actions.tooltips.adminDenial") : undefined
								}>
								{t("common.requestDetails.activity.actions.postAndDeny")}
							</Button>
						</>
					)}
				</div>
			</div>
		</div>
	);
};
