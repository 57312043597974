import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	ticketingIntegrationTicketChipContainer: {
		maxWidth: "100%",
		verticalAlign: "middle"
	},
	ticketingIntegrationTicketChip: {
		"&$ticketingIntegrationTicketChip&$ticketingIntegrationTicketChip": {
			width: "100%"
		}
	}
});
