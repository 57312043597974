import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	spinner: {
		margin: "auto"
	},
	integrations: {
		display: "flex",
		flexDirection: "column",
		flexWrap: "wrap",
		gap: "0.85rem"
	},
	titleContainer: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between"
	},
	titleActions: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "flex-end",
		gap: "var(--spacing-x2, 8px)"
	},
	iconButtonContent: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
		gap: "var(--spacing-x1, 4px)"
	}
});
