import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	base: {
		MozHyphens: "auto",
		WebkitHyphens: "auto",
		msHyphens: "auto",
		hyphens: "auto",
		alignItems: "center",
		display: "inline-block",
		fontFamily: "Poppins, sans-serif",
		fontSize: "var(--typography-standard-font-size)",
		fontStyle: "normal",
		fontWeight: "var(--typography-font-weight-regular)",
		letterSpacing: "calc(1em / 65)",
		lineHeight: "1.4em",
		marginBlock: 0,
		overflowWrap: "break-word",
		textTransform: "none",
		whiteSpace: "initial",
		wordWrap: "break-word",
		wordBreak: "break-word",
		"&$noWrap": {
			overflow: "hidden",
			textOverflow: "ellipsis",
			whiteSpace: "nowrap"
		},
		"&$relative": {
			fontWeight: "inherit",
			letterSpacing: "inherit",
			lineHeight: "inherit"
		},
		"&$multiLine": {
			whiteSpace: "pre-wrap"
		},
		"&$fade": {
			color: "var(--color-grey)"
		},
		"&$prefixIcon": {
			display: "flex",
			alignItems: "center",
			gap: "4px"
		}
	},
	sectionTitle: {
		"&$relative": {
			fontSize: "var(--typography-relative-section-title-font-size)"
		},
		fontSize: "var(--typography-section-title-font-size)",
		fontWeight: "var(--typography-font-weight-medium)",
		letterSpacing: "0.0375em",
		lineHeight: "1em",
		textTransform: "uppercase"
	},
	small: {
		"&$relative": {
			fontSize: "var(--typography-relative-small-font-size)"
		},
		fontSize: "var(--typography-small-font-size)",
		fontWeight: "var(--typography-font-weight-regular)",
		letterSpacing: "calc(1em / 55)",
		lineHeight: "1.4em"
	},
	standard: {
		"&$relative": {
			fontSize: "var(--typography-relative-standard-font-size)"
		},
		fontSize: "var(--typography-standard-font-size)",
		fontWeight: "var(--typography-font-weight-regular)",
		letterSpacing: "calc(1em / 65)",
		lineHeight: "1.4em"
	},
	tiny: {
		"&$relative": {
			fontSize: "var(--typography-relative-tiny-font-size)"
		},
		fontSize: "var(--typography-tiny-font-size)",
		fontWeight: "var(--typography-font-weight-regular)",
		letterSpacing: 0,
		lineHeight: "1.3em"
	},
	button: {
		"&$relative": {
			fontSize: "var(--typography-relative-button-font-size)"
		},
		fontSize: "var(--typography-button-font-size)",
		fontWeight: "var(--typography-font-weight-medium)",
		letterSpacing: "0.4px",
		lineHeight: "1.5em"
	},
	h1: {
		"&$relative": {
			fontSize: "var(--typography-relative-h1-font-size)"
		},
		fontSize: "var(--typography-h1-font-size)",
		fontWeight: "var(--typography-font-weight-light)",
		letterSpacing: "calc(1em / 60)",
		lineHeight: "1.2em"
	},
	h2: {
		"&$relative": {
			fontSize: "var(--typography-relative-h2-font-size)"
		},
		fontSize: "var(--typography-h2-font-size)",
		fontWeight: "var(--typography-font-weight-light)",
		letterSpacing: "calc(1em / 60)",
		lineHeight: "1.2em"
	},
	h3: {
		"&$relative": {
			fontSize: "var(--typography-relative-h3-font-size)"
		},
		fontSize: "var(--typography-h3-font-size)",
		fontWeight: "var(--typography-font-weight-regular)",
		letterSpacing: "calc(1em / 60)",
		lineHeight: "1.2em"
	},
	noWrap: {},
	relative: {},
	multiLine: {},
	fade: {},
	prefixIcon: {}
});
