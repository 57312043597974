import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	actionsContainer: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		width: "calc(100% + 1rem)"
	},
	create: {
		padding: 0
	},
	field: {
		width: "100%"
	},
	fieldContainer: {
		alignItems: "start"
	},
	title: {
		display: "flex",
		justifyContent: "space-between"
	},
	noProviders: {
		textAlign: "center",
		width: "100%"
	},
	option: {
		display: "flex",
		alignItems: "center",
		gap: "0.5rem"
	}
});
