import React from "react";
import { makeIcon } from "components/ui/Icon";

export const ExpandIcon = makeIcon(
	<svg viewBox="0 0 24 24" fill="none">
		<path
			d="M12 19.15L15.875 15.275C16.075 15.075 16.3083 14.975 16.575 14.975C16.8417 14.975 17.075 15.075 17.275 15.275C17.475 15.475 17.575 15.7125 17.575 15.9875C17.575 16.2625 17.475 16.5 17.275 16.7L13.425 20.575C13.0417 20.9583 12.5667 21.15 12 21.15C11.4333 21.15 10.9583 20.9583 10.575 20.575L6.69999 16.7C6.49999 16.5 6.40415 16.2625 6.41249 15.9875C6.42082 15.7125 6.52499 15.475 6.72499 15.275C6.92499 15.075 7.16249 14.975 7.43749 14.975C7.71249 14.975 7.94999 15.075 8.14999 15.275L12 19.15ZM12 4.84998L8.14999 8.69998C7.94999 8.89998 7.71665 8.99581 7.44999 8.98748C7.18332 8.97914 6.94999 8.88331 6.74999 8.69998C6.54999 8.49998 6.44582 8.26248 6.43749 7.98748C6.42915 7.71248 6.52499 7.47498 6.72499 7.27498L10.575 3.42498C10.9583 3.04164 11.4333 2.84998 12 2.84998C12.5667 2.84998 13.0417 3.04164 13.425 3.42498L17.275 7.27498C17.475 7.47498 17.5708 7.71248 17.5625 7.98748C17.5542 8.26248 17.45 8.49998 17.25 8.69998C17.05 8.88331 16.8167 8.97914 16.55 8.98748C16.2833 8.99581 16.05 8.89998 15.85 8.69998L12 4.84998Z"
			fill="currentColor"
		/>
	</svg>
);
