import classNames from "classnames";
import React from "react";
import { useStyles } from "./styles";

interface IProps {
	center?: boolean;
	inline?: boolean;
	size?: "medium" | "small" | "big";
}

export const LoadingDots: FC<IProps> = ({ center, className, inline, size }) => {
	const classes = useStyles();

	const result = (
		<div className={classNames(classes.loadingDots, className, { inline, center }, size)}>
			<svg height="32" width="32" viewBox="0 0 32 32">
				<g fill="currentColor">
					<g className={classes.dots}>
						<circle cx="16" cy="6" fill="currentColor" r="5" />
						<circle cx="26" cy="16" r="5" />
						<circle cx="16" cy="26" fill="currentColor" r="5" />
						<circle cx="6" cy="16" r="5" />
					</g>
				</g>
			</svg>
		</div>
	);

	if (inline) {
		return <> {result} </>;
	}

	return result;
};
