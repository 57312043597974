import { Record } from "immutable";
import { DirectoryGroupModel } from "./DirectoryGroupModel";

const defaults = {
	id: "",
	maintainerGroupId: "",
	directoryGroup: null as DirectoryGroupModel | null,
	directoryGroupId: ""
};

export class DirectoryGroupMaintainerModel extends Record<typeof defaults>(defaults) implements IMaintainerModel {
	static fromServerData(data: unknown): DirectoryGroupMaintainerModel {
		const { id, maintainerGroupId, directoryGroup, directoryGroupId } = data as typeof defaults;
		return new DirectoryGroupMaintainerModel({
			id,
			maintainerGroupId,
			directoryGroup: directoryGroup ? DirectoryGroupModel.fromServerData(directoryGroup) : null,
			directoryGroupId
		});
	}

	static fromEntity(entity: DirectoryGroupModel): DirectoryGroupMaintainerModel {
		return new DirectoryGroupMaintainerModel({
			id: "",
			maintainerGroupId: "",
			directoryGroup: entity,
			directoryGroupId: entity.id
		});
	}

	get entityId(): string {
		return this.directoryGroupId;
	}

	get entity(): DirectoryGroupModel | null {
		return this.directoryGroup;
	}
}
