import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	container: {
		alignItems: "flex-start",
		display: "flex",
		flexDirection: "column",
		gap: "8px",
		justifyContent: "flex-start",
		width: "100%"
	}
});
