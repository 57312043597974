import React, { useCallback } from "react";
import { UserEntity } from "components/common/entities/UserEntity";
import { TooltipOnOverflow } from "components/ui/TooltipOnOverflow";
import { UserModel } from "models/UserModel";
import { INHERIT, type TInherit, type TOptionComponentProps } from "utils/ui/select";
import { SelectOption, TEntityOverrideProps } from "../SelectOption";

function UserAvatarOption<T extends UserModel | TInherit>({
	disabled = false,
	getTextContent,
	isSelected = false,
	onSelect,
	option,
	optionKey
}: TOptionComponentProps<T>) {
	const renderOption = useCallback(
		(entityProps: TEntityOverrideProps) => {
			if (option === INHERIT) {
				const content = getTextContent ? getTextContent(option) : option;
				return <TooltipOnOverflow content={content} textVariant="body_sb" />;
			}
			return <UserEntity withIcon user={option} {...entityProps} />;
		},
		[option, getTextContent]
	);
	return (
		<SelectOption
			onSelect={onSelect}
			isSelected={isSelected}
			disabled={disabled}
			value={option}
			key={optionKey}
			renderOption={renderOption}
		/>
	);
}

UserAvatarOption.isSelectOption = true;

export { UserAvatarOption };
