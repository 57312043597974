import React from "react";
import { makeIcon } from "components/ui/Icon";

export const GroupIcon = makeIcon(
	<svg viewBox="0 0 24 24" fill="none">
		<g>
			<path
				d="M1 18C0.716667 18 0.479167 17.9042 0.2875 17.7125C0.0958333 17.5208 0 17.2833 0 17V16.425C0 15.6917 0.366667 15.1042 1.1 14.6625C1.83333 14.2208 2.8 14 4 14C4.21667 14 4.42083 14.0083 4.6125 14.025C4.80417 14.0417 4.99167 14.0667 5.175 14.1C4.94167 14.4333 4.77083 14.7917 4.6625 15.175C4.55417 15.5583 4.5 15.9667 4.5 16.4V18H1ZM7 18C6.71667 18 6.47917 17.9042 6.2875 17.7125C6.09583 17.5208 6 17.2833 6 17V16.4C6 15.3167 6.55417 14.4375 7.6625 13.7625C8.77083 13.0875 10.2167 12.75 12 12.75C13.8 12.75 15.25 13.0875 16.35 13.7625C17.45 14.4375 18 15.3167 18 16.4V17C18 17.2833 17.9042 17.5208 17.7125 17.7125C17.5208 17.9042 17.2833 18 17 18H7ZM19.5 18V16.4C19.5 15.9667 19.4417 15.5583 19.325 15.175C19.2083 14.7917 19.0417 14.4333 18.825 14.1C19.0083 14.0667 19.1958 14.0417 19.3875 14.025C19.5792 14.0083 19.7833 14 20 14C21.2 14 22.1667 14.2208 22.9 14.6625C23.6333 15.1042 24 15.6917 24 16.425V17C24 17.2833 23.9042 17.5208 23.7125 17.7125C23.5208 17.9042 23.2833 18 23 18H19.5ZM4 13C3.45 13 2.97917 12.8042 2.5875 12.4125C2.19583 12.0208 2 11.55 2 11C2 10.45 2.19583 9.97917 2.5875 9.5875C2.97917 9.19583 3.45 9 4 9C4.55 9 5.02083 9.19583 5.4125 9.5875C5.80417 9.97917 6 10.45 6 11C6 11.55 5.80417 12.0208 5.4125 12.4125C5.02083 12.8042 4.55 13 4 13ZM20 13C19.45 13 18.9792 12.8042 18.5875 12.4125C18.1958 12.0208 18 11.55 18 11C18 10.45 18.1958 9.97917 18.5875 9.5875C18.9792 9.19583 19.45 9 20 9C20.55 9 21.0208 9.19583 21.4125 9.5875C21.8042 9.97917 22 10.45 22 11C22 11.55 21.8042 12.0208 21.4125 12.4125C21.0208 12.8042 20.55 13 20 13ZM12 12C11.1667 12 10.4583 11.7083 9.875 11.125C9.29167 10.5417 9 9.83333 9 9C9 8.16667 9.29167 7.45833 9.875 6.875C10.4583 6.29167 11.1667 6 12 6C12.8333 6 13.5417 6.29167 14.125 6.875C14.7083 7.45833 15 8.16667 15 9C15 9.83333 14.7083 10.5417 14.125 11.125C13.5417 11.7083 12.8333 12 12 12Z"
				fill="currentColor"
			/>
		</g>
	</svg>
);
