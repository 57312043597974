import { createUseStyles } from "react-jss";
import { REQUEST_BAR_HEIGHT } from "../RequestBar/styles";

export const GAP_SIZE = 8;

export const calculateHeight = (props: { shown?: number }) => {
	const { shown = 3 } = props;
	const barsHeight = shown * REQUEST_BAR_HEIGHT;
	const gapHeight = shown * GAP_SIZE;
	return barsHeight + gapHeight;
};

export const useStyles = createUseStyles({
	container: {
		display: "flex",
		flexDirection: "column",
		gap: `${GAP_SIZE}px`,
		height: (props: { shown?: number }) => `${calculateHeight(props || {})}px`,
		overflowX: "auto",
		overflowY: "auto"
	},
	infiniteContainer: {
		display: "grid",
		gap: `${GAP_SIZE}px`
	}
});
