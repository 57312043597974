import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { RoleBarIntegration } from "components/common/RoleBar/components/RoleBarIntegration";
import { RoleBarResource } from "components/common/RoleBar/components/RoleBarResource";
import { RoleBarRole, TSingleRoleProps } from "components/common/RoleBar/components/RoleBarRole";
import { RoleIcon } from "components/ui/Icons/RoleIcon";
import { TooltipOnOverflow } from "components/ui/TooltipOnOverflow";
import { useIntegrations } from "hooks/useIntegrations";
import { IntegrationModel } from "models/IntegrationModel";
import { IntegrationResourceModel } from "models/IntegrationResourceModel";
import { useStyles } from "./styles";
import { IntegrationImage } from "../IntegrationImage";
import { RoleBar, type TRoleBarColumn } from "../RoleBar/RoleBar";

const COLUMNS_WIDTHS = {
	integration: "172px" as const,
	resource: "minmax(172px, 1fr)" as const,
	role: "172px" as const
};

const IntegrationColumn: FC<{ integrationId: string }> = ({ integrationId }) => {
	const integrations = useIntegrations(true);
	const integration = integrations?.get(integrationId);
	const classes = useStyles();
	const { t } = useTranslation();

	if (!integrations) return null;

	return integration ? (
		<RoleBarIntegration imageUrl={integration.imageUrl} name={integration.name} isDeleted={integration.isDeleted} />
	) : (
		<div className={classes.columnContent}>
			<IntegrationImage noBackground className={classes.image} />
			<TooltipOnOverflow textVariant="body_reg" content={t("common.unknown.integration")} />
		</div>
	);
};

const ResourceColumn: FC<{ integrationResource?: IntegrationResourceModel }> = ({ integrationResource }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	return integrationResource ? (
		<RoleBarResource
			name={integrationResource.name}
			euid={integrationResource.euid}
			description={integrationResource.description}
			type={integrationResource.type}
			tags={integrationResource.calculatedTags}
			isDeleted={integrationResource.isDeleted}
			className={classes.columnContent}
		/>
	) : (
		<div className={classes.columnContent}>
			<TooltipOnOverflow textVariant="body_reg" content={t("common.unknown.resource")} />
		</div>
	);
};

export type TRole = TSingleRoleProps["role"] & { integrationResource?: IntegrationResourceModel | null };

const RoleColumn: FC<{ integrationResourceRole?: TRole }> = ({ integrationResourceRole }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	return integrationResourceRole ? (
		<RoleBarRole role={integrationResourceRole} />
	) : (
		<div className={classes.columnContent}>
			<RoleIcon size="large" />
			<TooltipOnOverflow textVariant="body_reg" content={t("common.unknown.role")} />
		</div>
	);
};

export const BasicRoleBar: FC<{
	role: TRole;
	resource?: IntegrationResourceModel;
	integration?: IntegrationModel;
	noInteractions?: boolean;
	selected?: boolean;
}> = ({ role, resource, integration, noInteractions, selected }) => {
	const columns: TRoleBarColumn[] = useMemo(
		() => [
			{
				content: <IntegrationColumn integrationId={integration?.id ?? role.integrationResource?.integrationId ?? ""} />,
				width: COLUMNS_WIDTHS.integration
			},
			{
				content: <ResourceColumn integrationResource={resource ?? role.integrationResource ?? undefined} />,
				width: COLUMNS_WIDTHS.resource
			},
			{ content: <RoleColumn integrationResourceRole={role} />, width: COLUMNS_WIDTHS.role }
		],
		[role, resource, integration?.id]
	);

	return <RoleBar selected={selected} noInteraction={noInteractions} id={role?.id} columns={columns} />;
};
