import { List, Record } from "immutable";
import { PrerequisitePermissionRoleModel } from "./PrerequisitePermissionRoleModel";

const defaults = {
	id: "",
	prerequisitePermissionId: "",
	prerequisitePermissionRoles: null as List<PrerequisitePermissionRoleModel> | null
};

export class PrerequisitePermissionClauseModel extends Record<typeof defaults>(defaults) {
	static fromServerData(data: unknown) {
		const { id, prerequisitePermissionId, prerequisitePermissionRoles } = data as typeof defaults;

		return new PrerequisitePermissionClauseModel({
			id,
			prerequisitePermissionId,
			prerequisitePermissionRoles: prerequisitePermissionRoles
				? List(prerequisitePermissionRoles.map(role => PrerequisitePermissionRoleModel.fromServerData(role)))
				: List()
		});
	}
}
