import { MutableRefObject, useCallback, useEffect, useRef, useState } from "react";
import { useDebounceFn } from "hooks/useDebounce";

export const useResizeDrag = <T extends Element>(
	ref: MutableRefObject<T | null>,
	initialSize: number,
	maxWidth: number,
	minWidth: number,
	reverseDragSide = false
) => {
	const [size, setSize] = useState(initialSize);
	const [isDragging, setIsDragging] = useState(false);
	const eventMouseStart = useRef<{ size: number; mouse: number }>();

	const validateAndSetSize = useCallback((curr: number, min: number, max: number) => {
		let newSize = curr;
		if (curr > max) newSize = max;
		else if (curr < min) newSize = min;
		if (newSize !== curr) setSize(newSize);
	}, []);
	const validateAndSetSizeDebounced = useDebounceFn(validateAndSetSize, 100);

	const onMouseMove = useCallback(
		(e: MouseEvent) => {
			if (eventMouseStart.current) {
				if (e.stopPropagation) e.stopPropagation();
				if (e.preventDefault) e.preventDefault();

				const delta = eventMouseStart.current.mouse - e.clientX;
				let newSize = eventMouseStart.current.size + (reverseDragSide ? -1 * delta : delta);

				if (newSize < minWidth) newSize = minWidth;
				else if (newSize > maxWidth) newSize = maxWidth;

				setSize(newSize);
			}
		},
		[maxWidth, minWidth, reverseDragSide]
	);

	const onMouseUp = useCallback(() => {
		eventMouseStart.current = undefined;
		setIsDragging(false);
		document.removeEventListener("mouseup", onMouseUp);
		document.removeEventListener("mousemove", onMouseMove);
	}, [onMouseMove]);

	const onMouseDown = useCallback(
		(e: MouseEvent) => {
			eventMouseStart.current = { size, mouse: e.clientX };
			setIsDragging(true);
			document.addEventListener("mouseup", onMouseUp);
			document.addEventListener("mousemove", onMouseMove);
		},
		[onMouseMove, onMouseUp, size]
	);

	useEffect(() => {
		return () => {
			document.removeEventListener("mouseup", onMouseUp);
			document.removeEventListener("mousemove", onMouseMove);
		};
	}, [onMouseMove, onMouseUp]);

	useEffect(() => {
		(ref.current as HTMLElement | null)?.addEventListener("mousedown", onMouseDown);
	}, [onMouseDown, ref]);

	useEffect(() => {
		validateAndSetSizeDebounced(size, minWidth, maxWidth);
	}, [size, minWidth, maxWidth, validateAndSetSizeDebounced]);

	return { size, refreshInitialSize: setSize, isDragging };
};
