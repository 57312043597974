import { Record } from "immutable";

type TNotificationIntegrationType = "slack" | "teams";

const defaults = {
	id: "",
	euid: "",
	name: "",
	type: "" as TNotificationIntegrationType
};

export class NotificationIntegrationModel extends Record<typeof defaults>(defaults) {
	static fromServerData(data: unknown) {
		return new NotificationIntegrationModel(data as typeof defaults);
	}
}
