import React, { useCallback } from "react";
import { RoleEntity } from "components/common/entities";
import { SelectOption, TEntityOverrideProps } from "../SelectOption";
import type { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";
import type { TOptionComponent } from "utils/ui/select";

const RoleOption: TOptionComponent<IntegrationResourceRoleModel> = ({
	disabled = false,
	isSelected = false,
	onSelect,
	option,
	optionKey
}) => {
	const renderOption = useCallback(
		(entityProps: TEntityOverrideProps) => (
			<RoleEntity withRoleBarTooltip withIcon noWrap relative role={option} {...entityProps} />
		),
		[option]
	);

	return (
		<SelectOption
			disabled={disabled}
			isSelected={isSelected}
			key={optionKey}
			onSelect={onSelect}
			value={option}
			renderOption={renderOption}
		/>
	);
};

RoleOption.isSelectOption = true;

export { RoleOption };
