import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ApprovalAlgorithm } from "components/common/ApprovalAlgorithm";
import { AreYouSureModal } from "components/common/AreYouSureModal";
import { EditorSuccessModal } from "components/common/EditorSuccessModal";
import { PageTitleContent } from "components/templates/PageTitleContentTemplate";
import { Button } from "components/ui/Button";
import { ErrorModal } from "components/ui/ErrorModal";
import { IconButton } from "components/ui/IconButton";
import { CloseIcon } from "components/ui/Icons/CloseIcon";
import { WorkflowEditorProvider, useWorkflowEditorContext } from "context/workflowEditorContext";
import useErrorModalState from "hooks/useErrorModalState";
import { useIsOpenState } from "hooks/useIsOpenState";
import { useLoadingState } from "hooks/useLoadingState";
import { useOnEscKeyDownEvent } from "hooks/useOnEscKeyDownEvent";
import { useStyles } from "./styles";

const TRANSLATION_PREFIX = "pages.workflows.workflowEditor";

const EditWorkflow: FC = () => {
	const { t } = useTranslation();
	const classes = useStyles();
	const navigate = useNavigate();
	const {
		state: { isModified, isValid, mode },
		actions: { saveWorkflow }
	} = useWorkflowEditorContext();
	const { isLoading, withLoader } = useLoadingState();
	const { isOpen: successModalIsOpen, open: openSuccessModal, close: closeSuccessModal } = useIsOpenState();
	const { isOpen: exitModalIsOpen, open: openExitModal, close: closeExitModal } = useIsOpenState();
	const { errorModalClose, errorModalError, errorModalIsOpen, errorModalSetError } = useErrorModalState();

	const exit = useCallback(() => {
		closeExitModal();
		navigate("/workflows");
	}, [closeExitModal, navigate]);

	const afterSaveSuccess = useCallback(() => {
		closeSuccessModal();
		exit();
	}, [closeSuccessModal, exit]);

	const handleSubmit = useCallback(async () => {
		try {
			if (!isValid) return;
			const result = await withLoader(saveWorkflow());
			if (result) {
				openSuccessModal();
			}
		} catch (error) {
			errorModalSetError(error as Error);
		}
	}, [errorModalSetError, isValid, openSuccessModal, saveWorkflow, withLoader]);

	const onExitClick = useCallback(() => {
		if (isModified) {
			openExitModal();
		} else {
			exit();
		}
	}, [exit, isModified, openExitModal]);

	useOnEscKeyDownEvent(onExitClick);

	return (
		<PageTitleContent>
			<ErrorModal error={errorModalError} isOpen={errorModalIsOpen} onClose={errorModalClose} />
			<EditorSuccessModal
				state={mode}
				isOpen={successModalIsOpen}
				editTitle={t("pages.workflows.workflowEditor.editSuccessModalTitle")}
				createTitle={t("pages.workflows.workflowEditor.createSuccessModalTitle")}
				content={t("pages.workflows.workflowEditor.successModalInformation")}
				onClose={afterSaveSuccess}
			/>
			<AreYouSureModal
				className={classes.exitModal}
				contentClassName={classes.exitModalContent}
				actionLabel={t("pages.workflows.workflowEditor.exitModal.action")}
				closeLabel={t("pages.workflows.workflowEditor.exitModal.leaveButton")}
				content={t("pages.workflows.workflowEditor.exitModal.content")}
				isOpen={exitModalIsOpen}
				onClose={closeExitModal}
				onAction={closeExitModal}
				onCancel={exit}
			/>
			<PageTitleContent.Header>
				<PageTitleContent.Header.Top>
					<IconButton>
						<CloseIcon onClick={onExitClick} />
					</IconButton>
				</PageTitleContent.Header.Top>
				<PageTitleContent.Header.Title title={t(`${TRANSLATION_PREFIX}.${mode}Title`)} />
			</PageTitleContent.Header>
			<PageTitleContent.Body className={classes.contentBody}>
				<ApprovalAlgorithm className={classes.approvalAlgorithm} useContext />
			</PageTitleContent.Body>
			<PageTitleContent.Footer>
				<PageTitleContent.Footer.Right>
					<Button size="medium" disabled={isLoading || !isValid} loading={isLoading} onClick={handleSubmit}>
						{t(`${TRANSLATION_PREFIX}.saveWorkflow`)}
					</Button>
				</PageTitleContent.Footer.Right>
			</PageTitleContent.Footer>
		</PageTitleContent>
	);
};

export const EditWorkflowPage: FC = () => {
	const params = useParams<{ workflowId: string }>();

	return (
		<WorkflowEditorProvider mode="edit" workflowId={params.workflowId}>
			<EditWorkflow />
		</WorkflowEditorProvider>
	);
};

export const CreateWorkflowPage: FC = () => {
	return (
		<WorkflowEditorProvider mode="create">
			<EditWorkflow />
		</WorkflowEditorProvider>
	);
};
