import { Route } from "routes/route";
import type { TRoutes } from "routes/routes";

export const flattenRoutes = ({
	routes,
	basePath = "",
	flattenedRoutes = [],
	baseAnimationOrder = 0,
	animationOrderDivider = 1
}: {
	routes: TRoutes;
	basePath?: string;
	flattenedRoutes?: Route[];
	baseAnimationOrder?: number;
	animationOrderDivider?: number;
}) => {
	Object.values(routes).forEach(route => {
		flattenedRoutes.push(
			new Route({
				matches: route.matches.map(match => basePath + match),
				Page: route.Page,
				pageId: route.pageId,
				animationOrder: route.animationOrder && baseAnimationOrder + route.animationOrder / animationOrderDivider,
				inAnimation: route.inAnimation,
				outAnimation: route.outAnimation,
				shouldRender: route.shouldRender,
				hideSideNav: route.hideSideNav
			})
		);
		if (route?.subRoutes) {
			route.matches.forEach(match => {
				flattenRoutes({
					routes: route.subRoutes!,
					basePath: basePath + match,
					flattenedRoutes,
					baseAnimationOrder: route.animationOrder,
					animationOrderDivider: animationOrderDivider * 10
				});
			});
		}
	});

	return flattenedRoutes;
};
