import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	overflowContainer: {
		overflow: "auto",
		width: "100%"
	},
	listContainer: {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start",
		gap: "var(--spacing-x3, 12px)",
		minWidth: "984px",
		overflow: "auto",
		paddingBottom: "var(--spacing-x2, 8px)",
		width: "100%"
	},
	barContainer: {
		padding: "0px var(--spacing-x4, 16px)",
		gap: "var(--spacing-x5, 20px)",
		display: "grid",
		gridTemplateColumns: "80px 1px minmax(84px, 1fr) 1px minmax(272px, 1fr) 1px minmax(248px, 1fr) 40px",
		width: "100%"
	},
	headersContainer: {
		height: "20px",
		border: "2px solid var(--color-white)"
	},
	ruleBarContainer: {
		padding: "var(--spacing-x4, 16px)",
		borderRadius: "20px",
		border: "2px solid var(--color-purple-50)",
		background: "var(--color-purple-50)",
		"&$disabled": {
			opacity: "0.5"
		},
		"&:hover": {
			background: "var(--color-purple-100)",
			"&$disabled, &$disabled:hover": {
				background: "var(--color-purple-50)",
				borderColor: "var(--color-white)",
				pointerEvents: "none"
			}
		},
		"&$selected": {
			borderColor: "var(--color-black)",
			"&$disabled, &$disabled:hover": {
				borderColor: "var(--color-white)",
				pointerEvents: "none"
			}
		}
	},
	ruleActionsCellContainer: {
		display: "flex",
		minHeight: "144px",
		flexDirection: "column",
		alignItems: "center"
	},
	ruleActionsContainer: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		padding: "var(--spacing-x4, 16px) 0px",
		gap: "var(--spacing-x4, 16px)",
		height: "100%"
	},
	priorityCellContainer: {
		display: "flex",
		justifyContent: "flex-start",
		alignItems: "center",
		flexDirection: "row"
	},
	priorityCellText: {
		width: "56px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center"
	},
	applyToCellContainer: {
		display: "flex",
		justifyContent: "center",
		gap: "var(--spacing-x1, 4px)",
		alignItems: "flex-start",
		flexDirection: "column"
	},
	applyToCellListContainer: {
		display: "flex",
		justifyContent: "flex-start",
		flexWrap: "wrap",
		alignItems: "center",
		gap: "var(--spacing-x3, 12px)",
		width: "100%"
	},
	conditionsCellContainer: {
		display: "flex",
		gap: "var(--spacing-x3, 12px)",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-start"
	},
	conditionContainer: {
		display: "flex",
		justifyContent: "flex-start",
		alignItems: "center",
		gap: "var(--spacing-x2, 8px)",
		height: "30px",
		width: "100%"
	},
	fieldNameText: {
		display: "flex",
		alignItems: "center",
		textWrap: "nowrap",
		alignSelf: "stretch",
		gap: "1ch"
	},
	conditionChips: {
		overflow: "hidden"
	},
	disabled: {
		pointerEvents: "none"
	},
	selected: {},
	ruleInfoContainer: {
		display: "flex",
		flexDirection: "column",
		padding: "var(--spacing-x3, 12px)",

		alignItems: "flex-start",
		alignSelf: "stretch"
	},
	fullWidth: {
		width: "100%",
		height: "100%"
	},
	draggable: {
		"&:hover": {
			cursor: "grab"
		}
	}
});
