import { Record, List } from "immutable";
import { IntegrationActorModel } from "./IntegrationActorModel";
import { IntegrationResourceRoleModel } from "./IntegrationResourceRoleModel";
import { TicketModel } from "./TicketModel";

export type TTicketPermissionStatus = "pending" | "granted" | "failed" | "revoked";
type TTicketPermissionType = "prerequisite" | "regular";

const defaults = {
	id: "",
	status: "" as TTicketPermissionStatus,
	integrationActor: null as IntegrationActorModel | null,
	integrationResourceRole: null as IntegrationResourceRoleModel | null,
	oldIntegrationResourceRole: null as IntegrationResourceRoleModel | null,
	virtualRole: null as IntegrationResourceRoleModel | null,
	virtualRoleId: null as string | null,
	ticket: null as TicketModel | null,
	expiresAt: null as Date | null,
	ticketTargetIds: List<string>(),
	type: "" as TTicketPermissionType
};

export class TicketPermissionModel extends Record<typeof defaults>(defaults) {
	static fromServerData(data: unknown) {
		const {
			id,
			status,
			integrationActor,
			integrationResourceRole,
			oldIntegrationResourceRole,
			ticket,
			expiresAt,
			type,
			virtualRole,
			ticketTargetIds,
			virtualRoleId
		} = data as typeof defaults;

		return new TicketPermissionModel({
			id,
			status,
			type,
			integrationActor: integrationActor ? IntegrationActorModel.fromServerData(integrationActor) : null,
			integrationResourceRole: integrationResourceRole
				? IntegrationResourceRoleModel.fromServerData(integrationResourceRole)
				: null,
			oldIntegrationResourceRole: oldIntegrationResourceRole
				? IntegrationResourceRoleModel.fromServerData(oldIntegrationResourceRole)
				: null,
			ticketTargetIds: List(ticketTargetIds) ?? List(),
			virtualRole: virtualRole ? IntegrationResourceRoleModel.fromServerData(virtualRole) : null,
			virtualRoleId,
			ticket: ticket ? TicketModel.fromServerData(ticket) : null,
			expiresAt: expiresAt ? new Date(expiresAt) : null
		});
	}

	get role() {
		return this.virtualRole || this.integrationResourceRole;
	}
}
