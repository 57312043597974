import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { UserEntity } from "components/common/entities/UserEntity";
import { PageTitleContent } from "components/templates/PageTitleContentTemplate";
import { StaticChip } from "components/ui/chips/StaticChip";
import { RoleIcon } from "components/ui/Icons/RoleIcon";
import { UserCircleIcon } from "components/ui/Icons/UserCircleIcon";
import { Input } from "components/ui/Input";
import { Section } from "components/ui/Section";
import { Typography } from "components/ui/Typography";
import { PaginatedVirtualTable } from "components/ui/VirtualTable";
import { HeaderCellContent } from "components/ui/VirtualTable/components";
import { useUsersSelect } from "hooks/useUsersSelect";
import { UserModel } from "models/UserModel";
import { useStyles } from "./styles";
import type { TVirtualTableWidth } from "components/ui/VirtualTable/types";

const PER_PAGE = 50;

export const UsersPage: FC = ({ className }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [query, setQuery] = useState("");
	const {
		itemsForVirtualTable: users,
		isLoading,
		totalResults,
		getPage
	} = useUsersSelect(query, { perPage: PER_PAGE });
	const userList = useMemo(() => users || [], [users]);

	const columns = useMemo(
		() => [
			{
				header: <HeaderCellContent text={t("pages.users.columns.name")} icon={<UserCircleIcon />} />,
				key: "email",
				renderCell: (row: UserModel) => (
					<div className={classes.cell}>
						<UserEntity withIcon user={row} />
					</div>
				),
				width: "1fr" as TVirtualTableWidth
			},
			{
				header: <HeaderCellContent text={t("pages.users.columns.role")} icon={<RoleIcon />} />,
				key: "role",
				renderCell: (row: UserModel) => (
					<div className={classes.cell}>
						<Typography variant="body_reg">{t(`shared.roles.${row.role}`)}</Typography>
					</div>
				),
				width: "1fr" as TVirtualTableWidth
			}
		],
		[classes.cell, t]
	);

	const fetchNextPage = useCallback(
		(page: number) => {
			void getPage(page, true);
		},
		[getPage]
	);

	const onClick = useCallback(
		(data: UserModel) => {
			navigate(`/users/${data.id}`);
		},
		[navigate]
	);

	const title = useMemo(() => {
		return (
			<>
				<Typography variant="body_med"> {t("pages.user.total")}</Typography>
				<StaticChip size="small" variant="regular">
					{t("number", { value: totalResults })}
				</StaticChip>
			</>
		);
	}, [t, totalResults]);

	const actions = useMemo(() => {
		return (
			<Input
				className={classes.input}
				placeholder={t("pages.users.search")}
				size="large"
				onValueChange={setQuery}
				value={query}
				variant="search"
			/>
		);
	}, [classes.input, query, t]);

	return (
		<PageTitleContent className={className}>
			<PageTitleContent.Header>
				<Typography variant="header_sb">{t("pages.users.title")}</Typography>
			</PageTitleContent.Header>
			<PageTitleContent.Body>
				<Section fullHeight title={title} titleActions={actions}>
					<PaginatedVirtualTable
						isLoading={isLoading}
						className={classes.table}
						rows={userList}
						totalRows={totalResults}
						columns={columns}
						perPage={PER_PAGE}
						fetchPage={fetchNextPage}
						onRowClicked={onClick}
						selectableTrigger="manual"
						selectable
					/>
				</Section>
			</PageTitleContent.Body>
		</PageTitleContent>
	);
};
