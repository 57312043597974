import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	checkboxInput: {
		display: "flex",
		gap: "0.5rem",
		margin: (props?: { noMargin?: boolean }) => (props?.noMargin ? 0 : "0.5em"),
		width: "fit-content",
		cursor: "pointer",
		"&$disabled": {
			color: "var(--color-grey-disabled)",
			cursor: "inherit"
		}
	},
	minusSelected: {
		fontSize: "1rem",
		width: "1rem",
		stroke: "var(--color-purple)",
		height: "1rem",
		minWidth: "unset",
		"&$disabled": {
			stroke: "var(--color-grey)"
		}
	},
	checkboxFrame: {
		alignItems: "center",
		alignSelf: "center",
		background: "transparent",
		border: "1px solid var(--color-grey)",
		borderRadius: "1.5px",
		display: "flex",
		flexDirection: "row",
		height: "0.99rem",
		justifyContent: "center",
		width: "1rem",
		"&$withDescription": {
			alignSelf: "flex-start"
		},
		"&$selected": {
			border: "1px solid var(--color-purple)",
			"&:hover:not($disabled)": {
				opacity: "0.5",
				cursor: "pointer",
				border: "1px solid var(--color-grey)",
				"& $checkboxFill": {
					color: "var(--color-grey)"
				}
			},
			"&$disabled": {
				border: "1px solid var(--color-grey-disabled)",
				cursor: "default"
			}
		},
		"&$partialSelected": {
			border: "1px solid var(--color-purple)",
			"&:hover:not($disabled)": {
				opacity: "0.5",
				cursor: "pointer",
				border: "1px solid var(--color-purple)"
			},
			"&:hover": {
				opacity: "0.5"
			},
			"&$disabled": {
				border: "1px solid var(--color-grey-disabled)",
				cursor: "default"
			}
		},
		"&$allSelected": {
			border: "1px solid var(--color-purple)",
			background: "var(--color-blue)",
			"&:hover:not($disabled)": {
				opacity: "0.5",
				cursor: "pointer",
				border: "1px solid var(--color-purple)"
			}
		},
		"&:hover:not($disabled)": {
			opacity: "0.5",
			cursor: "pointer",
			border: "1px solid var(--color-purple)"
		},
		"&:hover:not($disabled) $checkboxFill": {
			opacity: "1"
		}
	},
	checkboxFill: {
		strokeWidth: "2",
		width: "1rem",
		height: "1rem",
		fontSize: "1rem",
		minWidth: "unset",
		color: "var(--color-purple)",
		opacity: "0",
		"&$selected": {
			opacity: "1",
			"&$disabled": {
				color: "var(--color-grey)"
			}
		},
		"&$allSelected": {
			color: "var(--color-almost-white)",
			opacity: "1",
			"&$disabled": {
				color: "var(--color-grey)"
			}
		},
		"&$disabled": {
			color: "var(--color-grey)"
		}
	},
	descriptionContainer: {
		display: "flex",
		flexDirection: "column"
	},
	description: {
		whiteSpace: "pre-wrap"
	},
	disabled: {},
	selected: {},
	allSelected: {},
	partialSelected: {},
	withDescription: {}
});
