import classNames from "classnames";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getIntegrationIcon } from "components/common/IntegrationImage";
import { useNewRequestFormContext } from "components/pages/NewRequestPage/newRequestFormContext";
import { Button } from "components/ui/Button";
import { IconPrefix } from "components/ui/IconPrefix";
import { Modal } from "components/ui/Modal";
import { TitleBody } from "components/ui/TitleBody";
import { useStyles } from "./styles";
import type { List } from "immutable";
import type { IntegrationModel } from "models/IntegrationModel";

type TProps = {
	close: () => void;
	isOpen: boolean;
	missingActorIntegrations: List<IntegrationModel> | null;
};

const Integration: FC<{ integration?: IntegrationModel }> = ({ integration }) => {
	if (!integration) return null;
	return <IconPrefix size="huge" semibold Icon={getIntegrationIcon(integration)} content={integration.name} />;
};

export const ReceiverMissingActorModal: FC<TProps> = ({
	className,
	missingActorIntegrations,
	innerRef,
	isOpen,
	close
}) => {
	const { t } = useTranslation("translation", { keyPrefix: "pages.newRequest.summaryStep.receiverMissingActorModal" });
	const classes = useStyles();
	const {
		state: { fullReceiverUser }
	} = useNewRequestFormContext();

	const modalContent = useMemo(() => {
		const count = missingActorIntegrations?.size || 0;
		if (!missingActorIntegrations || count === 0) return null;
		return (
			<div className={classes.content}>
				<TitleBody
					size="large"
					title={t("title")}
					body={t("fillInformation", { receiverUser: fullReceiverUser?.fullName })}
				/>
				{missingActorIntegrations
					.map(integration => <Integration key={integration.id} integration={integration} />)
					.toArray()}
			</div>
		);
	}, [missingActorIntegrations, classes.content, t, fullReceiverUser?.fullName]);

	const modalActions = useMemo(() => {
		return (
			<>
				<Button size="medium" onClick={close}>
					{t("editRequest")}
				</Button>
			</>
		);
	}, [close, t]);

	if (!modalContent) return null;
	return (
		<Modal
			actions={modalActions}
			className={classNames(classes.modal, className)}
			content={modalContent}
			innerRef={innerRef}
			isOpen={isOpen}
			onClose={close}
		/>
	);
};
