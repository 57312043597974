import { ErrorObject } from "ajv";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { fromSnakeToCamel } from "api/directoryConfigurations";
/* eslint-disable camelcase */
import { JSONTextArea } from "components/common/JSONTextArea";
import { Form } from "components/ui/Form";
import { Input } from "components/ui/Input";
import { TJsonSchema } from "types/utilTypes";
import { schemaToEmptyJson } from "utils/jsonSchema";
import { removeRedundantSpaces } from "utils/strings";
import { CommonConfigFields } from "../CommonConfigFields";
import type { IConfigForm, TConfigurationSchema } from "types/directoryConfiguration";

const GOOGLE_SCHEMA = {
	additionalProperties: false,
	type: "object",
	required: [
		"type",
		"project_id",
		"private_key_id",
		"private_key",
		"client_email",
		"client_id",
		"auth_uri",
		"token_uri",
		"auth_provider_x509_cert_url",
		"client_x509_cert_url"
	],
	properties: {
		type: { type: "string" },
		project_id: { type: "string" },
		private_key_id: { type: "string" },
		private_key: { type: "string" },
		client_email: { type: "string" },
		client_id: { type: "string" },
		auth_uri: { type: "string" },
		token_uri: { type: "string" },
		auth_provider_x509_cert_url: { type: "string" },
		client_x509_cert_url: { type: "string" }
	}
} as TJsonSchema;

type TGoogleServiceConfiguration = {
	type: string;
	project_id: string;
	private_key_id: string;
	private_key: string;
	client_email: string;
	client_id: string;
	auth_uri: string;
	token_uri: string;
	auth_provider_x509_cert_url: string;
	client_x509_cert_url: string;
};

export const GoogleConfigurationForm: FC<IConfigForm> = ({ onSave, error, enableSave, shouldReset, inEditMode }) => {
	const { t } = useTranslation();
	const [googleServiceConfigJson, setGoogleConfigJson] = useState<TGoogleServiceConfiguration>(
		schemaToEmptyJson(GOOGLE_SCHEMA)! as TGoogleServiceConfiguration
	);
	const [credentialSubject, setCredentialSubject] = useState("");
	const [configurationErrors, setConfigurationErrors] = useState<string[] | undefined>(undefined);

	const isValid = useMemo(() => {
		return (
			enableSave &&
			!!(
				removeRedundantSpaces(googleServiceConfigJson.type) &&
				removeRedundantSpaces(googleServiceConfigJson.project_id) &&
				removeRedundantSpaces(googleServiceConfigJson.private_key) &&
				removeRedundantSpaces(googleServiceConfigJson.private_key_id) &&
				removeRedundantSpaces(googleServiceConfigJson.client_email) &&
				removeRedundantSpaces(googleServiceConfigJson.client_id) &&
				removeRedundantSpaces(googleServiceConfigJson.auth_uri) &&
				removeRedundantSpaces(googleServiceConfigJson.token_uri) &&
				removeRedundantSpaces(googleServiceConfigJson.auth_provider_x509_cert_url) &&
				removeRedundantSpaces(googleServiceConfigJson.client_x509_cert_url) &&
				removeRedundantSpaces(credentialSubject)
			)
		);
	}, [googleServiceConfigJson, credentialSubject, enableSave]);

	const onJsonChanged = useCallback(
		(value: Record<string, unknown>) => setGoogleConfigJson(value as TGoogleServiceConfiguration),
		[]
	);

	const onError = useCallback((errors: Partial<ErrorObject>[] | null) => {
		if (errors) {
			setConfigurationErrors(
				errors?.map(
					({ message, instancePath }) => `${instancePath ? instancePath.substring(1) + " " : ""}${message || ""}`
				)
			);
		}
	}, []);

	const submit = useCallback(
		async (agentTokenId?: string | null, isDirectManagerSource?: boolean) => {
			if (!isValid) return;
			const configuration = {
				service_account_key: googleServiceConfigJson,
				credential_subject: credentialSubject
			};
			await onSave(fromSnakeToCamel(configuration) as TConfigurationSchema, isDirectManagerSource, agentTokenId);
		},
		[googleServiceConfigJson, credentialSubject, onSave, isValid]
	);

	useEffect(() => {
		if (shouldReset) {
			setGoogleConfigJson(schemaToEmptyJson(GOOGLE_SCHEMA)! as TGoogleServiceConfiguration);
			setCredentialSubject("");
		}
	}, [shouldReset]);

	return (
		<>
			<Form.Field>
				<JSONTextArea
					autoResize
					disabled={!enableSave}
					errors={configurationErrors}
					onChange={onJsonChanged}
					onError={onError}
					validationSchema={GOOGLE_SCHEMA}
					value={googleServiceConfigJson}
				/>
			</Form.Field>
			<Form.Field>
				<Input
					label={t("pages.settings.directoryConfiguration.google.credentialSubject")}
					value={credentialSubject}
					onValueChange={setCredentialSubject}
					isRequired
				/>
			</Form.Field>
			<CommonConfigFields inEditMode={inEditMode} onSave={submit} error={error} disabled={!isValid} />
		</>
	);
};
