import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IntegrationLogsSingleSelectFilterExpression } from "../IntegrationLogsSingleSelectFilterExpression";

type TEntitleLogsFilterExpressionProps = {
	onRemove: () => void;
};

export const EntitleLogsFilterExpression: FC<TEntitleLogsFilterExpressionProps> = ({ onRemove }) => {
	const { t } = useTranslation();
	const { include, entitleLogsState } = useMemo(() => {
		const include = { value: " ", label: t("pages.auditLog.integrationLogs.filter.labels.include") };
		const exclude = {
			value: "entitle",
			label: t("pages.auditLog.integrationLogs.filter.labels.exclude")
		};
		const entitleLogsState = [include, exclude];
		return { include, entitleLogsState };
	}, [t]);

	return (
		<IntegrationLogsSingleSelectFilterExpression
			singleChoiceOptions={entitleLogsState || []}
			title={t("pages.auditLog.integrationLogs.filter.entitleLogs")}
			onFilterRemove={onRemove}
			filterName="source"
			initialOperator="isNot"
			initialValue={include.label}
		/>
	);
};
