import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	container: {
		alignItems: "flex-start",
		display: "flex",
		flexDirection: "column",
		gap: "var(--spacing-x10, 40px)",
		width: "100%"
	},
	header: {
		display: "flex",
		flexDirection: "column",
		gap: "var(--spacing-x2, 8px)",
		width: "100%"
	},
	title: {
		alignItems: "center",
		display: "flex",
		gap: "var(--spacing-x3, 12px)",
		width: "100%"
	},
	titleLeft: {
		alignItems: "center",
		display: "flex",
		flexGrow: 1,
		gap: "var(--spacing-x3, 12px)"
	}
});
