import { createUseStyles } from "react-jss";
import { INTEGRATION_NODE_WIDTH } from "components/common/entities/Nodes/IntegrationNode/styles";
import { BP_MEDIA_QUERIES } from "utils/ui/breakpoints";

const GAP_SIZE = 12;
const EXTRA_PERMISSIONS_TEXT_WIDTH = 36;

export const useStyles = createUseStyles({
	container: {
		display: "none",
		gap: `${GAP_SIZE}px`,
		[BP_MEDIA_QUERIES.THIRTY]: {
			display: "flex",
			alignItems: "center",
			flexWrap: "nowrap"
		},
		width: ({ nodesAmount = 2 }: { nodesAmount?: number }) =>
			`${nodesAmount * INTEGRATION_NODE_WIDTH + nodesAmount * GAP_SIZE + EXTRA_PERMISSIONS_TEXT_WIDTH}px`
	},
	textAndChip: {
		alignItems: "center",
		display: "flex",
		flexGrow: 1,
		justifyContent: "space-between",
		overflow: "hidden",
		width: "100%"
	},
	skeletonText: {
		alignSelf: "center",
		animation: "$skeleton-loading 1s linear infinite alternate",
		backgroundColor: "var(--color-grey-300)",
		borderRadius: "12px",
		display: "flex",
		height: "1em",
		width: "50%"
	},
	skeletonIcon: {
		animation: "$skeleton-loading 1s linear infinite alternate",
		backgroundColor: "var(--color-grey-300)",
		borderRadius: "8px",
		height: "1.25em",
		width: "1.25em"
	},
	nodeSkeleton: {
		borderColor: "var(--color-blue-400)",
		"& *": {
			borderColor: "var(--color-blue-400)"
		}
	},
	resourceInformation: {
		alignItems: "center",
		display: "flex",
		flexDirection: "row",
		gap: "var(--spacing-x1, 4px)",
		minWidth: 0
	},
	"@keyframes skeleton-loading": {
		"0%": {
			backgroundColor: "var(--color-grey-200)"
		},
		"50%": {
			backgroundColor: "var(--color-grey-300)"
		},
		"100%": {
			backgroundColor: "var(--color-grey-400)"
		}
	}
});
