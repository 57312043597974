import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	statusChip: {
		float: "right",
		backgroundColor: "var(--color-grey-background)",
		"&$positive": {
			backgroundColor: "var(--color-green-10)"
		},
		"&$negative": {
			backgroundColor: "var(--color-red-10)"
		}
	},
	statusChipContainer: {
		flex: 1,
		display: "flex",
		margin: "-1rem 0",
		justifyContent: "flex-end",
		alignItems: "center"
	},
	positive: {},
	negative: {},
	statusIcon: {
		fontColor: "var(--color-grey-disabled)"
	}
});
