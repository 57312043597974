import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	step: {
		display: "flex",
		gap: "8px",
		flexDirection: "column"
	},
	approvers: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		gap: "0.5rem",
		height: "100%",
		minWidth: "max-content",
		"&:not(:last-child)": {
			borderBottom: "1px solid var(--color-grey-light)",
			paddingBottom: "8px",
			width: "100%"
		}
	},
	operator: {
		background: "var(--color-almost-white)",
		padding: "0.25rem 0.5rem",
		borderRadius: "var(--border-radius-rounder)",
		border: "1px solid var(--color-grey-light)",
		width: "fit-content",
		fontSize: "0.75rem"
	},
	entities: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		gap: "0.5rem"
	},
	entity: {
		background: "var(--color-white)",
		padding: "0.25rem 0.4rem",
		border: "1px solid var(--color-grey-light)",
		borderRadius: "var(--border-radius-sharp)",
		fontSize: "0.9rem"
	},
	arrow: {
		height: "1.2rem",
		width: "1rem",
		"&$horizontal": {
			transform: "rotate(-90deg)"
		}
	},
	vertical: {},
	horizontal: {}
});
