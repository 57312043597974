import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { TokenDurationSelectInput } from "components/common/Token/TokenDurationSelectInput";
import { TextCellContent } from "components/ui/VirtualTable/components";
import { checkIfTokenIsNew } from "../../../utils";
import type { TPersonalAccessToken } from "../../../PersonalAccessTokensTableSection";

type TProps = {
	token: TPersonalAccessToken;
	setDuration: (value: number | null) => void;
	duration: number | null;
};

export const ExpirationCell: FC<TProps> = ({ token, duration, setDuration }) => {
	const { t } = useTranslation();

	const handleDurationChange = useCallback(
		(value: number | null) => {
			setDuration(value);
		},
		[setDuration]
	);

	return checkIfTokenIsNew(token) ? (
		<TokenDurationSelectInput onChange={handleDurationChange} value={duration || ""} />
	) : (
		<TextCellContent
			text={
				token.expiresAt && token.expiresAt < new Date()
					? t("pages.settings.tokens.expired")
					: token.expiresAt
						? t("dateTime.date", { date: token.expiresAt })
						: t("shared.ticketExpiration.never")
			}
		/>
	);
};
