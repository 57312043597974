import React from "react";
import { Chip, IChipProps } from "components/ui/chips/Chip";
import { useStyles } from "./styles";
import type { IRowOptions } from "../../types";

type IChipCellContentProps = IChipProps & IRowOptions & { text: string };

const ChipCellContent: FC<IChipCellContentProps> = ({ size, text, ...props }) => {
	const classes = useStyles();
	return (
		<div className={classes.centeredCellContent}>
			<Chip size={size} {...props}>
				{text}
			</Chip>
		</div>
	);
};

const ChipCellContentMemo = React.memo(ChipCellContent) as typeof ChipCellContent;

export { ChipCellContentMemo as ChipCellContent };
