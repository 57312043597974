import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	fieldsContainer: {
		display: "flex",
		justifyContent: "flex-start",
		alignItems: "flex-start",
		flexDirection: "column",
		gap: "var(--spacing-x2, 8px)",
		width: "100%"
	}
});
