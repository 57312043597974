import { useMemo } from "react";
import { getTicketExpirationDate } from "utils/tickets/ticketExpirationDate";
import { useIntegrations } from "./useIntegrations";
import type { List } from "immutable";
import type { IntegrationResourceModel } from "models/IntegrationResourceModel";
import type { TicketPermissionModel } from "models/TicketPermissionModel";

export const usePermission = (
	resource: IntegrationResourceModel | null,
	ticketPermissions: List<TicketPermissionModel> | null
) => {
	const expiresAt = getTicketExpirationDate(ticketPermissions);

	const integrations = useIntegrations();
	const integration = useMemo(
		() => integrations?.find(integration => integration.id === resource?.integrationId),
		[integrations, resource?.integrationId]
	);

	return {
		expiresAt,
		integration
	};
};
