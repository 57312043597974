import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	page: {
		paddingBottom: "1rem",
		borderRadius: "var(--border-radius-semi-rounded)",
		overflow: "hidden"
	},
	resourcePage: {
		display: "flex",
		flexDirection: "column",
		height: "100%",
		borderRadius: "var(--border-radius-semi-rounded)"
	},
	header: {
		padding: "1.4em",
		borderRadius: "var(--border-radius-semi-rounded) var(--border-radius-semi-rounded) 0 0"
	},
	headerTitle: {
		marginRight: "1.1em"
	},
	headerOwner: {},
	separator: {
		height: "1.2em",
		alignSelf: "center",
		borderRight: "1px solid var(--color-grey-light)"
	},
	content: {
		display: "flex",
		flexDirection: "column",
		gap: "1.1em",
		padding: "1.5em 2.25em",
		overflow: "auto"
	},
	actors: {},
	table: {
		color: "var(--color-grey-dark)"
	},
	spinner: {
		margin: "auto"
	}
});
