import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { CloseCircleIcon } from "components/ui/Icons/CloseCircleIcon";
import { GrantedIcon } from "components/ui/Icons/GrantedIcon";
import { Typography } from "components/ui/legacy/Typography";
import { Table } from "components/ui/Table";
import { useStyles } from "./styles";

type TProps = {
	isIntegrated?: boolean;
	integration: string | React.JSX.Element;
	SystemLogo: FC | undefined;
	actions: ReactNode;
	additionalStatus?: ReactNode;
};

export const IntegrationRow: FC<TProps> = ({ isIntegrated, SystemLogo, integration, actions, additionalStatus }) => {
	const { t } = useTranslation();
	const classes = useStyles();

	return (
		<Table.Row>
			<Table.Cell>
				{SystemLogo && <SystemLogo className={classes.systemLogo} />}
				<Typography>{integration}</Typography>
			</Table.Cell>
			<Table.Cell>
				{isIntegrated ? (
					<>
						<GrantedIcon className={classes.connectedStatusIcon} />
						<Typography>{t("pages.settings.integrations.connected")}</Typography>
					</>
				) : (
					<>
						<CloseCircleIcon className={classes.notConnectedStatusIcon} />
						<Typography>{t("pages.settings.integrations.disconnected")}</Typography>
					</>
				)}
				{additionalStatus}
			</Table.Cell>
			<Table.Cell className={classes.actions}>{actions}</Table.Cell>
		</Table.Row>
	);
};
