import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { getIntegrationResourceTypes } from "api/integrationResources";
import { FilterExpressionEmptyState } from "components/common/FilterExpressionEmptyState";
import { Chip } from "components/ui/chips/Chip";
import { FilterExpression } from "components/ui/filters/FilterExpression";
import { ResourcesIcon } from "components/ui/Icons/ResourcesIcon";
import { TextOption } from "components/ui/selectOptions/TextOption";
import { OPERATORS } from "filters/integrationResource/integrationResourceTypeFilter";
import { useMultiSelectFilterExpression } from "../useFilterExpression";
import type { TConditionName } from "filters/condition.interface";
import type { TFilterExpressionProps } from "../filters.types";

const FILTER_NAME: TConditionName = "resourceType";
export const IntegrationResourceTypeFilterExpression = ({
	className,
	innerRef,
	filter,
	updateFilter,
	removeFilter,
	filterName
}: TFilterExpressionProps<"resourceType">) => {
	const { t } = useTranslation("translation", { keyPrefix: "filters" });

	const renderSelected = useCallback(
		(value: string) => {
			const onDelete = () =>
				updateFilter(filterName, {
					...filter,
					value: filter.value.filter(val => val !== value)
				});
			return (
				<Chip onDelete={onDelete} PrefixIcon={ResourcesIcon} variant="regular" size="large" selected>
					{value}
				</Chip>
			);
		},
		[updateFilter, filter, filterName]
	);

	const { onOptionSelect, onOperatorSelect, options, isLoading, getMoreOptions, onReset, onRemoveFilter } =
		useMultiSelectFilterExpression<"resourceType">(
			{ filter, updateFilter, removeFilter, filterName },
			getIntegrationResourceTypes,
			"is"
		);

	const getOptionLabel = useCallback((value: string) => value, []);

	return (
		<FilterExpression
			className={className}
			emptyState={<FilterExpressionEmptyState text={t(`emptyState.${FILTER_NAME}`)} />}
			getMoreOptions={getMoreOptions}
			getOptionLabel={getOptionLabel}
			innerRef={innerRef}
			inputPlaceholder={t(`placeholders.${FILTER_NAME}`)}
			isLoading={isLoading}
			onOperatorSelect={onOperatorSelect}
			onOptionSelect={onOptionSelect}
			onRemoveFilter={onRemoveFilter}
			onReset={onReset}
			operators={OPERATORS}
			optionRenderer={TextOption}
			options={options}
			relation="or"
			renderSelected={renderSelected}
			selected={filter.value}
			selectedOperator={filter.operator}
			title={t(`filterNames.${FILTER_NAME}`)}
			type="multiSelect"
		/>
	);
};
