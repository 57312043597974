import { Record } from "immutable";
import uniq from "lodash/uniq";
import { validateFilterInclusionOperator, validateFilterRelation } from "utils/validation/filterValidation";
import type { IFilter, TFilterOperator } from "types/filters";

export const OPERATORS = ["contains", "notContains"] as const satisfies TFilterOperator[];

const filterName = "integrationResourceName";

const defaults = {
	name: filterName as typeof filterName,
	value: [] as string[],
	operator: "contains" as (typeof OPERATORS)[number],
	relation: "or" as "and" | "or"
};

type TDefaults = typeof defaults;

export class IntegrationResourceNameFilter
	extends Record<TDefaults>(defaults)
	implements IFilter<TDefaults["value"], TDefaults["operator"], TDefaults["name"]>
{
	static filterName: typeof filterName = filterName;

	static fromURLSearchParams(urlSearchParams: URLSearchParams): IntegrationResourceNameFilter | null {
		const integrationResourceName = uniq(urlSearchParams.getAll("integrationResourceName"));
		const integrationResourceNameOperator = urlSearchParams.get("integrationResourceNameOperator") || undefined;
		const integrationResourceNameRelation = urlSearchParams.get("integrationResourceNameRelation") || undefined;
		if (
			integrationResourceName.length === 0 ||
			!validateFilterInclusionOperator(integrationResourceNameOperator) ||
			!validateFilterRelation(integrationResourceNameRelation)
		) {
			return null;
		}
		return new IntegrationResourceNameFilter({
			value: integrationResourceName,
			operator: integrationResourceNameOperator,
			relation: integrationResourceNameRelation
		});
	}

	toURLSearchParams(): URLSearchParams {
		const params = new URLSearchParams();
		if (!this.value.length) {
			return params;
		}
		for (const value of uniq(this.value)) {
			params.append("integrationResourceName", value);
		}
		params.append("integrationResourceNameOperator", this.operator);
		params.append("integrationResourceNameRelation", this.relation);
		return params;
	}
}

export type TIntegrationResourceNameFilter = Omit<TDefaults, "name">;
