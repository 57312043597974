import { createUseStyles } from "react-jss";

const ROLE_ROW_HEIGHT = 38;
const GAP_SIZE = 4;
const PADDING_SIZE = 8;
const MAX_HEIGHT = ROLE_ROW_HEIGHT * 6 + GAP_SIZE * 5 + PADDING_SIZE; // height times visible amount of roles and the gap in the middle

export const useStyles = createUseStyles({
	container: {
		display: "flex",
		flexDirection: "column",
		gap: "var(--spacing-x2)",
		width: "100%"
	},
	rolesList: {
		alignItems: "flex-start",
		display: "flex",
		flexDirection: "column",
		gap: "var(--spacing-x1)",
		maxHeight: `${MAX_HEIGHT}px`,
		overflow: "auto",
		padding: "var(--spacing-x1) 0"
	},
	roleRow: {
		alignItems: "center",
		display: "grid",
		gap: "var(--spacing-x3)",
		gridTemplateColumns: "1fr auto",
		height: `${ROLE_ROW_HEIGHT}px`,
		minHeight: `${ROLE_ROW_HEIGHT}px`,
		padding: "var(--spacing-x2) var(--spacing-x4) var(--spacing-x2) var(--spacing-x7)",
		width: "100%",
		"&:hover": {
			backgroundColor: "var(--color-blue-200)"
		}
	},
	tooltipContent: {
		alignItems: "flex-start",
		alignSelf: "stretch",
		display: "flex",
		flexDirection: "column",
		gap: "var(--spacing-x2)",
		padding: "var(--spacing-x5)"
	},
	modalContent: {
		alignItems: "flex-start",
		display: "flex",
		flexDirection: "column",
		gap: "var(--spacing-x8)",
		padding: "0px var(--spacing-x3) var(--spacing-x12) var(--spacing-x6)",
		width: "1012px"
	},
	modalSectionHeader: {
		paddingLeft: "var(--spacing-x5)",
		paddingRight: "var(--spacing-x3)",
		width: "660px"
	},
	modalIconText: {
		alignItems: "center",
		display: "flex",
		gap: "var(--spacing-x3)"
	},
	modalSection: {
		alignItems: "flex-start",
		alignSelf: "stretch",
		display: "flex",
		flexDirection: "column",
		gap: "var(--spacing-x2)",
		maxHeight: "360px"
	}
});
