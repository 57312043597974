import { useNewTicketFormContext } from "../../../newTicketContext";

export const useTabState = () => {
	const {
		state: {
			receiverUser,
			formState: { selectedItems }
		},
		actions: {
			formActions: { setSelectedItems, getIsItemConflicted }
		}
	} = useNewTicketFormContext();

	return { receiverUser, selectedItems, setSelectedItems, getIsItemConflicted };
};
