import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "components/ui/Form";
import { Input } from "components/ui/Input";
import { TextAreaInput } from "components/ui/TextAreaInput";
import { removeRedundantSpaces } from "utils/strings";
import { CommonConfigFields } from "../CommonConfigFields";
import type { IConfigForm } from "types/directoryConfiguration";

export const JumpCloudConfigurationForm: FC<IConfigForm> = ({ onSave, error, enableSave, shouldReset, inEditMode }) => {
	const { t } = useTranslation();
	const [url, setUrl] = useState("");
	const [token, setToken] = useState("");

	const isValid = useMemo(() => {
		return enableSave && !!(removeRedundantSpaces(url) && removeRedundantSpaces(token));
	}, [enableSave, token, url]);

	const submit = useCallback(
		async (agentTokenId?: string | null, isDirectManagerSource?: boolean) => {
			if (!isValid) return;

			const configuration = { url, token };
			await onSave(configuration, isDirectManagerSource, agentTokenId);
		},
		[isValid, url, token, onSave]
	);

	useEffect(() => {
		if (shouldReset) {
			setUrl("");
			setToken("");
		}
	}, [shouldReset]);

	return (
		<>
			<Form.Field>
				<Input
					isRequired
					label={t("pages.settings.directoryConfiguration.jumpcloud.url")}
					onValueChange={setUrl}
					value={url}
				/>
			</Form.Field>
			<Form.Field>
				<TextAreaInput
					autoResize
					isRequired
					label={t("pages.settings.directoryConfiguration.jumpcloud.token")}
					onValueChange={setToken}
					value={token}
				/>
			</Form.Field>
			<CommonConfigFields inEditMode={inEditMode} onSave={submit} error={error} disabled={!isValid} />
		</>
	);
};
