import { List, Map as ImmutableMap } from "immutable";
import React, { useMemo } from "react";
import { IntegrationAndResourcesCountVertex } from "components/pages/IdentityGraphPage/components/IdentityGraph/components/Vertices/IntegrationVertex";
import { AccountVertexModel } from "models/IdentityGraph/AccountVertexModel";
import { IntegrationVertexModel } from "models/IdentityGraph/IntegrationVertexModel";
import { RoleVertexModel } from "models/IdentityGraph/RoleVertexModel";
import { UserVertexModel } from "models/IdentityGraph/UserVertexModel";
import { VertexModel } from "models/IdentityGraph/VertexModel";
import { AccountsColumn } from "./AccountsColumn";
import { IntegrationsColumn } from "./IntegrationsColumn";
import { useStyles } from "./styles";
import { UsersColumn } from "./UsersColumn";

export const VerticesColumn: FC<{
	groupedVertices: ImmutableMap<string, List<VertexModel>>;
	refSetter: (ids: string[]) => (element: unknown) => void;
}> = ({ groupedVertices, refSetter }) => {
	const classes = useStyles();

	const userVertices = useMemo(() => groupedVertices.get("user"), [groupedVertices]);
	const accountVertices = useMemo(() => groupedVertices.get("account"), [groupedVertices]);
	const integrationVertex = useMemo(() => {
		const integrationVertices = groupedVertices.get("integration");
		const vertex = (integrationVertices?.first() as IntegrationVertexModel) ?? null;
		return vertex
			? {
					id: vertex.id,
					name: vertex.entity.integrationName,
					resourcesCount: vertex.entity.resourcesCount
				}
			: null;
	}, [groupedVertices]);
	const hasRoleVertices = useMemo(
		() => !(userVertices || accountVertices || integrationVertex),
		[userVertices, accountVertices, integrationVertex]
	);

	return (
		<div className={classes.columnContainer}>
			<div className={classes.column}>
				{userVertices ? <UsersColumn refSetter={refSetter} vertices={userVertices as List<UserVertexModel>} /> : null}
				{accountVertices ? (
					<AccountsColumn refSetter={refSetter} vertices={accountVertices as List<AccountVertexModel>} />
				) : null}
				{hasRoleVertices ? (
					<IntegrationsColumn
						refSetter={refSetter}
						vertices={groupedVertices as ImmutableMap<string, List<RoleVertexModel>>}
					/>
				) : null}
				{integrationVertex ? <IntegrationAndResourcesCountVertex integration={integrationVertex} selected /> : null}
			</div>
		</div>
	);
};
