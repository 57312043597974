import { Map } from "immutable";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { changeProfile } from "api/user";
import { IntegrationActorsSelectInput } from "components/common/UserIntegrationActorsSelect";
import { Button } from "components/ui/Button";
import { Typography } from "components/ui/legacy/Typography";
import { LoadingSpinner } from "components/ui/LoadingSpinner";
import { Table } from "components/ui/Table";
import { Title } from "components/ui/Title";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { useIntegrationActors } from "hooks/useIntegrationActors";
import { useIntegrations } from "hooks/useIntegrations";
import { useLoadingState } from "hooks/useLoadingState";
import { IntegrationActorModel } from "models/IntegrationActorModel";
import { UserModel } from "models/UserModel";
import { IsNullError } from "utils/errors/isNullError";
import { useStyles } from "./styles";

const PREFIX = "pages.profile.changeProfileForm.integrationActors";

const getInitialIntegrationActors = (user: UserModel, openGlobalErrorModal: (err: Error) => void) => {
	if (!user) {
		openGlobalErrorModal(
			IsNullError.from({
				location: "getInitialIntegrationActors",
				requestedProperty: "user"
			})
		);
		return undefined;
	} else {
		return user.integrationActors?.reduce(
			(final, actor) =>
				final.set(
					actor.integrationId,
					final.has(actor.integrationId) ? final.get(actor.integrationId)!.concat(actor) : [actor]
				),
			Map<string, IntegrationActorModel[]>()
		);
	}
};

interface IProps {
	user: UserModel;
	onSuccess: () => void;
	onUpdate: (user: UserModel) => void;
	setError: (error: Error | null) => void;
}

export const IntegrationActorsTable: FC<IProps> = ({ user, onSuccess, onUpdate, setError }) => {
	const { withLoader, isLoading } = useLoadingState();
	const openGlobalErrorModal = useOpenGlobalErrorModal();

	const classes = useStyles();
	const { t } = useTranslation();
	const integrations = useIntegrations();

	const integrationList = useMemo(() => integrations?.toList().toArray() || [], [integrations]);
	const integrationActorsMap = useMemo(
		() => getInitialIntegrationActors(user, openGlobalErrorModal),
		[user, openGlobalErrorModal]
	);
	const { integrationActorsOptions, integrationActors, setIntegrationActors } = useIntegrationActors<
		IntegrationActorModel[]
	>(integrationList, integrationActorsMap);

	const onSaveActors = useCallback(async () => {
		try {
			const newUser = await withLoader(
				changeProfile({
					integrationActors
				})
			);
			onUpdate(newUser);
			onSuccess();
		} catch (error) {
			if (!user) {
				openGlobalErrorModal(
					IsNullError.from({
						location: "onSendRequest",
						requestedProperty: "user"
					})
				);
			} else {
				const actors = getInitialIntegrationActors(user, openGlobalErrorModal);
				if (actors) {
					setIntegrationActors(actors);
				}
			}
			setError(error as Error);
		}
	}, [withLoader, integrationActors, onUpdate, onSuccess, user, setError, openGlobalErrorModal, setIntegrationActors]);

	return (
		<>
			<Title noBorder className={classes.integrationsTitle} variant="h3">
				{t(`${PREFIX}.title`)}
			</Title>
			{isLoading ? (
				<LoadingSpinner className={classes.spinner} />
			) : (
				<>
					<Table gridColumns="1fr 1fr" className={classes.scrollable}>
						<Table.Row>
							<Table.Header>
								<Typography variant="small">{t(`${PREFIX}.integration`)}</Typography>
							</Table.Header>
							<Table.Header>
								<Typography variant="small">{t(`${PREFIX}.account`)}</Typography>
							</Table.Header>
						</Table.Row>
						{integrationActorsOptions.map(({ integrationId, label, onChange, sortBy, value }) => (
							<Table.Row key={integrationId}>
								<Table.Cell>
									<Typography variant="small">{label}</Typography>
								</Table.Cell>
								<Table.Cell>
									<IntegrationActorsSelectInput
										optionClassName={classes.option}
										integrationId={integrationId}
										sortBy={sortBy}
										onChange={onChange}
										value={value}
										multiLine
									/>
								</Table.Cell>
							</Table.Row>
						))}
					</Table>
					<Button
						size="medium"
						disabled={isLoading}
						loading={isLoading}
						onClick={onSaveActors}
						className={classes.saveButton}>
						{t("buttons.save")}
					</Button>
				</>
			)}
		</>
	);
};
