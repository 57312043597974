import React from "react";

export const EntraIDLogo: FC = ({ className }) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" className={className}>
		<path
			d="M5.64771 17.9264C6.12101 18.2211 6.90986 18.5512 7.74437 18.5512C8.50415 18.5512 9.20788 18.3312 9.79329 17.9554C9.79329 17.9554 9.79536 17.9554 9.79536 17.9534L12 16.577V21.555C11.6512 21.555 11.2983 21.4596 10.9932 21.2686L5.64771 17.9264Z"
			fill="#225086"
		/>
		<path
			d="M10.5988 2.6207L1.43156 12.9587C0.723679 13.7579 0.908435 14.9661 1.82598 15.5391C1.82598 15.5391 5.21801 17.6586 5.64565 17.9264C6.11896 18.2211 6.9078 18.5512 7.74232 18.5512C8.5021 18.5512 9.20583 18.3312 9.79123 17.9554C9.79123 17.9554 9.79331 17.9554 9.79331 17.9533L12 16.577L6.667 13.2431L12.0021 7.22506V2C11.4831 2 10.9662 2.20759 10.5988 2.6207Z"
			fill="#66DDFF"
		/>
		<path d="M6.66699 13.2431L6.72927 13.2825L12 16.577H12.0021V7.22711L12 7.22504L6.66699 13.2431Z" fill="#CBF8FF" />
		<path
			d="M22.174 15.539C23.0916 14.9661 23.2763 13.7579 22.5684 12.9587L16.5545 6.17462C16.0688 5.94835 15.5249 5.81964 14.9499 5.81964C13.8185 5.81964 12.8096 6.30748 12.137 7.07349L12.0042 7.22295L17.3351 13.241L12 16.5749V21.5529C12.3508 21.5529 12.6996 21.4574 13.0068 21.2665L22.174 15.539Z"
			fill="#074793"
		/>
		<path
			d="M12.002 2V7.22506L12.1349 7.07559C12.8075 6.30958 13.8184 5.82174 14.9477 5.82174C15.5248 5.82174 16.0666 5.95253 16.5524 6.17672L13.4012 2.6207C13.0358 2.20759 12.5168 2.00208 12.002 2Z"
			fill="#0294E4"
		/>
		<path d="M17.335 13.2431L12.002 7.22714V16.5749L17.335 13.2431Z" fill="#96BCC2" />
	</svg>
);
