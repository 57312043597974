import { useCallback, useState } from "react";
import { useDebounceFn } from "./useDebounce";
import { DESC, usePagination, type TUsePaginationOptions } from "./usePagination";
import type { IPaginatedSearchOptions } from "utils/searchUtils";

type TUseDebouncedPaginationSearchOptions<T> = TUsePaginationOptions<T> & {
	wait?: number;
	payload?: Record<string, unknown>;
	initialFetch?: boolean;
};

const DEFAULT_DEBOUNCE_WAIT = 150;
const DEFAULT_PER_PAGE = 50;

export const useDebouncedPaginatedSearch = <T extends { id: string }>(
	query: string,
	options: TUseDebouncedPaginationSearchOptions<T>
) => {
	const [isLoading, setIsLoading] = useState(false);
	const { fetchItems, wait = DEFAULT_DEBOUNCE_WAIT, initialFetch = true, ...propPaginationOptions } = options;

	const fetchItemsWithSearch = useCallback(
		async (paginationOptions: IPaginatedSearchOptions) => {
			paginationOptions.search = { query };
			const items = await fetchItems(paginationOptions, options.payload);
			setIsLoading(false);
			return items;
		},
		[fetchItems, options.payload, query]
	);
	const pagination = usePagination({
		perPage: DEFAULT_PER_PAGE,
		disableSearch: true,
		sortOrder: DESC,
		...propPaginationOptions,
		fetchItems: fetchItemsWithSearch
	});
	const { isLoading: isLoadingPaginated, getPage } = pagination;

	const getPageDebounced = useDebounceFn(getPage, wait);

	const getPageDebouncedWithLoader = useCallback(
		(page = 1) => {
			setIsLoading(true);
			void getPageDebounced(page, true, true);
		},
		[getPageDebounced]
	);

	return {
		...pagination,
		getPageDebounced: getPageDebouncedWithLoader,
		isLoading: initialFetch && (isLoadingPaginated || isLoading)
	};
};
