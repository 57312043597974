import classNames from "classnames";
import React, { useMemo } from "react";
import { requirePropertyOf } from "utils/security";
import { useStyles } from "../styles";
import type { TBaseEntityNodeProps } from "../types";

export const NameWithEmailNodeContent: FC<
	TBaseEntityNodeProps & {
		text: React.ReactNode;
		icon?: React.ReactNode;
		withIcon?: boolean;
		inactive?: boolean;
		tooltipOnOverflow?: boolean;
	}
> = ({ text, withIcon, inactive, size, icon, className, innerRef, id }) => {
	const classes = useStyles();
	const sizeClassName = useMemo(() => requirePropertyOf(classes, size), [classes, size]);

	const iconComponent = useMemo(
		() => (withIcon ? <div className={classNames(classes.icon, classes.withEmail, sizeClassName)}>{icon}</div> : null),
		[withIcon, classes.icon, sizeClassName, classes.withEmail, icon]
	);

	return (
		<div
			id={id}
			ref={innerRef}
			className={classNames(classes.labelTextContent, sizeClassName, { [classes.inactive]: inactive }, className)}>
			{iconComponent}
			{text}
		</div>
	);
};
