import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	integrationImage: {
		height: "2.3rem",
		padding: "0.45rem",
		width: "2.3rem"
	},
	roleContainer: {
		alignItems: "center",
		display: "flex",
		flexDirection: "row",
		gap: "0.5rem"
	},
	roleName: {
		alignItems: "flex-start",
		display: "flex",
		flexDirection: "column",
		gap: "0.25rem"
	}
});
