import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AreYouSureModal } from "components/common/AreYouSureModal";
import { useStepperContext } from "components/common/Stepper/stepperContext";
import { useNewRequestFormContext } from "components/pages/NewRequestPage/newRequestFormContext";
import { routes } from "routes/routes";

type TProps = {
	isOpen: boolean;
	close: () => void;
};

export const RemoveAllTargetsModal: FC<TProps> = ({ className, innerRef, isOpen, close }) => {
	const { t } = useTranslation("translation", { keyPrefix: "pages.newRequest.summaryStep.removeAllTargetsModal" });
	const {
		actions: { resetState }
	} = useNewRequestFormContext();

	const {
		actions: { goToStep }
	} = useStepperContext();

	const navigate = useNavigate();

	const goBack = useCallback(() => {
		close();
		navigate(routes.requests.main);
		goToStep(0);
		resetState();
	}, [close, goToStep, navigate, resetState]);

	return (
		<AreYouSureModal
			className={className}
			innerRef={innerRef}
			isOpen={isOpen}
			onClose={close}
			onCancel={goBack}
			onAction={close}
			actionLabel={t("actionLabel")}
			closeLabel={t("cancelLabel")}
			content={t("content")}
		/>
	);
};
