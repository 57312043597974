import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { StaticChip } from "components/ui/chips/StaticChip";
import { Section } from "components/ui/Section";
import { Typography } from "components/ui/Typography";
import { usePoliciesContext } from "context/policiesContext";
import { PolicyModel } from "models/PolicyModel";
import { PolicyBarList } from "../PolicyBarList";

type TPolicyTableSectionProps = {
	onEdit: (policy: PolicyModel) => void;
	onRemove: (policyId: string) => void;
};
export const PolicyTableSection: FC<TPolicyTableSectionProps> = ({ className, innerRef, onRemove, onEdit }) => {
	const { t } = useTranslation();

	const {
		state: { policies }
	} = usePoliciesContext();

	const policiesArray = useMemo(
		() => Array.from(policies?.values() || []).sort((a, b) => a.sortOrder - b.sortOrder),
		[policies]
	);

	const title = useMemo(() => {
		return (
			<>
				<Typography variant="body_med"> {t("pages.policies.total")}</Typography>
				<StaticChip size="small" variant="regular">
					{t("number", { value: policiesArray?.length || 0 })}
				</StaticChip>
			</>
		);
	}, [policiesArray?.length, t]);

	return (
		<Section fullHeight title={title} className={className} innerRef={innerRef}>
			<PolicyBarList policies={policiesArray} onEdit={onEdit} onDelete={onRemove} />
		</Section>
	);
};
