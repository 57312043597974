import React from "react";
import { makeIcon } from "components/ui/Icon";

export const CollapseIcon = makeIcon(
	<svg viewBox="0 0 24 24" fill="none">
		<path
			d="M12 17.4L8.10005 21.3C7.91672 21.4834 7.68338 21.575 7.40005 21.575C7.11672 21.575 6.88338 21.4834 6.70005 21.3C6.51672 21.1167 6.42505 20.8834 6.42505 20.6C6.42505 20.3167 6.51672 20.0834 6.70005 19.9L10.575 16.025C10.9584 15.6417 11.4334 15.45 12 15.45C12.5667 15.45 13.0417 15.6417 13.425 16.025L17.3 19.9C17.4834 20.0834 17.575 20.3167 17.575 20.6C17.575 20.8834 17.4834 21.1167 17.3 21.3C17.1167 21.4834 16.8834 21.575 16.6 21.575C16.3167 21.575 16.0834 21.4834 15.9 21.3L12 17.4ZM12 6.60005L15.9 2.70005C16.0834 2.51672 16.3167 2.42505 16.6 2.42505C16.8834 2.42505 17.1167 2.51672 17.3 2.70005C17.4834 2.88338 17.575 3.11672 17.575 3.40005C17.575 3.68338 17.4834 3.91672 17.3 4.10005L13.425 7.97505C13.0417 8.35838 12.5667 8.55005 12 8.55005C11.4334 8.55005 10.9584 8.35838 10.575 7.97505L6.70005 4.10005C6.51672 3.91672 6.42505 3.68338 6.42505 3.40005C6.42505 3.11672 6.51672 2.88338 6.70005 2.70005C6.88338 2.51672 7.11672 2.42505 7.40005 2.42505C7.68338 2.42505 7.91672 2.51672 8.10005 2.70005L12 6.60005Z"
			fill="currentColor"
		/>
	</svg>
);
