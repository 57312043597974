import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	header: {
		alignItems: "flex-end",
		display: "flex",
		flexDirection: "row",
		gap: "12px",
		justifyContent: "flex-start",
		marginTop: "20px"
	},
	headerForm: {
		alignItems: "center",
		display: "flex",
		flexDirection: "row",
		flexGrow: "1",
		flexWrap: "nowrap",
		gap: "12px",
		padding: "0",
		"& > $field": {
			marginTop: 0,
			flexGrow: 1,
			maxWidth: "20%"
		}
	},
	historyActions: {
		display: "flex",
		flexDirection: "row",
		gap: "4px",
		paddingBottom: "4px"
	},
	field: {},
	select: {
		maxWidth: "100%",
		width: "100%"
	}
});
