import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	prefix: {
		alignItems: "center",
		display: "flex",
		justifyContent: "center",
		width: "1em",
		fontSize: "1.25em"
	},
	option: {
		padding: "var(--spacing-x3, 12px) var(--spacing-x4, 16px)",
		minHeight: "52px",
		display: "flex",
		width: "100%",
		alignItems: "center",
		justifyContent: "flex-start",
		gap: "var(--spacing-x3, 12px)",
		transition: "background-color 0.2s ease-in-out",
		willChange: "background-color",
		"&:hover": {
			cursor: "pointer",
			backgroundColor: "var(--color-blue-200)"
		}
	}
});
