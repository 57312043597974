import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	extraOptionsButton: {
		alignItems: "center",
		display: "inline-flex",
		flexDirection: "row",
		flexWrap: "wrap",
		fontSize: "var(--spacing-x5, 20px)",
		gap: "var(--spacing-x2, 8px)",
		height: "var(--spacing-x8, 32px)",
		justifyContent: "center",
		overflowWrap: "inherit",
		width: "var(--spacing-x8, 32px)",
		color: "var(--icon-button-secondary-color)",
		border: "2px solid var(--icon-button-secondary-border-color)",
		backgroundColor: "var(--icon-button-secondary-background-color-regular)",
		borderRadius: "var(--spacing-x1, 4px)",
		"&:hover": {
			backgroundColor: "var(--icon-button-secondary-background-color-hover)"
		}
	}
});
