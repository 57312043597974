import { ALL_FILTERS } from "filters";
import {
	IntegrationAllowRequestsFilter,
	IntegrationIdFilter,
	IntegrationMaintainerFilter,
	IntegrationNameFilter,
	IntegrationOwnerFilter,
	IntegrationWorkflowFilter,
	INTEGRATION_FILTERS
} from "filters/integration";
import {
	IntegrationResourceAllowRequestsFilter,
	IntegrationResourceIdFilter,
	IntegrationResourceIntegrationIdFilter,
	IntegrationResourceIntegrationNameFilter,
	IntegrationResourceMaintainerFilter,
	IntegrationResourceNameFilter,
	IntegrationResourceOwnerFilter,
	IntegrationResourceTagsFilter,
	IntegrationResourceTypeFilter,
	IntegrationResourceWorkflowFilter,
	INTEGRATION_RESOURCE_FILTERS
} from "filters/integrationResource";
import {
	IntegrationResourceRoleAllowRequestsFilter,
	IntegrationResourceRoleIdFilter,
	IntegrationResourceRoleIntegrationIdFilter,
	IntegrationResourceRoleIntegrationNameFilter,
	IntegrationResourceRoleIntegrationResourceIdFilter,
	IntegrationResourceRoleIntegrationResourceNameFilter,
	IntegrationResourceRoleIntegrationResourceTagsFilter,
	IntegrationResourceRoleIntegrationResourceTypeFilter,
	IntegrationResourceRoleNameFilter,
	IntegrationResourceRoleWorkflowFilter,
	INTEGRATION_RESOURCE_ROLE_FILTERS
} from "filters/integrationResourceRole";
import { notEmpty } from "utils/comparison";
import type { IFilter } from "types/filters";

type TIntegrationFilterFields = {
	allowRequests: boolean;
	id: string[];
	maintainer: string[] | null;
	name: string[];
	owner: string[];
	workflow: string[];
};

type TIntegrationResourceFilterFields = {
	allowRequests: boolean;
	id: string[];
	integrationId: string[];
	integrationName: string[];
	maintainer: string[] | null;
	name: string[];
	owner: (string | null)[];
	tags: string[];
	type: string[];
	workflow: (string | null)[];
};

type TIntegrationResourceRoleFilterFields = {
	allowRequests: boolean;
	id: string[];
	integrationId: string[];
	integrationName: string[];
	integrationResourceId: string[];
	integrationResourceName: string[];
	integrationResourceTags: string[];
	integrationResourceType: string[];
	name: string[];
	workflow: (string | null)[];
};

const filterEmptyString = (value: string | null) => value !== "";

function createArrayFilter<T extends string | null>(
	FilterClass: new (args: { value: T[] }) => IFilter,
	value?: T[]
): IFilter | undefined {
	const filteredValue = value?.filter(filterEmptyString);
	const classArguments = filteredValue && filteredValue.length ? { value: filteredValue } : undefined;

	return classArguments ? new FilterClass(classArguments) : undefined;
}

export function getIntegrationFilters(options: Partial<TIntegrationFilterFields>): IFilter[] {
	const { allowRequests, id, maintainer, name, owner, workflow } = options;

	const allowRequestsFilter =
		allowRequests !== undefined ? new IntegrationAllowRequestsFilter({ value: allowRequests }) : undefined;

	const idFilter = createArrayFilter(IntegrationIdFilter, id);

	const maintainerFilter =
		maintainer === null || (maintainer && maintainer.length > 0)
			? new IntegrationMaintainerFilter({
					value: Array.isArray(maintainer) ? maintainer.filter(filterEmptyString) : maintainer
				})
			: undefined;

	const nameFilter = createArrayFilter(IntegrationNameFilter, name);

	const ownerFilter = createArrayFilter(IntegrationOwnerFilter, owner);

	const workflowFilter = createArrayFilter(IntegrationWorkflowFilter, workflow);

	return [allowRequestsFilter, idFilter, maintainerFilter, nameFilter, ownerFilter, workflowFilter].filter(notEmpty);
}

export function getIntegrationResourceFilters(options: Partial<TIntegrationResourceFilterFields>): IFilter[] {
	const { allowRequests, id, integrationId, integrationName, maintainer, name, owner, tags, type, workflow } = options;

	const allowRequestsFilter =
		allowRequests !== undefined ? new IntegrationResourceAllowRequestsFilter({ value: allowRequests }) : undefined;

	const idFilter = createArrayFilter(IntegrationResourceIdFilter, id);

	const IntegrationIdFilter = createArrayFilter(IntegrationResourceIntegrationIdFilter, integrationId);

	const integrationNameFilter = createArrayFilter(IntegrationResourceIntegrationNameFilter, integrationName);

	const maintainerFilter =
		maintainer === null || (maintainer && maintainer.length > 0)
			? new IntegrationResourceMaintainerFilter({
					value: Array.isArray(maintainer) ? maintainer.filter(filterEmptyString) : maintainer
				})
			: undefined;

	const nameFilter = createArrayFilter(IntegrationResourceNameFilter, name);

	const ownerFilter = createArrayFilter(IntegrationResourceOwnerFilter, owner);

	const tagsFilter = createArrayFilter(IntegrationResourceTagsFilter, tags);

	const typeFilter = createArrayFilter(IntegrationResourceTypeFilter, type);

	const workflowFilter = createArrayFilter(IntegrationResourceWorkflowFilter, workflow);
	return [
		allowRequestsFilter,
		idFilter,
		IntegrationIdFilter,
		integrationNameFilter,
		maintainerFilter,
		nameFilter,
		ownerFilter,
		tagsFilter,
		typeFilter,
		workflowFilter
	].filter(notEmpty);
}

export function getIntegrationResourceRoleFilters(options: Partial<TIntegrationResourceRoleFilterFields>): IFilter[] {
	const {
		allowRequests,
		id,
		integrationId,
		integrationName,
		integrationResourceId,
		integrationResourceName,
		integrationResourceTags,
		integrationResourceType,
		name,
		workflow
	} = options;

	const allowRequestsFilter =
		allowRequests !== undefined ? new IntegrationResourceRoleAllowRequestsFilter({ value: allowRequests }) : undefined;

	const idFilter = createArrayFilter(IntegrationResourceRoleIdFilter, id);

	const integrationIdFilter = createArrayFilter(IntegrationResourceRoleIntegrationIdFilter, integrationId);

	const integrationNameFilter = createArrayFilter(IntegrationResourceRoleIntegrationNameFilter, integrationName);

	const integrationResourceIdFilter = createArrayFilter(
		IntegrationResourceRoleIntegrationResourceIdFilter,
		integrationResourceId
	);

	const integrationResourceNameFilter = createArrayFilter(
		IntegrationResourceRoleIntegrationResourceNameFilter,
		integrationResourceName
	);

	const integrationResourceTagsFilter = createArrayFilter(
		IntegrationResourceRoleIntegrationResourceTagsFilter,
		integrationResourceTags
	);

	const integrationResourceTypeFilter = createArrayFilter(
		IntegrationResourceRoleIntegrationResourceTypeFilter,
		integrationResourceType
	);

	const nameFilter = createArrayFilter(IntegrationResourceRoleNameFilter, name);

	const workflowFilter = createArrayFilter(IntegrationResourceRoleWorkflowFilter, workflow);

	return [
		allowRequestsFilter,
		idFilter,
		integrationIdFilter,
		integrationNameFilter,
		integrationResourceIdFilter,
		integrationResourceNameFilter,
		integrationResourceTagsFilter,
		integrationResourceTypeFilter,
		nameFilter,
		workflowFilter
	].filter(notEmpty);
}

export const fromURLSearchParams = (
	searchParams: URLSearchParams,
	type?: "integration" | "resource" | "role"
): IFilter[] => {
	let filterArray = ALL_FILTERS;
	if (type === "integration") {
		filterArray = INTEGRATION_FILTERS;
	} else if (type === "resource") {
		filterArray = INTEGRATION_RESOURCE_FILTERS;
	} else if (type === "role") {
		filterArray = INTEGRATION_RESOURCE_ROLE_FILTERS;
	}
	const filters = filterArray.map(filter => filter.fromURLSearchParams(searchParams)).filter(notEmpty);
	return filters;
};
