import classNames from "classnames";
import React, { useMemo } from "react";
import { useStyles } from "./styles";

type TProps = {
	height?: number | string;
	variant?: "text" | "rect" | "circle";
	width?: number | string;
};

export const Skeleton: FC<TProps> = ({ className, children, innerRef, variant = "rect", height, width }) => {
	const classes = useStyles();

	const skeletonClassName = useMemo(() => {
		switch (variant) {
			case "circle":
				return classes.skeletonCircle;
			case "text":
				return classes.skeletonText;
			case "rect":
			default:
				return classes.skeletonRectangle;
		}
	}, [classes, variant]);

	return (
		<div className={classNames(skeletonClassName, className)} ref={innerRef} style={{ height, width }}>
			{children}
		</div>
	);
};
