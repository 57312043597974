import ApiError from "./apiError";

export default class SessionExpiredError extends ApiError {
	constructor() {
		super({
			errorId: "fetch.sessionExpired",
			message: "Your session has expired. Please log in again."
		});
	}
}
