import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	center: {
		alignItems: "center",
		display: "flex",
		height: "100%",
		justifyContent: "center"
	},
	content: {
		"&$content$content": {
			background: "none",
			border: "none",
			padding: "1.5rem 2.25rem"
		},
		display: "flex",
		flexDirection: "column",
		gap: "1.5rem",
		padding: "1rem",
		overflow: "auto"
	},
	header: {
		alignItems: "center",
		display: "flex",
		gap: "0.5rem",
		background: "var(--color-white)"
	},
	link: {
		"&:hover": {
			textDecoration: "underline"
		}
	},
	permissionList: {
		display: "flex",
		background: "var(--color-white)",
		padding: "1.5rem 2.25rem",
		borderRadius: "var(--border-radius-semi-rounded)",
		flex: "1 1 auto"
	}
});
