import constate from "constate";
import { Map, Record } from "immutable";
import { useCallback, useMemo, useRef } from "react";
import { getUser, multiGetUsers } from "api/users";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { useThrottledBulkFetch } from "hooks/useThrottledBulkFetch";
import { UserModel } from "models/UserModel";
import { notEmpty } from "utils/comparison";

type TUserState = { loading: boolean; data: UserModel | null; hadError: boolean };
class UserLoadingState extends Record<TUserState>({ loading: false, data: null, hadError: false }) {}

const useFullUsersState = () => {
	const fullUsersStateRef = useRef(Map<string, UserLoadingState>());

	const setFullUsersState = useCallback((newState: Map<string, UserLoadingState>) => {
		fullUsersStateRef.current = newState;
	}, []);

	const getFullUsersState = useCallback(() => fullUsersStateRef.current, []);

	return { setFullUsersState, getFullUsersState };
};

const useUsers = () => {
	const {
		itemsById: users,
		loadIds: loadUsers,
		setItemsById: setUsers
	} = useThrottledBulkFetch(multiGetUsers, {
		throttleTime: 50,
		includeDeleted: true
	});
	const openGlobalErrorModal = useOpenGlobalErrorModal();
	const { setFullUsersState, getFullUsersState } = useFullUsersState();

	const setUser = useCallback(
		(user: UserModel) => {
			const currentFullUsersState = getFullUsersState();
			const currentUserFullState = (currentFullUsersState.get(user.id) || new UserLoadingState())
				.set("loading", false)
				.set("data", user);
			setFullUsersState(currentFullUsersState.set(user.id, currentUserFullState));
			setUsers(current => current?.set(user.id, user) || current);
		},
		[getFullUsersState, setFullUsersState, setUsers]
	);

	const loadFullUser: (id: string) => Promise<UserModel | null> = useCallback(
		async (id: string) => {
			let currentFullUsersState = getFullUsersState();
			try {
				const state = currentFullUsersState.get(id) || new UserLoadingState();
				if (!state.loading) {
					setFullUsersState(currentFullUsersState.set(id, state.set("loading", true)));
					const fullUser = await getUser(id);
					setUser(fullUser);
					return fullUser;
				}
				return state.data;
			} catch (error) {
				openGlobalErrorModal(error as Error);
				currentFullUsersState = getFullUsersState();
				setFullUsersState(
					currentFullUsersState.set(id, currentFullUsersState.get(id)!.set("loading", false).set("hadError", true))
				);
				return null;
			}
		},
		[getFullUsersState, openGlobalErrorModal, setFullUsersState, setUser]
	);

	const loadUser = useCallback((id: string) => loadUsers([id]), [loadUsers]);

	const fullUsersState = getFullUsersState();

	const fullUsers: Map<string, UserModel> = fullUsersState.map(value => value.data).filter(notEmpty);

	const sortedUsers = useMemo(
		() =>
			users
				.filter(notEmpty)
				?.toList()
				?.sortBy(user => user.fullName),
		[users]
	);

	const addUsersToContext = useCallback(
		(newUsers: UserModel[]) => {
			const missingUsers = newUsers.filter(user => !users.has(user.id));
			if (!missingUsers.length) return;
			setUsers(curr => {
				missingUsers.forEach(user => {
					curr = curr.set(user.id, user);
				});
				return curr;
			});
		},
		[setUsers, users]
	);

	return {
		state: {
			users,
			sortedUsers,
			fullUsers,
			fullUsersState
		},
		actions: { loadUsers, loadUser, loadFullUser, setUser, addUsers: addUsersToContext }
	};
};

export const [UsersProvider, useUsersContext] = constate(useUsers);
