import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	cell: {
		"&$header": {
			borderRightWidth: 0,
			borderTopWidth: 0
		},
		"&:not(:last-child)": {
			borderRightWidth: 1
		},
		"&$noPadding": {
			padding: 0
		},
		"&$clickable": {
			cursor: "pointer",
			"&:hover": {
				backgroundColor: "var(--color-almost-white)"
			}
		},
		"&$center": {
			justifyContent: "center"
		},
		alignItems: "center",
		borderColor: "#E5E5E5",
		borderStyle: "solid",
		borderTopWidth: 1,
		borderWidth: 0,
		display: "flex",
		height: "100%",
		overflow: "hidden",
		padding: "1rem",
		wordBreak: "break-all"
	},
	noPadding: {},
	header: {},
	loading: {
		opacity: 0.6
	},
	overflow: {},
	outline: {},
	row: {
		"&:last-child > $cell": {
			borderBottomWidth: 1
		},
		"&$clickable": {
			cursor: "pointer",
			"&:hover": {
				backgroundColor: "var(--color-almost-white)"
			}
		},
		display: "grid",
		gridAutoRows: "1fr",
		gridTemplateColumns: "inherit"
	},
	table: {
		"&$outline": {
			"& > $row": {
				"& > $cell": {
					"&$header:not(:last-child)": {
						borderRightWidth: 1
					},
					borderColor: "var(--color-grey-background)"
				},
				"&:last-child > $cell": {
					borderBottomWidth: 0
				}
			},
			border: "1px solid var(--color-grey-background)",
			borderRadius: "var(--border-radius-semi-rounded)"
		},
		"&$overflow": {
			overflow: "auto"
		},
		display: "block",
		gridTemplateColumns: "auto auto auto",
		transition: "opacity 0.2s ease-in-out"
	},
	clickable: {},
	center: {}
});
