import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "components/ui/Typography";
import { dayjs } from "utils/dates/dayjs";
import { useStyles } from "./styles";

type TProps = {
	date: Date;
};

const DateWithDiffComponent: FC<TProps> = ({ className, date }) => {
	const { t } = useTranslation();
	const classes = useStyles();

	return (
		<div className={classNames(className, classes.date)}>
			<Typography variant="text_reg">{t("dateTime.date", { date })}</Typography>
			<Typography variant="text_sm_reg">{dayjs(date).fromNow()}</Typography>
		</div>
	);
};

export const DateWithDiff = React.memo(DateWithDiffComponent);
