import { useCallback } from "react";

export const useStopPropagation = (callback?: (e: React.MouseEvent<HTMLElement>) => void) => {
	return useCallback(
		(e: React.MouseEvent<HTMLElement>) => {
			e.stopPropagation();
			callback?.(e);
		},
		[callback]
	);
};
