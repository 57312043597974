import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { UserCard } from "components/common/UserCard";
import { StaticChip } from "components/ui/chips/StaticChip";
import { TitleTooltip } from "components/ui/TitleTooltip";
import { TooltipOnOverflow } from "components/ui/TooltipOnOverflow";
import { useStyles } from "./styles";
import type { List } from "immutable";
import type { TEntityNodeColor } from "components/common/entities/Nodes/EntityNode";

export type TStatus = "approved" | "declined" | "pending";

interface IProps {
	title: React.ReactNode;
	approverIds?: List<string>;
	responderIds?: List<string>;
	status: TStatus;
}

export const GroupTooltipContent: FC<IProps> = ({ title, approverIds, responderIds, status, children }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const header = useMemo(
		() => (
			<div className={classes.groupTooltipTitle}>
				{typeof title === "string" ? (
					<>
						<TooltipOnOverflow textVariant="body_sb" content={title} />
						<StaticChip size="small" variant="regular">
							{t("number", { value: approverIds?.size ?? 0 })}
						</StaticChip>
					</>
				) : (
					title
				)}
			</div>
		),
		[approverIds?.size, classes.groupTooltipTitle, t, title]
	);

	return (
		<TitleTooltip
			header={header}
			body={
				<div className={classes.approversListContainer}>
					{approverIds?.map(approverId => {
						const responded = responderIds?.includes(approverId);
						let color: TEntityNodeColor = "purple";
						if (responded) color = status === "approved" ? "green" : "red";

						return (
							<UserCard
								key={approverId}
								user={approverId}
								color={color}
								selected={responded}
								className={classes.cardContainer}
							/>
						);
					})}
				</div>
			}>
			{React.isValidElement(children) ? children : <div>{children}</div>}
		</TitleTooltip>
	);
};
