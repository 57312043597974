import { IntegrationActorPermissionModel } from "models/IntegrationActorPermissionModel";
import { apiReq } from "utils/api/apiReq";

export async function revokePermission(integrationActorPermissionId: string) {
	const { data } = await apiReq(
		"POST",
		`/v1/integrationActorPermissions/${integrationActorPermissionId}/revokePermission`
	);
	return IntegrationActorPermissionModel.fromServerData(data);
}
