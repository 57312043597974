import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { PageTemplate } from "components/templates/PageTemplate";
import { LinkButton } from "components/ui/Button";
import { Typography } from "components/ui/legacy/Typography";
import { Loading } from "components/ui/Loading";
import { useAccessReviewsContext } from "context/accessReviewsContext";
import { useAccessReviews } from "hooks/useAccessReviews";
import { useLoadingState } from "hooks/useLoadingState";
import { AccessReview } from "./components/AccessReview";
import { NewAccessReviewPopup } from "./components/NewAccessReviewPopup";
import { PreviousAccessReviews } from "./components/PreviousAccessReviews";
import { useStyles } from "./styles";

export const AccessReviewDashboardPage: FC = ({ className }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const { accessReviews, getLoading } = useAccessReviews();

	const [selectedAccessReviewId, setSelectedAccessReviewId] = useState<string | null>(null);

	const back = useCallback(() => setSelectedAccessReviewId(null), [setSelectedAccessReviewId]);
	const change = useCallback(
		(accessReviewId: string) => setSelectedAccessReviewId(accessReviewId),
		[setSelectedAccessReviewId]
	);

	const {
		actions: { createAccessReview }
	} = useAccessReviewsContext();

	const { isLoading: loadingNewAccessReview, withLoader } = useLoadingState();

	const onCreateAccessReview = useCallback(
		async (name: string, description: string, immediateRevoke: boolean, templateId?: string) => {
			await withLoader(createAccessReview(name, description, immediateRevoke, templateId));
		},
		[createAccessReview, withLoader]
	);

	const Content = useCallback(() => {
		if (typeof accessReviews?.size !== "number") {
			return null;
		}
		if (accessReviews.size === 0 && !loadingNewAccessReview) {
			return (
				<div className={classes.noAccessReviews}>
					<Typography variant="h2">{t("pages.accessReview.noAccessReviews.title")}</Typography>
					<Typography variant="h3">{t("pages.accessReview.noAccessReviews.message")}</Typography>
				</div>
			);
		}
		if (!selectedAccessReviewId) {
			return (
				<>
					<AccessReview change={change} loadingNewAccessReview={loadingNewAccessReview} />
					{accessReviews.size !== 0 && <PreviousAccessReviews view={change} />}
				</>
			);
		}
		return <AccessReview accessReviewId={selectedAccessReviewId} back={back} change={change} />;
	}, [accessReviews?.size, selectedAccessReviewId, back, change, classes.noAccessReviews, t, loadingNewAccessReview]);

	return (
		<PageTemplate className={className}>
			<PageTemplate.Title className={classes.header}>
				{t("pages.accessReview.title")}
				<div className={classes.headerActions}>
					<LinkButton size="medium" variant="text" to="templates">
						{t("pages.accessReview.manageTemplates")}
					</LinkButton>
					<NewAccessReviewPopup onCreateAccessReview={onCreateAccessReview} />
				</div>
			</PageTemplate.Title>
			<PageTemplate.Content className={classes.content}>
				<Loading loading={getLoading} render={Content} />
			</PageTemplate.Content>
		</PageTemplate>
	);
};
