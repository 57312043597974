import classNames from "classnames";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { NameWithEmail } from "components/common/entities/components/NameWithEmail";
import { UserNode } from "components/common/entities/Nodes/UserNode";
import { ChevronDownIcon } from "components/ui/Icons/ChevronDownIcon";
import { ChevronUpIcon } from "components/ui/Icons/ChevronUpIcon";
import { Typography } from "components/ui/legacy/Typography";
import { useUser } from "hooks/useUser";
import { useStyles } from "./styles";

type TUserProps = {
	userId: string;
	refSetter?: (ids: string[]) => (element: unknown) => void;
	selected?: boolean;
};

type TUsersProps = {
	userIds: string[];
	refSetter?: (ids: string[]) => (element: unknown) => void;
	onCollapse?: () => void;
	onExpand: () => void;
};

type TCollapseProps = {
	collapse: true;
	onCollapse: () => void;
};

type TVertexProps = TUserProps | TUsersProps | TCollapseProps;

const isVertexProps = (props: TProps<TVertexProps>): props is TUserProps | TUsersProps => {
	return isUserProps(props) || "userIds" in props;
};
const isUserProps = (props: TProps<TVertexProps>): props is TUserProps => {
	return "userId" in props;
};

const getRef = (props: TProps<TVertexProps>) => {
	if (!isVertexProps(props)) return props.innerRef;
	else if (isUserProps(props)) {
		const ids = [props.userId];
		return props.refSetter ? props.refSetter(ids) : props.innerRef;
	} else {
		return props.refSetter ? props.refSetter(props.userIds) : props.innerRef;
	}
};

export const UserVertex: FC<TVertexProps> = props => {
	const classes = useStyles();
	const { t } = useTranslation();
	const ref = getRef(props);
	const isUser = isUserProps(props);
	const isUsers = isVertexProps(props) && !isUser;
	const user = useUser(isUser ? props.userId : "");
	const selected = isUser && props.selected;

	const userImageElement = isUser && user?.imageUrl ? <img src={user.imageUrl} className={classes.image} /> : null;

	const { content, actions } = useMemo(() => {
		if (isUser) {
			return {
				content: user ? <NameWithEmail name={user.fullName} email={user.email} /> : t("common.unknown.user"),
				actions: null
			};
		}
		if (isUsers) {
			return {
				content: <Typography variant="small">+{props.userIds.length}</Typography>,
				actions: (
					<>
						{props.onCollapse ? <ChevronUpIcon onClick={props.onCollapse} /> : null}
						<ChevronDownIcon onClick={props.onExpand} />
					</>
				)
			};
		}
		return {
			content: <Typography variant="small">{t("common.entities.collapse")}</Typography>,
			actions: <ChevronUpIcon onClick={props.onCollapse} />
		};
	}, [isUser, isUsers, props, t, user]);
	return (
		<UserNode
			className={classNames(classes.maxNodeWidth, props.className)}
			content={content}
			actions={actions}
			icon={userImageElement}
			isDeleted={user?.isDeleted}
			selected={selected}
			innerRef={ref}
		/>
	);
};
